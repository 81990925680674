import { useMemo } from 'react';
import { useGetSortedSPMByEditorIdQuery } from 'store/rtkqFeatures/api/spmSportedExtendedApi';
import { useAppSelector } from 'hooks/redux';
import { useGetMeetingsByUserIdQuery } from 'store/rtkqFeatures/api/meetingExtendedApi';
import { useGetServicesByUserIdQuery } from 'store/rtkqFeatures/api/serviceExtendedApi';
import { selectLoggedInUserId } from 'store/features/user/userSlice';

const useSortedSPM = () => {  
  const editorId = useAppSelector(selectLoggedInUserId);

  // get sorted spm
  const { data: spmSorted, isError } = useGetSortedSPMByEditorIdQuery(editorId, {
    skip: !editorId,
  });

  // get meetings
  const { data: meetings = [] } = useGetMeetingsByUserIdQuery(editorId, {
    skip: !editorId,
  });

  // get services
  const { data: services = [] } = useGetServicesByUserIdQuery(editorId, {
    skip: !editorId,
  });

  const sortedSPMItems = useMemo(() => {
    const unsortedSPM = [...meetings, ...services]; // add S & P here
    if(isError) return unsortedSPM;
    const sortedSPM = spmSorted?.allIds?.map((spmSortedId) => {
      return unsortedSPM.filter(({ _id: SPMId }) => {
        return SPMId === spmSortedId;
      })[0];
    });
    const validSortedSPM = sortedSPM?.filter((SPM) => Boolean(SPM));
    return validSortedSPM;
  }, [spmSorted, services, meetings, isError]);

  const sortedSPMItemsLength = sortedSPMItems?.length;
  
  return { sortedSPMItems, sortedSPMItemsLength, spmSorted };
};

export default useSortedSPM;
