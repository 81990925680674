import axios from 'axios';
import serverApi, { SERVER_API_BASE_URL } from 'lib/apiConfig';
import { UpdatedSession } from 'views/DashboardActions/SelectNewTime/useSelectNewTime';
import guestSessionLoginTokenUtils from 'views/guestSession/GuestSessionLogin/guestSessionLoginTokenUtils';

// ---------------- guestSessionLogin service
const guestSessionServices = {
  guestSessionLogin,
  guestSessionLogout,
  cancelGuestSession,
  validateGuestSession,
  createGuestCheckoutSession,
  updateSessionFromGuest,
};

export default guestSessionServices;

// guest session axios API client
function getGuestSessionAPIClient() {
  const loginToken = guestSessionLoginTokenUtils.getGuestSessionLoginToken();
  const axiosConfig = {
    baseURL: `${SERVER_API_BASE_URL.V1}/guest-session`,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${loginToken}`,
    },
  };
  const guestSessionAPIClient = axios.create(axiosConfig);
  // guestSessionAPIClient.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error.response) {
  //       const errorStatus = error.response.status;
  //       const errorData = error.response.data;
  //       console.log('GS errorStatus: ', errorStatus);
  //       console.log('GS errorData: ', errorData);
  //       if (errorData.isUnauthorizedSessionGuest) {
  //       }
  //     }
  //     return Promise.reject(error);
  //   }
  // );
  return guestSessionAPIClient;
}

type GuestSessionLoginResponseData = {
  isAuthenticated: boolean;
  loginToken: string;
};

type SessionLoginCredentials = Record<'email' | 'password', string>;

async function guestSessionLogin(
  sessionId: string,
  sessionloginCredentials: SessionLoginCredentials
) {
  const credentials = `${sessionloginCredentials.email}:${sessionloginCredentials.password}`;
  try {
    const { data } = await serverApi.v1.post<GuestSessionLoginResponseData>(
      `/guest-session/${sessionId}/login`,
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    // ✔️ setting guest session login token in local storage
    guestSessionLoginTokenUtils.setGuestSessionLoginToken(data.loginToken);
    return data;
  } catch (error) {
    throw error;
  }
}

async function guestSessionLogout(sessionId: string) {
  try {
    const { data: isLoggedOut } = await getGuestSessionAPIClient().delete<
      Record<'isLoggedOut', boolean>
    >(`/${sessionId}/logout`);
    return isLoggedOut;
  } catch (error) {
    throw error;
  }
}

async function updateSessionFromGuest(sessionId: string, updatedSession: UpdatedSession) {
  try {
    const res = getGuestSessionAPIClient().patch(`/${sessionId}/updateSession`, {
      updatedSession,
    });
    return res;
  } catch (error) {
    throw error;
  }
}

async function createGuestCheckoutSession(sessionId: string) {
  try {
    const { data: checkoutLink } = await getGuestSessionAPIClient().post<string>(
      `/${sessionId}/checkout-session`
    );
    return checkoutLink;
  } catch (error) {
    throw error;
  }
}

async function cancelGuestSession(sessionId: string) {
  try {
    await getGuestSessionAPIClient().patch(`/${sessionId}/cancel-session`);
  } catch (error) {
    throw error;
  }
}

async function validateGuestSession(sessionId: string) {
  try {
    const res = await getGuestSessionAPIClient().get(`/${sessionId}/validate`);
    return res;
  } catch (error) {
    throw error;
  }
}
