const guestSessionLoginTokenUtils = {
  setGuestSessionLoginToken,
  getGuestSessionLoginToken,
  removeGuestSessionLoginToken,
};

export default guestSessionLoginTokenUtils;

const GUEST_SESSION_LOGIN_TOKEN_NAME = 'GS_TOKEN';

function setGuestSessionLoginToken(value: string) {
  window.localStorage.setItem(GUEST_SESSION_LOGIN_TOKEN_NAME, value);
}

function getGuestSessionLoginToken() {
  return window.localStorage.getItem(GUEST_SESSION_LOGIN_TOKEN_NAME);
}
function removeGuestSessionLoginToken() {
  return window.localStorage.removeItem(GUEST_SESSION_LOGIN_TOKEN_NAME);
}
