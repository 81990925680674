import React from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  SxProps,
  TextFieldProps,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import IfElse from 'components/utilities/IfElse';
import GoogleAutoCompleteSuggestion from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleAutoCompleteSuggestion';
import useGoogleLocationAutoCompleteInput from 'components/utilities/GoogleLocationAutoCompleteInput/useGoogleLocationAutoCompleteInput';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';

export type AutoCompleteGuestLocationDetails = {
  suggestion: string;
  placeId: string;
  googleMapsLocationUrl: string;
} & GoogleLocationApiReturn;

export type GoogleLocationApiReturn = {
  address: string;
  location: {
    lat: number;
    lng: number;
  };
  googleMapsLocationUrl: string;
  countryISOCode?: string;
  stateISOCode?: string;
  country?: string;
  state?: string;
  city?: string;
  postalCode?: string;
};

export type GoogleLocationAutoCompleteInputProps = {
  getLocationDetails: (locationDetails: AutoCompleteGuestLocationDetails) => void;
  initialSuggestion?: string;
  className?: string;
  clearLocationValue?: boolean;
  isFromMeeting?: boolean;
  wrapperSx?: SxProps;
  textFieldProps?: TextFieldProps;
  handleClearInputBtnClick?: () => void;
  callbackOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const GoogleLocationAutoCompleteInput = ({
  initialSuggestion,
  className,
  clearLocationValue = false,
  isFromMeeting = false,
  wrapperSx,
  textFieldProps,
  getLocationDetails,
  handleClearInputBtnClick,
  callbackOnChange,
}: GoogleLocationAutoCompleteInputProps) => {
  const {
    ready,
    locationValue,
    suggestions,
    elementRef,
    handleClearInputBtnClickFromHook,
    handleSuggestionSelect,
    handleLocationChange,
    input: { isInputInFocus, setIsInputInFocus },
  } = useGoogleLocationAutoCompleteInput({
    initialSuggestion,
    className,
    clearLocationValue,
    getLocationDetails,
  });

  function handleClear() {
    handleClearInputBtnClickFromHook();
    handleClearInputBtnClick?.();
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    handleLocationChange(e);
    callbackOnChange?.(e);
  }

  return (
    <Box
      position='relative'
      sx={wrapperSx}
      ref={elementRef}
      onFocus={(e) => setIsInputInFocus(true)}
    >
      <TextFieldSyncWithTheme
        disabled={!ready}
        value={locationValue}
        fullWidth
        placeholder="Address or place"
        onChange={handleChange}
        InputProps={{
          fullWidth: true,
          endAdornment: (
            <InputAdornment position='end'>
            <IconButton onClick={handleClear} edge='end'>
              <CloseIcon fontSize='medium' />
            </IconButton>
          </InputAdornment>
          ),
        }}        
        {...textFieldProps}
      />      
      <GoogleSuggestions
        suggestions={suggestions}
        handleSuggestionSelect={handleSuggestionSelect}
        isInputInFocus={isInputInFocus}
      />
    </Box>
  );
};

export default GoogleLocationAutoCompleteInput;

type GoogleSuggestionProps = {
  suggestions: ReturnType<typeof useGoogleLocationAutoCompleteInput>['suggestions'];
  isInputInFocus: boolean;
  handleSuggestionSelect: ({
    description,
    place_id,
  }: google.maps.places.AutocompletePrediction) => Promise<void>;
  sx?: SxProps;
};

export function GoogleSuggestions({
  suggestions,
  isInputInFocus,
  handleSuggestionSelect,
  sx,
}: GoogleSuggestionProps) {
  return (
    <IfElse condition={suggestions.status === 'OK' && isInputInFocus}>
      <Box
        component='ul'
        className='ps-0'
        sx={{
          listStyleType: 'none',
          position: 'absolute',
          top: '100%',
          left: 0,
          right: 0,
          zIndex: '500',
          backgroundColor: 'common.white',
          ...sx,
        }}
      >
        {suggestions.loading && (
          <Typography sx={{ textAlign: 'center', my: 1 }}>
            <CircularProgress size={20} />
          </Typography>
        )}
        {suggestions.data.map((eachSuggesstion) => (
          <GoogleAutoCompleteSuggestion
            key={eachSuggesstion.place_id}
            suggestion={{ ...eachSuggesstion }}
          >
            {handleSuggestionSelect}
          </GoogleAutoCompleteSuggestion>
        ))}
      </Box>
    </IfElse>
  );
}

// NOTE: package's other utils
// const results = await getGeocode({ address: description });
// const geoCodeResult = results[0];
// const { place_id } = geoCodeResult;
// const { lat, lng } = getLatLng(geoCodeResult);
// const zipCode = getZipCode(geoCodeResult, false) ?? '';
// await getDetails({
//   placeId: place_id,
//   fields: ['name', 'rating'],
// });
// console.log({ place_id });
