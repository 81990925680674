import { useState, ChangeEvent } from 'react';
import uploadImageService from 'lib/services/uploadImageService';
import imgPlaceholder from 'assets/images/upload-pic.png';

type SignUpUserKeys =
  | 'name'
  | 'url'
  | 'title'
  | 'subTitle'
  | 'desc'
  | 'email'
  | 'img'
  | 'bgImage'
  | 'skills'
  | 'password';

export type BasicLoginUserDetails = { [key in SignUpUserKeys]: string } & {
  auth: boolean;
};

const BasicDetails = () => {
  const [logIn, setLogIn] = useState(false);
  const [, setShowImg] = useState(true);
  const [basicLoginUserDetails, setBasicLoginUserDetails] =
    useState<BasicLoginUserDetails>({
      auth: false,
      name: '',
      url: '',
      title: '',
      subTitle: 'Increase your profits with my advice',
      desc: '',
      email: '',
      img: '',
      bgImage: '',
      skills: '',
      password: '0000',
    });

  const handleImg = async (ev: ChangeEvent<HTMLInputElement>) => {
    let res = await uploadImageService.uploadImg(ev);
    let newUser = basicLoginUserDetails;
    newUser.img = res.secure_url;
    setShowImg(true);
    setBasicLoginUserDetails((prev) => ({ ...prev, img: res.secure_url }));
  };

  const handleChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name: key, value } = target;
    setBasicLoginUserDetails((prev) => ({ ...prev, [key]: value }));
  };

  const saveUser = () => {};

  // useEffect(() => {
  //   if (loggedInUser.name) {
  //     history.push(`/${loggedInUser.url}`);
  //   }
  // }, [history, loggedInUser]);

  return (
    <section className='basic-details'>
      <div className='container'>
        <div className='title'>
          <h1>Fill in some basic info</h1>
        </div>
        <div className='form-wrapper'>
          <form action=''>
            <img src={imgPlaceholder} alt='' />
            <input type='file' id='' className='upload-input' onChange={handleImg} />
            <div className='input-wrapper'>
              <input
                type='text'
                name='name'
                placeholder='Your full name'
                onChange={handleChange}
              />
            </div>
            <div className='input-wrapper'>
              <input
                type='text'
                name='email'
                placeholder='Your email'
                onChange={handleChange}
              />
            </div>
            {!logIn && (
              <div className='wrapper'>
                <div className='input-wrapper'>
                  <input
                    type='text'
                    name='url'
                    placeholder='Your url'
                    onChange={handleChange}
                  />
                </div>
                <div className='input-wrapper'>
                  <input
                    type='text'
                    name='title'
                    placeholder='Title'
                    onChange={handleChange}
                  />
                </div>
                <div className='input-wrapper'>
                  <textarea
                    name='desc'
                    id=''
                    cols={30}
                    rows={10}
                    placeholder='Description'
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            )}
          </form>
          <div className='btn-wrapper'>
            <button onClick={saveUser}>Next</button>
          </div>
        </div>
        <div className='btn-wrapper'>
          {!logIn && (
            <button onClick={() => setLogIn(true)}>Do you have an account?</button>
          )}
          {logIn && (
            <button onClick={() => setLogIn(false)}>Do not have an account?</button>
          )}
        </div>
      </div>
    </section>
  );
};

export default BasicDetails;
