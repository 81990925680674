import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { inputBgColor } from 'themes/AppThemeProvider/light';
import { SxProps } from '@mui/system';

interface TextFieldSyncWithThemeProps extends Omit<TextFieldProps, 'sx'> {
  borderColor?: string;
  textColor?: string;
  sx?: SxProps<any>; // Change this to be more flexible
}

export const customTextFieldPadding = {
  xs: '0.8rem 1rem',
  sm: '0.9rem 1rem',
  md: '1rem 1.25rem',
};

const TextFieldSyncWithTheme = React.forwardRef<HTMLDivElement, TextFieldSyncWithThemeProps>(
  ({ borderColor, textColor, sx, ...props }, ref) => {
    const theme = useTheme();
    const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

    return (
      <TextField
        margin="dense"
        color="primary"
        size="small"
        inputRef={ref}
        FormHelperTextProps={{ sx: { fontSize: '1.6rem' } }}
        {...props}
        sx={{
          '& .MuiInputBase-root': {
            background: `${inputBgColor} !important`,
            color: textColor ? `${textColor} !important` : undefined,
            borderColor: '#cbd6e2',
            fontWeight: 600,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor || 'inherit',
            },
            '& .MuiInputBase-input': {
              fontSize: '1.6rem',
              padding: { xs: customTextFieldPadding.xs, md: customTextFieldPadding.md },
            },
            '& textarea': {
              fontSize: '1.6rem',
              padding: '0 !important',
            },
          },
          '& .Mui-focused:not(p)': {
            borderWidth: 0,
            borderColor: theme.palette.primary.main,
            boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
          },
          '& input::placeholder, & textarea::placeholder, & select::placeholder': {
            fontSize: '1.6rem !important',
            color: '#7d98b6',
            opacity: 1,
          },
          ...(sx as any),
        }}
      />
    );
  }
);

TextFieldSyncWithTheme.displayName = 'TextFieldSyncWithTheme';

export default TextFieldSyncWithTheme;