import { Slide } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import {
  initialStepper,
  StepperState,
} from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import { FINAL_STEP_AI } from '../stepperComponents/styled-components/Frame';
import ProfileBackgroundMedia from '../stepperComponents/ProfileBackgroundMedia/ProfileBackgroundMedia';
import OnboardingSocialLinks from '../stepperComponents/OnboardingSocialLinks';
import BusinessInfoAndDomainSetup from '../stepperComponents/BusinessInfoAndDomainSetup';
import StepperIncDecrementBtns from './StepperIncDecrementBtns';
import UserPreferencesAndSettings from '../stepperComponents/UserPreferencesAndSettings';
import OnboardingLinearProgressBar from '../stepperComponents/OnboardingLinearProgressBar';
import { gradientBgColor } from 'themes/AppThemeProvider/light';

export const onboardingImgForDifferentSteps = {
  0: 'https://res.cloudinary.com/salesfronts/image/upload/v1724318511/Onboarding-pics/transparent/onboarding_step1-removebg-preview.png',
  1: 'https://res.cloudinary.com/salesfronts/image/upload/v1724302650/Onboarding-pics/transparent/onboarding_step2-removebg-preview.png',
  2: 'https://res.cloudinary.com/salesfronts/image/upload/v1724302650/Onboarding-pics/transparent/onboarding_step3-removebg-preview.png',
  3: 'https://res.cloudinary.com/salesfronts/image/upload/v1724302650/Onboarding-pics/transparent/onboarding_step4-removebg-preview.png',
};

const OnboardingFlowStepper = () => {
  const { values } = useFormikContext<StepperState>();
  const step = values.step;
  const progressCount =
    ((step - initialStepper.step) / (FINAL_STEP_AI - initialStepper.step)) * 100;
  const [hideProgress, setHideProgress] = useState(false);
  const isFormFinalStep = values.step === FINAL_STEP_AI;

  let renderedComponent;

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;
    if (step > FINAL_STEP_AI) {
      timeOutId = setTimeout(() => setHideProgress(true), 1000);
    }
    return () => clearTimeout(timeOutId);
  }, [step]);

  if (step === 1) {
    renderedComponent = <BusinessInfoAndDomainSetup />; // Start from here
  } else if (step === 2) {
    renderedComponent = <ProfileBackgroundMedia />;
  } else if (step === 3) {
    renderedComponent = <UserPreferencesAndSettings />;
  } else if (step === 4) {
    renderedComponent = <OnboardingSocialLinks />;
  } else {
    renderedComponent = (
      <Alert severity='error'>
        Something went wrong with Signup, Please try again later
      </Alert>
    );
  }

  return (
    <Box sx={{
      background: gradientBgColor,
      minHeight: '100vh',
    }}>
      {!hideProgress && (
        <OnboardingLinearProgressBar
          color='primary'
          value={progressCount}
          step={step}
          FINAL_STEP_AI={FINAL_STEP_AI}
        />
      )}
      <Slide
        appear={true}
        key={step} // Keep the key to force re-render
        direction={values.slideDirection}
        in={true}
        mountOnEnter
        unmountOnExit
        timeout={500}
      >
        <Stack
          justifyContent='center'
          alignItems='center'
        >
          {renderedComponent}
        </Stack>
      </Slide>
      <StepperIncDecrementBtns isFormFinalStep={isFormFinalStep} />
    </Box>
  );
};

export default OnboardingFlowStepper;