import { SxProps, Theme } from '@mui/material';

export type styleType = (params?: any) => SxProps<Theme>;
export const EmailLinkedUserAccount_bodyWrapper_width = 500;
const padding = 16;
export const style_EmailLinkedUserAccount_container: styleType = () => {
  return {
    display: { xs: 'block', md: 'grid' },
    placeItems: 'center',
    minHeight: '100vh',
    px: { xs: 0, md: '16px' },
  };
};

export const style_EmailLinkedUserAccount_bodyWrapper: styleType = () => {
  return {
    py: {
      xs: 3,
      sm: `${padding}px`,
    },
    width: {
      xs: '100%',
      md: `${EmailLinkedUserAccount_bodyWrapper_width}px`,
    },
    mx: { xs: 0, md: 'auto' },
    bgcolor: 'background.paper',
    height: { xs: '100%', md: '80%' },
    maxHeight: { xs: '100%', md: '600px' },
    borderRadius: (theme) => ({ xs: 0, md: theme.shape.borderRadius }),
    boxShadow: (theme) => ({ xs: 'none', md: theme.shadows[3] }),
    overflow: { xs: 'auto', md: 'hidden' },
    position: 'relative',
  };
};

export const style_EmailLinkedUserAccount_progressWrapper: styleType = () => {
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  };
};

export const style_EmailLinkedUserAccount_chooseAccountWrapper: styleType = () => {
  return {
    pb: { xs: 1, md: 1 },
    justifyContent: 'center',
    gap: { xs: 1, md: 2 },
  };
};

export const style_EmailLinkedUserAccount_UserAccountsList: styleType = () => {
  return {
    position: 'relative',
    overflowY: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: '#888 #f1f1f1',
    height: `calc(100% - ${padding * 2}px)`,
  };
};

export const style_AddAnotherAccount_add_another_account_wrapper: styleType = (sx) => {
  return {    
    '& .MuiListItemText-root': {
      height: '100%',
    },
    '& .MuiButtonBase-root': {
      pl: `${padding * 2}px`,
    },
    ...sx,
  };
};
