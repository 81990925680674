import React from 'react';
import Frame from './styled-components/Frame';
import { Stack } from '@mui/material';
import SelectLanguage from './SelectLanguage';
import TimezoneOnboarding from './TimezoneOnboarding';
import InputPhoneNumber from './InputPhoneNumber';
import InputAddress from './InputAddress';
import { onboardingImgForDifferentSteps } from '../OnboardingFlowStepper/OnboardingFlowStepper';
import useGetTransformedImgOnboardingSrc from 'views/OnboardingFlow/hooks/useGetTransfromedOnboardingSrc';

const UserPreferencesAndSettings = () => {
  const imgSrc = useGetTransformedImgOnboardingSrc(onboardingImgForDifferentSteps[2]);
  
  return (
    <Frame imgSrc={imgSrc} containerSx={{mb: 10}}>
      <Stack gap={{ xs: 4, md: 7.5 }} mb={2} direction='column'>
        <SelectLanguage />
        <TimezoneOnboarding />
        <InputPhoneNumber />
        <InputAddress />
      </Stack>
    </Frame>
  );
};

export default UserPreferencesAndSettings;
