import {
  Box,
  Paper,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';


export function UserSignupLoginInterfaceWrapper({
  sx = {},
  children,
}: {
  sx?: SxProps;
  children: React.ReactNode;
}) {
  return (
    <Stack
      component={Paper}
      justifyContent={'center'}
      alignItems='stetch'
      sx={{
        gap: 1,
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
        borderRadius: theme => theme.shape.borderRadius,
        maxWidth: { xs: '100%', md: '800px' },
        '& legend, & label': {
          color: '#000',
        },
        p: 4,
        minWidth: {
          xs: 300,
          md: 400,
        },
        width: {
          xs: '90%',
          md: 'auto',
        },
        ...sx,
      }}
    >
      {' '}
      <Box mb={4} alignSelf={'center'}>
        <Typography
          component='img'
          src={`${process.env.PUBLIC_URL}/fronts-ai.png`}
          alt='logo'
          sx={{
            objectFit: 'contain',
            maxWidth: 150,
            margin: 'auto',
          }}
        />
      </Box>
      {children}
    </Stack>
  );
}
