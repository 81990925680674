import {
  FormControl,
  Select,
  MenuItem,
  SxProps,
  FormLabel,
  alpha,
  useTheme,
} from '@mui/material';
import { ICity, ICountry, IState } from 'country-state-city';
import { inputBgColor } from 'themes/AppThemeProvider/light';

type T = ICountry | IState | ICity;

type RegionSelectorProps = {
  label: string;
  options: T[];
  selectedValue: string;
  name: 'country' | 'state' | 'city';
  selecteSx?: SxProps;
  onChange: (value: string, name: 'country' | 'state' | 'city') => void;
};

const RegionSelector = ({
  label,
  options,
  selectedValue,
  onChange,
  name,
  selecteSx,
}: RegionSelectorProps) => {
  const theme = useTheme();
  const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

  return (
    <FormControl>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        name={name}
        sx={{
          background: `${inputBgColor} !important`,
          borderColor: '#cbd6e2',
          minWidth: '150px',
          minHeight: '45px',
          '& .MuiSelect-select': {
            py: '4px',
          },
          // Normal mode
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#cbd6e2',
          },
          // Hover mode
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
          },
          // Focused mode
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
          },
          '&.Mui-focused': {
            backgroundColor: alpha(theme.palette.primary.light, 0.05),
            boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
          },
          ...selecteSx,
        }}
        value={selectedValue || ''}
        onChange={(e) => onChange(e.target.value as string, name)}
      >
        {options.map((option, i) => {
          const is_not_ICity_type = option.hasOwnProperty('isoCode');
          return (
            <MenuItem
              key={i}
              value={
                is_not_ICity_type ? (option as Exclude<T, ICity>).isoCode : option.name
              }
              sx={{
                background: `${inputBgColor} !important`,
              }}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default RegionSelector;
