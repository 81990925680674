import * as React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogContentProps,
  DialogTitle,
  DialogTitleProps,
  Divider,
  Slide,
  IconButton,
  DialogActionsProps,
  DialogActions,
  SxProps,
  Typography,
  Stack,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const dialogPadding: SxProps = {
  padding: { xs: '16px 16px', md: '16px 24px' },
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' timeout={1000} ref={ref} {...props} unmountOnExit />;
});

export default function Popup({
  setOpen,
  children,
  dialogProps,
  title,
  titleProps,
  content,
  contentProps,
  showCloseIcon,
  dialogActions,
  dialogActionsProps,
  onClose,
  textToShowWithCloseIcon,
}: {
  dialogProps: DialogProps;
  children?: React.ReactNode;
  title?: React.ReactNode;
  titleProps?: DialogTitleProps;
  content?: React.ReactNode;
  contentProps?: DialogContentProps;
  showCloseIcon?: boolean;
  dialogActions?: React.ReactNode;
  dialogActionsProps?: DialogActionsProps;
  textToShowWithCloseIcon?: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
}) {
  
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(false);
    onClose?.();
  };

  return (
    <React.Fragment>
      <Dialog
        PaperProps={
          showCloseIcon
            ? {
                sx: { position: 'relative' },
              }
            : {}
        }
        {...dialogProps}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        {showCloseIcon && (
          <Stack
            direction='row'
            justifyContent={'space-between'}
            alignItems={'center'}            
            position='relative'
            sx={{
              pl: 2,
              position: 'sticky',
              top: 0,
              right: 0,
              bgcolor: 'background.paper',
              zIndex: 2,              
              boxShadow: (theme) => `0 1px 2px ${theme.palette.divider}`,
            }}
          >
            {textToShowWithCloseIcon && (
              <Typography variant='h6' display={'inline-block'} marginRight={'auto'}>
                {textToShowWithCloseIcon}
              </Typography>
            )}
            <IconButton
              sx={{
                cursor: 'pointer',
                marginLeft: 'auto',
                display: 'inline-block',
                mr: 1.25,
                borderRadius: 'none',
              }}              
              color='error'
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        )}

        {title && (
          <DialogTitle
            sx={{ fontSize: { xs: 20, md: 20 }, fontWeight: 600, ...dialogPadding }}
            {...titleProps}
          >
            {title}
            <Divider />
          </DialogTitle>
        )}
        {content && (
          <DialogContent sx={dialogPadding} {...contentProps}>
            {content}
          </DialogContent>
        )}
        {children}
        {dialogActions && (
          <DialogActions sx={dialogPadding} {...dialogActionsProps}>
            {dialogActions}
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}
