import { createContext, useContext } from 'react';
import { PortfolioItemType, initialPortfolioItem } from 'types/portfolio';

type PortfolioContextType = {
  portfolioLocalState: PortfolioItemType;
  setPortfolioLocalState: React.Dispatch<React.SetStateAction<PortfolioItemType>>;
  portfolioIndex: number;
  setPortfolioIndex: React.Dispatch<React.SetStateAction<number>>;
  openEditDialog: boolean;
  setOpenEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PortfolioContext = createContext<PortfolioContextType>({  
  portfolioLocalState: initialPortfolioItem,
  portfolioIndex: -1,
  openEditDialog: false,
  setPortfolioLocalState: () => {},
  setPortfolioIndex: () => {},
  setOpenEditDialog: () => {},
});

const PortfolioContextProvider = ({
  children,

  portfolioIndex,
  setPortfolioIndex,

  openEditDialog,
  setOpenEditDialog,

  portfolioLocalState,
  setPortfolioLocalState,
}: {
  children: React.ReactNode;
} & PortfolioContextType) => {
  return (

    <PortfolioContext.Provider
    value={{
        portfolioIndex,
        setPortfolioIndex,
        openEditDialog,
        setOpenEditDialog,
        portfolioLocalState,
        setPortfolioLocalState,
      }}
      >
      {children}
    </PortfolioContext.Provider>
  );
};

export default PortfolioContextProvider;

export const usePortfolioContext = () => {
  const context = useContext(PortfolioContext);
  
  if (!context) {
    throw new Error('usePortfolioContext must be used within a PortfolioContextProvider');
  }
  return context;
};
