import { Alert, AlertTitle, Box, CircularProgress, Stack, Typography } from '@mui/material';

import IfElse from 'components/utilities/IfElse';
import useStripeOnboardingProcess from './hooks/useStripeOnboardingProcess';
import ChooseStripeCountryAndCurrencyPopup from 'Popups/ChooseStripeCountryAndCurrencyPopup';
import ThreeDotsAnimationText from 'components/utilities/ThreeDotsAnimationText';
import PremiumSubscriptionPopup from 'components/utilities/Popups/PremiumSubscriptionPopup';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import { subscriptionDashboardURL } from 'components/dashboard/FrontsAIPremium/FrontsAIPremium';

const StripeOnBoardingProcessBtn = () => {
  const {
    openPremiumPopup,
    setPremiumPopup,
    handleOnboardProcess,
    onboardingProcessPromiseState: { isLoading, isError, errorMsg },
    isCountryAndCurrencyNotSelected,
    setIsCountryAndCurrencyNotSelected,
    cachedLoggedInUser,
  } = useStripeOnboardingProcess();
  const isCurrencyAndCountryNotSelected =
    !cachedLoggedInUser?.countryISOCode && !cachedLoggedInUser?.currency;

  const alert = isCurrencyAndCountryNotSelected && (
    <Alert variant='standard' severity='error' sx={{ '& .MuiAlert-message': { padding: 0 } }}>
      <AlertTitle>You haven't selected you country and currency</AlertTitle>
      Please select your country and currency to receive payments securely.
    </Alert>
  );

  return (
    <Stack gap={2} paddingY='0' direction={'row'} flexWrap={'wrap'}>
      {isCountryAndCurrencyNotSelected && (
        <ChooseStripeCountryAndCurrencyPopup
          handleOnboardProcess={handleOnboardProcess}
          isCountryAndCurrencyNotSelected={isCountryAndCurrencyNotSelected}
          setIsCountryAndCurrencyNotSelected={setIsCountryAndCurrencyNotSelected}
          title='Setup Stripe payment'
        />
      )}
      <Box sx={{ flexBasis: '100%' }}>{alert}</Box>
      {isCurrencyAndCountryNotSelected && (
        <CustomButton
          isLoading={isLoading}
          handleOnboardProcess={handleOnboardProcess}
          textContent='Select your country and currency'
        />
      )}

      <PremiumSubscriptionPopup
        open={openPremiumPopup}
        setOpen={setPremiumPopup}
        fullScreen={false}
        title='You have to be a Premium user to setup payment.'
      />

      <CustomButton
        isLoading={isLoading}
        disable={isCurrencyAndCountryNotSelected}
        handleOnboardProcess={handleOnboardProcess}
        textContent='Complete Onboarding Process'
      />

      {/* ----------- error alert */}
      <IfElse condition={isError}>
        <Alert
          severity='error'
          sx={{
            maxWidth: 'fit-content',
            '& .MuiAlert-message': { padding: 0 },
            flexBasis: '100%',
          }}
        >
          {errorMsg}
        </Alert>
      </IfElse>
    </Stack>
  );
};

export default StripeOnBoardingProcessBtn;

function CustomButton({
  isLoading,
  handleOnboardProcess,
  textContent,
  disable,
}: {
  isLoading: boolean;
  handleOnboardProcess: (path: string) => void;
  textContent: string;
  disable?: boolean;
}) {
  return (
    <Box>
      <ResponsiveButton
        disabled={isLoading || disable}
        variant='contained'
        onClick={() => handleOnboardProcess(subscriptionDashboardURL)}                
      >
        <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1.5}>
          <Box component='span'>{textContent}</Box>
          <IfElse condition={isLoading}>
            <CircularProgress size={15} sx={{ color: 'common.white' }} />
          </IfElse>
        </Stack>
      </ResponsiveButton>
      {isLoading && (
        <ThreeDotsAnimationText typographySx={{ textAlign: 'left' }}>
          <Typography
            color='info.main'
            sx={{ fontSize: { xs: '1.4rem', md: '1.4rem' } }}
            component={'span'}
          >
            This can take some time
          </Typography>
        </ThreeDotsAnimationText>
      )}
    </Box>
  );
}
