function analyzeImageAndSuggestTextColor(input: File | string): Promise<string> {
  return new Promise((resolve, reject) => {
    const file = input;

    if (file) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      if (typeof file === 'string') {
        img.src = file;
      } else {
        img.src = URL.createObjectURL(file);
      }

      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);

        // Get the pixel color data
        const pixelData = ctx?.getImageData(0, 0, canvas.width, canvas.height).data;

        if (!pixelData) {
          reject('Could not get pixel data');
          return;
        }

        let totalRed = 0;
        let totalGreen = 0;
        let totalBlue = 0;

        // Calculate the average color in the image
        for (let i = 0; i < pixelData.length; i += 4) {
          totalRed += pixelData[i];
          totalGreen += pixelData[i + 1];
          totalBlue += pixelData[i + 2];
        }

        const averageRed = Math.floor(totalRed / (pixelData.length / 4));
        const averageGreen = Math.floor(totalGreen / (pixelData.length / 4));
        const averageBlue = Math.floor(totalBlue / (pixelData.length / 4));

        // Calculate the brightness of the average color
        const brightness =
          (averageRed * 299 + averageGreen * 587 + averageBlue * 114) / 1000;

        // Suggest text color based on brightness
        const textColor = brightness > 128 ? '#000000' : '#ffffff';
        resolve(textColor);
      };
    } else {
      reject('No file provided');
    }
  });
}

export default analyzeImageAndSuggestTextColor;

export function rgbToHex(r: number, g: number, b: number) {
  return (
    '#' +
    [r, g, b]
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      })
      .join('')
  );
}
// Define a type for RGB color
type RGBColor = {
  r: number;
  g: number;
  b: number;
};

// Function to analyze the image and set background and text color
export function analyzeImage(imageUrl: string) {
  const img: HTMLImageElement = new Image();
  img.crossOrigin = 'Anonymous'; // Enable cross-origin resource sharing (CORS)
  img.src = imageUrl;
  let textAndBgColor = {
    color: 'black',
    backgroundColor: 'white',
  };

  // Function to extract RGB values from a color string
  function extractRGBFromColor(color: string): RGBColor {
    const rgbArray: number[] = color.match(/\d+/g)?.map(Number) || [0, 0, 0];

    return {
      r: rgbArray[0],
      g: rgbArray[1],
      b: rgbArray[2],
    };
  }

  img.onload = function () {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

    if (!ctx) {
      console.error('Unable to get 2D context');
      return;
    }

    canvas.width = img.width;
    canvas.height = img.height;

    ctx.drawImage(img, 0, 0);

    const imageData: Uint8ClampedArray = ctx.getImageData(
      0,
      0,
      canvas.width,
      canvas.height
    ).data;

    // Analyze colors and get the dominant color
    const colorCounts: Record<string, number> = {};
    let maxColor: string = '';
    let maxCount: number = 0;

    for (let i = 0; i < imageData.length; i += 4) {
      const color: string = `rgb(${imageData[i]}, ${imageData[i + 1]}, ${
        imageData[i + 2]
      })`;
      colorCounts[color] = (colorCounts[color] || 0) + 1;

      if (colorCounts[color] > maxCount) {
        maxColor = color;
        maxCount = colorCounts[color];
      }
    }

    // Choose text color based on the perceived brightness of the background color
    const rgb: RGBColor = extractRGBFromColor(maxColor);
    const brightness: number = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    const textColor: string = brightness > 128 ? 'black' : 'white';
    textAndBgColor.color = textColor;
    textAndBgColor.backgroundColor = maxColor;
  };

  return textAndBgColor;
}

export function darkenColor(hex: string, percent: number) {
  // Parse the hexadecimal color code
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Darken the color
  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  // Ensure the values are within the valid range
  r = r < 0 ? 0 : r > 255 ? 255 : r;
  g = g < 0 ? 0 : g > 255 ? 255 : g;
  b = b < 0 ? 0 : b > 255 ? 255 : b;

  // Convert the RGB components back to hexadecimal
  let darkenedHex =
    '#' +
    (r < 16 ? '0' : '') +
    r.toString(16) +
    (g < 16 ? '0' : '') +
    g.toString(16) +
    (b < 16 ? '0' : '') +
    b.toString(16);

  return darkenedHex;
}

export function lightenColor(hex: string, percent: number) {
  // Remove the '#' if it's there
  hex = hex.replace(/^#/, '');

  // Parse the hex value to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Lighten each component by the given percentage
  r = Math.floor(((255 - r) * percent) / 100) + r;
  g = Math.floor(((255 - g) * percent) / 100) + g;
  b = Math.floor(((255 - b) * percent) / 100) + b;

  // Convert back to hex
  let lighterHex = '#' + (r * 65536 + g * 256 + b).toString(16).padStart(6, '0');

  return lighterHex;
}

export function checkImageTransparency(input: File | string): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const file = input;

    if (file) {
      let img = new Image();      
      img.crossOrigin = 'anonymous';

      if (typeof file === 'string') {
        img.src = file;
      } else {
        img.src = URL.createObjectURL(file);
      }

      img.onload = function () {
        let canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(img, 0, 0);
          let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          let data = imageData.data;
          let hasTransparency = false;
          for (let i = 3; i < data.length; i += 4) {
            if (data[i] < 255) {
              hasTransparency = true;
              break;
            }
          }
          resolve(hasTransparency);
        }
        img.src = 'your_image_url.png';
      };

      img.onerror = function () {
        reject('Error loading image');
      };
    }
  });
}

export function getContrast(hexcolor: string) {
  // Convert hex color to RGB
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);
  
  // Calculate the relative luminance
  if(0.299 * r + 0.587 * g + 0.114 * b > 186) {
    return '#000000';
  } else {
    return '#ffffff';
  }
}
