import { Typography, useMediaQuery } from '@mui/material';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';

const WavesLayout9 = () => {
  const isPhone = useMediaQuery('(max-width: 600px)');
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { currentTheme } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      currentTheme: themes?.[localCurrentThemeIndex] || initialThemeState,
    }),
  });
  const waveFillColor = cachedLoggedInUser?.subTitle
    ? currentTheme?.profile?.subtitle?.background
    : '#ffffff';

  return (
    <Typography
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        overflow: 'hidden',
        lineHeight: 0,
        '& svg': {
          position: 'relative',
          display: 'block',
          width: 'calc(100% + 1.3px)',
          height: 53,
          transform: 'rotateY(180deg)',
        },
        '& .shape-fill': {
          fill: waveFillColor,
        },
      }}
    >
      <svg
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox={`0 0 1200 ${isPhone ? 116 : 119}`}
        preserveAspectRatio='none'
      >
        <path
          d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
          className='shape-fill'
        ></path>
      </svg>
    </Typography>
  );
};

export default WavesLayout9;
