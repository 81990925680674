import {
  Button,
  ButtonProps,
  Stack,
  TextFieldProps,
} from '@mui/material';
import TextFieldSyncWithTheme from './TextFieldSyncWithTheme';

type ThisProps = {
  buttonProps?: ButtonProps;
  textFieldsProps?: TextFieldProps;
  containerWrapperProps?: any; // FIXME - Need to fix this any type
  buttonLabel: string;
};

const InputWithButton = ({
  buttonProps = {},
  textFieldsProps,
  buttonLabel,
  containerWrapperProps = {},  
}: ThisProps) => {
  return (
    <Stack
      {...containerWrapperProps}
      sx={{
        '& .MuiInputBase-root': {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          height: { xs: 39, md: 43 },
        },
        '& .MuiFormControl-root': {
          m: 0,
        },
        ...containerWrapperProps.sx,
      }}
      alignItems={'center'}
      direction='row'
    >
      <TextFieldSyncWithTheme
        autoComplete='off'
        fullWidth
        placeholder={'Add website URL'}        
        FormHelperTextProps={{
          sx: {
            fontSize: '80%',
          },
        }}
        {...textFieldsProps}
      />
      <Button
        variant='outlined'
        color='primary'
        {...buttonProps}
        sx={{
          height: { xs: 39, md: 43 },
          boxShadow: 'none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          ...buttonProps.sx,
        }}
      >
        {buttonLabel}
      </Button>
    </Stack>
  );
};

export default InputWithButton;
