type CustomLoaderStyle = {
  [key: string]: string;
};

type BootstrapLoaderProps = {
  customLoaderStyle?: CustomLoaderStyle;
  classnames?: string;
};

const BootstrapLoader = ({
  customLoaderStyle,
  classnames,
}: BootstrapLoaderProps) => {
  return (
    <div
      className={`spinner-border text-dark-black ${classnames}`}
      style={customLoaderStyle}
    ></div>
  );
};

export default BootstrapLoader;
