type MinsHrsObj = {
  hrs: number;
  mins: number;
};

type Time = Record<'secs' | 'mins' | 'hrs' | 'days', number>;

export function convertMinsToHrsMins(timeInMins: number): MinsHrsObj {
  const tempHrs = Number((timeInMins / 60).toPrecision(2));
  const hrs = Math.trunc(tempHrs);
  const mins = Math.round((tempHrs - hrs) * 60);
  return {
    hrs,
    mins,
  };
}

export function convertToMinutes(tempHrs: string, tempMins: string): number {
  const hrsToMins = Number(tempHrs) * 60;
  const mins = Number(tempMins);
  return hrsToMins + mins;
}

export function msToMinsHrs(milliseconds: number): MinsHrsObj {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = seconds >= 30 ? minutes + 1 : minutes;
  minutes = minutes % 60;
  // hours = hours % 24;
  return {
    hrs: hours,
    mins: minutes,
  };
}

export function mstoMinsHrsWithText(milliseconds: number) {
  const { hrs, mins } = msToMinsHrs(milliseconds);
  const HOURS_TEXT = hrs > 1 ? 'hrs' : 'hr';
  const MINUTES_TEXT = mins > 1 ? 'mins' : 'min';
  const hours = !!hrs && `${hrs} ${HOURS_TEXT}`;
  const minutes = !!mins && `${mins} ${MINUTES_TEXT}`;
  return {
    hrs,
    mins,
    hrsWithText: hours || '',
    minsWithText: minutes || '',
  };
}

export function msToTime(milliseconds: number): Time {
  let seconds = Number((milliseconds / 1000).toFixed(1));
  let minutes = Number((milliseconds / (1000 * 60)).toFixed(1));
  let hours = Number((milliseconds / (1000 * 60 * 60)).toFixed(1));
  let days = Number((milliseconds / (1000 * 60 * 60 * 24)).toFixed(1));

  return {
    secs: seconds,
    mins: minutes,
    hrs: hours,
    days,
  };
}
