import { Box, Divider, Slider, Stack, SxProps, Typography } from '@mui/material';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import objectFitContain from 'assets/icons/portfolio-icons/image_fit_contain.svg';
import objectFitCover from 'assets/icons/portfolio-icons/image_fit_cover.svg';
import imageCircle from 'assets/icons/portfolio-icons/image_circle.svg';
import imageRounded from 'assets/icons/portfolio-icons/image_rounded.svg';
import imageSquare from 'assets/icons/portfolio-icons/image_square.svg';

import {
  DrawerButton,
  DrawerSecondaryHeading,
  DrawerSectionHeading,
} from 'components/Drawer/DrawerResuableComponent';
import SPMSortDrawer from 'components/MUI_SPM/SPMSortDrawer/SPMSortDrawer';
import {
  handleError,
  imageBorderRadius,
} from 'components/Portfolios/sub-components/PortfolioDrawer';
import { PopupHeading } from 'components/dashboard/DashboardReusableComponent';
import EditDrawer from 'components/utilities/EditDrawer';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  useGetSortedSPMByEditorIdQuery,
  useUpdateSPMSortedByEditorIdMutation,
} from 'store/rtkqFeatures/api/spmSportedExtendedApi';

const storeDrawerContainer: SxProps = {
  px: 2,
  py: 2,
  position: 'relative',
  height: '100%',
};

// Main Component
const StoreDrawer = () => {
  return (
    <EditDrawer
      drawerWidth={250}
      buttonProps={{
        color: 'primary',
        sx: { display: 'flex', marginLeft: 'auto' },
      }}
    >
      <Box sx={storeDrawerContainer}>
        <PopupHeading variant='h6'>Store</PopupHeading>
        <Divider />
        <Stack direction={'column'} spacing={{ xs: 2, md: 4 }} mt={2} useFlexGap>
          <ImageDisplayOptions />
          <Divider />
          <ImageFrame />
          <Divider />
          <HeightSlider />
          <Divider />
          <ReOrderItems />
        </Stack>
      </Box>
    </EditDrawer>
  );
};

export default StoreDrawer;

// Additional Components
function ImageDisplayOptions() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data: sortedSPM } = useGetSortedSPMByEditorIdQuery(
    cachedLoggedInUser?._id || ''
  );
  const [updateSPMSorted, { isLoading, isError }] =
    useUpdateSPMSortedByEditorIdMutation();
  const storeItemImageFit = sortedSPM?.style?.image?.objectFit || 'contain';

  interface ButtonList {
    svg: string;
    objectFit: React.CSSProperties['objectFit'];
  }
  const buttonList: ButtonList[] = [
    { svg: objectFitContain, objectFit: 'contain' },
    { svg: objectFitCover, objectFit: 'cover' },
  ];

  async function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    objectFit: React.CSSProperties['objectFit']
  ) {
    e.preventDefault();
    if (sortedSPM) {
      await updateSPMSorted({
        editorId: cachedLoggedInUser?._id || '',
        sortedSpm: {
          ...(sortedSPM || {}),
          style: {
            ...sortedSPM?.style,
            image: {
              ...(sortedSPM?.style?.image || {}),
              objectFit,
            },
          },
        },
      });
    }
  }

  handleError(isError, 'Error while updating image display options');

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack
        direction={'row'}
        spacing={0.5}
        alignItems='center'
        justifyContent={'space-between'}
      >
        <DrawerSectionHeading>Image fit</DrawerSectionHeading>
        <Stack direction={'row'} useFlexGap spacing={0} justifySelf={'flex-end'}>
          {buttonList.map(({ svg, objectFit }, index) => (
            <DrawerButton
              key={index}
              onClick={(e) => handleClick(e, objectFit)}
              src={svg}
              selected={storeItemImageFit === objectFit}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
}

function ImageFrame() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data: sortedSPM } = useGetSortedSPMByEditorIdQuery(
    cachedLoggedInUser?._id || ''
  );
  const [updateSPMSorted, { isLoading, isError }] =
    useUpdateSPMSortedByEditorIdMutation();
  const storeItemImageFrame = sortedSPM?.style?.image?.borderRadius || 0;

  interface ButtonList {
    svg: string;
    borderRadius: React.CSSProperties['borderRadius'];
    clipPath?: React.CSSProperties['clipPath'];
  }
  const buttonList: ButtonList[] = [
    { svg: imageRounded, borderRadius: imageBorderRadius.rounded },
    { svg: imageCircle, clipPath: 'circle()', borderRadius: imageBorderRadius.circle },
    { svg: imageSquare, borderRadius: imageBorderRadius.square },
  ];

  async function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    borderRadius: React.CSSProperties['borderRadius'],
    clipPath?: React.CSSProperties['clipPath']
  ) {
    e.preventDefault();
    if (sortedSPM) {
      await updateSPMSorted({
        editorId: cachedLoggedInUser?._id || '',
        sortedSpm: {
          ...(sortedSPM || {}),
          style: {
            ...sortedSPM?.style,
            image: {
              ...(sortedSPM?.style?.image || {}),
              borderRadius,
              clipPath,
            },
          },
        },
      });
    }
  }

  handleError(isError, 'Error while updating image frame');

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack
        direction={'row'}
        spacing={0.5}
        alignItems='center'
        justifyContent={'space-between'}
        flexWrap='wrap'
      >
        <DrawerSectionHeading>Image Frame</DrawerSectionHeading>
        <Stack direction={'row'} useFlexGap spacing={0}>
          {buttonList.map(({ svg, borderRadius, clipPath }, index) => (
            <DrawerButton
              key={index}
              src={svg}
              onClick={(e) => handleClick(e, borderRadius, clipPath)}
              selected={storeItemImageFrame === borderRadius}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
}

function HeightSlider() {
  const [height, setHeight] = useState(100);
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data: spmSorted } = useGetSortedSPMByEditorIdQuery(
    cachedLoggedInUser?._id || ''
  );
  const [updateSPMSorted, { isLoading, isError }] =
    useUpdateSPMSortedByEditorIdMutation();

  useEffect(() => {
    if (spmSorted?.style?.image?.height || 0) {
      const height = Number(spmSorted?.style?.image?.height) || 350;
      const newHeight = isNaN(height) ? 350 : height / 3.5;
      setHeight(newHeight);
    }
  }, [spmSorted?.style?.image?.height]);

  const debounceAPI = useCallback(
    debounce(async (heightInPercentage: number) => {
      if (spmSorted) {
        await updateSPMSorted({
          editorId: cachedLoggedInUser?._id || '',
          sortedSpm: {
            ...(spmSorted || {}),
            style: {
              ...spmSorted?.style,
              image: {
                ...(spmSorted?.style?.image || {}),
                height: heightInPercentage * 3.5,
              },
            },
          },
        });
      }
    }, 500),
    [spmSorted, updateSPMSorted]
  );

  function handleChange(event: Event, newValue: number | number[]) {
    const height = newValue as number;
    setHeight(height);
    debounceAPI(newValue as number);
  }

  handleError(isError, 'Error while updating image height');

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems='center'
      justifyContent={'space-between'}
      flexWrap='wrap'
    >
      <SectionLoader isLoading={isLoading} />
      <DrawerSectionHeading>Image Height</DrawerSectionHeading>
      <Typography variant='body2' borderBottom={'1px solid'} color={'common.black'}>
        {height}%
      </Typography>
      <Slider
        sx={{ mt: 1, mr: height === 100 ? 7 + 'px !important' : 0 }}
        aria-label='Max-height'
        aria-valuetext={`Image height: ${height}%`}
        defaultValue={100}
        value={height}
        onChange={handleChange}
        valueLabelDisplay='auto'
        step={10}
        min={10}
        max={100}
        componentsProps={{
          thumb: {
            onTouchMove: (e) => {
              e.stopPropagation()
            },
          }
        }}
        marks
      />
    </Stack>
  );
}

function ReOrderItems() {
  return (
    <Stack direction='column' spacing={0.5} alignItems={'flex-start'}>
      <Box>
        <DrawerSectionHeading>Items order</DrawerSectionHeading>
        <DrawerSecondaryHeading>Drag to re-order</DrawerSecondaryHeading>
      </Box>
      <SPMSortDrawer />
    </Stack>
  );
}