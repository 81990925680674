import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

type AnyReactElement = React.ReactNode;

type AlertDialogSlideProps = {
  children: AnyReactElement; //dialog text content
  dialogTitleElement: string | JSX.Element;
  isDialogBoxOpen: boolean;
  actionText?: string;
  actionBtnClassNames?: string;
  cancelBtnText?: string;
  handleDialogActionBtnClick?: () => void;
  handleDialogCancelBtnClick?: () => void;
  showCancelBtn?: boolean;
  showActionBtn?: boolean;
};

export default function AlertDialogSlide({
  children,
  dialogTitleElement,
  isDialogBoxOpen,
  actionText,
  actionBtnClassNames = 'bg-dark-black',
  cancelBtnText = '',
  handleDialogActionBtnClick,
  handleDialogCancelBtnClick,
  showCancelBtn = true,
  showActionBtn = true,
}: AlertDialogSlideProps) {
  return (
    <Dialog
      open={isDialogBoxOpen}
      onClose={handleDialogCancelBtnClick}
      TransitionComponent={Transition}
      keepMounted={false}
      style={{ borderRadius: '5px', zIndex: 9999 }}
      aria-describedby='alert-dialog-slide-description'
    >
      <div className='alertDialogBox'>
        <DialogTitle component='div'>{dialogTitleElement}</DialogTitle>
        <DialogContent className='alertDialogBox__content'>
          <DialogContentText component='div' id='alert-dialog-slide-description'>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className='alertDialogBox__buttons'>
          <Stack spacing={2} direction='row' alignItems='center'>
            {/* --------------------- cancel btn */}
            {showCancelBtn && (
              <Button variant='outlined' onClick={handleDialogCancelBtnClick}>
                {cancelBtnText ? cancelBtnText : 'Cancel'}
              </Button>
            )}
            {/* -------------------- action btn */}
            {showActionBtn && (
              <Button
                variant='contained'
                className={actionBtnClassNames}
                onClick={handleDialogActionBtnClick}
              >
                {actionText}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </div>
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});
