import { useEffect, } from 'react';
import { useHistory } from 'react-router-dom';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import loginTokenUtils from 'utilities/loginTokenUtils';

const useEditorPageRedirect = () => {
  const history = useHistory();
  const loginToken = loginTokenUtils.getLocalStorageLoginToken();
  const { userIdInReduxUserSlice, cachedLoggedInUser, isFetching, isLoading } = useGetLoggedInUser();
  const userURL = cachedLoggedInUser?.url;
  const redirectedEditorPath = 'u';
  // DEV NOTE:
  // redirects to editor page if cache data found
  // redirects to '/ai/URL' if ai generated page, if not then '/URL'
  // routes are protected in app routing
  useEffect(() => {
    if (loginToken && cachedLoggedInUser && userIdInReduxUserSlice && userURL) {
      history.replace(`/${redirectedEditorPath}/${userURL}`);
    }
  }, [
    userIdInReduxUserSlice,
    cachedLoggedInUser,
    history,
    userURL,
    redirectedEditorPath,
    loginToken,
  ]);
  
  return { isFetching, isLoading }
};

export default useEditorPageRedirect;
