import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';

import DashboardNavbar from './sub-components/dashboard-navbar/DashboardNavbar';
import DashboardMain from './sub-components/dashboard-main/DashboardMain';
import DashboardSidebar from './sub-components/dashboard-sidebar/DashboardSidebar';

export const dashboard_icon_size: SxProps = {
  width: {xs: 20, md: 24},
  height: {xs: 20, md: 24},
};

export default function Dashboard() {
  return (
    <Box sx={{ display: 'flex' }}>
      <DashboardNavbar />
      <DashboardSidebar />
      <DashboardMain />
    </Box>
  );
}
