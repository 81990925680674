import { useEffect, useRef } from 'react';
import { Divider, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

import Frame from './styled-components/Frame';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import SocialLinkInput from 'components/utilities/SocialLinkInput/SocialLinkInput';
import { SocialContextProviderRef } from 'components/utilities/SocialLinkInput/SocialContextProvider';
import Question from './reusable-components/Question';
import { onboardingImgForDifferentSteps } from '../OnboardingFlowStepper/OnboardingFlowStepper';
import useGetTransformedImgOnboardingSrc from 'views/OnboardingFlow/hooks/useGetTransfromedOnboardingSrc';
import BoldSpan from 'components/utilities/BoldSpan';

const OnboardingSocialLinks = () => {
  const imgSrc = useGetTransformedImgOnboardingSrc(onboardingImgForDifferentSteps[3]);
  const { setFieldValue, values, isSubmitting } = useFormikContext<StepperState>();
  const socialContextRef = useRef(null);
  
  useEffect(() => {
    if(isSubmitting) {
      if (socialContextRef.current) {
        const socialLinks = (
          socialContextRef.current as SocialContextProviderRef
        ).onFinalSubmission();
        localStorage.setItem('socialLinks', JSON.stringify(socialLinks));
        setFieldValue('socialLinks', socialLinks);        
      }
    }
  }, [isSubmitting]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'completion',
      app_name: 'fronts.ai',
      screen_name: 'onboard_p4_contact_details',
    });

    window.dataLayer.push({
      event: 'screen_view',
      app_name: 'fronts.ai',
      screen_name: 'onboard_p5_social_links',
    });
  }, []);

  return (
    <Frame imgSrc={imgSrc}>
      <Question currentStep={4} helperText='These links will be displayed on the website'>
        Add{' '}
        <BoldSpan>
          <>social media</>
        </BoldSpan>{' '}
        and{' '}
        <BoldSpan>
          <>website</>
        </BoldSpan>{' '}
        links
      </Question>
      <Stack gap={{ xs: 4, md: 7.5 }} direction='column'>
        <Stack
          sx={{
            width: '100%',
            maxWidth: { xs: '100%', md: '400px' },
            marginBlockEnd: 'auto',
          }}
          direction={'column'}
          spacing={0}
          divider={<Divider orientation='horizontal' flexItem />}
        >
          <SocialLinkInput socialLinks={values.socialLinks} ref={socialContextRef} />
        </Stack>
      </Stack>
    </Frame>
  );
};

export default OnboardingSocialLinks;
