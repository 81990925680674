import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  SxProps,
  Typography,
} from '@mui/material';
import { useEffectOnce } from 'usehooks-ts';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useEffect, useState } from 'react';

import countryISOCode from 'utilities/countryWithISO2';
import supportedCountries from 'utilities/stripeSupportedCountriesList';
import useAsyncStatus from 'hooks/useAsyncStatus';

export type CountryCodeWithCurrency = {
  id: string;
  default_currency: string;
  name?: string;
};
type ChooseCountryAndCurrencySelectorProps = {
  countryCode: string;
  sx?: SxProps;
  handleSubmit: (countryISOCode: string, currency: string) => void;
};

function ChooseCountryAndCurrencySelector({
  countryCode,
  handleSubmit,
  sx = {},
}: ChooseCountryAndCurrencySelectorProps) {
  const [selectedCountry, setSelectedCountry] = useState<CountryCodeWithCurrency>({
    id: '',
    default_currency: '',
    name: '',
  });
  const currencyTextInUpperCase = selectedCountry.default_currency.toUpperCase();
  const currencySymbol = getSymbolFromCurrency(currencyTextInUpperCase);
  const [listOfCountries, setListOfCountries] = useState<CountryCodeWithCurrency[]>([]);
  const { Actions, updateStatus, error, isError, isLoading, isSuccess } =
    useAsyncStatus();

  useEffectOnce(() => {
    (async () => {
      try {
        updateStatus({ type: Actions.LOADING });
        let updateSupportedCountriesWithName = supportedCountries.map(
          (countryWithIdAndCurrency) => {
            const name = countryISOCode.find(
              (countryWithCodeAndName) =>
                countryWithCodeAndName.countryCodeISO2 === countryWithIdAndCurrency.id
            )?.name;

            return {
              ...countryWithIdAndCurrency,
              name,
            };
          }
        );
        updateSupportedCountriesWithName = updateSupportedCountriesWithName.sort(
          (a, b) => {
            // sort by name
            return a.name?.localeCompare(b.name || '') || 0;
          }
        );

        setListOfCountries(updateSupportedCountriesWithName);
        updateStatus({ type: Actions.SUCCESS, payload: '' });
      } catch (error) {
        updateStatus({
          type: Actions.ERROR,
          payload: 'Payment method not available for this country now.',
        });
      }
    })();
  });

  useEffect(() => {
    if (countryCode && listOfCountries.length) {
      const selectedCountry = listOfCountries.find(
        (country) => country.id === countryCode
      );
      if (selectedCountry) {
        setSelectedCountry(selectedCountry);
      } else {
        setSelectedCountry({
          id: '',
          default_currency: '',
          name: '',
        });
      }
    }
  }, [countryCode, listOfCountries]);

  // const handleChange = async (event: SelectChangeEvent) => {
  //   const { default_currency, id } = JSON.parse(
  //     event.target.value
  //   ) as CountryCodeWithCurrency;
  //   if (id && default_currency) {
  //     const name = countryISOCode.find(
  //       (countryWithCodeAndName) => countryWithCodeAndName.countryCodeISO2 === id
  //     )?.name;

  //     setSelectedCountry({ id, default_currency, name });
  //     handleSubmit(id, default_currency);
  //   }
  // };

  const handleCountryClick = (selectedCountryId: string) => {
    const selectedCountry = listOfCountries.find(
      (country) => country.id === selectedCountryId
    );
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
      handleSubmit(selectedCountry.id, selectedCountry.default_currency);
    }
  };

  return (
    <Box
      sx={{
        ...sx,
        '& input': {
          fontSize: '1.8rem',
          padding: '0.9em 1em',
        },
        position: 'relative',
      }}
    >
      {isLoading && (
        <CircularProgress
          color={'primary'}
          size={20}
          sx={{
            position: 'absolute',
            left: '50%',
            top: '20%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      <FormControl
        fullWidth
        sx={{
          opacity: isLoading ? 0.4 : 1,
          pointerEvents: isLoading ? 'none' : 'unset',
        }}
      >
        <Grid container spacing={0}>
          {listOfCountries.map((country) => {
            return (
              <Grid item xs={6} sm={3} md={3} sx={{ textAlign: 'left' }}>
                <Button
                  onClick={() => handleCountryClick(country.id)}
                  sx={{
                    height: '100%',
                    width: '100%',
                    textAlign: 'left',
                    justifyContent: 'flex-start',
                    border: '1px solid lightgrey',
                    backgroundColor:
                      country.id === selectedCountry.id ? 'yellow' : 'white',
                    '&:hover': {
                      backgroundColor:
                        country.id === selectedCountry.id ? 'yellow' : 'whitesmoke',
                    },
                  }}
                >
                  <img src={getCountryFlag(country.id)} alt={`${country.id} flag`} />
                  &nbsp; &nbsp; &nbsp;
                  <Typography
                    sx={{ fontSize: { xs: '1.4rem', md: '1.4rem' } }}
                    component='span'
                  >
                    {country.name}&nbsp; - &nbsp;
                    {getSymbolFromCurrency(country.default_currency.toUpperCase())} (
                    {country.default_currency.toUpperCase()})
                  </Typography>
                </Button>
              </Grid>
            );
          })}
        </Grid>
        {/* <Select
          name='country'
          labelId='select-country-label'
          id='select-country'
          value={JSON.stringify(selectedCountry)}
          label='Choose Country'
          onChange={handleChange}
        >
          {listOfCountries.map((country) => (
            <MenuItem value={JSON.stringify(country)} dense key={country.id}>
              {country.name}&nbsp;({country.id.toUpperCase()}){' '}
              {getSymbolFromCurrency(country.default_currency.toUpperCase())}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>

      {isError && <Box sx={{ color: 'red' }}>{error}</Box>}
      {isSuccess && selectedCountry.default_currency && (
        <Box textAlign={'center'} mt={2}>
          <Typography sx={{ fontSize: { xs: '1.4rem', md: '1.4rem' } }} component='span'>
            &nbsp; Selected currency -{' '}
            <strong>
              {' '}
              {currencySymbol}&nbsp;({currencyTextInUpperCase})&nbsp;
            </strong>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
export default ChooseCountryAndCurrencySelector;

function getCountryFlag(countryCode: string) {
  return `https://flagcdn.com/28x21/${countryCode.toLowerCase()}.png`;
}
