import { DomainPurchase } from 'views/Dashboard/sub-components/dashboard-main/sub-component/domain-managment/domainManagmentType';
import salesfrontsApiSlice from './salesfrontsbackendApiSlice';
import { DomainSuggestionWithPrice } from 'views/Domains/DomainsSearchSuggestions';

type DomainNameSuggestionsParam = {
  searchTerm: string;
  tldList: string;
  maxResult: number;
  adult: boolean;
  premium: boolean;
  allGA: boolean;
};

const domainExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDomainDetailsById: build.query<DomainPurchase[], string>({
      query: (userId) => ({
        url: `/domain/get-purchased-domain-details`,
        method: 'GET',
        params: { userId },
      }),
      providesTags: (result, error, domainId) => [{ type: 'DomainDetail', id: domainId }],
    }),
    getDomainNameSuggestions: build.query<DomainSuggestionWithPrice[], DomainNameSuggestionsParam>({
      query: ({ adult, allGA, maxResult, premium, searchTerm, tldList }) => ({
        url: `/domain/get-name-suggestions`,
        method: 'GET',
        params: { searchTerm, tldList, maxResult, adult, premium, allGA },
      }),
      providesTags: (result, error, { searchTerm }) => [
        { type: 'DomainNameSuggestions', id: searchTerm },
      ],
    }),
    getDomainExactSearch: build.query<DomainSuggestionWithPrice, {sld: string, tld: string}>({
      query: ({ sld, tld }) => ({
        url: `/domain/exact-search`,
        method: 'GET',
        params: { sld, tld },
      }),
      providesTags: (result, error, { sld }) => [
        { type: 'DomainExactSearch', id: sld },
      ],
    }),
    getDomainFavouriteSearch: build.query<DomainSuggestionWithPrice, {sld: string, tld: string}>({
      query: ({ sld, tld }) => ({
        url: `/domain/favourite-search`,
        method: 'GET',
        params: { sld, tld },
      }),
      providesTags: (result, error, { sld }) => [
        { type: 'DomainFavouriteSearch', id: sld },
      ],
    }),
  }),
});

export default domainExtendedApi;

export const { useGetDomainDetailsByIdQuery, useLazyGetDomainNameSuggestionsQuery, useLazyGetDomainExactSearchQuery, useLazyGetDomainFavouriteSearchQuery } = domainExtendedApi;
