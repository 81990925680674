import { keyframes } from '@emotion/react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { SystemStyleObject, Theme } from '@mui/system';

type ThreeDotsAnimationTextProps = {
  children: React.ReactNode;
  loadingTimer?: string;
  typographySx?: SxProps;
  animationElementSx?: SxProps;
};

const ThreeDotsAnimationText = ({
  children,
  animationElementSx,
  typographySx,
  loadingTimer = '2s',
}: ThreeDotsAnimationTextProps) => {
  return (
    <Box
      sx={{
        mt: 1,
        textAlign: 'center',
        color: 'info.main',
        ...typographySx,
        '&::after': {
          content: '"..."',
          animation: `${dotsAnimation} ${loadingTimer} infinite`,
          display: 'inline-block',
          width: '14px',
          ...(animationElementSx as SystemStyleObject<Theme>),
        },
      }}
    >
      {children}
    </Box>
  );
};

export default ThreeDotsAnimationText;

const dotsAnimation = keyframes`
  0%,
  20% {
    content: "";
  }
  40% {
    content: ".";
  }
  60% {
    content: "..";
  }
  80% {
    content: "...";
  }
`;
