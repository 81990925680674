import React from 'react';
import { PaletteOptions, ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { CssBaseline } from '@mui/material';
import { PaletteMode } from '@mui/material';

import { useAppSelector } from 'hooks/redux';
import { selectWebsiteFontFamily } from 'store/features/websiteFontFamily/websiteFontFamilySlice';
import darkTheme from './dark';
import lightTheme from './light';

type AppThemeProviderProps = {
  children: JSX.Element;
  mode: PaletteMode;
};

declare module '@mui/material/styles' {
  interface Palette {
    link: Palette['primary'];
  }
  interface CustomPaletteOptions extends PaletteOptions {
    link?: PaletteOptions['primary'];
    background: PaletteOptions['background'];
  }
}
const AppThemeProvider: React.FunctionComponent<AppThemeProviderProps> = ({ children, mode }) => {
  const websiteFontFamily = useAppSelector(selectWebsiteFontFamily);  
  const appDefaults = {
    headingColor: mode === 'dark' ? grey[100] : grey[900],
    textColor: mode === 'dark' ? grey[200] : grey[900],
    subTitleColor: mode === 'dark' ? grey[100] : grey[800],
  };

  const appTheme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {          
          sizeSmall: {
            fontSize: '1.4rem',
          },
          sizeMedium: {
            fontSize: '1.6rem',
          },
          sizeLarge: {
            fontSize: '2rem',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontFamily: websiteFontFamily,
            wordBreak: 'break-word',
            color: '#0d6efd',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            // fontFamily: appDefaults.fontFamily,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            // fontFamily: appDefaults.fontFamily,
          },
          input: {
            fontSize: '17px', // Set the font size for input
          },
        },
      },
    },
    palette: {
      mode,
      ...(mode === 'dark' ? darkTheme : lightTheme),
    },
    typography: {
      htmlFontSize: 10,
      fontFamily: websiteFontFamily,
      h1: {
        color: appDefaults.headingColor,
        fontSize: '4.2rem',
        fontWeight: '600',
      },
      h2: {
        color: appDefaults.headingColor,
        fontWeight: 600,
      },
      h3: {
        color: appDefaults.headingColor,
      },
      h4: {
        color: appDefaults.headingColor,
      },
      h5: {
        color: appDefaults.headingColor,
      },
      h6: {
        color: appDefaults.headingColor,
      },
      subtitle1: {
        color: appDefaults.subTitleColor,
        fontSize: '1.8rem',
      },
      subtitle2: {
        color: appDefaults.textColor,
        fontSize: "1.7rem",
      },
      body1: {
        color: appDefaults.textColor,
        fontSize: '1.6rem',
      },
      body2: {
        color: appDefaults.textColor,
        fontSize: '1.5rem',
      },
    },
  });

  return (
    <ThemeProvider theme={responsiveFontSizes(appTheme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    menu: true;
    a?: true;
  }
  interface TypographyOptions {
    menu: true;
    a?: true;
  }
}

// declare module '@mui/material/styles' {
//   // allow configuration using `createTheme`
//   interface TypographyVariantsOptions {}

// }
