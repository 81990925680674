import { Typography } from '@mui/material';

type Boldness = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type ThisProps = {
  children: string | JSX.Element | JSX.Element[];
  fontSize?: string;
  boldness?: Boldness;
};

const BoldSpan = ({ boldness = 700, children, fontSize }: ThisProps) => {
  return (
    <Typography
      component='span'
      fontWeight={boldness}
      sx={{
        fontSize: fontSize ? fontSize : 'inherit !important',
        wordBreak: "break-word",
        color: theme => theme.palette.primary.main,
      }}
    >
      {children}
    </Typography>
  );
};

export default BoldSpan;
