import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef } from 'react';

type IconPopupProps = {
  closeIconPopup: () => void;
  profileLink: string;
  showPopup: boolean;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const IconPopup = ({ showPopup, closeIconPopup, profileLink }: IconPopupProps) => {

  return (
    <div>
      <Dialog
        open={showPopup}
        TransitionComponent={Transition}
        keepMounted={false}        
        onClose={closeIconPopup}
        aria-describedby='alert-dialog-slide-description'
        fullWidth={true}
        maxWidth='sm'
      >
        <DialogTitle sx={{fontSize: {xs: '2.2rem', md: '2.5rem' }}}>{"Connected Account"}</DialogTitle>
        <DialogContent sx={{fontSize: {xs: '1.6rem', md: '1.8rem' }}}>
          <DialogContentText id='alert-dialog-slide-description' fontSize='inherit'>
            fronts.ai user authenticated by this{' '}
            <a href={profileLink} target='_blank' rel='noreferrer' onClick={closeIconPopup}>
              Facebook account
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{fontSize: 'large'}} variant='contained' color='primary' onClick={closeIconPopup}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IconPopup;
