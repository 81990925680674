import { SxProps } from '@mui/material';

export type styleType = (params?: any) => SxProps;

function getGridAreaTemplates(alignment: string | null, isMobile?: boolean) {
  if (!alignment || isMobile) {
    alignment = 'center';
  }

  if (alignment === 'right') {
    return `
  "edit        edit"
  "meta-desc   img"
  `;
  }

  if (alignment === 'center') {
    return `
    "edit      edit"
    "img       img"
    "meta-desc meta-desc"
    `;
  }

  if (alignment === 'left') {
    return `
    "edit   edit"
    "img    meta-desc"
    `;
  }
}

export function getOppositeAlignment(alignment?: string | null) {
  if (!alignment) {
    alignment = 'center';
  }

  if (alignment === 'right') {
    return 'start';
  }

  if (alignment === 'center') {
    return 'center';
  }

  if (alignment === 'left') {
    return 'start';
  }
}

export const style_ProfileCard_bgWave: styleType = (bg) => {
  return {
    zIndex: 0,
    width: '100%',
    position: 'absolute',
    top: 0,
    minHeight: { xs: '80rem', md: '80rem' },
    ...bg,
  };
};

export const style_ProfileCard_bgImage: styleType = (bgImage) => {
  return {
    ...bgImage,
    width: 'calc(100vw - (100vw - 100%))',
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1,
  };
};

export const style_ProfileCard_container: styleType = () => {
  return {
    py: 0,
    zIndex: 1,
  };
};

export const style_ProfileCard_figure: styleType = ({ alignment, isMobile }) => {
  return {
    display: 'grid',
    gridTemplateAreas: getGridAreaTemplates(alignment, isMobile),
    gridTemplateColumns: alignment === 'left' ? 'auto 1fr' : alignment === 'center' ? '1fr' : '1fr auto',
    justifyItems: isMobile ? 'center' : getOppositeAlignment(alignment),
    alignItems: 'center',
    columnGap: { xs: 0, md: 4 },
    rowGap: alignment === 'center' ? { xs: 1.5, md: 3 } : 0,
    maxWidth: 'fit-content',
    mx: 'auto',
    '& .align': {
      textAlign: isMobile ? 'center' : `${getOppositeAlignment(alignment)} !important`,
    },
  };
};

export const style_ProfileCard_userImageWrapper: styleType = () => {
  return { gridArea: 'img', maxWidth: { xs: 280, md: 500 } };
};

export const style_ProfileCard_userImage: styleType = () => {
  return {
    position: 'relative',
    display: 'block',
    maxWidth: '100%',
    '& img': {
      borderRadius: '4px',
      width: '100%',
    },
  };
};

export const style_ProfileCard_title: styleType = ({ title, userBgImage, theme }) => {
  return {
    ...title,
    fontSize: { xs: 20, md: 30 },
    display: 'inline-block',
    color: title.color,
    fontWeight: 600,
  };
};

export const style_ProfileCard_tags: styleType = ({ alignment }) => {
  return { justifyContent: {xs: 'center', md: getOppositeAlignment(alignment)}  }
};

// !! NOT IN USE 
export const style_ProfileCard_ctaButton: styleType = ({ buttonStyle, languageDir }) => {
  return {
    transition: 'all 0.3s ease',
    transitionProperty: 'background, opacity',
    '&:hover': {
      opacity: 0.8,
      background: buttonStyle.background + 'fc',
    },
    boxShadow: '0 0 10px #00000088',
    ...buttonStyle,
    opacity: 1,
    fontWeight: 600,
    padding: '10px 40px',
    borderRadius: '50px',
    fontSize: { xs: '20px', md: '24px' },
    direction: languageDir === 'rtl' ? 'ltr': 'ltr',
  };
};

export const style_ProfileCard_subtitle_container: styleType = ({ paddingBottom, paddingTop }: {paddingBottom: number, paddingTop: number}) => {
  return {
    pt: `${paddingTop}px`,
    pb: `${paddingBottom}px`,
    position: 'relative',
    zIndex: 1,
  };
};
