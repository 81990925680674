import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import {
  PhysicalOrVirtualNewService,
  PhysicalOrVirtualService,
  PhysicalOrVirtualServiceList,
} from 'types/service';

const serviceExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getServicesByUserId: build.query<PhysicalOrVirtualServiceList, string>({
      query: (userId) => ({
        url: `/services/user/${userId}`,
        // validateStatus: (response, result) => result.status !== 200,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Service' as const, id: 'LIST' },
              ...result.map(({ _id }) => ({ type: 'Service' as const, id: _id })),
            ]
          : [{ type: 'Service' as const, id: 'LIST' }],
    }),
    getServiceByServiceId: build.query<PhysicalOrVirtualService, string>({
      query: (serviceId) => ({
        url: `/services/${serviceId}`,
      }),
      providesTags: (result, error, args) => result ? [{type: 'Service' as const, id: result._id}] : [{type: 'Service' as const, id: 'PHYSICALORVIRTUALSERVICE'}]
    }),
    createService: build.mutation<void, PhysicalOrVirtualNewService>({
      query: (newService) => ({
        url: `/services/`,
        method: 'POST',
        body: newService,
      }),
      invalidatesTags: () => [{ type: 'Service' as const, id: 'LIST' }, 'SortedSPMIds'],
    }),
    deleteServiceById: build.mutation<void, string>({
      query: (serviceId) => ({
        url: `/services/${serviceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, err, serviceId) => [
        { type: 'Service', id: serviceId },
        'SortedSPMIds',
      ],
    }),
    updateService: build.mutation<
      void,
      {
        serviceId: string;
        updatedService: PhysicalOrVirtualService;
      }
    >({
      query: ({
        serviceId,
        updatedService: { _id, userId, isDeleted, type, ..._updatedService },
      }) => {
        return {
          url: `/services/${serviceId}`,
          method: 'PUT',
          body: _updatedService,
        };
      },
      invalidatesTags: () => [{ type: 'Service' as const, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetServicesByUserIdQuery,
  useCreateServiceMutation,
  useDeleteServiceByIdMutation,
  useUpdateServiceMutation,
  useGetServiceByServiceIdQuery,
  useLazyGetServiceByServiceIdQuery
} = serviceExtendedApi;

export default serviceExtendedApi;
