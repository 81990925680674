import { useAppDispatch, useAppSelector } from 'hooks/redux';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {
  INITIAL_WEBSITE_FONT_FAMILY,
  selectWebsiteFontFamily,
  udpateWebsiteFontFamily,
  WebsiteFontFamily,
} from 'store/features/websiteFontFamily/websiteFontFamilySlice';
import {
  useGetEditorByIdQuery,
  useUpdatedEditorByIdMutation,
} from 'store/rtkqFeatures/api/editorExtendedApi';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { toast } from 'react-toastify';
import throttle from 'lodash/throttle';
import { headerSelectorStyle } from 'components/ThemeSelector/ThemeSelector.style';
import { Typography } from '@mui/material';

type FontSelectorProps = {
  onFontChange?: () => void;
};

const FontSelector: React.FunctionComponent<FontSelectorProps> = ({ onFontChange }) => {
  const reduxDispatch = useAppDispatch();
  const websiteFontFamily = useAppSelector(selectWebsiteFontFamily);
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: loggedInUser } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  });
  const [udpateEditor] = useUpdatedEditorByIdMutation();
  const fontNames = Object.values(WebsiteFontFamily);

  const handleWebsiteFontFamilyChange = async (
    updatedWebsiteFontFamily: WebsiteFontFamily
  ) => {
    if (!loggedInUser) {
      toast.error('Unable to change Fonts!', {
        autoClose: 1000,
        hideProgressBar: true,
      });
      console.error(
        'Unable to change font because loggedInUser is undefined! | LoggedInUser: ',
        loggedInUser
      );
      return;
    }
    await udpateEditor({
      editorId,
      loggedInUser: {
        ...loggedInUser,
        fontFamily: updatedWebsiteFontFamily,
      },
    }).unwrap();
    reduxDispatch(udpateWebsiteFontFamily(updatedWebsiteFontFamily));
    typeof onFontChange == 'function' && onFontChange();
  };

  const handleWebsiteFontFamilyChangeEvent = (
    e: SelectChangeEvent<WebsiteFontFamily>
  ) => {
    const updatedWebsiteFontFamily = e.target.value as WebsiteFontFamily;
    if (websiteFontFamily === updatedWebsiteFontFamily) return;
    throttle(() => handleWebsiteFontFamilyChange(updatedWebsiteFontFamily), 500)();
  };

  return (
    <FormControl
      fullWidth
      sx={{        
        m: 0,
        '& .MuiFormLabel-root': {          
          color: '#000',
        },
        '& .MuiSelect-select': {
          lineHeight: 'normal',
        },
      }}
    >
      <Select
        className='font-selector'
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        aria-label='change-font'
        variant='filled'
        defaultValue={INITIAL_WEBSITE_FONT_FAMILY}
        MenuProps={{
          PopoverClasses: {
            root: 'font-selector-popover',
          },
        }}
        sx={headerSelectorStyle()}
        value={websiteFontFamily as WebsiteFontFamily}
        onChange={handleWebsiteFontFamilyChangeEvent}
      >
        {fontNames.map((fontName, index) => {
          return (
            <MenuItem
              key={index}
              value={fontName}
              sx={{
                fontFamily: `${fontName} !important`,
              }}
            >
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                variant='body2'
                fontFamily='inherit'
              >
                {fontName}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FontSelector;
