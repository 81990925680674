import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import { LoadingStatus } from 'hooks/useAsyncStatus';
import serverApi from 'lib/apiConfig';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import { StepperState } from '../OnboardingFlowForm/OnboardingFlowForm';
import routeConstants from 'constants/routeConstants';

export const aiWebBuilderFormSubmissionKey = 'aiWebBuilderFormSubmission';

const useOnboardingFormSubmission = () => {
  const history = useHistory();

  const handleFormSubmit = async (
    _: StepperState,
    formikHelpers: FormikHelpers<StepperState>
  ) => {
    const { setFieldValue } = formikHelpers;
    setFieldValue('submitStatus', LoadingStatus.LOADING);

    try {      
      const response = await generateWebsite();
      const newCreatedUser = response.data;
      
      if (newCreatedUser.url) {
        // do the hard url redirect
        window.location.href = `${routeConstants.EDITOR_ROUTE}/${newCreatedUser.url}`;
      } else {
        history.push('/sign-in');
      }
    } catch (error) {
      setFieldValue('submitStatus', LoadingStatus.ERROR);
      const isValidErrorObject = errorHandlingUtilities.isErrorObjectOrAxiosError(error);
      if (!isValidErrorObject) return;
      const hasGotResponseFromServer = 'response' in error;
      if (!hasGotResponseFromServer) return;
    }
  };

  return {
    handleFormSubmit,
  };
};

export default useOnboardingFormSubmission;

async function generateWebsite() {
  try {
    const user = await serverApi.onboardingRoutes.get('/generate-website');
    return user;
  } catch (error) {
    throw error;
  }
}