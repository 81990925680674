import { NavLink } from 'react-router-dom';
import { MeetingTypes } from 'types/meeting';
import { ServiceTypes } from 'types/service';

type EntityLayoutBoxProps = {
  entityType: ServiceTypes | MeetingTypes;
  entityBoxName: string;
  pathname: string;
  children: JSX.Element | JSX.Element[];
  entityBoxStylingClassNames: string;
  disabled?: boolean;
  notAvailable?: boolean;
};

// NOTE: Entity -> S | P | M
const EntityLayoutBox = ({
  entityType,
  entityBoxName,
  pathname,
  children, // entity icons
  entityBoxStylingClassNames,
  disabled = false,
  notAvailable = false,
}: EntityLayoutBoxProps) => {
  if (disabled) return <></>;

  const _entityBoxName = entityBoxName.trim();
  const routeData = {
    pathname,
    state: {
      isFromSelectNewStoreEntity: true,
      selectedEntityType: entityType,
    },
  };

  return (
    <NavLink to={notAvailable ? '#' : routeData}>
      <section className={`entity ${entityBoxStylingClassNames}`} data-id={notAvailable}>
        <ul className='entity__iconsContainer'>
          <EntityIcons>{children}</EntityIcons>
        </ul>
        <p className='entity__name text-capitalize'>{_entityBoxName}</p>
      </section>
    </NavLink>
  );
};

type EntityIconsProps = Pick<EntityLayoutBoxProps, 'children'>;

const EntityIcons = ({ children }: EntityIconsProps) => {
  if (!('length' in children)) {
    return <li className='entity__icon'>{children}</li>;
  }

  return (
    <>
      {children.map((Icon, index) => {
        return (
          <li key={index} className='entity__icon'>
            {Icon}
          </li>
        );
      })}
    </>
  );
};

export default EntityLayoutBox;
