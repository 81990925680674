import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { initialPortfoliosData } from 'types/portfolio';

// !
// NOTE: ! Not using this slice anymore | Moved to RTK Query
// !

export type PortfolioModule = {
  portfolio: any;
};

const INITIAL_STATE: PortfolioModule = {
  portfolio: initialPortfoliosData,
};

const portfoliosSlice = createSlice({
  name: 'portfolioModule',
  initialState: INITIAL_STATE,
  reducers: {
    setPortfolio: (state: PortfolioModule, action: PayloadAction<any>) => {
      state.portfolio = action.payload;
    },
    setPortfolioGallery: (state: PortfolioModule, action: PayloadAction<any>) => {
      state.portfolio.gallery = action.payload;
    },
  },
});

const portfoliosSliceReducer = portfoliosSlice.reducer;
export default portfoliosSliceReducer;

export const { setPortfolio, setPortfolioGallery } = portfoliosSlice.actions;

// slice selectors
export function selectPortfoliosModule(state: RootState) {
  return state.persistedReducer.portfolioModule;
}

export function selectLogginUserPortfolio(state: RootState) {
  const portfoliosModule = selectPortfoliosModule(state);
  return portfoliosModule.portfolio;
}

export function totalUserPortfolios(state: RootState) {
  const logginUserPortfolio = selectLogginUserPortfolio(state);
  return logginUserPortfolio.gallery.length;
}

// helpful while mapping
export function selectEachGalleryPortfolioWithIndex(state: RootState, index: number) {
  const editorPortfolio = selectLogginUserPortfolio(state);
  const requiredPortfolio = editorPortfolio.gallery[index];
  return requiredPortfolio;
}
