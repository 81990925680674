import { MouseEvent, useCallback, useRef } from 'react';
import { ButtonProps } from '@mui/material';
import ResponsiveButton from './ResponsiveButton';

const ThrottledButton = (props: ButtonProps & { delay: number }) => {
  const { delay, onClick, ...otherProps } = props;
  const throttleSeed = useRef<NodeJS.Timeout | null>(null);

  const throttledClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!throttleSeed.current) {        
        onClick && onClick(event);

        throttleSeed.current = setTimeout(() => {
          throttleSeed.current = null;
        }, delay);
      }
    },
    [delay, onClick]
  );

  return <ResponsiveButton {...otherProps} onClick={throttledClick} />;
};

export default ThrottledButton;
