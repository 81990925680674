import { Box, BoxProps } from '@mui/material';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const LayoutWrapper = ({ sx, children, ...boxProps }: BoxProps) => {  
  const {cachedLoggedInUser} = useGetLoggedInUser();

  return (
    <Box
      {...boxProps}
      className='layout-wrapper'
      sx={{
        direction: cachedLoggedInUser?.languageDir ?? 'ltr',
        display: 'grid',
        placeItems: 'center',        
        minHeight: '100vh',
        // paddingTop: { xs: 6.375, md: 6.375 },
        pb: { xs: 10, md: 0 },
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default LayoutWrapper;
