import getNodeEnv from 'utilities/getNodeEnv';
const NEXT_APP_DEVELOPMENT_APP_URL_STATIC = "http://localhost:3001"
const NEXT_APP_PRODUCTION_APP_URL_STATIC = "https://fronts.ai"
const REACT_APP_DEVELOPMENT_APP_URL = "https://localhost:3000"
const REACT_APP_PRODUCTION_APP_URL = "https://edit.fronts.ai"

const isProduction = getNodeEnv();
const routeConstants = {
  ONBOARDING_ROUTE: '/onboarding',
  HOME_ROUTE: '/sign-in',
  SIGNUP_ROUTE: '/sign-up',
  EDITOR_ROUTE: isProduction
    ? REACT_APP_PRODUCTION_APP_URL
    : REACT_APP_DEVELOPMENT_APP_URL,
  LIVE_ROUTE: isProduction
    ? NEXT_APP_PRODUCTION_APP_URL_STATIC
    : NEXT_APP_DEVELOPMENT_APP_URL_STATIC,
  LIVE_ROUTE_PRODUCTION: NEXT_APP_PRODUCTION_APP_URL_STATIC,
};

export default routeConstants;
