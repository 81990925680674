import { Button, ButtonProps, SxProps, useMediaQuery } from '@mui/material';

export const responsive_button_icon: SxProps = {
  width: { xs: 15, md: 17 },
  height: { xs: 15, md: 17 },
  color: 'currentcolor',
  verticalAlign: 'middle',
  alignSelf: 'center',
  mb: '2px',
};

export type ResponsiveButtonProps = ButtonProps<'button', Partial<Pick<HTMLAnchorElement, 'target'>>>;

const ResponsiveButton = (
  props: ButtonProps<'button', Partial<Pick<HTMLAnchorElement, 'target'>>>
) => {
  const isPhone = useMediaQuery('(max-width: 600px)');
  
  return (
    <Button
      variant='text'
      {...props}
      sx={{
        textTransform: 'unset',
        fontWeight: 500,
        boxShadow: (theme) => `1px 1px 3px ${theme.palette.text.disabled}`,
        transition: 'all 0.3s ease',
        px: {xs: 1.5, md: 2},
        '&:hover, &:focus': {
          boxShadow: (theme) => `0 0 2px ${theme.palette.text.disabled}`,
        },
        ...props.sx,
      }}
      size={isPhone ? 'small' : 'medium'}
    >
      {props.children}
    </Button>
  );
};

export default ResponsiveButton;
