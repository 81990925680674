import { AutoCompleteGuestLocationDetails } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import {
  MeetingTypes,
  PhysicalMeetingHostOptedOptions,
  PhysicalOrVirtualMeeting,
} from 'types/meeting';

export type Session = {
  _id: string;
  meetingId: PhysicalOrVirtualMeeting['_id'];
  userId: string;  
  status: 'guest-request-meeting'  | 'confirmed' | 'rejected' | 'cancelled' | 'payment-waiting' | 'paid' | 'guest-selected-proposed-time' | 'idle' | 'host-propose-new-times';
  isPaid: boolean;
  amountPaid?: number;
  currency?: string;
  receiptUrl?: string;
  paymentIntentId: string;
  created: number;
  participant: Record<'name' | 'email' | 'phoneNumber', string>;
  guests?: string[];
  duration: number;
  guestMeetingPreparationText: string;
  timezone: Record<'value' | 'label', string>;
  startAt: number;
  endAt: number;  
  seqNum: number; // FIXME: rename to icsSeqNum // NOTE: requied for sending email via ICS python service
  newProposedTime?: NewProposedTime;
  proposedTimeCreatedAt?: number;
  hostGuestMeetingDetails: NonNullable<PhysicalOrVirtualMeeting['hostGuestMeetingDetails']>;
  inPersonMeetingGuestLocationDetails?: AutoCompleteGuestLocationDetails; // with MEETING-P & opted opted "guest-location-checkout"
  meetingDetails: Pick<
    PhysicalOrVirtualMeeting,
    'title' | 'type' | 'hostDetails' | 'isPaid' | 'price' | 'desc' | 'included' | 'img'
  >;
  isArchived?: boolean; // !! deprecated  !! deprecated
} & Pick<PhysicalOrVirtualMeeting, 'showLinkOrLocationDetailsToGuest'>;

export type PickFromSessionType<T extends keyof Session> = {
  [key in T]: Session[key];
};

type NewProposedTime = {
  repeatable: {from: number, to: number}[],
  nonRepeatable: {from: number, to: number}[],
  bookingUpTo: number,
}

export const initialSessionData: Session = {
  _id: '',
  meetingId: '',
  userId: '',
  status: 'idle',
  isPaid: false,
  paymentIntentId: '',
  hostGuestMeetingDetails: {
    link: '',
    meetingExtraInstructions: '',
  },
  created: 0,
  participant: {
    name: '',
    email: '',
    phoneNumber: '',
  },
  guests: [],
  duration: 0,
  guestMeetingPreparationText: '',
  timezone: {
    value: '',
    label: '',
  },
  startAt: 0,
  endAt: 0,
  seqNum: 0,
  isArchived: false,
  showLinkOrLocationDetailsToGuest: false,
  meetingDetails: {
    img: '',
    desc: '',
    included: [],
    price: 0,
    isPaid: false,
    title: '',
    type: MeetingTypes.Virtual,
    hostDetails: {
      optedOption: PhysicalMeetingHostOptedOptions.GUEST_LOCATION_CHECKOUT,
      allSessionsData: {},
    },
  },
};
