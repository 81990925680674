import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
export interface HeadCell {
  id: string;
  label: string;
  numeric: boolean;
}

export interface CustomTableHeadProps {
  headCells: readonly HeadCell[];
}

const CustomTableHead = (props: CustomTableHeadProps) => {  
  return (
    <TableHead 
    >
      <TableRow>
        {props.headCells.map((headCell) => {
          return (
            <TableCell                      
              key={headCell.id}
              align={'left'}
              sx={{color: (theme) => theme.palette.text.secondary}}
            >
              {headCell.label}             
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
