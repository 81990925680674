import React, { useEffect, useState } from 'react';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

import DeleteIcon from '@mui/icons-material/Delete';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArchiveIcon from '@mui/icons-material/Archive';

import Popup from 'components/utilities/Popups/Popup';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import ServiceOrderInstructionToCustomer from './ServiceOrderInstructionToCustomer';
import ConfirmationDialog from 'Popups/ConfirmationDialog';
import ResponsiveButton, { responsive_button_icon } from 'components/Buttons/ResponsiveButton';
import ServiceOrderActions from './ServiceOrderActions';
import {
  IconTextPair,
  PopupHeading,
  PopupSectionHeading,
  PopupText,
  dashboard_popup_icon_size,
} from 'components/dashboard/DashboardReusableComponent';

import ServiceOrder from 'types/serviceOrder';

import { getStatusForSeriveOrder } from './ServiceOrderTable';
import getNodeEnv from 'utilities/getNodeEnv';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  useDeleteServiceOrderMutation,
  useGetServiceOrderByServiceOrderIdQuery,
  useUpdateServiceOrderArchivedMutation,
} from 'store/rtkqFeatures/api/serviceOrdersExtendedApi';

const ServiceOrderDetailsPopup = ({
  serviceOrderId,
  open,
  setOpen,
}: {
  serviceOrderId: ServiceOrder['_id'];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isNodeEnvProduction = getNodeEnv();
  const {
    isLoading: isServiceOrderLoading,
    data: serviceOrder,
    isError: isServiceOrderError,
  } = useGetServiceOrderByServiceOrderIdQuery(serviceOrderId);

  if (isServiceOrderError) {
    !isNodeEnvProduction && console.error('fetching service order error with id: ', serviceOrderId);
    return <></>;
  }

  if (isServiceOrderLoading || !serviceOrder) {
    return <></>;
  }

  return (
    <Popup
      dialogProps={{
        fullScreen: false,
        open,
        PaperProps: {
          sx: {
            overflow: 'auto',
            margin: 2,
            minWidth: { xs: 'calc(100% - 32px)', md: '500px' },
          },
        },
      }}
      setOpen={setOpen}
      title={''}
      showCloseIcon
    >
      <Stack
        direction={'column'}
        sx={{
          width: { xs: '100%', md: '100%' },
          minWidth: { xs: '100%', md: '500px' },
          p: { xs: 2, md: 4 },
          pb: { xs: 8, md: 4 },
          position: 'relative',
        }}
        spacing={2}
        divider={<Divider flexItem />}
      >
        <ServiceOrderPopupHeading orderDetails={serviceOrder} />
        <ServiceOrderActions
          cacheServiceOrderStatus={serviceOrder.status}
          serviceOrderId={serviceOrder._id}
        />
        <ServiceOrderPricing orderDetails={serviceOrder} />
        <ServiceOrderInstructionToCustomer
          serviceOrderId={serviceOrder._id}
          cacheProductOwnerInstructionsToCustomer={serviceOrder.productOwnerInstructionsToCustomer}
        />
        <ServiceOrderPopupBookedDetails orderDetails={serviceOrder} />

        <ServiceOrderPopupArchive orderDetails={serviceOrder} />
        <ServiceOrderPopupDelete orderDetails={serviceOrder} parentPopupSetFn={setOpen} />
      </Stack>
    </Popup>
  );
};

export default ServiceOrderDetailsPopup;

function ServiceOrderPopupHeading({ orderDetails }: { orderDetails: ServiceOrder }) {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const loggedInUserTimeZone = cachedLoggedInUser?.timeZone ?? 'UTC';

  const startTime = utcToZonedTime(orderDetails.createdAt, loggedInUserTimeZone);
  const month = format(startTime, 'MMM');
  const date = format(startTime, 'dd');

  const { backgroundColor, color } = getStatusForSeriveOrder(orderDetails.status);

  return (
    <Stack direction={'row'} flexWrap={'wrap'} useFlexGap spacing={1} alignItems={'baseline'}>
      {/* Month date viewer */}
      <Stack
        component={Paper}
        elevation={2}
        direction={'column'}
        sx={{ minWidth: '5ch', borderRadius: '10px', overflow: 'hidden', textAlign: 'center' }}
      >
        <Typography
          sx={{
            textTransform: 'uppercase',
            bgcolor: 'error.main',
            pt: 0.5,
            color: (theme) => theme.palette.primary.contrastText,
          }}
        >
          {month}
        </Typography>
        <Typography sx={{ color: (theme) => theme.palette.text.primary }}>{date}</Typography>
      </Stack>

      <Stack sx={{ maxWidth: { xs: '70%', md: '100%' } }}>
        <PopupHeading>{orderDetails.serviceDetails.title}</PopupHeading>

        <PopupText
          component={'span'}
          sx={{ bgcolor: backgroundColor, color: color, display: 'inline-block', px: 1 }}
        >
          {orderDetails.status}
        </PopupText>
      </Stack>
    </Stack>
  );
}

function ServiceOrderPricing({ orderDetails }: { orderDetails: ServiceOrder }) {
  const amountPaid = orderDetails.amountPaid ?? 0;
  const isAmountPaid = 100 > 0;

  if (!isAmountPaid) return <></>;

  return (
    <Stack spacing={1}>
      <PopupSectionHeading>Price</PopupSectionHeading>
      <Typography variant={'h5'} fontWeight={600}>
        {amountPaid}&nbsp;
        <PopupText component={'span'}>{orderDetails.currency}</PopupText>
      </Typography>
    </Stack>
  );
}

function ServiceOrderPopupBookedDetails({ orderDetails }: { orderDetails: ServiceOrder }) {
  const participantName = orderDetails.customerDetails.name;
  const participantEmail = orderDetails.customerDetails.email;
  const participantPhone: string | undefined = orderDetails.customerDetails?.phone;
  const messageToProductOwner: string | undefined =
    orderDetails.customerDetails.messageToProductOwner;
  const guestPaidAmount = orderDetails.amountPaid ?? 0;
  const guestPaymentReceipt = orderDetails.customerDetails.receiptUrl ?? '';

  return (
    <Stack spacing={1}>
      <PopupSectionHeading>Booked by</PopupSectionHeading>
      <Stack direction={'column'} spacing={1}>
        <IconTextPair
          icon={<PermIdentityIcon sx={dashboard_popup_icon_size} />}
          text={participantName}
        />
        <IconTextPair
          icon={<MailOutlineIcon sx={dashboard_popup_icon_size} />}
          text={participantEmail}
          component={'a'}
          href={`mailto:${participantEmail}`}
          sx={{ textDecoration: 'underline !important' }}
        />
        {participantPhone && (
          <IconTextPair
            icon={<LocalPhoneIcon sx={dashboard_popup_icon_size} />}
            component={'a'}
            href={`tel:${participantPhone}`}
            sx={{ textDecoration: 'underline !important' }}
            text={participantPhone}
          />
        )}
        {messageToProductOwner && (
          <IconTextPair
            icon={<EventNoteIcon sx={dashboard_popup_icon_size} />}
            text={messageToProductOwner}
          />
        )}
        {!!guestPaidAmount && !!guestPaymentReceipt && (
          <IconTextPair
            icon={<ReceiptIcon sx={dashboard_popup_icon_size} />}
            text={'Receipt'}
            component={'a'}
            href={guestPaymentReceipt}
            rel='noreferrer'
            target='_blank'
          />
        )}
      </Stack>
    </Stack>
  );
}

function ServiceOrderPopupArchive({ orderDetails }: { orderDetails: ServiceOrder }) {
  const isArchived = orderDetails.isArchived;
  const iconAnimation = {
    transition: 'transform 0.3s ease',
    transform: isArchived ? 'rotate(180deg)' : 'rotate(0deg)',
  };

  const [updateServiceOrderArchived, { isLoading: isServiceOrderArchiveLoading }] =
    useUpdateServiceOrderArchivedMutation();

  function handleArchiveClick() {
    updateServiceOrderArchived({
      serviceOrderId: orderDetails._id,
      isArchived: !!!orderDetails.isArchived,
    });
  }  

  return (
    <Stack direction={'column'} spacing={1}>
      <SectionLoader isLoading={isServiceOrderArchiveLoading} />
      <PopupSectionHeading>Archive Service order</PopupSectionHeading>

      <Stack direction={'column'} spacing={2} color={theme => theme.palette.text.secondary}>
        <IconTextPair
          text={'Archive or Unarchive to efficiently manage your orders'}
          icon={
            <ArchiveIcon
              sx={{
                ...responsive_button_icon,
                ...iconAnimation,
              }}
            />
          }
        />
        <ResponsiveButton
          onClick={handleArchiveClick}
          color='secondary'
          sx={{ alignSelf: 'flex-start' }}
          endIcon={
            <ArchiveIcon
              sx={{
                ...responsive_button_icon,
                ...iconAnimation,
              }}
            />
          }
        >
          {isArchived ? 'UnArchive' : 'Archive'}
        </ResponsiveButton>
      </Stack>
    </Stack>
  );
}

function ServiceOrderPopupDelete({
  orderDetails,
  parentPopupSetFn,
}: {
  orderDetails: ServiceOrder;
  parentPopupSetFn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [deleteServiceOrder, { isLoading: isDeleting, data: deletedResponse }] =
    useDeleteServiceOrderMutation();

  function handleConfirmDelete() {
    deleteServiceOrder(orderDetails._id);
    setConfirmDelete(false);
  }

  useEffect(() => {
    if (deletedResponse?.isDeleted) {
      parentPopupSetFn(false);
    }
  }, [deletedResponse?.isDeleted, parentPopupSetFn]);

  return (
    <Stack direction={'column'} spacing={1}>
      <SectionLoader isLoading={isDeleting} />
      <PopupSectionHeading color='error.main'>Delete Service order</PopupSectionHeading>

      <Stack direction={'column'} spacing={2}>
        <IconTextPair
          icon={<DeleteIcon sx={dashboard_popup_icon_size} />}
          text='Deleting service order is irreversible.'
        />
        <ResponsiveButton
          onClick={() => setConfirmDelete(true)}
          color='error'
          sx={{ alignSelf: 'flex-start' }}
          endIcon={<DeleteIcon sx={{ ...responsive_button_icon, color: 'error.main' }} />}
        >
          Delete
        </ResponsiveButton>
      </Stack>

      <ConfirmationDialog
        title='Confirm Delete'
        content={
          <>
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: 600 }}>{orderDetails.serviceDetails.title} </span> order
          </>
        }
        handleDialogClose={() => setConfirmDelete(false)}
        handleAgreeBtnClick={handleConfirmDelete}
        ariaDescribedby='alert-dialog-meeting-delete'
        confirmText='Delete'
        isLoading={isDeleting}
        open={confirmDelete}
        dialogProps={{
          open: confirmDelete,
          sx: { zIndex: 1501 },
          maxWidth: 'xs',
        }}
      />
    </Stack>
  );
}
