import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';

import useSortedSPM from 'components/MUI_SPM/SPMSortDrawer/hooks/useSortedSPM';
import { useUpdateSPMSortedByEditorIdMutation } from 'store/rtkqFeatures/api/spmSportedExtendedApi';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import generalUtilities from 'utilities/generalUtilities';
import { handleError } from 'components/Portfolios/sub-components/PortfolioDrawer';

const DRAGGABLE_ITEM_AVATAR_SIZE = 25;

const SPMSortDrawer = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const [updateSPMSortedIds, { isLoading, isError }] =
    useUpdateSPMSortedByEditorIdMutation();
  const { sortedSPMItems, spmSorted } = useSortedSPM(); // data depends upon cache
  const [drawerSortedSPM, setDrawerSortedSPM] = useState(sortedSPMItems);

  //  Drag and Drop handler
  async function handleOnDragEnd(result: DropResult, provided: ResponderProvided) {
    if (!result.destination) return;

    const beforeDragDrawerSortedSPM = drawerSortedSPM ? [...drawerSortedSPM] : [];
    const [reorderedItem] = beforeDragDrawerSortedSPM.splice(result.source.index, 1);
    beforeDragDrawerSortedSPM.splice(result.destination.index, 0, reorderedItem);
    const spmWithOrderProp = beforeDragDrawerSortedSPM.map((spm, index) => {
      return {
        ...spm,
        order: index,
      };
    });
    setDrawerSortedSPM(beforeDragDrawerSortedSPM);

    await updateSPMSortedIds({
      editorId,
      sortedSpm: {
        ...(spmSorted || {}),
        allIds: spmWithOrderProp.map((spm) => spm._id),
      },
    });
  }

  useEffect(() => {
    setDrawerSortedSPM(sortedSPMItems);
  }, [sortedSPMItems]);

  if (!drawerSortedSPM?.length) return <></>;

  handleError(isError, 'Failed to update SPM order');

  return (
    <>
      <Stack
        sx={{ minWidth: '100%', height: '100%' }}
        justifyContent='space-between'
        alignItems='stretch'
        spacing={2}
      >
        <SectionLoader isLoading={isLoading} />
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='draggableService'>
            {(provided) => (
              <Stack
                useFlexGap
                spacing={1}
                sx={{
                  width: '100%',
                  position: 'relative',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {drawerSortedSPM?.map((SPM, index) => (
                  <Draggable key={SPM._id} draggableId={SPM._id} index={index}>
                    {(provided) => (
                      <ListItem
                        // className={SPM._id}
                        sx={{
                          borderRadius: '5px',
                          background: 'common.white',
                          py: '1rem',
                          boxShadow: (theme) => `0 0 3px ${theme.palette.grey[500]}`,
                        }}
                        disablePadding
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Stack
                          direction='row'
                          justifyContent='flex-start'
                          alignItems='center'
                          spacing={0.5}
                        >
                          <ListItemIcon sx={{ minWidth: 20 }}>
                            <DragIndicatorIcon
                              sx={{
                                width: DRAGGABLE_ITEM_AVATAR_SIZE,
                                height: DRAGGABLE_ITEM_AVATAR_SIZE,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemAvatar sx={{ minWidth: 30 }}>
                            <Avatar
                              variant='square'
                              src={SPM.img}
                              sx={{
                                width: DRAGGABLE_ITEM_AVATAR_SIZE,
                                height: DRAGGABLE_ITEM_AVATAR_SIZE,
                              }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            sx={{
                              maxWidth: '40ch',
                              '& *': { fontSize: '1.2rem !important' },
                            }}
                            primary={generalUtilities.sliceText(SPM.title || '', 18)}
                          />
                        </Stack>
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </Stack>
    </>
  );
};

export default SPMSortDrawer;
