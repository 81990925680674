import { styleType } from 'components/logins/SelectLoginAccount/sub-components/EmailLinkedUserAccounts/EmailLinkedUserAccount.style';

export const style_HeaderMenuItems_container: styleType = (fontSizeButton) => {
  return {
    marginTop: {
      xs: '4rem',
      sm: 0,
    },
    fontSize: fontSizeButton,
    padding: { xs: '0.8rem', lg: '0.8rem 0' },
  };
};

export const style_HeaderMenuItems_liveSiteBtn: styleType = () => {
  return {
    fontSize: 'inherit',
    textTransform: 'capitalize',    
    minWidth: 'max-content',
    py: 1,
  };
};

export const style_HeaderMenuItems_subMenu: styleType = () => {
  return {
    position: 'absolute',
    top: '100%',
    right: 0,
    zIndex: 1000,
    width: 'max-content',
    backgroundColor: 'white',
    boxShadow: '0 0 5px rgba(0,0,0,0.5)',
    padding: '0.5rem 1rem',
    transition: 'all .2s linear',
    '@media (max-width: 600px)': {
      position: 'static',
      width: '100%',
      boxShadow: 'none',
      padding: '0.5rem 0',
    },
  };
};

export const style_HeaderMenuItems_button: styleType = () => {
  return {
    fontSize: 'inherit',
    textTransform: 'capitalize',
    minWidth: 'max-content',
    justifyContent: 'flex-start',
    p: 1,    
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.09) !important',
    },
  };
};
