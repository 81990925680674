import { Box, Stack, Typography } from '@mui/material';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import LayoutContainer from 'components/Containers/LayoutContainer';
import CallToActionBtn from './reusable-components/CallToActionBtn';

import { style_ProfileCard_bgWave } from 'components/ProfileCard/ProfileCard.style';
import LayoutWrapper from './reusable-components/LayoutWrapper';

const Layout5 = () => {
  const {
    userImage,

    currentTheme,
    tagsArray,

    userName,
    userTitle,
    user,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,
    userBgImage,

    buttonStyle,
    bg,
    bgImage,

    isMeetingAndServiceAvailable,
    storeHeading,

    isNeedDiv,
    isMobile,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,
    colorCode,

    displayTags,
    displaySocialLinks,

    userNameColor,
    titleColor,
  } = useProfileCard();

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box position='relative' component='section' sx={isNeedDiv ? {} : bg}>
      {isNeedDiv && !userBgImage && (
        <Box
          className='bg-wave'
          sx={{ ...style_ProfileCard_bgWave(bg), zIndex: -1 }}
        ></Box>
      )}
      <LayoutWrapper
        sx={{
          ...bgImage,          
        }}
      >
        <LayoutContainer >
          <ProfileEditAndDesignBtn />
          <Box
            sx={{
              gap: 2,
              display: 'grid',
              gridTemplateColumns: '15px auto',
              maxWidth: '80ch',
              m: 'auto',
            }}
          >
            <Box
              sx={{ width: `2px`, backgroundColor: currentTheme?.profile.userName.color }}
            />
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'flex-start',
                textAlign: 'left',
              }}
              useFlexGap
              spacing={2}
            >
              <Typography
                variant='h1'
                fontSize={{ xs: 30, md: 60 }}
                color={userNameColor}
              >
                {userName}
              </Typography>
              <Typography variant='h5' color={titleColor}>
                {userTitle}
              </Typography>

              {displaySocialLinks !== false && (
                <Box display='inline-block'>
                  <SocialIconsList
                    emailPublic={emailPublic}
                    email={email}
                    socialLinks={socialLinks}
                    whatsapp={whatsapp}
                    address={address}
                    phone={phone}
                    styling={{ justifyContent: 'center', display: 'inline-flex' }}
                    showOnlyIcon
                  />
                </Box>
              )}

              {displayTags !== false && (
                <Box>
                  <Tags
                    tagsArray={tagsArray}
                    tag={tag}
                    global={global}
                    sx={{ justifyContent: 'flex-start', display: 'inline-flex' }}
                  />
                </Box>
              )}
              {isMeetingAndServiceAvailable && storeHeading && (
                <CallToActionBtn
                  user={user}
                  buttonStyle={buttonStyle}
                  storeHeading={storeHeading}
                />
              )}
            </Stack>
          </Box>
        </LayoutContainer>
      </LayoutWrapper>

      {/* sub title */}
      <ProfileSubtitle
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        subTitle={user.subTitle}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout5;
