import { AxiosError } from 'axios';
import serverApi from 'lib/apiConfig';
import { InPersonMeetingGuestLocationDetailsWithMeetingInstructions } from 'store/features/session/sessionActions';
import { HostGuestMeetingDetails } from 'types/meeting';
import { Session } from 'types/sessions';
import loginTokenUtils from 'utilities/loginTokenUtils';

// types
type GenericObjectInterface = {
  [key: string]: any;
};

// type ProposeNewTime = {
//   repeatable: RepeatebleTentativeHours[],
//   nonRepeatable: RepeatebleTentativeHours[],
//   bookingUpTo: number,
// }

export type SessionBooking = Pick<
  Session,
  'participant' | 'guestMeetingPreparationText' | 'timezone' | 'startAt' | 'endAt'
> &
  Partial<
    Pick<Session, 'hostGuestMeetingDetails' | 'inPersonMeetingGuestLocationDetails'>
  >;

// services
async function getSessionById(id: string): Promise<Session | AxiosError> {
  try {
    const { data }= await serverApi.public.get(`sessions/${id}`);
    return data as Session;
  } catch (error) {
    return error as AxiosError;
  }
}

async function booking(session: SessionBooking, meetingId: string) {
  const res = await serverApi.public.post(
    `sessions/meeting/${meetingId}`,
    session
  );
  return res;
}

async function updateGuestEmailsOfSession(session: Session) {
  await serverApi.basic.put(`sessions/mails/${session._id}`, session.guests);
}

// In use.
async function getUserSessions(id: string) {
  const res = await serverApi.basic.get(`sessions/user/${id}`);
  return res.data;
}

async function setSessionStatus(id: string, status: string) {
  try {
    const { data } = await serverApi.basic.patch(`sessions/${id}/status`, {
      status,
    });
    return data;
  } catch (error) {
    throw error;
  }
}

// async function setNewProposeTimeForSession(
//   id: string,
//   newTimes: ProposeNewTime,
//   timezon: { value: string; label: string }
// ) {
//   const res = await serverApi.public.put(`sessions/propose-new-time/${id}`, {
//     proposedTime: newTimes,
//     timezon,
//   });
//   return res.data;
// }

async function updateSession(sessionId: string, updatedSession: GenericObjectInterface) {
  const loginToken = loginTokenUtils.getLocalStorageLoginToken();
  try {
    if (!loginToken) {
      const errorMsg = 'loginToken not found';
      const isNodeEnvProduction = process.env.NODE_ENV === 'production';
      !isNodeEnvProduction && console.error('loginToken not found');
      throw new Error(errorMsg);
    }
    const { data } = await serverApi.v1.put(`sessions/${sessionId}`, updatedSession, {
      headers: {
        Authorization: `Bearer ${loginToken}`,
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
}

// for updating hostGuestMeetingDetails on sessions object
async function updateHostGuestMeetingDetails(
  sessionId: string,
  hostGuestMeetingDetails: HostGuestMeetingDetails
) {
  const res = await serverApi.basic.put(
    `/sessions/${sessionId}/host-guest-meeting-details`,
    hostGuestMeetingDetails
  );
  if (res.status === 200 && res.data.isSuccessfulUpdate) return true;
  return false;
}

async function updateInPersonMeetingGuestLocationDetailsWithMeetingInstructions(
  sessionId: string,
  inPersonMeetingGuestLocationDetailsWithMeetingInstructions: InPersonMeetingGuestLocationDetailsWithMeetingInstructions
) {
  const res = await serverApi.basic.put(
    `/sessions/${sessionId}/inperson-meeting-guest-location-details`,
    inPersonMeetingGuestLocationDetailsWithMeetingInstructions
  );
  if (res.status === 200 && res.data.isSuccessfulUpdate) return true;
  return false;
}

async function sendInvitations(id: string, code: string) {
  const res = await serverApi.basic.get(`sessions/send-invitation/${id}`, {
    params: { code: `${code}` },
  });
  return res.data;
}

const sessionServices = {
  booking,
  getSessionById,
  setSessionStatus,
  updateGuestEmailsOfSession,
  getUserSessions,
  // setNewProposeTimeForSession,
  updateSession,
  updateHostGuestMeetingDetails,
  sendInvitations,
  updateInPersonMeetingGuestLocationDetailsWithMeetingInstructions,
};

export default sessionServices;
