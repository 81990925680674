import { useState } from 'react';

// NOTE: This hook to be used with editable headings only for text length validation!!

const MAX_HEADING_LENGTH = 20;

const useEditableHeadingsTextLengthValidation = () => {
  const [lengthValidationError, setLengthValidationError] = useState(false);

  const makeErrorFalseIfError = () => {
    lengthValidationError && setLengthValidationError(false);
  };

  return {
    MAX_HEADING_LENGTH,
    lengthValidationError,
    setLengthValidationError,
    makeErrorFalseIfError,
  };
};

export default useEditableHeadingsTextLengthValidation;
