type PolicySubHeadingProps = {
  children: string;
  classname?: string;
};

const PolicySubHeading = ({ children, classname }: PolicySubHeadingProps) => {
  return (
    <>
      <h3 className={`heading policy__sub-heading mb-4 fw-normal ${classname}`}>
        {children}
      </h3>
    </>
  );
};

export default PolicySubHeading;
