import { LoggedInUser } from 'types/user';
import salesfrontsApiSlice from './salesfrontsbackendApiSlice';

export type EmailLinkedAccount = { domainName?: string } & Pick<
  LoggedInUser,
  | 'authType'
  | 'name'
  | 'title'
  | 'email'
  | 'img'
  | 'url'
  | 'subTitle'
  | '_id'
  | 'timeZone'
  | 'loadCount'
  | 'searchable'
  | 'forcedSearchable'
  | 'isPremium'
>;

export type EmailLinkedUserAccounts = {
  email: string;
  totalAccounts: number;
  linkedAccounts: EmailLinkedAccount[];
};

const linkedAccountsExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLinkedAccounts: build.query<EmailLinkedUserAccounts, void>({
      query: () => `/user/email-linked-accounts`,
      providesTags: ['LinkedAccount'],
    }),
  }),
});

export const { useGetLinkedAccountsQuery } = linkedAccountsExtendedApi;
