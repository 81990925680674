import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export enum WebsiteFontFamily {
  POPPINS = 'Poppins',
  NUNITO = 'Nunito',
  ROBOTO = 'Roboto',
  OPEN_SANS = 'Open Sans',
  ANYBODY = 'Anybody',
  IBM_PLEX_MONO = 'IBM Plex Mono',
  ROBOTO_SLAB = 'Roboto Slab',
  MERRIWEATHER = 'Merriweather',
  DELIUS = 'Delius',
  NEUCHA = 'Neucha',
  WALTER_TURNCOAT = 'Walter Turncoat',
  RANCHO = 'Rancho',
  GLORIA_HALLELUJAH = 'Gloria Hallelujah',
  ROCHESTER = 'Rochester',
  TiroDevanagari = 'Tiro Devanagari Hindi',
  // HEBREW = 'IBM Plex Sans Hebrew',
}

export type WebsiteFontFamilyFontNames = keyof typeof WebsiteFontFamily;
export const INITIAL_WEBSITE_FONT_FAMILY = WebsiteFontFamily.POPPINS;

const websiteFontFamilySlice = createSlice({
  name: 'websiteFontFamily',
  initialState: INITIAL_WEBSITE_FONT_FAMILY,
  reducers: {
    udpateWebsiteFontFamily: (
      state: WebsiteFontFamily,
      action: PayloadAction<WebsiteFontFamily>
    ) => {
      state = action.payload;
      return state;
    },
    resetWebsiteFontFamilySlice: () => {
      return INITIAL_WEBSITE_FONT_FAMILY;
    },
  },
});

const websiteFontFamilySliceReducer = websiteFontFamilySlice.reducer;
export default websiteFontFamilySliceReducer;

export const { udpateWebsiteFontFamily, resetWebsiteFontFamilySlice } =
  websiteFontFamilySlice.actions;

// WebsiteFontFamily slice selectors
export const selectWebsiteFontFamily = (state: RootState) =>
  state.persistedReducer.websiteFontFamily;

export const langaugeDir = (state: RootState) => state.persistedReducer.loggedinUserModule.loggedInUser?.languageDir ?? 'ltr';