import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';
import EditBtn from 'components/utilities/edit-btn/EditBtn';

const AboutUsEditBtn = ({ sx }: { sx: SxProps }) => {
  return (
    <Box sx={sx}>
      <EditBtn
        to={'/edit-aboutUs'}
        stylings={{ textDecoration: 'none', color: 'white' }}
      />
    </Box>
  );
};

export default AboutUsEditBtn;
