import { Box, Button, SxProps, Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';

interface Props {
  html: string;
  sx: SxProps;
  length: number;
  gradient?: string;
}

const ReadMore: React.FC<Props> = ({ html, sx, length, gradient = '#ffffff' }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMore, setIsMore] = useState(false);
  const gradientColor = `${createGradientColor(gradient)}`;
  const elementRef = useRef<HTMLDivElement>(null);
  const elementDiv = elementRef.current;
  const lineHeightOfDescription = elementDiv && window.getComputedStyle(elementDiv).getPropertyValue('line-height');

  function hideContent(elementDiv: HTMLDivElement) {
    elementDiv.style.cssText = `height: ${length}px; overflow: hidden;`;
  }

  function showContent(elementDiv: HTMLDivElement, scrollHeight: number) {
    elementDiv.style.cssText = `height: ${scrollHeight}px; overflow: visible;`;
  }

  useEffect(() => {
    if (elementDiv) {
      if (elementDiv.scrollHeight > length) {
        setIsMore(true);
        setIsExpanded(false);
        hideContent(elementDiv);
      } else {
        setIsMore(false);
        setIsExpanded(true);
        showContent(elementDiv, elementDiv.scrollHeight);
      }
    } else {
      setIsMore(false);
      setIsExpanded(true);
    }
  }, [elementDiv, html, length]);

  const handeButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    if (elementDiv) {
      if (isExpanded) {
        hideContent(elementDiv);
      } else {
        showContent(elementDiv, elementDiv.scrollHeight);
      }
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <Box>
      <Typography
        ref={elementRef}
        sx={{
          ...sx,
          '& img': { maxWidth: '100%' },
          transition: 'height 0.3s ease-in-out',
          '& ol, & ul': {
            ml: '1.5rem',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      {isMore && (
        <>
          <Button
            disableRipple
            variant='text'
            size='large'
            color='info'
            sx={{
              p: 0,
              m: 0,
              fontSize: '16px',
              marginTop: '0',
              position: 'relative',
              width: '100%',
              '&:hover': { backgroundColor: 'transparent' },
              '&::after': isExpanded
                ? {}
                : {
                    content: '" "',
                    width: '100%',
                    background: `linear-gradient(${gradientColor})`,
                    height: lineHeightOfDescription,
                    position: 'absolute',
                    top: 0,
                    transform: 'translateY(-100%)',
                  },
            }}
            onClick={handeButtonClick}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </Button>
        </>
      )}
    </Box>
  );
};

export default ReadMore;

export function createGradientColor(hexColor: string) {
  // Convert hexadecimal color to RGB values
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);

  // Convert new RGB values to hexadecimal color
  const rgb = `${r}, ${g}, ${b}`;
  const gradientColor = `rgba(${rgb}, 0.3), rgba(${rgb}, 0.6), rgba(${rgb}, 0.9)`;

  return gradientColor;
}
