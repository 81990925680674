import { useAppSelector } from 'hooks/redux';
import { selectLocalCurrentThemeIndex } from 'store/features/localThemeSelector/localThemeSelectorSlice';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';

// extracting theme index from redux state not from cache
const useLocalCurrentThemeIndex = () => {
  const localCurrentThemeIndex = useAppSelector(selectLocalCurrentThemeIndex);
  const { data: rktQueryThemes } = useGetThemesQuery(undefined); // from cache
  const localCurrentThemeName = rktQueryThemes
    ? rktQueryThemes[localCurrentThemeIndex].name
    : '';
  const localCurrentThemeId = rktQueryThemes
    ? rktQueryThemes[localCurrentThemeIndex].id
    : 0;
  const isLocalCurrentThemePremium =
    !!rktQueryThemes && !!rktQueryThemes[localCurrentThemeIndex].premium;

  return {
    localCurrentThemeIndex,
    localCurrentThemeName,
    localCurrentThemeId,
    isLocalCurrentThemePremium,
  };
};

export default useLocalCurrentThemeIndex;
