import getSymbolFromCurrency from 'currency-symbol-map';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const useGetCurrency = (countryCurrency?:string) => {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  let currency;
  let symbol;
  if(cachedLoggedInUser) {
    currency = cachedLoggedInUser?.currency?.toUpperCase() || 'USD';
    symbol = getSymbolFromCurrency(cachedLoggedInUser?.currency?.toUpperCase() || 'USD');
  } else {
    if(countryCurrency) {
      currency = countryCurrency.toUpperCase() || 'USD';
      symbol = getSymbolFromCurrency(countryCurrency.toUpperCase() || 'USD');
    }
  }
  return {
    currency,
    symbol,
  }
};

export default useGetCurrency;