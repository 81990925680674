import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import { useEffect } from 'react';

export const googleOauthsource = 'google-oauth-popup';
export const fbOauthSource = 'fb-oauth-popup';

export const fbWindowNames = { signup: 'Facebook Sign Up', login: 'Facebook Login In' };
export const googleWindowNames = {signup: 'Google Sign Up', login: 'Google Sign In'};

function getWindowName(source: string) {
  if(fbWindowNames.login === source || fbWindowNames.signup === source) {
    return fbOauthSource;
  } 
  if(googleWindowNames.login === source || googleWindowNames.signup === source) {
    return googleOauthsource;
  }
}

const Oauth = () => {
  useEffect(() => {
    const params = window.location.search;

    if (window.opener && !window.closed) {
      const source = getWindowName(window.name);
      window.opener.postMessage({
        payload: params,
        source: source,
      }, "*"); // Consider specifying the origin here for security
      window.close();
    }
  }, []);

  return <FrontsLoader />;
};

export default Oauth;
