import { useContext } from 'react';
import { GuestSessionValidationContext } from 'views/guestSession/ValidateGuestSession/context/ValidationContextProvider';

const useValidationContextProvider = () => {
  const validationContext = useContext(GuestSessionValidationContext);
  if (!validationContext) {
    throw new Error(
      'validationContext must be consumed within GuestSessionValidationContextProvider'
    );
  }
  return validationContext;
};

export default useValidationContextProvider;
