import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import TinymceRichText from 'components/utilities/TinyMceEditor/TinymceRichText';

import generalUtilities from 'utilities/generalUtilities';
import SaveCancelButtons from 'components/Buttons/SaveCancelButtons';
import useBooleanStateController from 'hooks/useBooleanStateController';

// PURPOSE: to be used for editing pages with rich text  and action btns only

const initalEditorFormState = {
  isFormDirty: false,
  editorContent: '',
};

type ThisProps = {
  pageHeading: string;
  allowSavingForm: boolean;
  disableCancelBtn: boolean;
  disableUpdateBtn: boolean;
  editorCurrentContent: string;
  handleSavingForm: (updatedContent: string) => Promise<void>;
};

const EditPageWithRichText = ({
  pageHeading,
  allowSavingForm,
  disableCancelBtn,
  disableUpdateBtn,
  handleSavingForm,
  editorCurrentContent,
}: ThisProps) => {
  const history = useHistory();
  const isUserAgentWindows = generalUtilities.isUserAgentWindows();
  const [editFormState, updateEditFormState] = React.useState(initalEditorFormState);
  const editorContent = editFormState.editorContent;
  const isFormDirty = editFormState.isFormDirty;
  const [showLostChangesDlg, showLostChangesDlgController] = useBooleanStateController();

  const handleEditorContentChange = React.useCallback((updatedContent: string) => {
    updateEditFormState({
      isFormDirty: true,
      editorContent: updatedContent,
    });
  }, []);

  const openConfirmDiscardChangesPopup = () => {
    showLostChangesDlgController.setToggle();
  };

  const handleSaveForm = React.useCallback(
    async (isSaveKeyFunctionality: boolean = false) => {
      const isValidAction = isFormDirty && allowSavingForm;
      if (!isValidAction) return;
      try {
        const toastId = toast.loading('Saving');
        toast.dismiss(toastId);
        await handleSavingForm(editorContent);
        toast.success('Updated successfully! 🎉');
        if (isSaveKeyFunctionality) {
          updateEditFormState(initalEditorFormState);
          return;
        }
        history.goBack();
      } catch (error) {
        toast.error('Error updating');
      }
    },
    [history, allowSavingForm, handleSavingForm, editorContent, isFormDirty]
  );

  // ctrl + s functionality
  React.useEffect(() => {
    function handleSave(event: KeyboardEvent) {
      const isCtrlOrMetaKey = isUserAgentWindows ? event.ctrlKey : event.metaKey;
      const isPressedKeyIsS = event.key === 's';
      if (isPressedKeyIsS && isCtrlOrMetaKey) {
        event.preventDefault();
        handleSaveForm(true);
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    }
    window.addEventListener('keydown', handleSave);
    return () => window.removeEventListener('keydown', handleSave);
  }, [handleSaveForm, isUserAgentWindows]);

  return (
    <Box
      component='form'
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSaveForm();
      }}
      marginY={{ xs: 3, md: 5 }}
      paddingBottom={{ xs: '70px', md: '100px' }}
      position='relative'
    >
      <Container maxWidth='xl'>
        <Box>
          <KeyboardBackspaceIcon
            onClick={() => history.goBack()}
            sx={{
              fontSize: '3rem',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Box>
        <Box
          sx={{
            paddingTop: { xs: 2, md: 5 },
            paddingBottom: { xs: 2, md: 2 },
            paddingX: { xs: 0, md: 1 },
          }}
        >
          {/* -------------------- page  main heading */}
          <Typography component='h1' sx={mainHeadingStyle}>
            <Typography component='span' sx={headingSpanStyling}>
              {pageHeading}
            </Typography>
          </Typography>
          <Box>
            {/* ----------------- page editor ------------------- */}
            <TinymceRichText
              id=''
              height='600px'
              handleDescriptionChange={handleEditorContentChange}
              initialContent={editorCurrentContent}
              enableTimeOut={true}
            />
          </Box>
        </Box>
        {/* ------------------------ action btns ---------------------------- */}
        <Stack direction='row' spacing={4} sx={StickyButtonStyling}>
          <SaveCancelButtons
            showLostChangesDlg={showLostChangesDlg}
            openConfirmDiscardChangesPopup={openConfirmDiscardChangesPopup}
            goBack={() => history.goBack()}
            saveBtnChildren={'Save'}
            saveBtnProps={{
              disabled: !isFormDirty || disableUpdateBtn ? true : false,
              type: 'submit',
            }}
            cancelBtnChildren={'Cancel'}
            cancelBtnProps={{
              disabled: disableCancelBtn,
              onClick: () => history.goBack(),
              type: 'button',
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
};

export default EditPageWithRichText;

export const headingSpanStyling: SxProps = {
  backgroundColor: '#fff',
  border: '1px solid',
  borderRadius: '5px',
  color: 'var(--dark-black)',
  left: ' 50%',
  fontWeight: 700,
  padding: '0.5em',
  position: 'absolute',
  textTransform: 'uppercase',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export const mainHeadingStyle: SxProps = {
  paddingY: '0.5em',
  fontSize: {
    xs: '1.8rem',
    md: '2rem',
  },
  marginBottom: {
    xs: '1rem',
    md: '4rem',
  },
  position: 'relative',
  textAlign: 'center',
  maxWidth: '100%',
  '&:before ': {
    backgroundColor: 'var(--dark-black)',
    content: '""',
    height: '2px',
    left: 0,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '100%',
  },
};

export const StickyButtonStyling: SxProps = {
  justifyContent: { xs: 'center', md: 'flex-end' },  
  backgroundColor: 'white',
  width: '100%',
  zIndex: 139,  
};
