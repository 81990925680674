import React from 'react';
import Box from '@mui/material/Box';
import EditableTags from 'components/utilities/EditableTags/EditableTags';
import SPMTagInput from 'components/SPM_Forms/reusable-form-components/SPMEditableTags/SPMTagInput';
import StyledItemLabel from 'components/shared/StyledItemLabel';
import { Stack } from '@mui/material';

type EditableTagsCompProps = React.ComponentProps<typeof EditableTags>;
type HandleUpdatedTags = EditableTagsCompProps['onChange'];
type TagList = EditableTagsCompProps['taglist'];

export type SPMEditableTagsProps = {
  label: string;
  tagList: TagList;
  onTagsChange: HandleUpdatedTags;
};

const SPMEditableTags = ({ label, tagList, onTagsChange }: SPMEditableTagsProps) => {
  const handleUpdatedTags: HandleUpdatedTags = (updatedTagList: string[]) => {
    onTagsChange(updatedTagList);
  };

  return (
    <Stack direction='column' spacing={0.1} >
      <StyledItemLabel label={label} />
      <EditableTags
        TagsInput={<SPMTagInput tagList={tagList} onTagsChange={handleUpdatedTags} />}
        taglist={tagList}
        onChange={handleUpdatedTags}
      />
    </Stack>
  );
};

export default SPMEditableTags;
