import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import serverApi from 'lib/apiConfig';
import { GuestSession } from 'views/guestSession/GuestSession/GuesSessionTypes';
import guestSessionLoginTokenUtils from 'views/guestSession/GuestSessionLogin/guestSessionLoginTokenUtils';
import { Session } from 'types/sessions';

type GuestSessionWithCurrency = GuestSession & {currency: string};

const useGuestSession = () => {
  const { id: sessionId } = useParams<Record<'id', string>>();
  const [status, setStatus] = useState<Session['status']>('idle');
  const isSessionRejectedOrCancelled = status === 'cancelled' || status === 'rejected';
  const fetchConfig = isSessionRejectedOrCancelled
  ? undefined
  : {
      refreshInterval: 60_000, // 1 min
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    };

  // fetching guest session on mount/reconnect/onError(tries)
  const { data: session, error: sessionError, isLoading, isValidating } = useSWR<GuestSessionWithCurrency, Error>(
    `/guest-session/${sessionId}`,
    fetchGuestSession,
    fetchConfig,
  );

  useEffect(() => {
    if (session && session.status) {
      setStatus(session.status);
    }
  }, [session?.status])
  
  const sessionMeeting = session?.meetingDetails;
  const isSessionMeetingPaid = sessionMeeting?.isPaid ?? false;
  const isSessionPaid = session?.isPaid ?? false;
  const isSessionConfirmed = session?.status === 'confirmed';
  const isPaymentWaiting = session?.status === 'payment-waiting';
  return {
    apiData: {
      session,
      isLoading, sessionError, isValidating
    },
    sessionId,
    isSessionPaid,
    isSessionMeetingPaid,
    isSessionConfirmed,
    isPaymentWaiting,
    sessionMeeting,
  };
};

export default useGuestSession;

// utilities
async function fetchGuestSession(url: string) {
  const loginToken = guestSessionLoginTokenUtils.getGuestSessionLoginToken();
  return serverApi.v1
    .get<GuestSessionWithCurrency>(url, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`,
      },
    })
    .then((res) => res.data);
}
