import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';

export const initialEditUserDetails: EditUserDetailsForm = {
  name: '',
  email: '',
  emailPublic: false,
  phoneNumber: '',
  whatsAppNumber: '',
  whatsAppCountryCode: 1,
  userUrl: '',
  searchable: false,
  title: '',
  isAuthTagVisible: false,
  address: '',
  tags: [],
  userFavIcon: '',
  userDashboardImg: '',
  socialLinks: [],
  lat: 0,
  lng: 0,
  place_id: '',
  city: '',
  country: '',
  state: '',
  userBgImage: '',
};
