import React, { useEffect, useState } from 'react';
import { Alert, Box, Chip, Divider, Paper, Stack, TextField, Typography } from '@mui/material';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useHistory } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TimerIcon from '@mui/icons-material/Timer';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import Popup from 'components/utilities/Popups/Popup';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import IfElse from 'components/utilities/IfElse';
import GoogleLocationAutoCompleteInput from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import ClipboardCopyStyledBox from 'components/utilities/ClipboardCopyStyledBox/ClipboardCopyStyledBox';
import GoogleCaptcha from 'components/utilities/GoogleCaptcha';
import AlertDialogSlide from 'components/utilities/Popups/AlertDialog/AlertDialogSlide';
import ResponsiveButton, { responsive_button_icon } from 'components/Buttons/ResponsiveButton';
import {
  PopupHeading,
  PopupText,
  PopupSectionHeading,
  IconTextPair,
  dashboard_popup_icon_size,
  StatusValues,
} from 'components/dashboard/DashboardReusableComponent';
import ConfirmationDialog from 'Popups/ConfirmationDialog';

import { Session } from 'types/sessions';

import { meetingTypeToShow } from './MeetingSessionsTable';
import { getStatusForGuest } from 'views/guestSession/GuestSession/sub-components/SessionDetails/reusable-components/getStatusForGuest';
import { mstoMinsHrsWithText } from 'utilities/timeConvertions';
import { dashboard_icon_size } from 'views/Dashboard/Dashboard';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  useDeleteSessionMutation,
  useGetCurrentSessionsBySessionIdQuery,
  useUpdateSessionStatusMutation,
} from 'store/rtkqFeatures/api/SessionExtendedApi';
import useHostSessionDetailsToGuest from './hooks/useHostSessionDetailsToGuest';

const MeetingDetailsPopup = ({
  sessionId,
  open,
  setOpen,
}: {
  sessionId: Session['_id'];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: sessionDetails } = useGetCurrentSessionsBySessionIdQuery(sessionId);

  if (!sessionDetails) return <></>;

  return (
    <Popup
      dialogProps={{
        fullScreen: false,
        open,
        PaperProps: {
          sx: {
            overflow: 'auto',
            margin: 2,
            minWidth: { xs: '90%', md: '500px' },
          },
        },
      }}
      setOpen={setOpen}
      title=''
      showCloseIcon
    >
      <Stack
        direction={'column'}
        sx={{
          width: { xs: '100%', md: '100%' },
          p: { xs: 2, md: 4 },
          pb: { xs: 8, md: 4 },
          position: 'relative',
        }}
        spacing={2}
        divider={<Divider flexItem />}
      >
        <SessionPopupHeading sessionDetails={sessionDetails} />
        <SessionDurationAndPaid
          duration={sessionDetails.duration}
          amountPaid={sessionDetails.amountPaid}
          isPaid={sessionDetails.isPaid}
          currency={sessionDetails.currency}
        />
        <SessionPopupTime startAt={sessionDetails.startAt} endAt={sessionDetails.endAt} />
        <SessionPopupProvideDetailsToGuest sessionDetails={sessionDetails} />
        <SessionPopupBookedDetails sessionDetails={sessionDetails} />
        <SessionPopupDelete sessionDetails={sessionDetails} parentPopupSetFn={setOpen} />
      </Stack>
    </Popup>
  );
};

export default MeetingDetailsPopup;

function SessionPopupHeading({ sessionDetails }: { sessionDetails: Session }) {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const loggedInUserTimeZone = cachedLoggedInUser?.timeZone ?? 'UTC';

  const startTime = utcToZonedTime(sessionDetails.startAt, loggedInUserTimeZone);
  const month = format(startTime, 'MMM');
  const date = format(startTime, 'dd');

  const { backgroundColor, color, content } = getStatusForGuest(sessionDetails.status);

  return (
    <Stack direction={'column'} spacing={3.5}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '5ch 1fr',
          gap: 2,
          alignItems: 'baseline',
        }}
      >
        {/* Month date viewer */}
        <Stack
          component={Paper}
          elevation={2}
          direction={'column'}
          sx={{ minWidth: '5ch', borderRadius: '10px', overflow: 'hidden', textAlign: 'center' }}
        >
          <Typography
            sx={{
              textTransform: 'uppercase',
              bgcolor: 'error.main',
              pt: 0.5,
              color: (theme) => theme.palette.primary.contrastText,
            }}
          >
            {month}
          </Typography>
          <Typography sx={{ color: (theme) => theme.palette.text.primary }}>{date}</Typography>
        </Stack>

        <Stack>
          <PopupHeading>{sessionDetails.meetingDetails.title}</PopupHeading>
          <Typography variant={'body2'}>
            {meetingTypeToShow(sessionDetails.meetingDetails.type)} &nbsp;
            <Typography
              variant={'body2'}
              component={'span'}
              sx={{ bgcolor: backgroundColor, color: color, display: 'inline-block', px: 1 }}
            >
              {content}
            </Typography>
          </Typography>
        </Stack>
      </Box>
      <SessionPopupActions _id={sessionDetails._id} status={sessionDetails.status} />
    </Stack>
  );
}

// TODO - TEST THIS COMPONENT
function SessionDurationAndPaid({
  duration,
  isPaid,
  amountPaid,
  currency,
}: {
  duration: number;
  isPaid: boolean;
  amountPaid?: number;
  currency?: string;
}) {
  const { hrsWithText, minsWithText } = mstoMinsHrsWithText(duration);
  const displayDuration = `${hrsWithText} ${minsWithText}`;
  const displayPaidAmount = isPaid && amountPaid ? `${currency} ${amountPaid}` : 'Free';

  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      divider={<Divider orientation='vertical' flexItem />}
    >
      <Chip label={displayPaidAmount.toString()} />
      <Typography variant={'body1'} color={'text.primary'}>
        <TimerIcon sx={{ width: 25, height: 25, verticalAlign: 'bottom' }} /> &nbsp;{' '}
        {displayDuration} meeting
      </Typography>
    </Stack>
  );
}

function SessionPopupTime({ startAt, endAt }: { startAt: number; endAt: number }) {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const loggedInUserTimeZone = cachedLoggedInUser?.timeZone ?? 'UTC';

  const startTime = utcToZonedTime(startAt, loggedInUserTimeZone);
  const endTime = utcToZonedTime(endAt, loggedInUserTimeZone);

  const startHour = format(startTime, 'hh:mm');
  const startMeridiem = format(startTime, 'a');
  const startDate = format(startTime, 'PP');

  const endHour = format(endTime, 'hh:mm');
  const endMeridiem = format(endTime, 'a');
  const endDate = format(endTime, 'PP');

  return (
    <Stack spacing={1}>
      <PopupSectionHeading>Time</PopupSectionHeading>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '100px 35px 100px',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            component={'span'}
            fontWeight={500}
            variant={'h5'}
            color={(theme) => theme.palette.text.primary}
          >
            {startHour}
          </Typography>
          &nbsp;
          <Typography component={'span'} variant={'body2'}>
            {startMeridiem}
          </Typography>
          <Typography variant={'body2'}>{startDate}</Typography>
        </Box>
        <Typography variant={'subtitle2'}>
          <NavigateNextIcon sx={dashboard_popup_icon_size} />
        </Typography>
        <Box>
          <Typography component={'span'} fontWeight={500} variant={'h5'}>
            {endHour}
          </Typography>
          &nbsp;
          <Typography component={'span'} variant={'body2'}>
            {endMeridiem}
          </Typography>
          <Typography variant={'body2'}>{endDate}</Typography>
        </Box>
      </Box>
    </Stack>
  );
}

function SessionPopupBookedDetails({ sessionDetails }: { sessionDetails: Session }) {
  const participantName = sessionDetails.participant.name;
  const participantEmail = sessionDetails.participant.email;
  const participantPhone: string | undefined = sessionDetails.participant?.phoneNumber;
  const guestMeetingPreparationText: string | undefined =
    sessionDetails.guestMeetingPreparationText;
  const isSessionPaidByGuest = sessionDetails.isPaid;
  const guestPaymentReceipt = sessionDetails.receiptUrl ?? '';

  return (
    <Stack spacing={1}>
      <PopupSectionHeading>Booked by</PopupSectionHeading>
      <Stack direction={'column'} spacing={1}>
        <IconTextPair
          icon={<PermIdentityIcon sx={dashboard_popup_icon_size} />}
          text={participantName}
        />
        <IconTextPair
          icon={<MailOutlineIcon sx={dashboard_popup_icon_size} />}
          text={participantEmail}
          component={'a'}
          href={`mailto:${participantEmail}`}
          sx={{ textDecoration: 'underline !important' }}
        />
        {participantPhone && (
          <IconTextPair
            icon={<LocalPhoneIcon sx={dashboard_popup_icon_size} />}
            text={participantPhone}
            component={'a'}
            href={`tel:${participantPhone}`}
            sx={{ textDecoration: 'underline !important' }}
          />
        )}
        {guestMeetingPreparationText && (
          <IconTextPair
            icon={<EventNoteIcon sx={{ ...dashboard_popup_icon_size, alignSelf: 'baseline' }} />}
            text={guestMeetingPreparationText}
          />
        )}
        {isSessionPaidByGuest && !!guestPaymentReceipt && (
          <IconTextPair
            icon={<ReceiptIcon sx={dashboard_popup_icon_size} />}
            text={'Receipt'}
            component={'a'}
            href={guestPaymentReceipt}
            rel='noreferrer'
            target='_blank'
          />
        )}
      </Stack>
    </Stack>
  );
}

function SessionPopupProvideDetailsToGuest({ sessionDetails }: { sessionDetails: Session }) {
  const {
    thisSession,
    isOnlineMeeting,
    hostToGuestInfo,
    googleMapsApiScriptStatus,
    mutationsStatus: {
      isSessionHostGuestMeetingDetailsUpdationInProgress,
      isMeeingPInstructionsOrLocationDetailsUpdateInProgress,
    },
    guestMeetingLocationGoogleMapsLink,
    getGoogleAutoCompleteLocationDetails,
    handleHostToGuestDataInputs,
    recaptchaRef,
    setHostToGuestInfo,
    locationPrivacyConcernDialogBox,
    decideAllowingHostSharingDetailsWithGuest,
    submitHostToGuestDetailsForm,
  } = useHostSessionDetailsToGuest(sessionDetails._id, sessionDetails.userId);

  const onGoogleRecaptchWithToken = (token: string) => {
    if (token) {
      setHostToGuestInfo((prev) => ({
        ...prev,
        isRobot: false,
      }));
    }
  };

  const onGoogleRecaptchaExpiredOrError = () => {
    setHostToGuestInfo((prev) => ({
      ...prev,
      isRobot: true,
    }));
  };

  const closeLocationDetailsPrivacyDialogBox = () => {
    locationPrivacyConcernDialogBox.setIsAlertDialogBoxOpen.setFalse();
  };

  if (!thisSession) return <></>;

  const sessionStatus = thisSession.status;
  const isSessionStatusConfirmed = sessionStatus === 'confirmed';
  const isCopyLocationVisible = isOnlineMeeting && !!hostToGuestInfo.copyLink;
  const showVisitLocation = Boolean(
    guestMeetingLocationGoogleMapsLink && guestMeetingLocationGoogleMapsLink.includes('/maps')
  );

  return (
    <Stack spacing={2}>
      <IfElse condition={isCopyLocationVisible}>
        <ClipboardCopyStyledBox
          labelText='Online Meeting Link'
          copyText={hostToGuestInfo.copyLink}
        />
      </IfElse>

      <PopupSectionHeading>Provide details to guest</PopupSectionHeading>

      {/* Google autocomplete location */}
      {isOnlineMeeting ? (
        <TextField
          fullWidth
          variant='outlined'
          label='Link'
          name='link'
          value={hostToGuestInfo.link}
          onChange={handleHostToGuestDataInputs}
          size='small'
          sx={{ flexGrow: 1 }}
          InputLabelProps={{
            sx: {
              width: '100%',
              fontSize: { xs: '13px', md: '13px' },
            },
          }}
        />
      ) : (
        <Stack direction={'row'} spacing={3} useFlexGap flexWrap={'wrap'}>
          <IfElse condition={googleMapsApiScriptStatus === 'ready'}>
            <GoogleLocationAutoCompleteInput
              initialSuggestion={sessionDetails.inPersonMeetingGuestLocationDetails?.suggestion}
              clearLocationValue={hostToGuestInfo.clearLocationValue}
              getLocationDetails={getGoogleAutoCompleteLocationDetails}
              textFieldProps={{
                label: 'Meeting Location',
                size: 'small',
                sx: {
                  flexGrow: 1,
                  '& label': {
                    width: '100%',
                    fontSize: { xs: '13px', md: '13px' },
                  },
                },
                fullWidth: true,
              }}
              wrapperSx={{
                flexGrow: 1,
                fontSize: { xs: '13px', md: '13px' },
                maxWidth: showVisitLocation ? '350px' : '100%',
              }}
            />
            <IfElse condition={showVisitLocation}>
              <ResponsiveButton
                onClick={() => {
                  window.open(guestMeetingLocationGoogleMapsLink, '_blank');
                }}
                sx={{ ml: 'auto' }}
              >
                Visit Location
              </ResponsiveButton>
            </IfElse>
          </IfElse>
        </Stack>
      )}

      <Stack direction={'column'} spacing={1}>
        <TextField
          value={hostToGuestInfo.extraInstructions}
          onChange={handleHostToGuestDataInputs}
          variant='outlined'
          multiline
          label='Extra Instructions'
          name='extraInstructions'
          size='small'
          sx={{ flexGrow: 1, fontSize: { xs: '13px', md: '13px' } }}
          InputLabelProps={{
            sx: {
              fontSize: { xs: '13px', md: '13px' },
            },
          }}
        />

        <IfElse condition={!isSessionStatusConfirmed}>
          <Alert severity='info'>
            Extra instructions are hidden from client until session is confirmed.
          </Alert>
        </IfElse>

        <IfElse condition={hostToGuestInfo.isCaptchaVisible}>
          <GoogleCaptcha
            ref={recaptchaRef}
            onError={onGoogleRecaptchaExpiredOrError}
            onExpired={onGoogleRecaptchaExpiredOrError}
            onChangeWithValidCaptchaValue={onGoogleRecaptchWithToken}
            sx={{ ml: 'auto !important' }}
          />
        </IfElse>

        <IfElse condition={!hostToGuestInfo.isRobot}>
          {/*  send email  */}
          <ResponsiveButton
            sx={{ ml: 'auto !important' }}
            disabled={
              isMeeingPInstructionsOrLocationDetailsUpdateInProgress ||
              isSessionHostGuestMeetingDetailsUpdationInProgress
            }
            variant='text'
            color='secondary'
            onClick={submitHostToGuestDetailsForm}
            endIcon={<ForwardToInboxIcon sx={dashboard_icon_size} />}
          >
            {(isMeeingPInstructionsOrLocationDetailsUpdateInProgress ||
              isSessionHostGuestMeetingDetailsUpdationInProgress) && (
              <SectionLoader isLoading={true} />
            )}
            Send email update
          </ResponsiveButton>
        </IfElse>

        {/*  Alert Dialog Box  */}
        <AlertDialogSlide
          cancelBtnText='Yes'
          dialogTitleElement={
            <>
              <PopupSectionHeading>details privacy concern</PopupSectionHeading>
              <Divider />
            </>
          }
          actionText='No'
          isDialogBoxOpen={locationPrivacyConcernDialogBox.isAlertDialogBoxOpen}
          handleDialogCancelBtnClick={() => {
            closeLocationDetailsPrivacyDialogBox();
            decideAllowingHostSharingDetailsWithGuest(true);
          }}
          handleDialogActionBtnClick={() => {
            closeLocationDetailsPrivacyDialogBox();
            decideAllowingHostSharingDetailsWithGuest(false);
          }}
        >
          <IfElse
            condition={isOnlineMeeting}
            alternative={
              <PopupText component={'p'} fontWeight={500}>
                Your location and instructions in this meeting are hidden to the client until you
                confirm the meeting. For this client only, would you like to reveal the updated
                location and instructions?
              </PopupText>
            }
          >
            <PopupText component={'p'} fontWeight={500}>
              Your meeting link and instructions in this meeting are hidden to the client until you
              confirm the meeting. For this client only, would you like to reveal the updated
              meeting link and instructions?
            </PopupText>
          </IfElse>
        </AlertDialogSlide>
      </Stack>
    </Stack>
  );
}

function SessionPopupActions({ _id, status }: Pick<Session, '_id' | 'status'>) {
  type UserActions = 'accept' | 'reject' | 'new time';

  const history = useHistory();

  const isSessionPending = status === StatusValues.GUEST_REQUEST_MEETING;
  const isSelectedProposedTime = status === StatusValues.GUEST_SELECTED_PROPOSED_TIME;
  const isSessionConfirmedOrRejected =
    status === StatusValues.CANCELLED || status === StatusValues.REJECT;

  const [udpateSessionStatus, { isLoading: isStatusUpdationInProgress }] =
    useUpdateSessionStatusMutation();

  const handleStatusChange = async (userAction: UserActions) => {
    const isUserActionReject = userAction === 'reject';
    const isUserActionProposeNewTime = userAction === 'new time';

    if (isUserActionProposeNewTime) {
      history.push(`/propose-new-time/${_id}`);
      return;
    }
    udpateSessionStatus({
      sessionId: _id,
      status: isUserActionReject ? StatusValues.REJECT : StatusValues.CONFIRM,
    });
  };

  if (isSessionConfirmedOrRejected) return <></>;

  return (
    <Stack spacing={1} useFlexGap direction={'row'} flexWrap={'wrap'}>
      <IfElse condition={isSessionPending || isSelectedProposedTime}>
        <>
          <ResponsiveButton
            variant='text'
            color='success'
            disabled={isStatusUpdationInProgress}
            onClick={() => handleStatusChange('accept')}
            endIcon={<EventAvailableIcon sx={responsive_button_icon} />}
          >
            Accept
          </ResponsiveButton>
          <ResponsiveButton
            variant='text'
            color='error'
            disabled={isStatusUpdationInProgress}
            onClick={() => handleStatusChange('reject')}
            endIcon={<EventBusyIcon sx={responsive_button_icon} />}
          >
            Reject
          </ResponsiveButton>
        </>
      </IfElse>
      <ResponsiveButton
        color='secondary'
        variant='text'
        disabled={isStatusUpdationInProgress}
        onClick={() => handleStatusChange('new time')}
        endIcon={<MoreTimeIcon sx={responsive_button_icon} />}
      >
        Propose new time
      </ResponsiveButton>
    </Stack>
  );
}

function SessionPopupDelete({
  sessionDetails,
  parentPopupSetFn,
}: {
  sessionDetails: Session;
  parentPopupSetFn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [deleteSession, { isLoading: isDeleting, data: deletedResponse }] =
    useDeleteSessionMutation();
  const isDeleted = deletedResponse?.isDeleted;

  function handleConfirmDelete() {
    deleteSession(sessionDetails._id);
    setConfirmDelete(false);
  }

  useEffect(() => {
    if (isDeleted) {
      parentPopupSetFn(false);
    }
  }, [isDeleted, parentPopupSetFn]);

  return (
    <Stack direction={'column'} spacing={1}>
      <PopupSectionHeading color='error.main'>Delete Session</PopupSectionHeading>

      <Stack direction={'column'} spacing={2}>
        <IconTextPair
          icon={<DeleteIcon sx={dashboard_popup_icon_size} />}
          text='Deleting session is irreversible.'
        />
        <ResponsiveButton
          onClick={() => setConfirmDelete(true)}
          color='error'
          sx={{ alignSelf: 'flex-start' }}
          endIcon={<DeleteIcon sx={{ ...responsive_button_icon, color: 'error.main' }} />}
        >
          Delete session
        </ResponsiveButton>
      </Stack>

      <ConfirmationDialog
        title='Confirm Delete'
        content={
          <>
            Are you sure you want to delete{' '}
            <span style={{ fontWeight: 600 }}>{sessionDetails.meetingDetails.title} </span> session
          </>
        }
        handleDialogClose={() => setConfirmDelete(false)}
        handleAgreeBtnClick={handleConfirmDelete}
        ariaDescribedby='alert-dialog-meeting-delete'
        confirmText='Delete'
        isLoading={isDeleting}
        open={confirmDelete}
        dialogProps={{
          open: confirmDelete,
          sx: { zIndex: 1501 },
          maxWidth: 'xs',
        }}
      />
    </Stack>
  );
}
