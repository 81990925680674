import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import apiServices from 'lib/services';
import { green } from '@mui/material/colors';
import IfElse from 'components/utilities/IfElse';
import useAsyncStatus from 'hooks/useAsyncStatus';

type ThisProps = {
  sessionId: string;
  sessionMeetingId: string;
  isHidden?: boolean;
};

const GuestSessionStripePayment = ({
  sessionId,
  sessionMeetingId,
  isHidden = false,
}: ThisProps) => {
  const {
    isLoading: isPaymentStatusLoading,
    updateStatus: updatePaymentStatus,
    Actions: PaymentStatusActions,
  } = useAsyncStatus();

  const handleMakePayment = async () => {
    if (isPaymentStatusLoading) return;
    try {
      updatePaymentStatus({
        type: PaymentStatusActions.LOADING,
      });
      const checkoutLink =
        await apiServices.guestSessionServices.createGuestCheckoutSession(sessionId);
      if (!checkoutLink) throw new Error('checkoutLink not found');
      window.location.replace(checkoutLink);
    } catch (error) {
      toast.error('Some error occurred while creating checkout session');
      updatePaymentStatus({
        type: PaymentStatusActions.ERROR,
        payload: '',
      });
    }
  };

  if (isHidden) return <></>;

  return (
    <Box>
      <StripePaymentBtn
        handleMakePayment={handleMakePayment}
        showLoader={isPaymentStatusLoading}
      />
    </Box>
  );
};

export default GuestSessionStripePayment;

// sub components below

type StripePaymentBtnProps = {
  handleMakePayment: () => void;
  showLoader: boolean;
};

function StripePaymentBtn({ showLoader, handleMakePayment }: StripePaymentBtnProps) {
  return (
    <Button
      variant='contained'
      onClick={handleMakePayment}
      sx={{
        width: '160px',
        height: '49px',
        boxShadow: 0,
        '& *': {
          fontSize: '1.6rem',
        },
        backgroundColor: green[500],
        '&:hover': {
          backgroundColor: green[400],
        },
      }}
    >
      <Stack direction='row' spacing={1} justifyContent='center' alignItems='center'>
        <IfElse
          condition={showLoader}
          alternative={<Box component='span'>Pay for session</Box>}
        >
          <CircularProgress
            size={18}
            sx={{
              color: 'common.white',
            }}
          />
        </IfElse>
      </Stack>
    </Button>
  );
}
