import React from 'react';
import { Avatar, Link, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';

import { LoggedInUser } from 'types/user';
import socialIcons from 'views/EditUserDetails/sub-components/SocialIcons';

type SocialIconsListProps = Partial<
  Pick<LoggedInUser, 'whatsapp' | 'socialLinks' | 'email' | 'address' | 'phone' | 'emailPublic'>
> & {
  styling?: SxProps<Theme>;
  showOnlyIcon?: boolean;
  componentFromSearch?: boolean;
  socialIconsSize?: { width: { xs: number; md: number }; height: { xs: number; md: number } };
  spacing?: { xs: number; md: number };
};  

const defaultSocialIconsSize = { width: { xs: 25, md: 30 }, height: { xs: 25, md: 30 } };
type socialIconsKeys = keyof typeof socialIcons;

// NOTE: using in editor profile card
const SocialIconsList = (props: SocialIconsListProps) => {
  const {
    socialLinks,
    whatsapp,
    email,
    emailPublic,
    styling,
    showOnlyIcon = false,
    address,
    phone,
    componentFromSearch = false,
    socialIconsSize = defaultSocialIconsSize,
    spacing={ xs: 2, md: 2 }
  } = props;

  const whatsappNumber = `${whatsapp?.countryCode}${whatsapp?.num}`;
  const spacingStyling = { '& > :last-of-type': { md: { paddingRight: 0 } } };
  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = socialIcons(socialIconsSize).others.props.src;
  };
  const mergeStyling = Object.assign(spacingStyling, styling);
  const siteNames = Object.keys(socialIcons());
  return (
    <Stack
      direction='row'
      sx={{...mergeStyling}}
      marginTop={componentFromSearch ? 0 : 0}
      justifyContent={{ xs: 'flex-end', md: 'flex-end' }}
      flexWrap={{ xs: 'wrap', md: 'wrap' }}
      rowGap={{ xs: 2, md: 2 }}
      columnGap={spacing}
      alignItems='center'
    >
      {/* Just here for testing purpose */}
      {/* <Icons site={socialIcons(socialIconsSize)['fronts']} />
      <Icons site={socialIcons(socialIconsSize)['medium']} />
      <Icons site={socialIcons(socialIconsSize)['substack']} />
      <Icons site={socialIcons(socialIconsSize)['twitter']} />
      <Icons site={socialIcons(socialIconsSize)['tiktok']} />
      <Icons site={socialIcons(socialIconsSize)['address']} />
      <Icons site={socialIcons(socialIconsSize)['email']} />
      <Icons site={socialIcons(socialIconsSize)['instagram']} /> */}
      {Array.isArray(socialLinks) &&
        socialLinks.map((socialLink, i) => {
          if(!socialLink) return [];
          const url = socialLink.url || socialLink.link || socialLink.iconUrl;
          const siteName = socialLink.site;
          const siteIconName = (socialLink && (socialLink.link || socialLink.site))
            ?.trim()
            .toLowerCase();
          const showSquareIcon =
            siteIconName?.includes('yout') ||
            siteIconName?.includes('linkedin.com') ||
            siteIconName?.includes('fronts');
          return (
            <Tooltip
              title={
                <Typography sx={{ fontSize: { xs: 12, md: 14 }, color: '#fff' }}>
                  {url}
                </Typography>
              }
              key={i}
              arrow
              sx={{ fontSize: 22 }}
            >
              <Link href={url} target='_blank'>
                {socialLink?.iconUrl ? (
                  <Avatar
                    // variant={showSquareIcon ? 'square' : 'circular'}
                    variant={'square'}
                    sx={{
                      bgcolor: 'transparent',
                      ...socialIconsSize,
                      '& .MuiAvatar-img': { objectFit: 'contain' },
                    }}
                    src={socialLink.iconUrl}
                    imgProps={{ onError: handleImgError }}
                  ></Avatar>
                ) : siteNames.includes(siteName as socialIconsKeys) ? (
                  socialIcons(socialIconsSize)[siteName as socialIconsKeys]
                ) : (
                  socialIcons(socialIconsSize).others
                )}
              </Link>
            </Tooltip>
          );
        })}
      {!!whatsapp?.num && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 12, md: 14 }, color: '#fff' }}>
              {whatsapp?.num}
            </Typography>
          }
          arrow
          sx={{ fontSize: 22 }}
        >
          <Link href={`https://wa.me/${whatsappNumber}`} target='_blank'>
            {socialIcons(socialIconsSize).whatsapp}
          </Link>
        </Tooltip>
      )}
      {!!showOnlyIcon && (
        <>
          {emailPublic && email && (
            <Tooltip
              title={
                <Typography sx={{ fontSize: { xs: 12, md: 14 }, color: '#fff' }}>
                  {email}
                </Typography>
              }
              arrow
              sx={{ fontSize: 22 }}
            >
              <Link href={`mailto:${email}`} sx={{ p: 0 }} target='_blank'>
                {socialIcons(socialIconsSize).email}
              </Link>
            </Tooltip>
          )}
          {/* NOTE ^ Hiding address it for now */}
          {/* {(!!address) && (
            <Tooltip
              title={
                <Typography sx={{ fontSize: { xs: 12, md: 14 }, color: '#fff' }}>
                  {address}
                </Typography>
              }
              arrow
              sx={{ fontSize: 22 }}
            >
              <Link
                target='_blank'
                sx={{ p: 0 }}
                href={`https://www.google.com/maps/search/?api=1&query=${address.replaceAll(
                  ' ',
                  '+'
                )}`}
              >
                {socialIcons(socialIconsSize).address}
              </Link>
            </Tooltip>
          )} */}
          {!!phone && (
            <Tooltip
              title={
                <Typography sx={{ fontSize: { xs: 12, md: 14 }, color: '#fff' }}>
                  {phone}
                </Typography>
              }
              arrow
              sx={{ fontSize: 22 }}
            >
              {/* socialLink.site */}
              <Link target='_blank' href={`tel:${phone}`} sx={{ p: 0 }}>
                {socialIcons(socialIconsSize).phone}
              </Link>
            </Tooltip>
          )}
        </>
      )}
    </Stack>
  );
};

export default SocialIconsList;