import { Box, Grid, Stack } from '@mui/material';

import { useAppSelector } from 'hooks/redux';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { getInPx } from 'components/utilities/styleUtilities/styleUtilities';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';
import { initialThemeState, ThemeType } from 'themes/AppThemeProvider/themeType';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import { TinymceFormatPreserver } from 'components/utilities/TinyMceEditor/TinymceRichText';
import LayoutContainer from 'components/Containers/LayoutContainer';
import AboutHeading from './sub-components/AboutHeading';
import AboutUsEditBtn from './sub-components/AboutUsEditBtn';

const CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER = 2.5;

const AboutUs = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { about } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
    selectFromResult: ({ data: editorData }) => ({
      aboutHeading: editorData?.ui?.aboutHeading ?? '',
      about: editorData?.about ?? '',
    }),
  });
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { aboutStyles }: { aboutStyles: ThemeType['about'] } = useGetThemesQuery(
    undefined,
    {
      selectFromResult: ({ data: themes }) => ({
        aboutStyles: themes
          ? themes[localCurrentThemeIndex].about
          : initialThemeState.about,
      }),
    }
  );

  if (!aboutStyles) {
    return <p>about styling loading...</p>;
  }

  const {
    background,
    backgroundSize,
    backgroundBlendMode,
    paddingBottom,
    paddingTop,
    title,
    // description,
  } = aboutStyles;

  return (
    <>
      <Grid        
        item
        boxShadow={0}
        padding={{ xs: 0, md: 0, xl: 0 }}
        sx={{
          background: background,
          backgroundSize: backgroundSize,
          backgroundBlendMode: backgroundBlendMode,
        }}
      >
        <LayoutContainer          
          sx={{
            paddingTop: {
              xs: getInPx(paddingTop / CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER),
              sm: getInPx(paddingTop),
            },
            paddingBottom: {
              xs: getInPx(paddingBottom / CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER),
              sm: getInPx(paddingBottom),
            },
          }}
        >
          <Stack spacing={3} sx={{ position: 'relative' }}>
            {/* ----------- about us heading --------------------- */}
            <AboutHeading
              fontSize={title.fontSize}
              color={title.color}
              textAlign={title.textAlign}
            />
            <AboutUsEditBtn
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                transform: 'translateY(-100%)',
              }}
            />
            <TinymceFormatPreserver>
              <Box
                sx={{
                  '& *': {
                    color: title.color,
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: about || '',
                }}
              />
            </TinymceFormatPreserver>
          </Stack>
        </LayoutContainer>
      </Grid>
    </>
  );
};

export default AboutUs;
