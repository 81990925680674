export const languages = [
  { language: "English", languageDir: "ltr" },
  { language: "Afrikaans", languageDir: "ltr" },
  { language: "Albanian", languageDir: "ltr" },
  { language: "Amharic", languageDir: "ltr" },
  { language: "Arabic", languageDir: "rtl" },
  { language: "Armenian", languageDir: "ltr" },
  { language: "Assamese", languageDir: "ltr" },
  { language: "Azerbaijani", languageDir: "ltr" },
  { language: "Belorussian", languageDir: "ltr" },
  { language: "Bengali", languageDir: "ltr" },
  { language: "Bodo", languageDir: "ltr" },
  { language: "Bosnian", languageDir: "ltr" },
  { language: "Bulgarian", languageDir: "ltr" },
  { language: "Burmese", languageDir: "ltr" },
  { language: "Catalan", languageDir: "ltr" },
  { language: "Chinese", languageDir: "ltr" },
  { language: "Croatian", languageDir: "ltr" },
  { language: "Czech", languageDir: "ltr" },
  { language: "Danish", languageDir: "ltr" },
  { language: "Dogri", languageDir: "ltr" },
  { language: "Dutch", languageDir: "ltr" },
  { language: "Esperanto", languageDir: "ltr" },
  { language: "Estonian", languageDir: "ltr" },
  { language: "Finnish", languageDir: "ltr" },
  { language: "French", languageDir: "ltr" },
  { language: "Georgian", languageDir: "ltr" },
  { language: "German", languageDir: "ltr" },
  { language: "Greek", languageDir: "ltr" },
  { language: "Gujarati", languageDir: "ltr" },
  { language: "Hausa", languageDir: "ltr" },
  { language: "Hebrew", languageDir: "rtl" },
  { language: "Hindi", languageDir: "ltr" },
  { language: "Hungarian", languageDir: "ltr" },
  { language: "Indonesian", languageDir: "ltr" },
  { language: "Icelandic", languageDir: "ltr" },
  { language: "Igbo", languageDir: "ltr" },
  { language: "Italian", languageDir: "ltr" },
  { language: "Japanese", languageDir: "ltr" },
  { language: "Kannada", languageDir: "ltr" },
  { language: "Kashmiri", languageDir: "ltr" },
  { language: "Kazakh", languageDir: "ltr" },
  { language: "Khmer", languageDir: "ltr" },
  { language: "Konkani", languageDir: "ltr" },
  { language: "Korean", languageDir: "ltr" },
  { language: "Lao", languageDir: "ltr" },
  { language: "Latvian", languageDir: "ltr" },
  { language: "Lithuanian", languageDir: "ltr" },
  { language: "Luxembourgish", languageDir: "ltr" },
  { language: "Macedonian", languageDir: "ltr" },
  { language: "Malagasy", languageDir: "ltr" },
  { language: "Malay", languageDir: "ltr" },
  { language: "Malayalam", languageDir: "ltr" },
  { language: "Maltese", languageDir: "ltr" },
  { language: "Marathi", languageDir: "ltr" },
  { language: "Mongolian", languageDir: "ltr" },
  { language: "Nepali", languageDir: "ltr" },
  { language: "Norwegian", languageDir: "ltr" },
  { language: "Odia", languageDir: "ltr" },
  { language: "Pashto", languageDir: "ltr" },
  { language: "Persian", languageDir: "rtl" },
  { language: "Polish", languageDir: "ltr" },
  { language: "Portuguese", languageDir: "ltr" },
  { language: "Punjabi", languageDir: "ltr" },
  { language: "Romanian", languageDir: "ltr" },
  { language: "Russian", languageDir: "ltr" },
  { language: "Sindhi", languageDir: "ltr" },
  { language: "Sinhala", languageDir: "ltr" },
  { language: "Slovak", languageDir: "ltr" },
  { language: "Slovenian", languageDir: "ltr" },
  { language: "Somali", languageDir: "ltr" },
  { language: "Spanish", languageDir: "ltr" },
  { language: "Sundanese", languageDir: "ltr" },
  { language: "Swahili", languageDir: "ltr" },
  { language: "Swedish", languageDir: "ltr" },
  { language: "Tagalog", languageDir: "ltr" },
  { language: "Tajik", languageDir: "ltr" },
  { language: "Tamil", languageDir: "ltr" },
  { language: "Tatar", languageDir: "ltr" },
  { language: "Telugu", languageDir: "ltr" },
  { language: "Thai", languageDir: "ltr" },
  { language: "Yiddish", languageDir: "rtl" },
  { language: "Yoruba", languageDir: "ltr" },
  { language: "Turkish", languageDir: "ltr" },
  { language: "Ukrainian", languageDir: "ltr" },
  { language: "Urdu", languageDir: "rtl" },
  { language: "Uzbek", languageDir: "ltr" },
  { language: "Uyghur", languageDir: "ltr" },
  { language: "Vietnamese", languageDir: "ltr" },
  { language: "Welsh", languageDir: "ltr" },
  { language: "Xhosa", languageDir: "ltr" },
  { language: "Zulu", languageDir: "ltr" }
];
