import { CustomPaletteOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

export const themeColor = "#fef5ff";
export const gradientBgColor = "linear-gradient(135deg, #f0f4f7, #d9e3f0)";
export const inputBgColor = "#f6f8fa";

const lightTheme: CustomPaletteOptions = {            
  primary: {              
    dark: '#9e25d3',
    main: '#bb00bb',
    light: '#fe91f8',
  },
  secondary: {
    dark: grey[900],
    main: grey[800],
    light: grey[700],
  },
  link: {
    main: '#0d6efd',
  },
  text: {
    primary: grey[800],
    secondary: grey[600],
    disabled: grey[500],
  },
  background: {    
    default: '#ffffff',        
    // paper: '#ffffff',
    // theme: '#fef5ff',
  },
}

export default lightTheme;