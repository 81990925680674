import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tag from 'components/utilities/EditableTags/sub-components/Tag/Tag';
import { SxProps, Theme } from '@mui/material';
import IfElse from 'components/utilities/IfElse';
import EUDFormValidationErrorMsg from 'components/EditUserDetailsLatest/utility-components/EUDFormValidationErrorMsg';

const TAGS_VH_SPACING = 1;

const listContainerStyling: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  rowGap: TAGS_VH_SPACING,
  columnGap: TAGS_VH_SPACING,
  pl: 0,
  pt: {
    xs: '1rem',
    sm: '1rem',
  },
};

export type EditableTagsProps = {
  taglist: string[];
  TagsInput: JSX.Element;
  error?: string | string[];
  onChange: (updatedTagList: string[]) => void;
};

const EditableTags = ({ TagsInput, taglist, error, onChange }: EditableTagsProps) => {
  const isTagListArray = Array.isArray(taglist);
  const isValidTagListLength = isTagListArray && Boolean(taglist.length);
  const errorMsg = !Array.isArray(error) && error;
  return (
    <Stack
      spacing={{
        xs: 0,
        sm: 0,
      }}
    >
      <Box>{TagsInput}</Box>
      {/* ------------ field error msg */}
      <EUDFormValidationErrorMsg errorMsg={errorMsg || ''} isVisible={!!errorMsg} />
      {/* -------------- tag list rendering */}
      <IfElse condition={isValidTagListLength}>
        <Box component='ul' sx={listContainerStyling}>
          {taglist.map((tag, index) => {
            return <Tag key={index} taglist={taglist} label={tag} onChange={onChange} />;
          })}
        </Box>
      </IfElse>
    </Stack>
  );
};

export default EditableTags;
