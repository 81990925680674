import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import {
  MeetingAvailability,
  PhysicalOrVirtualMeeting,
  PhysicalOrVirtualMeetingList,
  PhysicalOrVirtualNewMeeting,
} from 'types/meeting';

const meetingExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMeetingsByUserId: build.query<PhysicalOrVirtualMeetingList, string>({
      query: (userId) => `/meetings/user/${userId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Meeting' as const, id: 'LIST' },
              ...result.map(({ _id }) => ({ type: 'Meeting' as const, id: _id })),
            ]
          : [{ type: 'Meeting' as const, id: 'LIST' }],
    }),
    getMeetingByMeetingId: build.query<PhysicalOrVirtualMeeting, string>({
      query: (meetingId) => `/meetings/${meetingId}`,
    }),
    deleteMeetingById: build.mutation<void, string>({
      query: (meetingId) => ({
        url: `/meetings/${meetingId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, err, meetingId) => [
        { type: 'Meeting', id: meetingId },
        'SortedSPMIds',
      ],
    }),
    createMeeting: build.mutation<void, PhysicalOrVirtualNewMeeting>({
      query: (newMeeting) => ({
        url: `/meetings/`,
        method: 'POST',
        body: newMeeting,
      }),
      invalidatesTags: () => [{ type: 'Meeting' as const, id: 'LIST' }, 'SortedSPMIds'],
    }),
    updateMeeting: build.mutation<
      void,
      {
        meetingId: string;
        updatedMeeting: PhysicalOrVirtualMeeting;
      }
    >({
      query: ({
        meetingId,
        updatedMeeting: { _id, userId, isDeleted, type, ..._updatedMeeting },
      }) => {
        return {
          url: `/meetings/${meetingId}`,
          method: 'PUT',
          body: _updatedMeeting,
        };
      },
      invalidatesTags: () => [{ type: 'Meeting' as const, id: 'LIST' }],
    }),
    updateMeetingTentativeAvailability: build.mutation<void, MeetingAvailability & {meetingId: string}>({
      query: (arg) => {
        return {
          url: `/meetings/update-tentative-availability/${arg.meetingId}`,
          method: 'PATCH',
          body: {
            ...arg,
          }
        }
      }
    })    
  }),
});

export const {  
  useLazyGetMeetingByMeetingIdQuery,
  useGetMeetingsByUserIdQuery,
  useDeleteMeetingByIdMutation,
  useCreateMeetingMutation,
  useUpdateMeetingMutation,
  useUpdateMeetingTentativeAvailabilityMutation,
} = meetingExtendedApi;

export default meetingExtendedApi;
