import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { grey, red } from '@mui/material/colors';
import { Return } from 'react-cool-onclickoutside';

type InPlaceActionTakerProps = {
  error?: boolean;
  editorMaginBottom?: string;
  show: boolean;
  children: JSX.Element;
  uponConfirmClick?: () => void;
  uponRejectClick?: () => void;
  domRef: Return;
  borderRadius?: string;
};

const InPlaceActionTaker: React.FunctionComponent<InPlaceActionTakerProps> = ({
  show,
  children,
  uponRejectClick,
  uponConfirmClick,
  domRef,
  borderRadius = '5px',
  editorMaginBottom = '2rem',
  error,
}) => {
  if (!show) {
    return children;
  }

  return (
    <Box
      ref={domRef}
      sx={{
        marginBottom: {
          xs: '3rem',
          sm: '1rem',
        },
        pb: '2rem',
      }}
      >
      <Box      
        sx={{
          border: `2px solid ${grey[800]}`,
          borderRadius: borderRadius,
          marginBottom: '0.9rem',
          borderColor: error ? red[700]: grey[800],
          position: 'relative',
        }}
      >
        {children}
      </Box>
      <Stack direction='row' justifyContent='flex-end' alignItems='center'>
        <Stack
          display='inline-flex'
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <Button
            disableTouchRipple
            onClick={uponConfirmClick}
            sx={{
              boxShadow: `0 4px 5px ${grey[400]}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: grey['A100'],
              '&:hover': {
                backgroundColor: grey[300],
              },
            }}
          >
            <CheckIcon
              sx={{
                fontSize: '1.5rem',
                color: grey[900],
                marginY: '0.45em',
              }}
            />
          </Button>
          <Button
            onClick={uponRejectClick}
            disableTouchRipple
            sx={{
              boxShadow: `0 4px 5px ${grey[400]}`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: grey['A100'],
              '&:hover': {
                backgroundColor: grey[300],
              },
            }}
          >
            <ClearIcon
              sx={{
                fontSize: '1.5rem',
                color: grey[900],
                marginY: '0.45em',
              }}
            />
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default InPlaceActionTaker;
