import React from 'react';
import { Button, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import { toast } from 'react-toastify';

import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { updateLocalCurrentThemeIndex } from 'store/features/localThemeSelector/localThemeSelectorSlice';
import { ThemeBannerProps } from 'components/Header/sub-components/ThemeBanner/ThemeBanner';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  useGetEditorByIdQuery,
  useUpdatedEditorByIdMutation,
} from 'store/rtkqFeatures/api/editorExtendedApi';
import useCurrentThemeSelector from 'store/rtkqFeatures/api/hooks/useCurrentThemeSelector';

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 1000,
  hideProgressBar: true,
};

type ApplyThemeBannerProps = {
  localCurrentThemeId: number | undefined;
  localCurrentThemeName: string;
} & Pick<ThemeBannerProps, 'closeThemeBanner'>;

const ApplyThemeBanner: React.FunctionComponent<ApplyThemeBannerProps> = ({
  localCurrentThemeId,
  localCurrentThemeName,
  closeThemeBanner,
}) => {
  const reduxDispatch = useAppDispatch();
  const editorId = useAppSelector(selectLoggedInUserId);
  const [updateThemeId] = useUpdatedEditorByIdMutation();
  const { cacheCurrentThemeIndex } = useCurrentThemeSelector();
  const { data: loggedInUser } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  });

  // for nexjs app theme view
  const updateEditorThemeId = async () => {
    if (!localCurrentThemeId && localCurrentThemeId !== 0) {
      toast.error('Unable to apply theme!');
      console.error(
        'Unable to apply theme because localCurrentThemeId not found | localCurrentThemeId: ',
        localCurrentThemeId
      );
      return;
    }
    if (!loggedInUser) {
      toast.error('Unable to change theme!', TOAST_MESSAGE_SETTINGS);
      console.error(
        'Unable to change theme because loggedInUser is undefined! | LoggedInUser: ',
        loggedInUser
      );
      return;
    }
    try {
      await updateThemeId({
        editorId,
        loggedInUser: {
          ...loggedInUser,
          themeId: localCurrentThemeId,
        },
      }).unwrap();
      closeThemeBanner();
    } catch (error) {}
  };

  const resetLocalThemeIndexChange = () => {
    reduxDispatch(updateLocalCurrentThemeIndex(cacheCurrentThemeIndex));
    closeThemeBanner();
  };

  return (
    <Stack      
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      spacing={2.3}
    >
      <Button
        onClick={resetLocalThemeIndexChange}
        sx={{
          fontSize: '1.45rem',
          color: 'common.white',
          '&:hover': {
            background: grey[700],
          },
        }}
      >
        Cancel
      </Button>
      {/* ----------- apply theme to your site btn ------------------- */}
      <Button
        variant='contained'
        onClick={updateEditorThemeId}
        sx={{
          fontSize: '1.45rem',
          background: '#EF3054',
          color: 'common.white',
          '&:hover': {
            background: '#bf213f',
          },
        }}
      >
        Apply {localCurrentThemeName} theme
      </Button>
    </Stack>
  );
};

export default ApplyThemeBanner;
