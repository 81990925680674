// FIXME - Many fns here are unused and can be removed.
import { add, addMinutes, differenceInDays, format, getDay, isAfter, isBefore, set, subMilliseconds, } from 'date-fns';
import { utcToZonedTime } from "date-fns-tz";
import moment from "moment";
import uniqWith from 'lodash/uniqWith';
import { ITimezoneOption } from 'react-timezone-select';
import { getTimezoneOffset } from 'date-fns-tz';

import { prefixZero } from 'components/SPM_Forms/reusable-form-components/TentativeAvailability/TentativeUtility';
import { TimeSlot } from 'components/SPM_Forms/reusable-form-components/TentativeAvailability/TentativeAvailabilityTypes';
import { RepeatebleTentativeHours } from 'types/meeting';
import { TOTAL_SECONDS_IN_WEEK } from 'components/SPM_Forms/reusable-form-components/TentativeAvailability/hooks/useTentativeAvailability';
import { TimezoneType } from 'views/DashboardActions/SelectNewTime/selectNewTimeReducer';

export function convert_utc_to_local(date: number, loggedInUserTimeZone: string): number {
  const startDate = moment(date);
  const startDuration = startDate
    .subtract({ minute: startDate.utcOffset() })
    .add({ millisecond: getTimezoneOffset(loggedInUserTimeZone) })
    .valueOf();

  return startDuration;
}

export const getHoursFromTimeString = (timeString: string) => {
  let hour = +timeString.split(":")[0];
  if (timeString.toLowerCase().includes("pm") && hour !== 12) {
    hour += 12;
  } else if (timeString === '12:00 AM') {
    return hour -= 12;
  }
  return hour;
};

export const getMinuteFromTimeString = (timeString: string): number => {
  const time = timeString.split(' ');
  const hourAndMin = time[0].split(':');
  const min = hourAndMin[1];

  return Number(min);
}

export const getDayFromSeconds = (seconds: number) => {
  // 86400 seconds in a day  
  return Math.floor(seconds / 86400);
}

export const getHourAndMinutesFromSeconds = (seconds: number, numberFormat = true) => {
  let day = Math.floor(seconds / 86400);
  if (day === 7) {
    day = 0;
  }
  if (day === -1) {
    day = 6;
  }
  const elapsedTime = seconds / 3600; // in hours  
  // -152
  let elapsedHours = Math.floor(elapsedTime) - day * 24; // only show current day hours removing elapsed day from seconds;
  if (Math.sign(elapsedHours) === -1 && Math.abs(elapsedHours) >= 144 && Math.abs(elapsedHours) <= 168) {
    elapsedHours = 168 - Math.abs(elapsedHours);
  }
  if (elapsedHours >= 168) {
    elapsedHours = elapsedHours - 168;
  }
  const elapsedMin = Math.floor(Number((elapsedTime - Math.floor(elapsedTime))) * 60);
  return {
    hour: elapsedHours === 0 ? 12 : elapsedHours,
    min: elapsedMin,
    meridiem: elapsedHours > 11 ? 'PM' : 'AM',
    day
    // min:numberFormat ? elapsedMin : elapsedMin < 10 ? prefixZero(elapsedMin, 2): elapsedMin,
  }
}

export const getHourAndMinutesFromSecondsIn24 = (seconds: number, numberFormat = true) => {
  let day = Math.floor(seconds / 86400);
  if (day === 7) {
    day = 0;
  }
  if (day === -1) {
    day = 6;
  }
  const elapsedTime = seconds / 3600; // in hours  
  // -152
  let elapsedHours = Math.floor(elapsedTime) - day * 24; // only show current day hours removing elapsed day from seconds;
  if (Math.sign(elapsedHours) === -1 && Math.abs(elapsedHours) >= 144 && Math.abs(elapsedHours) <= 168) {
    elapsedHours = 168 - Math.abs(elapsedHours);
  }
  if (elapsedHours >= 168) {
    elapsedHours = elapsedHours - 168;
  }
  const elapsedMin = Math.floor(Number((elapsedTime - Math.floor(elapsedTime))) * 60);
  return {
    hour: elapsedHours,
    min: elapsedMin,
    day
    // min:numberFormat ? elapsedMin : elapsedMin < 10 ? prefixZero(elapsedMin, 2): elapsedMin,
  }
}

export const getHourAndMinutesFromSecondsInLocalTimezone = (seconds: number, timezone: string) => {
  const convertedTimeToZonedTime = seconds;

  let day = Math.floor(convertedTimeToZonedTime / 86400);
  if (day === 7) {
    day = 0;
  }
  if (day === -1) {
    day = 6;
  }
  const elapsedTime = convertedTimeToZonedTime / 3600; // in hours  
  // -152
  let elapsedHours = Math.floor(elapsedTime) - day * 24; // only show current day hours removing elapsed day from seconds;
  if (Math.sign(elapsedHours) === -1 && Math.abs(elapsedHours) >= 144 && Math.abs(elapsedHours) <= 168) {
    elapsedHours = 168 - Math.abs(elapsedHours);
  }
  if (elapsedHours >= 168) {
    elapsedHours = elapsedHours - 168;
  }
  const elapsedMin = Math.floor(Number((elapsedTime - Math.floor(elapsedTime))) * 60);
  return {
    hour: elapsedHours === 0 ? 12 : elapsedHours,
    min: elapsedMin < 10 ? prefixZero(elapsedMin, 2) : elapsedMin,
    meridiem: elapsedHours > 11 ? 'PM' : 'AM',
    day
  }
}

export function getMinuteString(minute: number) {
  let minuteString = "00";
  if (minute > 15) {
    minuteString = "15";
  } else if (minute > 30) {
    minuteString = "30";
  } else if (minute > 45) {
    minuteString = "45";
  }

  return minuteString;
}

export function roundMinutesToNearestFive(minute: number) {
  return Math.round(minute / 5) * 5;
}

function roundToNext5Minutes(date: Date) {
  const minutes = date.getMinutes();
  const remainder = minutes % 5;
  if (remainder !== 0) {
    date.setMinutes(minutes + (5 - remainder));
  } else {
    date.setMinutes(minutes + 5);
  }
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export function generateTimeSlots(date: Date, timezon: TimezoneType ) {
  let timeZonedDate = utcToZonedTime(date, timezon.value);

  if (moment(timeZonedDate).date() < moment(date).date()) {
    timeZonedDate = new Date(
      moment(timeZonedDate)
        .date(moment(date).date())
        .hours(0)
        .minute(0)
        .format()
    );
  }
  let slots: timeSlotObjectKeys[];
  let hour;
  let dateToBook = timeZonedDate.getDate();
  let monthToBook = timeZonedDate.getMonth();
  let yearToBook = timeZonedDate.getFullYear();
  const now = utcToZonedTime(new Date(), timezon.value);

  if (
    timeZonedDate.getDate() === now.getDate() &&
    timeZonedDate.getMonth() === now.getMonth() &&
    timeZonedDate.getFullYear() === now.getFullYear()
  ) {
    hour = now.getHours() + 1;
  } else {
    hour = 0;
  }

  const timeStrings = ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];

  slots= []
  for (let i = hour; i < 24; i++) {
    slots.push({
      hour: i.toString(),
      min: timeStrings,
      meridiem: i < 12 ? "AM" : "PM"
    });
  }
  return slots;
}

export function splitTimeString(timeString: string): TimeSlot | null {
  // Validate input format
  const regex = /^(\d{1,2}):(\d{2}) (AM|PM)$/i;
  if (!regex.test(timeString)) {
    return null; // Invalid input format
  }

  const separateTime = timeString.split(' ');
  const separateHourAndMin = separateTime[0].split(':');
  const parsedHour = +separateHourAndMin[0];
  const min = separateHourAndMin[1];

  let hour = parsedHour;
  const period = separateTime[1].toUpperCase();

  if (period === 'AM' && hour === 12) {
    hour = 0; // Special case: 12 AM should be 0 hour
  } else if (period === 'PM' && hour !== 12) {
    hour += 12; // Convert PM hours to 24-hour format
  }

  return { hour, min };
}

export type timeSlotObjectKeys = {
  hour: string,
  min: string[],
  meridiem: string
}
type FromAndHour = {
  hour: number;
  min: number;
}

function convertTimeSlotToObject(timeslots: timeSlotObjectKeys[]): timeSlotObjectKeys[] {

  let mergedTimeSlots = uniqWith(timeslots, (pre, cur) => {
    if (pre.hour === cur.hour) {
      cur.min = [...cur.min, ...pre.min];
      return true;
    }
    return false;
  })

  return mergedTimeSlots;
}

export function getTimeSlots<ParameterType extends { hour: number, min: number }>(start: ParameterType, end: ParameterType, gap = 5) {
  const { hour: startHour, min: startMin } = start;
  const { hour: endHour, min: endMin } = end;

  let startTime = set(new Date(), { hours: startHour, minutes: startMin, seconds: 0 })
  let endTime = set(new Date(), { hours: endHour, minutes: endMin, seconds: 0 })

  if (isBefore(endTime, startTime)) {
    add(endTime, { days: 1 });
  }
  let timeSlotsArrayObjectInHourMinAndMeridiem = [];

  while (startTime <= endTime) {
    const slot = {
      hour: format(startTime, 'H'),
      min: [format(startTime, 'mm')],
      meridiem: format(startTime, 'aa'),
    }

    timeSlotsArrayObjectInHourMinAndMeridiem.push(slot);

    startTime = addMinutes(startTime, gap)
  }
  timeSlotsArrayObjectInHourMinAndMeridiem = convertTimeSlotToObject(timeSlotsArrayObjectInHourMinAndMeridiem);
  return {
    timeSlotsArrayObjectInHourMinAndMeridiem,
    endTime,
    startTime
  };
}
export function getTimeSlotsForRepeatable(startTime: number, endTime: number) {

  let timeSlotsArrayObjectInHourMinAndMeridiem = [];

  while (startTime <= endTime) {
    const { hour, min, meridiem } = getHourAndMinutesFromSeconds(startTime)

    timeSlotsArrayObjectInHourMinAndMeridiem.push({
      hour: hour.toString(),
      min: [min.toString()],
      meridiem,
    });

    startTime = startTime + 15 * 60; // Adding 15 minutes after each repeatation
  }

  timeSlotsArrayObjectInHourMinAndMeridiem = convertTimeSlotToObject(timeSlotsArrayObjectInHourMinAndMeridiem);

  return {
    timeSlotsArrayObjectInHourMinAndMeridiem,
    endTime,
    startTime
  };
}

export function getTimeSlotsInString<ParameterType extends { hour: number, min: number }>(start: ParameterType, end: ParameterType, gap = 5, dateView = 'h:mm aaa') {

  const { hour: startHour, min: startMin } = start;
  const { hour: endHour, min: endMin } = end;

  let startTime = set(new Date(), { hours: startHour, minutes: startMin, seconds: 0 })
  let endTime = set(new Date(), { hours: endHour, minutes: endMin, seconds: 0 })

  if (isBefore(endTime, startTime)) {
    add(endTime, { days: 1 });
  }
  let timeSlotsArray = [];

  while (startTime <= endTime) {

    timeSlotsArray.push(format(startTime, dateView));
    startTime = addMinutes(startTime, gap)
  }

  return {
    timeSlotsArray,
    endTime,
    startTime
  };
}

export function getTimeSlotsForForward(start: Date, clickedDate: Date = new Date(), end?: Date) {
  let startTime = set(clickedDate, { hours: +format(start, 'H'), minutes: +format(start, 'mm') });
  let endTime = end ? end : set(clickedDate, { hours: 0, minutes: 0, seconds: 0 });
  let timeSlotsArrayObjectInHourMinAndMeridiem = [];
  while (startTime <= endTime) {

    timeSlotsArrayObjectInHourMinAndMeridiem.push({
      hour: format(startTime, 'H'),
      min: [format(startTime, 'mm')],
      meridiem: format(startTime, 'aa'),
    });

    startTime = addMinutes(startTime, 5)
  }
  timeSlotsArrayObjectInHourMinAndMeridiem = convertTimeSlotToObject(timeSlotsArrayObjectInHourMinAndMeridiem);
  return {
    timeSlotsArrayObjectInHourMinAndMeridiem,
    endTime,
    startTime
  };
}

// const UTCTimezone = {
//   value: "Etc/GMT",
//   label: "(GMT+0:00) UTC",
//   offset: 0,
//   altName: "ETC/GMT"
// };

type GetTimeSlotParamType = {
  hour: number;
  min: number;
}
export function generateTimeSlotsForSameDay(startTime: number, endTime: number, clickedDate?: Date): timeSlotObjectKeys[] {
  const startTimeInHourAndMin = getHourAndMinutesFromSecondsIn24(startTime);
  const endTimeInHourAndMin = getHourAndMinutesFromSecondsIn24(endTime);

  const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({ hour: startTimeInHourAndMin.hour, min: startTimeInHourAndMin.min }, { hour: endTimeInHourAndMin.hour, min: endTimeInHourAndMin.min });

  return timeSlotsArrayObjectInHourMinAndMeridiem;
}

export function generateTimeSlotsForDifferentDay(startTime: number, endTime: number, clickedDate: Date) {
  let startTimeInHourAndMin = getHourAndMinutesFromSecondsIn24(startTime);
  let endTimeInHourAndMin = getHourAndMinutesFromSecondsIn24(endTime);

  // fix me later type error; 
  let startTimeToMidnight: any = [];
  let midnightToEndTime: any = [];

  if (getDay(clickedDate) !== endTimeInHourAndMin.day) {
    midnightToEndTime = [];
    const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({
      hour: startTimeInHourAndMin.hour,
      min: startTimeInHourAndMin.min,
    }, { hour: 24, min: 0 });
    startTimeToMidnight = timeSlotsArrayObjectInHourMinAndMeridiem;
  } else if (getDay(clickedDate) !== startTimeInHourAndMin.day) {
    startTimeToMidnight = [];
    const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({ hour: 0, min: 0 }, {
      hour: endTimeInHourAndMin.hour,
      min: endTimeInHourAndMin.min,
    })

    midnightToEndTime = timeSlotsArrayObjectInHourMinAndMeridiem;
  } else {
    const { timeSlotsArrayObjectInHourMinAndMeridiem: startTimeSlot } = getTimeSlots({
      hour: startTimeInHourAndMin.hour,
      min: startTimeInHourAndMin.min,
    }, { hour: 24, min: 0 });

    const { timeSlotsArrayObjectInHourMinAndMeridiem: endTimeSlot } = getTimeSlots({ hour: 0, min: 0 }, {
      hour: endTimeInHourAndMin.hour,
      min: endTimeInHourAndMin.min,
    })
    startTimeToMidnight = startTimeSlot;
    midnightToEndTime = endTimeSlot;
  }


  return startTimeToMidnight.concat(midnightToEndTime);
}

export function generateTimeSlotsForOneDefinedSlots(startTime: number, endTime: number, clickedDate?: Date): timeSlotObjectKeys[] {
  const getStartTimeInHourAndMin: GetTimeSlotParamType = {
    hour: +format(startTime, 'H'),
    min: +format(startTime, 'mm'),
  }
  const getEndTimeInHourAndMin: GetTimeSlotParamType = {
    hour: +format(endTime, 'H'),
    min: +format(endTime, 'mm'),
  }
  const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots(getStartTimeInHourAndMin, getEndTimeInHourAndMin);
  return timeSlotsArrayObjectInHourMinAndMeridiem;
}

export function generateTimeSlotsForOneDefinedSlotsInSeconds(startTime: number | false, endTime: number | false): timeSlotObjectKeys[] {

  const startTimeInHourAndMin = startTime && getHourAndMinutesFromSecondsIn24(startTime);
  const endTimeInHourAndMin = endTime && getHourAndMinutesFromSecondsIn24(endTime);
  if (startTimeInHourAndMin) {
    const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({ hour: startTimeInHourAndMin.hour, min: startTimeInHourAndMin.min }, { hour: 23, min: 59 });
    return timeSlotsArrayObjectInHourMinAndMeridiem;
  } else if (endTimeInHourAndMin) {
    const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({ hour: 0, min: 0 }, { hour: endTimeInHourAndMin.hour, min: endTimeInHourAndMin.min })
    return timeSlotsArrayObjectInHourMinAndMeridiem;
  } else {
    return [];
  }
}

export function generateTimeSlotsFromRange(startTime: number, endTime: number, timezone: ITimezoneOption, meetingDuration: number, clickedDate?: Date, isRepeatable?: boolean) {
  // end time show on calendar page = endTime - meetingDuration;
  const currentDate = utcToZonedTime(new Date(), timezone.value);
  const to = subMilliseconds(endTime, meetingDuration).valueOf();
  const fromDateInLocalTimezone = utcToZonedTime(startTime, timezone.value);
  const toDateInLocalTimezone = utcToZonedTime(to, timezone.value);
  let timeZonedFromDate = moment(fromDateInLocalTimezone).format('LT');
  let timeZonedToDate = moment(toDateInLocalTimezone).format('LT');

  // GENERATE SLOTS FOR THE NON-REPEATABLE ONE FOR DIFFERENT IN DAYS
  const differenceBetweenStartAndEndDate = Math.abs(differenceInDays(startTime, to));
  if (differenceBetweenStartAndEndDate) {
    if (moment(clickedDate).format('DD-MM-YY') === moment(startTime).format('DD-MM-YY')) {
      // current clicked date === starting date
      const extractTimeFromString = timeZonedFromDate.split(' ')[0];
      const from_hourAndMin = { hour: +extractTimeFromString.split(":")[0], min: +extractTimeFromString.split(":")[0] };

      const extractTimeToString = timeZonedToDate.split(' ')[0];
      const to_hourAndMin = { hour: +extractTimeToString.split(":")[0], min: +extractTimeToString.split(":")[0] };

      if (timeZonedFromDate.toLowerCase().includes("pm") && from_hourAndMin.hour !== 12) {
        from_hourAndMin.hour += 12;
      }
      if (timeZonedToDate.toLowerCase().includes("pm") && to_hourAndMin.hour !== 12) {
        to_hourAndMin.hour += 12;
      }
      if (timeZonedToDate.toLowerCase().includes("pm") && to_hourAndMin.hour !== 12) {
        to_hourAndMin.hour += 12;
      }
      if (timeZonedFromDate.toLowerCase().includes("am") && from_hourAndMin.hour === 12) {
        from_hourAndMin.hour = 0;
      }
      const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots<typeof from_hourAndMin>(from_hourAndMin, { hour: 24, min: 0 })
      return timeSlotsArrayObjectInHourMinAndMeridiem;
    } else if (moment(clickedDate).format('DD-MM-YY') === moment(to).format('DD-MM-YY')) {
      // current clicked date === ending date

      const extractTimeFromString = timeZonedFromDate.split(' ')[0];
      const from_hourAndMin = { hour: +extractTimeFromString.split(":")[0], min: +extractTimeFromString.split(":")[0] };

      const extractTimeToString = timeZonedToDate.split(' ')[0];
      const to_hourAndMin = { hour: +extractTimeToString.split(":")[0], min: +extractTimeToString.split(":")[0] };

      if (timeZonedFromDate.toLowerCase().includes("pm") && from_hourAndMin.hour !== 12) {
        from_hourAndMin.hour += 12;
      }
      if (timeZonedToDate.toLowerCase().includes("pm") && to_hourAndMin.hour !== 12) {
        to_hourAndMin.hour += 12;
      }
      if (timeZonedFromDate.toLowerCase().includes("am") && from_hourAndMin.hour === 12) {
        from_hourAndMin.hour = 0;
      }

      const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots<typeof from_hourAndMin>(from_hourAndMin, to_hourAndMin);
      return timeSlotsArrayObjectInHourMinAndMeridiem

    } else {
      // COMMENT All days between starting and ending dates
      const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({ hour: 0, min: 0 }, { hour: 24, min: 0 })
      return timeSlotsArrayObjectInHourMinAndMeridiem

    }
  }

  const extractTimeFromString = timeZonedFromDate.split(' ')[0];
  let from_hourAndMin = { hour: +extractTimeFromString.split(":")[0], min: +extractTimeFromString.split(":")[1] };

  const extractTimeToString = timeZonedToDate.split(' ')[0];
  let to_hourAndMin = { hour: +extractTimeToString.split(":")[0], min: +extractTimeToString.split(":")[1] };

  if (timeZonedFromDate.toLowerCase().includes("pm") && from_hourAndMin.hour !== 12) {
    from_hourAndMin.hour += 12;
  }
  if (timeZonedToDate.toLowerCase().includes("pm") && to_hourAndMin.hour !== 12) {
    to_hourAndMin.hour += 12;
  }
  if (timeZonedToDate.toLowerCase().includes("am") && to_hourAndMin.hour === 12) {
    to_hourAndMin.hour = 0;
  }
  if (timeZonedFromDate.toLowerCase().includes('am') && from_hourAndMin.hour === 12) {
    from_hourAndMin.hour -= 12;
  }
  // isSameDay    
  if (clickedDate) {
    // COMMENT for some reason - format(clickedDate, 'P') Its giving me error so I have to use moment format instead of date-fns;
    if (moment(currentDate).format('L') === moment(clickedDate).format('L')) {
      from_hourAndMin = showStartTimeAccordingToCurrentTime(from_hourAndMin, timezone);
    }
  }

  const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots(from_hourAndMin, to_hourAndMin);

  return timeSlotsArrayObjectInHourMinAndMeridiem;
}

export function showStartTimeAccordingToCurrentTime(from_hourAndMin: FromAndHour, timezone: ITimezoneOption): FromAndHour {
  let updatedFromHourAndMin: FromAndHour = from_hourAndMin;
  if (isAfter(new Date().getHours(), from_hourAndMin.hour)) {
    const currentTime = new Date().getTime();
    updatedFromHourAndMin.hour = +format(currentTime, 'H')
    updatedFromHourAndMin.min = +format(currentTime, 'mm')
  }
  return updatedFromHourAndMin
}

export function getTimeListAccordingToChangeTimezone(time: string[], offset: number): string[] {
  return (
    time.map((eachTime) => {
      const extractNumericPartFromTime = eachTime.split(' ')[0]
      const extractHoursAndMinutes: number[] = extractNumericPartFromTime.split(':').map(eachString => parseInt(eachString, 10));
      if (eachTime.includes('PM')) {
        const convertEachTimeInto24HourFormat = Number(extractHoursAndMinutes[0]) + 12; // converting to 24 hour format
        const getCurrentHourAndMinutes = new Date(new Date().setHours(convertEachTimeInto24HourFormat, Number(extractHoursAndMinutes[1])))

        // Converting each time to UTC standard
        const getCurrentUTCTimeStamp = new Date(getCurrentHourAndMinutes.getTime() + getCurrentHourAndMinutes.getTimezoneOffset() * 60 * 1000);

        // Converted According to timezone
        const convertedTimeAccordingToChangeTimezone = new Date(getCurrentUTCTimeStamp.getTime() + (offset * 60 * 60 * 1000))

        const hours = convertedTimeAccordingToChangeTimezone.getHours();
        const minutes = convertedTimeAccordingToChangeTimezone.getMinutes() === 0 ? '00' : convertedTimeAccordingToChangeTimezone.getMinutes();
        const time = `${hours}:${minutes}`
        return time;

      } else {
        const getCurrentHourAndMinutes = new Date(new Date().setHours(extractHoursAndMinutes[0], extractHoursAndMinutes[1]))

        // Converting each time to UTC standard
        const getCurrentUTCTimeStamp = new Date(getCurrentHourAndMinutes.getTime() + getCurrentHourAndMinutes.getTimezoneOffset() * 60 * 1000);

        // Converted According to timezone
        const convertedTimeAccordingToChangeTimezone = new Date(getCurrentUTCTimeStamp.getTime() + (offset * 60 * 60 * 1000))
        const hours = convertedTimeAccordingToChangeTimezone.getHours();
        const minutes = convertedTimeAccordingToChangeTimezone.getMinutes() === 0 ? '00' : convertedTimeAccordingToChangeTimezone.getMinutes();
        const time = `${hours}:${minutes}`;
        return time;
      }
    })
  )
}

export function generateTimeSlotsForSameDayForNonRepeatable(startTime: number, endTime: number, clickedDate?: Date): timeSlotObjectKeys[] {
  const startTimeInHourAndMin = getHourAndMinutesFromTimestampIn24(startTime);
  const endTimeInHourAndMin = getHourAndMinutesFromTimestampIn24(endTime);

  const { timeSlotsArrayObjectInHourMinAndMeridiem } = getTimeSlots({ hour: startTimeInHourAndMin.hour, min: startTimeInHourAndMin.min }, { hour: endTimeInHourAndMin.hour, min: endTimeInHourAndMin.min });

  return timeSlotsArrayObjectInHourMinAndMeridiem;
}

function getHourAndMinutesFromTimestampIn24(timestamp: number): GetTimeSlotParamType {
  return {
    hour: +format(timestamp, 'H'),
    min: +format(timestamp, 'mm'),
  }
}

export function generateTimeSlotsForDifferentDayForNonRepeatable(startTime: number, endTime: number, clickedDate: Date) {
  let startTimeInHourAndMin = getHourAndMinutesFromTimestampIn24(startTime);
  let endTimeInHourAndMin = getHourAndMinutesFromTimestampIn24(endTime);

  const { timeSlotsArrayObjectInHourMinAndMeridiem: startTimeSlot } = getTimeSlots({
    hour: startTimeInHourAndMin.hour,
    min: startTimeInHourAndMin.min,
  }, { hour: 24, min: 0 });

  const { timeSlotsArrayObjectInHourMinAndMeridiem: endTimeSlot } = getTimeSlots({ hour: 0, min: 0 }, {
    hour: endTimeInHourAndMin.hour,
    min: endTimeInHourAndMin.min,
  })

  return startTimeSlot.concat(endTimeSlot);
}


export function generateLocalNonRepeatableTimeSlots(
  date: Date,
  nonRepeatebleHoursInLocalTimezone: RepeatebleTentativeHours[],
  timeSlots: timeSlotObjectKeys[],
  timezoneValue: string
): timeSlotObjectKeys[] {
  const offsetNow = getOffsetNow(timezoneValue);
  const isToday = moment(date).isSame(offsetNow, 'day');
  const from = moment(date).set({ hour: 0, minute: 0, second: 0 }).valueOf();
  const to = moment(date).set({ hour: 23, minute: 59, second: 59 }).valueOf();

  if (isToday) {
    roundToNext5Minutes(offsetNow);
  }

  for (let i = 0; i < nonRepeatebleHoursInLocalTimezone.length; i++) {
    const fromToLocal = nonRepeatebleHoursInLocalTimezone[i];
    let adjustedFrom = fromToLocal.from;
    if (adjustedFrom < offsetNow.getTime()) {
      adjustedFrom = Math.max(adjustedFrom, offsetNow.getTime());
    }

    if (from <= fromToLocal.to && to >= adjustedFrom) {
      timeSlots.push(
        ...generateTimeSlotsForSameDayForNonRepeatable(
          Math.max(from, adjustedFrom),
          Math.min(to, fromToLocal.to),
          date
        )
      );
    }
  }

  return timeSlots;
}

export function generateLocalRepeatableTimeSlots(
  repeatebleHoursInLocalTimezone: RepeatebleTentativeHours[],
  date: Date,
  timeSlots: timeSlotObjectKeys[],
  timezoneValue: string,
): timeSlotObjectKeys[] {
  const now = roundToNext5Minutes(new Date());
  const timezoneOffset = getTimezoneOffset(timezoneValue)
  const offsetInSeconds = timezoneOffset / 1000;
  const offsetNow = new Date(getOffsetNow(timezoneValue).getTime() - (timezoneOffset + date.getTimezoneOffset()*60*1000));
  const isSameWeek = moment(moment(new Date(date).getTime())).isSame(offsetNow, 'week');
  const dayIndex = getDay(date);

  const relativeTimeNow = ((now.getTime() / 1000) + 4 * 86400) % TOTAL_SECONDS_IN_WEEK + offsetInSeconds;

  for (let i = 0; i < repeatebleHoursInLocalTimezone.length; i++) {
    const fromToLocal = repeatebleHoursInLocalTimezone[i];
    let adjustedFrom = fromToLocal.from;
    
    if (isSameWeek && adjustedFrom < relativeTimeNow) {
      adjustedFrom = Math.max(adjustedFrom, relativeTimeNow);
    }
    
    if (adjustedFrom <= fromToLocal.to) {
      if (
        dayIndex >= getDayFromSeconds(adjustedFrom) &&
        dayIndex <= getDayFromSeconds(fromToLocal.to)
      ) {
        timeSlots.push(
          ...getTimeSlotsForDate(adjustedFrom, fromToLocal.to, date)
        );
      }
    }
  }

  return timeSlots;
}

function getTimeSlotsForDate(from: number, to: number, date: Date) {
  const dayIndex = getDay(date);
  if (dayIndex != getDayFromSeconds(from)) {
    from = dayIndex * 86400;
  }
  if (dayIndex != getDayFromSeconds(to)) {
    to = (dayIndex + 1) * 86400 - 1;
  }

  return generateTimeSlotsForSameDay(from, to, date);
}

export function getOffsetNow(timezoneValue: string): Date {
  const now = new Date();
  const timezoneOffset = getTimezoneOffset(timezoneValue);
  const offsetNow = new Date(
    now.getTime() + now.getTimezoneOffset() * 60 * 1000 + timezoneOffset
  );
  return offsetNow;
}