import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import Compressor from 'compressorjs';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import StyledItemLabel from 'components/shared/StyledItemLabel';
import UploadImage from 'components/utilities/Cloudinary/UploadImage';

const UserDashboardImgUploader = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<EditUserDetailsForm>();
  const { files, userDashboardImg } = values;

  const handleImgUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toastId = toast.loading('Uploading Image...');
    // save it to value.file.userDashboardImg
    const file = e.target.files?.[0];
    if (file) {
      new Compressor(file, {
        quality: 0.9,
        maxWidth: 1200,
        maxHeight: 1200,        
        success: async (compressedFile) => {
          if (userDashboardImg) {
            setFieldValue('deletedImgs.userDashboardImg', userDashboardImg);
            setFieldTouched('deletedImgs.userDashboardImg', true);
          }
          setFieldValue('files.userDashboardImg', compressedFile);
          setFieldTouched('files', true);
          toast.dismiss(toastId);
          toast.success('Image uploaded successfully');
        },
        error: (error) => {
          toast.dismiss(toastId);
          toast.error(error.message);
        },
      });
    }
  };

  const removeFile = async (url: string | File) => {
    if (typeof url === 'string') {
      setFieldValue('deletedImgs.userDashboardImg', url);
      setFieldValue('userDashboardImg', '');
      setFieldTouched('userDashboardImg', true);
    } else {
      setFieldValue('files.userDashboardImg', null);
      setFieldTouched('files', true);
    }
  };

  return (
    <Stack
      gap={1}
      flexWrap={'wrap'}
      direction='row'
      justifyContent={'stretch'}
      position={'relative'}
    >
      <StyledItemLabel label='Main image' secondaryLabel='Upload main image' />
      <UploadImage
        imgUploadCallback={handleImgUpload}
        removeCallback={removeFile}
        img={files?.userDashboardImg ?? userDashboardImg}
      />
    </Stack>
  );
};

export default UserDashboardImgUploader;
