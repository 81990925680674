import { CustomPaletteOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

const darkTheme: CustomPaletteOptions = {
  primary: {
    dark: '#9e25d3',
    main: '#e613ff',
    light: '#f078fe',
  },
  secondary: {
    dark: grey[100],
    main: grey[200],
    light: grey[300],
  },
  link: {
    main: '#0d6efd',
  },
  text: {
    primary: '#ffffff',
    secondary: grey[100],
    disabled: grey[200],
  },
  background: {
    default: '#1c2121',
    // theme: '#1c2121',
    // paper: '#1c2121',
  },
}

export default darkTheme;