import { Stack, SxProps } from '@mui/material';

import ApplyThemeBanner from 'components/Header/sub-components/ThemeBanner/sub-components/ApplyThemeBanner/ApplyThemeBanner';
import PremiumThemeSubsNewSubscribeAction from 'components/Header/sub-components/ThemeBanner/sub-components/PremiumThemeBanner/sub-components/PremiumThemeSubscriptionActions/PremiumThemeSubsNewSubscribeAction/PremiumThemeSubsNewSubscribeAction';
import IfElse from 'components/utilities/IfElse';
import { useAppDispatch } from 'hooks/redux';

import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import { updateLocalCurrentThemeIndex } from 'store/features/localThemeSelector/localThemeSelectorSlice';
import useCurrentThemeSelector from 'store/rtkqFeatures/api/hooks/useCurrentThemeSelector';

export type ThemeBannerProps = {
  closeThemeBanner: () => void;
  sx?: SxProps;
};

const ThemeBanner: React.FunctionComponent<ThemeBannerProps> = ({
  closeThemeBanner,
  sx,
}) => {
  const reduxDispatch = useAppDispatch();
  const { cacheCurrentThemeIndex } = useCurrentThemeSelector();  
  const { localCurrentThemeId, localCurrentThemeName, } =
    useLocalCurrentThemeIndex();
  const canApplyCurrentLocalCurrentTheme = true;

  const handleCancelClick = () => {
    reduxDispatch(updateLocalCurrentThemeIndex(cacheCurrentThemeIndex));
    closeThemeBanner();
  };

  return (
    <Stack
      className='apply-theme-banner'
      direction='row'
      justifyContent='center'
      alignItems='center'
      spacing={1}
      sx={{
        background: '#1B2F33',
        padding: '1rem 1rem',
        ...sx,
      }}
    >
      <IfElse
        condition={canApplyCurrentLocalCurrentTheme}
        alternative={
          <PremiumThemeSubsNewSubscribeAction
            name={localCurrentThemeName}
            text={'is a premium theme.'}
            onCancelClick={handleCancelClick}
          />
        }
      >
        <ApplyThemeBanner
          localCurrentThemeName={localCurrentThemeName}
          localCurrentThemeId={localCurrentThemeId}
          closeThemeBanner={closeThemeBanner}
        />
      </IfElse>
    </Stack>
  );
};

export default ThemeBanner;
