import { SxProps, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import useHandleImgOnError from 'hooks/useHandleImgOnError';
import { useGetMeetingsByUserIdQuery } from 'store/rtkqFeatures/api/meetingExtendedApi';
import { useGetServicesByUserIdQuery } from 'store/rtkqFeatures/api/serviceExtendedApi';
import useProfileStyle from './useProfileStyle';
import { useAppSelector } from 'hooks/redux';

import getNodeEnv from 'utilities/getNodeEnv';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import { selectLocalLayoutState } from 'store/features/localLayoutState';
import { getContrast, lightenColor } from 'utilities/analyzeAndSuggestTextColor';

const useProfileCard = () => {
  const [colorCode, setColorCode] = useState({
    bgColor: 'ffffff',
    contrastTextColor: '#000000',
  });

  const { userIdInReduxUserSlice: editorId } = useGetLoggedInUser();
  const { handleImageError, showImage } = useHandleImgOnError();
  const isMobile = useMediaQuery('(max-width:600px)');
  const { cachedLoggedInUser } = useGetLoggedInUser();

  const layout = useAppSelector(selectLocalLayoutState);

  const storeHeading = cachedLoggedInUser?.ui.storeHeading;
  const isNodeEnvProduction = getNodeEnv();

  const { data: meetings } = useGetMeetingsByUserIdQuery(editorId);
  const { data: services } = useGetServicesByUserIdQuery(editorId);

  const isMeetingAndServiceAvailable =
    (meetings && meetings.length > 0) || (services && services.length > 0);

  const {
    currentTheme,
    userImage,

    tagsArray,
    tagsArrayLength,

    closeIconPopup,
    userAuthType,
    userName,
    userTitle,
    userProfileLink,
    isAuthTagVisible,
    showIconPopup,
    showIconPopupController,
    user,
  } = useProfileStyle();

  const { email, emailPublic, whatsapp, socialLinks, address, phone, userBgImage, ui } =
    user;

  const {
    global,
    subtitle,
    title,
    tag,
    userName: userNameStyling,
    profileImg,
    paddingBottom,
    paddingTop,
    editBtn,
    isNeedDiv,
    ...bg
  } = currentTheme.profile;

  const buttonStyle = currentTheme.store.item.button;

  useEffect(() => {
    if (currentTheme.profile.userName.color) {
      (async () => {
        const colorCode = getContrast(currentTheme.profile.userName.color);
        setColorCode({
          bgColor: colorCode.replace('#', ''),
          contrastTextColor: colorCode,
        });
      })();
    }
  }, [currentTheme.profile.userName.color]);

  const bgImageWithColorURL = isMobile
    ? cloudinaryUtilsFns.addTransformationToImageUrl({
        width: 768,
        url: userBgImage ?? '',
        color: {
          alpha: 50,
          colorCode: colorCode.bgColor,
        },
      })
    : cloudinaryUtilsFns.addTransformationToImageUrl({
        url: userBgImage ?? '',
        width: 1920,
        color: {
          alpha: 50,
          colorCode: colorCode.bgColor,
        },
      });

  const bgImage: SxProps = {
    backgroundImage: `url(${bgImageWithColorURL})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  if (!isNodeEnvProduction) {
    console.log('editorId - ', editorId);
  }

  const userNameColor = currentTheme.profile.userName.color;
  const titleColor = lightenColor(currentTheme.profile.userName.color, 10);

  return {
    currentTheme,

    tagsArray,
    tagsArrayLength,

    closeIconPopup,
    userAuthType,
    userName,
    userTitle,
    userProfileLink,
    isAuthTagVisible,
    showIconPopup,
    showIconPopupController,
    user,

    layout,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,

    buttonStyle,

    showImage,
    userImage,
    bgImageWithColorURL,
    bgImage,
    userBgImage,
    bg,

    isMeetingAndServiceAvailable,
    handleImageError,
    storeHeading,

    isNeedDiv,
    userNameStyling,
    isMobile,
    title,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,

    displayTags: ui?.displayTags,
    displaySocialLinks: ui?.displaySocialLinks,

    colorCode,

    userNameColor,
    titleColor,
  };
};

export default useProfileCard;
