import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import { PickFromGuestSession } from 'views/guestSession/GuestSession/GuesSessionTypes';
import IfElse from 'components/utilities/IfElse';
import ContentHeading from 'views/guestSession/GuestSession/utility-components/ContentHeading';
import useGetCurrency from 'hooks/useGetCurrency';
// import useValidationContextProvider from 'views/guestSession/ValidateGuestSession/hooks/useValidationContextProvider';

type MeetingDetails = Omit<
  PickFromGuestSession<'meetingDetails'>['meetingDetails'],
  'hostDetails' | 'type' | 'isPaid'
>;

type GuestSessionMeetingDetailsProps = MeetingDetails &
  PickFromGuestSession<'meetingHostDetails'> & {
    isMeetingPaid: boolean;
    receiptUrl: string | undefined;
    isSessionPaidByGuest: boolean;
    price?: number;
    currency?: string;
  };

const GuestSessionMeetingDetails = ({
  title,
  included,
  desc,
  img,
  isMeetingPaid,
  price = 0,
  receiptUrl = '',
  isSessionPaidByGuest,
  meetingHostDetails,
  includedItemsHeading,
  currency,
}: GuestSessionMeetingDetailsProps) => {
  const areIncludedItemsValid = Array.isArray(included) && Boolean(included.length);
  const { symbol } = useGetCurrency(currency);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Stack direction='row' flexWrap='nowrap' spacing={2}>
            {/* ------------ meeting img */}
            <Avatar alt='Remy Sharp' src={img} sx={{ width: '6rem', height: '6rem' }} />
            <Box>
              <Box>
                {/* ------------ meeting name */}
                <Typography variant='h3' fontWeight={600} textAlign='left' mb='0'>
                  {title}
                </Typography>
              </Box>
              {/* ------------ Booked with */}
              <IfElse condition={Boolean(meetingHostDetails.profileURL)}>
                <Typography variant='body2'>
                  Booked with&nbsp;
                  {/* ------------ Host Profile URL*/}
                  <Button
                    onClick={() => {
                      window.open(meetingHostDetails.profileURL, '_blank');
                    }}
                    sx={{
                      fontSize: 'inherit',
                    }}
                  >
                    {meetingHostDetails.fullname.trim()}
                  </Button>
                </Typography>
              </IfElse>
            </Box>
          </Stack>
          <Stack spacing={2.5}>
            {/* ----------------- price */}
            <IfElse condition={isMeetingPaid && !!price}>
              <Stack
                direction='row'
                flexWrap='nowrap'
                alignItems='center'
                spacing={{
                  xs: 2,
                  sm: 1,
                }}
              >
                <ContentHeading text='price' />
                <Typography
                  sx={{
                    bgcolor: '#1565C0',
                    color: '#fff',
                  }}
                >
                  &nbsp;{symbol}&nbsp;{price}&nbsp;
                </Typography>
                {/* ------------ isPaid chip with payment receipt */}
                <IfElse condition={isSessionPaidByGuest && Boolean(receiptUrl)}>
                  <Chip
                    variant='outlined'
                    label='Payment Receipt'
                    color='success'
                    component='a'
                    href={receiptUrl}
                    rel='noreferrer'
                    target='_blank'
                    clickable
                    sx={{
                      fontSize: '1.35rem',
                    }}
                  />
                </IfElse>
              </Stack>
            </IfElse>

            {/* ----------------- what's included */}
            <IfElse condition={areIncludedItemsValid}>
              <Box>
                <ContentHeading
                  text={includedItemsHeading || "What's Included"}
                  sx={{
                    mb: '0.6em',
                  }}
                />
                <Stack
                  component='ul'
                  direction='row'
                  flexWrap='wrap'
                  columnGap={1}
                  rowGap={1}
                  sx={{
                    listStyle: 'none',
                    pl: 0,
                  }}
                >
                  {included.map((includedItemName, index) => (
                    <li key={index}>
                      <Chip
                        sx={{
                          fontSize: {
                            xs: '1.45rem',
                            sm: '1.6rem',
                          },
                        }}
                        label={includedItemName}
                        variant='outlined'
                      />
                    </li>
                  ))}
                </Stack>
              </Box>
            </IfElse>
            {/* ----------------- meeting desc */}
            <IfElse condition={Boolean(desc)}>
              <Box color='#424242'>
                <ContentHeading
                  text='meeting description'
                  sx={{
                    mb: '1rem',
                  }}
                />
                <Box
                  sx={{ '& *': { color: 'inherit' } }}
                  dangerouslySetInnerHTML={{
                    __html: desc,
                  }}
                />
              </Box>
            </IfElse>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default GuestSessionMeetingDetails;
