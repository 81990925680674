import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material';
import { useCopyToClipboard } from 'usehooks-ts';
import { blue } from '@mui/material/colors';

type TextWithCopyBtnProps = {
  copyText: string;
  copySuccessMsg: string;
  copyErrorMsg?: string;
  copyIconSize?: number;
  sx?: SxProps<Theme>;
  copyTextClassNames?: string;
  link?: boolean;
  linkValue?: string;
};

const TextWithCopyBtn = ({
  copyText,
  copyTextClassNames,
  copySuccessMsg,
  copyErrorMsg,
  sx,
  copyIconSize = 15,
  linkValue = '',
  link = false,
}: TextWithCopyBtnProps) => {
  const [, copy] = useCopyToClipboard();

  let content;

  if (link) {
    content = (
      <Typography
        component='a'
        variant='body1'
        href={copyText}
        target='_blank'
        rel='noreferrer'
        sx={{
          '&:any-link': {
            color: blue[600],
          },
          ...sx,
        }}
      >
        {linkValue || copyText}
      </Typography>
    );
  } else {
    content = (
      <Typography variant='body1' sx={sx}>
        {copyText}
      </Typography>
    );
  }

  return (
    <Stack
      className={copyTextClassNames}
      direction='row'
      spacing={0.4}
      alignItems='center'
    >
      {content}
      <ContentCopyIcon
        className='cursor-pointer'
        onClick={async () => {
          console.log('copy btn clicked');
          try {
            await copy(copyText);
            toast.success(copySuccessMsg);
          } catch (error) {
            toast.error('Error while copying!');
          }
        }}
        sx={{
          fontSize: '2rem',
        }}
      />
    </Stack>
  );
};

export default TextWithCopyBtn;
