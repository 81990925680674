import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container/Container';
import 'styles/views/Home.scss';
import { Link } from '@mui/material';
import { gradientBgColor } from 'themes/AppThemeProvider/light';

type ThisProps = {
  children: JSX.Element;
  above600PxWidth?: string;
  showPrivacyPolicy?: boolean;
  showSignUpLink?: boolean;
};

const HomeLayout = ({
  children,
  above600PxWidth = '400px',
  showPrivacyPolicy = false,
}: ThisProps) => {
  return (
    <Box className='home' sx={{ background:gradientBgColor}}>
      <Container>
        <Box>{children}</Box>       
      </Container>
    </Box>
  );
};

export default HomeLayout;

export const PrivacyPolicyLink = () => {
  return (
    <Typography
      variant='body2'
      textAlign='center'
      sx={{
        '& a:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      By signing up, you agree to this&nbsp;
      <Link href='/privacy' target='_blank' rel='noopener noreferrer' underline='hover'>
        <span className='privacy-policy__text'>privacy policy</span>
      </Link>
    </Typography>
  );
};
