import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { TextField, SxProps, Theme } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import IfElse from 'components/utilities/IfElse';
import { EditableTagsProps } from 'components/utilities/EditableTags/EditableTags';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useTag from 'components/utilities/EditableTags/sub-components/Tag/hooks/useTag';
import { inputBgColor } from 'themes/AppThemeProvider/light';

export type TagProps = {
  label: string;
  borderColor?: string;
  textColor?: string;
} & Omit<EditableTagsProps, 'TagsInput'>;

const customTextFieldPadding = {
  xs: '0.2rem 0.4rem',
  sm: '0.3rem 0.5rem',
};

const Tag: React.FC<TagProps> = ({
  label,
  onChange,
  taglist,
  borderColor,
  textColor,
}) => {
  const theme = useTheme();
  const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

  const {
    tagDOMRef,
    isEditMode,
    editModeState,
    handleEditModeInputChange,
    handleEditConfirmBtnClick,
    handleCloseEditModeBtnClick,
    handleDeleteTagBtnClick,
    handleEditTagBtnClick,
  } = useTag({
    label,
    onChange,
    taglist,
  });

  const tagStyling: SxProps<Theme> = {
    outline: `1px solid ${borderColor || theme.palette.grey[400]}`,
    borderRadius: '16px',
    minWidth: '80px',
    padding: {
      xs: '0.2rem 0.8rem',
      sm: '0.3rem 1rem',
    },
    backgroundColor: inputBgColor,
  };

  const iconButtonStyle: SxProps<Theme> = {
    padding: '4px 0px 4px 8px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.4rem',
    },
  };

  return (
    <Stack
      ref={tagDOMRef}
      spacing={0}
      component='li'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      flexWrap='nowrap'
      sx={tagStyling}
    >
      <IfElse
        condition={isEditMode}
        alternative={
          <Box
            component='span'
            sx={{
              fontSize: '1.4rem',
              color: textColor || 'inherit',
            }}
          >
            {label}
          </Box>
        }
      >
        <TextField
          value={editModeState.text}
          onChange={handleEditModeInputChange}
          variant='outlined'
          size='small'
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: inputBgColor,
              '& .MuiOutlinedInput-input': {
                padding: customTextFieldPadding,
                maxWidth: '10ch',
                fontSize: '1.4rem',
                color: textColor || 'inherit',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: borderColor || 'inherit',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.text.primary,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.primary.main,
                borderWidth: '1px',
                boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
              },
            },
          }}
        />
      </IfElse>
      <Stack direction='row' alignItems='center' spacing={0}>
        <IfElse
          condition={!isEditMode}
          alternative={
            <>
              <IconButton
                onClick={handleEditConfirmBtnClick}
                sx={iconButtonStyle}
              >
                <CheckIcon />
              </IconButton>
              <IconButton
                onClick={handleCloseEditModeBtnClick}
                sx={iconButtonStyle}
              >
                <CloseIcon />
              </IconButton>
            </>
          }
        >
          <IconButton
            size='small'
            onClick={handleEditTagBtnClick}
            sx={iconButtonStyle}
          >
            <EditIcon fontSize='small' />
          </IconButton>
          <IconButton
            onClick={handleDeleteTagBtnClick}
            sx={[iconButtonStyle, { color: theme.palette.error.main }]}
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </IfElse>
      </Stack>
    </Stack>
  );
};

export default Tag;
