import { Link } from 'react-router-dom';

import useGetStripePlatformCustomer from 'store/rtkqFeatures/api/hooks/useGetStripePlatformCustomer';
import UserSettingsSection from '../UserSettingsSection';
import { successURL_key } from 'views/SuccessCancelPages/premiumThemeSubscription/PremiumThemeSubscriptionSuccessPage';

import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import StripePlatformCustomerPortalSession from '../StripePlatformCustomerPortalSession/StripePlatformCustomerPortalSession';

export const subscriptionDashboardURL = '/dashboard/subscription';

const FrontsAIPremium = () => {
  const { isPremiumUser, isPaidPremiumUser } = useGetStripePlatformCustomer();    

  function handleClick() {
    localStorage.setItem(successURL_key, subscriptionDashboardURL);
  }

  if(!isPremiumUser || isPaidPremiumUser) {
    return (
      <UserSettingsSection primaryHeading='Fronts.ai premium' secondaryHeading='Subscribe now for premium access and unlock exclusive features on fronts.ai.'>
        <Link to='/pricing'>
          <ResponsiveButton onClick={handleClick} variant='contained' color='primary'>
            Subscribe
          </ResponsiveButton>
        </Link>
      </UserSettingsSection>
    );
  } else {
    return <StripePlatformCustomerPortalSession />;
  }
};

export default FrontsAIPremium;