import { isStringEmpty } from 'utilities/stringUtilities';

export function getMargin(margin: string): string {
  if (margin === 'left') {
    return '0 auto 0 0';
  } else if (margin === 'right') {
    return '0 0 0 auto';
  } else if (margin === 'center') {
    return 'auto';
  } else {
    return '0';
  }
}

export function getLocalOrGlobalStyling(localStyling: string, globalStyling: string): string {
  if( Boolean(localStyling) && isStringEmpty(localStyling)) {
    return localStyling;
  } 
  return globalStyling;
} 

export function getInPx(num: number): string {
  return `${num}px`;
}
export function getInRem(num: number): string {
  return `${num}rem`;
}
export function getInEm(num: number): string {
  return `${num}em`;
}