import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';

import useAsyncStatus from 'hooks/useAsyncStatus';
import HomeLayout from 'views/Home/utility-components/HomeLayout';
import { useAppDispatch } from 'hooks/redux';
import loginTokenUtils from 'utilities/loginTokenUtils';
import TokenVerificationStatusErrorMsg from 'components/logins/MagicEmailLoginLinkVerify/sub-components/TokenVerificationStatusErrorMsg';
import validateEmailMagicLinkToken from 'components/logins/MagicEmailLoginLinkVerify/utils/validateEmailMagicLinkToken';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';

const MagicEmailLoginLinkVerify = () => {
  const reduxDispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token') ?? '';
  const isVerifiedOnce = useRef(false);
  const {
    isLoading: isVerfifyLoginTokenStatusLoading,
    isSuccess: isVerfifyLoginTokenStatusSuccess,
    isError: isVerfifyLoginTokenStatusError,
    updateStatus: updateVerfifyLoginTokenStatus,
    Actions: VerfifyLoginTokenStatusActions,
  } = useAsyncStatus();

  // verifying token upon token change
  useEffect(() => {
    if (!token || isVerifiedOnce.current) return;
    isVerifiedOnce.current = true;
    (async () => {
      try {
        updateVerfifyLoginTokenStatus({
          type: VerfifyLoginTokenStatusActions.LOADING,
        });
        // console.log('verifying magic login token | token: ', token);
        const { email } = await validateEmailMagicLinkToken({
          loginLinktoken: token,
        });
        
        history.replace({
          pathname: '/select-login-account',
          state: {
            isFromVerifiedMagicLoginLink: true,
            email,
          },
        });
      } catch (error) {
        const isAlreadyLoggedIn = loginTokenUtils.getLocalStorageLoginToken();
        if (isAlreadyLoggedIn) {
          // if the user is already logged in, then we will not log him out and redirect him to sign-in page
          history.replace('/sign-in');
          return;
        }
        updateVerfifyLoginTokenStatus({
          type: VerfifyLoginTokenStatusActions.ERROR,
          payload: 'Error while verifying token',
        });
      }
    })();
  }, [
    token,
    history,
    reduxDispatch,
    updateVerfifyLoginTokenStatus,
    VerfifyLoginTokenStatusActions.LOADING,
    VerfifyLoginTokenStatusActions.SUCCESS,
    VerfifyLoginTokenStatusActions.ERROR,
  ]);

  let content;

  if (isVerfifyLoginTokenStatusLoading) {
    content = (
      <FrontsLoader />
    );
  }

  if (isVerfifyLoginTokenStatusSuccess) {
    content = (
      <Typography textAlign='center' variant='body1' fontStyle='italic'>
        &#40;Validated!!&#41;
      </Typography>
    );
  }

  if (isVerfifyLoginTokenStatusError) {
    content = <TokenVerificationStatusErrorMsg />;
  }

  return (
    <HomeLayout>
      <>{content}</>
    </HomeLayout>
  );
};

export default MagicEmailLoginLinkVerify;
