import { useHistory } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import { SxProps, Theme } from '@mui/material';
import { style_AddAnotherAccount_add_another_account_wrapper } from '../EmailLinkedUserAccount.style';
import { saveRouteToSessionStorage } from 'views/Home/SignIn';
import { addAnotherAccount } from 'utilities/addAnotherAccount';
import routeConstants from 'constants/routeConstants';

type AddAnotherAccountProps = {
  isBtnDisabled: boolean;
  email?: string;
};

function AddAnotherAccount({ isBtnDisabled }: AddAnotherAccountProps) {
  const history = useHistory();

  const handleAddAnotherAccountBtnClick = async () => {
    const toastId = toast.loading('Adding another account...');
    try {
      const result = await addAnotherAccount();

      if (result.success) {
        toast.dismiss(toastId);
        toast.success('Account added successfully');
        saveRouteToSessionStorage(history);
        history.push(routeConstants.ONBOARDING_ROUTE);
      } else {
        toast.dismiss(toastId);
        toast.error('Error adding account');
      }
    } catch (error) {
      toast.dismiss();
      toast.error('An error occurred');
    } finally {
      toast.dismiss(toastId);
    }
  };

  const style: SxProps<Theme> = {
    display: 'flex',
    position: 'sticky',
    bottom: '-1px',
    background: (theme) => theme.palette.common.white,
  };

  return (
    <ListItem
      disablePadding
      alignItems='center'
      className='add_another_account_wrapper'
      sx={style_AddAnotherAccount_add_another_account_wrapper(style)}
    >
      <ListItemButton onClick={handleAddAnotherAccountBtnClick} disabled={isBtnDisabled}>
        <ListItemAvatar>
          <Avatar
            sx={{
              backgroundColor: 'transparent',
              '& svg': {
                fill: grey[700],
              },
            }}
          >
            <PersonAddIcon sx={{ width: 60, height: 50 }} />
          </Avatar>
        </ListItemAvatar>
        <Typography
          variant='body2'
          fontWeight={400}
          color={grey[600]}
          fontSize={'1.5rem'}
        >
          Add another account
        </Typography>
      </ListItemButton>
    </ListItem>
  );
}

export default AddAnotherAccount;
