import { useEffect, useRef } from 'react';
import { Stack } from '@mui/material';
import {toast} from 'react-toastify';

import { ThemeType } from 'themes/AppThemeProvider/themeType';

import {
  PortfolioProperties,
  initialPortfolio,
  initialPortfolioItem,
} from 'types/portfolio';
import PortfolioDesign, {
  callbackFnBtnLabelURL,
  callbackFnPortfolioImg,
} from '../Portfolio/PortfolioDesign';

import ResponsiveButton from 'components/Buttons/ResponsiveButton';

import {
  useAddPortfolioItemMutation,
  useGetPortfoliosByUserIdQuery,
  useUpdatePortfolioItemMutation,
} from 'store/rtkqFeatures/api/portfolioExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { usePortfolioContext } from 'components/Portfolios/PortfolioContext';
import { truncate } from 'components/Portfolios/utilities';

// Type definitions
type EditPortfolioProps = {
  portfolioIndex: number;
  portfolioSylings: ThemeType['portfolios'];
  widthOfParent?: number;
  mode?: 'edit' | 'add';
};

// Component definitions
const EditPortfolio = ({
  portfolioIndex,
  portfolioSylings,
  widthOfParent,
  mode = 'edit',
}: EditPortfolioProps) => {
  // Hooks and state
  const context = usePortfolioContext();
  const { portfolioLocalState, setPortfolioLocalState } = context;
  const ref = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadElement>(null);
  const descRef = useRef<HTMLDivElement>(null);
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data: portfolios } = useGetPortfoliosByUserIdQuery(
    cachedLoggedInUser?._id || ''
  );
  const [updatePortfolio] = useUpdatePortfolioItemMutation();
  const [addPortfolio] = useAddPortfolioItemMutation();

  const portfolio = portfolios?.gallery[portfolioIndex] ?? initialPortfolioItem;
  const portfoliosProperties: PortfolioProperties =
    portfolios?.properties || initialPortfolio.properties;
  const portfolioDesciption = truncate(portfolio.desc, 300);
  const portfolioImg = portfolio?.img || '';
  const portfolioUrl = portfolio?.url || '';

  // Effects
  useEffect(() => {
    if (portfolio) {
      setPortfolioLocalState(portfolio);
    }
  }, [portfolio, setPortfolioLocalState]);

  // Early returns
  if (!portfolio) return <></>;

  // Helper functions
  const getBtnLabelAndURL: callbackFnBtnLabelURL = (buttonLabel, url) => {
    setPortfolioLocalState((prev) => ({
      ...prev,
      url,
      buttonLabel,
    }));
  };

  // Save logic
  const handleSave = async () => {
    const timerID = toast.loading('Saving portfolio');

    const title = titleRef.current?.textContent || '';
    const desc = descRef.current?.textContent || '';

    const temp = {
      title,
      desc,
      img: portfolioLocalState.img,
      url: portfolioLocalState.url,
    };

    const isValid = Object.keys(temp).some((key) => {
      if (temp[key as keyof typeof temp]) {
        return true;
      }
    });

    if (!isValid) {
      toast.dismiss(timerID);
      return toast.error('Portfolio cannot be empty');
    }

    setPortfolioLocalState((prev) => ({
      ...prev,
      title,
      desc,
    }));

    try {
      if (mode === 'add') {
        await addPortfolio({
          userId: cachedLoggedInUser?._id || '',
          newPortfolio: {
            ...portfolioLocalState,
            title,
            desc,
          },
        }).unwrap();
      } else {
        await updatePortfolio({
          userId: cachedLoggedInUser?._id || '',
          portfolioIndex,
          portfolio: {
            ...portfolioLocalState,
            title,
            desc,
          },
        }).unwrap();
      }
      toast.success('Portfolio saved successfully');
    } catch (err) {
      toast.dismiss(timerID);
      toast.error('Error saving portfolio');
    } finally {
      toast.dismiss(timerID);
      context.setOpenEditDialog(false);
      context.setPortfolioIndex(-1);
    }
  };

  // Cancel logic
  const handleCancel = () => {
    context.setOpenEditDialog(false);
    context.setPortfolioIndex(-1);
  };

  // Image upload logic
  const callbackfnImg: callbackFnPortfolioImg = (img: string | File, type) => {
    if (type === 'upload') {
      if (img instanceof File) {
        setPortfolioLocalState((prev) => ({
          ...prev,
          img: URL.createObjectURL(img),
        }));
      } else {
        setPortfolioLocalState((prev) => ({
          ...prev,
          img,
        }));
      }
    }
    if (type === 'remove') {
      setPortfolioLocalState((prev) => ({
        ...prev,
        img: '',
      }));
    }
  };

  return (
    <Stack
      className='edit-portfolio-container'
      direction={'column'}
      sx={{
        background: portfolioSylings.background,
        backgroundSize: portfolioSylings.backgroundSize,
        backgroundBlendMode: portfolioSylings.backgroundBlendMode ?? 'normal',
        overflow: 'auto',
        my: 0,
        mx: 'auto',
        maxHeight: '90%',
        minHeight: 500,
        pb: 0,
        position: 'relative',
      }}
    >
      <PortfolioDesign
        isEditMode
        callbackfnImg={callbackfnImg}
        portfoliosProperties={portfoliosProperties}
        portfolio={portfolioLocalState}
        portfolioIndex={portfolioIndex}
        portfolioSylings={portfolioSylings}
        cardProps={{
          ref,
        }}
        cardWrapper={{
          width: (widthOfParent || 300) + 32,
          px: 2,
        }}
        cardActionAreaProps={{}}
        cardMediaProps={{
          image: portfolioImg,
        }}
        titleProps={{
          ref: titleRef,
          children: portfolio.title,
        }}
        descriptionProps={{
          ref: descRef,
          children: portfolioDesciption,
        }}
        URLProps={{
          showURL: !!portfolioUrl,
        }}
        callbackfn={getBtnLabelAndURL}
      />

      <Stack
        direction='row'
        spacing={2}
        sx={{
          justifyContent: 'flex-end',
          marginLeft: 'auto',
          width: '100%',
          mt: 2,
          p: 2,
          bgcolor: (theme) => theme.palette.background.paper,
        }}
      >
        <ResponsiveButton onClick={handleCancel} size='small' color='secondary'>
          Cancel
        </ResponsiveButton>
        <ResponsiveButton
          onClick={handleSave}
          size='small'
          color='primary'
          variant='contained'
        >
          {mode === 'add' ? 'Add' : 'Save'}
        </ResponsiveButton>
      </Stack>
    </Stack>
  );
};

export default EditPortfolio;
