import React, { forwardRef, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import isURL from 'validator/lib/isURL';
import {toast} from 'react-toastify';

import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import { DEFAULT_WEBSITE_ICON, fetchFavicon } from 'utilities/fetchFavicon';
import SocialContextProvider, {
  SocialContextProviderRef,
  SocialLinksGlobalState,
  useSocialContext,
  getFullSocialLink,
} from './SocialContextProvider';

import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { SocialLinkType } from 'types/user';
import InputWithButton from 'components/InputField/InputWithButton';

// Constants
export const socialIconsURL = {
  instagram:
    'https://res.cloudinary.com/salesfronts/image/upload/v1713421292/icons/i5f8ukhcyn9oebb9xzmt.png',
  tiktok:
    'https://res.cloudinary.com/salesfronts/image/upload/v1713192004/icons/pdpuaacp9xrunpdwoblf.png',
  facebook:
    'https://res.cloudinary.com/salesfronts/image/upload/v1713421291/icons/c4dlrszsu9ojlojxcqpn.png',
  twitter:
    'https://res.cloudinary.com/salesfronts/image/upload/v1713454916/icons/iubz0lckd8esxwd6azcz.png',
  x: 'https://res.cloudinary.com/salesfronts/image/upload/v1713454916/icons/iubz0lckd8esxwd6azcz.png',
  youtube:
    'https://res.cloudinary.com/salesfronts/image/upload/v1713421494/icons/ty70ghrma6snvid1gzvf.png',
  linkedin:
    'https://res.cloudinary.com/salesfronts/image/upload/v1713191987/icons/dnqtrng6prlapzfgu8zc.png',
};
const initialsocialLinkInputState = {
  error: '',
  link: '',
  iconUrl: '',
};
const socialIconsSize = {
  width: { xs: 25, md: 30 },
  height: { xs: 25, md: 30 },
  objectFit: 'contain',
};

interface SocialLinkInputProps {
  callback?: () => any;
  sx?: SxProps<Theme>;
  socialLinks?: SocialLinkType[];
}

// Main component
const SocialLinkInput = forwardRef<SocialContextProviderRef, SocialLinkInputProps>(
  ({ callback, sx, socialLinks }, ref) => {
    return (
      <Box sx={sx} position={'relative'}>
        <SocialContextProvider ref={ref} socialLinks={socialLinks}>
          <SocialLink
            imgSrc={socialIconsURL.instagram}
            placeHolder='Add Instagram handle'
            name='instagram'
            iconSx={socialIconsSize}
          />
          <SocialLink
            imgSrc={socialIconsURL.tiktok}
            placeHolder='Add Tiktok handle'
            name='tiktok'
            iconSx={socialIconsSize}
          />
          <SocialLink
            imgSrc={socialIconsURL.facebook}
            placeHolder='Add Facebook handle'
            name='facebook'
            iconSx={socialIconsSize}
          />
          <SocialLink
            imgSrc={socialIconsURL.x}
            placeHolder='Add x handle'
            name='x'
            iconSx={socialIconsSize}
          />
          <SocialLink
            imgSrc={socialIconsURL.youtube}
            placeHolder='Add Youtube handle'
            name='youtube'
            iconSx={socialIconsSize}
          />
          <SocialLink
            imgSrc={socialIconsURL.linkedin}
            placeHolder='Add LinkedIn handle'
            name='linkedin'
            iconSx={socialIconsSize}
          />
          <CustomSocialLinks />

          <Divider sx={{ my: 2 }} />

          <AddCustomSocialLink />
        </SocialContextProvider>
      </Box>
    );
  }
);

export default SocialLinkInput;

function Icon({ children }: { children: React.ReactNode }) {
  return (
    <InputAdornment
      sx={{
        minWidth: 30,
        pl: 0,
        pr: 0,
        '& input::placeholder': {
          fontSize: { xs: '1.2rem', md: '1.2rem' },
        },
      }}
      position='start'
    >
      {children}
    </InputAdornment>
  );
}

// Sub-Components
function SocialLink({
  name,
  imgSrc,
  placeHolder,
  iconSx,
}: {
  name: keyof SocialLinksGlobalState['links'];
  imgSrc: string;
  placeHolder: string;
  iconSx?: SxProps;
}) {
  const { socialLinksGlobalState, setSocialLinksGlobalState } = useSocialContext();

  const handlePreview = () => {
    const [fullUrl, handleOnly] = getFullSocialLink(socialLinksGlobalState, name);
    if (fullUrl) {
      setSocialLinksGlobalState((prev) => ({
        ...prev,
        links: {
          ...prev.links,
          [name]: handleOnly,
        },
      }));
      window.open(fullUrl, '_blank');
    }
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();

    // validate if the e.target.value is in uppercase
    let value = e.target.value
      .trim()
      .toLowerCase()
      .replace('twitter.com', 'x.com')
      .replace('http://', 'https://');

    setSocialLinksGlobalState((prev) => ({
      ...prev,
      links: {
        ...prev.links,
        [name]: value,
      },
    }));
  }

  return (
    <Box
      sx={{
        '& .MuiInputBase-input': {
          padding: {
            xs: '0.8rem 1rem 0.8rem 0 !important',
            md: '1rem 1.25rem 1rem 0 !important',
          },
        },
      }}
    >
      <TextFieldSyncWithTheme
        fullWidth
        autoCapitalize='none'
        autoComplete='off'
        value={socialLinksGlobalState.links[name]}
        onChange={handleChange}
        placeholder={placeHolder}
        FormHelperTextProps={{
          sx: {
            fontSize: '80%',
          },
        }}
        InputProps={{
          startAdornment: (
            <Icon>
              <Typography
                sx={{
                  objectFit: 'contain',
                  ...iconSx,
                }}
                component={'img'}
                src={imgSrc || DEFAULT_WEBSITE_ICON}
                alt={`${name}-icon`}
              />
            </Icon>
          ),
          endAdornment: socialLinksGlobalState.links[name] ? (
            <InputAdornment position='end'>
              <IconButton onClick={handlePreview}>
                <OpenInNewIcon
                  sx={{
                    fontSize: '1.7rem',
                    verticalAlign: 'middle',
                  }}
                />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </Box>
  );
}

function CustomSocialLinks() {
  const { socialLinksGlobalState, setSocialLinksGlobalState } = useSocialContext();

  function handleDeleteLinkBtnClick(link: string) {
    const socialLinkFilteredReplica = socialLinksGlobalState.customLinks?.filter(
      (socialLink) => socialLink.link !== link
    );
    setSocialLinksGlobalState((prev) => ({
      ...prev,
      customLinks: socialLinkFilteredReplica,
    }));
  }

  return (
    <>
      {socialLinksGlobalState.customLinks?.map((socialLink, index) => {
        return (
          <Stack
            key={index}
            direction='row'
            alignItems='center'
            spacing={0}
            maxWidth={'100%'}
          >
            <TextFieldSyncWithTheme
              autoComplete='off'
              fullWidth
              value={socialLink.link}
              type='url'
              FormHelperTextProps={{
                sx: {
                  fontSize: '80%',
                },
              }}
              InputProps={{
                startAdornment: (
                  <Icon>
                    <Typography
                      sx={{
                        width: 30,
                        objectFit: 'contain',
                      }}
                      component={'img'}
                      src={socialLink.iconUrl}
                      alt='custom-icon'
                    />
                  </Icon>
                ),
              }}
            />
            <IconButton
              color='error'
              onClick={() => handleDeleteLinkBtnClick(socialLink.link || '')}
              sx={{ borderRadius: 0 }}
            >
              <DeleteIcon sx={{ width: 25, height: 25 }} />
            </IconButton>
          </Stack>
        );
      })}
    </>
  );
}

function AddCustomSocialLink() {
  const { setSocialLinksGlobalState } = useSocialContext();

  const [socialLinkInputState, setSocialLinkInputState] = useState(
    initialsocialLinkInputState
  );

  async function handleAddSubmit() {
    const timerId = toast.loading('Fetching favicon');
    try {
      let link = socialLinkInputState.link.trim();

      if (!link || link.trim().length === 0) {
        toast.dismiss(timerId);
        toast.error('Please enter a valid link');
        return;
      }

      if (!link.includes('https://')) {
        link = 'https://' + link;
      }

      const iconUrl = await fetchFavicon(link);

      if (socialLinkInputState.error) {
        toast.dismiss(timerId);
        toast.error('Please enter a valid link');
        return;
      }

      setSocialLinkInputState((prev) => ({
        ...prev,
        iconUrl,
      }));
      setSocialLinksGlobalState((prev) => ({
        ...prev,
        customLinks: [...prev.customLinks, { link, iconUrl }],
      }));

      toast.dismiss(timerId);
      setSocialLinkInputState(initialsocialLinkInputState);
      toast.success('Favicon fetched successfully');
    } catch (error) {
      console.error('error fetching favicon', error);
    }
  }

  function handleKeyUp(e: React.KeyboardEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleAddSubmit();
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    e.stopPropagation();

    const value = e.target.value;
    const testValue = value.trim();

    if (testValue.length === 0) {
      setSocialLinkInputState((prev) => ({
        ...prev,
        error: '',
        link: value,
      }));
      return;
    }

    // ✅ validation: too short?
    if (value.length < 4) {
      setSocialLinkInputState((prev) => ({
        ...prev,
        error: 'Too short',
        link: value,
      }));
      return;
    }

    // ✅ validation: isValidLink?
    if (!isURL(testValue)) {
      setSocialLinkInputState((prev) => ({
        ...prev,
        error: 'Not a valid link',
        link: value,
      }));
      return;
    }

    setSocialLinkInputState((prev) => ({
      ...prev,
      error: '',
      link: value,
    }));
  }

  return (
    <InputWithButton
      buttonLabel='Add'
      textFieldsProps={{
        autoComplete: 'off',
        fullWidth: true,
        value: socialLinkInputState.link,
        onChange: handleChange,
        onKeyUp: handleKeyUp,
        helperText: socialLinkInputState.error,
        placeholder: 'Add website URL',
        type: 'url',
        FormHelperTextProps: {
          sx: {
            fontSize: '80%',
          },
        },
        InputProps: {
          startAdornment: (
            <Icon>
              <Typography
                sx={{
                  width: 30,
                  objectFit: 'contain',
                }}
                component={'img'}
                src={DEFAULT_WEBSITE_ICON}
                alt='custom-icon'
              />
            </Icon>
          ),
        },
      }}
      buttonProps={{
        disabled: socialLinkInputState.error.length > 0,
        onClick: handleAddSubmit,        
      }}
    />
  );  
}
