type PrivacyText16Props = {
  children: JSX.Element | string;
  classname?: string;
};

const PrivacyText16 = ({ children, classname }: PrivacyText16Props) => {
  return (
    <p className={`privacy-text privacy-text-16 ${classname}`}>{children}</p>
  );
};

export default PrivacyText16;
