import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import BoldSpan from 'components/utilities/BoldSpan';
import routeConstants from 'constants/routeConstants';

const homeRoute = routeConstants.HOME_ROUTE;

// if isVerfifyLoginTokenStatusError === 'error'
const TokenVerificationStatusErrorMsg = () => {
  const history = useHistory();

  const handleRequestNewLoginLinkBtnClick = () => {
    history.replace(homeRoute);
  };

  return (
    <Stack spacing={3}>
      <Typography
        variant='h3'
        textAlign='left'
        sx={{
          color: red[400],
        }}
      >
        Error
      </Typography>
      <Stack spacing={1.3}>
        <Typography variant='body2'>
          We're sorry, but the login link you clicked is invalid or has expired.
        </Typography>
        <Typography variant='body2'>
          This can happen if you have already used this login link, or if the link has
          been in your inbox for more than <BoldSpan>1hr</BoldSpan>.
        </Typography>
        <Typography variant='body2'>
          If you continue to experience issues, please contact our support team for
          further assistance.
        </Typography>
        <Typography variant='body2'>Thank you for using our service.</Typography>
      </Stack>
      <Box>
        <Button
          onClick={handleRequestNewLoginLinkBtnClick}
          variant='contained'
          sx={{
            fontSize: '1.5rem',
            padding: '0.8em 1em',
          }}
        >
          Request new login link
        </Button>
      </Box>
    </Stack>
  );
};

export default TokenVerificationStatusErrorMsg;
