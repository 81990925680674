import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SERVER_API_BASE_URL } from 'lib/apiConfig';
import loginTokenUtils from 'utilities/loginTokenUtils';

const salesfrontsApiSlice = createApi({
  reducerPath: 'salesfrontsbackendApi', // RTKQ cache stored under this name
  baseQuery: fetchBaseQuery({
    baseUrl: SERVER_API_BASE_URL.V1,
    credentials: 'include',
    prepareHeaders: (headers, api) => {
      const loginToken = loginTokenUtils.getLocalStorageLoginToken();
      if (loginToken) {
        headers.set('authorization', `Bearer ${loginToken}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: [
    'Editor',
    'Session',
    'Portfolio',
    'Theme',
    'Meeting',
    'Service',
    'SortedSPMIds',
    'StripeConnectedAccount',
    'StripeConnectedAccountStatus',
    'StripePlatformCustomer',
    'ServiceOrder',
    'Search',
    'StripeUtilsMinimumCharge',
    'LinkedAccount',
    'DomainDetail',
    'DomainNameSuggestions',
    'DomainExactSearch',
    'DomainFavouriteSearch',
  ],
});

export default salesfrontsApiSlice;

// rest salesfrontsbackendApi app slice
export const resetSalesfrontsAPIQueryCache = salesfrontsApiSlice.util.resetApiState;

// const RTKQueryAxiosInstance = serverApi.v1;
// RTKQueryAxiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // if received a response
//     if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
//       if ('response' in error) {
//         const errorStatus = error.response?.status;
//         const errorData = error.response?.data;
//         const isUnauthorizedError = Boolean(errorData?.isUnauthorizedUser);

//         if (errorStatus === 401) {
//           if (isUnauthorizedError) {
//             console.log('logged out from logoutService()');
//             logoutService();
//           }
//         }
//       }
//     }
//     // Promise.reject(error);
//     throw error;
//   }
// );
