import { sucessfulLoginInit } from 'components/logins/onLoginSuccessUtilities';
import serverApi from 'lib/apiConfig';
import getNodeEnv from 'utilities/getNodeEnv';
import loginTokenUtils from 'utilities/loginTokenUtils';

const authServices = { authenticateUser };
export default authServices;

async function authenticateUser({ loginToken }: Record<'loginToken', string>) {
  try {
    await serverApi.v1.post(
      '/auth/authenticate-user',
      {},
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    throw error;
  }
}

export async function setLoginTokenOnSelectedUserAccount({
  email,
  userId,
  loginToken,
}: Record<'email' | 'userId' | 'loginToken', string>) {  
  try {
    const { data } = await serverApi.v1.post<Record<'newLoginToken', string>>(
      '/auth/set-user-login-token',
      {
        email,
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
        },
      }
    );
    return data;
  } catch (error) {
    throw error;
  }
}

export async function attempt_login_with_existing_token({
  email,
  userId,
}: Record<'email' | 'userId', string>) {
  const isNodeEnvProduction = getNodeEnv();
  try {
    const loginToken = loginTokenUtils.getLocalStorageLoginToken();
    // const loginToken = localStorage.getItem("loginToken");

    if (!loginToken) {
      const errorMsg = 'loginToken not found';
      !isNodeEnvProduction && console.error('loginToken not found');
      throw new Error(errorMsg);
    }
    
    const { newLoginToken } = await setLoginTokenOnSelectedUserAccount({
      email,
      userId,
      loginToken,
    });

    loginTokenUtils.setLocalStorageLoginToken(newLoginToken);
    // localStorage.setItem("loginToken", newLoginToken);
    const choosenAccountLoginToken = loginTokenUtils.getLocalStorageLoginToken();
    // const choosenAccountLoginToken = localStorage.getItem("loginToken");;

    if (!choosenAccountLoginToken) {
      const ERROR_MSG = 'choosenAccountLoginToken Token not found';
      !isNodeEnvProduction && console.error(ERROR_MSG);
      throw new Error(ERROR_MSG);
    }

    await authServices.authenticateUser({
      loginToken: choosenAccountLoginToken,
    });

    // fetch selected user data
    await sucessfulLoginInit({
      editorId: userId,
    });

    return loginToken;
  } catch (error) {
    throw error;
  }
}
