import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import AddAnotherAccount from 'components/logins/SelectLoginAccount/sub-components/EmailLinkedUserAccounts/sub-components/AddAnotherAccount';
import routeConstants from 'constants/routeConstants';
import { attempt_login_with_existing_token } from 'lib/services/authServices';
import IfElse from 'components/utilities/IfElse';
import AccountLists from './sub-components/AccountLists';
import getNodeEnv from 'utilities/getNodeEnv';

import {  
  EmailLinkedUserAccount_bodyWrapper_width,
  style_EmailLinkedUserAccount_UserAccountsList,
  style_EmailLinkedUserAccount_bodyWrapper,
  style_EmailLinkedUserAccount_chooseAccountWrapper,
  style_EmailLinkedUserAccount_container,
  style_EmailLinkedUserAccount_progressWrapper,
} from './EmailLinkedUserAccount.style';
import { EmailLinkedUserAccounts as EmailLinkedUserAccountsType } from 'store/rtkqFeatures/api/linkedAccountsExtendedApi';
import { gradientBgColor } from 'themes/AppThemeProvider/light';
import { Link } from '@mui/material';

export type EmailLinkedUserAccountsProps = {
  emailLinkedUserAccounts: EmailLinkedUserAccountsType;
  redirectRoute?: string;
  clearLoggedInUser: () => void;
  callback?: () => void;
};

const EmailLinkedUserAccounts = ({
  emailLinkedUserAccounts,
  redirectRoute,
  clearLoggedInUser,
  callback,
}: EmailLinkedUserAccountsProps) => {
  const history = useHistory();
  const isNodeEnvProduction = getNodeEnv();
  const [userLoginStatus, setUserLoginStatus] = useState<'idle' | 'loading'>('idle');
  const isUserLoginStatusLoading = userLoginStatus === 'loading';
  const showAddAccount = emailLinkedUserAccounts.totalAccounts <= 5;

  const handleUserAccountClick = async ({
    email,
    userId,
    userURL,
  }: Record<'email' | 'userId' | 'userURL', string>) => {
    try {
      setUserLoginStatus('loading');
      await attempt_login_with_existing_token({ email, userId });

      // redirect the user to editor page
      history.replace({
        pathname: redirectRoute ? redirectRoute : `/${userURL}`,
      });
    } catch (error) {
      history.replace(routeConstants.HOME_ROUTE);
      const ERROR_MSG = 'Error while logging in';
      toast.error(ERROR_MSG);
      !isNodeEnvProduction && console.error(`${ERROR_MSG} | error: `, error);
      clearLoggedInUser();
    } finally {
      callback && callback();
      setUserLoginStatus('idle');
    }
  };
  const handleLogout = () => {
    clearLoggedInUser();
    history.push(routeConstants.HOME_ROUTE);
  };

  return (
    <Box sx={{ background: gradientBgColor }}>
      <Container sx={style_EmailLinkedUserAccount_container()}>
        <Box
          className='style_EmailLinkedUserAccount_bodyWrapper'
          sx={style_EmailLinkedUserAccount_bodyWrapper()}
        >
          <IfElse condition={isUserLoginStatusLoading}>
            <Box sx={style_EmailLinkedUserAccount_progressWrapper()}>
              <LinearProgress />
            </Box>
          </IfElse>
          <Stack sx={style_EmailLinkedUserAccount_chooseAccountWrapper()}>
            <Typography
              className='style_EmailLinkedUserAccount_ChooseAccount'
              variant='h6'
              fontWeight={600}
              textAlign={'start'}
              pl={3}
              py={1}
            >
              Choose an account
            </Typography>
          </Stack>
          <List
            className='style_EmailLinkedUserAccount_UserAccountsList'
            sx={style_EmailLinkedUserAccount_UserAccountsList()}
          >
            {emailLinkedUserAccounts.linkedAccounts.map((account) => (
              <AccountLists
                key={account._id}
                setUserLoginStatus={setUserLoginStatus}
                account={account}
                isUserLoginStatusLoading={isUserLoginStatusLoading}
                handleUserAccountClick={handleUserAccountClick}
              />
            ))}
            {showAddAccount && (
              <AddAnotherAccount
                isBtnDisabled={isUserLoginStatusLoading}
                email={emailLinkedUserAccounts.email}
              />
            )}
          </List>
        </Box>

        <Box sx={{
          bgcolor: {xs: 'white', md: 'inherit'},
          maxWidth: {
            xs: '100%',
            md: `${EmailLinkedUserAccount_bodyWrapper_width}px`,
          },
          pb: 4,
          textAlign: 'center',
          alignSelf: 'self-start',
        }}>
          <Link underline='always' onClick={handleLogout} sx={{
            textDecorationColor: 'initial',
            cursor: 'pointer',
          }}>
            Logout
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default EmailLinkedUserAccounts;
