import { useRef } from 'react';
import CardActions from '@mui/material/CardActions/CardActions';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { toast } from 'react-toastify';

import IconButton from '@mui/material/IconButton';

import {
  useDeletePortfolioItemByIndexMutation,
  useGetPortfoliosByUserIdQuery,
} from 'store/rtkqFeatures/api/portfolioExtendedApi';
import useBooleanStateController from 'hooks/useBooleanStateController';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

import {
  PortfolioProperties,
  initialPortfolio,
  initialPortfolioItem,
} from 'types/portfolio';
import { ThemeType } from 'themes/AppThemeProvider/themeType';

import BootstrapLoader from 'components/utilities/Loaders/BootstrapLoader';
import ConfirmationDialog from 'Popups/ConfirmationDialog';
import Popup from 'components/utilities/Popups/Popup';
import EditPortfolio from '../edit-portfolio/EditPortfolio';
import PortfolioDesign from './PortfolioDesign';
import { truncate } from 'components/Portfolios/utilities';
import { usePortfolioContext } from 'components/Portfolios/PortfolioContext';

// 📔 Portfolio Query cache can be invalidated with component prop: "portfolioIndex"
type PortfolioProps = {
  portfolioIndex: number;
  portfolioSylings: ThemeType['portfolios'];
};

const Portfolio = ({ portfolioIndex, portfolioSylings }: PortfolioProps) => {
  const context = usePortfolioContext();
  const ref = useRef<HTMLDivElement>(null);
  const [openDeleteDialog, deleteDialogController] = useBooleanStateController();
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const userId = cachedLoggedInUser?._id || '';
  const [deletePortfolioItem, { isLoading: isPortfolioDeletionInProgress }] =
    useDeletePortfolioItemByIndexMutation();
  const { data: portfolios } = useGetPortfoliosByUserIdQuery(userId);

  const thisPortfolio = portfolios?.gallery?.at(portfolioIndex) ?? initialPortfolioItem;
  const portfoliosProperties: PortfolioProperties =
    portfolios?.properties || initialPortfolio.properties;

  // Early returns
  if (!thisPortfolio) return <BootstrapLoader />;

  // Destructuring  
  const portfolioImgRadius = portfolios?.properties?.image?.borderRadius ? portfolios?.properties?.image?.borderRadius: 0;  
  const portfolioImg = thisPortfolio.img;
  const portfolioUrl = thisPortfolio.url;
  const portfolioDescription = truncate(thisPortfolio.desc, 300);
  const doesPortfolioHaveDescOnly =
    !!portfolioDescription && !(!!portfolioImg && !!portfolioUrl);
  const { descriptionStyle } = portfolioSylings.item;

  async function handleDeletePortfolio() {
    try {
      const loaderId = toast.loading('Deleting portfolio');
      deleteDialogController.setFalse();
      await deletePortfolioItem({
        userId,
        portfolioIndex,
      }).unwrap();
      toast.dismiss(loaderId);
      toast.success('Portfolio deleted successfully');
    } catch (error) {
      toast.error('Error deleting portfolio');
    }
  }

  function handleDeletePortfolioIconClick() {
    deleteDialogController.setTrue();
  }

  function handleEditClick() {
    context.setPortfolioIndex(portfolioIndex);
    context.setOpenEditDialog(true);
  }

  function handleClose() {
    context.setOpenEditDialog(false);
    context.setPortfolioIndex(-1);
  }
  
  const cardActions = (
    <CardActions
      sx={{
        flexDirection: 'row',
        borderTop: doesPortfolioHaveDescOnly ? `2px solid ${grey[100]}` : '0',
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        color: portfolioSylings.title.color,
        borderRadius: `0 ${portfolioImgRadius.toString().includes('%') ? 0 : portfolioImgRadius} 0 0`,
        padding: '4px',
        fontSize: {
          xs: `calc(${descriptionStyle.fontSize} / 1.2)`,
          md: descriptionStyle.fontSize,
        },
      }}
    >
      <IconButton onClick={handleDeletePortfolioIconClick}>
        <DeleteIcon sx={{ fontSize: 'inherit', color: '#000' }} />
      </IconButton>
      <IconButton onClick={handleEditClick}>
        <EditIcon sx={{ fontSize: 'inherit', color: '#000' }} />
      </IconButton>
    </CardActions>
  );

  return (
    <>
      <PortfolioDesign
        portfoliosProperties={portfoliosProperties}
        portfolioSylings={portfolioSylings}
        cardProps={{
          ref,
        }}
        cardActions={cardActions}
        cardActionAreaProps={{
          onClick: handleEditClick,
        }}
        cardMediaProps={{
          image: portfolioImg,
        }}
        titleProps={{}}
        descriptionProps={{
          children: portfolioDescription,
        }}
        URLProps={{
          showURL: !!portfolioUrl,
        }}
        portfolio={thisPortfolio}
      />
      <ConfirmationDialog
        isLoading={isPortfolioDeletionInProgress}
        open={openDeleteDialog}
        title='Delete Portfolio'
        content='Are you sure you want to delete this portfolio?'
        handleDialogClose={deleteDialogController.setFalse}
        handleAgreeBtnClick={handleDeletePortfolio}
      />
      <Popup
        onClose={handleClose}
        dialogProps={{
          fullWidth: false,
          open: context.portfolioIndex === portfolioIndex && context.openEditDialog,
          PaperProps: {
            className: 'edit-portfolio-dialog',
            sx: {
              m: { xs: '16px', md: '32px' },
              py: 0,
            },
          },
        }}
        setOpen={() => {}}
      >
        <EditPortfolio
          widthOfParent={ref.current?.clientWidth || 300}
          portfolioIndex={portfolioIndex}
          portfolioSylings={portfolioSylings}
        />
      </Popup>
    </>
  );
};

export default Portfolio;
