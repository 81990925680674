import { useEffect, useRef, useState } from 'react';
import { Box, Grid, Stack, SxProps, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';

import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import IconPopup from '../IconPopup';
import SocialIconsList from 'components/utilities/SocialIconsList';
import IfElse from 'components/utilities/IfElse';
import Tags from '../Tags';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import WavesLayout9 from './WavesLayout9';
import LayoutContainer from 'components/Containers/LayoutContainer';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import {
  checkImageTransparency,
} from 'utilities/analyzeAndSuggestTextColor';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import LayoutWrapper from './reusable-components/LayoutWrapper';
import LayoutProfileImg from './reusable-components/LayoutProfileImg';

const Layout9 = () => {
  const {
    currentTheme,
    userImage,
    tagsArrayLength,
    tagsArray,
    closeIconPopup,
    userName,
    userTitle,
    userProfileLink,
    showIconPopup,
    user,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,
    userBgImage,

    buttonStyle,

    isMeetingAndServiceAvailable,
    handleImageError,
    storeHeading,

    isMobile,
    paddingBottom,
    paddingTop,
    tag,
    global,

    bgImageWithColorURL,
    bg,
    displaySocialLinks,
    displayTags,

    userNameColor,
    titleColor,
  } = useProfileCard();
  const [contentDiv, setContentDiv] = useState<HTMLDivElement | null>(null);
  const [isImageTransparent, setIsImageTransparent] = useState(false);
  const contentDivRef = useRef<HTMLDivElement>(null);
  const [showAnimation, setShowAnimation] = useState(false);

  function handleMouseEnter() {
    setShowAnimation(true);
  }

  function handleMouseLeave() {
    setShowAnimation(false);
  }

  useEffect(() => {
    setContentDiv(contentDivRef.current);
  }, [contentDivRef]);

  useEffect(() => {
    (async () => {
      try {
        const isTransparent = await checkImageTransparency(userImage);
        setIsImageTransparent(isTransparent);
      } catch (err) {
        console.error(err);
      }
    })();
  }, [userImage]);

  if (!currentTheme) return <FrontsLoader />;

  const styleForWrapperBox = userBgImage
    ? {
        backgroundImage: `url(${bgImageWithColorURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }
    : bg;

  const styleForImageBox: SxProps = isImageTransparent
    ? { border: 'none', filter: `drop-shadow(4px 4px 0px black)` }
    : {
        border: {
          xs: `2px solid ${currentTheme?.profile.userName.color}`,
          md: `5px solid ${currentTheme?.profile.userName.color}`,
        },
      };

  return (
    <Grid item sx={{ bgcolor: 'white' }}>
      {userProfileLink && (
        <IconPopup
          showPopup={showIconPopup}
          closeIconPopup={closeIconPopup}
          profileLink={userProfileLink as string}
        />
      )}
      <Stack>
        <LayoutWrapper
          sx={{
            direction: user?.languageDir ?? 'ltr',
            minHeight: {
              xs: contentDiv?.getBoundingClientRect().bottom,
              md: '100vh',
            },
            backgroundColor: currentTheme.themeColor,
            ...styleForWrapperBox,
          }}
        >
          <WavesLayout9 />
          <LayoutContainer>
            <ProfileEditAndDesignBtn />
            <Stack
              ref={contentDivRef}
              direction={'row'}
              spacing={1.5}
              useFlexGap
              mt={{ xs: 4, md: 8 }}
              flexWrap={{ xs: 'wrap', md: 'nowrap' }}
              flexGrow={1}
            >
              <Stack
                rowGap={1.5}
                sx={{ flexBasis: { xs: '100%', md: '50%' }, order: { xs: 2, md: 1 } }}
              >
                <Box>
                  {/* USERNAME */}
                  <Typography
                    className='align'
                    variant='h1'
                    fontSize={{ xs: 25, md: 40 }}
                    color={userNameColor}
                  >
                    <Link to={'/edit-user'} style={{ color: 'inherit' }}>
                      {userName}
                    </Link>
                  </Typography>
                </Box>

                <Box>
                  {/* TITLE */}
                  <Link to={'/edit-user'}>
                    <Typography
                      component='h3'
                      variant='h3'
                      color={titleColor}
                      fontSize={{ xs: 18, md: 25 }}
                    >
                      {userTitle}
                    </Typography>
                  </Link>
                </Box>

                {/* SOCIAL ICONS  */}
                {displaySocialLinks !== false && (
                  <Box display='inline-block'>
                    <SocialIconsList
                      emailPublic={emailPublic}
                      email={email}
                      socialLinks={socialLinks}
                      whatsapp={whatsapp}
                      address={address}
                      phone={phone}
                      styling={{ justifyContent: 'center', display: 'inline-flex' }}
                      showOnlyIcon
                    />
                  </Box>
                )}

                {/* TAGS */}
                <IfElse condition={!!tagsArrayLength && displayTags !== false}>
                  <Box>
                    <Link to={'/edit-user'}>
                      <Tags
                        tagsArray={tagsArray}
                        tag={tag}
                        global={global}
                        sx={{ justifyContent: { xs: 'flex-start', md: 'flex-start' } }}
                      />
                    </Link>
                  </Box>
                </IfElse>

                {/* CTA BUTTONS */}
                {isMeetingAndServiceAvailable && (
                  <Stack
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter}
                    direction={'row'}
                    spacing={3}
                    useFlexGap
                    pb={{ xs: 0, md: 5 }}
                  >
                    {isMeetingAndServiceAvailable && storeHeading && (
                      <CallToActionBtn
                        user={user}
                        buttonStyle={buttonStyle}
                        storeHeading={storeHeading}
                      />
                    )}
                  </Stack>
                )}
              </Stack>

              {/* USER IMAGE */}
              <Box
                sx={{
                  margin: { xs: '0', md: 'auto' },
                  borderRadius: 0,
                  maxWidth: { xs: 250, md: 500 },
                  position: 'relative',
                  order: { xs: 1, md: 2 },
                }}
                aria-label='user img'
              >
                <Box
                  sx={{
                    transform: {
                      xs: 'none',
                      md: showAnimation ? 'rotate(0)' : 'rotate(9deg)',
                    },
                    borderRadius: '5px',
                    zIndex: 2,
                    position: 'relative',
                    transition: 'transform 0.3s ease-in-out',
                    ...styleForImageBox,
                  }}
                >
                  <LayoutProfileImg />
                </Box>
                {!isMobile && !isImageTransparent && (
                  <Box
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                      top: 0,
                      zIndex: 1,
                      border: `3px solid ${currentTheme?.profile.userName.color}`,
                      borderRadius: '5px',
                      transform: 'rotate(0)',
                    }}
                  />
                )}
              </Box>
            </Stack>
          </LayoutContainer>
        </LayoutWrapper>

        {/* sub title --------------------------------------------- */}
        <ProfileSubtitle
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          subTitle={user.subTitle}
          subTitleStyle={{
            pt: userBgImage ? 2 : 0,
            color: currentTheme?.profile.subtitle.color,
          }}
        />
      </Stack>
    </Grid>
  );
};

export default Layout9;
