import React from 'react';
import { Box, ButtonGroup, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

import { getInPx } from 'components/utilities/styleUtilities/styleUtilities';
import BootstrapLoader from 'components/utilities/Loaders/BootstrapLoader';
import useStoreStyles from 'components/MUI_SPM/Store/hooks/useStoreStyles';
import StoreHeading from 'components/MUI_SPM/Store/sub-components/StoreHeading';
import AddStoreEntitiesBtn from 'components/MUI_SPM/Store/sub-components/AddStoreEntitiesBtn';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import LayoutContainer from 'components/Containers/LayoutContainer';
import StoreDrawer from './sub-components/StoreDrawer';

const CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER = 2.5;

type StoreProps = {
  children: JSX.Element | JSX.Element[];
};

const Store: React.FunctionComponent<StoreProps> = ({ children }) => {
  const { storeStylings } = useStoreStyles();  
  const { cachedLoggedInUser } = useGetLoggedInUser();

  if (!storeStylings) {
    return <BootstrapLoader />;
  }

  const {
    background,
    backgroundSize,
    backgroundBlendMode,
    paddingBottom,
    paddingTop,
    title,
  } = storeStylings;

  return (
    <Box
      id={cachedLoggedInUser?.ui?.storeHeading}
      sx={{
        background: background,
        backgroundSize: backgroundSize,
        backgroundBlendMode,
      }}
    >
      <LayoutContainer
        sx={{
          paddingTop: {
            xs: getInPx(paddingTop / CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER),
            sm: getInPx(paddingTop),
          },
          paddingBottom: {
            xs: getInPx(paddingBottom / CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER),
            sm: getInPx(paddingBottom),
          },
        }}
      >
        {/* store heading */}
        <StoreHeading
          fontSize={title.fontSize}
          color={title.color}
          textAlign={title.textAlign}
        />
        {/* btn */}
        <Stack
          dir='ltr'
          direction='row'
          spacing={2}
          marginBottom={1}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
        >
          <ButtonGroup
            variant='contained'
            aria-label='outlined primary button group'
            sx={{
              '.MuiButtonGroup-grouped:not(:last-of-type)': { borderColor: grey[400] },
            }}
          >
            <StoreDrawer />
            <AddStoreEntitiesBtn styling={{}} />
          </ButtonGroup>
        </Stack>
        {/* children as sorted SPM */}
        {children}
      </LayoutContainer>
    </Box>
  );
};

export default Store;
