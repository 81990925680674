import React, { forwardRef, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Button,
  ButtonBase,
  Grid,
  Paper,
  Stack,
  Theme,
  Typography,
  lighten,
} from '@mui/material';
import { toast } from 'react-toastify';

import { Route, Switch, useRouteMatch, NavLink, useHistory } from 'react-router-dom';

import UserSettingsSection from 'components/dashboard/UserSettingsSection';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';

import {
  EmailLinkedAccount,
  useGetLinkedAccountsQuery,
} from 'store/rtkqFeatures/api/linkedAccountsExtendedApi';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import SiteDetailsPopup from './SiteDetailsPopup';
import { PopupText } from 'components/dashboard/DashboardReusableComponent';
import { saveRouteToSessionStorage } from 'views/Home/SignIn';
import { addAnotherAccount } from 'utilities/addAnotherAccount';
import { useAppDispatch } from 'hooks/redux';
import { yellow } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';
import editorExtendedApi, { useDeleteEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import LoaderWithBgBlur from 'components/utilities/Loaders/LoaderWithBgBlur';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { resetLoggedinUserSlice } from 'store/features/user/userSlice';
import routeConstants from 'constants/routeConstants';
import serverApi from 'lib/apiConfig';
import { loadEditorProfileData } from 'components/logins/onLoginSuccessUtilities';

const SitesManagment = () => {
  const cardRef = useRef<HTMLAnchorElement | null>(null);
  const { path } = useRouteMatch();
  const { cachedLoggedInUser } = useGetLoggedInUser();

  const { isError, isSuccess, isLoading, data, error, isFetching } =
    useGetLinkedAccountsQuery(undefined, {
      skip: !cachedLoggedInUser?.email,
    });
  const showAddAccount = data?.totalAccounts && data?.totalAccounts <= 5;

  useEffect(() => {
    if (isSuccess) {
      if (cardRef.current) {
        cardRef.current.focus();
      }
    }
  }, [isSuccess]);

  if (isError) {
    console.log(error);
    toast.info('Error loading linked accounts. Please try again later.');
  }

  return (
    <Grid container gap={5}>
      <SectionLoader isLoading={isLoading || isFetching} />
      {data?.linkedAccounts.map((linkedAccount: EmailLinkedAccount, i) => {
        const isCurrentLoggedInSite = linkedAccount._id === cachedLoggedInUser?._id;
        return (
          <Card
            ref={isCurrentLoggedInSite ? cardRef : null}
            key={i}
            linkedAccount={linkedAccount}
            path={path}
            isCurrentLoggedInSite={isCurrentLoggedInSite}
          />
        );
      })}
      {!showAddAccount && (
        <Card isCurrentLoggedInSite={false} linkedAccount={null} path='' />
      )}
      <Switch>
        <Route path={`${path}/:url`}>
          <SiteDetailsPopup />
        </Route>
      </Switch>
    </Grid>
  );
};

export default SitesManagment;

const getCardBackgroundColor = (
  theme: Theme,
  isCurrentLoggedInSite: boolean,
  linkedAccount: EmailLinkedAccount | null
) => {
  if (linkedAccount) {
    if (!linkedAccount.url) {
      return yellow[100];
    } else if (isCurrentLoggedInSite) {
      return theme.palette.mode === 'dark'
        ? lighten(theme.palette.background.default, 0.1)
        : '#fef5ff';
    } else {
      return theme.palette.background.default;
    }
  }
};

const getOnboardingCompleted = (linkedAccount: EmailLinkedAccount | null) => {
  if (linkedAccount) {
    if (!linkedAccount.url) {
      return false;
    } else if (linkedAccount.url) {
      return true;
    }
  } else {
    return true;
  }
};

type CardProps = {
  linkedAccount: EmailLinkedAccount | null;
  path: string;
  isCurrentLoggedInSite: boolean;
};
const Card = forwardRef(
  (
    { linkedAccount, path, isCurrentLoggedInSite }: CardProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const url = linkedAccount?.domainName ? linkedAccount.domainName : linkedAccount?.url;
    const history = useHistory();
    const dispatch = useAppDispatch();
    const hasOnboardingCompleted = getOnboardingCompleted(linkedAccount);
    const [triggerUserDelete, { isLoading }] = useDeleteEditorByIdMutation();
    const { cachedLoggedInUser } = useGetLoggedInUser();

    function getFullPath() {
      if (linkedAccount?.url) {
        return `${path}/${linkedAccount.url}`;
      } else if (!linkedAccount) {
        return `#`;
      } else {
        return '';
      }
    }

    async function handleAddSite() {
      const toastId = toast.loading('Adding another account...');

      try {
        const result = await addAnotherAccount();

        if (result.success) {
          toast.dismiss(toastId);
          toast.success('Account added successfully');
          saveRouteToSessionStorage(history); // Save the route to session storage
          history.push(routeConstants.ONBOARDING_ROUTE); // Redirect to onboarding
        } else {
          toast.dismiss(toastId);
          toast.error('Error adding account. Please try again later.');
        }
      } catch (error) {
        console.error(error);
        toast.error('Error adding account. Please try again later.');
      } finally {
        toast.dismiss(toastId);
      }
    }

    function getSecondaryHeading() {
      if (linkedAccount) {
        if (!hasOnboardingCompleted) {
          return 'You have not completed your onboarding yet. click here to complete it.';
        } else {
          return url;
        }
      } else {
        return 'Add more sites';
      }
    }

    function handleDelete(e: React.MouseEvent<HTMLButtonElement>) {
      e.stopPropagation();
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
      if (linkedAccount) {
        triggerUserDelete(linkedAccount._id);
        if (cachedLoggedInUser?._id === linkedAccount._id) {
          loginTokenUtils.removeLocalStorageLoginToken();
          dispatch(resetLoggedinUserSlice());
          history.push(routeConstants.HOME_ROUTE);
        }
      }
    }

    async function handleRedirectToOnboarding() {      
      try {
        const response = await serverApi.basicWithCredentials.get(
          `/auth/generate-login-token`,
          {
            params: {
              userId: linkedAccount?._id,
            },
          }
        );
        const { token, user } = response.data;
  
        // save it here to local storage
        loginTokenUtils.setLocalStorageLoginToken(token);
        await loadEditorProfileData(user._id);
        editorExtendedApi.util.invalidateTags(['Editor']);
        // TODO Check why it's firstly going to editor page then onboarding page
        saveRouteToSessionStorage(history);
        window.location.href = `${routeConstants.ONBOARDING_ROUTE}`;
      } catch (error) {
        console.error('Error while generating token', error);
        toast.error('Error redirecting to onboarding. Please try again later.');
      }             
    }

    function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
      if (!hasOnboardingCompleted) {
        handleRedirectToOnboarding();
      } else if (!linkedAccount) {
        handleAddSite();
      } else {
        e.stopPropagation();
      }
    }

    return (
      <Grid
        component={ButtonBase}
        onClick={handleClick}
        item
        className='site-card'
        xs={12}
        md={5}
        sx={{
          textAlign: 'left',
          borderRadius: '0',
          boxShadow: (theme) =>
            isCurrentLoggedInSite
              ? theme.shadows[3]
              : `0 0 3px ${theme.palette.text.disabled}`,
          border: (theme) =>
            isCurrentLoggedInSite
              ? `1px solid ${theme.palette.primary.main}`
              : `1px solid white`,
          outline: (theme) =>
            isCurrentLoggedInSite
              ? `5px solid ${theme.palette.primary.main}`
              : `1px solid white`,
          outlineOffset: isCurrentLoggedInSite ? '0' : 'none',
          bgcolor: (theme) =>
            getCardBackgroundColor(theme, isCurrentLoggedInSite, linkedAccount),
          cursor: 'pointer',
          transition: 'outline-offset .3s ease',
          transitionProperty: 'border, outline, outline-offset',
          willChange: 'outline-offset',
          '&:hover': {
            border: (theme) => `1px solid ${theme.palette.primary.light}`,
            outline: (theme) => `1px solid ${theme.palette.primary.light}`,
            outlineOffset: { xs: 0, md: '10px' },
          },
          '&:focus': {
            outline: (theme) => `5px solid ${theme.palette.primary.main}`,
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
            outlineOffset: { xs: 0, md: '10px' },
          },
        }}
      >
        {isLoading && <LoaderWithBgBlur />}
        <NavLink
          ref={ref}
          to={getFullPath()}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <UserSettingsSection
            primaryHeading={<SiteHeading linkedAccount={linkedAccount} />}
            secondaryHeading={getSecondaryHeading()}
          >
            <SiteDescription linkedAccount={linkedAccount} />{' '}
            {!hasOnboardingCompleted && (
              <Button
                onClick={handleDelete}
                variant='contained'
                color='error'
                endIcon={<Delete />}
                sx={{
                  margin: '10px 0 0 auto',
                  display: 'flex',
                }}
              >
                Delete
              </Button>
            )}
          </UserSettingsSection>
        </NavLink>
      </Grid>
    );
  }
);

function SiteHeading({ linkedAccount }: { linkedAccount: EmailLinkedAccount | null }) {
  const hasOnboardingCompleted = linkedAccount && !!linkedAccount?.url;
  function getHeading() {
    if (linkedAccount) {
      if (!hasOnboardingCompleted) {
        return 'Complete your onboarding';
      } else {
        return linkedAccount?.title;
      }
    } else {
      return 'Add Site';
    }
  }

  return (
    <Stack direction='row'>
      <Avatar
        sx={{ mr: 2, width: { xs: 24, md: 40 }, height: { xs: 24, md: 40 } }}
        src={linkedAccount?.img}
        component={Paper}
        elevation={1}
      />
      <Typography
        variant='h5'
        title={linkedAccount?.title || 'Add Site'}
        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      >
        {getHeading()}
      </Typography>
    </Stack>
  );
}

function SiteDescription({
  linkedAccount,
}: {
  linkedAccount: EmailLinkedAccount | null;
}) {
  const [description, setDescription] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (linkedAccount?.subTitle) {
      const aboutElement = document.createElement('div');
      aboutElement.innerHTML = linkedAccount.subTitle;

      if (aboutElement.textContent?.trim()) {
        if (aboutElement.textContent.length > 150) {
          const cleanedAboutText = aboutElement.textContent.replace(/\s+/g, ' ');
          setDescription(cleanedAboutText?.slice(0, 150) + '...');
        } else {
          setDescription(aboutElement.textContent);
        }
      } else {
        setDescription(null);
      }
    }
  }, [description, linkedAccount?.subTitle]);

  return (
    <Stack direction={'column'}>
      <PopupText>{description}</PopupText>
    </Stack>
  );
}
