import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { grey } from '@mui/material/colors';
import { ToastContainer } from 'react-toastify';
import { Button, Skeleton, Typography } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import useGuestSession from 'views/guestSession/GuestSession/hooks/useGuestSession';
import IfElse from 'components/utilities/IfElse';
import SessionDetails, {
  cancelledStatuses,
} from 'views/guestSession/GuestSession/sub-components/SessionDetails/SessionDetails';
import GuestSessionActions from 'views/guestSession/GuestSession/sub-components/GuestSessionActions/GuestSessionActions';
import GuestSessionStripePayment from 'views/guestSession/GuestSession/sub-components/GuestSessionStripePayment';
import GuestSessionMeetingDetails from 'views/guestSession/GuestSession/sub-components/GuestSessionMeetingDetails/GuestSessionMeetingDetails';
import CurvesDesign from 'components/utilities/CurvesDesign';
import useValidationContextProvider from '../ValidateGuestSession/hooks/useValidationContextProvider';
import CancelledSessionPoppup from '../CancelledSessionPoppup';

const GuestSession = () => {
  const {
    apiData: { session, isLoading, sessionError },
    isSessionPaid,
    isSessionMeetingPaid,
    sessionId,
    isPaymentWaiting,
  } = useGuestSession();
  const {
    validationState,
    isSessionCancelled,
    isSessionRejected,
    updateValidationState,
  } = useValidationContextProvider();
  const isCancelledSessionPopupOpen = isSessionCancelled || isSessionRejected;
  // console.log('isCancelledSessionPopupOpen', isCancelledSessionPopupOpen);
  useEffect(() => {
    if (session && session.status) {
      updateValidationState({ sessionCancellationStatus: session.status });
    }
  }, [session?.status]);

  // if (isValidating) return <FullWidthLoader />;
  if (sessionError?.message) {
    console.error('sessionError', sessionError);    
    throw new Error('sessionError: ', sessionError);
    // return <Typography color='error.dark' textAlign='center'>{sessionError?.message}</Typography>;
  }
  if (!session) return <></>;
  // console.log('session', session)
  const meetingDetails = session.meetingDetails;
  const hasHostProposesNewTimes = session.status === 'host-propose-new-times';
  const hasSessionBeenCancelled: boolean = cancelledStatuses.includes(session.status);
  let cardContent;

  cardContent = (
    <>
      {/*  alerts  */}
      <IfElse condition={hasHostProposesNewTimes}>
        <Box component='section' mb={2}>
          <Alert
            severity='warning'
            sx={{ alignItems: 'center', padding: { xs: '4px 10px', md: '6px 16px' } }}
            icon={
              <AccessTimeFilledIcon
                sx={{
                  fontSize: { xs: '20px', md: '22px' },
                  color: '#ed6c02',
                  display: { xs: 'none', md: 'inline-block' },
                }}
              />
            }
          >
            <Typography>
              Host has proposed new time for this session please &nbsp;
              <NavLink to={`/guest-session/select-new-time/${session._id}`}>
                <Button
                  variant='contained'
                  color='warning'
                  sx={{
                    fontSize: { xs: '14px', md: '16px' },
                    padding: { xs: '4px 10px' },
                  }}
                >
                  Select new time
                </Button>
              </NavLink>
            </Typography>
            {/* ----------------- select new time for "propose new time" session status ------------------- */}
          </Alert>
        </Box>
      </IfElse>
      {/* -------------- meeting details------------------ */}
      <Box>
        <GuestSessionMeetingDetails
          receiptUrl={session.receiptUrl}
          isSessionPaidByGuest={session.isPaid}
          isMeetingPaid={Boolean(meetingDetails.isPaid)}
          currency={session.currency}
          price={meetingDetails.price}
          desc={meetingDetails.desc}
          img={meetingDetails.img}
          included={meetingDetails.included}
          includedItemsHeading={meetingDetails.includedItemsHeading}
          title={meetingDetails.title}
          meetingHostDetails={session.meetingHostDetails}
        />
        <Box
          component='hr'
          sx={{
            my: {
              xs: '2rem',
              sm: '4rem',
            },
          }}
        />
        {/* -------------- session details (participant included) */}
        <SessionDetails
          participant={session.participant}
          status={session.status}
          guestMeetingPreparationText={session.guestMeetingPreparationText}
          startAt={session.startAt}
          endAt={session.endAt}
          timezone={session.timezone}
          hostMeetingInstructions={
            session.hostGuestMeetingDetails?.meetingExtraInstructions
          }
          onlineMeetingLink={session.hostGuestMeetingDetails?.link}
          hasHostProposesNewTimes={hasHostProposesNewTimes}
          inPersonMeetingGuestLocationDetails={
            session.inPersonMeetingGuestLocationDetails
          }
        />
      </Box>
    </>
  );

  return (
    <CurvesDesign>
      <>
        {isCancelledSessionPopupOpen && (
          <CancelledSessionPoppup
            sessionCancellationStatus={validationState.sessionCancellationStatus}
          />
        )}
        <Container
          maxWidth='md'
          sx={{
            py: {
              xs: '6rem',
              sm: '8rem',
            },
            // border: '2px solid red'
          }}
        >
          <ToastContainer />
          <Box>
            {/* -------------------------- session guest Actions ------------------------------------- */}
            <GuestSessionActions
              hasSessionBeenCancelled={hasSessionBeenCancelled}
              sessionId={sessionId}
              PaymentBtn={
                <GuestSessionStripePayment
                  isHidden={!(!isSessionPaid && isSessionMeetingPaid && isPaymentWaiting)}
                  sessionId={sessionId}
                  sessionMeetingId={session.meetingId}
                />
              }
            />
            {/* ------------------------------ session card */}
            <Box
              component='section'
              sx={{
                backgroundColor: 'common.white',
                padding: {
                  xs: '2rem 1.5rem',
                  sm: '4rem 4.2rem',
                },
                borderRadius: '10px',
                boxShadow: `0 0 4px ${grey[400]}`,
              }}
            >
              {isLoading && (
                <>
                  <Skeleton variant='circular' width={40} height={40} />
                  <Skeleton variant='rectangular' width={210} height={60} />
                  <Skeleton variant='rounded' width={210} height={60} />
                </>
              )}
              {cardContent}
            </Box>
          </Box>
        </Container>
      </>
    </CurvesDesign>
  );
};

export default GuestSession;

// # Paused code (delete with permission)
// ------------------- managing session guests (paused) (guests code) ---------------------------------------
// NOTE: to be added after session details
// <Box className='mail-list'>
//   {sessionMeeting?.type.toLowerCase().includes('group') && (
//     <h3>Edit your guests</h3>
//   )}
//   {!!session.guests?.length && (
//     <Box className='EditGuests'>
//       {session.guests.map((mail) => (
//         <Box className='SeeionStatusSec'>
//           <Box className='guest' key={mail}>
//             <li>{mail}</li>
//             <button onClick={() => removeMail(mail)}>x</button>
//           </Box>
//         </Box>
//       ))}
//     </Box>
//   )}
// </Box>

// ---------------------------------------------- adding session guests emails (guests code) ----------------------------------------
// NOTE: to be added if session has not been cancelled
// {sessionMeeting?.type.toLowerCase().includes('group') && (
//   <Box className='add-guest'>
//     <input
//       type='text'
//       placeholder='Guest email'
//       value={mailToAdd}
//       onChange={(ev) => setMailToAdd(ev.target.value)}
//     />
//     <button onClick={() => addMail()}>Add guest</button>
//   </Box>
// )}
