import { Avatar, Button, ButtonProps } from '@mui/material';
import { PopupSectionHeading, PopupText } from 'components/dashboard/DashboardReusableComponent';

type ButtonPortfolioProps = ButtonProps & { src: string; selected?: boolean };

export function DrawerSectionHeading({ children }: { children: React.ReactNode }) {
  return (
    <PopupSectionHeading
      sx={{ fontSize: { xs: '1.2rem', md: '1.3rem' }, color: '#000000' }}
    >
      {children}
    </PopupSectionHeading>
  );
}

export function DrawerSecondaryHeading({ children }: { children: React.ReactNode }) {
  return (
    <PopupText sx={{ fontSize: { xs: '1rem', md: '1.1rem' } }}>{children}</PopupText>
  );
}

export function DrawerButton({ src, selected, sx, ...otherProps }: ButtonPortfolioProps) {
  return (
    <Button
      {...otherProps}
      sx={{
        minWidth: 0,
        px: 0,
        py: 0.25,
        border: (theme) =>
          selected ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent',
        ...sx,
      }}
      variant='text'
    >
      <Avatar
        sx={{
          borderRadius: 1,
          width: 35,
          height: 25,
          '& img': {
            objectFit: 'contain',
          },
        }}
        variant='square'
        src={src}
      />
    </Button>
  );
}
