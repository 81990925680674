import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IfElse from 'components/utilities/IfElse';
import { SxProps } from '@mui/material';

type ThisProps = {
  children: React.ReactNode;
  currentStep: number;
  helperText?: string;
  required?: boolean;
  mb?: string;
  sx?: SxProps
};

const Question = ({
  children,
  currentStep,
  helperText = '',
  mb = '0',
  sx={},
  required = false,
}: ThisProps) => {
  return (
    <Box position='relative' mb={mb} sx={sx}>
      <Typography
        variant='h6'
        component='h6'
        fontWeight={600}
        textAlign={{ xs: 'center', sm: 'center', md: 'start' }}
        sx={{
          lineHeight: 1,
        }}
      >
        {children}
        <IfElse condition={required}>
          <Typography component='sup' color='error.main'>*</Typography>
        </IfElse>
      </Typography>
      <IfElse condition={Boolean(helperText)}>
        <Typography mt={{xs: 0, md: 1}} variant='body1' component={'p'} fontWeight={400} textAlign={{ xs: 'center', sm: 'center', md: 'start' }}>{helperText}</Typography>
      </IfElse>
    </Box>
  );
};

export default Question;