import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import { SortedSpm } from 'types/sorted-spm';

const spmSportedExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSortedSPMByEditorId: build.query<SortedSpm, string>({
      query: (editorId) => `/sorted-spm-ids/user/${editorId}`,
      providesTags: ['SortedSPMIds'],
    }),
    updateSPMSortedByEditorId: build.mutation<
      void,
      {
        editorId: string;
        sortedSpm: Partial<SortedSpm>;
      }
    >({
      query: ({ editorId, sortedSpm }) => ({
        url: `/sorted-spm-ids/user/${editorId}`,
        method: 'PUT',
        body: sortedSpm,
      }),
      invalidatesTags: ['SortedSPMIds'],
    }),
  }),
});

export default spmSportedExtendedApi;

export const {
  useGetSortedSPMByEditorIdQuery,
  useUpdateSPMSortedByEditorIdMutation,
} = spmSportedExtendedApi;
