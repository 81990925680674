import { getTimezoneOffset } from 'date-fns-tz';
import { useAppSelector } from 'hooks/redux';
import moment from 'moment';
import { useState, useEffect, useReducer } from 'react';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { RepeatebleTentativeHours, TentativeTimes } from 'types/meeting';
import TentativeAvailabilityReducer, {
  intialTentativeAvailabilityStates,
} from '../TentativeAvailabilityReducers';
import {
  DaysObjectInterface,
  FormInputProps,
  NonRepeatableLists,
  TentativeHoursToBeDeleted,
} from '../TentativeAvailabilityTypes';
import {
  initialDays,
} from '../TentativeUtility';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';
import timeWeekdayReducer, { intialTimeWeekdayState } from '../util/timeWeekdayReducer';
import { ITimezoneOption } from 'react-timezone-select';
import { LoggedInUser } from 'types/user';
import { getHours, getMinutes, isBefore, isSameDay } from 'date-fns';
import { useEffectOnce } from 'usehooks-ts';

type UseTentativeAvailabilityProps =
  Omit<FormInputProps, 'value'> &
  {
    isFromProposeNewTime?: boolean,
    bookingUpTo?: number,
    timezone?: Pick<ITimezoneOption,
      "label" | "value">, loggedInUserFromProposeNewTime?: LoggedInUser
  };
export const TOTAL_SECONDS_IN_WEEK = 604800;
const TOTAL_SECONDS_IN_DAY = 86400;
const TOTAL_MILLISECONDS_IN_DAY = 86400000;

export function addTime(previousTimes: TentativeTimes[], startSeconds: number, endSeconds: number) {
  let handled = false;
  // if (startSeconds == endSeconds) {
  //   return previousTimes;
  // }
  // this is a problem because if we are booking time from (saturday <= 86400) to (sunday >= 0), it will not work so I had to make a different case to handle this    
  if (startSeconds > endSeconds) {
    // swap
    const temp = startSeconds;
    startSeconds = endSeconds;
    endSeconds = temp;
  }
  for (let i = 0; i < previousTimes.length; i++) {
    if (startSeconds >= previousTimes[i].from && endSeconds <= previousTimes[i].to) {
      // time is within
      handled = true;
      break;
    }
    if (startSeconds <= previousTimes[i].to && endSeconds >= previousTimes[i].from) {
      // time is overlapping
      for (let j = i; j < previousTimes.length; j++) {
        if (j < previousTimes.length - 1 && endSeconds < previousTimes[j + 1].from) {
          if (endSeconds < previousTimes[j].to) {
            endSeconds = previousTimes[j].to;
          }
          if (startSeconds > previousTimes[i].from) {
            startSeconds = previousTimes[i].from;
          }
          previousTimes.splice(i, j + 1 - i, { from: startSeconds, to: endSeconds });
          handled = true;
          break;
        }
        if (j == previousTimes.length - 1 && endSeconds >= previousTimes[j].from) {
          if (endSeconds < previousTimes[j].to) {
            endSeconds = previousTimes[j].to;
          }
          if (startSeconds > previousTimes[i].from) {
            startSeconds = previousTimes[i].from;
          }
          previousTimes.splice(i, j + 1 - i, { from: startSeconds, to: endSeconds });
          handled = true;
          break;
        }
      }
      if (!handled) {
        console.error('Error in addTime function', previousTimes, startSeconds, endSeconds);
      }
      break;
    }
  }
  if (!handled) {
    for (let i = 0; i < previousTimes.length; i++) {
      if (endSeconds < previousTimes[i].from) {
        // time is before
        previousTimes.splice(i, 0, { from: startSeconds, to: endSeconds });
        handled = true;
        break;
      }
    }
  }
  if (!handled) {
    // time is last
    previousTimes.push({ from: startSeconds, to: endSeconds });
  }

  return previousTimes;
};

const useTentativeAvailability = ({
  onChange,
  onDelete,
  repeatableAvailability,
  nonRepeatableAvailability,
  meetingId,
  isFromProposeNewTime,
  timezone,
  bookingUpTo,
  meetingDuration,
  handleBookingUpto,
}: UseTentativeAvailabilityProps) => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: loggedInUser } = useGetEditorByIdQuery(editorId, {
    skip: isFromProposeNewTime && !editorId,
  }); //  user from cache
  const loggedInUserTimeZone = isFromProposeNewTime && timezone ? timezone.value : loggedInUser?.timeZone ?? '';
  // const meeting = useAppSelector(selectMeetingsModule).meetings.find(
  //   (eachMeeting) => eachMeeting._id === meetingId
  // );
  const [tentativeAvailabilityState, dispatch] = useReducer(TentativeAvailabilityReducer, intialTentativeAvailabilityStates);
  const [timeWeekdayState, timeWeekdayDispatch] = useReducer(timeWeekdayReducer, intialTimeWeekdayState)
  const { isWeekDayClick, addAvailability, count, repeatWeekly } = tentativeAvailabilityState;
  const { clickedWeekDays, from, to } = timeWeekdayState;

  const [repeatableLists, setRepeatableLists] = useState<RepeatebleTentativeHours[] | []>(repeatableAvailability ?? []);
  const [nonRepeatableLists, setNonRepeatableLists] = useState<NonRepeatableLists>(nonRepeatableAvailability ?? []);
  const [alertDialogBoxDeleteState, setalertDialogBoxDeleteState] = useState({ isOpen: false, });
  const [alertDialogBoxState, setAlertDialogBoxState] = useState<{ isOpen: boolean, message?: string }>({ isOpen: false, message: '' });

  const [key, setKey] = useState<TentativeHoursToBeDeleted>();
  const fromTimeString = `${from.hour}:${from.min}`;
  const toTimeString = `${to.hour}:${to.min}`;
  const days: DaysObjectInterface = initialDays;

  useEffectOnce(() => {
    setRepeatableLists(repeatableAvailability ?? []);
    setNonRepeatableLists(nonRepeatableAvailability ?? []);
    //  set booking up to and availability
    timeWeekdayDispatch({ type: 'removeAllWeekDay', payload: [] });
    // meeting.bookingUpto is for proposed new time.
    // bookingUpTo is for tentative availability for edit and create meeting.
    dispatch({
      type: "updateAddAvailabilityAndBookingUpto", payload: {
        addAvailability: isFromProposeNewTime ? true : false,
        bookingUpTo: bookingUpTo || 0
      }
    })
  })

  useEffect(() => {
    handleBookingUpto(tentativeAvailabilityState.bookingUpTo);
  }, [tentativeAvailabilityState]);

  
  function combineTimeSlotToDay() {
    const timezoneOffsetInMs = getTimezoneOffset(loggedInUserTimeZone); // in milliseconds
    const timezoneOffsetInSeconds = getTimezoneOffset(loggedInUserTimeZone) / 1000; // in seconds
    let previousTimes: RepeatebleTentativeHours[] = [...repeatableLists, ...nonRepeatableLists];
    let localRepetableList = [...repeatableLists];
    let localNonRepetableList = [...nonRepeatableLists];

    clickedWeekDays.forEach((day) => {
      if (repeatWeekly && typeof day === 'number') {
        const startTimeInSeconds = +from.hour * 3600 + +from.min * 60;
        let startTimeWithDayInSecondsInUTC = (startTimeInSeconds - timezoneOffsetInSeconds) + (day * TOTAL_SECONDS_IN_DAY); // In UTC
        
        if (Math.sign(startTimeWithDayInSecondsInUTC) === -1) {
          startTimeWithDayInSecondsInUTC = TOTAL_SECONDS_IN_WEEK - Math.abs(startTimeWithDayInSecondsInUTC);
        }
        let endTimeInSeconds = +to.hour * 3600 + +to.min * 60;

        // Adding the next day to end time if the selected time is 12:00 am;
        if (+to.hour === 0 && +to.min === 0) endTimeInSeconds = endTimeInSeconds + TOTAL_SECONDS_IN_DAY;
        let endTimeWithDayInSecondsInUTC = (endTimeInSeconds - timezoneOffsetInSeconds) + (day * TOTAL_SECONDS_IN_DAY); // In UTC
        if (Math.sign(endTimeWithDayInSecondsInUTC) === -1) {
          // If the end time is fallingback to previous day which can be sunday to saturday so handling case for that case.          
          endTimeWithDayInSecondsInUTC = TOTAL_SECONDS_IN_WEEK - Math.abs(endTimeWithDayInSecondsInUTC);
        }
        if (startTimeWithDayInSecondsInUTC > endTimeWithDayInSecondsInUTC) {
          if(Math.abs(startTimeWithDayInSecondsInUTC - endTimeWithDayInSecondsInUTC) > 86400 * 5) {
            if(endTimeWithDayInSecondsInUTC > startTimeWithDayInSecondsInUTC) {
              let temp = endTimeWithDayInSecondsInUTC;
              endTimeWithDayInSecondsInUTC = startTimeWithDayInSecondsInUTC;
              startTimeWithDayInSecondsInUTC = temp;
            }
            previousTimes = addTime(previousTimes, 0, endTimeWithDayInSecondsInUTC);
            previousTimes = addTime(previousTimes, startTimeWithDayInSecondsInUTC, TOTAL_SECONDS_IN_WEEK);
          }
          // 588600 -> 3600
          // 1. 588600 -> 604800 
          // 2. 0 -> 3600
        } else {
          previousTimes = addTime(previousTimes, startTimeWithDayInSecondsInUTC, endTimeWithDayInSecondsInUTC);
        }
      } else {
        // let fromDate: number = new Date(day + ' ' + fromTimeString).valueOf(); //
        const selectedFromTimeWithDay = moment(day + ' ' + fromTimeString);
        const utcOffset = selectedFromTimeWithDay.utcOffset();
        let fromDate = selectedFromTimeWithDay.utc().add(utcOffset, 'm').valueOf();

        const selectedToTimeWithDay = moment(day + ' ' + toTimeString);
        let toDate: number = selectedToTimeWithDay.utc().add(utcOffset, 'm').valueOf();
        // Checking if the from and to time is same then adding next date to toDate
        if (
          (isSameDay(fromDate, toDate) && getHours(fromDate) === getHours(toDate) && getMinutes(fromDate) === getMinutes(toDate)) ||
          isBefore(toDate, fromDate)
        ) {
          toDate = toDate + TOTAL_MILLISECONDS_IN_DAY;
        }
        // convert to utc
        // utc = localTime - (-/+ offset)
        const fromDateInUTC = fromDate - timezoneOffsetInMs;
        const toDateInUTC = toDate - timezoneOffsetInMs;
        previousTimes = addTime(previousTimes, fromDateInUTC, toDateInUTC);
      }
    })
    const currentTimeInUTC = moment(new Date()).utc().valueOf();
    localRepetableList = previousTimes.filter((each) => each.from < 10000000);
    localNonRepetableList = previousTimes.filter((each) => each.to >= currentTimeInUTC - 24 * 60 * 60 * 1000);

    setNonRepeatableLists(localNonRepetableList);
    setRepeatableLists(localRepetableList);
    return { localRepetableList, localNonRepetableList };
  };

  const onDeleteIconClick = (
    slot: RepeatebleTentativeHours,
    keyIndex: number,
    repeat: boolean
  ) => {
    setKey({ slot, keyIndex, repeat });
    setalertDialogBoxDeleteState((state) => ({
      ...state,
      isOpen: true,
    }));
  };

  const handleDeleteAvailability = () => {
    let repeatableListsClone = [...repeatableLists];
    let nonRepeatableListsClone = [...nonRepeatableLists];
    if (key) {
      if (key.repeat) {
        // delete repeatable tentative hours
        repeatableListsClone.splice(key.keyIndex, 1);
        setRepeatableLists(repeatableListsClone);
        // setRepeatableLists((prevState) => prevState.filter(each => JSON.stringify(key.slot) !== JSON.stringify(each)))
      } else {
        // delete non repeatable tentative hours
        nonRepeatableListsClone.splice(key.keyIndex, 1);
        setNonRepeatableLists(nonRepeatableListsClone);
        // setNonRepeatableLists((prevState) => prevState.filter(each => JSON.stringify(key.slot) !== JSON.stringify(each)))
      }

      onDelete(repeatableListsClone, nonRepeatableListsClone);
    }
    setalertDialogBoxDeleteState((state) => ({
      ...state,
      isOpen: false,
    }));
  };

  const handleDateIncrement = () => {
    dispatch({ type: 'updateCount', payload: count + 1 });
  };

  const handleDateDecrement = () => {
    dispatch({ type: 'updateCount', payload: count - 1 });
  };

  const handleAddAvailability = () => {
    const { localNonRepetableList, localRepetableList } = combineTimeSlotToDay();    
    onChange(localRepetableList, localNonRepetableList);

    // --- RESETING ---
    dispatch({ type: 'updateAddAvailability', payload: false });
    // set from
    timeWeekdayDispatch({ type: 'updateFrom', payload: intialTimeWeekdayState.from });
    // set to
    timeWeekdayDispatch({ type: 'updateTo', payload: intialTimeWeekdayState.to });
    timeWeekdayDispatch({ type: 'removeAllWeekDay', payload: [] });

  };

  const handleOpenAvailibility = () => {
    dispatch({ type: 'updateAddAvailability', payload: true });
  };

  const handleRepeatWeeklyChange = () => {
    dispatch({ type: 'updateRepeatWeekly', payload: !repeatWeekly });
    timeWeekdayDispatch({ type: 'removeAllWeekDay', payload: [] })
  };

  const handleWeekDayClick = (day: string | number, isReleased: 'true' | 'false') => {
    timeWeekdayDispatch({ type: 'updateIsReleased', payload: isReleased })
    timeWeekdayDispatch({ type: 'updateCurrentDay', payload: day })

    if (isReleased === 'false') { // add the weekday
      timeWeekdayDispatch({ type: 'updateClickedWeekDays', payload: day })
    }

    if (isReleased === 'true') { // remove the weekday
      timeWeekdayDispatch({ type: 'removeWeekDay', payload: day })
    }
  };

  return {
    onDeleteIconClick,
    handleRepeatWeeklyChange,
    handleAddAvailability,
    handleOpenAvailibility,
    setalertDialogBoxDeleteState,
    handleDeleteAvailability,
    handleDateDecrement,
    handleWeekDayClick,
    handleDateIncrement,
    dispatch,
    timeWeekdayDispatch,
    setNonRepeatableLists,
    setRepeatableLists,
    setAlertDialogBoxState,
    timeWeekdayState,
    repeatableLists,
    nonRepeatableLists,
    addAvailability,
    repeatWeekly,
    alertDialogBoxDeleteState,
    days,
    count,
    isWeekDayClick,
    tentativeAvailabilityState,
    currentTimeZone: loggedInUserTimeZone,
    loggedInUser,
    alertDialogBoxState,
  };
};

export default useTentativeAvailability;