import { MeetingTypes } from './meeting';
import { ServiceTypes } from './service';

export enum StoreItemTypes {
  MeetingVirtual = MeetingTypes.Virtual,
  MeetingPhysical = MeetingTypes.Physical,
  ServiceVirtual = ServiceTypes.Virtual,
  ServicePhysical = ServiceTypes.Physical,
}

