import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Paper,
  Stack,
  useMediaQuery,
  Link as MuiLink,
  Box,
  Switch,
  FormControlLabel,
  SxProps,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';

import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import BarChartIcon from '@mui/icons-material/BarChart';
import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import ShareIcon from '@mui/icons-material/Share';
import PublicIcon from '@mui/icons-material/Public';

import Popup from 'components/utilities/Popups/Popup';
import { attempt_login_with_existing_token } from 'lib/services/authServices';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import TimeZoneSelectSettings from 'components/dashboard/TimeZoneSelectSettings';
import PremiumSubscriptionPopup from 'components/utilities/Popups/PremiumSubscriptionPopup';
import ShareLink from 'components/utilities/ShareLink';
import ResponsiveButton, {
  responsive_button_icon,
} from 'components/Buttons/ResponsiveButton';
import DeleteUserAccountSettings from 'components/dashboard/DeleteSite/DeleteSite';
import CopyBtn from 'components/utilities/CopyBtn';
import SiteContextProvider, { SiteContext } from './SiteContext';
import {
  IconTextPair,
  PopupHeading,
  PopupSectionHeading,
  PopupText,
  dashboard_popup_icon_size,
} from 'components/dashboard/DashboardReusableComponent';

import getNodeEnv from 'utilities/getNodeEnv';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  EmailLinkedAccount,
  useGetLinkedAccountsQuery,
} from 'store/rtkqFeatures/api/linkedAccountsExtendedApi';
import { usePatchUpdateEditorFieldByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import routeConstants from 'constants/routeConstants';
import { LoggedInUser } from 'types/user';

const hightlightAnimation: SxProps = {
  position: 'relative',  
  backgroundColor: 'yellow',
  animation: 'highlight .5s 3',
  '@keyframes highlight': {
    '0%': {
      backgroundColor: 'yellow',
    },
    '50%': {
      backgroundColor: 'white',
    },
    '100%': {
      backgroundColor: 'yellow',
    },
  },
};

const SiteDetailsPopup = () => {
  const [site, setSite] = useState<EmailLinkedAccount>();
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams<Pick<LoggedInUser, 'url'>>();
  const location = useLocation();
  const queryParams = location.search;
  const hightlight = new URLSearchParams(queryParams).get('highlight');

  const { cachedLoggedInUser } = useGetLoggedInUser();
  const history = useHistory();
  const currentLoggedInSite = site?._id === cachedLoggedInUser?._id;

  const { data } = useGetLinkedAccountsQuery(undefined, {
    skip: !cachedLoggedInUser?.email,
  });

  useEffect(() => {
    const selectedSite = data?.linkedAccounts.find(
      (linkedAccount) => linkedAccount.url === params.url
    );
    if (selectedSite) {
      setSite(selectedSite);
    }
  }, [params.url, data, history]);

  if (!site) return null;

  return (
    <SiteContextProvider
      popupOpen={true}
      setPopupOpen={() => {}}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
    >
      <Popup
        onClose={() => history.goBack()}
        dialogProps={{
          fullWidth: false,
          open: true,
          id: site._id,
          PaperProps: {
            sx: {
              overflow: 'auto',
              margin: 2,
              minWidth: { xs: '90%', md: '600px' },
            },
          },
        }}
        setOpen={() => {}}
        showCloseIcon
      >
        <Stack
          direction={'column'}
          sx={{
            width: { xs: '100%', md: '100%' },
            pb: { xs: 8, md: 4 },
            position: 'relative',
            pointerEvents: isLoading ? 'none' : 'auto',
            userSelect: isLoading ? 'none' : 'auto',
            '& > div': {
              p: { xs: 2, md: 4 },
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            },
            '& > div:first-child': {
              borderTop: 'none',
            },
            '& > div:last-child': {
              borderBottom: 'none',
            },
          }}
        >
          {isLoading && <SectionLoader isLoading={isLoading} />}

          <SiteDetailsPopupHeadingAndActions
            site={site}
            currentLoggedInSite={currentLoggedInSite}
          />

          <SiteDetailsPopupVisits
            totalVisits={site.loadCount}
            isPremium={site.isPremium}
          />

          <SiteDetailsPopupSearchable
            currentLoggedInSite={currentLoggedInSite}
            isSearchable={site?.searchable}
            loggedInUserId={cachedLoggedInUser?._id || site._id}
          />

          <SiteDetailsPopupTimezone
            sx={hightlight === 'timezone' ? hightlightAnimation : {}}
            timezone={site.timeZone}
            currentLoggedInSite={currentLoggedInSite}
          />

          {currentLoggedInSite && <SiteDetailsPopupDelete />}
        </Stack>
      </Popup>
    </SiteContextProvider>
  );
};

export default SiteDetailsPopup;

function SiteDetailsPopupHeadingAndActions({
  site,
  currentLoggedInSite,
}: {
  site: EmailLinkedAccount;
  currentLoggedInSite: boolean;
}) {
  const url = site?.domainName ? site.domainName : site?.url;

    function getFullPath() {
      if(site?.domainName) {
        return `https://${site.domainName}`
      } 
      if (site?.url) {
        return `${routeConstants.LIVE_ROUTE}/${site.url}`
      } else if(!site) {
        return `/sign-up`
      } else {
        return ''
      }
    } 

  return (
    <Stack direction={'column'} spacing={3}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '45px 1fr', gap: 1 }}>
        <Avatar src={site.img} component={Paper} elevation={2} />
        <Stack sx={{ maxWidth: { xs: '100%', md: '100%' } }}>
          <PopupHeading>{site.title}</PopupHeading>
          <PopupText fontWeight={600}>
            {site.name}
            <PopupText fontSize={'0.9em'}>
              <MuiLink
                href={getFullPath()}
                underline='hover'
                color={'inherit'}
              >
                {url}
              </MuiLink>
              <CopyBtn
                copyContent={getFullPath()}
                sxButton={{ py: 0 }}
                sx={{
                  fontSize: 'inherit',
                  verticalAlign: 'middle',
                  ...responsive_button_icon,
                }}
              />
              {/* <Link to={'/domain'}>
                <Button color='primary' variant='contained' size='small'>
                  domain
                </Button>
              </Link> */}
            </PopupText>
          </PopupText>
        </Stack>
      </Box>
      <SiteDetailsPopupActions site={site} currentLoggedInSite={currentLoggedInSite} />
    </Stack>
  );
}

function SiteDetailsPopupTimezone({
  currentLoggedInSite,
  timezone,
  sx,
}: {
  currentLoggedInSite: boolean;
  timezone: string;
  sx: SxProps;
}) {
  return (
    <Stack direction={'column'} spacing={1} sx={sx}>
      <PopupSectionHeading>Timezone</PopupSectionHeading>

      <Stack direction={'column'} spacing={1}>
        <IconTextPair
          icon={<PublicIcon sx={{ ...dashboard_popup_icon_size }} />}
          text={currentLoggedInSite ? undefined : timezone}
        >
          {currentLoggedInSite && <TimeZoneSelectSettings />}
        </IconTextPair>
      </Stack>
    </Stack>
  );
}

function SiteDetailsPopupSearchable({
  isSearchable,
  currentLoggedInSite,
  loggedInUserId,
}: {
  currentLoggedInSite: boolean;
  isSearchable?: boolean;
  loggedInUserId: string;
}) {
  const isPhone = useMediaQuery('(max-width:600px)');
  const [checked, setChecked] = useState(!!isSearchable);

  const [updateSearchable, { isLoading }] = usePatchUpdateEditorFieldByIdMutation();

  useEffect(() => {
    setChecked(!!isSearchable);
  }, [isSearchable]);

  const handleSwitchChangeLocallyDebounced = debounce(async (checked: boolean) => {
    if (loggedInUserId) {
      await updateSearchable({
        editorId: loggedInUserId,
        searchable: checked,
      });
    }
  }, 300);

  const handleSwitchChangeLocally = (checked: boolean) => {
    setChecked(checked);
    handleSwitchChangeLocallyDebounced(checked);
  };

  function LabelColor() {
    return (
      <PopupText
        bgcolor={checked ? 'primary.dark' : 'text.disabled'}
        color={checked ? 'primary.contrastText' : 'secondary.contrastText'}
        component={'span'}
        px={1}
        ml={currentLoggedInSite ? 0 : 1}
      >
        {checked ? 'ON' : 'OFF'}
      </PopupText>
    );
  }

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack direction={'column'} spacing={1}>
        <PopupSectionHeading>
          Searchable in{' '}
          <MuiLink
            href={routeConstants.LIVE_ROUTE}
            target='_blank'
            underline='always'
            color='inherit'
          >
            fronts.ai
          </MuiLink>
        </PopupSectionHeading>
        <Box>
          <IconTextPair
            icon={
              <PersonSearchIcon
                sx={{ ...dashboard_popup_icon_size, color: 'currentcolor' }}
              />
            }
            boxSx={{
              alignItems: 'center',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            <FormControlLabel
              control={
                currentLoggedInSite ? (
                  <Switch
                    checked={checked}
                    onChange={(_event, checked) => handleSwitchChangeLocally(checked)}
                    color='primary'
                    size={isPhone ? 'small' : 'medium'}
                  />
                ) : (
                  <></>
                )
              }
              label={<LabelColor />}
            />
          </IconTextPair>
        </Box>
      </Stack>
    </>
  );
}

function SiteDetailsPopupVisits({
  totalVisits,
  isPremium,
}: {
  totalVisits: EmailLinkedAccount['loadCount'];
  isPremium?: boolean;
}) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Stack direction={'column'} spacing={1}>
      <PopupSectionHeading>Total Visits</PopupSectionHeading>

      <Stack direction={'row'} spacing={3} alignItems={'center'}>
        <IconTextPair
          boxSx={{
            filter: isPremium ? 'none' : 'blur(3px)',
            color: (theme) => theme.palette.text.secondary,
          }}
          icon={
            <BarChartIcon sx={{ ...dashboard_popup_icon_size, color: 'currentcolor' }} />
          }
          text={isPremium && totalVisits ? totalVisits?.toString() : '000'}
        />
        {!isPremium && (
          <ResponsiveButton color='secondary' onClick={handleClick}>
            View visits
          </ResponsiveButton>
        )}
      </Stack>
      <PremiumSubscriptionPopup
        title={'Upgrade to premium to view analytics'}
        open={open}
        setOpen={setOpen}
      />
    </Stack>
  );
}

function SiteDetailsPopupDelete() {
  return (
    <Stack direction={'column'} spacing={1}>
      <PopupSectionHeading color='error.main'>Delete Site</PopupSectionHeading>

      <Stack direction={'column'} spacing={2}>
        <IconTextPair
          icon={<DeleteIcon sx={dashboard_popup_icon_size} />}
          text='Deleting profile is irreversible, permanently removing all data and settings.'
        />
        <DeleteUserAccountSettings
          sx={{
            alignSelf: 'flex-start',
          }}
        />
      </Stack>
    </Stack>
  );
}

function SiteDetailsPopupActions({
  site,
  currentLoggedInSite,
}: {
  site: EmailLinkedAccount;
  currentLoggedInSite?: boolean;
}) {
  const isNodeEnvProduction = getNodeEnv();
  const history = useHistory();
  const context = useContext(SiteContext);

  const handleLoginClick = async ({
    email,
    userId,
    url,
  }: Record<'email' | 'userId' | 'url', string>) => {
    try {
      context.setIsLoading(true);
      const timerId = toast.loading('Logging in...');
      await attempt_login_with_existing_token({ email, userId });
      toast.dismiss(timerId);
      history.replace({ pathname: `/dashboard/sites/${url}` });
    } catch (error) {
      history.replace(routeConstants.HOME_ROUTE);
      const ERROR_MSG = 'Error while logging in';
      toast.error(ERROR_MSG);
      !isNodeEnvProduction && console.error(`${ERROR_MSG} | error: `, error);
    } finally {
      context.setIsLoading(false);
    }
  };

  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      justifyContent='flex-start'
      spacing={2}
      alignItems={'center'}
      useFlexGap
    >
      {currentLoggedInSite ? (
        <Link to={`/${site.url}`}>
          <ResponsiveButton
            color='primary'
            variant='text'
            LinkComponent={'a'}
            endIcon={<EditIcon sx={responsive_button_icon} />}
          >
            Edit
          </ResponsiveButton>
        </Link>
      ) : (
        <ResponsiveButton
          color='primary'
          variant='text'
          endIcon={<LoginIcon sx={responsive_button_icon} />}
          onClick={() =>
            handleLoginClick({
              email: site.email,
              userId: site._id,
              url: site.url,
            })
          }
        >
          Login
        </ResponsiveButton>
      )}
      <ShareLink
        link={`${routeConstants.LIVE_ROUTE_PRODUCTION}/${site.url}`}
        buttonProps={{
          endIcon: <ShareIcon sx={responsive_button_icon} />,
          variant: 'text',
          color: 'secondary',
        }}
      >
        Share
      </ShareLink>
      <ResponsiveButton
        LinkComponent={'a'}
        target={'_blank'}
        href={`${routeConstants.LIVE_ROUTE}/${site.url}`}
        endIcon={<OpenInNewIcon sx={responsive_button_icon} />}
        color='secondary'
        variant='text'
      >
        Live Site
      </ResponsiveButton>
    </Stack>
  );
}
