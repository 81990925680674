import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export type ConnectedAccountModule = any;

// !! DON'T USE THIS SLICE | MOVED TO RTK Query
const INITIAL_STATE: ConnectedAccountModule = {
  _id: '',
  userId: '',
  isOnboardingProcessCompleted: false,
  type: 'standard',
  isDeleted: false,
};

const connectedAccountSlice = createSlice({
  name: 'connectedAccountModule',
  initialState: INITIAL_STATE,
  reducers: {
    setConnectedAccount: (
      state: ConnectedAccountModule,
      action: PayloadAction<ConnectedAccountModule>
    ) => {
      return action.payload;
    },
    resetConnectedAccountSlice: () => {
      return INITIAL_STATE;
    },
  },
});

const connectedAccountSliceReducer = connectedAccountSlice.reducer;
export default connectedAccountSliceReducer;

export const { setConnectedAccount, resetConnectedAccountSlice } =
  connectedAccountSlice.actions;

// slice selectors
export const selectConnectedAccountMoudle = (state: RootState) => {
  return state.persistedReducer.connectedAccountModule;
};

export const selectConnectedAccount = (state: RootState) => {
  const connectedAccountMoudle = selectConnectedAccountMoudle(state);
  return connectedAccountMoudle;
};

export const selectConnectedAccountId = (state: RootState) => {
  const connectedAcc = selectConnectedAccountMoudle(state);
  return connectedAcc._id;
};
