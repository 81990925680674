import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, DialogActions, Stack, Typography } from '@mui/material';

import { useAppSelector } from 'hooks/redux';
import editorExtendedApi, {
  useGetEditorByIdQuery,
  useUpdatedEditorByIdMutation,
} from 'store/rtkqFeatures/api/editorExtendedApi';

import { selectLoggedInUserId } from 'store/features/user/userSlice';
import Popup from 'components/utilities/Popups/Popup';
import { storeDispatch } from 'store/store';

const description = (
  <Typography variant='body1' textAlign='center'>
    You no longer need to hassle with DNS settings and name servers. Simplify your
    domain integration with just one click.
  </Typography>
);

const DomainFeatureShowPopup = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const [updateEditor] = useUpdatedEditorByIdMutation();
  const { data: loggedInUser, isFetching } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  }); //  user from cache
  const shouldShowDomainNewFeature = loggedInUser?.shouldShowNewDomainFeature;
  const showPopup = !isFetching && (shouldShowDomainNewFeature === undefined || shouldShowDomainNewFeature === true);

  const handleCloseClick = async () => {
    if (!loggedInUser) {
      toast.error('Unable to close the popup because loggedinuser is undefined');
      return;
    }
    // Optimistically update the local state
    storeDispatch(
      editorExtendedApi.util.updateQueryData('getEditorById', editorId, (draft) => {
        draft.shouldShowNewDomainFeature = false;
      })
    );

    updateEditor({
      editorId: loggedInUser._id,
      loggedInUser: {
        shouldShowNewDomainFeature: false,
      },
    })
      .unwrap()
      .catch((error) => {
        // If the mutation fails, revert the optimistic update
        storeDispatch(
          editorExtendedApi.util.updateQueryData('getEditorById', editorId, (draft) => {
            draft.shouldShowNewDomainFeature = true;
          })
        );
        console.log(
          'error updating user to make shouldShowNewDomainFeature false',
          error
        );
      });
  };
  
  return (
    <Popup
      setOpen={handleCloseClick}
      dialogProps={{
        fullScreen: false,
        open: showPopup,
        keepMounted: false,
        fullWidth: true,
      }}
      title={<>Get the {loggedInUser?.pendingDomain ?? 'Domain'}</>}
      titleProps={{
        textAlign: 'center',
        component: 'h4',
        id: 'alert-dialog-title',
        fontSize: { xs: 20, md: 25 },
        fontWeight: 600,
      }}
      content={description}
    >
      <DialogActions sx={{ mt: '1.5rem' }}>
        <Stack direction='row' spacing={3} pr={3} pb={0.5}>
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              padding: '0.5em 1em',
              fontSize: '1.6rem',
            }}
            className='confirm-service__cancelBtn'
            onClick={handleCloseClick}
          >
            Close
          </Button>
          <Link to='/domains'>
            <Button
              color='primary'
              variant='contained'
              onClick={handleCloseClick}
              sx={{
                fontSize: '1.6rem',
              }}
              className='confirm-service__confirmBtn theme-btn--filled user-interaction-btn'
            >
              Get domain
            </Button>
          </Link>
        </Stack>
      </DialogActions>
    </Popup>
  );
};

export default DomainFeatureShowPopup;
