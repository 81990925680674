import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import EditBtn from 'components/utilities/edit-btn/EditBtn';
import { TinymceFormatPreserver } from 'components/utilities/TinyMceEditor/TinymceRichText';
import LayoutContainer from 'components/Containers/LayoutContainer';
import { style_ProfileCard_subtitle_container } from 'components/ProfileCard/ProfileCard.style';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';

type ThisProps = {
  subTitleStyle: any;
  subTitle: string;
  paddingBottom: number;
  paddingTop: number;
};

const ProfileSubtitle = ({
  subTitle,
  subTitleStyle,
  paddingBottom,
  paddingTop,
}: ThisProps) => {
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { currentTheme } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      currentTheme: themes?.[localCurrentThemeIndex] || initialThemeState,
    }),
  });

  const { background, backgroundBlendMode, ...subTitleCss } = subTitleStyle;

  return (
    <Box
      className='profile-subtitle'
      sx={{
        background: currentTheme.profile.subtitle.background,
        backgroundBlendMode: currentTheme.profile.subtitle.backgroundBlendMode,
      }}
    >
      <LayoutContainer
        className='profile-subtitle-container'
        sx={style_ProfileCard_subtitle_container({
          paddingBottom,
          paddingTop,
        })}
      >
        <Stack spacing={0.6}>
          {/* --------------- edit btn */}
          <Stack direction='row' dir='ltr' alignItems='center' justifyContent='flex-end'>
            <EditBtn
              to={'/edit/profile-subtitle'}
              stylings={{ textDecoration: 'none', color: 'inherit' }}
            />
          </Stack>
          {/* ----------------- user subtitle */}
          <Box
            sx={{
              overflow: 'hidden',
              '& pre': {
                whiteSpace: 'break-spaces',
              },
              '& *': {
                maxWidth: '100%',
              },
              ...subTitleCss,
            }}
          >
            <TinymceFormatPreserver>
              <Box
                dangerouslySetInnerHTML={{
                  __html: subTitle,
                }}
              />
            </TinymceFormatPreserver>
          </Box>
        </Stack>
      </LayoutContainer>
    </Box>
  );
};

export default ProfileSubtitle;
