import { Link } from 'react-router-dom';
import routeConstants from 'constants/routeConstants';

const homeRoute = routeConstants.HOME_ROUTE;

const PageNotFound = () =>
  // checking source map out thing on build
  //   {
  //   errorStatus = 404,
  //   headline = "Look like you're lost",
  //   description = 'The page you are looking for is not available!',
  // }: {
  //   errorStatus?: number;
  //   headline?: string;
  //   description?: string;
  // }
  {
    return (
      <section className='page_404'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='col-sm-12 col-sm-offset-1  text-center'>
                <div className='four_zero_four_bg'>
                  <h1 className='text-center '>{404}</h1>
                </div>

                <div className='contant_box_404'>
                  <h3 className='h2 mb-3'>Looks like you're lost</h3>

                  <p>The page you are looking for is not available!</p>
                  <Link
                    replace
                    to={homeRoute}
                    className='theme-btn--filled text-white mt-3 d-inline-block'
                  >
                    Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style>
          {`      
          .page_404 { 
            padding:40px 0; 
            background:#fff; 
            padding-top: 80px;            
            display: grid;
            place-items: center;
            font-family: 'Open-sans', serif;
          }

          @media (max-width : 586px) {
            .page_404 {
              padding-top: 30px;
            }
          }

          .page_404 img { 
            width:100%;
          }

          .four_zero_four_bg {
            background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
            height: 500px;
            background-position: center;
          }

          .four_zero_four_bg h1 {
            font-size:80px;            
          }

          .four_zero_four_bg h3 {
            font-size:80px;
          }
            
          .link_404 {
            color: #fff!important;
            padding: 10px 20px;
            background: #3b3b3b;
            margin: 20px 0;
            font-size: 20px;
            border-radius: 5px;
            display: inline-block;            
          }

          .contant_box_404 { 
            margin-top:-50px;
          }
        `}
        </style>
      </section>
    );
  };

export default PageNotFound;
