import * as yup from 'yup';

import serverApi from 'lib/apiConfig';
import loginTokenUtils from 'utilities/loginTokenUtils';
const isNodeEnvProduction = process.env.NODE_ENV === 'production';

// fetching url only for the first time and only if the userUrl field value has changed
const cacheTest = (asyncValidate: (val: string) => Promise<boolean>) => {
  let _valid = false;
  let _value = '';

  return async (value: string) => {
    if (value !== _value) {
      const response = await asyncValidate(value);
      _value = value;
      _valid = response;
      return response;
    }
    return _valid;
  };
};

const checkIfUrlExistsTest = cacheTest(
  (value: string) =>
    new Promise((resolve) =>
      checkIfUrlExists(value).then((doesUrlExists) => resolve(!doesUrlExists))
    )
);

export const userUrlValidation = (includeAvailabilityCheck = false) => {
  let schema = yup
      .string()
      .max(120, 'Too long!!')
      .min(2, 'Too short!!')
      .required('Website URL is required')
      .test(
          'chars validation',
          'Only lowercase alphabets, numbers, hyphens, underscores, and periods are allowed with no spaces',
          function (value) {
              const regex = /^[a-z0-9\-_.]+$/;
              return regex.test(value);
          }
      );

  if (includeAvailabilityCheck) {
      schema = schema.test(
          'checking user URL availability',
          "URL isn't available",
          checkIfUrlExistsTest
      );
  }

  return schema;
};

// EUD: Edit User Details
// From validation schema
const EUDFormValidationSchema = yup.object().shape({
  name: yup.string().min(4, 'Too short!!').max(120, 'Too long!!').required('Name is required'),
  phoneNumber: yup.string(),
  // whatsAppNumber: yup.string(),
  userUrl: userUrlValidation(true),
  title: yup.string().min(4, 'Too short!!').max(120, 'Too long!!').required('Title is required'),
  searchable: yup.boolean(),
  isAuthTagVisible: yup.boolean(),
  userFavIcon: yup.string(),
  userDashboardImg: yup.string(),
  // set tags to be less than 21
  tags: yup.array().max(20, 'Maximum 20 tags allowed'),
});

export default EUDFormValidationSchema;

// utils
const checkIfUrlExists = async (userURL: string) => {
  try {
    // console.log('checking url existence in EUD validation schema');
    const loginToken = loginTokenUtils.getLocalStorageLoginToken();

    // ✅ if not token exists
    if (!loginToken) {
      !isNodeEnvProduction &&
        console.error(
          'Login token not found while validating user URL in Edit user details validation schema | loginToken: ',
          loginToken
        );
      return false;
    }
    const { data: doesUrlExists } = await serverApi.v1.post<boolean>(
      '/user/check-url-availability',
      {
        userURL,
      },      
    );
    return doesUrlExists;
  } catch (error) {
    return false;
  }
};
