import { useState } from 'react';
import { AxiosError } from 'axios';

import connectedAccountServices from 'lib/services/connectedAccountServices';
import useAsyncStatus, { Actions, AsyncReducerActions } from 'hooks/useAsyncStatus';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import useGetStripePlatformCustomer from 'store/rtkqFeatures/api/hooks/useGetStripePlatformCustomer';

// NOTE: with Axios not RTKQuery

/**
 * if onboarding process isn't completed then onboard link
 * comes in error object and redirects automatically from there.
*/

const useStripeOnboardingProcess = () => {
  const { isPremiumUser } = useGetStripePlatformCustomer();
  const [openPremiumPopup, setPremiumPopup] = useState(false);
  const [isCountryAndCurrencyNotSelected, setIsCountryAndCurrencyNotSelected] = useState(false);
  const { userIdInReduxUserSlice: editorId, cachedLoggedInUser } = useGetLoggedInUser();
  
  const {
    status: generateLinkStatus,
    isIdle: isGenerateLinkStatusIdle,
    isLoading: isGenerateLinkStatusLoading,
    isSuccess: isGenerateLinkStatusSuccess,
    isError: isGenerateLinkStatusError,
    error: generateLinkStatusErrorMsg,
    updateStatus: updateGenerateLinkStatus,
    Actions: GenerateLinkStatusActions,
  } = useAsyncStatus();

  const handleOnboardProcess = async (returnPath: string) => {
    if (!cachedLoggedInUser?.countryISOCode || !cachedLoggedInUser?.currency) {
      // if any of them is undefined then we are showing popup messsage first to choose country and currency and then proceed to onboard process.
      setIsCountryAndCurrencyNotSelected(true);
      return;
    };    
    if (isGenerateLinkStatusLoading) return;
    if (!isPremiumUser) {
      setPremiumPopup(true);
      return;
    }
    await generateOnboardLink(editorId, returnPath, updateGenerateLinkStatus, GenerateLinkStatusActions);
  };

  return {
    isOnboardingProcessCompleted: '',
    setIsCountryAndCurrencyNotSelected,
    data: { onboardLink: '' }, // !! returns nothing }
    openPremiumPopup,
    setPremiumPopup,
    handleOnboardProcess,
    cachedLoggedInUser,
    onboardingProcessPromiseState: {
      isIdle: isGenerateLinkStatusIdle,
      isLoading: isGenerateLinkStatusLoading,
      isError: isGenerateLinkStatusError,
      errorMsg: generateLinkStatusErrorMsg,
      promiseStatus: generateLinkStatus,
      isSuccess: isGenerateLinkStatusSuccess,
    },
    isCountryAndCurrencyNotSelected,
  };
};

export default useStripeOnboardingProcess;

async function generateOnboardLink(
  editorId: string,
  returnPath: string,
  updateGenerateLinkStatus: React.Dispatch<AsyncReducerActions>,
  GenerateLinkStatusActions: typeof Actions,
) {
  try {
    updateGenerateLinkStatus({
      type: GenerateLinkStatusActions.LOADING,
    });
    await connectedAccountServices.generateAccountLinkByUserId(editorId, returnPath);    

  } catch (error) {
    if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
      if ('response' in error) {
        const errorStatus = error.response?.status;
        const errorData = error.response?.data;
        if (errorStatus === 403) {
          // console.log('XXX04 | Handling error status 403');
          const isOnboardingProcessNotCompleted = !errorData.isOnboardProcessCompleted;
          if (isOnboardingProcessNotCompleted) {
            const onboardLink = errorData.stripeOnboardLink;
            onboardLink && window.location.replace(onboardLink);
          }
        } else {
          updateGenerateLinkStatus({
            type: GenerateLinkStatusActions.ERROR,
            payload: (error as AxiosError).message,
          });
        }
      }
    }
  }
}