import { Box, Stack, Tooltip, Typography } from '@mui/material';
import LinkTwoToneIcon from '@mui/icons-material/LinkTwoTone';
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useCopyToClipboard } from 'usehooks-ts';
import { grey } from '@mui/material/colors';
import { toast } from 'react-toastify';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 2000,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_CENTER,
};

type ProfileDetailsBannerLinksProps = {
  staticPageLinkURL: string;
};

const ProfileDetailsBannerLinks: React.FunctionComponent<
  ProfileDetailsBannerLinksProps
> = ({ staticPageLinkURL }) => {
  const [, copyFn] = useCopyToClipboard();
  const { cachedLoggedInUser: editor } = useGetLoggedInUser();
  const name = editor?.name;
  const copyLink = `Copy Link`;
  const emailLink = `mailto:?subject=Sharing my website with you&body=Hi!%0D%0A%0D%0AI wanted to reach out and let you know about my website - ${staticPageLinkURL} that lists all the services I offer.%0D%0AI have included all the information you need about my services, including description, pricing, availability, and what is included.%0D%0APlease take a moment to check out my website and let me know if you have any questions.%0D%0AFeel free to book the service that fits your need%0D%0A%0D%0ABest regards%0D%0A${name}`;
  const emailShare = 'Share by Email';
  const whatsAppLink = `https://wa.me/?text=check out my website at ${staticPageLinkURL}`;
  const whatsAppShare = 'Share via WhatsApp';
  const facebookLink = `https://www.facebook.com/share.php?u=${staticPageLinkURL}`;
  const facebookShare = 'Share on Facebook';
  const twitterLink = `https://twitter.com/intent/tweet?url=${staticPageLinkURL}`;
  const twitterShare = 'Share on Twitter';
  const linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?url=${staticPageLinkURL}`;
  const linkedInShare = 'Share on LinkedIn';

  const handleCopyStaticPageUrlClick = async () => {
    try {
      await copyFn(staticPageLinkURL);
      toast.info('Your site URL copied. Share it with others 🎉', TOAST_MESSAGE_SETTINGS);
    } catch (error) {
      toast.error('Unable to copy site URL', TOAST_MESSAGE_SETTINGS);
    }
  };

  const handleShareEmailClick = async () => {
    window.open(emailLink, '_blank');
  };

  const handleShareWhatsAppClick = () => {
    window.open(whatsAppLink, '_blank');
  };

  const handleShareFacebookClick = () => {
    window.open(facebookLink, '_blank');
  };

  const handleShareTwitterClick = () => {
    window.open(twitterLink, '_blank');
  };

  const handleShareLinkedInClick = () => {
    window.open(linkedInLink, '_blank');
  };

  const iconFontSize = {
    fontSize: '1.5rem',
  };

  return (
    <Stack spacing={1} direction='row' justifyContent='flex-start' alignItems='center'>
      {/* ------------ copy static page link --------------- */}
      <IconWrapper show={!!copyLink} onIconClick={handleCopyStaticPageUrlClick}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 10, md: 14 }, color: '#fff' }}>
              {copyLink}
            </Typography>
          }
        >
          <LinkTwoToneIcon sx={iconFontSize} />
        </Tooltip>
      </IconWrapper>
      {/* ------------ email link ---------------*/}
      <IconWrapper show={!!emailShare} onIconClick={handleShareEmailClick}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 10, md: 14 }, color: '#fff' }}>
              {emailShare}
            </Typography>
          }
        >
          <MailOutlineTwoToneIcon sx={iconFontSize} />
        </Tooltip>
      </IconWrapper>
      {/* ------------ Facebook link --------------- */}
      <IconWrapper show={!!facebookShare} onIconClick={handleShareFacebookClick}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 10, md: 14 }, color: '#fff' }}>
              {facebookShare}
            </Typography>
          }
        >
          <FacebookOutlinedIcon sx={iconFontSize} />
        </Tooltip>
      </IconWrapper>
      {/* ------------ whatsapp link --------------- */}
      <IconWrapper show={!!whatsAppShare} onIconClick={handleShareWhatsAppClick}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 10, md: 14 }, color: '#fff' }}>
              {whatsAppShare}
            </Typography>
          }
        >
          <WhatsAppIcon sx={iconFontSize} />
        </Tooltip>
      </IconWrapper>
      {/* ------------ Twitter link --------------- */}
      <IconWrapper show={!!twitterShare} onIconClick={handleShareTwitterClick}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 10, md: 14 }, color: '#fff' }}>
              {twitterShare}
            </Typography>
          }
        >
          <TwitterIcon sx={iconFontSize} />
        </Tooltip>
      </IconWrapper>
      {/* ------------ LinkedIn link --------------- */}
      <IconWrapper show={!!linkedInShare} onIconClick={handleShareLinkedInClick}>
        <Tooltip
          title={
            <Typography sx={{ fontSize: { xs: 10, md: 14 }, color: '#fff' }}>
              {linkedInShare}
            </Typography>
          }
        >
          <LinkedInIcon sx={iconFontSize} />
        </Tooltip>
      </IconWrapper>
    </Stack>
  );
};

export default ProfileDetailsBannerLinks;

type IconWrapperProps = {
  children: JSX.Element | JSX.Element[];
  onIconClick?: React.MouseEventHandler<HTMLDivElement>;
  show?: boolean;
};

function IconWrapper({ children, onIconClick, show = true }: IconWrapperProps) {
  if (!show) return <></>;
  return (
    <Box
      onClick={onIconClick}
      sx={{
        color: 'common.white',
        padding: '0.7rem',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: grey[900],
        '&:hover': {
          backgroundColor: grey[800],
          cursor: 'pointer',
        },
      }}
    >
      {children}
    </Box>
  );
}
