import ContactUsHere from 'components/privacyPolicy/ContactUsHere';
import InlineBoldSpan from 'components/privacyPolicy/InlineBoldSpan';
import Policy from 'components/privacyPolicy/Policy';
import PolicyHeading from 'components/privacyPolicy/PolicyHeading';
import PolicySubHeading from 'components/privacyPolicy/PolicySubHeading';
import PolicySubSection from 'components/privacyPolicy/PolicySubSection';
import PrivacyText14 from 'components/privacyPolicy/PrivacyText14';
import PrivacyText16 from 'components/privacyPolicy/PrivacyText16';

const PrivacyPolicy = () => {
  const effectiveDate = 'June 1, 2022';
  const companyName = 'ProcessUs Solutions Inc.';
  const websiteName = 'fronts.ai';
  const contactEmail = 'contact@fronts.ai';
  const websiteDomain = `${websiteName}.com`;

  return (
    <div id='policy-page'>
      <div className='layout-container'>
        <h1 className='policy-page__heading heading heading-big'>
          {websiteName} Privacy Policy
        </h1>
        <p className='policy-page__effective-date'>
          Effective from {effectiveDate}
        </p>
        <p className='mb-4'>Welcome to {websiteName}'s Privacy Policy</p>
        <ol type='1' id='policy__container'>
          <Policy>
            <PolicyHeading>1. Please read carefully</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                    {websiteDomain} cares deeply about the privacy of its
                    visitors and users. To that end, this Privacy Policy (
                    <InlineBoldSpan>“Privacy Policy”</InlineBoldSpan>) describes
                    how {companyName} collect, use, and share your Personal
                    Information, as well as an explanation of the data rights
                    you may have in that Personal Information. This Privacy
                    Policy applies to all
                    <span> {websiteName} </span>
                    users, including unregistered visitors, registered users,
                    and premium users (collectively, “
                    <InlineBoldSpan>Users</InlineBoldSpan>”, “
                    <InlineBoldSpan>you</InlineBoldSpan>”, or “
                    <InlineBoldSpan>your</InlineBoldSpan>”), and to all{' '}
                    {websiteName} services, including our websites (including
                    www.
                    {websiteDomain} and any of its subdomains, the “
                    <InlineBoldSpan>Website</InlineBoldSpan>”), web applications
                    (
                    <InlineBoldSpan>
                      <>"{websiteName} Apps”</>
                    </InlineBoldSpan>
                    ), mobile applications (
                    <InlineBoldSpan>"Mobile Apps"</InlineBoldSpan>), and related
                    services (collectively, the{' '}
                    <InlineBoldSpan>“Services”</InlineBoldSpan>). This Privacy
                    Policy is not intended to override the terms of any contract
                    you have with us, nor any rights you may have under other
                    applicable data privacy laws.
                  </>
                </PrivacyText16>
                <div className='w-100'></div>
                <PrivacyText16 classname='mt-4'>
                  <>
                    Prior to accessing or using our Services, please read this
                    policy and make sure you fully understand our practices in
                    relation to your Personal Information. If you read and fully
                    understand this Privacy Policy, and remain opposed to our
                    practices, you must immediately leave and discontinue all
                    use of any of our Services. If you have any questions or
                    concerns regarding this policy, please contact us at {contactEmail} .
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  This policy describes our privacy practices – what Personal
                  Information we collect about our Users, what we do with it,
                  how we share it, and your rights regarding that Personal
                  Information.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  By accessing or using any of our Services, you acknowledge
                  that you have read this Privacy Policy.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>
              2. What ‘Personal Information’ do we collect?
            </PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PolicySubSection>
                  <PolicySubHeading>2.1. User information:</PolicySubHeading>
                  <PrivacyText16>
                    <>
                      To provide you the Services, we must collect Personal
                      Information relating to an identified or identifiable
                      natural person (
                      <InlineBoldSpan>“Personal Information”</InlineBoldSpan>).
                      We collect Personal Information you provide us, from your
                      use of the Services, and from other sources. Here are the
                      types of Personal Information we collect about you:
                    </>
                  </PrivacyText16>
                  <ol>
                    <li>
                      <PrivacyText16>
                        <>
                          1.{' '}
                          <InlineBoldSpan>
                            Information you provide us.
                          </InlineBoldSpan>{' '}
                          When you register for our Services, purchase and/or
                          register domain names, use any of our Services; and/or
                          when you contact us directly by any communication
                          channel (e.g., {websiteName}’s support tickets,
                          emails), you may provide us Personal Information, such
                          as name, email address, phone number, payment
                          information (for Users with Paid Services),
                          information you include in your communications with us
                          and with other users on our platform, and Personal
                          Information contained in scanned identification
                          documents (such as an ID card, driver’s license,
                          passport, or official company registration documents).
                        </>
                      </PrivacyText16>
                    </li>
                    <li>
                      <PrivacyText16>
                        <>
                          2.{' '}
                          <InlineBoldSpan>
                            Information we collect when you use the Services.
                          </InlineBoldSpan>{' '}
                          When you visit, download, and/or use any of our
                          Services, we may collect aggregated usage Personal
                          Information, such as Visitors’ and Users’ browsing and
                          ‘click-stream’ activity on the Services, session
                          heatmaps and scrolls, non-identifying Personal
                          Information regarding the Visitor’s or User’s device,
                          operating system, internet browser, screen resolution,
                          language and keyboard settings, internet service
                          provider, referring/exit pages, date/time stamps, etc
                        </>
                      </PrivacyText16>
                    </li>
                    <li>
                      <PrivacyText16>
                        <>
                          3.{' '}
                          <InlineBoldSpan>
                            Information we collect from other sources.
                          </InlineBoldSpan>{' '}
                          We may receive Personal Information about you from
                          third-party sources, such as i) security providers ,
                          fraud detection and prevention providers for example
                          to help us screen out users associated with fraud, ii)
                          social media platforms, when you log-in or sign-up
                          using your social media account, we may receive
                          Personal Information from that service (e.g., your
                          username, basic profile Personal Information) and in
                          some cases, we may collect Personal Information from
                          lead enhancement companies which help us to improve
                          our service offering; iii) advertising and marketing
                          partners in order to monitor, manage and measure our
                          ad campaigns.
                        </>
                      </PrivacyText16>
                    </li>
                  </ol>
                </PolicySubSection>
                <PolicySubSection>
                  <PolicySubHeading>
                    2.2. Users of users ‘Personal Information’
                  </PolicySubHeading>
                  <PrivacyText16>
                    <>
                      We may also collect Personal Information pertaining to
                      visitors and users of our User’s websites or services (
                      <InlineBoldSpan>“Users-of-Users”</InlineBoldSpan>), solely
                      for and on our Users’ behalf.
                      {/* (as further described in Section 6 below). */}
                    </>
                  </PrivacyText16>
                </PolicySubSection>
                <PolicySubSection lastSection>
                  <PolicySubHeading>
                    {`2.3. ${websiteName} jobs applicant information`}
                  </PolicySubHeading>
                  <PrivacyText16>
                    <>
                      We also collect information that is provided to us by{' '}
                      {websiteName} jobs candidates (“Applicants”), when they
                      apply to any of the open positions on this website, by
                      e-mail or otherwise.
                      {/* (as further described in Section 5 below). */}
                    </>
                  </PrivacyText16>
                </PolicySubSection>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  To provide our Services, we collect Personal Information about
                  our Users.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  The Personal Information comes from you when you visit or use
                  our services, Personal Information we collect automatically,
                  and Personal Information we collect from other sources.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <div className='row'>
              <PolicyHeading>
                3. How we share your ‘Personal Information’
              </PolicyHeading>
              <div className='col-7'>
                <PrivacyText16>
                  We may share your Personal Information with service providers
                  and others (or otherwise allow them access to it) in the
                  following manners and instances:
                </PrivacyText16>
                <PolicySubSection classname='pt-5'>
                  <PrivacyText16>
                    <>
                      3.1.{' '}
                      <InlineBoldSpan>
                        Third Party Service Providers:
                      </InlineBoldSpan>{' '}
                      {websiteName} has partnered or will partner with a number
                      of selected service providers, whose services and
                      solutions complement, facilitate and enhance our own.
                      These include hosting and server co-location services,
                      communications and content delivery networks (CDNs), data
                      and cyber security services, billing and payment
                      processing services, domain name registrars, fraud
                      detection and prevention services, web analytics, e-mail
                      distribution and monitoring services, session recording
                      and remote access services, performance measurement, data
                      optimization and marketing services, content providers,
                      and our legal and financial advisors (collectively,{' '}
                      <InlineBoldSpan>
                        “Third Party Service Provider(s)”).
                      </InlineBoldSpan>
                    </>
                  </PrivacyText16>
                </PolicySubSection>
                <PolicySubSection>
                  <PrivacyText16>
                    <>
                      {websiteName} may share the following categories of
                      Personal Information with Third Party Service Providers
                      for a business purpose:
                    </>
                  </PrivacyText16>
                  <ul className='ps-4'>
                    <li className='mb-4'>
                      <PrivacyText16>
                        &#9679; identifiers, including name, alias, postal
                        address, unique personal identifier, online identifier,
                        internet protocol address, email address, account name,
                        or other similar identifiers.
                      </PrivacyText16>
                    </li>
                    <li>
                      <PrivacyText16>
                        &#9679; commercial Personal Information, for example
                        Personal Information regarding products or services
                        purchased, obtained, or considered.
                      </PrivacyText16>
                    </li>
                  </ul>
                </PolicySubSection>
                <PolicySubSection>
                  <PrivacyText16>
                    <>
                      3.2.{' '}
                      <InlineBoldSpan>
                        Law Enforcement, Legal Requests and Duties:
                      </InlineBoldSpan>{' '}
                      {websiteName} may disclose or otherwise allow access to
                      any categories of your Personal Information described in
                      this Privacy Policy pursuant to a legal request, such as a
                      subpoena, legal proceedings, search warrant or court
                      order, or in compliance with applicable laws, if we have a
                      good faith belief that the law requires us to do so, with
                      or without notice to you.
                    </>
                  </PrivacyText16>
                </PolicySubSection>
                <PolicySubSection>
                  <PrivacyText16>
                    <>
                      3.3.{' '}
                      <InlineBoldSpan>
                        Protecting Rights and Safety:
                      </InlineBoldSpan>{' '}
                      {websiteName} may share any categories of your Personal
                      Information described in this Privacy Policy if we believe
                      in good faith that this will help protect the rights,
                      property or personal safety of {websiteName}, any of our
                      Users, any Users-of-Users, or any member of the general
                      public, with or without notice to you.
                    </>
                  </PrivacyText16>
                </PolicySubSection>
                <PolicySubSection>
                  <PrivacyText16>
                    <>
                      3.4.{' '}
                      <InlineBoldSpan>
                        In connection with a change in corporate control:
                      </InlineBoldSpan>{' '}
                      In addition, should {websiteName} undergo any change in
                      control, including by means of merger, acquisition or
                      purchase of substantially all of its assets, your Personal
                      Information may be shared with the parties involved in
                      such event.
                    </>
                  </PrivacyText16>
                </PolicySubSection>
                <PolicySubSection lastSection>
                  <PrivacyText16>
                    <>
                      3.5.{' '}
                      <InlineBoldSpan>
                        Upon Your Further Direction
                      </InlineBoldSpan>{' '}
                      The {websiteName} Services enable you, through different
                      techniques, to engage and procure various third party
                      services, products and tools for enhancing your web or
                      mobile sites, including, without limitation, applications
                      and widgets offered to you by third parties through the{' '}
                      {websiteName} Website (including the {websiteName} App
                      Market), eCommerce payment providers, third party
                      designers who may assist you with your website, etc.
                      (collectively, “Third Party Services”). If you choose to
                      engage with such Third Party Services, they may have
                      access to and process Personal Information of your
                      Users-of-Users collected through your web or mobile sites.
                      For example: a) Framed Pages: our Services may enable you
                      to integrate Third Party Services directly into your web
                      or mobile sites, such as via page framing techniques to
                      serve content to or from Third Party Services or other
                      parties (<InlineBoldSpan>“Frames”</InlineBoldSpan>). In
                      these circumstances, the Third Party Services may collect
                      Personal Information from your Users-of-Users.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      <InlineBoldSpan>{`b) App Market Developers: `}</InlineBoldSpan>{' '}
                      We allow third party developers (
                      <InlineBoldSpan>
                        “Third Party Developer(s)”
                      </InlineBoldSpan>
                      ) to develop and offer their own applications via the{' '}
                      {websiteName}
                      App Market (
                      <InlineBoldSpan>“Third Party App(s)”</InlineBoldSpan>) to
                      Users, which you may integrate into your web or mobile
                      sites. Each Third Party Developer is bound by the{' '}
                      {websiteName} App Market Partner Agreement, which among
                      other things, restricts the ways in which such developers
                      may access, store, share, and use the Personal Information
                      you and/or your Users-of-Users provide to them.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      <InlineBoldSpan>
                        {`c) Social Media Features:`}
                      </InlineBoldSpan>{' '}
                      Our Services may enable you to integrate certain Social
                      Media features, widgets, and single sign on features, such
                      as “Facebook Connect,” or “Google Sign-in” (“Social Media
                      Features”) into your web or mobile sites. These Social
                      Media Features may collect certain Personal Information
                      from your Users-of-Users such as identifiers, including
                      name, alias, unique personal identifier, online
                      identifier, internet protocol address, email address, or
                      other similar identifiers. Social Media Features are
                      hosted either by a third party or directly on our
                      Services.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      Please note that in the examples listed above in this
                      Section 3.5, {websiteName} merely acts as an intermediary
                      platform allowing you to procure the services of such
                      Third Party Services (including, but not limited to, Third
                      Party Developers, Third Party Apps and Social Media
                      Features) with which you are interacting directly, and at
                      your discretion. In this respect, {websiteName} acts as a
                      service provider to you, disclosing information to the
                      Third Party Services on your behalf. {websiteName} will
                      share your Users-of-Users’ Personal Information with Third
                      Parties Services only upon your direction or with your
                      permission and is not, and shall not be, in any way
                      responsible for such Third Party Services processing of
                      such Personal Information, or liable with respect thereto.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      {websiteName} does not control and is not responsible for
                      the actions or policies of any Third Party Service, and
                      your use of any Third Party Service is at your own risk.
                      We encourage you to review any privacy policy accompanying
                      a Third Party Service and ask such Third Party Service for
                      any clarifications you may need before deciding to install
                      and/or use their services.
                    </>
                  </PrivacyText16>
                </PolicySubSection>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We may share the Personal Information of our Users and
                  Users-of-Users with various third parties, including certain
                  service providers and law enforcement officials.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  The Personal Information may be shared solely in accordance
                  with this policy.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>
              4. Where do we store your ‘Personal Information’?
            </PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PolicySubSection lastSection>
                  <PrivacyText16>
                    4.1. Users’ and Users-of-Users’ Personal Information may be
                    stored in data centers located in the United States of
                    America. We may use other jurisdictions as necessary for the
                    proper delivery of our Services and/or as may be required by
                    law.
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      <InlineBoldSpan>{`${websiteName}`}</InlineBoldSpan> is
                      based in United States of America, which is considered by
                      the European Commission to be offering an adequate level
                      of protection for the Personal Information of EU Member
                      State residents.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      <InlineBoldSpan>{`${websiteName} affiliates and Third-Party Service Providers`}</InlineBoldSpan>{' '}
                      that store or process your Personal Information on{' '}
                      {websiteName}’s behalf are contractually committed to keep
                      it protected and secured, in accordance with industry
                      standards and regardless of any lesser legal requirements
                      which may apply in their jurisdiction.
                    </>
                  </PrivacyText16>
                </PolicySubSection>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We may store and process Personal Information in the USA,
                  Europe, or other jurisdictions, whether by ourselves or with
                  the help of our affiliates and service providers.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  <>
                    {websiteName}’s data storage providers are contractually
                    committed to protect and secure your data.
                  </>
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  <>
                    Among other things, {websiteName} will ensure that there is
                    the adequate level of protection or that relevant Standard
                    Contractual Clauses are in place for the international
                    transfer of our EU users’ data.
                  </>
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>
              5. Use of cookies and other third-party technologies
            </PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  We and our Third Party Service Providers use cookies and other
                  similar technologies (“Cookies”) in order for us to provide
                  our Service and ensure that it performs properly, to analyze
                  our performance and marketing activities, and to personalize
                  your experience.
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  You can learn more about how we use cookies and similar
                  technologies and how you can exercise control over them in our
                  Cookie Policy.
                  {/* link */}
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  Please note that we do not change our practices in response to
                  a “Do Not Track” signal in the HTTP header from a browser or
                  mobile application, however, most browsers allow you to
                  control cookies, including whether or not to accept them and
                  how to remove them. You may set most browsers to notify you if
                  you receive a cookie, or you may choose to block cookies with
                  your browser.
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We and certain third party services may use cookies and
                  similar tracking technologies throughout our services.
                </PrivacyText14>
                <PrivacyText14 classname='mt-3'>
                  These technologies are used mostly for stability, security,
                  functionality, performance and advertising purposes.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>{`6. Communications from ${websiteName}`}</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PolicySubSection>
                  <PolicySubHeading>6.1. Promotional messages</PolicySubHeading>
                  <PrivacyText16>
                    <>
                      We may use your Personal Information to send you
                      promotional content and messages by e-mail, text messages,
                      notifications within our platform, marketing calls and
                      similar forms of communication from {websiteName} or our
                      partners (acting on {websiteName}’s behalf) through such
                      means.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      If you do not wish to receive such promotional messages or
                      calls, you may notify {websiteName} at any time or follow
                      the “unsubscribe” or STOP instructions contained in the
                      promotional communications you receive.
                    </>
                  </PrivacyText16>
                </PolicySubSection>
                <PolicySubSection>
                  <PolicySubHeading>
                    6.2. Service and billing messages
                  </PolicySubHeading>
                  <PrivacyText16>
                    <>
                      {websiteName} may also contact you with important
                      information regarding our Services, or your use thereof.
                      For example, we may send you a notice (through any of the
                      means available to us) if a certain Service is temporarily
                      suspended for maintenance; reply to your support ticket or
                      e-mail; send you reminders or warnings regarding upcoming
                      or late payments for your current or upcoming
                      subscriptions; forward abuse complaints regarding your
                      User Website; or notify you of material changes in our
                      Services.
                    </>
                  </PrivacyText16>
                  <PrivacyText16 classname='mt-5'>
                    <>
                      It is important that you are always able to receive such
                      messages. For this reason, you are not be able to opt-out
                      of receiving such Service and Billing Messages unless you
                      are no longer a {websiteName} User (which can be done by
                      deactivating your account).
                    </>
                  </PrivacyText16>
                </PolicySubSection>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We may send you promotional messages and content. You can
                  easily opt-out of receiving promotional messages by contacting
                  us or unsubscribing.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  We may also contact you with service and billing-related
                  messages and content. You will not be able to opt-out of
                  receiving such messages.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>
              7. Your rights in relation to your ‘Personal Information’
            </PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                    {companyName} believes that it is imperative that all{' '}
                    {websiteName} users have control over their Personal
                    Information. Therefore, depending on the way you use the{' '}
                    {websiteName} Services, you may have the right to access,
                    update or delete certain Personal Information to another
                    service, restrict, or object to certain uses of your
                    Personal Information (for example, for direct marketing
                    purposes). Further, when we rely on your consent for
                    processing of your Personal Information (for instance, for
                    direct marketing) you can withdraw your consent at any time,
                    and such withdrawal will take affect from thereon.
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                    {websiteName} will not charge you more if you exercise any
                    of these rights and will continue to provide you with the
                    same level of service
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                    If you are a {websiteName} user, you can access and correct
                    a lot of your Personal Information directly through your
                    account online.
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  When it comes to your Personal Information you stored with us,
                  you have the right to: access, update, or delete your Personal
                  Information​, restrict, or object to certain uses of your
                  Personal Information, withdraw consent you’ve given us before
                  to processing.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  Just reach out to us by e-mail or through our customer care
                  team, or, when available, fill out our dedicated form.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>8. Data retention</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  We may retain your Personal Information (as well as your
                  Users-of-Users’ Personal Information) for as long as your User
                  Account is active, as indicated in this Privacy Policy, or as
                  otherwise needed to provide you with our Services.
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  We may continue to retain your Personal Information after you
                  deactivate your User Account and/or cease to use any
                  particular Services, as reasonably necessary to comply with
                  our legal obligations, to resolve disputes regarding our Users
                  or their Users-of-Users, prevent fraud and abuse, enforce our
                  agreements and/or protect our legitimate interests.
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  We maintain a data retention policy which we apply to Personal
                  Information in our care.
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We may keep your Personal Information for as long as your
                  account is active, and longer as needed (for example, if we
                  are legally obligated to keep it longer, or need it to protect
                  our interests).
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>9. Security</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                    {websiteName} has implemented security measures designed to
                    protect the Personal Information you share with us,
                    including physical, electronic and procedural measures.
                    Among other things, we offer HTTPS secure access to most
                    areas on our Services; the transmission of sensitive payment
                    information (such as a credit card number) through our
                    designated purchase forms is protected by an industry
                    standard SSL/TLS encrypted connection; and we regularly
                    monitor our systems for possible vulnerabilities and
                    attacks, and regularly seek new ways and Third Party
                    Services for further enhancing the security of our Services
                    and protection of our Visitors’ and Users’ privacy.
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                    Regardless of the measures and efforts taken by{' '}
                    {websiteName}, we cannot and do not guarantee the absolute
                    protection and security of your Personal Information, your
                    Users-of-Users’ Personal Information or any other
                    information you upload, publish or otherwise share with{' '}
                    {websiteName} or anyone else. We encourage you to set strong
                    passwords for your User Account and User Website, and avoid
                    providing us or anyone with any sensitive Personal
                    Information of which you believe its disclosure could cause
                    you substantial or irreparable harm. Furthermore, because
                    certain areas on our Services are less secure than others
                    (for example, if you set your Support forum ticket to be
                    “Public” instead of “Private”, or if you browse to a non-SSL
                    page), and since e-mail and instant messaging are not
                    recognized as secure forms of communications, we request and
                    encourage you not to share any Personal Information on any
                    of these areas or via any of these methods.
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                    If you have any questions regarding the security of our
                    Services, you are welcome to contact us <ContactUsHere />.
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  <>
                    {websiteName} values the security of our customers' Personal
                    Information and we do everything in our power to protect it.
                  </>
                </PrivacyText14>
                <PrivacyText14 classname='mt-4'>
                  However, as we can’t guarantee absolute protection – we
                  encourage you to be careful, set a strong password for your
                  account, and avoid submitting any sensitive information which,
                  if exposed, could cause you major harm.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>10. Third-party websites</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  Our Services may contain links to other websites or services.
                  We are not responsible for such websites’ or services’ privacy
                  practices. We encourage you to be aware when you leave our
                  Services and to read the privacy statements of each and every
                  website and service you visit before providing your Personal
                  Information. This Privacy Policy does not apply to such linked
                  third party websites and services.
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  Our Services may contain links to other websites or services.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>11. Public forums and user content</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                    Our Services offer publicly accessible blogs, communities
                    and support forums. Please be aware that any Personal
                    Information you provide in any such areas may be read,
                    collected, and used by others who access them. To request
                    removal of your Personal Information from our blogs,
                    communities or forums, feel free to contact us{' '}
                    <ContactUsHere />. In some cases, we may not be able to
                    remove your Personal Information from such areas. For
                    example, if you use a third party application to post a
                    comment (e.g., the Facebook social plugin application) while
                    logged in to your related profile with such third party, you
                    must login into such application or contact its provider if
                    you want to remove the Personal Information you posted on
                    that platform.
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <InlineBoldSpan>
                    In any event, we advise against posting any Personal
                    Information (via any means) you don’t wish to publicize.
                  </InlineBoldSpan>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                    If you upload any user content to your User Account or post
                    it on your User Website and provide it in any other way as
                    part of the use of any Service, you do so at your own risk.
                    We have put adequate security measures in place to protect
                    your Personal Information. However, we cannot control the
                    actions of other Users or members of the public who may
                    access your User Content, and are not responsible for the
                    circumvention of any privacy settings or security measures
                    you or we may have placed on your User Website (including,
                    for instance, password-protected areas on your User
                    Website). You understand and acknowledge that, even after
                    its removal by you or us, copies of User Content may remain
                    viewable in cached and archived pages or if any third
                    parties (including any of your Users-of-Users) have copied
                    or stored such User Content.{' '}
                    <InlineBoldSpan>
                      To clarify, we advise against uploading or posting any
                      information you do not wish to be public.
                    </InlineBoldSpan>
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  Avoid posting any Personal Information to any of the public
                  areas on our Services, or to your own website, if you don’t
                  want it to become publicly available.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>12. Public Directory and Website Accessibility</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                  Upon the successful creation of your website through Fronts.ai,
                  please be aware that your page will be automatically included in 
                  our public directory. This is a standard procedure designed to
                  increase the visibility of your site and to offer you an easy way
                  to start networking within the Fronts.ai community and beyond.
                  This public directory is accessible by any individual or entity
                  with an Internet connection. Therefore, any information that 
                  you decide to disclose on your website, including but not limited 
                  to, your business name, location, hours of operation, service 
                  offerings, and booking calendar, will be accessible by anyone 
                  who browses the public directory or visits your website.
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                  We encourage you to consider carefully the information you choose
                  to include on your website. Keep in mind that sensitive or 
                  personal information should not be disclosed unless it is
                  essential to your operations and you are prepared for it to be
                  publicly accessible.
                </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                  Fronts.ai assumes no responsibility for any misuse of information
                  that you choose to make public through your website or the public
                  directory. We encourage users to implement their own protective 
                  measures and to practice safe internet habits, such as not 
                  including personally identifiable information whenever possible 
                  and reviewing their site's content for accuracy and 
                  appropriateness on a regular basis.
                </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                  As part of our commitment to your privacy, Fronts.ai will never 
                  sell, rent, or trade any information that you provide during the 
                  website creation process to any third parties. All such 
                  information will be used solely for the purpose of facilitating
                  the functionality and user experience of our website builder
                  platform.
                </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                  You always retain the right to request removal of your website
                  from the public directory. However, please note that while this
                  will make your site less accessible from within the Fronts.ai
                  platform, it will not necessarily prevent internet users who 
                  have already obtained your website URL from accessing your
                  site directly.
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                When you create a website with Fronts.ai, it's automatically 
                added to our public directory, viewable by anyone online. 
                This can boost visibility but also means any information on
                your site is publicly accessible. Please be mindful of the
                info you include, especially personal or sensitive details.
                Fronts.ai isn't responsible for misuse of publicly available
                info. We won't sell, rent or trade your info to third parties.
                You can request to remove your site from the directory,
                but anyone with the URL can still access it.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>13. Updates and interpretation</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                    We may update this Privacy Policy as required by applicable
                    law, and to reflect changes to our Personal Information
                    collection, usage and storage practices. If we make any
                    changes that we deem as “material” (in our sole good faith
                    discretion), we will notify you (using one of the
                    notification methods set forth in Section 15.3 of the Terms
                    of Use) prior to the change becoming effective. In relation
                    to any updated Privacy Policy, we will, as required by
                    applicable law, notify you, seek your consent and/or take
                    any other measures. We encourage you to periodically review
                    this page for the latest Information on our privacy
                    practices. Unless stated otherwise, our most current Privacy
                    Policy applies to all information that we have about you and
                    your Users-of-Users, with respect to our Website,{' '}
                    {websiteName} Apps, Mobile Apps and other Services.
                    {/* Link */}
                  </>
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  Any heading, caption or section title contained herein, and
                  any explanation or summary under the right “#ItsThatEasy”
                  column, is provided only for convenience, and in no way
                  defines or explains any section or provision hereof, or
                  legally binds any of us in any way.
                </PrivacyText16>
                <PrivacyText16 classname='mt-5'>
                  <>
                    This Privacy Policy was written in English, and may be
                    translated into other languages for your convenience. You
                    may access and view other language versions by changing your{' '}
                    {websiteName}
                    Website language settings. If a translated (non-English)
                    version of this Privacy Policy conflicts in any way with its
                    English version, the provisions of the English version shall
                    prevail.
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We may change this policy at any time. We will notify you of
                  changes as required by applicable law.
                </PrivacyText14>
              </div>
            </div>
          </Policy>
          <Policy>
            <PolicyHeading>14. Contacting us</PolicyHeading>
            <div className='row'>
              <div className='col-7'>
                <PrivacyText16>
                  <>
                    If you have any questions about this Privacy Policy, contact us
                    at {contactEmail} and we will attempt to answer any questions
                    regarding the use of your Personal Information in accordance
                    with this Privacy Policy.
                  </>
                </PrivacyText16>
              </div>
              <div className='col-3 offset-2'>
                <h5 className='fw-bold text-14 mb-4'>#ItsThatEasy</h5>
                <PrivacyText14>
                  We may change this policy at any time. We will notify you of
                  changes as required by applicable law.
                </PrivacyText14>
                <PrivacyText14 classname='mt-5'>
                  Only the left column is legally binding (this column is just
                  for clarity).
                </PrivacyText14>
              </div>
            </div>
          </Policy>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
