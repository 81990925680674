import { Box } from '@mui/material';
import Portfolios from 'components/Portfolios/Portfolios';
import Footer from 'views/footer/Footer';
import AboutUs from './AboutUs/AboutUs';
import ProfileCard from 'components/ProfileCard/ProfileCard';
import Store from 'components/MUI_SPM/Store/Store';
import SortedSPM from 'components/MUI_SPM/SortedSPM/SortedSPM';
import ProfileDetailsBanner from 'components/ProfileDetailsBanner/ProfileDetailsBanner';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import useEditor from './hooks/useEditor';
import { Redirect, useRouteMatch } from 'react-router-dom';
import DomainFeatureShowPopup from './sub-components/DomainFeatureShowPopup';
import { HEADER_HEIGHT } from 'components/Header/Header';
import routeConstants from 'constants/routeConstants';

const Editor = () => {
  const { loggedInUser } = useEditor();
  const url = useRouteMatch<{url: string}>().params?.url;

  if (!loggedInUser) {
    return <FrontsLoader />;
  }

  if(loggedInUser && !loggedInUser?.url) {
    return <Redirect to={routeConstants.ONBOARDING_ROUTE} />
  }

  if(url !== loggedInUser?.url) {
    return <Redirect to={`/${loggedInUser?.url}`} />
  }


  return (
    <>
      <Box component='main' className='editor-main' sx={{mt: `${HEADER_HEIGHT}px`}}>
        <ProfileCard />
        <Portfolios />
        <AboutUs />
        <Store>
          <SortedSPM />
        </Store>
        <Footer />
      </Box>
      <DomainFeatureShowPopup />      
      <ProfileDetailsBanner />
    </>
  );
};

export default Editor;
