import { Box } from '@mui/material';

import IfElse from 'components/utilities/IfElse';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import UserSettingsSection from '../../dashboard/UserSettingsSection';

import { useAppSelector } from 'hooks/redux';
import {
  useGetStripeConnectedAccountByEditorIdQuery,
  useGetStripeConnectedAccountStatusQuery,
} from 'store/rtkqFeatures/api/stripeConnectedAccountExtendedApi';
import StripeDashboardBtn from './sub-components/StripeDashboardBtn';
import StripeOnBoardingProcessBtn from './sub-components/StripeOnBoardingProcessBtn/StripeOnBoardingProcessBtn';

const StripeOnboardingIntegration = () => {
  const { data: accountStatus } = useGetStripeConnectedAccountStatusQuery();
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data } = useGetStripeConnectedAccountByEditorIdQuery(editorId, {
    skip: !editorId,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    pollingInterval: 180_000, // 3 mins
  });

  return (
    <UserSettingsSection
      primaryHeading={<>Stripe payment setup </>}
      secondaryHeading={
        !data?.isOnboardingProcessCompleted
          ? 'Complete the Stripe Onboarding Process to successfully integerate Stripe Payments to your fronts.ai account.'
          : accountStatus?.includes('progress')
          ? accountStatus
          : accountStatus
      }
    >
      <Box position={'relative'}>
        <IfElse
          condition={!data?.isOnboardingProcessCompleted}
          alternative={<StripeDashboardBtn />}
        >
          <StripeOnBoardingProcessBtn />
        </IfElse>
      </Box>
    </UserSettingsSection>
  );
};

export default StripeOnboardingIntegration;
