import { Box, SxProps } from '@mui/material';
import useHandleImgOnError from 'hooks/useHandleImgOnError';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const LayoutProfileImg = ({ sx }: { sx?: SxProps }) => {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const {handleImageError, showImage} = useHandleImgOnError();
  const height = cachedLoggedInUser?.profileStyle?.image?.height;
  const objectFit = cachedLoggedInUser?.profileStyle?.image?.objectFit || 'contain';
  const borderRadius = cachedLoggedInUser?.profileStyle?.image?.borderRadius || 0;
  const clipPath = cachedLoggedInUser?.profileStyle?.image?.clipPath || 'none';
  const img = cachedLoggedInUser?.img;
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if(!showImage) return <></>;

  return (    
    <Box
      sx={{
        width: isSafari ? 'auto': '100%',
        verticalAlign: 'middle',
        height: height,
        objectFit,
        borderRadius: borderRadius + ' !important',
        clipPath,
        maxHeight: 350,        
        ...sx,
      }}
      maxWidth={{ xs: '100%', md: '100%' }}
      component='img'
      src={img}
      onError={handleImageError}
    />
  );
};

export default LayoutProfileImg;
