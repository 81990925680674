import serverApi from 'lib/apiConfig';
import uploadImageService from 'lib/services/uploadImageService';

export const DEFAULT_WEBSITE_ICON =
  'https://res.cloudinary.com/salesfronts/image/upload/v1707369353/rstfw6vkwtk1zla1ikwb.png';

/**
 * Fetches the favicon for a given URL.
 *
 * @param {string} newLink - The URL for which the favicon needs to be fetched.
 * @returns {Promise<string>} A Promise that resolves to the URL of the fetched favicon as a Blob URL.
 * @throws {Error} If there is an error during the fetching process.
 *
 * @example
 * Example usage:
 * try {
 *   const faviconUrl = await fetchFavicon('https://example.com');
 *   console.log('Fetched favicon URL:', faviconUrl);
 * } catch (error) {
 *   console.error('Error fetching favicon:', error);
 * }
 */
export async function fetchFavicon(newLink: string, id?: string): Promise<string> {
  try {
    const response = await serverApi.public.get(`/fetch-favicon?url=${newLink}`, {
      responseType: 'arraybuffer',
    });
    const blob = new Blob([response.data], { type: 'image/png' });

    if (id) {
      const uploadedImgURL = await uploadImageService.uploadImageWithAuthentication(blob);
      return uploadedImgURL;
    } else {
      const uploadedImgURL = await uploadImageService.uploadImageWithoutAuthentication(blob);
      return uploadedImgURL;
    }
  } catch (error) {
    process.env.NODE_ENV !== 'production' && console.error('Error fetching link icon URL:', error);
    return DEFAULT_WEBSITE_ICON;
  }
}
