import React from 'react';
import routeConstants from 'constants/routeConstants';
import { Box, Button, Container, Stack, Typography } from '@mui/material';

const homeRoute = routeConstants.HOME_ROUTE;

type Props = {
  children: JSX.Element | JSX.Element[];
};

type State = {
  isError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    return { isError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error: ', error.message);
    console.error('Error info: ', errorInfo);
    console.error('componet stack: ', errorInfo.componentStack);
  }

  render(): React.ReactNode {
    if (this.state.isError) {
      return <Error />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

export function Error() {
  return (
    <Box component='section'>
      <Container
        maxWidth='md'
        sx={{
          my: { xs: '5rem', md: '10rem' },
          py: { xs: 2, md: 4 },
          border: { xs: 'none', md: '1px solid lightgrey' },
          borderRadius: 2,
        }}
      >
        <Stack spacing={2}>
          <Stack direction='row' alignItems='center' spacing={4}>
            <Box
              component='img'
              src='https://res.cloudinary.com/salesfronts/image/upload/v1677311661/ezgif.com-resize_cwhygs.gif'
              width={100}
              height={100}
            />

            <Typography
              component='h4'
              sx={{ fontSize: { xs: '1.8rem', md: '2rem' }, fontWeight: 700 }}
            >
              Error 404.&nbsp; Oops you've have encountered an error
            </Typography>
          </Stack>
          <Typography component='p'>
            It appears that either something went wrong or the page doesn't exist.
            <br />
            Please try to refresh the page and if this happened to your site, please email{' '}
            <a href={`mailto:contact@fronts.ai`}>contact@fronts.ai</a>
            &nbsp; with your website address and we will try to solve it as soon as we
            can.
          </Typography>
          <Box textAlign='center'>
            <Button
              sx={{
                fontSize: { xs: '1.8rem', md: '1.8rem' },
                margin: 'auto',
                backgroundColor: '#b629ed',
                color: '#fff',
              }}
              variant='contained'
              href={homeRoute}
            >
              {/* b629ed */}
              &nbsp;Go to fronts.ai &nbsp;
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
