import { createContext, useState } from 'react';
import { Session } from 'types/sessions';
import GuestSession from 'views/guestSession/GuestSession/GuestSession';

export type ValidationState = {
  // isLoggedIn: boolean;
  sessionCancellationStatus: Session['status'];
};

type GuestSessionValidationContextType = {
  validationState: ValidationState;
  updateValidationState: React.Dispatch<React.SetStateAction<ValidationState>>;
  isSessionCancelled: boolean;
  isSessionRejected: boolean;
};

export const GuestSessionValidationContext =
  createContext<GuestSessionValidationContextType | null>(null);

const GuestSessionValidationContextProvider = () => {
  const [validationState, updateValidationState] = useState<ValidationState>({
    // isLoggedIn: false,
    sessionCancellationStatus: 'idle',
  });

  return (
    <GuestSessionValidationContext.Provider
      value={{
        validationState,
        updateValidationState,
        isSessionCancelled: validationState.sessionCancellationStatus === 'cancelled',
        isSessionRejected: validationState.sessionCancellationStatus === 'rejected',
      }}
    >
      {/* <ValidateGuestSession /> */}
      <GuestSession />
    </GuestSessionValidationContext.Provider>
  );
};

export default GuestSessionValidationContextProvider;
