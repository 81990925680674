import { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useFormikContext } from 'formik';

import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import routeConstants from 'constants/routeConstants';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import LayoutContainer from 'components/Containers/LayoutContainer';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { useAppDispatch } from 'hooks/redux';
import { resetLoggedinUserSlice } from 'store/features/user/userSlice';
import { gradientBgColor } from 'themes/AppThemeProvider/light';
import { stepperPadding } from '../stepperComponents/styled-components/Frame';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  getFieldsToSave,
  saveFilledDetailsAndStepDetails,
} from 'views/OnboardingFlow/utils/stephandler';
import { SocialLinks } from 'types/user';
import { useHistory } from 'react-router-dom';

export enum StepperLimit {
  MIN = 1,
  MAX = 4,
}
export const PREVIOUS_ROUTE_ONBOARDING = 'previousRouteOnboarding';

const btnStyling: SxProps<Theme> = {
  fontSize: { xs: '1.5rem', md: '1.7rem' },
  px: { xs: 2, md: 3 },
  alignSelf: 'flex-start',
};

type StepperIncDecrementBtnsProps = {
  isFormFinalStep: boolean;
};

const stepNames: { [key: number]: string } = {
  1: 'bussiness-details',
  2: 'media-upload',
  3: 'user-preferences',
  4: 'social-links',
};

export const stepToFieldMap: Record<number, Array<keyof StepperState>> = {
  1: ['pendingUrl', 'name'],
  2: ['occupation', 'profileImgFile'],
  3: ['timeZone', 'userGMTOffset'],
};

const StepperIncDecrementBtns = ({ isFormFinalStep }: StepperIncDecrementBtnsProps) => {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const previousRoute = sessionStorage.getItem(PREVIOUS_ROUTE_ONBOARDING);

  const {
    values,
    validateForm,
    setErrors,
    setFieldTouched,
    setSubmitting,
    setValues,
    submitForm,
  } = useFormikContext<StepperState>();

  const step = values.step;

  const getSteps = useMemo(() => {
    const steps = {
      next: step + 1,
      back: step - 1,
    };
    return steps;
  }, [step]);

  if (!cachedLoggedInUser) {
    console.error('cachedLoggedInUser is not available');
    return <></>;
  }

  const increaseSteps = async () => {
    setValues((prevValues) => ({
      ...prevValues,
      isNextLoading: true,
      slideDirection: 'left',
    }));

    if (isFormFinalStep) {
      setSubmitting(true);
    }

    const fields = stepToFieldMap[step];
    const errors = await validateForm();
    const hasValidationErrors = fields ? fields.some((field) => errors[field]) : false;

    if (hasValidationErrors) {
      fields.forEach((field) => setFieldTouched(field, true, true));
      setValues((prevValues) => ({ ...prevValues, isNextLoading: false }));
      return;
    }

    let fieldValues = await getFieldsToSave(values, setValues, cachedLoggedInUser);

    setErrors({});

    if (step === 4) {
      const socialLinksInString = localStorage.getItem('socialLinks');
      if (socialLinksInString) {
        const socialLinks: SocialLinks[] = JSON.parse(socialLinksInString);
        fieldValues = { socialLinks };
      }
    }

    await saveFilledDetailsAndStepDetails({
      formDetails: fieldValues as unknown as StepperState,
      stepDetails: { stepName: stepNames[step], stepCount: step },
    });

    if (isFormFinalStep) {
      await submitForm();
      setValues((prevValues) => ({
        ...prevValues,
        isNextLoading: false,
      }));
      // clear the localstorage
      localStorage.removeItem('socialLinks');
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        isNextLoading: false,
        step: getSteps.next ?? 1,
      }));
    }
  };

  const previousSteps = () => {
    if (!getSteps.back) {
      if (previousRoute === routeConstants.SIGNUP_ROUTE) {
        loginTokenUtils.removeLocalStorageLoginToken();
        dispatch(resetLoggedinUserSlice());
        window.location.href = routeConstants.SIGNUP_ROUTE;
      } else if (previousRoute === '/select-login-account') {
        history.replace({
          pathname: '/select-login-account',
          state: {
            isFromVerifiedMagicLoginLink: true,
            email: cachedLoggedInUser.email,
          },
        });
      } else if (!getSteps.back && previousRoute) {
        history.push(previousRoute);
        sessionStorage.removeItem(PREVIOUS_ROUTE_ONBOARDING);
      } else {
        loginTokenUtils.removeLocalStorageLoginToken();
        dispatch(resetLoggedinUserSlice());
        history.push(routeConstants.SIGNUP_ROUTE);
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        slideDirection: 'right',
        step: getSteps.back ?? 1,
      }));
    }
  };

  const getNextButtonText = () => {
    if (isFormFinalStep) return 'Finish';
    return values.isNextLoading ? 'Validating...' : 'Next';
  };

  const NextIcon = () => {
    if (values.isNextLoading) return <CircularProgress size={20} color='inherit' />;
    return isFormFinalStep ? null : <ArrowForward />;
  };

  return (
    <Box
      sx={{
        background: gradientBgColor,
        width: '100%',
        boxShadow: 2,
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <LayoutContainer
        sx={{
          mx: 'auto',
          maxWidth: 'none !important',
          flexGrow: 1,
          py: 1,
        }}
      >
        <Box sx={stepperPadding}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            sx={{ gap: { xs: 1, md: 2 } }}
          >
            <ResponsiveButton
              color='secondary'
              variant='outlined'
              size={isPhone ? 'small' : 'large'}
              startIcon={<ArrowBack />}
              sx={{ ...btnStyling, boxShadow: 'none', order: { xs: 2, md: 1 } }}
              onClick={previousSteps}
              disabled={values.isNextLoading}
            >
              Back
            </ResponsiveButton>
            <ResponsiveButton
              variant='contained'
              color='primary'
              size={isPhone ? 'small' : 'large'}
              disabled={values.isNextLoading}
              sx={{ ...btnStyling, boxShadow: 'none', order: { xs: 4, md: 4 } }}
              onClick={increaseSteps}
              endIcon={<NextIcon />}
            >
              {getNextButtonText()}
            </ResponsiveButton>
          </Stack>
        </Box>
      </LayoutContainer>
    </Box>
  );
};

export default StepperIncDecrementBtns;
