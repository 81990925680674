import uploadImageService from 'lib/services/uploadImageService';
import { EditUserDetailsForm, ProfileDeletedImgs, ProfileFilesType } from './types';

async function uploadProfileMultipleFile(values: EditUserDetailsForm) {
  // const startTime = new Date().getTime();
  const { userBgImage, userDashboardImg, userFavIcon } = values.files as ProfileFilesType;
  const promises = [];

  const urls = {
    userBgImage: values.userBgImage,
    userDashboardImg: values.userDashboardImg,
    userFavIcon: values.userFavIcon,
  };

  if (userBgImage) {
    promises.push(
      uploadImageService
        .uploadImageWithAuthentication(userBgImage)
        .then((userBgImageURL) => {
          if (userBgImageURL) {
            urls.userBgImage = userBgImageURL;
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }

  if (userDashboardImg) {
    promises.push(
      uploadImageService
        .uploadImageWithAuthentication(userDashboardImg)
        .then((userDashboardImgURL) => {
          if (userDashboardImgURL) {
            urls.userDashboardImg = userDashboardImgURL;
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );
  }

  if (userFavIcon) {
    promises.push(
      uploadImageService.uploadImageWithAuthentication(userFavIcon).then((userFavIconURL) => {
        if (userFavIconURL) {
          urls.userFavIcon = userFavIconURL;
        }
      })
    );
  }

  await Promise.allSettled(promises);
  return urls;
}

async function deleteProfileMutltipleImgs(urls: Partial<ProfileDeletedImgs>) {
  const { userBgImage, userDashboardImg, userFavIcon } = urls;
  const deletedUrls = {
    userBgImage,
    userDashboardImg,
    userFavIcon,
  };

  const promises = [
    userBgImage ? uploadImageService.deleteImg(userBgImage) : null,
    userDashboardImg ? uploadImageService.deleteImg(userDashboardImg) : null,
    userFavIcon ? uploadImageService.deleteImg(userFavIcon) : null,
  ];

  await Promise.allSettled(promises);

  if(deletedUrls.userBgImage && deletedUrls.userBgImage.includes('http')) {
    deletedUrls.userBgImage = '';
  } 
  if(deletedUrls.userDashboardImg && deletedUrls.userDashboardImg.includes('http')) {
    deletedUrls.userDashboardImg = '';
  }
  if(deletedUrls.userFavIcon && deletedUrls.userFavIcon.includes('http')) {
    deletedUrls.userFavIcon = '';
  }
  return deletedUrls;
}

const EUDUtilsFns = {
  uploadProfileMultipleFile,
  deleteProfileMutltipleImgs,
};

export default EUDUtilsFns;
