import { Box, CircularProgress } from '@mui/material';

import IfElse from 'components/utilities/IfElse';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';

import useStripeCustomerPortalSession from './hooks/useStripeCustomerPortalSession';

// NOTE: this button is being used in StripePlatformCustomerPortalSession.tsx & PremiumThemeSubscriptionStatusAction.tsx
const CreateCustomerPortalSessionBtn = () => {  
  const {
    createStripeCustomerPortalSession,
    createPortalStatus: { isCreatePortalStatusLoading },
  } = useStripeCustomerPortalSession();

  return (
    <ResponsiveButton
      onClick={createStripeCustomerPortalSession}
      disabled={isCreatePortalStatusLoading}
      variant='contained'      
      sx={{
        display: 'inline-block',        
        alignSelf: 'flex-start',
      }}
    >
      <IfElse
        condition={isCreatePortalStatusLoading}
        alternative={
          <Box
            component='span'
            sx={{
              textTransform: 'none',
            }}
          >
            See your plans
          </Box>
        }
      >
        <CircularProgress
          size={17}
          sx={{
            color: 'common.white',
          }}
        />
      </IfElse>
    </ResponsiveButton>
  );
};

export default CreateCustomerPortalSessionBtn;
