import React from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  Stack,
  SelectChangeEvent,
} from '@mui/material';
import { City, State, Country } from 'country-state-city';
import CloseIcon from '@mui/icons-material/Close';
import { alpha, useTheme } from '@mui/material/styles';
import { inputBgColor } from 'themes/AppThemeProvider/light';

import StyledItemLabel from 'components/shared/StyledItemLabel';
import useEUDAddressInput from './useEUDAddressInput';
import { GoogleSuggestions } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';

type EUDAddressInputProps = {
  label: string;
  name: string;
  borderColor?: string;
  textColor?: string;
};

const customTextFieldPadding = {
  xs: '0.8rem 1rem',
  sm: '0.9rem 1rem',
  md: '1rem 1.25rem',
};

const EUDAddressInput: React.FC<EUDAddressInputProps> = ({ 
  label, 
  name, 
  borderColor,
  textColor 
}) => {
  const theme = useTheme();
  const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

  const {
    locationValue,
    suggestions,
    input: { isInputInFocus, setIsInputInFocus },
    region,
    localInputState,
    elementRef,
    handleSuggestionSelect,
    handleInputChange,
    handleCountryChange,
    handleStateChange,
    handleCityChange,
    handleClearInputBtnClick,
  } = useEUDAddressInput(name);

  const commonInputStyles = {
    '& .MuiOutlinedInput-root': {
      background: `${inputBgColor} !important`,
      color: textColor ? `${textColor} !important` : undefined,
      borderColor: borderColor || '#cbd6e2',
      fontWeight: 600,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: borderColor || 'inherit',
      },
      '& .MuiInputBase-input': {
        fontSize: '1.6rem',
        padding: { xs: customTextFieldPadding.xs, md: customTextFieldPadding.md },
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
      boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
    },
  };

  return (
    <>
      <Box ref={elementRef} onFocus={() => setIsInputInFocus(true)} sx={{ position: 'relative' }}>
        <StyledItemLabel label={label} />
        <TextField
          name="address"
          value={localInputState || locationValue}
          fullWidth
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClearInputBtnClick} edge="end">
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={commonInputStyles}
        />
        <GoogleSuggestions
          suggestions={suggestions}
          handleSuggestionSelect={handleSuggestionSelect}
          isInputInFocus={isInputInFocus}
          sx={{ position: 'relative' }}
        />
      </Box>

      <Stack direction="row" gap={2} mt={2} flexWrap="wrap">
        <FormControl sx={{ minWidth: '150px' }}>
          <StyledItemLabel label="Country" />
          <Select
            value={region.country?.isoCode || ''}
            onChange={handleCountryChange as (event: SelectChangeEvent<string>) => void}
            name="country"
            sx={commonInputStyles}
          >
            {Country.getAllCountries().map((country) => (
              <MenuItem key={country.isoCode} value={country.isoCode}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: '150px' }}>
          <StyledItemLabel label="State" />
          <Select
            onChange={handleStateChange as (event: SelectChangeEvent<string>) => void}
            value={region.state?.isoCode || ''}
            name="state"
            sx={commonInputStyles}
          >
            {State.getStatesOfCountry(region.country?.isoCode).map((state) => (
              <MenuItem key={state.isoCode} value={state.isoCode}>
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: '150px' }}>
          <StyledItemLabel label="City" />
          <Select
            onChange={handleCityChange as (event: SelectChangeEvent<string>) => void}
            name="city"
            value={region.city || ''}
            sx={commonInputStyles}
          >
            {City.getCitiesOfState(region.country?.isoCode || '', region.state?.isoCode || '').map(
              (city) => (
                <MenuItem key={city.name} value={city.name}>
                  {city.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
};

export default EUDAddressInput;