import { Box, SxProps } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

import ImageIcon from '@mui/icons-material/Image';

import ThrottledButton from 'components/Buttons/ThrottledButton';

type UploadBtnProps = {
  handleImgInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  btnStyle: SxProps;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  wrapperStyle?: SxProps;
  isLoading?: boolean;
};

const UploadBtn = ({
  handleImgInput,
  isLoading,
  btnStyle,
  inputProps,
  wrapperStyle = {},
}: UploadBtnProps) => {
  const mergeStyle = { ...clearBtnStyle, ...btnStyle };
  return (
    <Box sx={wrapperStyle} className='image-upload-btn'>
      <ThrottledButton
        className='throttle-upload-btn'
        delay={1000}
        sx={mergeStyle}
        variant='outlined'
        color='inherit'
      >
        <ImageIcon className='upload-image-icon' color='inherit' fontSize='large' />
      </ThrottledButton>
      <input
        disabled={isLoading}
        type='file'
        className='cursor-pointer'
        style={{
          inset: 0,
          opacity: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          border: '1px solid red',
          width: '100%',
          cursor: 'pointer',
        }}
        {...inputProps}
        onChange={handleImgInput}
      />
    </Box>
  );
};

export default UploadBtn;

const clearBtnStyle: SxProps = {
  cursor: 'none',
  pointerEvents: 'none',
  userSelect: 'none',
  display: 'grid',
  placeItems: 'center',
  border: `2px dashed ${grey[400]}`,
  borderRadius: 2,
  overflow: 'hidden',
  '&:hover': {
    border: `2px dashed ${grey[600]}`,
  },
  width: {
    xs: '100%',
    sm: '100%',
  },
  height: {
    xs: '100%',
    sm: '100%',
  },
};
