import React from 'react';
import { Box, Typography, Switch, styled } from '@mui/material';
import { useFormikContext } from 'formik';
import { alpha, useTheme } from '@mui/material/styles';

import { EditUserDetailsForm } from '../types';
import StyledSwitch from 'components/ShareComponents/StyledSwitch';

type EUDEmailSwitchProps = {  
  name: keyof EditUserDetailsForm;
};

const EUDEmailSwitch: React.FC<EUDEmailSwitchProps> = ({ name }) => {
  const { setFieldTouched, getFieldProps } = useFormikContext<EditUserDetailsForm>();
  const { value, onChange } = getFieldProps(name);
  const theme = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(name, true, true);
    onChange({
      target: {
        name: name,
        value: e.target.checked,
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: '4px 0' }}>
      <StyledSwitch checked={value} onChange={handleChange} />
      <Box
        sx={{
          padding: '4px 12px',
          borderRadius: '16px',
          backgroundColor: value ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.grey[400], 0.1),
          color: value ? theme.palette.primary.main : theme.palette.text.secondary,
          fontSize: '1.4rem',
          fontWeight: 600,
          transition: 'all 0.3s ease',
        }}
      >
        {value ? 'Visible to everyone' : 'Hidden'}
      </Box>
    </Box>
  );
};

export default EUDEmailSwitch;