import React, { useEffect, useRef, useState } from 'react';
import TextFieldSyncWithTheme from './TextFieldSyncWithTheme';
import { Stack } from '@mui/material';

const OtpInput = ({
  length = 6,
  getOtp,
  resetOtp,
}: {
  length: number;
  getOtp: (val: string) => void;
  resetOtp: boolean;
}) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputs = useRef<HTMLDivElement[]>([]);
  const otpRef = useRef<string>('');

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text/plain');
    const pasteValues = pasteData.split('').filter((char) => char.match(/^\d$/));
    const newOtp = [...otp];

    for (let i = 0; i < length; i++) {
      if (pasteValues[i]) {
        newOtp[i] = pasteValues[i];
      } else {
        break;
      }
    }

    setOtp(newOtp);
  };

  const handleInputFocus = (index: number) => {
    inputs.current[index].focus();
  };

  useEffect(() => {
    const inputsRef = inputs.current;

    // Define named functions for event listeners
    const handlePasteEvent = (e: any) => handlePaste(e);
    const handleFocusEvent = (index: number) => () => handleInputFocus(index);

    if (inputsRef) {
      inputsRef.forEach((input, index) => {
        input.addEventListener('paste', (e: any) => handlePasteEvent(e));
        input.addEventListener('focus', () => handleFocusEvent(index));
      });

      return () => {
        inputsRef.forEach((input, index) => {
          if (!input) return;
          input.removeEventListener('paste', (e: any) => handlePasteEvent(e));
          input.removeEventListener('focus', () => handleFocusEvent(index));
        });
      };
    }
  }, []);

  // send back otp to parent component
  useEffect(() => {
    if (otp.join('') === otpRef.current) return;
    getOtp(otp.join(''));
    otpRef.current = otp.join('');
  }, [otp, getOtp]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { value } = e.target;

    // clear if backspace is pressed
    if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }

    // Only allow single digit input
    if (value.match(/^\d$/)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input
      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
    }

    // Move focus to previous input on backspace
    if (value === '' && index > 0) {
      inputs.current[index - 1].focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.key === 'Backspace' && otp[index] === '') {
      // Move focus to previous input on backspace if current input is empty
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  return (
    <Stack direction='row' gap={1}>
      {otp.map((_, i) => {
        return (
          <TextFieldSyncWithTheme
            autoComplete='one-time-code'
            key={i}
            variant='outlined'
            value={otp[i]}
            onFocus={() => handleInputFocus(i)}
            onChange={(e) => handleChange(e, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
            ref={(el) => {
              (inputs.current[i] as any) = el;
            }}
            sx={{
              width: { xs: '5ch', md: '4ch' },
            }}
            inputProps={{
              maxLength: 1,
            }}
            fullWidth
          />
        );
      })}
    </Stack>
  );
};

export default OtpInput;
