import React from 'react';
import { Box, Container, Stack, SxProps, Theme } from '@mui/material';

import ResponsiveButton from './ResponsiveButton';
import SPMFormConfirmationDialogBox from 'components/SPM_Forms/reusable-form-components/SPMFormConfirmationDialogBox';

const btnStyling: SxProps<Theme> = {
  px: (theme) => theme.spacing(3),
  fontSize: { xs: '1.8rem', md: '2rem' },
};

const buttonWrapper: SxProps<Theme> = {
  my: 0,
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
  backgroundColor: 'common.white',  
  py: '1.25rem !important',
  px: { xs: 2, md: '2.5rem' },
};

type SaveCancelButtonProps = {
  cancelBtnProps?: React.ComponentProps<typeof ResponsiveButton>;
  cancelBtnChildren?: React.ReactNode;
  saveBtnChildren?: React.ReactNode;
  saveBtnProps?: React.ComponentProps<typeof ResponsiveButton>;

  buttonWrapperCustomStyle?: SxProps<Theme>;

  dialogTitle?: React.ReactNode;
  dialogChildren?: React.ReactNode;

  showLostChangesDlg: boolean;
  openConfirmDiscardChangesPopup: () => void;

  goBack: () => void;
};

const SaveCancelButtons = ({
  cancelBtnChildren,
  cancelBtnProps,

  saveBtnChildren,
  saveBtnProps,

  buttonWrapperCustomStyle = {},

  showLostChangesDlg,
  openConfirmDiscardChangesPopup,

  goBack,

  dialogTitle = 'You have unsaved changes!',
  dialogChildren = (
    <>
      If you close this dialog, you will lose all the changes you made.
      <br />
      <br />
      Are you sure you want to close?
    </>
  ),
}: SaveCancelButtonProps) => {

  const style = {...buttonWrapper,...buttonWrapperCustomStyle}

  return (
    <>
      <Box sx={style}>
        <Container maxWidth='xl' sx={{ p: { xs: '0', md: '0 2rem' } }}>
          <Stack spacing={3} justifyContent='flex-end' alignItems='center' direction='row'>
            <ResponsiveButton
              size='large'
              variant='text'
              color='secondary'
              {...cancelBtnProps}
              sx={{
                ...btnStyling,
              }}
            >
              {cancelBtnChildren}
            </ResponsiveButton>
            <ResponsiveButton
              size='large'
              {...saveBtnProps}
              variant='contained'
              color='primary'
              sx={{
                ...btnStyling,
              }}
            >
              {saveBtnChildren}
            </ResponsiveButton>
          </Stack>
        </Container>
      </Box>
      {/* ----- leave unsaved changes confirmation  */}
      <SPMFormConfirmationDialogBox
        open={showLostChangesDlg}
        onAgreeBtnClick={goBack}
        onDisagreeBtnClick={openConfirmDiscardChangesPopup}
        contentText={dialogChildren}
        title={dialogTitle}
      />
    </>
  );
};

export default SaveCancelButtons;
