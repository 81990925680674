import { useAppDispatch } from 'hooks/redux';
import userServices from 'lib/services/userServices';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { readEnv } from 'utilities/generalUtilities';
import getNodeEnv from 'utilities/getNodeEnv';
import { stringify } from 'query-string';

const useFacebookLogin = () => {
  const isEnvProduction = getNodeEnv();
  
  const fbAppId = readEnv("REACT_APP_PRODUCTION_FB_APP_ID");

  const redirect_uri = isEnvProduction
    ? readEnv("REACT_APP_PRODUCTION_APP_URL")
    : readEnv("REACT_APP_DEVELOPMENT_APP_URL");

  const stringifiedParams = stringify({
    display: 'popup',
    auth_type: 'rerequest',
    response_type: 'code',
    redirect_uri: `${redirect_uri}/oauth/facebook/callback`, // should be same as backend as in fbSignupUtils.js > getFbAccessTokenFromCode > redirect_uri
    scope: ['email'].join(','),
    client_id: fbAppId,
    state: 'FB_LOGIN',
  });
  
  const [login, setLogin] = useState(false);
  const storeDispatch = useAppDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fbParamState = searchParams.get('state') ?? '';
  const fbAccessCode = searchParams.get('code') ?? '';
  const isFbLogin = fbParamState === 'FB_LOGIN';

  useEffect(() => {
    if (isFbLogin && fbAccessCode) {
      (async () => {
        try {
          await userServices.getFbProfileData(fbAccessCode);
        } catch (error) {
          setLogin(false);
        }
      })();
    }
  }, [fbAccessCode, isFbLogin, storeDispatch]);

  const facebookLoginUrl = `https://www.facebook.com/v4.0/dialog/oauth?${stringifiedParams}`;


  return {
    facebookLoginUrl,
    login,
    setLogin,
  };
};

export default useFacebookLogin;
