import { Box, CircularProgress, Stack, DialogProps, useMediaQuery, ButtonProps } from '@mui/material';

import IfElse from '../components/utilities/IfElse';
import Popup from 'components/utilities/Popups/Popup';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';

type ConfirmationDialogProps = {
  title: React.ReactNode;
  content?: React.ReactNode;
  isLoading: boolean;
  open: boolean;
  handleDialogClose: () => void;
  handleAgreeBtnClick: () => void;
  ariaDescribedby?: string;
  confirmText?: string;
  cancelText?: string;
  dialogProps?: DialogProps;
  agreeBtnProps?: ButtonProps;
};

function ConfirmationDialog({
  title,
  content,
  isLoading,
  open,
  ariaDescribedby,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  dialogProps,
  handleDialogClose,
  handleAgreeBtnClick,
  agreeBtnProps,
}: ConfirmationDialogProps) {

  const isPhone = useMediaQuery('(max-width: 600px)');

  return (
    <Popup
      setOpen={handleDialogClose}
      dialogProps={{
        fullWidth: false,
        open,
        'aria-describedby': ariaDescribedby,
        ...dialogProps,
      }}
      titleProps={{
        component: isPhone ? 'h5' : 'h2',
        variant: isPhone ? 'h5' : 'h2',
      }}
      title={title}
      content={content}
      dialogActions={
        <>
          <ResponsiveButton color='secondary' onClick={handleDialogClose}>
            {cancelText}
          </ResponsiveButton>
          <ResponsiveButton
            disabled={isLoading}
            color='error'
            variant='contained'
            onClick={handleAgreeBtnClick}
            {...agreeBtnProps}
          >
            <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={1.5}>
              <Box component='span'>{confirmText}</Box>
              <IfElse condition={isLoading}>
                <CircularProgress
                  size={15}
                  sx={{
                    color: 'common.white',
                  }}
                />
              </IfElse>
            </Stack>
          </ResponsiveButton>
        </>
      }
    />
  );
}

export default ConfirmationDialog;
