import { Fade } from '@mui/material';
import { red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

type ThisProps = {
  errorText: string;
  isVisible: boolean;
  textAlign?: 'left' | 'right' | 'center';
  marginTop?: number | string;
};

const ErrorText = ({
  isVisible,
  errorText,
  marginTop = 0.6,
  textAlign = 'left',
}: ThisProps) => {
  if (!isVisible || !errorText) return <></>;
  return (
    <Fade in timeout={800}>
      <Typography
        textAlign={textAlign}
        component='p'
        sx={{
          mt: marginTop,
          color: red[600],
          '&.MuiTypography-root': {
            fontSize: '1.5rem',
          },
        }}
      >
        {errorText}
      </Typography>
    </Fade>
  );
};

export default ErrorText;
