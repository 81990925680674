import React, { useEffect } from 'react';
import { Box, SxProps, Tab, Tabs } from '@mui/material';
import { isBefore } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { toast } from 'react-toastify';

import { HeadCell } from 'components/Table/CustomTableHead';
import MeetingSessionsTable from './MeetingSessionsTable';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';

import { useGetSessionsByUserIdQuery } from 'store/rtkqFeatures/api/SessionExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

import { Session } from 'types/sessions';
import { LoggedInUser } from 'types/user';
import { EmptyTabPanel } from 'components/dashboard/DashboardReusableComponent';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface FilteredSessions {
  upcoming: Session[];
  completed: Session[];
  cancelled: Session[];
  expired: Session[];
}

export const meetingManagmentheadCells: readonly HeadCell[] = [
  {
    id: 'status',
    label: 'Status',
    numeric: false,
  },
  {
    id: 'meetingDetails.title',
    label: 'Meeting Title',
    numeric: false,
  },
  {
    id: 'created',
    label: 'Created at',
    numeric: true,
  },
  {
    id: 'startAt',
    label: 'Start at',
    numeric: true,
  },
  {
    id: 'isPaid',
    label: 'Paid',
    numeric: false,
  },
  {
    id: 'type',
    label: 'Meeting Type',
    numeric: false,
  },
  {
    id: 'details',
    label: 'Details',
    numeric: false,
  },
];

function getFilteredData(data: Session[], loggedInUserTimeZone: LoggedInUser['timeZone']): FilteredSessions {
  const upcoming: Session[] = [];
  const completed: Session[] = [];
  const cancelled: Session[] = [];
  const expired: Session[] = [];

  data.forEach((session, i) => {
    const startDuration = utcToZonedTime(session.startAt, loggedInUserTimeZone);
    const curentTime = utcToZonedTime(new Date(), loggedInUserTimeZone);

    // & just for testing purpose
    /*if (i === 0) {
      console.log('+++++++++++++++++++++++++++++++');
      // console.log('xx00 ', loggedInUserTimeZone);
      // console.log('xx01 ', format(startDuration, 'dd-MMMM-yyyy hh:mma'));
      // console.log('xx02 ', format(session.startAt, 'dd-MMMM-yyyy hh:mma'));
      // console.log('xx03 ', format(new Date(), 'dd-MMMM-yyyy hh:mma'));
      console.log('xx04 ', utcToZonedTime(new Date(), loggedInUserTimeZone).valueOf());
      console.log('xx05 ', utcToZonedTime(session.startAt, loggedInUserTimeZone).valueOf());
      console.log('xx06 ', utcToZonedTime(new Date(), loggedInUserTimeZone));
      console.log('xx07 ', utcToZonedTime(session.startAt, loggedInUserTimeZone));
      console.log('xx08 ', isBefore(curentTime, startDuration));
      console.log('+++++++++++++++++++++++++++++++');
    }*/

    if (isBefore(curentTime, startDuration)) {
      // upcoming
      upcoming.push(session);
    }
    if (isBefore(startDuration, curentTime) && session.status !== 'confirmed' && session.status !== 'rejected' && session.status !== 'cancelled') {
      // expired
      expired.push(session);
    }
    if (session.status === 'cancelled' || session.status === 'rejected') {
      // cancelled
      cancelled.push(session);
    }
    if (session.status === 'confirmed') {
      // completed
      completed.push(session);
    }
  });

  return { upcoming, completed, cancelled, expired };
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`meeting-tabpanel-${index}`} aria-labelledby={`meeting-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

const MeetingManagment = () => {
  const [value, setValue] = React.useState(0);
  const [filteredSessions, setFilteredSessions] = React.useState<FilteredSessions>({
    upcoming: [],
    completed: [],
    cancelled: [],
    expired: [],
  });
  const { userIdInReduxUserSlice: editorId, cachedLoggedInUser } = useGetLoggedInUser();
  const {
    data: fetchedUserSessions,
    isLoading,
    isError,
  } = useGetSessionsByUserIdQuery(editorId, {
    skip: !editorId,
    pollingInterval: 180_000, // 3 mins
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  if (isError) {
    toast.error('Something went wrong! Please try again later.');
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (fetchedUserSessions) {
      setFilteredSessions(getFilteredData(fetchedUserSessions, cachedLoggedInUser?.timeZone ?? 'UTC'));
    }
  }, [fetchedUserSessions, cachedLoggedInUser?.timeZone]);

  return (
    <Box position={'relative'}>
      <SectionLoader isLoading={isLoading} />
      <Box
        sx={{
          mr: 'auto',
          borderBottom: 1,
          borderColor: 'divider',
          justifyContent: { xs: 'space-between', md: 'flex-start' },
        }}
        >
        <Tabs          
          variant='scrollable'
          scrollButtons='auto'
          value={value}
          onChange={handleChange}
          aria-label='meeting tabs'
          sx={{
            display: 'flex',
            justifyContent: { xs: 'space-between', md: 'flex-start' },
          }}          
        >
          <Tab sx={tabStyling} label='Upcoming' id='meeting-tab-0' aria-controls='meeting-tabpanel-0' />
          <Tab sx={tabStyling} label='Completed' id='meeting-tab-1' aria-controls='meeting-tabpanel-1' />
          <Tab sx={tabStyling} label='Cancelled' id='meeting-tab-2' aria-controls='meeting-tabpanel-2' />
          <Tab sx={tabStyling} label='Expired' id='meeting-tab-3' aria-controls='meeting-tabpanel-3' />
          <Tab sx={tabStyling} label='All' id='meeting-tab-4' aria-controls='meeting-tabpanel-4' />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {/* upcoming */}
        {filteredSessions.upcoming.length > 0 ? <MeetingSessionsTable tableData={filteredSessions.upcoming} />: <EmptyTabPanel />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/* Completed */}
        {filteredSessions.completed.length > 0 ? <MeetingSessionsTable tableData={filteredSessions.completed} />: <EmptyTabPanel />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {/* cancelled */}
        {filteredSessions.cancelled.length > 0 ? <MeetingSessionsTable tableData={filteredSessions.cancelled} />: <EmptyTabPanel />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {/* expired */}
        {filteredSessions.expired.length > 0 ? <MeetingSessionsTable tableData={filteredSessions.expired} />: <EmptyTabPanel />}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {fetchedUserSessions ? <MeetingSessionsTable tableData={fetchedUserSessions} />: <EmptyTabPanel />}
      </CustomTabPanel>
    </Box>
  );
};

export default MeetingManagment;

export const tabStyling: SxProps = {  
  color: 'text.primary',
  fontSize: { xs: 12, sm: 14, md: 16 },
  minWidth: { xs: 80, sm: 80, md: 150 },
  p: { xs: '12px 4px', sm: '12px 4px', md: '12px 16px' },
};
