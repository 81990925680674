import * as React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import ChooseCountryAndCurrencySelector from 'components/utilities/ChooseCountryAndCurrencySelector';
import { usePatchUpdateEditorFieldByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { LoggedInUser } from 'types/user';
import Popup from 'components/utilities/Popups/Popup';
import { subscriptionDashboardURL } from 'components/dashboard/FrontsAIPremium/FrontsAIPremium';

type ChooseStripeCountryAndCurrencyPopupProps = {
  isCountryAndCurrencyNotSelected: boolean;
  setIsCountryAndCurrencyNotSelected: React.Dispatch<React.SetStateAction<boolean>>;  
  description?: React.ReactNode;
  title: string;
  handleOnboardProcess?: (str: string, bool: boolean) => void;
  handleSubscribe?: () => void;
};

const content = (
  <>
    Please select your Stripe country to receive payments securely.
    <br />
    <strong>This choice cannot be changed later.</strong>
    <br />
  </>
);

const ChooseStripeCountryAndCurrencyPopup = ({
  isCountryAndCurrencyNotSelected,
  setIsCountryAndCurrencyNotSelected,
  description = content,
  title,
  handleOnboardProcess,
  handleSubscribe,
}: ChooseStripeCountryAndCurrencyPopupProps) => {
  const { userIdInReduxUserSlice } = useGetLoggedInUser();
  const [updateCountryAndCurrencyInUserObj] = usePatchUpdateEditorFieldByIdMutation();  
  const [stripeAccount, setStripeAccount] =
    React.useState<Pick<LoggedInUser, 'countryISOCode' | 'currency'>>();

  const handleClose = () => {
    setIsCountryAndCurrencyNotSelected(false);
  };

  const handleSubmit = (countryISOCode?: string, currency?: string) => {
    if (countryISOCode || currency) {
      setStripeAccount({ countryISOCode, currency });
    }
  };

  const handleSaveClick = async () => {
    if (!stripeAccount) {
      return;
    }
    await updateCountryAndCurrencyInUserObj({
      editorId: userIdInReduxUserSlice,
      countryISOCode: stripeAccount.countryISOCode,
      currency: stripeAccount.currency,
    });
    handleOnboardProcess?.(subscriptionDashboardURL, true);
    handleSubscribe?.();
    handleClose();
  };

  return (
    <Popup
      dialogProps={{
        fullScreen: true,
        open: isCountryAndCurrencyNotSelected,
        keepMounted: false,
      }}      
      setOpen={handleClose}
      title={title}
      content={description}
      contentProps={{ sx: { overflow: 'hidden' } }}
    >
      <ChooseCountryAndCurrencySelector
        sx={{ px: { xs: 2, md: 3 }, flexBasis: '70%' }}
        countryCode=''
        handleSubmit={handleSubmit}
      />
      <DialogActions sx={{ pb: 2, px: 3 }}>
        <Button
          variant='outlined'
          color='secondary'
          onClick={handleClose}
          sx={{ fontSize: { xs: '1.8rem', md: '2rem' }, mr: 2, px: 4 }}
        >
          close
        </Button>

        <Button
          variant='contained'
          color='primary'
          onClick={handleSaveClick}
          sx={{ fontSize: { xs: '1.8rem', md: '2rem' }, px: 4 }}
        >
          Save
        </Button>
      </DialogActions>
    </Popup>
  );
};

export default ChooseStripeCountryAndCurrencyPopup;
