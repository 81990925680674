import { SxProps, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';

type ThisProps = {
  text: string;
  sx?: SxProps<Theme>;
};

const ContentHeading = ({ text, sx }: ThisProps) => {
  return (
    <Typography
      variant='h4'
      textAlign='left'
      textTransform='capitalize'
      fontWeight={600}
      sx={{
        ...sx,
        '&.MuiTypography-h4': {
          fontSize: {
            xs: '1.6rem',
            sm: '1.8rem',
          },
        },
      }}
    >
      {text}
    </Typography>
  );
};

export default ContentHeading;
