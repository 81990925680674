import { meetinDefaults } from 'defaults/meetingDefault';
import {
  MeetingTypes,
  PhysicalMeeting,
  PhysicalMeetingHostOptedOptions,
  VirtualMeeting,
  VirtualMeetingHostOptedOptions,
  MeetingTentatitveAvailabilityOptions
} from 'types/meeting';

export const initialVirtualMeeting: VirtualMeeting = {
  _id: '',
  userId: '',
  isDeleted: false,
  type: MeetingTypes.Virtual,
  status: 'inactive',
  isGroup: false,
  title: '',
  desc: '',
  isPaid: true,
  price: 100,
  included: [],
  img: meetinDefaults.virtualMeetingImg,
  time: 45454845,
  hostGuestMeetingDetails: {
    link: '',
    meetingExtraInstructions: '',
  },
  showLinkOrLocationDetailsToGuest: false,
  hostDetails: {
    optedOption: VirtualMeetingHostOptedOptions.GUEST_PHONE_SESSION_BOOKING,
    allSessionsData: {
      virtual: 'https://www.yourlink.com',
    },
  },
  ui: {
    ctaBtnText: `Let's Meet`,
    includedItemsHeading: `What's Included:`,
    tagTitle: 'Online Meeting',
  },
  tentativeAvilabitity: MeetingTentatitveAvailabilityOptions.ALWAYS_TENTATITVELY_AVAILABLE,
  limitTentativeAvailability: false
};

export const initialPhysicalMeeting: PhysicalMeeting = {
  _id: '',
  userId: '',
  isDeleted: false,
  type: MeetingTypes.Physical,
  status: 'inactive',
  isGroup: false,
  title: '',
  desc: '',
  isPaid: true,
  price: 100,
  included: [],
  img: meetinDefaults.virtualMeetingImg,
  time: 45454845,
  hostGuestMeetingDetails: {
    link: '',
    meetingExtraInstructions: '',
  },
  showLinkOrLocationDetailsToGuest: false,
  hostDetails: {
    optedOption:
      PhysicalMeetingHostOptedOptions.HOST_LOCATION_PHONE_SESSION_SCHEDULED,
    allSessionsData: {
      physical: '3232 Street makarums',
    },
  },
  ui: {
    ctaBtnText: 'Book',
    includedItemsHeading: `What's Included:`,
    tagTitle: 'In-Person Meeting',
  },
  tentativeAvilabitity: MeetingTentatitveAvailabilityOptions.ALWAYS_TENTATITVELY_AVAILABLE,
  limitTentativeAvailability: false
};
