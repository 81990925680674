import { useCallback, useState } from 'react';
import { useFormikContext } from 'formik';
import { useScript } from 'usehooks-ts';
import {  Box } from '@mui/material';
import { City, Country, State } from 'country-state-city';

import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import GoogleLocationAutoCompleteInput, {
  AutoCompleteGuestLocationDetails,
} from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import { googlePlacesApiScriptUrl } from 'API_Subscriptions/googlePlacesApi';
import IfElse from 'components/utilities/IfElse';
import ErrorText from 'views/OnboardingFlow/sub-components/stepperComponents/reusable-components/ErrorText';
import serverApi from 'lib/apiConfig';
import { getCurrentGMTOffset } from 'utilities/timzonUtilities';
import BoldSpan from 'components/utilities/BoldSpan';
import { Stack } from '@mui/system';
import {
  Region,
  getCountryByName,
  getStateByName,
} from 'components/EditUserDetailsLatest/utility-components/EUDAddressInput/useEUDAddressInput';
import RegionSelector from 'components/RegionSelector';
import { customTextFieldPadding } from 'components/InputField/TextFieldSyncWithTheme';
import Question from './reusable-components/Question';

const InputAddress = () => {
  const googleMapsApiScriptStatus = useScript(googlePlacesApiScriptUrl, {
    removeOnUnmount: true,
  }); // loading google maps api
  const { setValues, values, touched, errors, setErrors } =
    useFormikContext<StepperState>();
  const [region, setRegion] = useState<Region>({});
  const isValidationError = Boolean(touched.address && errors.address);
  const textFieldHelperText = (isValidationError ? errors.address : '') ?? '';

  const getLocationDetails = useCallback(
    async (locationDetails: AutoCompleteGuestLocationDetails) => {
      const {
        address,
        googleMapsLocationUrl,
        location,
        placeId,
        city,
        country,
        countryISOCode,
        postalCode,
        state,
        stateISOCode,
      } = locationDetails;

      const updated_values: Partial<StepperState> = {
        address,
        country: country || '',
        state: state || '',
        city: city || '',
      };

      setErrors({});
      setValues((prev) => ({
        ...prev,
        address: locationDetails.address,
        country: locationDetails.country,
        state: locationDetails.state,
        city: locationDetails.city,
        addressComponents: {
          ...updated_values,
          googleMapsLocationUrl,
          stateISOCode,
          countryISOCode,
          postalCode,
          place_id: placeId,
          lat: location.lat,
          lng: location.lng,
        },
      }));
      setRegion({
        country: getCountryByName(locationDetails.country || ''),
        state: getStateByName(locationDetails.state || ''),
        city: locationDetails.city,
      });
    },
    [setValues, setErrors]
  );

  const handleRegionChange = (value: string, name: keyof Region) => {
    if (name === 'country') {
      const country = Country.getCountryByCode(value);
      setRegion((prev) => ({
        ...prev,
        country: country,
      }));
      setValues((prev) => ({
        ...prev,
        country: country?.name || prev.country,
      }));
    } else if (name === 'state') {
      const state = State.getStateByCode(value);
      setRegion((prev) => ({
        ...prev,
        state,
      }));
      setValues((prev) => ({
        ...prev,
        state: state?.name || prev.state,
      }));
    } else if (name === 'city') {
      setRegion((prev) => ({
        ...prev,
        city: value,
      }));
      setValues((prev) => ({
        ...prev,
        city: value,
      }));
    }
  };

  const handleClearBtnClick = () => {
    setValues((prev) => ({
      ...prev,
      address: '',
      country: '',
      state: '',
      city: '',      
      addressComponents: {},
    }));
    setRegion({});
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      handleClearBtnClick();
    }
  };

  return (
    <Box>
      <Question currentStep={3} helperText='This address will appear on the website '>
        <>  
          Enter a <BoldSpan>business address</BoldSpan>
        </>
      </Question>
      <Box
        sx={{
          mt: 2,
          '& input': {
            fontSize: '1.8rem',
            padding: customTextFieldPadding,
          },
          '& .MuiInputBase-root': {
            backgroundColor: (theme) => theme.palette.background.default,
          },
        }}
      >
        <IfElse condition={googleMapsApiScriptStatus === 'ready'}>
          <GoogleLocationAutoCompleteInput
            initialSuggestion={values.address}
            getLocationDetails={getLocationDetails}
            handleClearInputBtnClick={handleClearBtnClick}
            textFieldProps={{
              label: '',
              placeholder: 'Business address',
              color: 'primary',
              multiline: false,
            }}
            callbackOnChange={handleOnChange}
          />

          <Stack direction={'row'} gap={2} mt={2} flexWrap={'wrap'}>
            {/* ----------------- country */}
            <RegionSelector
              name='country'
              label={'Country'}
              options={Country.getAllCountries()}
              selectedValue={region.country?.isoCode || ''}
              onChange={handleRegionChange}
            />

            {/* ----------------- State */}
            <RegionSelector
              name='state'
              label={'State'}
              options={State.getStatesOfCountry(region.country?.isoCode || '')}
              selectedValue={region.state?.isoCode || ''}
              onChange={handleRegionChange}
            />

            {/* ----------------- City */}
            <RegionSelector
              name='city'
              label={'City'}
              options={City.getCitiesOfState(
                region.country?.isoCode || '',
                region.state?.isoCode || ''
              )}
              selectedValue={region.city || ''}
              onChange={handleRegionChange}
            />
          </Stack>

          <ErrorText errorText={textFieldHelperText} isVisible={isValidationError} />
        </IfElse>
      </Box>
    </Box>
  );
};

export default InputAddress;

// get timezone of location
type TimezoneResponse = {
  dstOffset: number; // 0
  rawOffset: number; // -28800
  status: string; // "OK"
  timeZoneId: string; // America/Los_Angeles
  timeZoneName: string; // "Pacific Standard Time"
};

async function getTimezoneFromLatAndLng(lat: number, lng: number) {
  try {
    const response = await serverApi.v2.get<TimezoneResponse>(
      `/google-maps-places-api/get-timezone?lat=${lat}&lng=${lng}`
    );
    // console.log('XX012 ', response.data)
    const { rawOffset, status } = response.data;
    if (status === 'OK') {
      const timezoneOffsetInHours = rawOffset / 3600; // converting rawOffset seconds to hours
      return timezoneOffsetInHours;
    }
  } catch (error) {
    if (typeof error === 'string') {
      console.error(error);
    }
  }
}


// FIXME - DELETE THIS FUNCTION
async function getUserGMTOffsetAndCurrency(
  locationDetails: AutoCompleteGuestLocationDetails
) {
  let userGMTOffset;

  try {
    // Get user's GMT offset from their latitude and longitude
    userGMTOffset = await getTimezoneFromLatAndLng(
      locationDetails.location.lat,
      locationDetails.location.lng
    );
  } catch (timezoneError) {
    console.error('An error occurred while fetching timezone:', timezoneError);
    userGMTOffset = getCurrentGMTOffset(); // Set a default value for GMT offset
  }

  if (!userGMTOffset) {
    userGMTOffset = getCurrentGMTOffset();
  }
  return { userGMTOffset };
}
