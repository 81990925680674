import { Grid } from '@mui/material';

import useSortedSPM from 'components/MUI_SPM/SPMSortDrawer/hooks/useSortedSPM';

import SPMDeletionConfirmationDialog from 'components/MUI_SPM/SPMCard/sub-components/SPMDeletionConfirmationDialog/SPMDeletionConfirmationDialog';
import useSPMDeletionConfirmationDialog from 'components/MUI_SPM/SPMCard/sub-components/SPMDeletionConfirmationDialog/hooks/useSPMDeletionConfirmationDialog';
import SPMDeletionConfirmationDialogBtns from 'components/MUI_SPM/SPMCard/sub-components/SPMDeletionConfirmationDialog/sub-components/SPMDeletionConfirmationDialogBtns';
import Meeting from 'components/MUI_SPM/Meeting/Meeting';
import Service from 'components/MUI_SPM/Service/Service';

const SortedSPM = () => {
  const { sortedSPMItems, sortedSPMItemsLength } = useSortedSPM(); // data depends upon cache
  const {
    deleteEntityDialogState,
    setDeleteEntityDialogState,
    isSPMDeletionInProgress,
    handleDeleteEntityConfirmationDialogBtnClick,
  } = useSPMDeletionConfirmationDialog();

  // Early returns
  if (!sortedSPMItemsLength) return <></>;

  return (
    <>
      <SPMDeletionConfirmationDialog open={deleteEntityDialogState.isDialogOpen}>
        <SPMDeletionConfirmationDialogBtns
          setDeleteEntityDialogState={setDeleteEntityDialogState}
          isEntityDeletionProcessInProgress={isSPMDeletionInProgress}
          handleDeleteEntityConfirmationDialogBtnClick={
            handleDeleteEntityConfirmationDialogBtnClick
          }
        />
      </SPMDeletionConfirmationDialog>
      <Grid
        marginTop={{ xs: '0px', md: '0' }}
        container
        component='ul'
        columnGap={{ xs: 4, sm: 8, md: 10 }}
        rowGap={{ xs: 4, sm: 8, md: 10 }}
        sx={{
          listStyleType: 'none',
          paddingLeft: 0,
        }}
      >
        {sortedSPMItems?.map(({ _id: entityId, userId, type }) => {
          const entityName = type.split('-')[0] as 'MEETING' | 'SERVICE';
          return {
            MEETING: (
              <Meeting
                key={entityId}
                meetingId={entityId}
                editorId={userId}
                setDeleteEntityDialogState={setDeleteEntityDialogState}
              />
            ),
            SERVICE: (
              <Service
                key={entityId}
                serviceId={entityId}
                editorId={userId}
                setDeleteEntityDialogState={setDeleteEntityDialogState}
              />
            ),
          }[entityName];
        })}
      </Grid>
    </>
  );
};

export default SortedSPM;
