import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import EditableHeadingsTextLengthValidationError from 'components/utilities/EditableHeadingsTextLengthValidationError/EditableHeadingsTextLengthValidationError';
import useEditableHeadingsTextLengthValidation from 'components/utilities/EditableHeadingsTextLengthValidationError/hooks/useEditableHeadingsTextLengthValidation';
import InPlaceActionTaker from 'components/utilities/InPlaceActionTaker/InPlaceActionTaker';
import useHtmlContentEditable from 'hooks/useHtmlContentEditable';
import React, { useRef, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { toast } from 'react-toastify';
import { useUpdatedEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import EditIcon from '@mui/icons-material/Edit';

// NOTE: Heading text updation validation: https://mui.com/material-ui/react-popover/

type AboutHeadingProps = {
  fontSize: string;
  textAlign?: any; // !! remove any
  color?: any; // !! remove any
};

const AboutHeading: React.FunctionComponent<AboutHeadingProps> = ({
  fontSize,
  textAlign = 'center',
  color = grey[700],
}) => {
  const [showInPlaceActionTaker, setShowInPlaceActionTaker] = useState(false);
  const {
    lengthValidationError,
    setLengthValidationError,
    makeErrorFalseIfError,
    MAX_HEADING_LENGTH,
  } = useEditableHeadingsTextLengthValidation();
  const inPlaceActionTakenDOMRef = useOnclickOutside(() => {
    setShowInPlaceActionTaker(false);
    makeErrorFalseIfError();
  });
  const { cachedLoggedInUser: editor, userIdInReduxUserSlice: editorId } =
    useGetLoggedInUser();
  const aboutHeadingFromCachedData = editor?.ui.aboutHeading ?? 'About';
  const [udpateEditor] = useUpdatedEditorByIdMutation();
  const aboutHeadingDomRef = useRef<HTMLHeadingElement | null>(null);
  const [handleAboutUIHeadingChange] = useHtmlContentEditable(
    aboutHeadingDomRef,
    async (changedHeading) => {
      const changedHeadingLength = changedHeading.length;
      if (changedHeadingLength > MAX_HEADING_LENGTH) {
        setLengthValidationError(true);
        return;
      } else {
        makeErrorFalseIfError();
      }
      if (changedHeading === aboutHeadingFromCachedData) {
        setShowInPlaceActionTaker(false);
        return;
      }
      if (!editor) {
        console.error('Cannot update about heading becuase editor is undefined');
        toast.error('Unable to update About heading');
        return;
      }
      // update user object
      await udpateEditor({
        editorId,
        loggedInUser: {
          ...editor,
          ui: {
            ...editor.ui,
            aboutHeading: changedHeading,
          },
        },
      }).unwrap();
      setShowInPlaceActionTaker(false);
    }
  );

  const uponConfirmHeadingChangeClick = () => {
    handleAboutUIHeadingChange();
  };

  const uponRejectHeadingChangeClick = () => {
    setShowInPlaceActionTaker(false);
    makeErrorFalseIfError();
  };
  return (
    <InPlaceActionTaker
      error={lengthValidationError}
      domRef={inPlaceActionTakenDOMRef}
      show={showInPlaceActionTaker}
      uponConfirmClick={uponConfirmHeadingChangeClick}
      uponRejectClick={uponRejectHeadingChangeClick}
    >
      <>
        <Typography
          variant='h2'
          component='h2'
          onClick={() => setShowInPlaceActionTaker(true)}
          textAlign={textAlign}
          color={color}
          fontSize={{
            xs: `calc(${fontSize} / 1.2)`,
            sm: fontSize,
          }}
          sx={{
            outline: '1px solid transparent',
            '&:hover': {
              outline: (theme) => '1px solid ' + theme.palette.text.secondary,
            },
            '&:focus': {
              outline: '1px solid transparent',
            },
          }}
          ref={aboutHeadingDomRef}
          spellCheck={false}
          suppressContentEditableWarning
          contentEditable
          autoFocus
        >
          {aboutHeadingFromCachedData}
          &nbsp;
          
          <Typography color='inherit' component='sup'>
            <EditIcon color={color} />
          </Typography>
        </Typography>

        <EditableHeadingsTextLengthValidationError isError={lengthValidationError} />
      </>
    </InPlaceActionTaker>
  );
};

export default AboutHeading;
