import axios, { AxiosError, CancelTokenSource } from 'axios';
import serverApi from 'lib/apiConfig';
import { LoggedInUser } from 'types/user';
import loginTokenUtils from 'utilities/loginTokenUtils';

// !! deprecated function
async function checkIfUrlExists(userURL: string) {
  try {
    const { data: doesUrlExists } = await serverApi.basic.post<boolean>(
      '/user/check-url-availability',
      {
        userURL,
      }
    );
    return doesUrlExists;
  } catch (error) {
    throw error;
  }
}

async function generateOtp(email: string) {
  return serverApi.basic.post('/auth/generate-otp', { email });
}

type SignUpResponse = { user: LoggedInUser; token: string; isExistingUser: boolean };

async function fbSignUp(redirectedFbCode: string, cancelToken: CancelTokenSource): Promise<SignUpResponse> {
  try {
    const res = await serverApi.basic.post('/auth/fb-signup', {
      data: {
        redirectedFbCode,        
      },      
    }, {
      cancelToken: cancelToken.token,
    });
    const editor = res.data;
    return editor;
  } catch (error) {
    throw error;
  }
}

async function fbLogin(redirectedFbCode: string, cancelToken: CancelTokenSource): Promise<SignUpResponse> {
  try {
    const res = await serverApi.basic.post('/auth/fb-login', {
      data: {
        redirectedFbCode,
      },            
    }, {
      cancelToken: cancelToken.token
    });
    const editor = res.data;
    return editor;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.error('Request canceled', (error as unknown as AxiosError).message);
      throw new Error('Request canceled');
    } else {
      throw error;
    }
  }
}

async function getFbProfileData(redirectedFbCode: string) {
  try {
    const res = await serverApi.basic.post<LoggedInUser>('/auth/get-fb-profile-data', {
      data: {
        redirectedFbCode,
      },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function linkedinSignUp(linkedinAccessCode: string) {
  try {
    const res = await serverApi.basic.post<LoggedInUser>('/auth/linkedin-signup', {
      data: {
        linkedinAccessCode,
      },
    });
    const responseData = res.data;
    return responseData;
  } catch (error) {
    throw error;
  }
}

async function googleSignUp(googleAuthorizationCode: string, cancelToken: CancelTokenSource): Promise<SignUpResponse> {
  try {
    const res = await serverApi.basic.post('/auth/google-signup', {
      googleAuthorizationCode,      
    }, {
      cancelToken: cancelToken.token,
    });
    const responseData = res.data;
    return responseData;
  } catch (error) {
    throw error;
  }
}

async function googleLogin(googleAuthorizationCode: string, cancelToken: CancelTokenSource): Promise<SignUpResponse> {
  try {
    const res = await serverApi.basic.post('/auth/google-login', {
      googleAuthorizationCode
    }, {
      cancelToken: cancelToken.token,
    });
    const responseData = res.data;
    return responseData;
  } catch (error) {
    throw error;
  }
}

async function updateUserDetails(user: LoggedInUser) {
  try {
    const res = await serverApi.basic.put<LoggedInUser>(`/user/${user._id}`, user);
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function getUserById(id: string) {
  try {
    let res = await serverApi.basic.get<LoggedInUser>(`/user/${id}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

async function getUserByUrl(url: string) {
  try {
    let res = await serverApi.basic.get<LoggedInUser>(`/user/getByUrl/${url}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export type DeleteUserResponse =
  | 'user successfully deleted'
  | `user with id doesn't exists`
  | 'failed to delete user account';

async function deleteUser(userId: LoggedInUser['_id']): Promise<DeleteUserResponse> {
  try {
    const response = await serverApi.basic.delete(`/user/${userId}`);
    const responseStatus = response.status;
    if (responseStatus === 200 && response.data.message) {
      return 'user successfully deleted';
    } else if (responseStatus === 404 && response.data.message) {
      return "user with id doesn't exists";
    }
  } catch (error) {
    if (error instanceof Error) {
      console.error(error.message);
    }
  }
  return 'failed to delete user account';
}

async function userLogout() {
  try {
    const loginToken = loginTokenUtils.getLocalStorageLoginToken();
    await serverApi.v1.post(
      '/auth/logout',
      {},
      {
        headers: {
          Authorization: `Bearer ${loginToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    loginTokenUtils.removeLocalStorageLoginToken();
  } catch (error) {
    throw error;
  }
}

const userServices = {
  generateOtp,
  checkIfUrlExists,
  linkedinSignUp,
  fbSignUp,
  fbLogin,
  googleSignUp,
  updateUserDetails,
  getUserById,
  getUserByUrl,
  deleteUser,
  userLogout,
  getFbProfileData,
  googleLogin,
};

export default userServices;
