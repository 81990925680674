import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ReadMoreIcon from '@mui/icons-material/ReadMore';

import TinymceRichText, {
  TinymceFormatPreserver,
} from 'components/utilities/TinyMceEditor/TinymceRichText';

import { PortfolioItemType } from 'types/portfolio';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import Popup from 'components/utilities/Popups/Popup';
import { ThemeType } from 'themes/AppThemeProvider/themeType';
import { usePortfolioContext } from 'components/Portfolios/PortfolioContext';
import { lighten, useTheme } from '@mui/material';
import ConfirmationDialog from 'Popups/ConfirmationDialog';
import { getTextContent } from 'components/Portfolios/utilities';
import { useStyledTheme } from 'components/ShareComponents/StyledTheme';

export type PortfolioFormState = {
  readMore: PortfolioItemType['readMore'];
  isFormDirty: boolean;
  isDescriptionEditorExpanded: boolean;
  successMsg: string;
  errorMsg: string;
  loading: boolean;
};

const EditPortfolioReadMore = ({
  isEditMode,
  titleStyle,
  currentPortfolio,
  currentportfolioId,
}: {
  isEditMode?: boolean;
  titleStyle: ThemeType['portfolios']['item']['titleStyle'];
  currentPortfolio: PortfolioItemType;
  currentportfolioId?: number;
}) => {
  const styledTheme = useStyledTheme();
  const portfolioReadMore = currentPortfolio.readMore
    ? currentPortfolio.readMore
    : getTextContent(currentPortfolio.desc).length > 300
    ? currentPortfolio.desc
    : '';
  const { setPortfolioLocalState } = usePortfolioContext();
  const [open, setOpen] = useState(false);
  const [readMoreLocal, setReadMoreLocal] = useState(portfolioReadMore || '');
  const [
    openLostChangesConfirmationDialog,
    updateOpenLostChangesConfirmationDialog,
  ] = useState(false);
  const isThereAnyChanges = readMoreLocal !== currentPortfolio?.readMore;
  const color = !!readMoreLocal
    ? titleStyle.color
    : lighten(titleStyle.color, 0.5);

  useEffect(() => {
    setReadMoreLocal(portfolioReadMore || '');
  }, [portfolioReadMore]);

  function handleReadMoreBtnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setOpen(true);
  }

  const handlePortfolioDescriptionChange = (text: string) => {
    setReadMoreLocal(text);
  };

  function handleCancel() {
    if (isThereAnyChanges) {
      updateOpenLostChangesConfirmationDialog(true);
    } else {
      setOpen(false);
    }
  }

  function handleSave() {
    setOpen(false);
    if (isThereAnyChanges) {
      setPortfolioLocalState((prev) => ({
        ...prev,
        readMore: readMoreLocal,
      }));
    }
  }

  function handleCloseAnyway() {
    setOpen(false);
    updateOpenLostChangesConfirmationDialog(false);
  }

  if (!isEditMode && !!!readMoreLocal) {
    return <></>;
  }

  return (
    <>
      <Box textAlign='end' sx={{ mt: 1, width: '100%', color }}>
        <ResponsiveButton
          onClick={handleReadMoreBtnClick}
          sx={{
            mt: 1,
            borderRadius: 50,
            boxShadow: 'none',
            outline: isEditMode ? `1px dashed ${color}` : 'none',
            outlineStyle: !!readMoreLocal ? 'solid' : 'dashed',
            outlineWidth: !!readMoreLocal ? 1 : 2,
            '&:hover': {
              outline: isEditMode ? styledTheme.outlineSelected : 'none',
              boxShadow: isEditMode ? styledTheme.boxShadowSelected : 'none',
            },
          }}
          color='inherit'
          endIcon={<ReadMoreIcon />}
        >
          Read more
        </ResponsiveButton>
      </Box>
      <Popup
        setOpen={setOpen}
        showCloseIcon={!isEditMode}
        dialogProps={{
          open,
          fullWidth: true,
          PaperProps: {
            sx: {
              maxWidth: '100%',
              width: '100%',
              boxShadow: 'none',
              height: { xs: '100%', md: 'calc(100% - 64px)' },
              maxHeight: { xs: '100%', md: 'calc(100% - 64px)' },
              gap: { xs: 3, md: 6 },
              m: { xs: 0, md: 4 },
            },
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            '& *': {
              wordBreak: 'break-word',
            },
            height: '100%',
          }}
        >
          {isEditMode ? (
            <TinymceRichText
              height={'100%'}
              initialContent={readMoreLocal}
              handleDescriptionChange={handlePortfolioDescriptionChange}
              id={`portfolio-${currentportfolioId || 0}`}
            />
          ) : (
            <TinymceFormatPreserver
              dangerouslySetInnerHTML={{
                __html: readMoreLocal,
              }}
            />
          )}
        </Box>
        {isEditMode && (
          <Stack
            direction='row'
            spacing={2}
            sx={{
              justifyContent: 'flex-end',
              marginLeft: 'auto',
              width: '100%',
              mt: 2,
              p: 2,
              bgcolor: (theme) => theme.palette.background.paper,
            }}
          >
            <ResponsiveButton
              onClick={handleCancel}
              size='small'
              color='secondary'
            >
              Cancel
            </ResponsiveButton>
            <ResponsiveButton
              onClick={handleSave}
              size='small'
              color='primary'
              variant='contained'
              disabled={!isThereAnyChanges}
            >
              Save
            </ResponsiveButton>
          </Stack>
        )}
      </Popup>
      <ConfirmationDialog
        open={openLostChangesConfirmationDialog}
        title='Unsaved Changes!'
        content='You have unsaved changes. Are you sure you want to close the editor?'
        confirmText='Close Anyway'
        isLoading={false}
        handleAgreeBtnClick={handleCloseAnyway}
        handleDialogClose={() => updateOpenLostChangesConfirmationDialog(false)}
      />
    </>
  );
};

export default EditPortfolioReadMore;
