import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  initialPhysicalService,
  initialVirtualService,
} from 'store/features/services/sliceInitializations';
import { PhysicalOrVirtualService } from 'types/service';

export type ServicesModule = {
  services: PhysicalOrVirtualService[];
};

// !! Don't use this slice, moved to rtk query

const INITIAL_STATE: ServicesModule = {
  services: [initialVirtualService, initialPhysicalService],
};

const servicesSlice = createSlice({
  name: 'servicesModule',
  initialState: INITIAL_STATE,
  reducers: {
    setUserServices: (
      state: ServicesModule,
      action: PayloadAction<ServicesModule['services']>
    ) => {
      state.services = action.payload;
    },
    serviceRemoved: (
      state: ServicesModule,
      action: PayloadAction<PhysicalOrVirtualService['_id']>
    ) => {
      const deletedServiceId = action.payload;
      const updatedServices = state.services.filter(
        (service) => service._id !== deletedServiceId
      );
      state.services = updatedServices;
    },
    newServiceAdded: (
      state: ServicesModule,
      action: PayloadAction<PhysicalOrVirtualService>
    ) => {
      state.services.push(action.payload);
    },
    updateService: (state: ServicesModule, action: PayloadAction<any>) => {
      const { serviceUpdatedContext, toBeUpdatedServiceDetails } = action.payload;
      const serviceIndex = state.services.findIndex(
        (service) => service._id === toBeUpdatedServiceDetails._id
      );
      const updatedService = {
        ...toBeUpdatedServiceDetails,
        ...serviceUpdatedContext,
      };
      state.services[serviceIndex] = updatedService;
    },
    updateUserServices: (
      state: ServicesModule,
      action: PayloadAction<ServicesModule['services']>
    ) => {
      const services = action.payload;
      state.services = services;
    },
    resetServiceSlice: () => {
      return INITIAL_STATE;
    },
  },
});

const servicesSliceReducer = servicesSlice.reducer;
export default servicesSliceReducer;

export const {
  setUserServices,
  resetServiceSlice,
  newServiceAdded,
  serviceRemoved,
  updateService,
  updateUserServices,
} = servicesSlice.actions;

// selectors
export function selectServicesModule(state: RootState) {
  return state.persistedReducer.servicesModule;
}

export function selectAllServices(state: RootState) {
  const servicesModule = selectServicesModule(state);
  return servicesModule.services;
}

export function selectServiceId(serviceId: string) {
  return serviceId;
}

export const selectServiceById = createSelector(
  [selectAllServices, selectServiceId],
  (allRootStateServices, serviceId) => {
    const requiredService = allRootStateServices.filter(
      (eachService) => eachService._id === serviceId
    );
    return requiredService[0];
  }
);
