import { Box, Button, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import routeConstants from 'constants/routeConstants';
import { useLocation } from 'react-router-dom';

const SUCCESS_ICON_URL = `https://res.cloudinary.com/salesfronts/image/upload/v1675523723/success-svg_ew7l0h.svg`;

const ServiceCheckoutSuccessPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editorUrl = searchParams.get('user-url') ?? 'fronts';

  const handleBackToHome = () => {
    window.location.replace(`${routeConstants.LIVE_ROUTE}/${editorUrl}`);
  };

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {/* --------------- card ----------------- */}
      <Stack
        spacing={13}
        sx={{
          borderRadius: '8px',
          padding: '5rem 4rem',
          boxShadow: `0 0 4px ${grey[400]}`,
        }}
      >
        <Stack spacing={3}>
          {/* ------------------- success icon -------------------------- */}
          <Stack direction='row' justifyContent='center' alignItems='center'>
            <Box
              component='img'
              src={SUCCESS_ICON_URL}
              sx={{
                width: '80px',
              }}
            />
          </Stack>
          {/* ------------------- thanks msg -------------------------- */}
          <Typography variant='h2' textAlign='center' maxWidth='33ch'>
            Thank you for your order!
          </Typography>
          <Box>
            <Typography
              variant='body1'
              textAlign='center'
              maxWidth='33ch'
              sx={{
                marginInline: 'auto',
              }}
            >
              We appreciate your business and are glad to have been of service. If you
              need anything, don't hesitate to reach out.
            </Typography>
          </Box>
        </Stack>
        {/* ------------------- back to home btn -------------------------- */}
        <Box>
          <Button
            variant='contained'
            onClick={handleBackToHome}
            sx={{
              background: grey[900],
              fontSize: '1.6rem',
              maxWidth: 'fit-content',
              display: 'block',
              marginX: 'auto',
              padding: '0.7em 1.3em',
              '&:hover': {
                background: grey[800],
              },
            }}
          >
            Back to website
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default ServiceCheckoutSuccessPage;
