import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { grey } from '@mui/material/colors';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Stack,
  useMediaQuery,
} from '@mui/material';

import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import IfElse from 'components/utilities/IfElse';
import FileReader from 'components/utilities/FileReader/FileReader';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import uploadImageService from 'lib/services/uploadImageService';
import Question from '../../reusable-components/Question';
import BoldSpan from 'components/utilities/BoldSpan';

const InputProfileImg = () => {
  const { setFieldValue, values, setFieldTouched, isValid, errors } =
    useFormikContext<StepperState>();
  const [currentImg, setCurrentImg] = useState<string | null>(null);
  const isChangeMode = !!values.img?.trim();
  const isError = errors.profileImgFile && !isValid;

  useEffect(() => {
    if (values.profileImgFile && values.profileImgFile instanceof File) {
      console.log('in profile img file');
      const newImgUrl = URL.createObjectURL(values.profileImgFile);
      setCurrentImg(newImgUrl);
    } else {
      setCurrentImg(values.img);
    }

    return () => {
      if (values.img && !values.profileImgFile) {
        URL.revokeObjectURL(values.img);
      }
    };
  }, [values.img, values.profileImgFile]);

  function handleDeleteImg() {
    if (isChangeMode && values.img?.trim()) {
      if (values.img) {
        const publicId = cloudinaryUtilsFns.extractPublicIdFromCloudinaryURL(values.img);
        if (publicId) {
          uploadImageService.deleteCloudinaryImgWithPublicId(publicId);
        }
      }
    }
  }

  useEffect(() => {
    if (values.img && !values.profileImgFile) {
      async function fetchAndConvertImage() {
        const response = await fetch(values.img);
        const data = await response.blob();
        const file = new File([data], 'img.jpg', { type: data.type });

        setFieldValue('profileImgFile', file);
      }

      fetchAndConvertImage();
    }
  }, [values.img, setFieldValue, values.profileImgFile]);

  return (
    <Box>
      <Question
        required
        currentStep={2}
        helperText='This will appear on the website and in search results'
      >
        <>
          Upload <BoldSpan>main image</BoldSpan>
        </>
      </Question>
      <Stack sx={{ flexDirection: 'column', mt: 1 }}>
        {/* img  */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Stack justifyContent={{ xs: 'center', md: 'flex-start' }} rowGap={2}>
            {!!currentImg && (
              <Box
                component='img'
                src={currentImg}
                alt='spm-img'
                sx={{
                  maxHeight: '139px',
                  objectFit: 'contain',
                }}
              />
            )}
            <FileReader
              label={values.profileImgFile ? 'Change Image' : 'Upload Image'}
              handleImageChange={(e) => {
                setFieldTouched('profileImgFile', true);
                if (e.target.files?.length) {
                  setFieldValue('profileImgFile', e.target.files[0]);
                }
                isChangeMode && handleDeleteImg();
              }}
            />
          </Stack>
        </Box>
        <IfElse condition={!!isError}>
          <FormHelperText
            error
            sx={{
              textAlign: { xs: 'center', md: 'start' },
            }}
            color='error.main'
          >
            {errors.profileImgFile}
          </FormHelperText>
        </IfElse>
      </Stack>
    </Box>
  );
};

export default InputProfileImg;

export function ImgLoader() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box
      sx={{
        position: 'absolute',
        backgroundColor: grey[900],
        opacity: 0.95,
        inset: 0,
        zIndex: 44444444,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        size={isMobile ? 15 : 21}
        sx={{
          color: 'common.white',
        }}
      />
    </Box>
  );
}
