import { Box, SxProps } from '@mui/material';

interface PageWrapperProps {
  children: React.ReactNode;
  sx?: SxProps;
}
const PageWrapper: React.FC<PageWrapperProps> = ({ children, sx }) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        py: {xs: 2, sm: 2, md: 2},
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default PageWrapper;
