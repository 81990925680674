import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import { StripeConnectedAccount } from 'types/StripeConnectedAccount';

const stripeConnectedAccountExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStripeConnectedAccountByEditorId: build.query<StripeConnectedAccount, string>({
      query: (editorId) => `/stripe/connected-account/user/${editorId}`,
      providesTags: ['StripeConnectedAccount'],
    }),
    getStripeConnectedAccountStatus: build.query<'Account activation in progress' | 'Account is active', void>({
      query: () => `/stripe/connected-account/status`,
      providesTags: ['StripeConnectedAccountStatus'],
    }),
  }),
});

export const { useGetStripeConnectedAccountByEditorIdQuery, useGetStripeConnectedAccountStatusQuery } =
  stripeConnectedAccountExtendedApi;

export default stripeConnectedAccountExtendedApi;
