import { Verified, Info } from '@mui/icons-material';

import { useGetStripeConnectedAccountStatusQuery } from 'store/rtkqFeatures/api/stripeConnectedAccountExtendedApi';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';

const StripeDashboardBtn = () => {
  const { data: accountStatus } = useGetStripeConnectedAccountStatusQuery();

  return (
    <ResponsiveButton
      LinkComponent={'a'}
      href='https://dashboard.stripe.com/login'
      target='_blank'
      rel='noreferrer'
      color={accountStatus?.includes('progress') ? 'warning' : 'success'}
      variant='contained'
      endIcon={
        accountStatus?.includes('progress') ? (
          <Info sx={{ verticalAlign: 'middle' }} />
        ) : (
          <Verified sx={{ verticalAlign: 'middle' }} />
        )
      }
      sx={{      
        alignSelf: 'flex-start',
      }}
    >
      Stripe dashboard
    </ResponsiveButton>
  );
};

export default StripeDashboardBtn;
