import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useField, useFormikContext } from 'formik';
import { InputAdornment, Box, TextField, IconButton, InputLabel, SxProps, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { alpha, useTheme } from '@mui/material/styles';
import { inputBgColor } from 'themes/AppThemeProvider/light';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import EUDFormValidationErrorMsg from 'components/EditUserDetailsLatest/utility-components/EUDFormValidationErrorMsg';
import StyledItemLabel from 'components/shared/StyledItemLabel';

type EUDFormInputProps = {
  label: string;
  name: keyof EditUserDetailsForm;
  inputStartAdornment?: React.ReactNode;
  required?: boolean;
  type?: 'email' | 'text';
  multiline?: boolean;
  hideEndAdornment?: boolean;
  borderColor?: string;
  textColor?: string;
  customCss?: SxProps<Theme>;
};

const customTextFieldPadding = {
  xs: '0.8rem 1rem',
  sm: '0.9rem 1rem',
  md: '1rem 1.25rem',
};

const EUDFormInput: React.FC<EUDFormInputProps> = ({
  label,
  name,
  inputStartAdornment,
  hideEndAdornment = false,
  type = 'text',
  required = false,
  multiline = false,
  borderColor,
  textColor,
  customCss = {},
}) => {
  const { getFieldProps, setFieldTouched } = useFormikContext<EditUserDetailsForm>();
  const [_field, meta, fieldHelpers] = useField(name);
  const showStartAdornment = Boolean(!multiline && inputStartAdornment);
  const isFieldTouched = meta.touched;
  const isFieldError = Boolean(meta.error);
  const { value, onChange, ...restFieldProps } = getFieldProps(name);
  const [state, setState] = useState(value);
  const theme = useTheme();
  const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

  useEffect(() => {
    setState(value);
  }, [value]);

  const handleClearInputBtnClick = () => {
    fieldHelpers.setValue('');
    setState('');
    setFieldTouchedDebounced(name);
  };

  const setFieldTouchedDebounced = useCallback(
    debounce((name: string) => setFieldTouched(name, true, true), 600),
    []
  );

  const onChangeDebounced = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => onChange(e), 500),
    []
  );

  const onInputChange = () => {
    setFieldTouchedDebounced(name);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let value = e.target.value;
    if (e.target.name === 'userUrl' && value.match(/[A-Z]/)) {
      value = value.toLowerCase();
    }
    setState(value);
    onChangeDebounced(e);
  };

  return (
    <Box>
      <StyledItemLabel label={label} required={required} />
      <TextField
        id={name}
        onInput={onInputChange}
        error={isFieldTouched && isFieldError}
        multiline={multiline}
        minRows={multiline ? 4 : undefined}
        maxRows={multiline ? 4 : undefined}
        required={required}
        fullWidth
        type={type}
        value={state}
        onChange={handleInputChange}
        InputProps={{
          startAdornment: showStartAdornment ? (
            <InputAdornment position="start" sx={{ mr: 0, ml: '-11px', height: '100%' }}>
              {inputStartAdornment}
            </InputAdornment>
          ) : undefined,
          endAdornment: !hideEndAdornment ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClearInputBtnClick} edge="end">
                <CloseIcon fontSize="medium" />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        {...restFieldProps}
        sx={{
          '& .MuiOutlinedInput-root': {
            background: `${inputBgColor} !important`,
            color: textColor ? `${textColor} !important` : undefined,
            borderColor: borderColor || '#cbd6e2',
            fontWeight: 600,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor || 'inherit',
            },
            '& .MuiInputBase-input': {
              fontSize: '1.6rem',
              padding: { xs: customTextFieldPadding.xs, md: customTextFieldPadding.md },
              paddingLeft: showStartAdornment ? 0 : undefined, // Remove left padding when there's a start adornment
            },
            '& .MuiInputAdornment-root': {
              '& > *': {
                fontSize: '1.6rem',
              },
            },
            '& .MuiInputAdornment-positionStart': {
              marginRight: 0,
            },
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
            boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
          },
          '& input::placeholder': {
            fontSize: '1.6rem !important',
            color: '#7d98b6',
            opacity: 1,
          },
          ...customCss,
        }}
      />
      <EUDFormValidationErrorMsg
        errorMsg={meta.error || ''}
        isVisible={isFieldTouched && isFieldError}
      />
    </Box>
  );
};

export default EUDFormInput;