import { Box } from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';

const Required = () => {
  return (
    <Box
      component='sup'
      sx={{
        ml: '3px',
        top: '-2.1px',
        verticalAlign: 'middle',
        fontSize: '1.7rem',
        color: red[600],
      }}
    >
      *
    </Box>
  );
};

export default Required;
