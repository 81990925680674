import { toast } from 'react-toastify';
import { Box, Button, Stack, SxProps } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import UploadBtn from 'components/utilities/Cloudinary/UploadBtn';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import analyzeImageAndSuggestTextColor from 'utilities/analyzeAndSuggestTextColor';

type UploadImageProps = {
  img?: string | File;
  uploadBtnWrapperStyle?: SxProps;
  imgUploadCallback: (e: React.ChangeEvent<HTMLInputElement>, textColor: string) => void;
  removeCallback: (url: string | File) => void;
  width?: number;
  height?: number;
  showClearIcon?: boolean;
};

const UploadImage = ({
  imgUploadCallback,
  removeCallback,
  img,
  width = 100,
  height = 100,
  showClearIcon = true,
  uploadBtnWrapperStyle = {},
}: UploadImageProps) => {
  const imgExist = !!img;
  const theme = useTheme();

  const handleImgUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      toast.error('No file selected');
      return;
    }

    if (e.target.files.length > 5) {
      toast.error('You can upload maximum 5 files');
      return;
    }

    if ([...e.target.files].some((file) => file.size > 10000000)) {
      toast.error('You can upload maximum 10 MB file');
      return;
    }

    if (![...e.target.files].some((file) => file.type.startsWith('image/'))) {
      toast.error('You can only upload images');
      return;
    }
    // TODO - add support for gIF upload
    const textColor = await analyzeImageAndSuggestTextColor(e.target.files[0]);
    imgUploadCallback(e, textColor);
  };

  const removeFile = async (img: string | File) => {
    removeCallback(img);
  };

  const getImgUrl = (img: string | File): string => {
    if (typeof img === 'string') {
      return img;
    } else {
      return URL.createObjectURL(img);
    }
  };

  return (
    <Stack
      className='upload-image-container'
      direction='row'
      gap={2}
      flexWrap={'wrap'}
      flexBasis={'100%'}
      sx={{ position: 'relative', height }}
    >
      <Box
        sx={{
          position: 'relative',
          width: 'auto',
          maxWidth: '400px',
          height,
          borderRadius: 1,
          overflow: 'hidden',
          boxShadow: theme.shadows[2],
        }}
      >
        {imgExist ? (
          <>
            <Box
              component="img"
              src={getImgUrl(img)}
              alt="Uploaded image"
              sx={{
                width: 'auto',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            {showClearIcon && (
              <Button
                onClick={() => removeFile(img)}
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  minWidth: 'unset',
                  p: 0.5,
                  bgcolor: alpha(theme.palette.background.paper, 0.8),
                  '&:hover': {
                    bgcolor: alpha(theme.palette.background.paper, 0.9),
                  },
                }}
              >
                <ClearIcon sx={{ color: theme.palette.error.main }} />
              </Button>
            )}
          </>
        ) : (
          <UploadBtn
            btnStyle={{
              width: '100%',
              height: '100%',
              fontSize: { xs: '1.4rem', md: '1.6rem' },
              py: 0.25,
            }}
            wrapperStyle={{
              minWidth: width,
              width: '100%',
              height: '100%',
              ...uploadBtnWrapperStyle,
            }}
            handleImgInput={handleImgUpload}
            inputProps={{ name: 'userBgImage', multiple: false, id: 'upload-image' }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default UploadImage;

const btnStyle: SxProps = { fontSize: { xs: '1.4rem', md: '1.6rem' }, py: 0.25 };
