type ReturnType = [() => void];

function useHtmlContentEditable(
  // initialHeading: string,
  elementDOMRef: React.MutableRefObject<HTMLElement | null>,
  onElementChange: (changedElement: string) => void
): ReturnType {
  // const [heading, updateHeading] = useState(initialHeading);

  const handleUIhtmlElementChange = () => {
    if (!elementDOMRef.current) return;

    // fetching the node text
    const node = elementDOMRef.current;
    const text = node.innerText;
    // console.log('updated heading fetched from dom ref: ', headingText);

    // passing selected node text to passed onHeadingChange callback
    onElementChange(text.trim());
  };

  return [handleUIhtmlElementChange];
}

export default useHtmlContentEditable;
