import React, { useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { Box, InputAdornment, TextField, Button, SxProps, Theme } from '@mui/material';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { alpha, useTheme } from '@mui/material/styles';
import { inputBgColor } from 'themes/AppThemeProvider/light';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import { isStringNonEnglish } from 'utilities/stringUtilities';

type EUDTagsInputProps = {
  borderColor?: string;
  textColor?: string;
  customCss?: SxProps<Theme>;
};

const customTextFieldPadding = {
  xs: '0.8rem 1rem',
  sm: '0.9rem 1rem',
  md: '1rem 1.25rem',
};

const EUDTagsInput: React.FC<EUDTagsInputProps> = ({
  borderColor,
  textColor,
  customCss = {},
}) => {
  const [tagName, setTagName] = useState('');
  const { values, setFieldValue, setFieldTouched } = useFormikContext<EditUserDetailsForm>();
  const theme = useTheme();
  const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

  const handleTagNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(e.target.value);
  };

  const handleAddTagBtnClick = useCallback(() => {
    const tempTagName = tagName.trim();

    if (!isStringNonEnglish(tempTagName)) {
      toast.error('Tag name can only contain english letters, numbers and _');
      return;
    }

    if (!tempTagName) {
      toast.error("Can't add empty values");
      return;
    }

    const optimizedTags = values.tags.map(tag => tag.trim());

    if (optimizedTags.some(tag => tag.toLowerCase() === tempTagName.toLowerCase())) {
      toast.error('Value already exists');
      return;
    }

    setFieldValue('tags', [...optimizedTags, tempTagName]);
    setFieldTouched('tags', true);
    setTagName('');
  }, [tagName, values.tags, setFieldValue, setFieldTouched]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleAddTagBtnClick();
    }
  };

  return (
    <Box>
      <TextField
        fullWidth
        value={tagName}
        onChange={handleTagNameInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter a skill"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={handleAddTagBtnClick}
                endIcon={<AddIcon />}
                sx={{
                  minWidth: 'auto',
                  padding: '6px 12px',
                  backgroundColor: theme.palette.grey[200],
                  color: theme.palette.text.primary,
                  '&:hover': {
                    backgroundColor: theme.palette.grey[300],
                  },
                }}
              >
                Add
              </Button>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            background: `${inputBgColor} !important`,
            color: textColor ? `${textColor} !important` : undefined,
            borderColor: borderColor || '#cbd6e2',
            fontWeight: 600,
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: borderColor || 'inherit',
            },
            '& .MuiInputBase-input': {
              fontSize: '1.6rem',
              padding: { xs: customTextFieldPadding.xs, md: customTextFieldPadding.md },
            },
            '& .MuiInputAdornment-root': {
              marginRight: '-11px',
            },
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
            borderWidth: '2px',
            boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
          },
          '& input::placeholder': {
            fontSize: '1.6rem !important',
            color: '#7d98b6',
            opacity: 1,
          },
          ...customCss,
        }}
      />
    </Box>
  );
};

export default EUDTagsInput;