import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useScript } from 'usehooks-ts';

import { MeetingTypes } from 'types/meeting';
import { AutoCompleteGuestLocationDetails } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import useBooleanStateController from 'hooks/useBooleanStateController';
import sessionServices from 'lib/services/sessionServices';
import { initialAutoCompleteGuestLocationDetails } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteUtilities';
import {
  useGetSessionsByUserIdQuery,
  useUpdateMeeingPInstructionsOrLocationDetailsMutation,
  useUpdateSessionHostGuestMeetingDetailsMutation,
} from 'store/rtkqFeatures/api/SessionExtendedApi';
import { googlePlacesApiScriptUrl } from 'API_Subscriptions/googlePlacesApi';
import getNodeEnv from 'utilities/getNodeEnv';

const useHostSessionDetailsToGuest = (sessionId: string, userId: string) => {
  const isNodeEnvProduction = getNodeEnv();
  // loading google maps api
  const googleMapsApiScriptStatus = useScript(googlePlacesApiScriptUrl, {
    removeOnUnmount: true,
  });
  // ** queries
  const { thisSession } = useGetSessionsByUserIdQuery(userId, {
    selectFromResult: ({ data }) => ({
      thisSession: data?.find((eachSession) => eachSession._id === sessionId),
    }),
  });
  // ** mutations
  const [
    updateMeeingPInstructionsOrLocationDetails,
    { isLoading: isMeeingPInstructionsOrLocationDetailsUpdateInProgress },
  ] = useUpdateMeeingPInstructionsOrLocationDetailsMutation();

  const [
    updateSessionHostGuestMeetingDetails,
    { isLoading: isSessionHostGuestMeetingDetailsUpdationInProgress },
  ] = useUpdateSessionHostGuestMeetingDetailsMutation();

  // for inPerson meeting
  const showLinkOrLocationDetailsToGuest =
    thisSession?.showLinkOrLocationDetailsToGuest ?? true;
  const recaptchaRef = useRef<any>(null);
  const [hostToGuestInfo, setHostToGuestInfo] = useState({
    copyLink: thisSession?.hostGuestMeetingDetails?.link || '',
    link: thisSession?.hostGuestMeetingDetails?.link || '',
    clearLocationValue: false,
    extraInstructions:
      thisSession?.hostGuestMeetingDetails?.meetingExtraInstructions ?? '',
    isRobot: true,
    inPersonMeetingGuestLocationDetails:
      thisSession?.inPersonMeetingGuestLocationDetails ??
      initialAutoCompleteGuestLocationDetails,
    isCaptchaVisible: false,    
  });
  const [isAlertDialogBoxOpen, setIsAlertDialogBoxOpen] =
    useBooleanStateController(false);
  const editorAllowedSharingLocaitonDetailsToGuest = useRef<undefined | boolean>(
    undefined
  );
  const guestMeetingLocationGoogleMapsLink =
    thisSession?.inPersonMeetingGuestLocationDetails?.googleMapsLocationUrl ?? '';
  const isOnlineMeeting = thisSession?.meetingDetails.type === MeetingTypes.Virtual;

  const getGoogleAutoCompleteLocationDetails = (
    details: AutoCompleteGuestLocationDetails
  ) => {
    setHostToGuestInfo((prev) => ({
      ...prev,
      isCaptchaVisible: true,
      link: details.googleMapsLocationUrl,
      inPersonMeetingGuestLocationDetails: details,
    }));
  };

  const handleHostToGuestDataInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setHostToGuestInfo((prev) => ({
      ...prev,
      isCaptchaVisible: true,
      [name]: value,
    }));
  };

  const submitHostToGuestDetailsForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    saveAndSendEmailUpdate();
  };

  const saveAndSendEmailUpdate = async (
    skipRecaptcha: boolean = false,
    skipPrivacyChecking: boolean = false
  ) => {
    const { link, extraInstructions } = hostToGuestInfo;
    // validations
    try {
      if (!skipRecaptcha) {
        if (hostToGuestInfo.isRobot) {
          toast.error('Verify captcha');
          return;
        }
      }
      /**
       * In case of online meeting until session isn't confirmed
       * for privacy, this check is there. This check won't allow
       * to show private details on guest session when loggedin.
       */
      if (!showLinkOrLocationDetailsToGuest && !skipPrivacyChecking) {
        setIsAlertDialogBoxOpen.setTrue();
        return;
      }
      if (isOnlineMeeting) {
        await updateSessionHostGuestMeetingDetails({
          sessionId,
          hostGuestMeetingDetails: {
            link,
            meetingExtraInstructions: extraInstructions,
          },
        }).unwrap();
      }
      if (!isOnlineMeeting) {
        const updatedDetails = {
          sessionId,
          meetingExtraInstructions: hostToGuestInfo.extraInstructions,
          inPersonMeetingGuestLocationDetails:
            hostToGuestInfo.inPersonMeetingGuestLocationDetails,
        };
        await updateMeeingPInstructionsOrLocationDetails(updatedDetails).unwrap();
      }
      setHostToGuestInfo((prev) => ({
        ...prev,
        copyLink: prev.link,
      }));
      toast.success('Details updated');
    } catch (error) {
      toast.error('Something went wrong! please try again later');
    } finally {
      setHostToGuestInfo((prev) => ({
        ...prev,
        isCaptchaVisible: false,
        isRobot: true,
      }));
      recaptchaRef?.current && recaptchaRef.current?.reset?.();
    }
  };

  const decideAllowingHostSharingDetailsWithGuest = async (hasAllowed: boolean) => {
    if (hasAllowed) {
      await sessionServices.updateSession(thisSession?._id as string, {
        showLinkOrLocationDetailsToGuest: true,
      });
      !isNodeEnvProduction && toast.success('Private flag set to true');
    }
    saveAndSendEmailUpdate(true, true);
  };

  return {
    thisSession,
    isOnlineMeeting,
    googleMapsApiScriptStatus,
    mutationsStatus: {
      isSessionHostGuestMeetingDetailsUpdationInProgress,
      isMeeingPInstructionsOrLocationDetailsUpdateInProgress,
    },
    locationPrivacyConcernDialogBox: {
      isAlertDialogBoxOpen,
      setIsAlertDialogBoxOpen,
    },
    hostToGuestInfo,
    guestMeetingLocationGoogleMapsLink,
    getGoogleAutoCompleteLocationDetails,
    handleHostToGuestDataInputs,
    recaptchaRef,
    setHostToGuestInfo,
    saveAndSendEmailUpdate,
    decideAllowingHostSharingDetailsWithGuest,
    editorAllowedSharingLocaitonDetailsToGuest,
    submitHostToGuestDetailsForm,
  };
};

export default useHostSessionDetailsToGuest;