import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  initialPhysicalMeeting,
  initialVirtualMeeting,
} from 'store/features/meetings/sliceInitializations';
import { PhysicalOrVirtualMeeting, PhysicalOrVirtualMeetingList } from 'types/meeting';

// !! DON'T USE THIS SLICE | MOVED TO RTK Query

export type MeetingsModule = {
  meetings: PhysicalOrVirtualMeetingList;
  currentMeeting: PhysicalOrVirtualMeeting; // for booking functionality purpose | Calendar, ProposeNewTime, SelectNewTime, sessionStatus
};

const INITIAL_STATE: MeetingsModule = {
  meetings: [initialVirtualMeeting, initialPhysicalMeeting],
  currentMeeting: initialVirtualMeeting,
};

const meetingsSlice = createSlice({
  name: 'meetingsModule',
  initialState: INITIAL_STATE,
  reducers: {
    setMeetings: (
      state: MeetingsModule,
      action: PayloadAction<PhysicalOrVirtualMeetingList>
    ) => {
      state.meetings = action.payload;
    },
    meetingAdded: (
      state: MeetingsModule,
      action: PayloadAction<PhysicalOrVirtualMeeting>
    ) => {
      const newMeeting = action.payload;
      state.meetings.push(newMeeting);
    },
    setCurrentMeeting: (
      state: MeetingsModule,
      action: PayloadAction<PhysicalOrVirtualMeeting>
    ) => {
      const newMeeting = action.payload;
      state.currentMeeting = newMeeting;
    },
    meetingDeleted: (
      state: MeetingsModule,
      action: PayloadAction<PhysicalOrVirtualMeeting['_id']>
    ) => {
      const meetingId = action.payload;
      const updatedMeetings = state.meetings.filter(
        (meeting) => meeting._id !== meetingId
      );
      state.meetings = updatedMeetings;
    },
    resetMeetingsSlice: () => {
      return INITIAL_STATE;
    },
  },
});

const meetingsSliceReducer = meetingsSlice.reducer;
export default meetingsSliceReducer;

export const {
  setMeetings,
  meetingAdded,
  meetingDeleted,
  resetMeetingsSlice,
  setCurrentMeeting,
} = meetingsSlice.actions;

// slice selectors
export function selectMeetingsModule(state: RootState) {
  return state.persistedReducer.meetingsModule;
}

export function selectAllMeetings(state: RootState) {
  const meetingsModule = selectMeetingsModule(state);
  return meetingsModule.meetings;
}

export function selectMeetingId(state: RootState, meetingId: string) {
  return meetingId;
}

export const selectMeetingById = createSelector(
  [selectAllMeetings, selectMeetingId],
  (allRootStateMeetings, meetingId) => {
    const requireMeetingArray = allRootStateMeetings.filter(
      (eachMeeting) => eachMeeting._id === meetingId
    );
    return requireMeetingArray[0];
  }
);
