import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SPMEditableTagsProps } from 'components/SPM_Forms/reusable-form-components/SPMEditableTags/SPMEditableTags';
import IfElse from 'components/utilities/IfElse';
import { theme } from '@cloudinary/url-gen/actions/effect';
import { inputBgColor } from 'themes/AppThemeProvider/light';

type ThisProps = Pick<SPMEditableTagsProps, 'tagList' | 'onTagsChange'>;

const initialTagInputState = {
  tagName: '',
  error: '',
};

const SPMTagInput = ({ tagList, onTagsChange }: ThisProps) => {
  const [tagInputState, updateTagInputState] = useState(initialTagInputState);
  const tagName = tagInputState.tagName;
  const tagError = tagInputState.error;
  const isTagInputError = !!tagError;

  const resetTagInputState = () => {
    updateTagInputState(initialTagInputState);
  };

  const handleTagNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagName = e.target.value;

    updateTagInputState(() => ({
      tagName,
      error: '',
    }));
  };

  const handleAddTagBtnClick = () => {
    const tempTagName = tagName.trim();

    if (!tagName.trim().toLocaleLowerCase()) {
      toast.error('Cannot add empty value');
      return;
    }

    if(tagList.length > 20) {
      toast.error('Cannot add more than 20 tags');
      return;;
    }

    const tagsCopy = [...tagList];
    const optimizedTags = [];

    // ✔️ trimming each tag
    for (const element of tagsCopy) {
      const optimizedTag = element.trim();
      optimizedTags.push(optimizedTag);
    }

    // ✅ check for existing tag name
    const doesTagNameAlreadyExists = optimizedTags.some((tag) => tag.toLowerCase() === tempTagName.toLowerCase());
    if (doesTagNameAlreadyExists) {
      toast.error('Value already exists');
      return;
    }

    const tagsWithNewTag = [...optimizedTags, tempTagName];

    onTagsChange(tagsWithNewTag);

    resetTagInputState();
  };

  return (
    <FormControl fullWidth sx={{maxWidth: 300}}>
      <OutlinedInput
        fullWidth
        value={tagName}
        onChange={handleTagNameInputChange}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={handleAddTagBtnClick} edge='end'>
              <AddCircleIcon fontSize='large' sx={{color: '#bb00bb'}} />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          background: `${inputBgColor} !important`,
          borderRadius: '5px',
          '& input': {
            border: 0,
            '&:hover': {
              border: 0,
            },
            '&:focus': {
              border: 0,
            },
          },
        }}
      />
      <IfElse condition={isTagInputError}>
        <FormHelperText
          error
          id='tags-input-error'
          sx={{
            fontSize: '1.5rem',
          }}
        >
          {tagError}
        </FormHelperText>
      </IfElse>
    </FormControl>
  );
};

export default SPMTagInput;

// const validateTagInput = ({
//   isSubmitted = false,
//   value = tagName,
// }: ValidateTagInputParams) => {
//   // const testValue = value.trim().toLocaleLowerCase();

//   // ✅ validation: empty?
//   if (!value) {
//     updateTagInputState(() => ({
//       tagName: value,
//       error: 'tag name is required',
//     }));
//     return false;
//   }

//   // ✔️ more validation in submittion status
//   if (isSubmitted) {
//   }

//   return true;
// };
