import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { Session, initialSessionData } from 'types/sessions';

// NOTE: This slice is not in use.
// TODO: DELETE THIS SLICE (needs permission/discussion) | Moved to RTK Query

export type SessionModule = {
  userSessions: Session[];
  currentSession: Session;
  removedSession: string;
  selectedSessionId: string;
};

const INITIAL_STATE: SessionModule = {
  userSessions: [initialSessionData],
  currentSession: initialSessionData,
  removedSession: '',
  selectedSessionId: '',
};

export const sessionSlice = createSlice({
  name: 'sessionModule',
  initialState: INITIAL_STATE,
  reducers: {
    setSessions: (state: SessionModule, action: PayloadAction<Session[]>) => {
      state.userSessions = action.payload;
    },
    setCurrentSession: (
      state: SessionModule,
      action: PayloadAction<Session>
    ) => {
      state.currentSession = action.payload;
    },
    setRemovedSession: (
      state: SessionModule,
      action: PayloadAction<string>
    ) => {
      state.removedSession = action.payload;
    },
    setSelectedSessionId: (
      state: SessionModule,
      action: PayloadAction<string>
    ) => {
      state.selectedSessionId = action.payload;
    },
    resetSessionSlice: () => {
      return INITIAL_STATE;
    },
  },
});

const sessionSliceReducer = sessionSlice.reducer;
export default sessionSliceReducer;

export const {
  setSessions,
  setCurrentSession,
  setRemovedSession,
  resetSessionSlice,
  setSelectedSessionId,
} = sessionSlice.actions;

// slice selectors
export const selectSessionModule = (state: RootState) =>
  state.persistedReducer.sessionsModule;

// helper functions
// function toDatestamp(strDate: any) {
//   const dt = new Date(strDate).getTime();
//   return dt;
// }
