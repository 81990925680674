import { AutoCompleteGuestLocationDetails } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import serverApi from 'lib/apiConfig';

export const initialAutoCompleteGuestLocationDetails: AutoCompleteGuestLocationDetails =
  {
    suggestion: '',
    placeId: '',
    address: '',
    location: {
      lat: 1,
      lng: 1,
    },
    googleMapsLocationUrl: '',
    countryISOCode: '',
  };

export async function getPlaceDetailsFromGoogleMapsApi(
  placeId: string,
  fields: string[] = [
    'url',
    'vicinity',
    'website',
    'formatted_address',
    'geometry',
    'address_components',
  ]
) {
  if (!placeId || !fields.length) return null;
  const _fields = String(fields.map((eachField: string) => eachField.trim()));

  // FIXME: use debouce here
  try {
    const response = await serverApi.v2.get(
      `/google-maps-places-api/place-details?place-id=${placeId}&fields=${_fields}`
    );
    // console.log('XX012 ', response.data)
    return response.data;
  } catch (error) {
    if (typeof error === 'string') {
      console.error(error);
    }
  }
}
