import { Grid, Paper } from '@mui/material';
import StripeOnboardingIntegration from 'components/dashboard/StripeOnboardingIntegration/StripeOnboardingIntegration';

const PaymentCollection = () => {
  return (
    <Grid container gap={3}>
      <Grid component={Paper} variant='outlined' item xs={12} md={5}>
        <StripeOnboardingIntegration />
      </Grid>
    </Grid>
  );
};

export default PaymentCollection;
