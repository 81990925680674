import { Box, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import {
  style_ProfileCard_bgImage,
  style_ProfileCard_bgWave,
  style_ProfileCard_container,
  style_ProfileCard_figure,
  style_ProfileCard_tags,
  style_ProfileCard_title,
  style_ProfileCard_userImage,
  style_ProfileCard_userImageWrapper,
} from 'components/ProfileCard/ProfileCard.style';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';

import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import IconPopup from '../IconPopup';
import SocialIconsList from 'components/utilities/SocialIconsList';
import IfElse from 'components/utilities/IfElse';
import Tags from '../Tags';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import LayoutContainer from 'components/Containers/LayoutContainer';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import LayoutWrapper from './reusable-components/LayoutWrapper';
import LayoutProfileImg from './reusable-components/LayoutProfileImg';

const Layout8 = () => {
  const alignment = 'center';
  const {
    currentTheme,

    user,
    userImage,
    userName,
    userTitle,
    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,
    userBgImage,

    tagsArrayLength,
    tagsArray,

    closeIconPopup,

    userProfileLink,
    showIconPopup,

    buttonStyle,
    bgImage,
    bg,

    isMeetingAndServiceAvailable,
    handleImageError,
    showImage,
    storeHeading,

    isNeedDiv,
    isMobile,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,

    displayTags,
    displaySocialLinks,

    userNameColor,
    titleColor,
  } = useProfileCard();

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Grid item sx={isNeedDiv ? {} : { ...bg }}>
      {isNeedDiv && !userBgImage && (
        <Box className='bg-wave' sx={style_ProfileCard_bgWave(bg)}></Box>
      )}
      {userProfileLink && (
        <IconPopup
          showPopup={showIconPopup}
          closeIconPopup={closeIconPopup}
          profileLink={userProfileLink as string}
        />
      )}
      <Stack>
        <LayoutWrapper sx={{ direction: user?.languageDir ?? 'ltr' }}>
          {userBgImage && (
            <Box className='bg-image' sx={style_ProfileCard_bgImage(bgImage)}></Box>
          )}

          <LayoutContainer sx={style_ProfileCard_container()}>
            <ProfileEditAndDesignBtn />
            <Box
              component='figure'
              sx={style_ProfileCard_figure({ alignment, isMobile })}
            >
              {showImage && !!userImage && (
                <Box
                  sx={{
                    ...style_ProfileCard_userImageWrapper(),
                    maxWidth: { xs: 280, md: 500 },
                  }}
                >
                  <Link to={'/edit-user'}>
                    <Box sx={style_ProfileCard_userImage()} aria-label='user img'>
                      <LayoutProfileImg />
                    </Box>
                  </Link>
                </Box>
              )}
              <Stack sx={{ gridArea: 'meta-desc' }} rowGap={1.5}>
                <Box sx={{ gridArea: 'head' }}>
                  {/* USERNAME */}
                  <Typography
                    className='align'
                    variant='h1'
                    fontSize={{ xs: 30, md: 60 }}
                    color={userNameColor}
                  >
                    <Link to={'/edit-user'} style={{ color: 'inherit' }}>
                      {userName}
                    </Link>
                  </Typography>
                </Box>

                <Box sx={{ gridArea: 'title' }} className='align'>
                  {/* TITLE --------------------------------------------- */}
                  <Link to={'/edit-user'}>
                    <Typography
                      component='h3'
                      variant='h3'
                      sx={{
                        ...style_ProfileCard_title({
                          title: titleColor,
                          userBgImage,
                          theme: currentTheme,
                        }),
                        color: titleColor,
                      }}
                    >
                      {userTitle}
                    </Typography>
                  </Link>
                </Box>

                {/* SOCIAL ICONS */}
                {displaySocialLinks !== false && (
                  <Box
                    display='inline-block'
                    sx={{ gridArea: 'social' }}
                    className='align'
                  >
                    <SocialIconsList
                      emailPublic={emailPublic}
                      email={email}
                      socialLinks={socialLinks}
                      whatsapp={whatsapp}
                      address={address}
                      phone={phone}
                      styling={{ justifyContent: 'center', display: 'inline-flex' }}
                      showOnlyIcon
                    />
                  </Box>
                )}

                {/* TAGS */}
                <IfElse condition={!!tagsArrayLength && displayTags !== false}>
                  <Box sx={{ gridArea: 'tag' }} className='align'>
                    <Link to={'/edit-user'}>
                      <Tags
                        tagsArray={tagsArray}
                        tag={tag}
                        global={global}
                        sx={style_ProfileCard_tags({ alignment })}
                      />
                    </Link>
                  </Box>
                </IfElse>

                {/* CTA BUTTONS */}
                {isMeetingAndServiceAvailable && storeHeading && (
                  <Stack justifyContent={'center'} direction='row'>
                    <CallToActionBtn
                      user={user}
                      buttonStyle={{...buttonStyle, mb: 3}}
                      storeHeading={storeHeading}
                    />
                  </Stack>
                )}
              </Stack>
            </Box>
          </LayoutContainer>
        </LayoutWrapper>

        {/* sub title --------------------------------------------- */}
        <ProfileSubtitle
          paddingBottom={paddingBottom}
          paddingTop={paddingTop}
          subTitle={user.subTitle}
          subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
        />
      </Stack>
    </Grid>
  );
};

export default Layout8;
