import { Avatar, SxProps } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LanguageIcon from '@mui/icons-material/Language';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { FaSpotify, FaTiktok } from 'react-icons/fa';
const size = 'calc(100% - 10px)';
const sizeXs = 'calc(100% - 5px)';

const iconStyles = {
  width: { xs: sizeXs, md: size },
  height: { xs: sizeXs, md: size },
};

const svgProps: SxProps = {
  '& svg': iconStyles
};

const socialIcons = (
  sx?: SxProps,
  AvatarProps: any = { variant: 'circular' },
) => {
  const { variant, ...avatarPropsWithoutVariant } = AvatarProps;

  return {
    facebook: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#1877f2', ...sx }}>
        <FacebookIcon
          sx={{
            bgcolor: '#1877f2',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    instagram: (
      <Avatar
        {...AvatarProps}
        sx={{
          // background:
          //   'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)',
          background: '#E1306C',
          ...sx,
        }}
      >
        <InstagramIcon
          sx={{
            // background:
            //   'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);',
            background: '#E1306C',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    linkedin: (
      <Avatar {...avatarPropsWithoutVariant} sx={{ bgcolor: '#0a66c2', ...sx }}>
        <LinkedInIcon
          sx={{
            bgcolor: '#0a66c2',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    twitter: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#1da1f2', ...sx }}>
        {' '}
        <TwitterIcon
          sx={{
            bgcolor: '#1da1f2',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    whatsapp: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#25d366', ...sx }}>
        <WhatsAppIcon
          sx={{
            bgcolor: '#25d366',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    youtube: (
      <Avatar {...avatarPropsWithoutVariant}
      variant='circular' sx={{ bgcolor: '#ff0000', ...sx }}>
        <YouTubeIcon
          sx={{
            bgcolor: '#ff0000',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    email: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#DB4437', ...sx }}>
        <EmailIcon
          sx={{
            bgcolor: '#DB4437',
            ...iconStyles,
          }}
        />
      </Avatar>
    ),
    others: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#1877f2', ...sx }}>
        <LanguageIcon sx={iconStyles} />
      </Avatar>
    ),
    phone: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#1877f2', ...sx }}>
        <LocalPhoneIcon sx={iconStyles} />
      </Avatar>
    ),
    address: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#de4b3e', ...sx }}>
        <PinDropIcon sx={iconStyles} />
      </Avatar>
    ),
    tiktok: (
      <Avatar {...AvatarProps} sx={{ bgcolor: 'white', ...svgProps, ...sx }}>
        <FaTiktok color='#FE2C55'/>
        {/* <svg
          fill='transparent'
          stroke='#FE2C55'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 50 50'
          width='100%'
          height='100%'
        >
          <path d='M 0 25 A 1 1 0 0 0 50 25 A 1 1 0 0 0 0 25 Z z M 37.006 22.323 c -0.227 0.021 -0.457 0.035 -0.69 0.035 c -2.623 0 -4.928 -1.349 -6.269 -3.388 c 0 5.349 0 11.435 0 11.537 c 0 4.709 -3.818 8.527 -8.527 8.527 s -8.527 -3.818 -8.527 -8.527 s 3.818 -8.527 8.527 -8.527 c 0.178 0 0.352 0.016 0.527 0.027 v 4.202 c -0.175 -0.021 -0.347 -0.053 -0.527 -0.053 c -2.404 0 -4.352 1.948 -4.352 4.352 s 1.948 4.352 4.352 4.352 s 4.527 -1.894 4.527 -4.298 c 0 -0.095 0.042 -19.594 0.042 -19.594 h 4.016 c 0.378 3.591 3.277 6.425 6.901 6.685 V 22.323 z' />
        </svg> */}
      </Avatar>
    ),
    spotify: (
      <Avatar {...AvatarProps} sx={{ bgcolor: 'white', ...sx }}>
        <FaSpotify color='#1DB954' width='100%' height='100%' style={{width: '100%', height: '100%'}} />
        {/* <svg
          fill='#1DB954'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 24 24'
          width='100%'
          height='100%'
        >
          <path d='M 17.9 10.9 C 14.7 9 9.35 8.8 6.3 9.75 c -0.5 0.15 -1 -0.15 -1.15 -0.6 c -0.15 -0.5 0.15 -1 0.6 -1.15 c 3.55 -1.05 9.4 -0.85 13.1 1.35 c 0.45 0.25 0.6 0.85 0.35 1.3 c -0.25 0.35 -0.85 0.5 -1.3 0.25 m -0.1 2.8 c -0.25 0.35 -0.7 0.5 -1.05 0.25 c -2.7 -1.65 -6.8 -2.15 -9.95 -1.15 c -0.4 0.1 -0.85 -0.1 -0.95 -0.5 c -0.1 -0.4 0.1 -0.85 0.5 -0.95 c 3.65 -1.1 8.15 -0.55 11.25 1.35 c 0.3 0.15 0.45 0.65 0.2 1 m -1.2 2.75 c -0.2 0.3 -0.55 0.4 -0.85 0.2 c -2.35 -1.45 -5.3 -1.75 -8.8 -0.95 c -0.35 0.1 -0.65 -0.15 -0.75 -0.45 c -0.1 -0.35 0.15 -0.65 0.45 -0.75 c 3.8 -0.85 7.1 -0.5 9.7 1.1 c 0.35 0.15 0.4 0.55 0.25 0.85 M 24 12 A 1 1 0 0 0 0 12 a 1 1 0 0 0 24 0 Z' />
        </svg> */}
      </Avatar>
    ),
    substack: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#ff6819', ...svgProps, ...sx }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='white'
          viewBox='1.862 0 445.207 448.177'
          width='100%'
          height='100%'
        >
          <path d='M 106.576 97.734 L 336.571 97.734 L 336.571 129.976 L 106.576 129.976 L 106.576 97.734 Z M 106.576 215.342 L 336.571 215.342 L 336.571 360.315 L 221.55 295.8 L 106.576 360.315 L 106.576 215.342 Z M 106.576 156.537 L 336.571 156.537 L 336.571 188.78 L 106.576 188.78 L 106.576 156.537 Z' />
        </svg>
      </Avatar>
    ),
    medium: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#000', ...svgProps, ...sx }}>
        <svg
          fill='white'
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 -55 256 256'
          preserveAspectRatio='xMidYMid'
        >
          <path d='M72.2009141,1.42108547e-14 C112.076502,1.42108547e-14 144.399375,32.5485469 144.399375,72.6964154 C144.399375,112.844284 112.074049,145.390378 72.2009141,145.390378 C32.327779,145.390378 0,112.844284 0,72.6964154 C0,32.5485469 32.325326,1.42108547e-14 72.2009141,1.42108547e-14 Z M187.500628,4.25836743 C207.438422,4.25836743 223.601085,34.8960455 223.601085,72.6964154 L223.603538,72.6964154 C223.603538,110.486973 207.440875,141.134463 187.503081,141.134463 C167.565287,141.134463 151.402624,110.486973 151.402624,72.6964154 C151.402624,34.9058574 167.562834,4.25836743 187.500628,4.25836743 Z M243.303393,11.3867175 C250.314,11.3867175 256,38.835526 256,72.6964154 C256,106.547493 250.316453,134.006113 243.303393,134.006113 C236.290333,134.006113 230.609239,106.554852 230.609239,72.6964154 C230.609239,38.837979 236.292786,11.3867175 243.303393,11.3867175 Z' />
        </svg>
      </Avatar>
    ),
    salesfronts: (
      <Avatar {...AvatarProps} sx={{ bgcolor: '#b201c7', ...svgProps, ...sx }}>
        <svg
          fill='#b201c7'
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 260 260'
          preserveAspectRatio='xMidYMid meet'
        >
          <g
            xmlns='http://www.w3.org/2000/svg'
            stroke='none'
            transform='translate(0.000000,260.000000) scale(0.100000,-0.100000)'
          >
            <path d='M 1326 2510.746 C 1298 2506.746 1238.774 2488.114 1197.774 2458.959 C 1141.642 2419.044 1104 2396 1015 2306 C 954 2245 877 2155 843 2105 L 782 2015 L 638 1997 C 362 1964 154 1892 86 1807 C -13 1683 105 1377 359 1099 L 429 1023 L 389 931 C 368 881 332 785 310 719 C 223 456 231 335 343 213 C 395 156 462 123 544 111 C 633 98 714 117 832 175 C 917 217 1164 376 1234 433 L 1263 457 L 1386 373 C 1530 276 1671 204 1790 164 C 1863 140 1892.915 122.798 1990.906 121.473 C 2064.886 120.473 2112 138 2158 159 C 2222 188 2273 245 2297 314 C 2311 354 2315 394 2314 495 C 2313 641 2295 732 2231 910 L 2193 1014 L 2295 1140 C 2415 1289 2494.095 1396.464 2527 1465 C 2548.139 1509.029 2568.405 1564.012 2563.433 1639.739 C 2558.623 1713.003 2555.943 1730.786 2528.222 1783 C 2458.385 1914.544 2317 1976 2059 1987 L 1912 1993 C 1891.997 2035.199 1847.495 2124.992 1841.578 2124.992 C 1801.652 2197.1 1652 2435 1515 2483 C 1460 2502 1385 2519.746 1326 2510.746 Z M 1519 2199 C 1617 2155 1713 2042 1774 1898 L 1802 1832 L 1929 1828 C 2028 1824 2067 1818 2113 1801 C 2185 1773 2234.001 1730.133 2266.801 1673.029 C 2306.489 1603.934 2281.557 1476.833 2258.725 1430.242 C 2238.775 1389.531 2158 1272 2077 1177 L 2011 1099 L 2036 1033 C 2095 877 2119 669 2089 579 C 2056 480 1990 441 1859 440 C 1720 440 1583 492 1402 614 C 1356 645 1314 670 1309 670 C 1304 670 1269 648 1231 620 C 1125 543 984 463 912 439 C 796 400 681 426 610 506 C 564 559 550 599 550 679 C 550 758 581 875 639 1017 L 677 1109 L 652 1132 C 598 1182 487 1338 448 1419 C 412 1492 393.556 1537.895 387.982 1585.032 C 383.05 1626.745 398 1667 430 1704 C 448 1724 480 1750 502 1761 C 559 1790 710 1828 835 1843 C 930 1856 946 1861 960 1881 C 1051 2020 1098.982 2079.004 1175.141 2139.872 C 1264.507 2211.295 1325.878 2234.352 1390.878 2234.352 C 1444.878 2234.352 1488 2214 1519 2199 Z' />
          </g>
        </svg>
      </Avatar>
    ),
    fronts: (
      <Avatar {...avatarPropsWithoutVariant} sx={{ bgcolor: 'white', ...svgProps, ...sx }}>
        <svg
          fill='#b201c7'
          xmlns='http://www.w3.org/2000/svg'
          width='100%'
          height='100%'
          viewBox='0 0 260 260'
          preserveAspectRatio='xMidYMid meet'
        >
          <g
            xmlns='http://www.w3.org/2000/svg'
            stroke='none'
            transform='translate(0.000000,260.000000) scale(0.100000,-0.100000)'
          >
            <path d='M 1326 2510.746 C 1298 2506.746 1238.774 2488.114 1197.774 2458.959 C 1141.642 2419.044 1104 2396 1015 2306 C 954 2245 877 2155 843 2105 L 782 2015 L 638 1997 C 362 1964 154 1892 86 1807 C -13 1683 105 1377 359 1099 L 429 1023 L 389 931 C 368 881 332 785 310 719 C 223 456 231 335 343 213 C 395 156 462 123 544 111 C 633 98 714 117 832 175 C 917 217 1164 376 1234 433 L 1263 457 L 1386 373 C 1530 276 1671 204 1790 164 C 1863 140 1892.915 122.798 1990.906 121.473 C 2064.886 120.473 2112 138 2158 159 C 2222 188 2273 245 2297 314 C 2311 354 2315 394 2314 495 C 2313 641 2295 732 2231 910 L 2193 1014 L 2295 1140 C 2415 1289 2494.095 1396.464 2527 1465 C 2548.139 1509.029 2568.405 1564.012 2563.433 1639.739 C 2558.623 1713.003 2555.943 1730.786 2528.222 1783 C 2458.385 1914.544 2317 1976 2059 1987 L 1912 1993 C 1891.997 2035.199 1847.495 2124.992 1841.578 2124.992 C 1801.652 2197.1 1652 2435 1515 2483 C 1460 2502 1385 2519.746 1326 2510.746 Z M 1519 2199 C 1617 2155 1713 2042 1774 1898 L 1802 1832 L 1929 1828 C 2028 1824 2067 1818 2113 1801 C 2185 1773 2234.001 1730.133 2266.801 1673.029 C 2306.489 1603.934 2281.557 1476.833 2258.725 1430.242 C 2238.775 1389.531 2158 1272 2077 1177 L 2011 1099 L 2036 1033 C 2095 877 2119 669 2089 579 C 2056 480 1990 441 1859 440 C 1720 440 1583 492 1402 614 C 1356 645 1314 670 1309 670 C 1304 670 1269 648 1231 620 C 1125 543 984 463 912 439 C 796 400 681 426 610 506 C 564 559 550 599 550 679 C 550 758 581 875 639 1017 L 677 1109 L 652 1132 C 598 1182 487 1338 448 1419 C 412 1492 393.556 1537.895 387.982 1585.032 C 383.05 1626.745 398 1667 430 1704 C 448 1724 480 1750 502 1761 C 559 1790 710 1828 835 1843 C 930 1856 946 1861 960 1881 C 1051 2020 1098.982 2079.004 1175.141 2139.872 C 1264.507 2211.295 1325.878 2234.352 1390.878 2234.352 C 1444.878 2234.352 1488 2214 1519 2199 Z' />
          </g>
        </svg>
      </Avatar>
    ),
  };
};

export default socialIcons;

export type SocialIconsKeys = keyof ReturnType<typeof socialIcons>;
export const socialIconsKeys = Object.keys(socialIcons()) as unknown as SocialIconsKeys; 

