import { FormData } from './types';
import { NewVirtualService, ServiceTypes, VirtualService } from 'types/service';
import serviceDefaults from 'defaults/serviceDefaults';

export const DEFAULT_SERVICE_IMG = serviceDefaults.digitalServiceImg;

export const initialCreateService: FormData = {
  status: true,
  title: 'Service',
  desc: 'Service description',
  isPaid: false,
  price: 1,
  img: DEFAULT_SERVICE_IMG,
  included: [],
  ctaBtnText: 'Order',
  isFormDirty: false,
};

export const getFormInitialValues = (
  isCreateMode: boolean,
  entityType: 'virtual' | 'physical' | 'service',
  service: VirtualService | undefined,
  exchangeRates: any
): FormData => {
  if (!service) {
    return {
      isPaid: false,
      price: 0,
      title: '',
      desc: '',
      img: '',
      included: [],
      file: undefined,
      deletedImg: undefined,
      status: false,
      ctaBtnText: '',
      isFormDirty: false,
    };
  }

  return {
    isPaid: service.isPaid,
    price: service.price ?? 0,
    title: service.title,
    desc: service.desc,
    img: service.img,
    included: service.included,
    file: undefined,
    deletedImg: undefined,
    status: service.status === 'active',
    ctaBtnText: service.ui.ctaBtnText,
    isFormDirty: false,
  };
};

export function createNewService(
  submitedData: FormData,
  loggedInUserId: string
): NewVirtualService {
  const { desc, isPaid, status, title, price, img, included, ctaBtnText } = submitedData;

  return {
    userId: loggedInUserId,
    isDeleted: false,
    status: status ? 'active' : 'inactive',
    type: ServiceTypes.Virtual,
    title,
    img: img,
    desc,
    included: included.length ? included : [],
    isPaid,
    price: isPaid ? price : 0,
    ui: {
      ctaBtnText,
      includedItemsHeading: `What's Included:`,
      tagTitle: 'Online Service',
    },
  };
}

export function createEditedServiceUpdatedContext(
  submittedData: FormData,
  editedService: VirtualService
): VirtualService {
  const { desc, img, status, included, title, isPaid, price, ctaBtnText } = submittedData;
  const { _id, userId, type, ui, isDeleted } = editedService;
  return {
    _id,
    userId,
    isDeleted,
    type,
    status: status ? 'active' : 'inactive',
    title,
    desc,
    isPaid,
    img,
    included,
    ui: {
      ...ui,
      ctaBtnText,
    },
    price: isPaid ? price : 0,
  };
}