import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import Link from '@mui/material/Link';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia';
import Typography, { TypographyProps } from '@mui/material/Typography';
import {
  Box,
  BoxProps,
  CardActionArea,
  CardActionAreaProps,
  IconButton,
  Stack,
  SxProps,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import useHandleImgOnError from 'hooks/useHandleImgOnError';

import { ThemeType } from 'themes/AppThemeProvider/themeType';
import { PortfolioItemType, PortfolioProperties } from 'types/portfolio';

import IfElse from 'components/utilities/IfElse';
import Popup from 'components/utilities/Popups/Popup';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import { PopupSectionHeading } from 'components/dashboard/DashboardReusableComponent';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import UploadImage from 'components/utilities/Cloudinary/UploadImage';
import uploadImageService, { regexForGifType } from 'lib/services/uploadImageService';
import { twoMb } from 'components/utilities/TinyMceEditor/tinymceImgUploader';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import useAsyncStatus from 'hooks/useAsyncStatus';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import EditPortfolioReadMore from '../edit-portfolio/EditPortfolioReadMore';
import { portfolioMaxHeight } from '../PortfolioDrawer';
import { useStyledTheme } from 'components/ShareComponents/StyledTheme';

// Constant variable
export enum portfolioElementId {
  titleId = 'portfolio-title',
  descriptionId = 'portfolio-desc',
}

// Types
export type callbackFnBtnLabelURL = (buttonLabel: string, url: string) => void;
export type callbackFnPortfolioImg = (
  img: string | File,
  type: 'upload' | 'remove'
) => void;
interface PortfolioButtonLabelProps {
  portfolio: PortfolioItemType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callbackfn?: callbackFnBtnLabelURL;
}
interface PortfolioDesignProps {
  portfolioSylings: ThemeType['portfolios'];
  cardProps: CardProps;
  cardActionAreaProps: CardActionAreaProps;
  cardMediaProps: CardMediaProps;

  titleProps: TypographyProps;
  descriptionProps: BoxProps;
  URLProps: {
    showURL: boolean;
  };
  isEditMode?: boolean;
  cardActions?: React.ReactNode;

  portfolio: PortfolioItemType;
  portfolioIndex?: number;
  portfoliosProperties: PortfolioProperties;

  cardWrapper?: SxProps;

  callbackfn?: callbackFnBtnLabelURL;
  callbackfnImg?: callbackFnPortfolioImg;
}

// Main component
const PortfolioDesign = ({
  isEditMode = false,
  portfolioSylings,
  cardWrapper,
  cardProps,
  cardActions,
  cardActionAreaProps,
  cardMediaProps,

  portfolio,
  portfolioIndex,
  portfoliosProperties,

  descriptionProps,
  titleProps,
  URLProps,

  callbackfn,
  callbackfnImg,
}: PortfolioDesignProps) => {
  // Hooks and states
  const [open, setOpen] = useState(false);
  const { handleImageError, showImage } = useHandleImgOnError();
  const {
    button,
    imgShadow,
    contentPadding,
    titleStyle,
    descriptionStyle,
    ...restStyle
  } = portfolioSylings.item;
  const styledTheme = useStyledTheme();
  const { image, textAlign } = portfoliosProperties;

  const isButtonLabelEmpty =
    isEditMode && !(portfolio.buttonLabel && portfolio.buttonLabel.trim());

  function handleLinkClick(e: React.MouseEvent<HTMLAnchorElement>) {
    if (isEditMode) {
      e.preventDefault();
      setOpen(true);
    }
  }

  return (
    <Box
      className='portfolio-card-wrapper'
      sx={{
        px: isEditMode ? 0 : 2,
        py: 2,
        overflowX: isEditMode ? 'hidden' : 'auto',
        ...cardWrapper,
      }}
    >
      <Card
        sx={{
          position: 'relative',
          ...restStyle,
          margin: '0 auto',
          overflow: 'visible',
          color: titleStyle.color,
          '& .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight': {
            opacity: 0,
          },
        }}
        variant='elevation'
        raised={false}
        {...cardProps}
      >
        {cardActions && cardActions}
        <CardActionArea disableRipple={!!isEditMode} {...cardActionAreaProps}>
          {isEditMode ? (
            <UploadPortfolioImage
              callbackfn={callbackfnImg}
              portfolio={portfolio}
              portfolioProperties={portfoliosProperties}
              sx={{
                maxHeight: portfolioMaxHeight,
                width: '100%',
                height: image.height,
                maxWidth: '100%',
                objectFit: image.objectFit,
                objectPosition: image.objectPosition,
                borderRadius: image.borderRadius + ' !important',
                clipPath: image.clipPath,
                outline: `1px dashed ${titleStyle.color}`,
                filter: `drop-shadow(${imgShadow})`,
                '&:hover': {
                  outline: isEditMode ? styledTheme.outlineHover : 'none',
                  boxShadow: isEditMode ? styledTheme.boxShadowSelected : 'none',
                },
                '& .throttle-upload-btn': {
                  border: 0,
                },
                '& .upload-image-icon': {
                  width: 100,
                  height: 100,
                  color: titleStyle.color,
                },
              }}
            />
          ) : (
            showImage &&
            !!portfolio.img && (
              <CardMedia
                className='portfolio-img'
                component='img'
                onError={handleImageError}
                sx={{
                  maxWidth: '100%',
                  height: image.height,
                  objectFit: image.objectFit,
                  objectPosition: image.objectPosition,
                  borderRadius: image.borderRadius + ' !important',
                  clipPath: image.clipPath,
                  maxHeight: portfolioMaxHeight,
                  width: '100%',
                  filter: `drop-shadow(${imgShadow})`,
                }}
                alt='portfolio-img'
                {...cardMediaProps}
              />
            )
          )}
          <CardContent
            sx={{
              color: titleStyle.color,
              paddingBottom: !!portfolio.url ? 0 : '10px',
              p: contentPadding,
              textAlign,
              mb: 2,
              mt: 2,
              '& *': {
                wordBreak: 'break-word',
              },
            }}
          >
            <Heading
              title={portfolio.title}
              isEditMode={isEditMode}
              titleProps={titleProps}
              titleStyle={titleStyle}
            />
            <Description
              isEditMode={isEditMode}
              descriptionProps={descriptionProps}
              descriptionStyle={descriptionStyle}
              titleStyle={titleStyle}
            />
            <EditPortfolioReadMore
              currentportfolioId={portfolioIndex}
              currentPortfolio={portfolio}
              isEditMode={isEditMode}
              titleStyle={titleStyle}
            />
          </CardContent>
        </CardActionArea>

        {/*  portfolio button */}
        <IfElse condition={isEditMode || !!URLProps.showURL}>
          <Link
            className='portfolio-link'
            textAlign='center'
            display='block'
            target='_blank'
            rel='noreferrer'
            onClick={handleLinkClick}
            href={portfolio.url}
            underline='always'
            sx={{
              ...button,
              boxShadow: isButtonLabelEmpty ? 'none' : button.boxShadow,
              background: isButtonLabelEmpty ? 'transparent' : button.background,
              color: isButtonLabelEmpty ? titleStyle.color : button.color,
              border: isEditMode ? 'none' : button.border,
              outline:
                isEditMode && isButtonLabelEmpty
                  ? `1px dashed ${titleStyle.color}`
                  : 'none',
              minHeight: 40,
              width: '100%',
              textDecoration: 'none',
              textOverflow: 'ellipsis',
              whiteSpace: 'break-spaces',
              overflow: 'hidden',
              '&:hover, &:focus ': {
                textDecoration: 'underline',
                outline: isEditMode
                  ? styledTheme.outlineHover
                  : `1px dashed ${titleStyle.color}`,
                boxShadow: isEditMode ? styledTheme.boxShadowSelected : 'none',
              },
            }}
          >
            {portfolio.buttonLabel || portfolio.url || 'Link'}
          </Link>
        </IfElse>
      </Card>
      <PortfolioButtonLabelURL
        portfolio={portfolio}
        open={open}
        setOpen={setOpen}
        callbackfn={callbackfn}
      />
    </Box>
  );
};

export default PortfolioDesign;

// Additional components
function PortfolioButtonLabelURL({
  portfolio,
  open,
  setOpen,
  callbackfn,
}: PortfolioButtonLabelProps) {
  const [portfolioBtnState, setPortfolioBtnState] = useState<
    Pick<PortfolioItemType, 'buttonLabel' | 'url'>
  >({
    buttonLabel: '',
    url: '',
  });

  useEffect(() => {
    if (portfolio?.buttonLabel) {
      setPortfolioBtnState((prev) => ({
        ...prev,
        buttonLabel: portfolio.buttonLabel,
      }));
    }
    if (portfolio?.url) {
      setPortfolioBtnState((prev) => ({
        ...prev,
        url: portfolio.url,
      }));
    }
  }, [portfolio.buttonLabel, portfolio.url]);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    const { buttonLabel, url } = portfolioBtnState;
    e.preventDefault();
    if (buttonLabel && !url) {
      toast.error('URL is required');
      return;
    }
    callbackfn?.(portfolioBtnState.buttonLabel || '', portfolioBtnState.url || '');
    setOpen(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const buttonLabelLength = 50;
    if (e.target.name === 'buttonLabel') {
      let value = e.target.value.trim();
      if (value.length > buttonLabelLength) return;
    }

    setPortfolioBtnState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  function handleClear(
    e: React.MouseEvent<HTMLButtonElement>,
    clickedField?: keyof typeof portfolioBtnState
  ) {
    e.preventDefault();
    if (clickedField === 'url') {
      setPortfolioBtnState((prev) => ({
        ...prev,
        url: '',
      }));
    }
    if (clickedField === 'buttonLabel') {
      setPortfolioBtnState((prev) => ({
        ...prev,
        buttonLabel: '',
      }));
    }
  }

  return (
    <Popup
      setOpen={setOpen}
      dialogProps={{
        open,
        fullWidth: true,
      }}
    >
      <Stack
        onSubmit={handleSubmit}
        direction='column'
        component={'form'}
        useFlexGap
        spacing={2}
        sx={{ p: 3 }}
      >
        <Stack direction={'column'} spacing={0.5}>
          <PopupSectionHeading>URL</PopupSectionHeading>
          <TextFieldSyncWithTheme
            name='url'
            value={portfolioBtnState.url}
            onChange={handleChange}
            placeholder='Type URL here..'
            type='url'
            label=''
            InputProps={{
              endAdornment: (
                <IconButton onClick={(e) => handleClear(e, 'url')} color='error'>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>
        <Stack direction={'column'} spacing={0.5}>
          <PopupSectionHeading>Button Label </PopupSectionHeading>
          <TextFieldSyncWithTheme
            value={portfolioBtnState.buttonLabel}
            onChange={handleChange}
            name='buttonLabel'
            type='text'
            placeholder='Type button label here..'
            label=''
            InputProps={{
              endAdornment: (
                <IconButton onClick={(e) => handleClear(e, 'buttonLabel')} color='error'>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>

        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
          <ResponsiveButton
            size='small'
            onClick={() => setOpen(false)}
            type='button'
            variant='text'
            color='secondary'
          >
            Cancel
          </ResponsiveButton>
          <ResponsiveButton
            size='small'
            type='submit'
            color='primary'
            variant='contained'
          >
            Save
          </ResponsiveButton>
        </Stack>
      </Stack>
    </Popup>
  );
}

function UploadPortfolioImage({
  portfolio,
  sx,
  callbackfn,
  portfolioProperties,
}: {
  portfolio: PortfolioItemType;
  sx: SxProps;
  callbackfn?: callbackFnPortfolioImg;
  portfolioProperties: PortfolioProperties;
}) {
  const [portfolioImg, setPortfolioImg] = useState('');
  const { Actions, isLoading, updateStatus } = useAsyncStatus();
  const height = Number(portfolioProperties.image.height) || 300;
  const newHeight = isNaN(height) ? 300 : height;

  useEffect(() => {
    if (portfolio?.img) {
      setPortfolioImg(portfolio?.img);
    } else {
      setPortfolioImg('');
    }
  }, [portfolio?.img]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    updateStatus({ type: Actions.LOADING });
    try {
      if (!file) return;
      const isGifFile = file?.name.match(regexForGifType);

      // -------------- uploading gif ------------------------
      if (isGifFile) {
        if (file?.size > twoMb) {
          toast.error('Cannot upload Max gif file size limit 1mb.');
          return;
        } else {
          try {
            const uploadedImgURL = await uploadImageService.uploadImg(e);
            setPortfolioImg(uploadedImgURL);
            callbackfn?.(uploadedImgURL, 'upload');
            updateStatus({
              type: Actions.SUCCESS,
              payload: 'Gif uploaded successfully',
            });
            return;
          } catch (error) {
            if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
              updateStatus({
                type: Actions.ERROR,
                payload: 'Error uploading gif',
              });
              console.error(error);
            }
          }
        }
      }

      // -------------- uploading img ------------------------
      new Compressor(file, {
        quality: 1,
        maxWidth: 1200,
        maxHeight: 1200,
        success(result) {
          (async () => {
            try {
              const uploadedImgURL =
                await uploadImageService.uploadImageWithAuthentication(result);
              setPortfolioImg(uploadedImgURL);
              callbackfn?.(uploadedImgURL, 'upload');
              updateStatus({
                type: Actions.SUCCESS,
                payload: 'Image uploaded successfully',
              });
            } catch (error) {
              if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
                console.error(error);
                updateStatus({
                  type: Actions.ERROR,
                  payload: 'Error uploading image',
                });
              }
            }
          })();
        },
        error: (error) => {
          console.error(error);
        },
      });
    } catch (error) {
      updateStatus({ type: Actions.ERROR, payload: 'Error uploading image' });
      if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    }
  };

  const handleRemoveCallback = async (img: string | File) => {
    setPortfolioImg('');
    callbackfn?.(img, 'remove');

    // TODO: remove image from cloudinary
    /*if (
      typeof img === 'string' &&
      img?.includes('blob:http') &&
      img.includes('localhost')
      ) {
      return;
    }
    try {
      if (img instanceof File) return;
      await uploadImageService.deleteImg(img);
    } catch (err) {
      console.log(err);
    } finally {
      setPortfolioImg('');
      callbackfn?.(img, 'remove');
    }*/
  };

  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
        '& .upload-image-container': {
          p: '0',
          '& .image-wrapper': {
            width: '100%',
            maxWidth: '100%',
          },
          '& .image-upload-btn': {
            width: '100%',
            maxWidth: '100%',
          },
          '& img': {
            objectFit: 'contain',
          },
          '& *': {
            boxShadow: 'none',
          },
        },
      }}
    >
      <SectionLoader isLoading={isLoading} sx={{ zIndex: 1300 }} />
      <UploadImage
        showClearIcon={true}
        img={portfolioImg}
        imgUploadCallback={handleImageUpload}
        removeCallback={handleRemoveCallback}
        height={newHeight}
        width={newHeight}
        uploadBtnWrapperStyle={{ position: 'absolute', top: 0, left: 0 }}
      />
    </Box>
  );
}

function Heading({
  isEditMode,
  titleStyle,
  titleProps,
  title,
}: {
  title: string;
  isEditMode?: boolean;
  titleStyle: ThemeType['portfolios']['item']['titleStyle'];
  titleProps: TypographyProps;
}) {
  const styledTheme = useStyledTheme();
  const [isSelected, setIsSelected] = React.useState(false);
  const maxLength = 100;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const textContent = e.target instanceof HTMLElement && e.target.textContent;
    const isTypewriterOrNumericKey = /^[a-zA-Z0-9]$/.test(e.key);

    if (textContent && textContent?.length >= maxLength && isTypewriterOrNumericKey) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    let text = e.clipboardData.getData('text/plain');
    if (text.length >= maxLength) {
      text = text.slice(0, maxLength - 1);
    }
    document.execCommand('insertText', false, text);
  };

  return (
    <Typography
      suppressContentEditableWarning={true}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      data-id={portfolioElementId.titleId}
      {...titleProps}
      contentEditable={isEditMode}
      variant='h5'
      textAlign='left'
      mb='0.5rem'
      fontWeight='600'
      onFocus={() => setIsSelected(true)}
      onBlur={() => setIsSelected(false)}
      sx={{
        ...titleStyle,
        width: '100%',
        minHeight: isEditMode ? '32px' : 'auto',
        mt: 1,
        textAlign: 'inherit',
        outline: isSelected
          ? `1px dashed ${titleStyle.color}`
          : isEditMode
          ? `1px dashed ${titleStyle.color}`
          : 'none',
        boxShadow: isSelected ? styledTheme.boxShadowSelected : 'none',
      }}
    >
      {title}
    </Typography>
  );
}

function Description({
  isEditMode,
  titleStyle,
  descriptionStyle,
  descriptionProps,
}: {
  isEditMode?: boolean;
  titleStyle: ThemeType['portfolios']['item']['titleStyle'];
  descriptionStyle: ThemeType['portfolios']['item']['descriptionStyle'];
  descriptionProps: BoxProps;
}) {
  const styledTheme = useStyledTheme();
  const [isSelected, setIsSelected] = React.useState(false);
  const maxLength = 300;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const textContent = e.target instanceof HTMLElement && e.target.textContent;
    const isTypewriterOrNumericKey = /^[a-zA-Z0-9]$/.test(e.key);

    if (textContent && textContent?.length >= maxLength && isTypewriterOrNumericKey) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    let text = e.clipboardData.getData('text/plain');
    if (text.length + (e.currentTarget.textContent?.length || 0) >= maxLength) {
      text = text.slice(0, maxLength - 5);
    }
    document.execCommand('insertText', false, text);
  };

  return (
    <Box
      suppressContentEditableWarning={true}
      data-id={portfolioElementId.descriptionId}
      contentEditable={isEditMode}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      onFocus={() => setIsSelected(true)}
      onBlur={() => setIsSelected(false)}
      sx={{
        wordBreak: 'break-word',
        mt: 2,
        width: '100%',
        minHeight: isEditMode ? '100px' : 'auto',
        ...descriptionStyle,
        outline: isSelected
          ? `1px dashed ${titleStyle.color}`
          : isEditMode
          ? `1px dashed ${titleStyle.color}`
          : 'none',
        boxShadow: isSelected ? styledTheme.boxShadowSelected : 'none',
        '& * ': {
          wordBreak: 'break-word',
          maxWidth: '100%',
        },
        '& img': {
          maxHeight: '300px',
          maxWidth: '100%',
          objectFit: 'contain',
        },
      }}
      {...descriptionProps}
    />
  );
}
