import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export type DashboardMenu =
| 'sites'
| 'meetings'
| 'orders'
| 'subscription'
| 'payment-collection'
| 'editor'
| 'domain';

export interface IDashboardModule {
  is_sidebar_minified: boolean;
}

const initialState: IDashboardModule = {
  is_sidebar_minified: false,
};

export const DashboardModule = createSlice({
  name: 'DashboardUser',
  initialState,
  reducers: {
    toggleSideBar: (state) => {
      state.is_sidebar_minified = !state.is_sidebar_minified;
    },
    set_sidebar_minified: (state, actions: PayloadAction<boolean>) => {
      state.is_sidebar_minified = actions.payload;
    },
  },
});

export const { toggleSideBar, set_sidebar_minified } = DashboardModule.actions;

export function select_dashboard_user_module(state: RootState) {
  return state.persistedReducer.dashboardModule;
}

export function select_dashboard_user_is_sidebar_minified(state: RootState): boolean {
  return select_dashboard_user_module(state).is_sidebar_minified;
}

export default DashboardModule.reducer;
