import { Box, SxProps, Theme, Typography, TypographyProps } from '@mui/material';

export const dashboard_popup_icon_size: SxProps<Theme> = {
  width: { xs: 20, md: 25 },
  height: { xs: 20, md: 25 },
  color: (theme) => theme.palette.text.secondary,
};

export enum StatusValues { // Need to put it in a separate file
  GUEST_REQUEST_MEETING = 'guest-request-meeting',
  PROPOSE_NEW_TIME = 'host-propose-new-times',
  CONFIRM = 'confirmed',
  REJECT = 'rejected',
  CANCELLED = 'cancelled',
  PAYMENT_WAITING = 'payment-waiting',
  PAID = 'paid',
  GUEST_SELECTED_PROPOSED_TIME = 'guest-selected-proposed-time',
}

export function PopupHeading({ ...props }: TypographyProps) {
  return (
    <Typography variant={'h5'} fontWeight={600} color={'text.primary'} {...props}>
      {props.children}
    </Typography>
  );
}

export function PopupSectionHeading({ ...props }: TypographyProps) {
  return (
    <Typography
      variant={'body2'}
      fontSize={{ xs: '1.3rem', sm: '1.4rem', md: '1.5rem' }}
      component={'span'}
      fontWeight={600}
      {...props}
    >
      {props.children}
    </Typography>
  );
}

export function PopupText({ ...props }: TypographyProps) {
  return (
    <Typography
      variant={'body2'}
      fontSize={{ xs: '1.4rem', sm: '1.5rem', md: '1.6rem' }}
      fontWeight={400}
      {...props}
    >
      {props.children}
    </Typography>
  );
}

interface IconTextPairProps
  extends TypographyProps,
    Partial<Pick<HTMLAnchorElement, 'target' | 'href' | 'rel'>> {
  icon?: React.ReactNode;
  text?: React.ReactNode;
  children?: React.ReactNode;
  boxSx?: SxProps<Theme>;
}

export function IconTextPair({
  icon,
  text,
  boxSx,
  children,
  ...typographyProps
}: IconTextPairProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '20px 1fr',
        gap: 1.5,
        alignItems: 'center',
        ...boxSx,
      }}
    >
      {icon && icon}
      {children && children}
      {text && <Typography variant={'body2'} component={'span'} fontWeight={400} {...typographyProps}>
        {text}
      </Typography>}
    </Box>
  );
}

export function EmptyTabPanel({ ...props }: TypographyProps) {
  return (
    <Typography mt={2} variant={'body2'} {...props}>
      No orders found
    </Typography>
  );
}

// ------------ Dashobard Table ------------

export function TableHeadingMobile({ ...props }: TypographyProps) {
  return (
    <Typography variant={'body1'} fontWeight={600} {...props}>
      {props.children}
    </Typography>
  );
}

export function TableStatusMobile({ ...props }: TypographyProps) {
  return (
    <Typography fontWeight={500} textTransform={'capitalize'} variant={'body2'} {...props}>
      {props.children}
    </Typography>
  );
}

export function TableTextMobile({ ...props }: TypographyProps) {
  return (
    <Typography variant={'body2'} {...props}>
      {props.children}
    </Typography>
  );
}
