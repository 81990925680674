import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import { ThemeType } from 'themes/AppThemeProvider/themeType';

const themesExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getThemes: build.query<ThemeType[], void>({
      query: () => '/themes/request-themes-json',
      providesTags: ['Theme'],
    }),
    getThemeById: build.query<ThemeType, number>({
      query: (themeId) => `/themes/request-themes-json/${themeId}`,
      providesTags: (result, error, themeId) => [{ type: 'Theme', id: themeId }],
    }),
  }),
});

export const { useGetThemesQuery, useGetThemeByIdQuery } = themesExtendedApi;

export default themesExtendedApi;
