import React from 'react';
import {
  ButtonBase,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { Session } from 'types/sessions';
import { MeetingTypes } from 'types/meeting';

import { dashboard_icon_size } from 'views/Dashboard/Dashboard';
import { convert_utc_to_local } from 'utilities/calendarUtilities';
import { getStatusForGuest } from 'views/guestSession/GuestSession/sub-components/SessionDetails/reusable-components/getStatusForGuest';

import CustomTable from 'components/Table/CustomTable';
import { HeadCell } from 'components/Table/CustomTableHead';
import MeetingDetailsPopup from './MeetingDetailsPopup';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  TableHeadingMobile,
  TableStatusMobile,
  TableTextMobile,
} from 'components/dashboard/DashboardReusableComponent';

export const meetingManagmentHeadCells: readonly HeadCell[] = [
  {
    id: 'status',
    label: 'Status',
    numeric: false,
  },
  {
    id: 'title',
    label: 'Meeting Title',
    numeric: false,
  },
  {
    id: 'participant',
    label: 'Participant Name',
    numeric: false,
  },
  {
    id: 'startAt',
    label: 'Start at',
    numeric: true,
  },
  {
    id: 'isPaid',
    label: 'Paid',
    numeric: false,
  },
  {
    id: 'type',
    label: 'Meeting Type',
    numeric: false,
  },
  {
    id: 'details',
    label: 'Details',
    numeric: false,
  },
];

export const meetingTypeToShow = (type: MeetingTypes) => {
  return type === MeetingTypes.Physical ? 'In person meeting' : 'Online meeting';
};

export default function MeetingSessions({ tableData }: { tableData: readonly Session[] }) {
  const [sessionId, setSessionId] = React.useState<Session['_id'] | null>(null);
  const [open, setOpen] = React.useState(false);

  const { cachedLoggedInUser } = useGetLoggedInUser();
  const loggedInUserTimeZone = cachedLoggedInUser?.timeZone ?? 'UTC';

  const isPhone = useMediaQuery('(max-width:600px)');

  const handleClick = (id: string) => {
    if (!id) return;
    setSessionId(id);
    setOpen(true);
  };
  const timeFormat = (timeStamp: number) =>
    format(convert_utc_to_local(timeStamp, loggedInUserTimeZone), 'EEEE dd-MMMM-yyyy hh:mm a');

  return (
    <>
      {isPhone ? (
        <Stack spacing={1} mt={1}>
          {tableData.map((session) => {
            const { _id, startAt, status, participant, meetingDetails } = session;
            const { backgroundColor, content } = getStatusForGuest(status);
            return (
              <Paper
                key={_id}
                variant='outlined'
                sx={{ display: 'grid', gridTemplateColumns: '1fr 20px', padding: 1 }}
              >
                <Stack
                  component={ButtonBase}
                  direction={'column'}
                  justifyContent={'center'}
                  onClick={() => handleClick(_id)}
                  alignItems={'flex-start'}
                >
                  <TableStatusMobile color={backgroundColor}>{content}</TableStatusMobile>
                  <TableHeadingMobile>{participant.name}</TableHeadingMobile>
                  <TableTextMobile>{meetingDetails.title}</TableTextMobile>
                  <TableTextMobile>{timeFormat(startAt)}</TableTextMobile>
                </Stack>

                <IconButton onClick={() => handleClick(_id)}>
                  <MoreHorizIcon sx={dashboard_icon_size} />
                </IconButton>
              </Paper>
            );
          })}
        </Stack>
      ) : (
        <CustomTable headCells={meetingManagmentHeadCells}>
          <TableBody>
            {tableData.map(
              (
                { meetingDetails: { title, type }, startAt, isPaid, status, participant, _id },
                index
              ) => {
                const { backgroundColor, color, content } = getStatusForGuest(status);
                const startDuration = convert_utc_to_local(startAt, loggedInUserTimeZone);
                return (
                  <TableRow
                    onClick={() => handleClick(_id)}
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={index}
                    sx={{
                      '& .MuiTableCell-body': {
                        cursor: 'pointer',
                        overflowX: 'auto',
                        fontSize: '1.3rem',
                        maxWidth: '200px',
                        whitspace: 'nowrap',
                        align: 'left',
                      },
                    }}
                  >
                    <TableCell sx={{ bgcolor: backgroundColor, color: color }}>{content}</TableCell>
                    <TableCell>{title}</TableCell>
                    <TableCell>{participant.name}</TableCell>
                    <TableCell>{format(startDuration, 'dd-MMMM-yyyy hh:mm a')}</TableCell>
                    <TableCell>{isPaid ? 'True' : 'False'}</TableCell>
                    <TableCell>{meetingTypeToShow(type)}</TableCell>
                    <TableCell>
                      <IconButton>
                        <MoreHorizIcon sx={dashboard_icon_size} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </CustomTable>
      )}
      {sessionId && <MeetingDetailsPopup open={open} setOpen={setOpen} sessionId={sessionId} />}
    </>
  );
}
