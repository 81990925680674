import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/SwipeableDrawer';
import { ButtonProps } from '@mui/material/Button';

import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';

import ResponsiveButton from 'components/Buttons/ResponsiveButton';

export default function EditDrawer({
  children,
  drawerProps,
  buttonProps,
  buttonChildren,
  drawerWidth = 250,
}: {
  children?: React.ReactNode;
  drawerProps?: typeof Drawer;

  buttonProps?: ButtonProps;
  buttonChildren?: React.ReactNode;
  drawerWidth?: number;
}) {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    const targetClass = 'MuiSlider-thumb';
    const classNames = Array.from((e.target as HTMLElement).classList);

    if(classNames.includes(targetClass)) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <ResponsiveButton
        color='info'
        variant='contained'
        onClick={toggleDrawer(true)}
        endIcon={<VerticalSplitIcon />}
        {...buttonProps}
      >
        {buttonChildren || 'Design'}
      </ResponsiveButton>
      <Drawer
        onDragStart={(e) => e.preventDefault()}
        onTouchMove={handleTouchMove}
        disableSwipeToOpen
        allowSwipeInChildren
        ModalProps={{ sx: { zIndex: 1301 } }}
        anchor='right'
        open={openDrawer}
        {...drawerProps}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box
          sx={{ width: drawerWidth }}
          role='presentation'
          onClick={() => {
            toggleDrawer(false);
          }}
          onKeyDown={toggleDrawer(false)}
        >
          {children}
        </Box>
      </Drawer>
    </div>
  );
}
