import {Id, toast} from 'react-toastify';
import serverApi from 'lib/apiConfig';
import { DomainSuggestionWithPrice } from './DomainsSearchSuggestions';

export enum DOMAIN_PARAMS {
  action = 'action',
  domainName = 'domainName',
  status = 'status',
  searchedDomain = 'q',
  id = 'id',
  token = 'token',
}

const useDomains = () => {
  return {};
};

export default useDomains;

export async function verifyCredentials(loginLinkToken: string, loginToken?: string | null) {
  try {
    const response = await serverApi.basic.post(
      '/auth/verify-credentials-domain',
      {
        loginLinkToken,
        loginToken,
      },
      {
        headers: {
          Authorization: `Bearer ${loginLinkToken}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getDomainPrices(
  domainName: string,
  domainRegistrationYears: number = 1
): Promise<DomainSuggestionWithPrice> {
  try {
    const response = await serverApi.domain.get('/get-domain-prices', {
      params: {
        domainName,
        domainRegistrationYears,
      },
    });
    console.log('response ', response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export type EventDataType = {
  status?: string;
  message?: string;
  data?: any;
};

export function setupSSE(
  callback: (status: EventDataType) => void,
  eventSource: EventSource
) {
  let toastId: Id;

  console.log('in eventSource');

  // Event listener for incoming SSE messages
  eventSource.onmessage = function (event) {
    console.log('reading on message of eventSource');
    const eventData: EventDataType = JSON.parse(event.data) as {
      status?: string;
      message?: string;
      data?: any;
    };
    // Handle incoming SSE data here
    console.log('Received SSE data:', eventData);

    if (toastId) toast.dismiss(toastId);

    toastId = toast.loading(eventData.message || 'Waiting for payment...');
    callback(eventData);

    if (eventData?.status === 'error') {
      eventSource.close();
      toast.error(eventData?.message || 'Error occurred while processing payment');
      callback(eventData);
      toast.dismiss(toastId);
    }

    if (eventData?.status === 'success') {
      eventSource.close();
      toast.success('Domain purchased and set with your account successfully!');
      callback(eventData);
      toast.dismiss(toastId);
    }
  };

  // Event listener for SSE connection error
  eventSource.onerror = function (error) {
    console.log('reading on error of eventSource');
    console.error('SSE connection error:', error);
    eventSource.close();
  };
}
