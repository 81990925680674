import React from 'react';
import { Box, Button, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { SPMCardProps } from 'components/MUI_SPM/SPMCard/SPMCard';
import { useHistory } from 'react-router-dom';

// NOTE: For deleting and editing SPM

type SPMCardActionsProps = Pick<SPMCardProps, 'handleCardDeleteAction' | 'editActionRedirectPath' | 'editActionSearchParams'> & {
  background: string;
  color: string;
  borderRadius: string;
  isDirectionRtl: boolean;
};

const SPMCardActions: React.FunctionComponent<SPMCardActionsProps> = ({
  color,
  background,
  borderRadius,
  handleCardDeleteAction,
  editActionRedirectPath,
  editActionSearchParams,
  isDirectionRtl,
}) => {
  const history = useHistory();

  const handleEditEntityAction = () => {
    history.push({
      pathname: editActionRedirectPath,
      search: editActionSearchParams,
    });
  };
  return (
    <Box
      display='inline-flex'
      aria-label='Edit delete store items'
      sx={{
        background: background,
        position: 'absolute',
        right: isDirectionRtl ? '100%' : 0,
        top: 0,
        boxShadow: `0 0 4px ${grey[400]}`,
        opacity: 0.8,
        borderRadius: 0,
        borderBottomLeftRadius: '4px',
        transform: isDirectionRtl ? 'translateX(100%)' : 'translateX(0)',
        borderTopRightRadius: isDirectionRtl ? 0 : borderRadius,
        borderTopLeftRadius: isDirectionRtl ? borderRadius : 0,
      }}
    >
      {/* ----------------------- delete action ------------------------------- */}
      <Button
        onClick={handleCardDeleteAction}
        size='small'
        sx={{          
          paddingY: 2,
        }}
      >
        <DeleteIcon  sx={{ color: color }} />
      </Button>
      <Divider flexItem orientation='vertical' />
      {/* ----------------------- edit action ------------------------------- */}
      <Button
        size='small'
        onClick={handleEditEntityAction}
        sx={{          
          paddingY: 2,
        }}
      >
        <EditIcon sx={{ color: color }} />
      </Button>
    </Box>
  );
};

export default SPMCardActions;
