import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import appRoutes from 'AppRouting/appRoutes';
import PrivateRoute from 'AppRouting/PrivateRoute';
import { Header } from 'components';
import IfElse from 'components/utilities/IfElse';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import { useTheme } from '@mui/material';

const AppRouting = () => {
  const theme = useTheme();

  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={1000}
        closeOnClick
        pauseOnHover={true}
        theme={theme.palette.mode}
      />
      <Router>
        <Suspense fallback={<FrontsLoader />}>
          <Route path='/u/:url'>
            {({ match }) => {
              const url = match?.params?.url;
              if (url) {
                return <Redirect to={`/${url}`} />;
              } else {                
                return null;
              }
            }}
          </Route>
          <Switch>
            {appRoutes.map(
              ({
                path,
                Component,
                exact = true,
                showHeader = false,
                isProtected = true,
                ...otherProps
              }) => (
                <Route key={path} path={path} exact={exact}>
                  <IfElse condition={showHeader}>
                    <Header />
                  </IfElse>
                  <PrivateRoute
                    path={path}
                    isProtected={isProtected}
                    Component={Component}
                    {...otherProps}
                  />
                </Route>
              )
            )}
          </Switch>
        </Suspense>
      </Router>
    </>
  );
};

export default AppRouting;
