import { Box } from '@mui/material';
import { useEffect } from 'react';

const FrontsLoader = () => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, []);
  
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
        background: 'background.theme',
        zIndex: 1111,
        overflowY: 'hidden',
        '& .loaderFronts': {
          display: 'inline-block',
          fontSize: '26px',
          fontFamily: 'Poppins, sans-serviceDefaults',
          fontWeight: 800,
          color: '#000000',
          position: 'relative',
        },

        '.loaderFronts span::before': {
          content: '""',
          position: 'absolute',
          left: '21.5px',
          top: '10px',
          width: '20px',
          height: '20px',
          borderRadius: '50%',
          border: '4px solid #e613ff',
          borderBottomColor: '#000',
          boxSizing: 'border-box',
          animation: 'rotation .5s linear infinite',
        },

        '@keyframes rotation': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      }}
    >
      <div className='loaderFronts'>
        <span>
          fr &nbsp;&nbsp; <span style={{ color: '#e613ff' }}>n</span>ts
          {/* <sub style={{ color: '#e613ff' }}>⋆</sub>ai */}
          <span style={{ color: '#e613ff' }}>.</span>ai
        </span>
      </div>
    </Box>
  );
};

export default FrontsLoader;
