import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { AxiosError } from 'axios';
import OtpInput from 'components/InputField/OtpInput';
import serverApi from 'lib/apiConfig';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { LoggedInUser } from 'types/user';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { setLoggedInUser } from 'store/features/user/userSlice';
import { useAppDispatch } from 'hooks/redux';
import { sucessfulLoginInit } from 'components/logins/onLoginSuccessUtilities';
import CountdownTimer from 'components/Timer/CountdownTimer';
import userServices from 'lib/services/userServices';

const timeToResendAfter = 1;

async function verifyOtp(
  email: string,
  otp: string
): Promise<{ user: LoggedInUser; token: string; hasOnboardingIncomplete: boolean }> {
  const res = await serverApi.basic.post('/auth/verify-otp', {
    email,
    otp: parseInt(otp),
  });
  return res.data;
}

const OtpConfirmation = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const [resetOtp, setResetOtp] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const email = new URLSearchParams(location.search).get('email');
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const startTimer = () => {
      timer = setTimeout(() => {
        // Timer logic here
        setShowResend(true);
        console.log('Timer expired');
      }, timeToResendAfter * 60 * 1000);
    };

    startTimer();

    return () => {
      clearTimeout(timer);
      setShowResend(false);
    };
  }, []);

  if (!email) return <></>;

  const getOtp = async (otp: string) => {
    let localOtp = otp;

    if (localOtp.length > 0) {
      localOtp = localOtp.trim();
      if (localOtp.length === 6) {
        const toastId = toast.loading('Verifying code...');
        try {
          const { token, user } = await verifyOtp(email, localOtp);
          loginTokenUtils.setLocalStorageLoginToken(token);
          dispatch(setLoggedInUser(user));
          await sucessfulLoginInit({
            editorId: user._id,
          });
          toast.dismiss(toastId);

          history.push({
            pathname: '/select-login-account',
            state: {
              isFromVerifiedMagicLoginLink: true,
              email: user.email,
            },
          });
        } catch (error) {
          console.log((error as AxiosError).message);
          toast.dismiss(toastId);
          toast.error('Code verification failed');
          setResetOtp(true);
        } finally {
          toast.dismiss(toastId);
        }
      }
    }
  };

  const handleResendClick = async (email: string) => {
    const toastId = toast.loading('re-sending code...');
    try {
      await userServices.generateOtp(email);
      toast.success('Code sent successfully');
    } catch (err) {
      console.error('Error sending code', err);
      toast.error('Error sending code');
      return;
    } finally {
      toast.dismiss(toastId);
    }
  };

  return (
    <Stack
      direction={{ xs: 'row', md: 'row' }}
      height={{ xs: '100%', md: '100vh' }}
      flexWrap={'wrap'}
      sx={{
        background: {
          xs: 'url(https://res.cloudinary.com/salesfronts/image/upload/v1724402161/Onboarding-pics/otp_bg.jpg)',
          md: 'none',
        },
        minHeight: '100vh',
        overflow: 'hidden',
        alignItems: { xs: 'center', md: 'flex-start' },
      }}
    >
      <Stack
        maxWidth='xl'
        component={Container}
        sx={{
          width: { xs: '90%', md: '50%' },
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          py: { xs: 4, md: 0 },
          borderRadius: (theme) => theme.shape.borderRadius,
          bgcolor: theme.palette.common.white,
        }}
      >
        <Typography variant='h4' gutterBottom>
          Confirm your email
        </Typography>
        <Typography
          variant='body2'
          gutterBottom
          textAlign={'center'}
          color={(theme) => theme.palette.text.primary}
        >
          We've sent a verification code {email}
        </Typography>
        <OtpInput length={6} getOtp={getOtp} resetOtp={resetOtp} />
        <Typography
          variant='body2'
          gutterBottom
          mt={2}
          color={(theme) => theme.palette.text.primary}
          sx={{
            height: showResend ? 'auto' : 0,
            opacity: showResend ? 1 : 0,
            transition: 'height 0.2s, opacity 0.2s',
          }}
        >
          Didn't recieve any email?{' '}
          <Link
            disabled={!showResend}
            component={'button'}
            onClick={() => handleResendClick(email)}
            underline='hover'
          >
            Resend
          </Link>
        </Typography>
        {!showResend && <CountdownTimer minutes={timeToResendAfter} />}
      </Stack>
      {!isMobile && (
        <Box
          sx={{
            width: { xs: '0%', md: '50%' },
          }}
        >
          <Typography
            component={'img'}
            sx={{
              objectFit: 'contain',
              maxWidth: '100%',
            }}
            src={
              'https://res.cloudinary.com/salesfronts/image/upload/v1724402161/Onboarding-pics/otp_bg.jpg'
            }
          />
        </Box>
      )}
    </Stack>
  );
};

export default OtpConfirmation;
