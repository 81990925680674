import { FormikProvider } from 'formik';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import { useScript } from 'usehooks-ts';

import EUDFormInput from 'components/EditUserDetailsLatest/utility-components/EUDFormInput';
import StyledItemLabel from 'components/shared/StyledItemLabel';
import EUDActionBtns from 'components/EditUserDetailsLatest/sub-components/EUDActionBtns';
import AuthTagVisbileSwitch from 'components/EditUserDetailsLatest/sub-components/AuthTagVisbileSwitch';
import UserDashboardImgUploader from 'components/EditUserDetailsLatest/sub-components/UserDashboardImgUploader';
import EUDTags from 'components/EditUserDetailsLatest/sub-components/EUDTags/EUDTags';
import UserFavIconImgUploader from 'components/EditUserDetailsLatest/sub-components/UserFavIconImgUploader';
import EUDWhatsappNumber from 'components/EditUserDetailsLatest/sub-components/EUDWhatsappNumber';
import EUDSocialLinks from 'components/EditUserDetailsLatest/sub-components/EUDSocialLinks/EUDSocialLinks';
import EUDEmailSwitch from './utility-components/EUDEmailSwitch';
import EUDAddressInput from './utility-components/EUDAddressInput/EUDAddressInput';
import { googlePlacesApiScriptUrl } from 'API_Subscriptions/googlePlacesApi';
import IfElse from 'components/utilities/IfElse';
import UserBackgroundImgOrVideoUploader from './sub-components/UserBackgroundImgOrVideoUploader';
import PageWrapper from 'components/page-wrapper/page-wrapper';
import ProfileSearchableSwitchFromEditProfile from 'components/EditUserDetailsLatest/sub-components/ProfileSearchableSwitchFromEditProfile';

import useEditUserDetails from 'components/EditUserDetailsLatest/hooks/useEditUserDetails';
import { Stack, useTheme, Link } from '@mui/material';

const headingStyle = {
  textAlign: 'left',
  mb: {
    xs: '1.2em',
    sm: '1.5em',
  },
};

const EditUserDetails = () => {
  const { formik } = useEditUserDetails();
  const theme = useTheme();

  // loading google maps api
  const googleMapsApiScriptStatus = useScript(googlePlacesApiScriptUrl, {
    removeOnUnmount: true,
  });

  return (
    <PageWrapper sx={{ pb: { xs: 15, md: 20 } }}>
      <Container maxWidth='md'>
        <Box className="spm" zIndex={12}>
          <h1>
            <span>Edit details</span>
          </h1>
          <FormikProvider value={formik}>
            {/* ----------------- form */}
            <Box component='form' onSubmit={formik.handleSubmit}>
              <Grid container rowSpacing={{ xs: 3, sm: 5 }}>
                {/* ----------------- userUrl */}
                <Grid item xs={12}>
                  <EUDFormInput
                    required
                    name='userUrl'
                    label='Website address'
                    inputStartAdornment={
                      <Typography
                        sx={{
                          borderRadius: '2px',
                          p: 0.8,
                          backgroundColor: grey[200],
                        }}
                      >
                        fronts.ai/
                      </Typography>
                    }
                  />
                </Grid>
                {/* ----------------- isSearchable */}
                <Grid item xs={12}>
                  <Stack direction='column' spacing={0.1}>
                    <StyledItemLabel
                      label={<>Searchable on{' '}
                          <Link
                            href='https://fronts.ai'
                            target='_blank'
                            rel='noopener noreferrer'
                            sx={{
                              color: theme.palette.primary.main,
                              textTransform: 'none',
                              textDecoration: 'none',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                          >
                            fronts.ai
                          </Link>
                      </>}
                    />
                    <ProfileSearchableSwitchFromEditProfile
                      isSearchable={Boolean(formik.values.searchable)}
                    />
                  </Stack>
                </Grid>
                {/* ----------------- userDashboardImg */}
                <Grid item xs={6} sm={3}>
                  <UserDashboardImgUploader />
                </Grid>
                {/* ----------------- userFavIcon */}
                <Grid item xs={6} sm={3}>
                  <UserFavIconImgUploader />
                </Grid>
                {/* ------------------- background images or video */}
                <Grid item xs={12} sm={6}>
                  <UserBackgroundImgOrVideoUploader />
                </Grid>
                {/* ----------------- name */}
                <Grid item xs={12}>
                  <EUDFormInput required label='Name' name='name' />
                </Grid>
                {/* ----------------- title */}
                <Grid item xs={12}>
                  <EUDFormInput required label='Title' name='title' />
                </Grid>
                {/* ----------------- email */}
                <Grid item xs={12}>
                  <StyledItemLabel label={'Contact email'} />
                  <Typography variant='body1' color={'text.primary'}>
                    {formik.values.email}
                  </Typography>
                  <EUDEmailSwitch name='emailPublic' />
                </Grid>
                {/* ----------------- social links */}
                <Grid item xs={12}>
                  <Box>
                    <StyledItemLabel label='Social links' />
                    <EUDSocialLinks />
                  </Box>
                </Grid>
                {/* ----------------- skills/tags */}
                <Grid item xs={12}>
                  <Box>
                    <EUDTags />
                  </Box>
                </Grid>
                {/* ----------------- phoneNumber */}
                <Grid item xs={12}>
                  <EUDFormInput label='Phone number' name='phoneNumber' />
                </Grid>
                {/* ----------------- whatsAppNumber */}
                <Grid item xs={12}>
                  <EUDWhatsappNumber />
                </Grid>
                {/* ----------------- isAuthTagVisible (only enabled for fb auth type) */}
                <AuthTagVisbileSwitch />
                {/* ----------------- address */}
                <Grid item xs={12} sm={12}>
                  <IfElse condition={googleMapsApiScriptStatus === 'ready'}>
                    <EUDAddressInput label='Address' name='address' />
                  </IfElse>
                </Grid>
                {/* ----------------- Action btns */}
                <Grid item xs={12}>
                  <EUDActionBtns />
                </Grid>
              </Grid>
            </Box>
          </FormikProvider>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default EditUserDetails;
