import ButtonGroup from '@mui/material/ButtonGroup';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import useGuestSessionServices from 'views/guestSession/GuestSession/sub-components/GuestSessionActions/hooks/useGuestSessionServices';
import useValidationContextProvider from 'views/guestSession/ValidateGuestSession/hooks/useValidationContextProvider';

const btnsStyling: SxProps<Theme> = {
  backgroundColor: 'common.white',
  fontSize: {
    xs: '1.5rem',
    sm: '1.6rem',
  },
  py: {
    xs: '0.6em',
    sm: '0.6em',
  },
};

type GuestSessionActionsProps = {
  PaymentBtn: JSX.Element;
  hasSessionBeenCancelled: boolean;
  sessionId?: string;
};

const GuestSessionActions = ({
  sessionId,
  PaymentBtn,
  hasSessionBeenCancelled,
}: GuestSessionActionsProps) => {
  const {
    // validationState,
    isSessionCancelled,
    isSessionRejected,
    // updateValidationState,
  } = useValidationContextProvider();
  const { cancelSession, handleSessionLogout } = useGuestSessionServices(sessionId);

  if (!sessionId) {
    console.error('sessionId not found to display guest actions!');
    return <Alert severity='error'>Some error occured!!</Alert>;
  }

  const isSessionCancelledOrRejected = isSessionCancelled || isSessionRejected;

  return (
    <Stack
      direction='row'
      flexWrap='nowrap'
      alignItems='center'
      justifyContent='flex-end'
      spacing={1}
      sx={{
        pb: '1rem',
      }}
    >
      {!isSessionCancelledOrRejected && <Box>{PaymentBtn}</Box>}
      <ButtonGroup
        variant='outlined'
        aria-label='cancel-session-and-logout-btns'
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {/* -------------------------- Cancel Session Btn */}
        {!isSessionCancelledOrRejected && (
          <Button
            hidden={hasSessionBeenCancelled}
            onClick={cancelSession}
            sx={btnsStyling}
          >
            Cancel session
          </Button>
        )}
        {/* ------------------------------------ logout Btn */}
        <Button onClick={handleSessionLogout} sx={btnsStyling}>
          Logout&nbsp;
          <LogoutIcon />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

export default GuestSessionActions;
