import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

export default function OnboardingLinearProgressBar(
  props: LinearProgressProps & { value: number; step: number; FINAL_STEP_AI: number }
) {
  const { FINAL_STEP_AI, ...otherProps } = props;

  return (
    <Box position={'relative'}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: { xs: 1, md: 2 },
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 1,
          textAlign: 'center',          
        }}
      >
        <Box sx={{ width: '100%', '& .MuiLinearProgress-root': { height: '8px' } }}>
          <LinearProgress color='primary' variant='determinate' {...otherProps} />
        </Box>
        <Typography
          component='small'
          sx={{            
            fontSize: { xs: '1.1rem', md: '1.2rem' },
            textAlign: 'center',
            position: 'fixed',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '3px',
            width: 'fit-content',
            borderRadius: '10px',
            background: "linear-gradient(135deg, #e9eff5, #e8eef4)",
            px: 1,
            mr: 'auto'
          }}
        >
          Step {props.step} of {FINAL_STEP_AI}
        </Typography>
      </Box>
    </Box>
  );
}
