import { Box, Button, IconButton, SxProps, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import LayoutContainer from 'components/Containers/LayoutContainer';
import { Link } from 'react-router-dom';

import ClearIcon from '@mui/icons-material/Clear';

type ThisProps = {
  name: string;
  text: string;
  onCancelClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  typographySx?: SxProps;
  nameSx?: SxProps;
};

const PremiumThemeSubsNewSubscribeAction = ({
  name,
  text,
  typographySx,
  nameSx,
  onCancelClick,
}: ThisProps) => {

  return (
    <LayoutContainer
      maxWidth='lg'
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Typography
        variant='body1'
        sx={{
          color: grey[400],
          textAlign: 'center',
          width: '100%',
          ...typographySx,
        }}
      >
        <Box
          component='span'
          sx={{
            color: 'common.white',
            fontWeight: 'bold',
            ...nameSx,
          }}
        >
          {name}
        </Box>
        &nbsp;{text}&nbsp;
        <Link to={'/pricing'}>
          <Button
            variant='contained'
            sx={{
              background: '#ffc71d',
              fontSize: { xs: '1.5rem', md: '1.6rem' },
              padding: '0px 10px',
              color: 'common.black',
              '&:hover': {
                background: '#d8a816',
              },
            }}
          >
            Subscribe
          </Button>
        </Link>
        &nbsp;to apply to your site
      </Typography>
      <IconButton onClick={onCancelClick} sx={{ color: 'white', borderRadius: 0 }}>
        <ClearIcon />
      </IconButton>
    </LayoutContainer>
  );
};

export default PremiumThemeSubsNewSubscribeAction;
