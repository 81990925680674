import { Box, ButtonProps, Stack, Typography } from '@mui/material';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import { DomainSuggestionWithPrice } from 'views/Domains/DomainsSearchSuggestions';

export default function DomainCardOnboarding({
  domain,
  exactSearch,
  handleBuyDomainClick,
  domainBtnText = 'Buy Domain',
  icon,
  btnProps,
}: {
  domain?: DomainSuggestionWithPrice;
  handleBuyDomainClick: (name: string, e: React.MouseEvent<HTMLButtonElement>) => void;
  exactSearch?: boolean;
  domainBtnText?: string;
  icon?: React.ReactNode;
  btnProps?: ButtonProps;
}) {  
  if(!domain) return <></>;

  const isFreeDomain = domain.prices.isFreeDomain;
  const [sld, tld] = domain.name.split('.');  

   return (
    <Stack
      key={domain.name}
      direction='row'
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      alignItems='center'
      gap={3}      
      py={{ xs: 2, md: 2 }}
      sx={{
        minHeight: 100,
        '&:hover': {
          bgcolor: '#fef5ff',
          cursor: 'pointer',
        },
      }}
    >
      <Box flexGrow={{ xs: 0, md: 1 }}>
        {exactSearch && (
          <Typography
            variant='caption'
            sx={{
              bgcolor: (theme) => theme.palette.primary.light,
              color: (theme) => theme.palette.common.black,
              py: '2px',
              px: '10px',
              fontWeight: 600,
              borderRadius: 1,
            }}
          >
            Exact Search
          </Typography>
        )}
        {domain.ispremium && (
          <Typography
            variant='caption'
            sx={{
              bgcolor: (theme) => theme.palette.primary.light,
              color: (theme) => theme.palette.common.black,
              py: '2px',
              px: '10px',
              fontWeight: 600,
              borderRadius: 1,
            }}
          >
            PREMIUM
          </Typography>
        )}
        <Typography variant='subtitle1' fontWeight={500}>
          {sld}.<Typography component={'span'} color={theme => theme.palette.primary.main}>{tld}</Typography>
        </Typography>
      </Box>
      <Stack direction='row' alignItems='baseline' gap={2}>
        {isFreeDomain && (
          <Typography component={'s'} variant='h5'>
            {domain.prices.display.originalPriceDisplay}
          </Typography>
        )}
        <Typography variant={'h4'} fontWeight={600}>
          {domain.prices.display.registrationDisplay}
        </Typography>
      </Stack>
      <Box textAlign='end'>
        <ResponsiveButton
          onClick={(e) => handleBuyDomainClick(domain.name, e)}
          color='primary'
          variant='text'
          sx={{ alignSelf: 'flex-start', mt: 0, ...btnProps?.sx }}
          endIcon={icon}
          {...btnProps}
        >
          {domainBtnText}
        </ResponsiveButton>
      </Box>
    </Stack>
  );
}