import { Middleware } from '@reduxjs/toolkit';
import { isRejected, MiddlewareAPI } from '@reduxjs/toolkit';
import { storeDispatch } from 'store/store';
import loginTokenUtils from 'utilities/loginTokenUtils';
import routeConstants from 'constants/routeConstants';
// import { logout } from 'store/features/user/userActions';

const isNodeEnvProduction = process.env.NODE_ENV === 'production';
const homeRoute = routeConstants.HOME_ROUTE;

// for 401 unauthorized requests
// redirect not allowed for the below list
const redirectExcludedPathNames = [
  '/sign-in',
  '/login/email/verify',
  '/ai-website-builder',
  '/privacy',
  '/search',
];

const errorHandlingMiddleware: Middleware =
  (api: MiddlewareAPI<typeof storeDispatch>) => (next) => (action) => {
    if (isRejected(action)) {
      const errorHttpStatus = action.payload?.status;
      const errorData = action.payload?.data;
      // console.log('status code: ', errorHttpStatus);
      // console.log(action.payload);
      if (errorHttpStatus === 401) {
        // redirecting from unauthorized requests
        if (errorData.isUnauthorizedUser) {
          const currentPath = new URL(window.location.href).pathname;
          const isCurrentPathInExcludedRedirectList =
            redirectExcludedPathNames.includes(currentPath);
          const isRedirectValid = !isCurrentPathInExcludedRedirectList;
          if (isRedirectValid) {
            !isNodeEnvProduction &&
              console.log(
                'redirected by error handling middleware on 401 unauth request'
              );
            // storeDispatch(logout());
            loginTokenUtils.removeLocalStorageLoginToken();
            window.location.href = homeRoute;
          }
        }
      }
    }
    return next(action);
  };

export default errorHandlingMiddleware;
