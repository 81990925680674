import { allTimezones, ITimezoneOption } from 'react-timezone-select';
import spacetime from 'spacetime';
import soft from 'timezone-soft';

const getOptions = () => {
  const timezones = allTimezones;
  let labelStyle = 'original';

  return Object.entries(timezones).reduce<ITimezoneOption[]>((selectOptions, zone) => {
    const now = spacetime.now(zone[0]);
    const tz = now.timezone();
    const tzStrings = soft(zone[0]);

    let label = '';
    let abbr = now.isDST() ? tzStrings[0].daylight?.abbr : tzStrings[0].standard?.abbr;
    let altName = now.isDST() ? tzStrings[0].daylight?.name : tzStrings[0].standard?.name;

    const min = tz.current.offset * 60;
    const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60));
    const prefix = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

    switch (labelStyle) {
      case 'original':
        label = prefix;
        break;
      case 'altName':
        label = `${prefix} ${altName?.length ? `(${altName})` : ''}`;
        break;
      case 'abbrev':
        label = `${prefix} ${abbr?.length < 5 ? `(${abbr})` : ''}`;
        break;
      default:
        label = `${prefix}`;
    }

    selectOptions.push({
      value: tz.name,
      label: label,
      offset: tz.current.offset,
      abbrev: abbr,
      altName: altName,
    });

    return selectOptions;
  }, []);
};

export default getOptions;

export function getCurrentGMTOffset() {
  const now = new Date();
  const gmtOffsetMinutes = -now.getTimezoneOffset();
  const gmtOffsetHours = gmtOffsetMinutes / 60;
  const gmtOffsetString = (gmtOffsetHours >= 0 ? '+' : '') + gmtOffsetHours.toFixed(2);
  return gmtOffsetString;
}