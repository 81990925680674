type PrivacyText14Props = {
  children: JSX.Element | string;
  classname?: string;
};

const PrivacyText14 = ({ children, classname }: PrivacyText14Props) => {
  return (
    <p className={`privacy-text privacy-text-14 ${classname}`}>{children}</p>
  );
};

export default PrivacyText14;
