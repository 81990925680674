import React from 'react';
import {
  ButtonBase,
  IconButton,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import { format } from 'date-fns';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import CustomTable from 'components/Table/CustomTable';
import { HeadCell } from 'components/Table/CustomTableHead';
import ServiceOrderDetailsPopup from './ServiceOrderDetailsPopup';

import { dashboard_icon_size } from 'views/Dashboard/Dashboard';
import { convert_utc_to_local } from 'utilities/calendarUtilities';

import ServiceOrder, { ServiceOrderStatusValues } from 'types/serviceOrder';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import {
  TableHeadingMobile,
  TableStatusMobile,
  TableTextMobile,
} from 'components/dashboard/DashboardReusableComponent';

const ServiceOrderTable = ({ tableData }: { tableData: ServiceOrder[] }) => {
  const [serviceOrderId, setServiceOrderId] = React.useState<ServiceOrder['_id'] | null>(null);
  const [open, setOpen] = React.useState(false);

  const isPhone = useMediaQuery('(max-width:600px)');

  const { cachedLoggedInUser } = useGetLoggedInUser();
  const loggedInUserTimeZone = cachedLoggedInUser?.timeZone ?? 'UTC';

  const handleClick = (id: string) => {
    if (!id) return;
    setServiceOrderId(id);
    setOpen(true);
  };
  const timeFormat = (createdAt: number) =>  format(
    convert_utc_to_local(createdAt, loggedInUserTimeZone),
    'EEEE dd-MMMM-yyyy hh:mm a'
  );

  return (
    <>
      {isPhone ? (
        <Stack spacing={1} mt={1}>
          {tableData.map((serviceOrder, i) => {
            const { backgroundColor } = getStatusForSeriveOrder(serviceOrder.status);
            return (
              <Paper
                key={serviceOrder._id}
                variant='outlined'
                sx={{ display: 'grid', gridTemplateColumns: '1fr 20px', padding: 1 }}
              >
                <Stack
                  component={ButtonBase}
                  alignItems={'flex-start'}
                  direction={'column'}
                  justifyContent={'center'}
                  onClick={() => handleClick(serviceOrder._id)}
                >
                  <TableStatusMobile color={backgroundColor}>{serviceOrder.status}</TableStatusMobile>
                  <TableHeadingMobile>{serviceOrder.customerDetails.name}</TableHeadingMobile>
                  <TableTextMobile>{serviceOrder.serviceDetails.title}</TableTextMobile>
                  <TableTextMobile>
                    {timeFormat(+serviceOrder.createdAt)}
                  </TableTextMobile>
                </Stack>

                <IconButton onClick={() => handleClick(serviceOrder._id)}>
                  <MoreHorizIcon sx={dashboard_icon_size} />
                </IconButton>
              </Paper>
            );
          })}
        </Stack>
      ) : (
        <CustomTable headCells={serviceOrderHeadCells}>
          <TableBody>
            {tableData.map((serviceOrder, i) => {
              const { backgroundColor, color } = getStatusForSeriveOrder(serviceOrder.status);
              return (
                <>
                  <TableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={i}
                    sx={{
                      '& .MuiTableCell-body': {
                        cursor: 'pointer',
                        overflowX: 'auto',
                        fontSize: '1.3rem',
                        maxWidth: '200px',
                        whitspace: 'nowrap',
                        align: 'left',
                      },
                    }}
                    onClick={() => handleClick(serviceOrder._id)}
                  >
                    <TableCell sx={{ bgcolor: backgroundColor, color: color }}>
                      {serviceOrder.status}
                    </TableCell>
                    <TableCell>{serviceOrder.customerDetails.name}</TableCell>
                    <TableCell>{serviceOrder.serviceDetails.title}</TableCell>
                    <TableCell>
                    {timeFormat(+serviceOrder.createdAt)}
                    </TableCell>
                    <TableCell>{serviceOrder.amountPaid}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleClick(serviceOrder._id)}>
                        <MoreHorizIcon sx={dashboard_icon_size} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </CustomTable>
      )}
      {serviceOrderId && (
        <ServiceOrderDetailsPopup open={open} setOpen={setOpen} serviceOrderId={serviceOrderId} />
      )}
    </>
  );
};

export default ServiceOrderTable;

export const serviceOrderHeadCells: readonly HeadCell[] = [
  {
    id: 'status',
    label: 'Status',
    numeric: false,
  },
  {
    id: 'customer-name',
    label: 'Customer Name',
    numeric: false,
  },
  {
    id: 'service-title',
    label: 'Title',
    numeric: false,
  },
  {
    id: 'created-at',
    label: 'Created At',
    numeric: true,
  },
  {
    id: 'amount-paid',
    label: 'Amount Paid',
    numeric: false,
  },
  {
    id: 'details',
    label: 'Details',
    numeric: false,
  },
];

export function getStatusForSeriveOrder(status: ServiceOrderStatusValues) {
  let backgroundColor: React.CSSProperties['backgroundColor'], color: React.CSSProperties['color'];

  if (status === ServiceOrderStatusValues.DELIVERED) {
    backgroundColor = '#66bb6a';
    color = '#FFF';
  } else if (
    status === ServiceOrderStatusValues.IN_PROGRESS ||
    ServiceOrderStatusValues.DELIVERED
  ) {
    backgroundColor = '#FFD700';
    color = '#000';
  }

  return {
    backgroundColor,
    color,
    status,
  };
}
