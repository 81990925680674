import { useRef, useState, useEffect } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';

import TinymceRichText from 'components/utilities/TinyMceEditor/TinymceRichText';
import LabelOnTheBorder from 'components/SPM_Forms/reusable-form-components/LabelOnTheBorder';
import FormPaidSwitch from 'components/SPM_Forms/reusable-form-components/FormPaidSwitch/FormPaidSwitch';
import Status from 'components/SPM_Forms/reusable-form-components/Status';
import FormLabelWithTextInput from 'components/SPM_Forms/reusable-form-components/FormLabelWithTextInput';
import SPMEditableTags from 'components/SPM_Forms/reusable-form-components/SPMEditableTags/SPMEditableTags';
import UploadImageBase from 'components/SPM_Forms/base/sub-components/UploadImageBase';
import SaveCancelButtons from 'components/Buttons/SaveCancelButtons';
import React from 'react';
import StyledItemLabel from 'components/shared/StyledItemLabel';

// Define an interface for your form values
export type CreateEditFormValues<T extends Record<string, unknown> = {}> = {
  included: string[];
  ctaBtnText: string;  // Changed to string for better typing
  price: number;       // Changed to number for price
  desc: string;
  title: string;
  isPaid: boolean;
  status: boolean;
  mins: number;
  hrs: number;  
} & {
  [key in keyof T]: T[key]
};

interface CreateEditBaseProps {
  isCreateMode: boolean;
  entityName: string;
  symbol: string;
  currency: string;
  exchangeRates: number;
  getUpdatedSkills: (tagList: string[]) => void;
  goToEditorPage: () => void;
  isFormPrimaryActionInProgress: boolean;
  children: React.ReactNode;
}

const CreateEditBase: React.FC<CreateEditBaseProps> = ({
  isCreateMode,
  entityName,
  symbol,
  currency,
  exchangeRates,
  getUpdatedSkills,
  goToEditorPage,
  isFormPrimaryActionInProgress,
  children,
}: CreateEditBaseProps) => {
  const formik = useFormikContext<CreateEditFormValues>();
  const titleInputRef = useRef(null);
  const [openLostChangesConfirmationDialog, setOpenLostChangesConfirmationDialog] =
    useState(false);
  const isActive = formik.values.status;
  const activeStatus = isActive ? 'active' : 'inactive';

  const validateVisitingToEditorPage = () => {
    if (!formik.dirty) {
      goToEditorPage();
      return;
    }
    setOpenLostChangesConfirmationDialog(true);
  };

  const getUpdatedStatusValue = (isChecked: boolean) => {
    formik.setFieldValue('status', isChecked);
  };

  const updateFormIsPaidValue = (isPaid: boolean) => {
    formik.setFieldValue('isPaid', isPaid);
  };

  const handleUpdatedRichTextDesc = (updatedDescription: string) => {
    formik.setFieldValue('desc', updatedDescription);
  };

  const updateMeetingDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!formik.values.mins && !formik.values.hrs && !Number(value)) return;
    formik.setFieldValue(name, value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box>
      <Container
        maxWidth='md'
        sx={{ my: 4, bgcolor: (theme) => theme.palette.common.white }}
      >
        <form className='spm' onSubmit={formik.handleSubmit}>         
          <h1>
            <span>
              {isCreateMode ? 'Create' : 'Edit'} {entityName}
            </span>
          </h1>
          <div className='spm__fields__wrapper'>
            <Status
              currentStep={1}
              status={formik.values.status}
              activeStatus={activeStatus}
              getUpdatedStatusValue={getUpdatedStatusValue}
              isEntityPaid={formik.values.isPaid}
            />
            <FormLabelWithTextInput
              handleChange={formik.handleChange}
              inputId='titleId'
              name='title'
              placeholder={`${entityName} title`}
              inputName='title'
              inputValue={formik.values.title}
              labelText='Title'
              required={true}
              ref={titleInputRef}
            />
            <Stack direction='column' spacing={0.1} data-id='desc'>
              <StyledItemLabel label='Description' required={true} />
              <TinymceRichText
                handleDescriptionChange={handleUpdatedRichTextDesc}
                initialContent={formik.values.desc}
                name='desc'
                id={entityName}
              />
            </Stack>
            <UploadImageBase />

            <Stack direction='column' spacing={0.1} data-id='switch'>
              <StyledItemLabel label='Paid' required={true} />
              <FormPaidSwitch
                isPaid={formik.values.isPaid}
                entityName={entityName}
                isEntityStatusActive={formik.values.status}
                getUpdatedIsPaidValue={updateFormIsPaidValue}
              />
              {formik.values.isPaid && (
                <>
                  <StyledItemLabel label='Price' />
                  <div className='price floating-label' style={{ padding: '8px 0px' }}>
                    <LabelOnTheBorder
                      labelname={currency}
                      name='price'
                      type='number'
                      autoComplete='off'
                      handleChange={formik.handleChange}
                      value={formik.values.price}
                      min={exchangeRates ? +exchangeRates : 1}
                      step={0.1}
                    />
                    <Typography
                      component='span'
                      sx={{
                        fontSize: { xs: '90%', md: '90%' },
                        bgcolor: 'yellow',
                        color: 'black',
                      }}
                    >
                      &nbsp;A processing charge of{' '}
                      <strong>
                        {symbol}&nbsp;{exchangeRates}
                      </strong>
                      &nbsp; will be applied by Stripe for each transaction.
                    </Typography>
                  </div>
                </>
              )}
            </Stack>
            <FormLabelWithTextInput
              className='spm__fields__cta-btn-text'
              handleChange={formik.handleChange}
              inputId='ctaBtnTextId'
              placeholder={formik.values.ctaBtnText}
              inputName='ctaBtnText'              
              inputValue={formik.values.ctaBtnText}
              labelText='Call To Action Button Text'
              required={true}
            />
            <SPMEditableTags
              label={`What's Included:`}
              tagList={formik.values.included}
              onTagsChange={getUpdatedSkills}
            />
            {React.Children.map(children, (child) =>
              React.cloneElement(child as React.ReactElement, {
                updateMeetingDuration,
              })
            )}
          </div>
          <SaveCancelButtons
            goBack={goToEditorPage}
            cancelBtnProps={{
              onClick: validateVisitingToEditorPage,
            }}
            cancelBtnChildren='Cancel'
            saveBtnProps={{
              type: 'submit',
              disabled:
                !formik.dirty || isFormPrimaryActionInProgress || formik.isSubmitting,
            }}
            saveBtnChildren={isCreateMode ? 'Create' : 'Save'}
            showLostChangesDlg={openLostChangesConfirmationDialog}
            openConfirmDiscardChangesPopup={() =>
              setOpenLostChangesConfirmationDialog(false)
            }
          />
        </form>
      </Container>
    </Box>
  );
};

export default CreateEditBase;
