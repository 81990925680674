type PolicyHeadingProps = {
  children: string;
};

const PolicyHeading = ({ children }: PolicyHeadingProps) => {
  return (
    <>
      <h2 className='heading heading-policy'>{children}</h2>
      <hr />
    </>
  );
};

export default PolicyHeading;
