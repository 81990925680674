import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import routeConstants from 'constants/routeConstants';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { storeDispatch } from 'store/store';
import { logout } from 'store/features/user/userActions';
import getNodeEnv from 'utilities/getNodeEnv';
import { useGetLinkedAccountsQuery } from 'store/rtkqFeatures/api/linkedAccountsExtendedApi';

const homeRoute = routeConstants.HOME_ROUTE;

type DataFromVerifiedLoginLink = Partial<{
  isFromVerifiedMagicLoginLink: boolean;
  email: string;
  isFromEditor?: boolean;
}>;

const useSelectLoginAccount = (email?: string) => {
  const history = useHistory();
  const isNodeEnvProduction = getNodeEnv();
  const location = useLocation<DataFromVerifiedLoginLink>();
  const locationStateData = location.state;
  const isFromVerifiedMagicLoginLink = email
  ? true
  : Boolean(locationStateData.isFromVerifiedMagicLoginLink);
  const userEmail = email || locationStateData.email || '';
  const shouldFetchEmailLinkedAccounts = Boolean(
    userEmail && isFromVerifiedMagicLoginLink
  );
  const {data, isLoading, isError, isFetching, isUninitialized, isSuccess} = useGetLinkedAccountsQuery(undefined, {
    skip: !shouldFetchEmailLinkedAccounts,
  });

  const clearLoggedInUser = useCallback(() => {
    loginTokenUtils.removeLocalStorageLoginToken();
    storeDispatch(logout());    
  }, []);

  if (!isFromVerifiedMagicLoginLink || !userEmail) {
    !isNodeEnvProduction &&
      console.error(
        `redirected from select login account because data not found | email: ${userEmail} | isFromVerifiedMagicLoginLink: ${isFromVerifiedMagicLoginLink}`
      );
    clearLoggedInUser();
    history.replace(homeRoute);
  }

  return {
    isFetchingEmailLinkedAccounts: isLoading,
    isFetching,
    isUninitialized,
    isSuccess,
    clearLoggedInUser,
    data: {
      emailLinkedUserAccounts: data,
      mutateEmailLinkedAccountsData: () => {},
    },
    errors: {
      isErrorFetchingEmailLinkedAccounts: isError,
    },
  };
};

export default useSelectLoginAccount;