import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { useGetStripeConnectedAccountByEditorIdQuery } from 'store/rtkqFeatures/api/stripeConnectedAccountExtendedApi';

const useGetStripeConnectedAccount = () => {
  const { userIdInReduxUserSlice: editorId } = useGetLoggedInUser();
  const { data: connectedAccount } =
    useGetStripeConnectedAccountByEditorIdQuery(editorId);
  const isStripeOnboardingProcessCompleted =
    !!connectedAccount?.isOnboardingProcessCompleted;

  // console.log('connectedAccount: ', connectedAccount);

  return {
    connectedAccount,
    isStripeOnboardingProcessCompleted,
  };
};

export default useGetStripeConnectedAccount;
