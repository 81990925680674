import { useReducer } from 'react';

export enum LoadingStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

type State = {
  status: LoadingStatus;
  error: string;
  successMsg: string;
};

const INITIAL_STATE: State = {
  status: LoadingStatus.IDLE,
  successMsg: '',
  error: '',
};

// ----------- typing actions ------------------
export enum Actions {
  RESET = 'reset',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

type ResetAction = {
  type: Actions.RESET;
};

type LoadingAction = {
  type: Actions.LOADING;
};

type SuccessAction = {
  type: Actions.SUCCESS;
  payload: string;
};

type ErrorAction = {
  type: Actions.ERROR;
  payload: string;
};

export type AsyncReducerActions = ResetAction | LoadingAction | SuccessAction | ErrorAction;

function stateReducer(state: State, action: AsyncReducerActions) {
  const { type } = action;

  if (type === Actions.RESET) {
    return INITIAL_STATE;
  } else if (type === Actions.LOADING) {
    return {
      status: LoadingStatus.LOADING,
      error: '',
      successMsg: '',
    };
  } else if (type === Actions.SUCCESS) {
    return {
      status: LoadingStatus.SUCCESS,
      error: '',
      successMsg: action.payload,
    };
  } else if (type === Actions.ERROR) {
    return {
      status: LoadingStatus.ERROR,
      error: action.payload,
      successMsg: '',
    };
  }

  return state;
}

const useAsyncStatus = () => {
  const [statusState, updateStatus] = useReducer(stateReducer, INITIAL_STATE);
  const isIdle = statusState.status === LoadingStatus.IDLE;
  const isLoading = statusState.status === LoadingStatus.LOADING;
  const isSuccess = statusState.status === LoadingStatus.SUCCESS;
  const isError = statusState.status === LoadingStatus.ERROR;
  const successMsg = statusState.successMsg;
  const error = statusState.error;
  const status = statusState.status;

  return {
    isIdle,
    isLoading,
    isSuccess,
    successMsg,
    isError,
    error,
    status,
    updateStatus,
    INITIAL_STATE,
    Actions,
  };
};

export default useAsyncStatus;
