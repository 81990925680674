import { combineReducers, AnyAction } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import apiServices from 'lib/services';
import servicesSliceReducer, { ServicesModule } from './features/services/serviceSlice';
import portfolioSliceReducer, {
  PortfolioModule,
} from './features/portfolio/portfolioSlice';
import sessionSliceReducer, { SessionModule } from './features/session/sessionSlice';
import sortedSPMIdsSliceReducer, {
  SortedSPMIds,
} from './features/sortedSPMIds/sortedSPMIdsSlice';
import modalSliceReducer, { ModalModule } from './features/modal/modalSlice';
import connectedAccountSliceReducer, {
  ConnectedAccountModule,
} from './features/connectedAccount/connectedAccountSlice';
import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import meetingsSliceReducer, {
  MeetingsModule,
} from 'store/features/meetings/meetingsSlice';
import { UserModule } from 'types/user';
import testCounterReducer from 'store/features/testCounter/testCounterSlice';
import loggedinUserSliceReducer from 'store/features/user/userSlice';
import websiteFontFamilySliceReducer, {
  WebsiteFontFamily,
} from 'store/features/websiteFontFamily/websiteFontFamilySlice';
import localThemeSelectorReducer, {
  LocalThemeSelector,
} from 'store/features/localThemeSelector/localThemeSelectorSlice';
import errorHandlingMiddleware from 'store/rtkqFeatures/RTKQueryMiddlewares/errorHandlingMiddleware';
import { WebsiteExtractorModule } from 'store/features/websiteExtractor/websiteExtractorTypes';
import websiteExtractorSliceReducer from 'store/features/websiteExtractor/websiteExtractorSlice';
import DashboardSliceReducer, { IDashboardModule } from './features/dashboard/dashboardSlice';
import localLayoutStateReducer, { LocalLayoutSelector } from './features/localLayoutState';

type RootReducerType =
  | {
      loggedinUserModule: UserModule;
      servicesModule: ServicesModule;
      meetingsModule: MeetingsModule;
      portfolioModule: PortfolioModule;
      sessionsModule: SessionModule;
      modalModule: ModalModule;
      connectedAccountModule: ConnectedAccountModule;
      sortedSPMIds: SortedSPMIds;
      websiteFontFamily: WebsiteFontFamily;
      localThemeSelector: LocalThemeSelector;
      websiteExtractorModule: WebsiteExtractorModule;
      dashboardModule: IDashboardModule;
      localLayoutSelector: LocalLayoutSelector;   
      testCounter: number; // testing purpose
    }
  | undefined;

const appReducer = combineReducers<NonNullable<RootReducerType>>({
  loggedinUserModule: loggedinUserSliceReducer,
  servicesModule: servicesSliceReducer,
  meetingsModule: meetingsSliceReducer,
  portfolioModule: portfolioSliceReducer,
  sessionsModule: sessionSliceReducer,
  modalModule: modalSliceReducer,
  connectedAccountModule: connectedAccountSliceReducer,
  sortedSPMIds: sortedSPMIdsSliceReducer,
  websiteFontFamily: websiteFontFamilySliceReducer,
  websiteExtractorModule: websiteExtractorSliceReducer,
  dashboardModule: DashboardSliceReducer,
  testCounter: testCounterReducer,
  localThemeSelector: localThemeSelectorReducer,
  localLayoutSelector: localLayoutStateReducer,
});

const rootReducer = (state: RootReducerType, action: AnyAction) => {
  if (action.type === 'userModule/setLogOut') {
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [salesfrontsApiSlice.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const reduxStore = configureStore({
  reducer: {
    persistedReducer,
    [salesfrontsApiSlice.reducerPath]: salesfrontsApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: apiServices,
      },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(salesfrontsApiSlice.middleware, errorHandlingMiddleware),
});

export const storeDispatch = reduxStore.dispatch;

// to enable auto refetch for fetchOnReconnect && fetchOnFocus
setupListeners(reduxStore.dispatch);

export default reduxStore;
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
