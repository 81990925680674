import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { Box, Button, IconButton, Link, Toolbar } from '@mui/material';
import { toast } from 'react-toastify';

import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { DashboardListItem } from './DashboardListItem';
import {
  select_dashboard_user_is_sidebar_minified,
  toggleSideBar,
} from 'store/features/dashboard/dashboardSlice';
import { dashboard_icon_size } from 'views/Dashboard/Dashboard';
import { PREMIUM_THEME_ICON_URL } from 'components/ThemeSelector/ThemeSelector';
import PrimarySidebarListItems from './DashboardListItem';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { storeDispatch } from 'store/store';
import { logout } from 'store/features/user/userActions';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGetStripePlatformCustomer from 'store/rtkqFeatures/api/hooks/useGetStripePlatformCustomer';
import userServices from 'lib/services/userServices';
import routeConstants from 'constants/routeConstants';

export const admin_drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: admin_drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const DashboardSidebar = () => {
  const is_sidebar_minified = useAppSelector(select_dashboard_user_is_sidebar_minified);
  const { isPremiumUser } = useGetStripePlatformCustomer();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const toggleDrawer = () => dispatch(toggleSideBar());

  const handleLogout = async () => {
    const timerId = toast.loading('Logging out...');
    try {
      await userServices.userLogout();
      loginTokenUtils.removeLocalStorageLoginToken();
      storeDispatch(logout());
      toast.success('Logged out successfully');
      history.push(routeConstants.HOME_ROUTE);
    } catch (err) {
      toast.error('Error while logging out, Please try again after refreshing the page');
    } finally {
      toast.dismiss(timerId);
    }
  };

  return (
    <Drawer
      component={'aside'}
      variant='permanent'
      open={!is_sidebar_minified}      
      PaperProps={{ sx: { border: 0, boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)' } }}      
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1],
        }}
      >
        <Link
          href={routeConstants.LIVE_ROUTE}
          style={{ display: !is_sidebar_minified ? 'flex' : 'none' }}
        >
          <Button>
            <Box
              component='img'
              src={`${process.env.PUBLIC_URL}/fronts-ai.png`}
              alt='logo'
              sx={{
                objectFit: 'contain',
                maxWidth: '100px',
              }}
            />
            {isPremiumUser && (
              <Box
                component='img'
                ml={0.5}
                src={PREMIUM_THEME_ICON_URL}
                alt='logo'
                sx={{
                  objectFit: 'contain',
                  maxWidth: '100%',
                  ...dashboard_icon_size,
                }}
              />
            )}
          </Button>
        </Link>

        <IconButton
          sx={{
            display: !is_sidebar_minified ? 'inline-block' : 'none',            
            transition: 'transform 0.2s',
            clipPath: 'circle()',
            '&:hover': {
              transform: 'translateX(-3px)',
            },
          }}
          onClick={toggleDrawer}
        >
          <ChevronLeftIcon sx={dashboard_icon_size} />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component='nav' sx={{ height: '100%' }}>
        <PrimarySidebarListItems />
      </List>
      <Divider />
      <Toolbar disableGutters sx={{ pb: 1 }}>
        <DashboardListItem
          icon={<LogoutIcon sx={dashboard_icon_size} />}
          text={'Logout'}
          link={'#'}
          handleClick={handleLogout}
          sx={{ alignSelf: 'flex-end' }}
        />
      </Toolbar>
    </Drawer>
  );
};

export default DashboardSidebar;
