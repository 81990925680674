import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import MuiLink from '@mui/material/Link';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';

import useEditorPageRedirect from 'components/logins/hooks/useEditorPageRedirect';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import HomeLayout from 'views/Home/utility-components/HomeLayout';
import UserSignupInterface from 'views/OnboardingFlow/sub-components/stepperComponents/UserSignupLoginInterface/UserSignupLoginInterface';
import useAsyncStatus, { Actions } from 'hooks/useAsyncStatus';
import useFacebookLogin from 'components/logins/hooks/useFacebookLogin';
import useHandleEmailSignIn from 'components/logins/EmailSignIn/hooks/useHandleEmailSignIn';
import { UserSignupLoginInterfaceWrapper } from 'views/OnboardingFlow/sub-components/stepperComponents/UserSignupLoginInterface/UserSignupLoginInterfaceWrapper';

import { loadEditorProfileData } from 'components/logins/onLoginSuccessUtilities';
import googleLoginServices from 'lib/services/googleLoginServices';
import userServices from 'lib/services/userServices';
import loginTokenUtils from 'utilities/loginTokenUtils';
import routeConstants from 'constants/routeConstants';
import { googleOauthsource, fbOauthSource, fbWindowNames } from 'views/Oauth';
import { PREVIOUS_ROUTE_ONBOARDING } from 'views/OnboardingFlow/sub-components/OnboardingFlowStepper/StepperIncDecrementBtns';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import { SignUpSignInBackendError } from 'views/SignUp/SignUp';
import ConfirmationDialog from 'Popups/ConfirmationDialog';
import { LoggedInUser } from 'types/user';
import { useAppDispatch } from 'hooks/redux';
import { resetLoggedinUserSlice } from 'store/features/user/userSlice';

const POPUP_WIDTH = 500;
const POPUP_HEIGHT = 600;

export function saveRouteToSessionStorage(history: ReturnType<typeof useHistory>) {
  // remove previous route from sessionStorage
  sessionStorage.removeItem(PREVIOUS_ROUTE_ONBOARDING);
  // saving route to sessionStorage
  sessionStorage.setItem(PREVIOUS_ROUTE_ONBOARDING, history.location.pathname);
}

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [authURL, setAuthURL] = useState('');
  const dispatch = useAppDispatch();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const fbCancelToken = axios.CancelToken.source();
  const googleCancelToken = axios.CancelToken.source();

  const { isFetching, isLoading: isRedirectLoading } = useEditorPageRedirect();
  const { facebookLoginUrl } = useFacebookLogin();
  const {
    updateStatus: updateCustomLoginStatus,
    isLoading,
    isError,
    error,
  } = useAsyncStatus();
  const { handleFormSubmit, emailSendStatus } = useHandleEmailSignIn();
  const googleStatus = useAsyncStatus();
  const fbStatus = useAsyncStatus();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);  
    setEmail(searchParams.get('email') || '');
  }, [location]);

  useEffect(() => {
    return () => {
      console.log('sign in unmounting');
      window.removeEventListener('message', handlePopupMessage);
      fbCancelToken.cancel('Facebook login cancelled');
      googleCancelToken.cancel('Google login cancelled');
    }
  }, []);

  const handleAuthResponse = async (user: LoggedInUser, token: string) => {    
    dispatch(resetLoggedinUserSlice());
    loginTokenUtils.setLocalStorageLoginToken(token);
    await loadEditorProfileData(user._id);        
    history.push('/select-login-account', {
      isFromVerifiedMagicLoginLink: true,
      email: user.email,
    });
  };

  const handleAuthError = (error: AxiosError, updateStatusFn: Function) => {
    const errorData =
      errorHandlingUtilities.getErrorData<SignUpSignInBackendError>(error);
    let errMsg: string = '';
    if (!errorData) {
      errMsg = 'Internal Server Error, Try again';
    }
    errMsg = errorData?.message || '';

    updateStatusFn({
      type: Actions.ERROR,
      payload: errMsg,
    });
  };

  const handleGoogleAuth = async (data: any) => {
    const urlParams = new URLSearchParams(data.payload);
    const googleAuthorizationCode = urlParams.get('code');

    if (!googleAuthorizationCode) {
      console.error('Google Authorization Code not found');
      return;
    }

    try {
      const { user, token } = await userServices.googleLogin(googleAuthorizationCode, googleCancelToken);
      await handleAuthResponse(user, token);
      googleStatus.updateStatus({
        type: Actions.SUCCESS,
        payload: 'Google Sign in successful',
      });
    } catch (error) {
      handleAuthError(error as AxiosError, googleStatus.updateStatus);
    }
  };

  const handleFacebookAuth = async (data: any) => {
    fbStatus.updateStatus({ type: Actions.LOADING });
    const urlParams = new URLSearchParams(data.payload);
    const fbAuthorizationCode = urlParams.get('code');
    const fbParamState = urlParams.get('state');

    if (fbParamState !== 'FB_LOGIN' || !fbAuthorizationCode) {
      console.error('Invalid Facebook Authorization');
      fbStatus.updateStatus({
        type: Actions.ERROR,
        payload: 'Facebook Sign up failed',
      });
      return;
    }

    try {
      const { user, token } = await userServices.fbLogin(fbAuthorizationCode, fbCancelToken);
      await handleAuthResponse(user, token);
      fbStatus.updateStatus({
        type: Actions.SUCCESS,
        payload: 'Facebook Sign up successful',
      });
    } catch (error) {
      handleAuthError(error as AxiosError, fbStatus.updateStatus);
    }
  };

  const handlePopupMessage = async (event: MessageEvent) => {
    if (event.origin !== routeConstants.EDITOR_ROUTE) return;
    if (event.data.source === googleOauthsource) {
      await handleGoogleAuth(event.data);
    } else if (event.data.source === fbOauthSource) {
      await handleFacebookAuth(event.data);
    }
  };

  const openPopup = (url: string, title: string) => {
    if (isSafari) {
      return window.open(url, title);
    } else {
      const left = window.innerWidth / 2 - POPUP_WIDTH / 2;
      const top = window.innerHeight / 2 - POPUP_HEIGHT / 2;
      return window.open(
        url,
        title,
        `menubar=no,toolbar=no,width=${POPUP_WIDTH},height=${POPUP_HEIGHT},top=${top},left=${left}`
      );
    }
  };

  const generateGoogleAuthUrl = async () => {
    window.removeEventListener('message', handlePopupMessage);

    try {
      const authURL = await googleLoginServices.generateGoogleAuthUrl();

      if (isSafari) {
        setAuthURL(authURL);
        setOpen(true);
      } else {
        openPopup(authURL, 'Google Sign In');
        window.addEventListener('message', handlePopupMessage, false);
      }
    } catch (error) {
      console.error('Error generating Google Auth URL', error);
      toast.error(error);
      throw new Error('Error generating Google Auth URL');
    }
  };

  const handleGoogleLogin = async () => {
    googleStatus.updateStatus({ type: Actions.LOADING });
    try {
      await generateGoogleAuthUrl();
    } catch (err) {
      googleStatus.updateStatus({
        type: Actions.ERROR,
        payload: (err as AxiosError).message,
      });
    }
  };

  const handleFBLogin = () => {
    fbStatus.updateStatus({ type: Actions.LOADING });
    window.removeEventListener('message', handlePopupMessage);
    const popup = openPopup(facebookLoginUrl, fbWindowNames.login);
    window.addEventListener('message', handlePopupMessage);
    const popupCheckInterval = setInterval(() => {
      if (popup?.closed) {
        clearInterval(popupCheckInterval);
        fbStatus.updateStatus({ type: Actions.RESET });
      }
    }, 500);
  };

  const handleCustomEmailClick = async (email: string) => {    
    dispatch(resetLoggedinUserSlice());
    setEmail(email);
    await handleFormSubmit({ email });
  };

  const getEmailSendStatus = () => {
    const { isEmailSendStatusLoading, isEmailSendStatusSuccess, isEmailSendStatusError } =
      emailSendStatus;
    if (isEmailSendStatusLoading) return 'Sending email...';
    if (isEmailSendStatusSuccess) return 'Check your email';
    if (isEmailSendStatusError) return 'Refresh and try again';
    return 'Sign In';
  };

  if (isFetching || isRedirectLoading) return <FrontsLoader />;

  return (
    <HomeLayout above600PxWidth='600px' showPrivacyPolicy showSignUpLink>
      <Box>
        <UserSignupInterface
          sx={{
            width: { xs: '100%', md: '400px' },
            mx: 'auto',
            display: emailSendStatus.isEmailSendStatusSuccess ? 'none' : 'flex',
          }}
          type='sign-in'
          isFbLoading={fbStatus.isLoading}
          isGoogleLoading={googleStatus.isLoading}
          customLoginStatus={getEmailSendStatus()}
          handleCustomEmailClick={handleCustomEmailClick}
          handleFbBtnClick={handleFBLogin}
          handleGoogleBtnClick={handleGoogleLogin}
          emailLogin={{
            isError,
            updateStatus: updateCustomLoginStatus,
            error,
            isLoading: emailSendStatus.isEmailSendStatusLoading || isLoading,
          }}
        />

        {emailSendStatus.isEmailSendStatusSuccess && (
          <UserSignupLoginInterfaceWrapper
            sx={{
              width: { xs: '100%', md: '400px' },
              mx: 'auto',
            }}
          >
            <Box textAlign='center'>
              <Typography
                variant='body1'
                color={(theme) => theme.palette.common.black}
                fontWeight={500}
              >
                We have sent you an email
                <br />
                check your email for the access link
              </Typography>

              <br />
              <br />
              {emailSendStatus.isEmailSendStatusLoading && <CircularProgress size={20} />}

              <Typography variant='body2' fontWeight={500}>
                Didn't receive the email?
                <MuiLink
                  onClick={() => handleCustomEmailClick(email)}
                  component={Button}
                  sx={{ textTransform: 'capitalize' }}
                  underline='hover'
                >
                  Resend
                </MuiLink>
              </Typography>
            </Box>
          </UserSignupLoginInterfaceWrapper>
        )}

        {emailSendStatus.isEmailSendStatusError && (
          <Box textAlign='center'>
            <Typography color='error.main'>Something went wrong</Typography>
            <MuiLink
              onClick={() => handleCustomEmailClick(email)}
              component={Button}
              sx={{ textTransform: 'capitalize' }}
              underline='hover'
            >
              Try again
            </MuiLink>
          </Box>
        )}

        <ConfirmationDialog
          open={open}
          handleAgreeBtnClick={() => {
            window.addEventListener('message', handlePopupMessage);
            openPopup(authURL, 'Google Sign In');
            setOpen(false);
          }}
          dialogProps={{
            open,
            sx: {
              minWidth: 300,
            },
          }}
          handleDialogClose={() => setOpen(false)}
          title='Login'
          content='Click on confirm to Login'
          isLoading={false}
          ariaDescribedby='login-in-dialog'
          cancelText='Cancel'
          confirmText='Confirm'
          agreeBtnProps={{
            color: 'primary',
          }}
        />
      </Box>
    </HomeLayout>
  );
};

export default SignIn;
