import React, { useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'hooks/redux';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import {
  useGetEditorByIdQuery,
  useUpdatedEditorByIdMutation,
} from 'store/rtkqFeatures/api/editorExtendedApi';
import useHtmlContentEditable from 'hooks/useHtmlContentEditable';
import { toast } from 'react-toastify';
import InPlaceActionTaker from 'components/utilities/InPlaceActionTaker/InPlaceActionTaker';
import useOnclickOutside from 'react-cool-onclickoutside';
import EditableHeadingsTextLengthValidationError from 'components/utilities/EditableHeadingsTextLengthValidationError/EditableHeadingsTextLengthValidationError';
import useEditableHeadingsTextLengthValidation from 'components/utilities/EditableHeadingsTextLengthValidationError/hooks/useEditableHeadingsTextLengthValidation';
import EditIcon from '@mui/icons-material/Edit';

type StoreHeadingProps = {
  fontSize: string;
  color?: any;
  textAlign?: any;
};

const StoreHeading: React.FunctionComponent<StoreHeadingProps> = ({
  fontSize,
  color,
  textAlign,
}) => {
  const [showInPlaceActionTaker, setShowInPlaceActionTaker] = useState(false);
  const {
    lengthValidationError,
    setLengthValidationError,
    makeErrorFalseIfError,
    MAX_HEADING_LENGTH,
  } = useEditableHeadingsTextLengthValidation();
  const inPlaceActionTakenDOMRef = useOnclickOutside(() => {
    setShowInPlaceActionTaker(false);
    makeErrorFalseIfError();
  });
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: editor } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  });
  const storeHeadingFromCachedData = editor?.ui.storeHeading ?? 'Store';
  // console.log('storeHeadingFromCachedData: ', storeHeadingFromCachedData);
  const [udpateEditor] = useUpdatedEditorByIdMutation();
  const storeHeadingDomRef = useRef<HTMLHeadingElement | null>(null);
  const [handleStoreUIHeadingChange] = useHtmlContentEditable(
    storeHeadingDomRef,
    async (changedHeading) => {
      // validation
      const changedHeadingLength = changedHeading.length;
      if (changedHeadingLength > MAX_HEADING_LENGTH) {
        setLengthValidationError(true);
        return;
      } else {
        makeErrorFalseIfError();
      }
      if (changedHeading === storeHeadingFromCachedData) {
        setShowInPlaceActionTaker(false);
        return;
      }
      if (!editor) {
        console.error('Cannot update about heading becuase editor is undefined');
        toast.error('Unable to update About heading');
        return;
      }
      // update user object
      await udpateEditor({
        editorId,
        loggedInUser: {
          ...editor,
          ui: {
            ...editor.ui,
            storeHeading: changedHeading,
          },
        },
      }).unwrap();
    }
  );

  const uponConfirmHeadingChangeClick = () => {
    handleStoreUIHeadingChange();
  };

  const uponRejectHeadingChangeClick = () => {
    setShowInPlaceActionTaker(false);
    makeErrorFalseIfError();
  };

  return (
    <InPlaceActionTaker
      error={lengthValidationError}
      domRef={inPlaceActionTakenDOMRef}
      show={showInPlaceActionTaker}
      uponConfirmClick={uponConfirmHeadingChangeClick}
      uponRejectClick={uponRejectHeadingChangeClick}
    >
      <>
        <Typography
          variant='h1'
          marginBottom={{ xs: 2, md: 0 }}
          onClick={() => setShowInPlaceActionTaker(true)}
          textAlign={textAlign}
          color={color}
          fontSize={{
            xs: `calc(${fontSize} / 1.2)`,
            // xs: '15px',
            sm: fontSize,
          }}
          sx={{            
            outline: '1px solid transparent',
            '&:hover': {
              outline: (theme) =>  '1px solid ' + theme.palette.text.secondary,
            },
            '&:focus': {
              outline: '1px solid transparent',
            },
          }}
          ref={storeHeadingDomRef}
          spellCheck={false}
          suppressContentEditableWarning
          contentEditable
        >
          {storeHeadingFromCachedData}
          &nbsp;
          <Typography color='inherit' component='sup'>
            <EditIcon />
          </Typography>
        </Typography>
        <EditableHeadingsTextLengthValidationError isError={lengthValidationError} />
      </>
    </InPlaceActionTaker>
  );
};

export default StoreHeading;
