import {
  Button,
  CircularProgress,
  Link as MuiLink,
  Stack,
  SxProps,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';

import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import Or from 'views/Home/utility-components/Or';

import { AsyncReducerActions, Actions } from 'hooks/useAsyncStatus';

import { FcGoogle } from 'react-icons/fc';
import { ImFacebook2 } from 'react-icons/im';
import React, { useState } from 'react';
import { UserSignupLoginInterfaceWrapper } from './UserSignupLoginInterfaceWrapper';

type ThisProps = {
  type: 'sign-in' | 'sign-up';
  sx?: SxProps;
  handleGoogleBtnClick: () => void;
  handleFbBtnClick: () => void;
  handleCustomEmailClick: (email: string) => void;
  isGoogleLoading?: boolean;
  isFbLoading?: boolean;
  customLoginStatus?: string;
  children?: React.ReactNode;
  emailLogin: {
    isError: boolean;
    updateStatus: React.Dispatch<AsyncReducerActions>;
    error: string;
    isLoading: boolean;
  };
};

const UserSignupLoginInterface = ({
  type,
  sx = {},
  handleGoogleBtnClick,
  handleFbBtnClick,
  handleCustomEmailClick,
  isGoogleLoading,
  isFbLoading,
  customLoginStatus,
  children,
  emailLogin: {
    isError,
    updateStatus,
    error,
    isLoading,
  },
}: ThisProps) => {
  const [email, setEmail] = useState('');
  const theme = useTheme();
  const isSignIn = type === 'sign-in';
  const textFieldHelperText = isError ? error : '';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonStyle: SxProps = {
    justifyContent: 'flex-start',
    textTransform: 'none',
    pointerEvents: isGoogleLoading || isFbLoading ? 'none' : 'auto',
    '& .MuiButton-startIcon': {
      mr: '12px',
      ml: 0,
    },
  };
  const isThereAnyProcessLoading = isGoogleLoading || isFbLoading || isLoading;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const isValidEmail = email.includes('@') && email.includes('.');

    if (!isValidEmail) {
      updateStatus({ type: Actions.ERROR, payload: 'Invalid email' });
    }

    updateStatus({ type: Actions.SUCCESS, payload: 'Valid email' });
    handleCustomEmailClick(email);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <UserSignupLoginInterfaceWrapper sx={sx}>
      <Stack direction='column' gap={2}>
        <Typography variant='h6' fontWeight={600} component='h6' textAlign='center'>
          {isSignIn ? 'Access your account' : 'Create your first website'}
        </Typography>
        <Stack
          component='form'
          onSubmit={handleSubmit}
          direction='column'
          flexWrap={'nowrap'}
          gap={1.5}
        >
          <TextFieldSyncWithTheme
            autoComplete='off'
            type='email'
            aria-label='email'
            value={email}
            onChange={handleChange}
            helperText={textFieldHelperText}
            error={isError}
            placeholder='Email'
            inputProps={{
              sx: {
                textTransform: 'lowercase',
              },
            }}
            FormHelperTextProps={{
              sx: {
                display: 'block',
                fontSize: '1.35rem',
              },
            }}
          />
          <ResponsiveButton
            variant='contained'
            disabled={isThereAnyProcessLoading}
            type='submit'
            endIcon={
              isLoading ? <CircularProgress color='inherit' size={20} /> : null
            }
          >
            {customLoginStatus}
          </ResponsiveButton>
        </Stack>

        {isSignIn ? (
          <Typography variant='body2'>
            New here?{' '}
            <MuiLink to='/sign-up' component={Link} underline='hover'>
              Create a website
            </MuiLink>
          </Typography>
        ) : (
          <Typography variant='body2'>
            Already have an account?{' '}
            <MuiLink to='/sign-in' component={Link} underline='hover'>
              Sign in
            </MuiLink>
          </Typography>
        )}

        <Or />

        <Stack direction='column' flexWrap={'nowrap'} gap={0.8}>
          <Button
            size={isMobile ? 'small' : 'large'}
            sx={buttonStyle}
            variant='outlined'
            color='secondary'
            disabled={isThereAnyProcessLoading}
            startIcon={<FcGoogle />}
            onClick={handleGoogleBtnClick}
            endIcon={isGoogleLoading ? <CircularProgress size={20} /> : null}
          >
            {isSignIn ? 'Login with Google' : 'Sign up with Google'}
          </Button>
          <Button
            size={isMobile ? 'small' : 'large'}
            sx={buttonStyle}
            variant='outlined'
            color='secondary'
            disabled={isThereAnyProcessLoading}
            startIcon={<ImFacebook2 color='#3B5998' />}
            endIcon={isFbLoading ? <CircularProgress size={20} /> : null}
            onClick={handleFbBtnClick}
          >
            {isSignIn ? 'Login with Facebook' : 'Sign up with Facebook'}
          </Button>
        </Stack>

        {children}
      </Stack>
    </UserSignupLoginInterfaceWrapper>
  );
};

export default UserSignupLoginInterface;
