import { useMemo, useState } from "react";

type StateControllerPossibleKeys = 'setTrue' | 'setFalse' | 'setToggle';

export type StateController = Record<StateControllerPossibleKeys, () => void> & {
  set: (state: boolean) => void;
};

type ReturnState = [
  state: boolean,
  stateController: StateController
]

export default function useBooleanStateController(initialVal: boolean = false): ReturnState {
  const [state, setstate] = useState<boolean>(initialVal);

  const stateController = useMemo(
    () => ({
      setTrue: () => setstate(true),
      setFalse: () => setstate(false),
      setToggle: () => setstate((prevState) => !prevState),
      set: (state: boolean) => setstate(state),
    }),
    []
  );

  return [state, stateController];
}