import useGetLoginToken from 'hooks/useGetLoginToken';
import { useGetStripePlatformCustomerQuery } from 'store/rtkqFeatures/api/stripePlatformCustomerExtendedApi';
import useGetLoggedInUser from './useGetLoggedInUser';

const useGetStripePlatformCustomer = () => {
  const { loginToken } = useGetLoginToken();
  const {cachedLoggedInUser} = useGetLoggedInUser();

  const { data: fetchedStripePlatformCustomer } = useGetStripePlatformCustomerQuery(
    null,
    {
      skip: !loginToken,
    }
  );
  let stripePlatformCustomer = fetchedStripePlatformCustomer;

  // COMMENT - Add isPremiumUser from backend request not in db.
  // This is a workaround to avoid a second request to the backend.  
  
  // ✅ when data is undefined
  if (!stripePlatformCustomer) {
    stripePlatformCustomer = {
      _id: '',
      isPremiumUser: false,      
      status: 'inactive',
    };
   }

  return {
    stripePlatformCustomer,
    isPremiumUser: !!cachedLoggedInUser?.isPremium,
    isPaidPremiumUser: stripePlatformCustomer.isPremiumUser,
  };
};

export default useGetStripePlatformCustomer;
