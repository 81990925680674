import { useField, useFormikContext } from 'formik';
import CountrySelectCustom from 'utilities/CountrySelectCustom';
import { EditUserDetailsForm } from '../types';

const EUDWhatsappNumber = () => {
  const { values } = useFormikContext<EditUserDetailsForm>();
  const [_field, _meta, fieldHelpers] = useField('whatsAppCountryCode');
  const [_whatsappField, _whatsappMeta, whatsappHelper] = useField('whatsAppNumber');

  const whatsappNumberWithCountryCode = values.whatsAppCountryCode 
  ? `+${values.whatsAppCountryCode} ${values.whatsAppNumber}`
  : `${values.whatsAppCountryCode} ${values.whatsAppNumber}`;

  const handleChange = (num: number | null, countryCode: number | null) => {
    fieldHelpers.setTouched(true);
    whatsappHelper.setTouched(true);

    if (num === null || countryCode === null) {
      fieldHelpers.setValue(undefined);
      whatsappHelper.setValue(undefined);
      return;
    }
    fieldHelpers.setValue(+countryCode);
    whatsappHelper.setValue(num.toString());
  };

  return <CountrySelectCustom value={whatsappNumberWithCountryCode} onChange={handleChange} />;
};

export default EUDWhatsappNumber;
