/**
 * utils to manage login jwt token for user's authentication.
 */

const loginTokenUtils = {
  setLocalStorageLoginToken,
  getLocalStorageLoginToken,
  removeLocalStorageLoginToken,
};

export default loginTokenUtils;

const LOGIN_TOKEN_NAME = 'loginToken';

const localStorage = window.localStorage;

function setLocalStorageLoginToken(token: string) {
  localStorage.setItem(LOGIN_TOKEN_NAME, token);
}

function getLocalStorageLoginToken() {
  const loginToken = localStorage.getItem(LOGIN_TOKEN_NAME);
  return loginToken;
}

function removeLocalStorageLoginToken() {
  localStorage.removeItem(LOGIN_TOKEN_NAME);
}
