import { useFormikContext } from 'formik';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import CountrySelectCustom from 'utilities/CountrySelectCustom';
import { Box, FormHelperText } from '@mui/material';
import BoldSpan from 'components/utilities/BoldSpan';
import Question from './reusable-components/Question';

// step: 6
const InputPhoneNumber = () => {
  const { getFieldProps, touched, errors, values, setValues } =
    useFormikContext<StepperState>();  
  const isValidationError = Boolean(touched.phone && errors.phone);
  const textFieldHelperText = isValidationError ? errors.phone : '';

  const handlePhoneChange = (
    num: number | null,
    countryCode: number | null,
    numWithCountry?: string
  ) => {
    if (num === null || countryCode === null) {
      setValues({
        ...values,
        phone: '',
        whatsapp: undefined,
      });
      return;
    }
    if (numWithCountry) {
      setValues({
        ...values,
        phone: numWithCountry,
        whatsapp:
          num && countryCode
            ? {
                countryCode: countryCode,
                num: num.toString(),
              }
            : undefined,
      });
    }
  };

  return (
    <Box>
      <Question
        currentStep={3}
        helperText='Provide a contact number to display on the website '
      >
        <>
          Provide a{' '}
          <BoldSpan>
            <>phone number</>
          </BoldSpan>
        </>
      </Question>
      <CountrySelectCustom
        inputProps={getFieldProps('phone')}
        onChange={handlePhoneChange}
        value={values.phone}
        hideLabel={true}
        customCss={{
          backgroundColor: (theme) => theme.palette.background.default,
          mt: 2,
          '& input': {
            padding: {
              xs: '0.8rem 1rem !important',
              md: '1rem 1.25rem !important',
            },
          },
        }}
        closeBtnSx={{
          transform: 'translate(-2px, -20px)',
        }}
      />
      {isValidationError && (
        <FormHelperText error color='error.main'>
          {textFieldHelperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default InputPhoneNumber;
