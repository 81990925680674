type IfElseProps = {
  condition: boolean | undefined;
  alternative?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
};

const IfElse = ({ condition, alternative = <></>, children }: IfElseProps) => {
  if (condition) return <>{children}</>;
  return alternative;
};

export default IfElse;
