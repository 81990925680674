import React from 'react';
import { Stack, Typography, FormControlLabel, Box, alpha, useTheme } from '@mui/material';
import { useStyledTheme } from 'components/ShareComponents/StyledTheme';
import StyledSwitch from 'components/ShareComponents/StyledSwitch';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import StyledItemLabel from 'components/shared/StyledItemLabel';

type StatusProps = {
  getUpdatedStatusValue: (isChecked: boolean) => void;
  status: boolean;
  activeStatus: 'active' | 'inactive';
  isEntityPaid: boolean;
  currentStep: number;
  label?: string;
};

const Status: React.FC<StatusProps> = ({
  getUpdatedStatusValue,
  status,
  activeStatus,
  isEntityPaid,
  currentStep = 1,
  label = 'Status',
}) => {
  const theme = useTheme();
  
  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    getUpdatedStatusValue(event.target.checked);
  };

  return (
    <Stack direction='column' spacing={0.1}>
      <StyledItemLabel label={label}/>
      <FormControlLabel
        control={
          <StyledSwitch
            checked={status}
            onChange={handleStatusChange}
            disabled={isEntityPaid}
          />
        }
        label={
          <Box
          sx={{
            marginLeft: '10px',
            padding: '4px 12px',
            borderRadius: '16px',
            backgroundColor: status ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.grey[400], 0.1),
            color: status ? theme.palette.primary.main : theme.palette.text.secondary,
            fontSize: '1.4rem',
            fontWeight: 600,
            transition: 'all 0.3s ease',
          }}
        >
          {activeStatus}
        </Box>
        }
      />
      {isEntityPaid && (
        <TextFieldSyncWithTheme
          disabled
          value="Cannot change status of a paid entity"
          fullWidth
          variant="outlined"
        />
      )}
    </Stack>
  );
};

export default Status;