import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';

import IfElse from 'components/utilities/IfElse';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';

import {
  style_ProfileCard_bgImage,
  style_ProfileCard_bgWave,
} from 'components/ProfileCard/ProfileCard.style';

import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import LayoutContainer from 'components/Containers/LayoutContainer';
import { checkImageTransparency } from 'utilities/analyzeAndSuggestTextColor';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import LayoutWrapper from './reusable-components/LayoutWrapper';
import LayoutProfileImg from './reusable-components/LayoutProfileImg';

const Layout1 = () => {
  const {
    userImage,
    bgImage,

    currentTheme,
    tagsArrayLength,
    tagsArray,

    userName,
    userTitle,
    user,

    userNameColor,
    titleColor,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,
    userBgImage,

    buttonStyle,
    bg,

    isMeetingAndServiceAvailable,
    storeHeading,

    isNeedDiv,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,

    displayTags,
    displaySocialLinks,
  } = useProfileCard();

  const [isImageTransparent, setIsImageTransparent] = useState(false);

  useEffect(() => {
    if (userImage) {
      (async () => {
        try {
          const isTransparent = await checkImageTransparency(userImage);
          setIsImageTransparent(isTransparent);
        } catch (err) {
          console.error(err);
        }
      })();
    }
  });

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box component='section'>
      <LayoutWrapper sx={isNeedDiv ? {} : { ...bg }}>
        {isNeedDiv && !userBgImage && (
          <Box className='bg-wave' sx={style_ProfileCard_bgWave(bg)}></Box>
        )}
        {userBgImage && (
          <Box className='bg-image' sx={style_ProfileCard_bgImage(bgImage)}></Box>
        )}
        <LayoutContainer>
          <ProfileEditAndDesignBtn />
          <Stack
            flexWrap={{ xs: 'wrap', md: 'nowrap' }}
            direction={'row'}
            boxShadow={1}
            zIndex={2}
            position='relative'
            sx={{ 
              boxShadow: 3,
              maxWidth: 1200,
              m: 'auto',
            }}
          >
            <Stack
              sx={{
                order: { xs: 1, md: 0 },
                flexBasis: { xs: '100%', md: '50%' },
                backdropFilter: 'blur(10px)',
                flexGrow: { xs: 0, md: 1 },
              }}
              className='text_section'
              flexGrow={1}
              spacing={2}
              px={{ xs: 2, md: 4 }}
              py={{ xs: 4, md: 4 }}
              useFlexGap
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Typography
                variant='h2'
                fontWeight={600}
                textAlign={'center'}
                color={userNameColor}
                fontSize={{ xs: 30, md: 40 }}
              >
                <Link to={'/edit-user'}>{userName}</Link>
              </Typography>

              <Link to={'/edit-user'}>
                <Typography variant='h5' textAlign={'center'} color={titleColor}>
                  {userTitle}
                </Typography>
              </Link>

              {displaySocialLinks !== false && (
                <Box display='inline-block'>
                  <SocialIconsList
                    emailPublic={emailPublic}
                    email={email}
                    socialLinks={socialLinks}
                    whatsapp={whatsapp}
                    address={address}
                    phone={phone}
                    styling={{ justifyContent: 'center', display: 'inline-flex' }}
                    showOnlyIcon
                  />
                </Box>
              )}

              <IfElse condition={!!tagsArrayLength && displayTags !== false}>
                <Box>
                  <Link to={'/edit-user'}>
                    <Tags tagsArray={tagsArray} tag={tag} global={global} />
                  </Link>
                </Box>
              </IfElse>

              {isMeetingAndServiceAvailable && (
                <Stack
                  direction={'row'}
                  justifyContent={'center'}
                  spacing={3}
                  useFlexGap
                  mt={2}
                  pt={0}
                >
                  {isMeetingAndServiceAvailable && storeHeading && (
                    <CallToActionBtn
                      user={user}
                      buttonStyle={buttonStyle}
                      storeHeading={storeHeading}
                    />
                  )}
                </Stack>
              )}
            </Stack>
            <Box
              className='profile_img'
              sx={{
                order: { xs: 0, md: 1 },
                backdropFilter: 'blur(10px)',
                flexBasis: { xs: '100%', md: '50%' },
                p: isImageTransparent ? 4 : 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <LayoutProfileImg />
            </Box>
          </Stack>
        </LayoutContainer>
      </LayoutWrapper>

      {/* sub title */}
      <ProfileSubtitle
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        subTitle={user.subTitle}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout1;
