import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import {
  Box,
  Divider,
  IconButton,
  Slider,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';

import centerLayoutSvg from 'assets/icons/layouts/center.svg';
import leftLayoutSvg from 'assets/icons/layouts/left.svg';
import rightLayoutSvg from 'assets/icons/layouts/right.svg';
import boxImageLayoutSvg from 'assets/icons/layouts/box-image.svg';
import layout11Icon from 'assets/icons/layouts/layout11.svg';
import layout12Icon from 'assets/icons/layouts/layout12.svg';
import centerFullBackgroundLayoutSvg from 'assets/icons/layouts/center-full-background.svg';
import boxFullBackgroundLayoutSvg from 'assets/icons/layouts/box-full-background.svg';
import centerTextFirstLayoutSvg from 'assets/icons/layouts/center-text-first.svg';
import rightImageRotated from 'assets/icons/layouts/right-image-rotated.svg';
import leftFullBackgroundLayoutSvg from 'assets/icons/layouts/left-full-background.svg';
import boxBackgroundSvg from 'assets/icons/layouts/box-background.svg';
import objectFitContain from 'assets/icons/portfolio-icons/image_fit_contain.svg';
import objectFitCover from 'assets/icons/portfolio-icons/image_fit_cover.svg';
import imageCircle from 'assets/icons/portfolio-icons/image_circle.svg';
import imageRounded from 'assets/icons/portfolio-icons/image_rounded.svg';
import imageSquare from 'assets/icons/portfolio-icons/image_square.svg';

import EditDrawer from 'components/utilities/EditDrawer';
import { PopupHeading } from 'components/dashboard/DashboardReusableComponent';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { usePatchUpdateEditorFieldByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';

import { Layout } from 'types/user';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  selectLocalLayoutState,
  updateLocalCurrentLayoutIndex,
} from 'store/features/localLayoutState';
import { PREMIUM_THEME_ICON_URL } from 'components/ThemeSelector/ThemeSelector';
import {
  DrawerButton,
  DrawerSectionHeading,
} from 'components/Drawer/DrawerResuableComponent';
import {
  handleError,
  imageBorderRadius,
} from 'components/Portfolios/sub-components/PortfolioDrawer';

export type ListOfLayouts = {
  layout: Layout;
  isPremium: boolean;
  svg: string;
};

export const listOfLayouts: ListOfLayouts[] = [
  {
    layout: 'Layout6',
    isPremium: false,
    svg: rightLayoutSvg,
  },
  {
    layout: 'Layout7',
    isPremium: false,
    svg: leftLayoutSvg,
  },
  {
    layout: 'Layout8',
    isPremium: false,
    svg: centerLayoutSvg,
  },
  {
    layout: 'Layout4',
    isPremium: false,
    svg: centerTextFirstLayoutSvg,
  },
  {
    layout: 'Layout2',
    isPremium: false,
    svg: centerFullBackgroundLayoutSvg,
  },
  {
    layout: 'Layout3',
    isPremium: false,
    svg: boxFullBackgroundLayoutSvg,
  },
  {
    layout: 'Layout5',
    isPremium: false,
    svg: leftFullBackgroundLayoutSvg,
  },
  {
    layout: 'Layout1',
    isPremium: false,
    svg: boxImageLayoutSvg,
  },
  {
    layout: 'Layout9',
    isPremium: false,
    svg: rightImageRotated,
  },
  {
    layout: 'Layout10',
    isPremium: false,
    svg: boxBackgroundSvg,
  },
  {
    layout: 'Layout11',
    isPremium: false,
    svg: layout11Icon,
  },
  {
    layout: 'Layout12',
    isPremium: false,
    svg: layout12Icon,
  },
];

// Main Component
const ProfileDrawer = () => {
  return (
    <EditDrawer
      buttonProps={{
        color: 'primary',
        sx: { boxShadow: 0, '&:hover': { boxShadow: 0 } },
      }}
    >
      <Box sx={{ px: 2, py: 2, position: 'relative', height: '100%' }}>
        <PopupHeading variant='h6'>Profile Settings</PopupHeading>
        <Divider />

        <Stack direction={'column'} spacing={{ xs: 2, md: 4 }} mt={2} useFlexGap>
          <ProfileSocialLinks />
          <Divider />
          <ProfileTags />
          <Divider />
          <ImageDisplayOptions />
          <Divider />
          <ImageFrame />
          <Divider />
          <HeightSlider />
          <Divider />
          <ProfileLayout />
        </Stack>
      </Box>
    </EditDrawer>
  );
};

export default ProfileDrawer;

// Additional Components
function ProfileSocialLinks() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const displaySocialLinks = cachedLoggedInUser?.ui?.displaySocialLinks !== false;
  const [updateEditor, { isLoading }] = usePatchUpdateEditorFieldByIdMutation();

  const handleSocialLinksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!cachedLoggedInUser) return;
    updateEditor({
      editorId: cachedLoggedInUser._id,
      ui: {
        ...cachedLoggedInUser.ui,
        displaySocialLinks: e.target.checked,
      },
    });
  };

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack
        justifyContent={'space-between'}
        direction='row'
        spacing={2}
        alignItems={'center'}
      >
        <DrawerSectionHeading>
          {displaySocialLinks ? 'Hide Social Links' : 'Show Social Links'}
        </DrawerSectionHeading>
        <Switch
          name='hide-social-links'
          size={isMobile ? 'small' : 'medium'}
          checked={!!displaySocialLinks}
          onChange={handleSocialLinksChange}
          color='primary'
        />
      </Stack>
    </>
  );
}

function ProfileTags() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const displayTags = cachedLoggedInUser?.ui?.displayTags !== false;
  const [updateEditor, { isLoading }] = usePatchUpdateEditorFieldByIdMutation();

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!cachedLoggedInUser) return;
    updateEditor({
      editorId: cachedLoggedInUser._id,
      ui: {
        ...cachedLoggedInUser.ui,
        displayTags: e.target.checked,
      },
    });
  };

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack
        justifyContent={'space-between'}
        direction='row'
        spacing={2}
        alignItems={'center'}
      >
        <DrawerSectionHeading>
          {displayTags ? 'Hide Tags' : 'Show Tags'}
        </DrawerSectionHeading>
        <Switch
          name='hide-tags'
          size={isMobile ? 'small' : 'medium'}
          checked={!!displayTags}
          onChange={handleTagsChange}
          color='primary'
        />
      </Stack>
    </>
  );
}

function ImageDisplayOptions() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const [updateEditor, { isLoading, isError }] = usePatchUpdateEditorFieldByIdMutation();
  const profileImageFit = cachedLoggedInUser?.profileStyle?.image?.objectFit || 'contain';
  interface ButtonList {
    svg: string;
    objectFit: React.CSSProperties['objectFit'];
  }
  const buttonList: ButtonList[] = [
    { svg: objectFitContain, objectFit: 'contain' },
    { svg: objectFitCover, objectFit: 'cover' },
  ];

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    objectFit: React.CSSProperties['objectFit']
  ) {
    e.preventDefault();
    if (cachedLoggedInUser) {
      updateEditor({
        editorId: cachedLoggedInUser._id,
        profileStyle: {
          ...cachedLoggedInUser.profileStyle,
          image: {
            ...cachedLoggedInUser?.profileStyle?.image,
            objectFit,
          },
        },
      });
    }
  }

  handleError(isError, 'Error while updating image display options');

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack
        direction={'row'}
        spacing={0.5}
        alignItems='center'
        justifyContent={'space-between'}
      >
        <DrawerSectionHeading>Image fit</DrawerSectionHeading>
        <Stack direction={'row'} useFlexGap spacing={0} justifySelf={'flex-end'}>
          {buttonList.map(({ svg, objectFit }, index) => (
            <DrawerButton
              key={index}
              onClick={(e) => handleClick(e, objectFit)}
              src={svg}
              selected={profileImageFit === objectFit}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
}

function ImageFrame() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const [updateEditor, { isLoading, isError }] = usePatchUpdateEditorFieldByIdMutation();
  const profileImageFrame = cachedLoggedInUser?.profileStyle?.image?.borderRadius || 0;

  interface ButtonList {
    svg: string;
    borderRadius: React.CSSProperties['borderRadius'];
    clipPath?: React.CSSProperties['clipPath'];
  }
  const buttonList: ButtonList[] = [
    { svg: imageRounded, borderRadius:  imageBorderRadius.rounded },
    { svg: imageCircle, clipPath: 'circle()', borderRadius: imageBorderRadius.circle },
    { svg: imageSquare, borderRadius: imageBorderRadius.square },
  ];

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    borderRadius: React.CSSProperties['borderRadius'],
    clipPath?: React.CSSProperties['clipPath']
  ) {
    e.preventDefault();
    if (cachedLoggedInUser) {
      updateEditor({
        editorId: cachedLoggedInUser._id,
        profileStyle: {
          ...cachedLoggedInUser.profileStyle,
          image: {
            ...cachedLoggedInUser?.profileStyle?.image,
            borderRadius,
            clipPath,
          },
        },
      });
    }
  }

  handleError(isError, 'Error while updating image frame');

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack
        direction={'row'}
        spacing={0.5}
        alignItems='center'
        justifyContent={'space-between'}
        flexWrap='wrap'
      >
        <DrawerSectionHeading>Image Frame</DrawerSectionHeading>
        <Stack direction={'row'} useFlexGap spacing={0}>
          {buttonList.map(({ svg, borderRadius, clipPath }, index) => (
            <DrawerButton
              key={index}
              src={svg}
              onClick={(e) => handleClick(e, borderRadius, clipPath)}
              selected={profileImageFrame === borderRadius}
            />
          ))}
        </Stack>
      </Stack>
    </>
  );
}

function HeightSlider() {
  const [height, setHeight] = useState(100);
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const [updateEditor, { isLoading, isError }] = usePatchUpdateEditorFieldByIdMutation();

  useEffect(() => {
    if (cachedLoggedInUser?.profileStyle?.image?.height) {
      const height = Number(cachedLoggedInUser?.profileStyle.image.height) || 300;
      const newHeight = isNaN(height) ? 500 : height / 3.5;
      setHeight(newHeight);
    }
  }, [cachedLoggedInUser?.profileStyle?.image?.height]);

  const debounceAPI = useCallback(
    debounce((heightInPercentage: number) => {
      if (cachedLoggedInUser) {
        updateEditor({
          editorId: cachedLoggedInUser._id,
          profileStyle: {
            ...cachedLoggedInUser.profileStyle,
            image: {
              ...cachedLoggedInUser?.profileStyle?.image,
              height: heightInPercentage * 3.5,
            },
          },
        });
      }
    }, 500),
    [cachedLoggedInUser, updateEditor]
  );

  function handleChange(event: Event, newValue: number | number[]) {
    event.stopPropagation();
    event.preventDefault();
    const height = newValue as number;
    setHeight(height);
    debounceAPI(newValue as number);
  }

  handleError(isError, 'Error while updating image height');

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems='center'
      justifyContent={'space-between'}
      flexWrap='wrap'
    >
      <SectionLoader isLoading={isLoading} />
      <DrawerSectionHeading>Image Height</DrawerSectionHeading>
      <Typography variant='body2' borderBottom={'1px solid'} color={'common.black'}>
        {height}%
      </Typography>
      <Slider
        sx={{ mt: 1, mr: height === 100 ? 7 + 'px !important' : 0 }}
        aria-label='Max-height'
        aria-valuetext={`Image height: ${height}%`}
        defaultValue={100}
        value={height}
        componentsProps={{
          thumb: {
            onTouchMove: (e) => {
              e.stopPropagation()
            },
          }
        }}
        marks
        onChange={handleChange}
        valueLabelDisplay='auto'
        step={10}
        min={10}
        max={100}
      />
    </Stack>
  );
}

function ProfileLayout() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const [updateEditor, { isLoading }] = usePatchUpdateEditorFieldByIdMutation();
  const localLayout = useAppSelector(selectLocalLayoutState);
  const dispatchLocalLayout = useAppDispatch();

  const handleLayoutClick = (layout: Layout, isPremium: boolean) => {
    if (!cachedLoggedInUser) return;
    dispatchLocalLayout(updateLocalCurrentLayoutIndex(layout));

    updateEditor({
      editorId: cachedLoggedInUser._id,
      profileStyle: {
        ...cachedLoggedInUser.profileStyle,
        layout,
      },
    });
  };

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <Stack direction={'column'}>
        <DrawerSectionHeading>Layout</DrawerSectionHeading>
        <Stack
          direction={'row'}
          columnGap={1.1}
          rowGap={2}
          useFlexGap
          flexWrap={'wrap'}
          borderRadius={'3px'}
          paddingTop={'4px'}
        >
          {listOfLayouts.map(({ layout, isPremium, svg }, index) => {
            return (
              <IconButton
                key={index}
                title={layout}
                onClick={() => handleLayoutClick(layout, isPremium)}
                sx={{
                  position: 'relative',
                  borderRadius: 1,
                  border: (theme) =>
                    localLayout === layout
                      ? `1px solid ${theme.palette.primary.main}`
                      : '1px solid transparent',
                }}
              >
                <Typography component='img' sx={{ width: 80 }} src={svg} alt={layout} />
                {/* premium theme icon  */}
                {isPremium && (
                  <Box
                    component='img'
                    src={PREMIUM_THEME_ICON_URL}
                    alt='premium-theme-icon'
                    width={20}
                    sx={{ position: 'absolute', top: 0, right: 0 }}
                  />
                )}
              </IconButton>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
}
