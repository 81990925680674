import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import { StripePlatformCustomer } from 'types/stripePlatformCustomer';

const stripePlatformCustomerExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStripePlatformCustomer: build.query<StripePlatformCustomer, null>({
      query: () => ({
        url: '/stripe-platform-customers/',
      }),
      providesTags: ['StripePlatformCustomer'],
    }),
  }),
});

export const { useGetStripePlatformCustomerQuery } = stripePlatformCustomerExtendedApi;

export default stripePlatformCustomerExtendedApi;
