import React from 'react';
import Slider, { Settings } from 'react-slick';
import Box from '@mui/material/Box/Box';
import { SxProps } from '@mui/material';

import { useGetPortfoliosByUserIdQuery } from 'store/rtkqFeatures/api/portfolioExtendedApi';
import Portfolio from 'components/Portfolios/sub-components/Portfolio/Portfolio';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';

// Style definitions
export const sliderStyle: SxProps = {
  maxWidth: {
    xs: 'calc(100vw - 60px)',
    lg: '100%',
  },
  mx: 'auto',
  '& .slick-list': {},
  '& .slick-arrow::before': {
    color: '#4e4e4e',
    '@media only screen and (max-width: 400px)': {},
  },
  '& .slick-arrow.slick-prev': {
    '@media only screen and (max-width: 400px)': {
      marginRight: '-40px',
    },
  },
  '& .slick-arrow.slick-next': {
    '@media only screen and (max-width: 400px)': {
      marginLeft: 0,
    },
  },
  '& .slick-slide': {
    p: {xs: 0, md: '2rem'},
  },
  '& .slick-dots li button:before': {
    fontSize: '10px',
  },
  '& .slideWithOneImg': {
    '& .slick-list': {
      paddingLeft: '0',
    },
  },
};

const portfolioReactSlickSettings: Settings = {
  dots: true,
  arrows: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 200,
      },
    },
  ],
};

// Type definitions
type PortfolioSliderProps = {
  sliderSettings?: number | null;
  setSlider: React.Dispatch<React.SetStateAction<Slider | null | undefined>>;
  userId: string;
};

// Component definition
function PortfolioSlider({ setSlider, sliderSettings, userId }: PortfolioSliderProps) {
  // Hooks and state
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { portfolioSylings } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      portfolioSylings: themes
        ? themes[localCurrentThemeIndex].portfolios
        : initialThemeState.portfolios,
    }),
  });
  const { data } = useGetPortfoliosByUserIdQuery(userId, {
    skip: !!!userId,
  });
  const userPortfolios = data?.gallery;
  const portfoliosSliderSettings = data?.portfolioSliderSettings;
  const havePortfoliosToShow = !!userPortfolios?.length;
  const { slidesToScroll, ...restPortfolioSettings } = portfolioReactSlickSettings;
  const slidesToShow =
    portfoliosSliderSettings?.slidesToShow || restPortfolioSettings.slidesToShow || 3;

  // Early return
  if (!userPortfolios?.length) return <></>;

  return (
    <Box sx={sliderStyle}>
      <Slider
        ref={(c) => setSlider(c)}
        {...restPortfolioSettings}
        {...portfoliosSliderSettings}
        infinite={userPortfolios.length > slidesToShow}
        slidesToScroll={
          sliderSettings ? sliderSettings : portfolioReactSlickSettings.slidesToScroll
        }
        className={!!havePortfoliosToShow ? '' : 'slideWithOneImg'}
      >
        {!!havePortfoliosToShow &&
          userPortfolios?.map((_, index) => (
            <Portfolio
              key={index}
              portfolioIndex={index}
              portfolioSylings={portfolioSylings}
            />
          ))}
      </Slider>
    </Box>
  );
}

export default PortfolioSlider;
