import React from 'react';
import debounce from 'lodash/debounce';
import Box from '@mui/material/Box';
import { Editor } from '@tinymce/tinymce-react';
import useTinyMceEditorConfig from './useTinyMceEditorConfig';
import { imgUploadHandler } from './tinymceImgUploader';
import { styled } from '@mui/material';

// NOTE DEV: if facing rendering issues, just increase the autoUpdateDuration(ms).

export type TinymceRichTextProps = {
  initialContent: string;
  handleDescriptionChange: (
    updatedEditorContent: string,
    name?: string
  ) => void;
  disabled?: boolean;
  name?: string;
  height?: string;
  wrapperStylingClassNames?: string;
  id?: string;
  autoUpdateDuration?: number;
  enableTimeOut?: boolean;
};

const TinymceRichText = ({
  initialContent,
  handleDescriptionChange,
  id = '',
  name = '',
  disabled = false,
  wrapperStylingClassNames = '',
  height = '400px',
  autoUpdateDuration = 200,
  enableTimeOut = false,
}: TinymceRichTextProps) => {
  const editorRef = React.useRef<Editor>(null);
  const tinyMceConfig = useTinyMceEditorConfig();
  const wrapperStyle = {
    border: 0,
    height,
    flexGrow: 1,
  };
  let timeOutId: NodeJS.Timeout;

  const handleUpdateParent = debounce((newContent: string) => {
    clearTimeout(timeOutId);

    function updateEditorContent() {
      const bookmark = editorRef.current?.editor?.selection.getBookmark(
        2,
        true
      );
      handleDescriptionChange(newContent.trim(), name);
      if (bookmark) {
        editorRef.current?.editor?.selection.moveToBookmark(bookmark);
      }
    }
    enableTimeOut
      ? (timeOutId = setTimeout(updateEditorContent, 200))
      : updateEditorContent();
  }, autoUpdateDuration);

  return (
    <Box style={wrapperStyle} className={wrapperStylingClassNames} id={id}>
      <Editor
        id={id}
        ref={editorRef}
        apiKey={process.env.REACT_APP_TINYMCE_RICHTEXT_API_KEY}
        disabled={disabled}
        initialValue={initialContent}
        onEditorChange={handleUpdateParent}
        onSubmit={(event) => event.preventDefault()}
        init={{
          height,
          images_upload_handler: imgUploadHandler,
          ...tinyMceConfig,
        }}
      />
    </Box>
  );
};

export default React.memo(TinymceRichText);

// NOTE: to be used where injecting tinymce content with dangerouslySetInnerHTML as a wrapper

export const TinymceFormatPreserver = styled(Box)(() => ({
  overflow: 'hidden',
  '& *': {
    all: 'revert-layer',
    color: 'inherit',
    fontSize: 'inherit',
    textAlign: 'revert',
  },
  '& code': {
    // background: 'whitesmoke',
  },
  '& img': {
    maxWidth: '100%',
    objectFit: 'contain',
  },
  '& a': {
    color: '#0d6efd',
    textDecoration: 'underline',
  },
}));
