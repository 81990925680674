import { Box, Stack } from '@mui/material';
import UploadImage from 'components/utilities/Cloudinary/UploadImage';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import Compressor from 'compressorjs';
import StyledItemLabel from 'components/shared/StyledItemLabel';

interface FormData {
  img: string;
  file: File | null;
  deletedImg?: string;
  isFormDirty: boolean;
}

const UploadImageBase = () => {
  const { values, setFieldValue } = useFormikContext<FormData>();
  const { img, file } = values;

  const handleImgUpload = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];
    if (file) {
      new Compressor(file, {
        quality: 0.95,
        maxWidth: 1200,
        maxHeight: 1200,
        success: async (compressedFile) => {
          if (img) {
            setFieldValue('deletedImg', img);
          }
          setFieldValue('file', compressedFile);
          setFieldValue('isFormDirty', true);
        },
        error: (error) => {
          toast.error(error.message);
        },
      });
    }
  };

  const removeFile = async (url: string | File) => {
    if (typeof url === 'string') {
      setFieldValue('deletedImg', url);
      setFieldValue('img', '');
    } else {
      setFieldValue('file', null);
    }
    setFieldValue('isFormDirty', true);
  };

  return (
    <Stack direction='column' spacing={0.1} data-id='upload-img'>
      <StyledItemLabel label="image" />
      <UploadImage
        imgUploadCallback={handleImgUpload}
        removeCallback={removeFile}
        img={file ?? img}
        width={200}
        height={200}
      />
    </Stack>
  );
};

export default UploadImageBase;