// TODO nEED TO BE REFACTORED AND CONVERT TO BUTTONS
import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';

import { useUpdateServiceOrderStatusMutation } from 'store/rtkqFeatures/api/serviceOrdersExtendedApi';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import { PopupSectionHeading } from 'components/dashboard/DashboardReusableComponent';
import { ServiceOrderStatusValues } from 'types/serviceOrder';

type ServiceOrderStatusProps = {
  serviceOrderId: string;
  cacheServiceOrderStatus: ServiceOrderStatusValues;
};

const ServiceOrderActions = ({
  cacheServiceOrderStatus,
  serviceOrderId,
}: ServiceOrderStatusProps) => {
  const [updateServiceOrderStatus, { isLoading: serviceOrderStatusChangeInProgress }] =
    useUpdateServiceOrderStatusMutation();
    
  const isStatusSelectDisabled =
    cacheServiceOrderStatus === ServiceOrderStatusValues.DELIVERED ||
    serviceOrderStatusChangeInProgress;

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedStatus = event.target.value as ServiceOrderStatusValues;
    await updateServiceOrderStatus({
      serviceOrderId,
      updatedStatus: selectedStatus,
    }).unwrap();
  };

  return (
    <Stack spacing={1}>
      <SectionLoader isLoading={serviceOrderStatusChangeInProgress} />
      <PopupSectionHeading>Status</PopupSectionHeading>
      <Select
        size='small'
        variant='outlined'
        disabled={isStatusSelectDisabled}
        labelId='service-order-status-select'
        id='service-order-status-select'
        label=''       
        onChange={handleChange}
        value={cacheServiceOrderStatus}
      >
        <MenuItem disabled={true} value={ServiceOrderStatusValues.RECEIVED}>
          Received
        </MenuItem>
        <MenuItem value={ServiceOrderStatusValues.IN_PROGRESS}>In Progress</MenuItem>
        <MenuItem value={ServiceOrderStatusValues.DELIVERED}>Delivered</MenuItem>
      </Select>
    </Stack>
  );
};

export default ServiceOrderActions;
