import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Link as MUILink,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';

import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import { useGetDomainDetailsByIdQuery } from 'store/rtkqFeatures/api/domainExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { DomainPurchase, Enom, RegistrantDetails } from './domainManagmentType';
import serverApi from 'lib/apiConfig';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import routeConstants from 'constants/routeConstants';

const registrantKeyNamesToDisplay: { [K in keyof RegistrantDetails]: string } = {
  registrantFirstName: 'First Name',
  registrantLastName: 'Last Name',
  registrantAddress1: 'Address',
  registrantCity: 'City',
  registrantPostalCode: 'Postal Code',
  registrantCountry: 'Country',
  registrantPhone: 'Phone',
  registrantEmailAddress: 'Email Address',
};

type EnomKeySubset = Pick<
  Enom,
  | 'registeryCreateDate'
  | 'registryExpDate'
  | 'orderStatus'
  // | 'totalCharged'
  | 'orderDesription'
  | 'autoRenew'
>;

const enomKeyNamesToDisplay: { [K in keyof EnomKeySubset]: string } = {
  // orderId: 'Order Id',
  registeryCreateDate: 'Purchased Date',
  registryExpDate: 'Renewal Date',
  orderStatus: 'Order Status',
  // totalCharged: 'Total Charged',
  orderDesription: 'Order Description',
  // errCount: 'Error Count',
  // errors: 'Errors',
  // rrpcode: 'Rrp Code',
  // rrpText: 'Rrp Text',
  // requestDateAndTime: 'Request Date And Time',
  // trackingKey: 'Tracking Key',
};

const enomKeyDisplayArray = Object.keys(enomKeyNamesToDisplay) as (keyof EnomKeySubset)[];

function humanReadableDate(timeStamp: number | string) {
  const dateFormat = 'yyyy-MM-dd HH:mm:ss.SSS';
  if (!timeStamp) return '';
  if (typeof timeStamp === 'number') {
    const humanReadableDate = format(timeStamp * 1000, 'MMMM do, yyyy h:mm:ss a');
    return humanReadableDate;
  } else {
    const parsedDate = parse(timeStamp, dateFormat, new Date());
    const humanReadableDate = format(parsedDate, 'MMMM do, yyyy h:mm:ss a');
    return humanReadableDate;
  }
}

function getEnomValue(typedKey: keyof EnomKeySubset, domainPurchase: DomainPurchase) {
  const enom = domainPurchase.enom;
  if (typedKey === 'registeryCreateDate' || typedKey === 'registryExpDate') {
    if (typedKey === 'registryExpDate') {
      if (domainPurchase.enom.autoRenew === false) {
        return 'Auto Renewal Cancelled';
      }
    }
    return humanReadableDate(enom[typedKey]);
  } else {
    return enom[typedKey];
  }
}

function extractRegistrantDetails(metaData?: DomainPurchase['metadata']): RegistrantDetails | {} {
  if(!metaData) return {};
  const metaDataKeys = Object.keys(metaData);

  let registrantDetails: any = {};

  for (let i = 0; i < metaDataKeys.length; i++) {
    const key = metaDataKeys[i];
    if (metaDataKeys[i].includes('registrant')) {
      registrantDetails[key] = metaData[key as keyof DomainPurchase['metadata']];
    }
  }
  return registrantDetails;
}

export async function cancelPremiumAndDomainSubscriptionController(domainName: string) {
  return await serverApi.domain.get('/cancel-premium-and-domain-subscription', {
    params: {
      domainName,
    },
  });
}

const DomainManagment = () => {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { isError, isLoading, data, isSuccess } = useGetDomainDetailsByIdQuery(
    cachedLoggedInUser?._id || '',
    { skip: !cachedLoggedInUser?._id }
  );

  async function handleCancelDomainAndFrontsSubscription(domainName: string) {
    console.log('Cancel subscription clicked - ', domainName);
    const subscription = await cancelPremiumAndDomainSubscriptionController(domainName);
    console.log('Subscription cancelled - ', subscription);
  }

  return (
    <Box position={'relative'}>
      {isError && (
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            gap: 2,
          }}
        >
          <Typography variant='h5'>No domain found</Typography>
          <Link to={'/domains'}>
            <Button variant='contained'>Add a domain</Button>
          </Link>
        </Box>
      )}
      <SectionLoader isLoading={isLoading} />
      {isSuccess && (
        <Stack gap={4}>
          {/* <GetGoogleWorkspace /> */}
          {data.map((eachDomain) => {
            let registrantDetails =
              eachDomain.registrantDetails ||
              extractRegistrantDetails(eachDomain.metadata) || {};
            console.log(registrantDetails)
            return (
              <React.Fragment key={eachDomain._id}>
                <Paper elevation={1} sx={{ p: 2 }}>
                  <Typography
                    variant='h4'
                    textAlign='center'
                    sx={{ '&:hover': { textDecoration: 'underline' } }}
                  >
                    <Link to={`https://${eachDomain.domainName}`}>
                      {eachDomain.domainName}
                    </Link>
                  </Typography>
                  {eachDomain.isEmailVerified === false && (
                    <Alert severity='warning' variant='outlined'>
                      Important! After purchasing you will receive an email from
                      donotreply@name-services.com with the subject IMMEDIATE VERIFICATION
                      required for your domain name registration.
                      <br />
                      You must click on the “Click here to verify your email address” You
                      have 15 days to verify your email or the domain will be suspended.
                    </Alert>
                  )}
                  <Box mt={2}>
                    {Object.keys(eachDomain.enom).map((key) => {
                      const typedKey = key as keyof EnomKeySubset;
                      if (!enomKeyDisplayArray.includes(typedKey)) {
                        return null;
                      }
                      return (
                        <Stack
                          key={typedKey}
                          direction='row'
                          flexWrap={'wrap'}
                          gap={2}
                          sx={{
                            px: 1,
                            py: 1,
                            '&:nth-child(odd)': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                          }}
                        >
                          <Typography
                            width={150}
                            variant='body1'
                            textTransform={'capitalize'}
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {enomKeyNamesToDisplay[typedKey]}
                          </Typography>
                          <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                            {getEnomValue(typedKey, eachDomain) || 'N/A'}
                          </Typography>
                        </Stack>
                      );
                    })}
                    <DomainEmail domain={eachDomain} />
                  </Box>

                  <Typography variant='h6' mt={4}>
                    Registrant Details
                  </Typography>
                  <Box mt={2}>
                    {registrantDetails && Object.keys(registrantDetails).map((key) => {
                      const typedKey = key as keyof RegistrantDetails;
                      return (
                        <Stack
                          key={typedKey}
                          direction='row'
                          flexWrap={'wrap'}
                          gap={2}
                          sx={{
                            px: 1,
                            py: 1,
                            '&:nth-child(odd)': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                          }}
                        >
                          <Typography
                            width={150}
                            variant='body1'
                            textTransform={'capitalize'}
                            sx={{ wordBreak: 'break-word' }}
                          >
                            {registrantKeyNamesToDisplay[typedKey]}
                          </Typography>
                          <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
                            {registrantDetails[typedKey]}
                          </Typography>
                        </Stack>
                      );
                    })}
                  </Box>
                  <Button
                    onClick={() =>
                      handleCancelDomainAndFrontsSubscription(eachDomain.domainName)
                    }
                    variant='contained'
                    color='primary'
                    sx={{ marginInlineStart: 'auto', mt: 2, display: 'block' }}
                  >
                    Cancel Subscription
                  </Button>
                </Paper>{' '}
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </Box>
  );
};

export default DomainManagment;

function DomainEmail({ domain }: { domain: DomainPurchase }) {
  const dnsRecordContent = domain?.cloudflare?.dnsRecord?.content;
  const [input, setInput] = useState('');
  const doesGoogleWorkspaceExist = domain.googleWorkspace;
  const email = domain.googleWorkspace?.email
    ? domain.googleWorkspace.email
    : domain.email;

  async function handleAddDNSClick() {
    const toastId = toast.loading('Adding DNS record...');
    try {
      const res = await serverApi.domain.get('/add-dns-record', {
        params: {
          domainName: domain.domainName,
          content: input,
        },
      });
      toast.dismiss(toastId);
      toast.success('DNS record added successfully');
      console.log(res.data);
    } catch (err) {
      toast.error('Error adding DNS, Please try after some time');
      toast.dismiss(toastId);
      console.error('Error', err);
    }
  }

  useEffect(() => {
    if (dnsRecordContent) {
      setInput(dnsRecordContent);
    }
  }, [dnsRecordContent]);

  return (
    <>
      <Stack
        direction='row'
        flexWrap={'wrap'}
        gap={2}
        sx={{
          px: 1,
          py: 1,
          '&:nth-child(odd)': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Typography
          width={150}
          variant='body1'
          textTransform={'capitalize'}
          sx={{ wordBreak: 'break-word' }}
        >
          {doesGoogleWorkspaceExist ? 'Google Workspace Email' : 'Email'}
        </Typography>
        <Typography variant='body1' sx={{ wordBreak: 'break-word' }}>
          {email}
        </Typography>
      </Stack>
      <Stack>
        <Stack direction='row' flexWrap={'wrap'} gap={2} alignItems={'center'}>
          <TextFieldSyncWithTheme
            onChange={(e) => setInput(e.target.value)}
            placeholder='Paste TXT here...'
            value={input}
            sx={{
              flexGrow: 1,
              '& .MuiInputBase-root': {
                pr: 0,
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Button variant='outlined' color='primary' onClick={handleAddDNSClick}>
                    {!!dnsRecordContent ? 'Edit' : 'Add'} TXT
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Box
          sx={{
            marginInline: 'auto',
          }}
          onClick={() => {
            if (routeConstants.EDITOR_ROUTE) {
              window.open(
                `${routeConstants.EDITOR_ROUTE}/domain-details/${domain.domainName}`,
                '_self'
              );
              window.open('https://referworkspace.app.goo.gl/r4js', '_blank');
            }
          }}
        >
          <MUILink
            underline='always'
            variant='inherit'
            sx={{
              display: 'inline-block',
              maxWidth: 'fit-content',
              mt: 0,
              cursor: 'pointer',
              textDecorationColor: (theme) => theme.palette.info.light,
              color: (theme) => theme.palette.info.light,
              '&:hover': {
                color: (theme) => theme.palette.info.dark,
                textDecorationColor: (theme) => theme.palette.info.dark,
              },
            }}
          >
            Click on this link to open Google Workspace in a new tab and use these steps
            as a guide
          </MUILink>
        </Box>
      </Stack>
    </>
  );
}

const googleWorkspaceContent = {
  headline: 'Enhance Your Domain with Google Workspace!',
  list: [
    '- Professional business email address at your domain',
    '- 30 GB of secure cloud storage per user',
    '- Shared calendars to keep your team in sync',
    '- Video conferencing with Google Meet',
    '- Real-time collaboration with Google Docs, Sheets, and Slides',
    '- 24/7 customer support',
  ],
};

function GetGoogleWorkspace() {
  return (
    <Paper
      elevation={1}
      sx={{ p: 2, border: (theme) => `1px solid ${theme.palette.info.light}` }}
    >
      <Box>
        <Typography variant='h6'>{googleWorkspaceContent.headline}</Typography>
        <List>
          {googleWorkspaceContent.list.map((item, index) => {
            return (
              <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant='body1'>{item}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
}
