import React from 'react';
import { useGetMeetingsByUserIdQuery } from 'store/rtkqFeatures/api/meetingExtendedApi';

type MeetingsLowPriorityContextProps = {
  editorId: string;
  meetingId: string;
};

// NOTE: will be displayed below meeting action btn
const MeetingsLowPriorityContext: React.FunctionComponent<
  MeetingsLowPriorityContextProps
> = ({ meetingId }) => {
  const { thisMeeting } = useGetMeetingsByUserIdQuery(meetingId, {
    selectFromResult: ({ data }) => ({
      thisMeeting: data?.find(({ _id }) => _id === meetingId),
    }),
  });

  if (!thisMeeting) return <></>;

  return <></>;
};

export default MeetingsLowPriorityContext;
