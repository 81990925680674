import { Button, Stack, SxProps, Typography, useMediaQuery } from '@mui/material';
import useClipbord from 'hooks/useClipbord';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { dashboard_icon_size } from 'views/Dashboard/Dashboard';

type ClipboardCopyStyledBoxProps = {
  copyText: string;
  labelText: string;
  elementToShow?: string | React.ReactNode;
  onCopySuccess?: () => void;
  sx?: SxProps;
};

const ClipboardCopyStyledBox = ({
  labelText,
  copyText,
  onCopySuccess,
  elementToShow,
  sx,
}: ClipboardCopyStyledBoxProps) => {
  const { setClipboard } = useClipbord();
  const isPhone = useMediaQuery('(max-width:600px)');

  const handleCopyIconClick = () => {
    setClipboard(copyText, () => {
      if (onCopySuccess) {
        onCopySuccess?.();
        return;
      }
      toast.success(`${labelText} copied!`);
    });
  };
  return (
    <Stack spacing={0} sx={sx}>
      <Typography variant={'body2'} component={'i'} fontWeight={600}>
        {labelText}
      </Typography>
      <Typography variant={'body2'} sx={{ wordBreak: 'break-word' }}>
        {elementToShow ? elementToShow : copyText}
        <Button color='info' size={isPhone ? 'small': 'medium'} variant='text' onClick={handleCopyIconClick}>
          <ContentCopyIcon sx={dashboard_icon_size} />
        </Button>
      </Typography>
    </Stack>
  );
};

export default ClipboardCopyStyledBox;
