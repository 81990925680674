import { Box, Divider, Skeleton, Stack } from '@mui/material';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import DomainCardOnboarding from 'components/Domain/DomainCardOnboarding';
import DomainSearchInput from 'components/Domain/DomainSearchInput';
import { useFormikContext } from 'formik';
import useAsyncStatus from 'hooks/useAsyncStatus';
import React, { useEffect, useRef } from 'react';
import { useLazyGetDomainNameSuggestionsQuery } from 'store/rtkqFeatures/api/domainExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { validateDomainName } from 'views/Domains/components/DomainSearchHeroSection';
import { LoggedInUser } from 'types/user';

import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

function getValidSearchTerm(cachedLoggedInUser: LoggedInUser): string {
  const { name, email } = cachedLoggedInUser;
  if (name && name.trim().length > 0) {
    return name.toLowerCase().replaceAll(' ', '');
  } else {
    return email.split('@')[0].toLowerCase().replaceAll('-', '');
  }
}

const SearchedDomainOnboarding = () => {
  const { setFieldValue, values } = useFormikContext<StepperState>();
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const [value, setValue] = React.useState('');
  const { Actions, updateStatus, isError, error } = useAsyncStatus();
  const { updateStatus: updateSearchLoadingStatus, isLoading: isSearchDomainLoading } =
    useAsyncStatus();
  const [trigger, { data, isLoading }] = useLazyGetDomainNameSuggestionsQuery();
  const ref = useRef<{ isPrepopulated: boolean }>(null);
  const isSearchLoading = isLoading || isSearchDomainLoading;

  // Initialize the value state here
  useEffect(() => {
    if (cachedLoggedInUser) {
      setValue(getValidSearchTerm(cachedLoggedInUser));
    }
  }, [cachedLoggedInUser]);

  useEffect(() => {
    if (ref.current?.isPrepopulated) return;
    if (cachedLoggedInUser) {
      trigger(
        {
          searchTerm: getValidSearchTerm(cachedLoggedInUser),
          tldList: 'com',
          maxResult: 5,
          adult: false,
          premium: true,
          allGA: true,
        },
        true
      );
      if (ref.current) {
        ref.current.isPrepopulated = true;
      }
    }
  }, [cachedLoggedInUser, trigger]);

  const sortedData = React.useMemo(() => {
    if (!data || !values.pendingDomain) return data;

    // Sort the data array to place the pending domain at the first index
    return data.slice().sort((a, b) => {
      if (a.name === values.pendingDomain) return -1;
      if (b.name === values.pendingDomain) return 1;
      return 0;
    });
  }, [data, values.pendingDomain]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    
    input = input.toLowerCase();
  
    // Remove any special characters except letters, numbers, dots, hyphens, and spaces
    input = input.replace(/[^a-z0-9.\-\s]/g, '');
    setValue(input);
    validateDomainName(input, updateStatus, Actions);
  };

  const handleSearchClick = async () => {
    updateSearchLoadingStatus({ type: Actions.LOADING });
    if (!value) return;
    if (cachedLoggedInUser) {
      try {
        await trigger(
          {
            searchTerm: value.replaceAll(' ', ''),
            tldList: 'com',
            maxResult: 5,
            adult: false,
            premium: true,
            allGA: true,
          },
          true
        ).unwrap();
        updateSearchLoadingStatus({
          type: Actions.SUCCESS,
          payload: 'Successfully searched for domains',
        });
      } catch (err) {
        updateSearchLoadingStatus({
          type: Actions.ERROR,
          payload: 'Failed to search for domains',
        });
      } finally {
        updateSearchLoadingStatus({ type: Actions.RESET });
      }
    }
  };

  const handleAddToFavouriteClick = (
    domainName: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!data) return;
    const selectedDomainIndex = data.findIndex(
      (eachDomain) => eachDomain.name === domainName
    );

    if (selectedDomainIndex === -1) return;
    setFieldValue('pendingDomain', data[selectedDomainIndex].name);
  };

  const DomainLists = (): JSX.Element => {
    return (
      <>
        {sortedData?.map((eachDomain, i) => {
          const isSelectedDomain = eachDomain.name === values.pendingDomain;
          return (
            <React.Fragment key={i}>
              <Divider orientation='horizontal' sx={{ my: 0 }} flexItem />
              <DomainCardOnboarding
                handleBuyDomainClick={handleAddToFavouriteClick}
                domain={eachDomain}
                icon={isSelectedDomain ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                domainBtnText={isSelectedDomain ? 'Added' : 'Add to Favourites'}
                key={i}
                btnProps={{
                  disableElevation: isSelectedDomain ? true : false,
                  sx: {
                    pointerEvents: isSelectedDomain ? 'none' : 'auto',
                    boxShadow: isSelectedDomain
                      ? 'none'
                      : theme => `1px 1px 3px ${theme.palette.text.disabled}`,
                  },
                }}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <Box
      sx={{
        '& .domain-search-form': {
          mb: 5,
          justifyContent: 'flex-start',

          '& .domain-search-form-control': {
            justifyContent: 'flex-start',
          },
        },
      }}
    >
      <DomainSearchInput
        error={error}
        handleClick={handleSearchClick}
        onChange={handleChange}
        inputValue={value}
        isError={isError}
        isLoading={isSearchLoading}
        updateStatus={updateStatus}
        isStandaloneForm={false}
      />
      {isSearchLoading ? (
        <Stack spacing={2}>
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton
              animation='wave'
              variant='rectangular'
              width={'100%'}
              height={70}
              key={i}
            />
          ))}
        </Stack>
      ) : (
        <DomainLists />
      )}
    </Box>
  );
};

export default SearchedDomainOnboarding;
