import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { toast } from 'react-toastify';

import apiServices from 'lib/services';
import { ServiceCheckoutFormValues } from 'views/ServiceCheckout/ServiceCheckout';
import serverApi from 'lib/apiConfig';
import { LoggedInUser } from 'types/user';
import { StripeConnectedAccount } from 'types/StripeConnectedAccount';
import { PhysicalOrVirtualService } from 'types/service';
import { ThemeType } from 'themes/AppThemeProvider/themeType';

// Route View = domain/service/{serviceId}/checkout?editor-id={editorId}

const API_DATA_FETCHING_SETTINGS = {
  revalidateOnReconnect: true,
};

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 1000,
  hideProgressBar: true,
};
type ServiceWithTheme = PhysicalOrVirtualService & {
  theme: ThemeType;
}
const useServiceCheckout = () => {
  const history = useHistory();
  const { serviceId } = useParams<Record<'serviceId', string>>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editorId = searchParams.get('editor-id') ?? '';
  const { data: editor, error: fetchEditorError, isLoading: isEditorLoading } = useSWR<LoggedInUser>(
    `/user/${editorId}`,
    apiDataFetcher,
    API_DATA_FETCHING_SETTINGS
  );
  const { data: editorConnectedAcc, error: fetchConnectedAccError, isLoading: isConnectAccLoading } =
    useSWR<StripeConnectedAccount>(
      `/stripe/connected-account/user/${editorId}`,
      apiDataFetcher,
      API_DATA_FETCHING_SETTINGS
    );
  const { data: currentServiceDetails, error: fetchCurrentServiceError, isLoading: isServiceLoading } =
    useSWR<ServiceWithTheme>(
      `/services/${serviceId}`,
      apiDataFetcher,
      API_DATA_FETCHING_SETTINGS
    );

  // only for paid services
  const handleStripeServiceCheckout = async (
    customerDetails: ServiceCheckoutFormValues,
    servicePrice: number,
    isServicePaid: boolean
  ) => {
    if (!editor || !serviceId) {
      toast.error('Unable to checkout service!', TOAST_MESSAGE_SETTINGS);
      console.error(
        'Unable to checkout service because either serviceId or loggedInUser not found!!: ',
        {
          editor,
          serviceId,
        }
      );
      return;
    }
    if (!servicePrice && isServicePaid) {
      toast.error('Unable to checkout service!', TOAST_MESSAGE_SETTINGS);
      console.error(
        'Unable to because paid service price is invalid, servicePrice: ',
        servicePrice
      );
      return;
    }
    try {
      await apiServices.serviceServices.serviceCheckout(serviceId, customerDetails, {
        onSuccess: (checkoutSessionUrl) => {
          isServicePaid && window.location.replace(checkoutSessionUrl);
        },
      });
    } catch (error) {
      throw error;
    }
  };
  return {
    errors: {
      fetchCurrentServiceError,
      fetchConnectedAccError,
      fetchEditorError,
    },
    loading: {
      isEditorLoading,
      isConnectAccLoading,
      isServiceLoading,
    },
    editor,
    currentServiceDetails,
    editorConnectedAcc,
    history,
    serviceId,
    handleStripeServiceCheckout,
  };
};

export default useServiceCheckout;

// utils
async function apiDataFetcher(handle: string) {
  // console.log('publicAPIClient baseURL path: ', serverApi.public.defaults.baseURL);
  const res = await serverApi.public.get(handle);
  return res.data;
}
