import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

const SUCCESS_ICON_URL = `https://res.cloudinary.com/salesfronts/image/upload/v1675523723/success-svg_ew7l0h.svg`;
export const successURL_key = 'success_url';

const PremiumThemeSubscriptionSuccessPage = () => {
  const history = useHistory();
  
  const handleBackToHome = () => {
    const success_URL = localStorage.getItem(successURL_key);
    if(success_URL) {      
      localStorage.removeItem(successURL_key)
      history.push(success_URL);
    } else {
      history.push('/');
    }
  };

  return (
    <Stack
      sx={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#fef5ff',
      }}
    >
      {/* --------------- card ----------------- */}
      <Stack spacing={3} justifyContent='center' alignItems='center' component={Paper} elevation={2} p={3}>
        {/* ------------------- success icon -------------------------- */}
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Box
            component='img'
            src={SUCCESS_ICON_URL}
            sx={{
              width: 60,
            }}
          />
        </Stack>
        {/* ------------------- thanks msg -------------------------- */}
        <Typography
          variant='h4'
          sx={{
            textAlign: 'center',
            maxWidth: '33ch',
            fontWeight: 700,
          }}
        >
          Thank you for subscribing
          <br />
          You are now a premium user.
        </Typography>
        <Button
          variant='contained'
          onClick={handleBackToHome}
          color='primary'
          sx={{
            fontSize: '1.6rem',
            maxWidth: 'fit-content',
            display: 'block',
            marginX: 'auto',            
          }}
        >
          Back To website
        </Button>
      </Stack>
    </Stack>
  );
};

export default PremiumThemeSubscriptionSuccessPage;
