import React, { useRef, useState } from 'react';
import {
  Box,
  Avatar,
  Stack,
  Typography,
  IconButton,
  Paper,
  useTheme,
} from '@mui/material';
import { useField } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';

import EUDFormValidationErrorMsg from 'components/EditUserDetailsLatest/utility-components/EUDFormValidationErrorMsg';
import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import IfElse from 'components/utilities/IfElse';
import { DEFAULT_WEBSITE_ICON } from 'utilities/fetchFavicon';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import Popup from 'components/utilities/Popups/Popup';
import SocialLinkInput from 'components/utilities/SocialLinkInput/SocialLinkInput';
import { SocialContextProviderRef } from 'components/utilities/SocialLinkInput/SocialContextProvider';

type LinkInputState = {
  error: string;
  link: string;
};

const initialLinkInputState: LinkInputState = {
  error: '',
  link: '',
};

const EUDSocialLinks: React.FC = () => {
  const theme = useTheme();
  const socialContextRef = useRef<SocialContextProviderRef>(null);
  const [open, setOpen] = useState(false);
  const [field, , fieldHelpers] = useField('socialLinks');
  const socialLinks = (field.value as EditUserDetailsForm['socialLinks']) || [];
  const isValidSocialLinksListLength = Array.isArray(socialLinks) && socialLinks.length > 0;
  const [linkInputState, setLinkInputState] = useState<LinkInputState>(initialLinkInputState);
  const isError = Boolean(linkInputState.error);

  const handleDeleteLinkBtnClick = (deletedLinkText?: string) => {
    if (!deletedLinkText) return;
    const linksWithRemovedLink = socialLinks.filter(link => (link.link || link.url) !== deletedLinkText);
    fieldHelpers.setValue(linksWithRemovedLink);
    fieldHelpers.setTouched(true);
  };

  const handleImgError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log('error found while setting social icon img');
    e.currentTarget.src = DEFAULT_WEBSITE_ICON;
  };

  const handleFinalSubmissionFromChild = () => {
    if (socialContextRef.current) {
      const socialLinks = socialContextRef.current.onFinalSubmission();
      console.log(socialLinks);
      fieldHelpers.setValue(socialLinks);
      fieldHelpers.setTouched(true);
    }
  };

  const handleOpenPopup = () => setOpen(true);
  const handleCancel = () => setOpen(false);
  const handleSave = () => {
    handleFinalSubmissionFromChild();
    setOpen(false);
  };

  return (
    <Box>
      <Box position='relative'>
        <Popup
          dialogProps={{
            open,
            onClose: () => {},
            fullWidth: false,
            maxWidth: 'sm',
            sx: { '& .MuiPaper-root': { bgcolor: theme.palette.grey[100] } },
          }}
          title='Edit social links'
          showCloseIcon={false}
          setOpen={setOpen}
        >
          <Box sx={{ pt: 0, pb: 2, px: 2.5 }}>
            <SocialLinkInput socialLinks={socialLinks} ref={socialContextRef} />
            <Stack
              mt={2}
              spacing={3}
              justifyContent='flex-end'
              alignItems='center'
              direction='row'
            >
              <ResponsiveButton
                size='small'
                variant='text'
                color='secondary'
                onClick={handleCancel}
              >
                Cancel
              </ResponsiveButton>
              <ResponsiveButton
                size='small'
                variant='contained'
                color='primary'
                onClick={handleSave}
              >
                Save
              </ResponsiveButton>
            </Stack>
          </Box>
        </Popup>
        <ResponsiveButton 
          variant='outlined' 
          onClick={handleOpenPopup}
          startIcon={<EditIcon />}
        >
          Edit Social Links
        </ResponsiveButton>
        <EUDFormValidationErrorMsg isVisible={isError} errorMsg={linkInputState.error} />
      </Box>
      <IfElse condition={isValidSocialLinksListLength}>
        <Paper
          variant='outlined'
          sx={{
            my: 2,
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
          }}
        >
          {socialLinks.map((socialLink, index) => {
            const showSocialLink = socialLink.link || socialLink.url;
            const showSocialLinkIconURL = socialLink.iconUrl || DEFAULT_WEBSITE_ICON;

            return (
              <Stack
                key={index}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  py: 1.5,
                  px: 2,
                  bgcolor: index % 2 === 0 ? theme.palette.grey[50] : theme.palette.common.white,
                }}
              >
                <Stack direction='row' alignItems='center' spacing={2} flexGrow={1}>
                  <Avatar
                    variant='square'
                    src={showSocialLinkIconURL}
                    imgProps={{ onError: handleImgError }}
                    sx={{
                      width: 24,
                      height: 24,
                      '& .MuiAvatar-img': { objectFit: 'contain' },
                    }}
                  />
                  <Typography
                    variant='body2'
                    sx={{
                      maxWidth: { xs: '180px', sm: '250px', md: '350px' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {showSocialLink}
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={1}>
                  <IconButton
                    size="small"
                    onClick={() => window.open(showSocialLink, '_blank')}
                  >
                    <OpenInNewIcon fontSize='small' />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteLinkBtnClick(showSocialLink)}
                    sx={{ color: theme.palette.error.main }}
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Stack>
              </Stack>
            );
          })}
        </Paper>
      </IfElse>
    </Box>
  );
};

export default EUDSocialLinks;