import serverApi from 'lib/apiConfig';
import authServices from 'lib/services/authServices';
import loginTokenUtils from 'utilities/loginTokenUtils';

type ValidateEmailMagicLinkTokenParams = {
  loginLinktoken: string;
};

type ValidateEmailMagicLinkTokenAPIData = {
  editorId: string;
  loginToken: string;
  url: string;
  email: string;
};

type ValidateEmailMagicLinkTokenReturnType = Promise<
  Omit<ValidateEmailMagicLinkTokenAPIData, 'loginToken'>
>;

/**
 * Verifies magic login link token and sets login token to local stoage.
 * After setting token,reauthenticate the user with the same set token.
 * If some error then it throws error.
 */
async function validateEmailMagicLinkToken({
  loginLinktoken,
}: ValidateEmailMagicLinkTokenParams): ValidateEmailMagicLinkTokenReturnType {
  try {
    const { data: responseData } =
      await serverApi.v1.post<ValidateEmailMagicLinkTokenAPIData>(
        '/auth/login/email/verify-login-token',
        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${loginLinktoken}`,
            'Content-Type': 'application/json',
          },
        }
      );
    // setting the login jwt token in local storage
    loginTokenUtils.setLocalStorageLoginToken(responseData.loginToken);
    const loginTokenInLocalStorage = loginTokenUtils.getLocalStorageLoginToken();
    // authenticating the user with the set login token from local storage.
    if (!loginTokenInLocalStorage) {
      const errorMsg = 'loginToken not found';
      const isNodeEnvProduction = process.env.NODE_ENV === 'production';
      !isNodeEnvProduction && console.error('loginToken not found');
      throw new Error(errorMsg);
    }
    await authServices.authenticateUser({
      loginToken: loginTokenInLocalStorage,
    });
    return responseData;
  } catch (error) {
    console.error('error while validating magic login link token');
    throw error;
  }
}

export default validateEmailMagicLinkToken;
