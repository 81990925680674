import { Box, CircularProgress, SxProps, useMediaQuery } from '@mui/material';

const SectionLoader = ({ isLoading, sx }: { isLoading: boolean; sx?: SxProps }) => {
  const isPhone = useMediaQuery('(max-width: 600px)');

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            height: '100%',
            display: 'grid',
            placeItems: 'center',
            backgroundColor: '#ffffff88',
            zIndex: 1,
            ...sx,
          }}
        >
          <CircularProgress color='primary' size={isPhone ? 20 : 25} />
        </Box>
      )}
    </>
  );
};

export default SectionLoader;
