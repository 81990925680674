import React from 'react';
import { Box, Chip } from '@mui/material';
import { SPMCardProps } from 'components/MUI_SPM/SPMCard/SPMCard';

type SPMCardIncludedItemsProps = Partial<
  Pick<SPMCardProps, 'cardIncludedItems' | 'cardIncludedItemsHeading'> & {
    tagStyling: any;
    globalStyling: any;
    query: string; // only for searched results
    handleTagClick?: (e: React.MouseEvent<HTMLLIElement>) => void; // only for searched results
  }
>;

const SPMCardIncludedItems: React.FunctionComponent<SPMCardIncludedItemsProps> = ({
  cardIncludedItems = [],
  cardIncludedItemsHeading,
  tagStyling,
  globalStyling,
  query,
  handleTagClick,
}) => {
  return (
    <Box
      sx={{
        marginTop: 0,
      }}
    >     
      <Box
        component='ul'
        display='flex'
        justifyContent='flex-start'
        alignItems='center'
        flexWrap='wrap'
        columnGap={tagStyling ? tagStyling.columnGap : 1}
        rowGap={tagStyling ? tagStyling.rowGap : 1}
        sx={{ listStyleType: 'none', paddingLeft: 0 }}
      >
        {cardIncludedItems.map((skillName, i) => (
          <Box
            component='li'
            key={i}
            id={skillName}
            onClick={handleTagClick ? handleTagClick : () => {}}
          >
            <Chip
              label={skillName}
              sx={
                tagStyling
                  ? {
                      fontSize: tagStyling.fontSize,
                      padding: tagStyling.padding,
                      background: tagStyling.background,
                      borderRadius: tagStyling.borderRadius,
                      border: tagStyling.border,
                      boxShadow: tagStyling.boxShadow,
                      color: tagStyling.color,
                    }
                  : {
                      fontSize: '16px',
                      padding: '6px',
                      backgroundColor:
                        query?.toLowerCase() === skillName.toLowerCase()
                          ? 'yellow'
                          : '#fff',
                      cursor: query ? 'pointer' : 'unset',
                      color: '#000',
                      borderRadius: '4px',
                      border: '0 solid #fff',
                      boxShadow: 'inset -2px 2-x 5px #939393',
                      rowGap: '8px',
                      columnGap: '8px',
                    }
              }
              size='medium'
              variant='filled'
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SPMCardIncludedItems;
