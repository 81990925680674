import React from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { IconButton, Link } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import useHandleImgOnError from 'hooks/useHandleImgOnError';
import { EmailLinkedUserAccounts } from 'store/rtkqFeatures/api/linkedAccountsExtendedApi';
import routeConstants from 'constants/routeConstants';
import serverApi from 'lib/apiConfig';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { resetLoggedinUserSlice, setLoggedInUser } from 'store/features/user/userSlice';
import { useAppDispatch } from 'hooks/redux';
import { useStyledTheme } from 'components/ShareComponents/StyledTheme';
import { saveRouteToSessionStorage } from 'views/Home/SignIn';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import LoaderWithBgBlur from 'components/utilities/Loaders/LoaderWithBgBlur';
import ConfirmationDialog from 'Popups/ConfirmationDialog';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

type ThisProps = {
  setUserLoginStatus: React.Dispatch<React.SetStateAction<'idle' | 'loading'>>;
  isUserLoginStatusLoading: boolean;
  account: EmailLinkedUserAccounts['linkedAccounts'][0];
  handleUserAccountClick: ({
    email,
    userId,
    userURL,
  }: Record<'email' | 'userId' | 'userURL', string>) => Promise<void>;
};

const AccountLists = ({
  isUserLoginStatusLoading,
  account,
  handleUserAccountClick,
  setUserLoginStatus,
}: ThisProps) => {
  const styledTheme = useStyledTheme();
  const { handleImageError, showImage } = useHandleImgOnError();
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [triggerUserDelete, { isLoading }] = useDeleteEditorByIdMutation();
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const hasOnboardingIncomplete = !!!account.url;
  const url = account.domainName ? account.domainName : account.url;
  const accountFullURL = account.domainName
    ? `https://${account.domainName}`
    : `${routeConstants.LIVE_ROUTE}/${account.url}`;
  const dispatch = useAppDispatch();
  const history = useHistory();

  const sendToOnboarding = async (userId: string) => {
    setUserLoginStatus('loading');

    try {
      const response = await serverApi.basicWithCredentials.get(
        `/auth/generate-login-token`,
        {
          params: {
            userId,
          },
        }
      );
      const { token, user } = response.data;

      // save it here to local storage
      loginTokenUtils.setLocalStorageLoginToken(token);
      dispatch(setLoggedInUser(user));
      saveRouteToSessionStorage(history);
      history.push(routeConstants.ONBOARDING_ROUTE);
    } catch (error) {
      console.error('Error while generating token', error);
    } finally {
      setUserLoginStatus('idle');
    }
  };

  const handleDeleteBtn = () => {
    triggerUserDelete(account._id);
    if (cachedLoggedInUser?._id === account._id) {
      loginTokenUtils.removeLocalStorageLoginToken();
      dispatch(resetLoggedinUserSlice());
      history.push(routeConstants.HOME_ROUTE);
    }
  };

  return (
    <Box key={account._id}>
      {isLoading && <LoaderWithBgBlur />}
      <ListItem
        disableGutters
        disablePadding
        alignItems='flex-start'
        sx={{
          flexDirection: 'row',
        }}
      >
        <ListItemButton
          disableGutters
          sx={{
            width: 'inherit',
            paddingY: 2,
            paddingX: 3,
            alignItems: 'flex-start',
            '&:hover': {
              outline: styledTheme.outlineHover,
              bgcolor: 'rgba(0, 0, 0, 0.04)',
            },
            '&:focus': {
              outline: styledTheme.outlineSelected,
              boxShadow: styledTheme.boxShadowSelected,
            },
          }}
          disabled={isUserLoginStatusLoading}
          onClick={() => {
            if (hasOnboardingIncomplete) {
              sendToOnboarding(account._id);
            } else {
              handleUserAccountClick({
                email: account.email,
                userId: account._id,
                userURL: account.url || '',
              });
            }
          }}
        >
          <ListItemAvatar
            sx={{
              opacity: hasOnboardingIncomplete ? 0.5 : 1,
            }}
          >
            {showImage && !!account.img ? (
              <Avatar
                alt={account.name}
                src={account.img}
                sx={{
                  width: 56,
                  height: 56,
                  fontSize: '2rem',
                  textTransform: 'uppercase',
                  marginRight: '16px',
                }}
                imgProps={{ onError: handleImageError }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 56,
                  height: 56,
                  bgcolor: getRandomColor(),
                  fontSize: '2rem',
                  textTransform: 'uppercase',
                  marginRight: '16px',
                }}
              ></Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            sx={{
              my: 0,
            }}
            primary={
              <Typography
                variant='subtitle1'
                textAlign='left'
                fontWeight={500}
                sx={{
                  mb: 0,
                  lineHeight: '1.3',
                  opacity: hasOnboardingIncomplete ? 0.5 : 1,
                }}
              >
                {account.name}
              </Typography>
            }
            secondary={
              <>
                <Typography
                  component='span'
                  variant='body2'
                  fontWeight={400}
                  fontSize={'1.6rem'}
                  sx={{
                    display: 'block',
                    mb: 0,
                    color: (theme) =>
                      hasOnboardingIncomplete
                        ? theme.palette.error.main
                        : theme.typography.body2.color,
                  }}
                >
                  {hasOnboardingIncomplete
                    ? 'This account has not been completed yet. Click to complete.'
                    : account.title}
                </Typography>
                <Stack spacing={1.5} component='span'>
                  {!hasOnboardingIncomplete && (
                    <Stack
                      component='span'
                      direction='row'
                      alignItems='flex-start'
                      spacing={1}
                      flexWrap='nowrap'
                    >
                      <Typography component='span' fontSize={'1.4rem'} color={grey[600]}>
                        {url}
                      </Typography>
                      <Link
                        onClick={(e) => e.stopPropagation()}
                        underline='hover'
                        href={accountFullURL}
                        target='_blank'
                        sx={{ '&:hover': { color: '#0b3f8d' } }}
                      >
                        <OpenInNewIcon
                          sx={{
                            fontSize: '1.6rem',
                            verticalAlign: 'middle',
                          }}
                        />
                      </Link>
                    </Stack>
                  )}
                </Stack>
              </>
            }
          />
        </ListItemButton>
        {hasOnboardingIncomplete && (
          <>
            <IconButton
              onClick={() => setConfirmDelete(true)}
              className='delete-btn'
              disabled={isLoading}
              sx={{
                alignSelf: 'center',
                height: 'auto',
              }}
            >
              <DeleteIcon sx={{ color: 'error.main' }} />
            </IconButton>
          </>
        )}
      </ListItem>

      <ConfirmationDialog
        open={confirmDelete}
        handleAgreeBtnClick={handleDeleteBtn}
        title={'Delete this'}
        content={'Are you sure want to delete this account'}
        isLoading={isLoading}
        handleDialogClose={() => setConfirmDelete(false)}
      />
    </Box>
  );
};

export default AccountLists;

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 8)];
  }
  return color;
}
