import { SxProps, Typography } from '@mui/material';

const FooterCopyright = ({ sx }: { sx: SxProps }) => {
  return (
    <Typography
      bgcolor={'secondary.main'}
      color={'common.white'}
      textAlign={'center'}
      borderTop={'1px solid'}
      py={1}
      sx={{ ...sx, fontSize: { xs: '1.3rem', md: '1.4rem' } }}
    >
      © {new Date().getFullYear()}, ProcessUs Solutions Inc.
    </Typography>
  );
};

export default FooterCopyright;
