import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

type SPMDeletionConfirmationDialogProps = {
  open: boolean;
  children: React.ReactNode;
};

const SPMDeletionConfirmationDialog: React.FunctionComponent<
  SPMDeletionConfirmationDialogProps
> = ({ open, children }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby='spm deletion confirmation dialog'
    >
      <DialogTitle
        sx={{
          color: 'common.black',
          fontSize: {
            xs: '1.9rem',
            sm: '2.4rem',
          },
          fontWeight: 700,
        }}
      >
        {'Are you sure about deleting this store item?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-slide-description' fontSize='1.6rem'>
          Deleting it will permanently remove it from the store.
        </DialogContentText>
      </DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
};

export default SPMDeletionConfirmationDialog;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});
