import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MouseEvent } from 'react';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'usehooks-ts';
import { useMediaQuery } from '@mui/material';

import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import SocialIconsList from 'components/utilities/SocialIconsList';
import BootstrapLoader from 'components/utilities/Loaders/BootstrapLoader';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';
import IfElse from 'components/utilities/IfElse';
import socialIcons from 'views/EditUserDetails/sub-components/SocialIcons';
import FooterCopyright from './FooterCopyright';

import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import useHandleImgOnError from 'hooks/useHandleImgOnError';
import { useAppSelector } from 'hooks/redux';
import LayoutContainer from 'components/Containers/LayoutContainer';

const Footer = () => {
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { handleImageError, showImage } = useHandleImgOnError();
  const [_, copyFn] = useCopyToClipboard();
  const isPhone = useMediaQuery('(max-width:600px)');
  const { data: themes } = useGetThemesQuery(undefined);
  const currentTheme = themes && themes[localCurrentThemeIndex];
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: userMetaData } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  }); //  user from cache

  if (!currentTheme?.navbar || !userMetaData) return <BootstrapLoader />;

  const isDirectionRtl = userMetaData.languageDir === 'rtl';
  const { img, email, emailPublic, phone, address, socialLinks, whatsapp, name } =
    userMetaData;
  const { linkColor, navbar: navbarStyles, footer } = currentTheme;

  const footerBg = footer?.background || navbarStyles.background;
  const footerColor = footer?.color || navbarStyles.color;
  const footerBackgroundBlend = footer?.backgroundBlendMode;
  const footerBackgroundSize = footer?.backgroundSize;
  const spacing = { xs: 1.5, md: 2 };

  const handleClick = async (e: MouseEvent<HTMLParagraphElement>) => {
    if (email) {
      e.preventDefault();
      await copyFn(email);
      toast.success('Copied to clipboard', { autoClose: 1000, position: 'top-center' });
    }
  };

  return (
    <>
      <Grid
        className='footer'
        item
        component='footer'
        position='relative'
        boxShadow='0px -2px 5px 0 rgb(0 0 0 / 20%)'
        sx={{
          background: footerBg,
          color: footerColor,
          backgroundSize: footerBackgroundSize,
          backgroundBlendMode: footerBackgroundBlend,
          pt: 6.25,
          pb: 8,
          '& *': {
            direction: isDirectionRtl ? 'rtl' : 'ltr',
          },
        }}
      >
        <LayoutContainer>
          <Grid
            container
            spacing={{ xs: 0, md: 2 }}
            justifyContent={{ xs: 'left', md: 'flex-start' }}
            alignItems={{ xs: 'flex-end', md: 'flex-end' }}
          >
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                // textAlign: { xs: 'left', md: 'left' },
                alignSelf: 'flex-start',
              }}
            >
              {showImage && !!img && (
                <Box
                  margin={{ xs: '0', md: 0 }}
                  borderRadius={1}
                  width={{ xs: 130, md: 150 }}
                  sx={{
                    maxWidth: { xs: 130, md: 150 },
                    maxHeight: '100%',
                    overflow: 'hidden',
                    '& img': {
                      maxWidth: { xs: 100, md: 130 },
                      objectFit: 'contain',
                      borderRadius: '4px',
                      height: 'auto',
                    },
                  }}
                >
                  <img src={img} alt='footer logo' onError={handleImageError} />
                </Box>
              )}
              <Typography
                variant='h6'
                display='inline-block'
                marginTop={1}
                fontWeight={600}
                color={footerColor}
                sx={{ wordBreak: 'break-word' }}
              >
                {name}
              </Typography>
            </Grid>
            {!isPhone && <Grid item md={2}></Grid>}
            <Grid item md={6} xs={12} sx={{ maxWidth: { xs: '100%', md: '100%' } }}>
              <Stack
                flexWrap='wrap'
                direction={{ xs: 'column', md: 'column' }}
                justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                alignItems={{ xs: 'flex-start', md: 'flex-end' }}
                spacing={spacing}
                mt={{ xs: spacing.xs, md: 0 }}
              >
                {/* address  */}
                {/* NOTE Hiding address it for now */}
                {/* {address && address.trim().length > 0 && (
                  <FooterItems
                    icon='address'
                    content={address}
                    color={footerColor}
                    linkColor={linkColor}
                    href={`https://www.google.com/maps/search/?api=1&query=${address.replaceAll(
                      ' ',
                      '+'
                    )}`}
                  />
                )} */}

                {/* phone number */}
                <IfElse condition={!!phone}>
                  <FooterItems
                    icon='phone'
                    content={phone}
                    color={footerColor}
                    linkColor={linkColor}
                    href={`tel:${phone}`}
                  />
                </IfElse>

                {/* --------------- email -------------------------------- */}
                <IfElse condition={!!emailPublic && !!email}>
                  <Stack
                    direction={{ xs: 'row', md: 'row' }}
                    columnGap={{ xs: 1, md: 1 }}
                    flexBasis='100%'
                    alignItems={{ xs: 'flex-start', md: 'flex-start' }}
                    justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
                    color={footerColor}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: 'none',
                      '& address ': { color: footerColor },
                    }}
                  >
                    <Typography
                      pt={{ xs: 0.2, md: 0 }}
                      order={{ xs: 2, md: 1 }}
                      onClick={handleClick}
                      component='p'
                      sx={{
                        fontStyle: 'normal',
                        textAlign: { xs: 'left', md: 'right' },
                        color: 'inherit',
                        '&:hover': { color: linkColor },
                        wordBreak: 'break-all',

                      }}
                    >
                      {email}
                    </Typography>
                    <Typography
                      component='a'
                      href={`mailto:${phone}`}
                      target='_blank'
                      order={{ xs: 1, md: 2 }}
                    >
                      {socialIcons(defaultSocialIconsSize)['email']}
                    </Typography>
                  </Stack>
                </IfElse>
                <SocialIconsList
                  emailPublic={emailPublic}
                  email={email}
                  whatsapp={whatsapp}
                  socialLinks={socialLinks}
                  address={address}
                  phone={phone}
                  socialIconsSize={defaultSocialIconsSize}
                  spacing={{ xs: 2, md: 2 }}
                  styling={{
                    justifyContent: { xs: 'flex-start', md: 'flex-end' },
                    '& a': { paddingBottom: 0 },
                    marginTop: 1,
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </LayoutContainer>
      </Grid>
      <FooterCopyright sx={{ background: footerBg, color: footerColor }} />
    </>
  );
};

export default Footer;

const defaultSocialIconsSize = { width: { xs: 25, md: 30 }, height: { xs: 25, md: 30 } };

type FooterItemsProps = {
  content: string;
  color: string;
  linkColor: string;
  icon: 'address' | 'phone';
  href: string;
};

function FooterItems({ color, content, href, linkColor, icon }: FooterItemsProps) {
  return (
    <Stack
      component='a'
      direction={{ xs: 'row', md: 'row' }}
      columnGap={{ xs: 1, md: 1 }}
      flexBasis='100%'
      alignItems={{ xs: 'flex-start', md: 'flex-start' }}
      justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
      target='_blank'
      href={href}
      color={color}
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
        '& address ': { color: color },
      }}
    >
      <Typography
        pt={{ xs: 0.2, md: 0 }}
        order={{ xs: 2, md: 1 }}
        component='address'
        sx={{
          fontStyle: 'normal',
          textAlign: { xs: 'left', md: 'right' },
          color: 'inherit',
          '&:hover': { color: linkColor },
        }}
      >
        {content}
      </Typography>
      <Typography component='span' order={{ xs: 1, md: 2 }}>
        {socialIcons(defaultSocialIconsSize)[icon]}
      </Typography>
    </Stack>
  );
}
