import { useAppSelector } from 'hooks/redux';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';

const useEditorURL = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const history = useHistory();
  const { data: editor } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  });
  const editorURL = editor?.url;

  const goToEditorPage = useCallback(() => {
    editorURL && history.push(`/${editorURL}`);
  }, [editorURL, history]);

  return {
    editorURL,
    goToEditorPage,
  };
};

export default useEditorURL;
