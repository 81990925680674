import { Container, ContainerProps } from '@mui/material';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const LayoutContainer = ({ children, ...otherProps }: ContainerProps) => {
  const { cachedLoggedInUser } = useGetLoggedInUser();

  if (cachedLoggedInUser) {
    const width: ContainerProps['maxWidth'] = 'xl';

    return (
      <Container maxWidth={width} {...otherProps}>
        {children}
      </Container>
    );
  }

  return <Container {...otherProps}>{children}</Container>;
};

export default LayoutContainer;
