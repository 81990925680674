import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import guestSessionServices from 'lib/services/guestSessionServices';
import getNodeEnv from 'utilities/getNodeEnv';
import guestSessionLoginTokenUtils from 'views/guestSession/GuestSessionLogin/guestSessionLoginTokenUtils';
import useValidationContextProvider from 'views/guestSession/ValidateGuestSession/hooks/useValidationContextProvider';

const useGuestSessionServices = (sessionId: string | undefined) => {
  const history = useHistory();
  const isNodeEnvProduction = getNodeEnv();
  const { validationState, updateValidationState } = useValidationContextProvider();

  const cancelSession = async () => {
    try {
      if (!sessionId) {
        const ERROR_MSG = 'sessionId not found';
        !isNodeEnvProduction && console.error(`${ERROR_MSG} while canncelling session`);
        throw new Error(ERROR_MSG);
      }
      await guestSessionServices.cancelGuestSession(sessionId);
      updateValidationState({
        ...validationState,
        sessionCancellationStatus: 'cancelled',
      });
    } catch (error) {
      toast.error('Error cancelling session');
    }
  };

  const handleSessionLogout = async () => {
    try {
      if (!sessionId) {
        const ERROR_MSG = 'sessionId not found';
        !isNodeEnvProduction && console.error(`${ERROR_MSG} while logging out`);
        throw new Error(ERROR_MSG);
      }
      const isLoggedOut = await guestSessionServices.guestSessionLogout(sessionId);
      if (isLoggedOut) {
        guestSessionLoginTokenUtils.removeGuestSessionLoginToken();
        history.push(`/guest-session/${sessionId}/login`);
        return;
      }
      throw new Error('error logging out');
    } catch (error) {
      toast.error('Error logging out');
    }
  };

  return {
    cancelSession,
    handleSessionLogout,
  };
};

export default useGuestSessionServices;
