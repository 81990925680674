import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';

const stripeUtils = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getStripeUtilsMinimumCharge: build.query<string, string>({
      query: (currency) => `/stripe/get-minimum-charge?currency=${currency.toLowerCase()}`,
      providesTags: ['StripeUtilsMinimumCharge'],
    }),
  }),
});

export const { useGetStripeUtilsMinimumChargeQuery, useLazyGetStripeUtilsMinimumChargeQuery  } =
stripeUtils;

export default stripeUtils;
