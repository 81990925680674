export const initialThemeState = {
  id: 0,
  name: 'Creative',
  themeColor: '#d4aee8',
  backgroundStyle: 'solid',
  fonts: {
    default: 'Poppins',
    button: 'Poppins',
    navbar: 'Poppins',
    sizes: {
      navbar: '16px',
      text: '16px',
      h1: '42px',
      h2: '32px',
      h3: '24px',
    },
    weights: {
      navbar: 'bold',
      text: 'normal',
      h1: 'bold',
      h2: 'bold',
      h3: 'semi-bold',
    },
    align: {
      title: 'center',
      subtitle: 'center',
      text: 'left',
    },
  },
  linkColor: '#0d6efd',
  navbar: {
    style: 'corners',
    sticky: true,
    autoHide: true,
    shadow: true,
    background: '#fafafa',
    color: '#616161',
    ctaButton: {
      background: 'transparent',
      color: '#000',
      borderWidth: '0px',
      borderColor: 'transparent',
      borderRadius: '5px',
      padding: '',
      boxShadow: 'none',
    },
  },
  profile: {
    paddingTop: 80,
    paddingBottom: 80,
    isNeedDiv: false,
    backgroundColor: '#fff',
    background:
      'linear-gradient(135deg,#bcdfef 0%,#d4aee8 20%,#e799e1 40%,#f6a1d0 60%,#fcb4c0 80%,#fbeabc 100%);',
    backgroundBlendMode: 'normal',
    backgroundSize: '',
    editBtn: {
      background: '#D0E8F2',
      color: '#0e1318',
    },
    profileImg: {
      width: { xs: 280, md: 280 },
      height: { xs: 280, md: 280 },
      maxWidth: { xs: 280, md: 280 },
      maxHeight: { xs: 280, md: 280 },
      textAlign: "center"
    },
    global: {
      color: '#fff',
      align: 'left',
    },
    userName: {
      fontSize: '42px',
      textAlign: 'center',
      color: '#000',
    },
    title: {
      fontSize: '32px',
      textAlign: 'center',
      color: '#000',
    },
    subtitle: {
      fontSize: '24px',
      textAlign: 'center',
      color: '#000',
      background: '#ffffff',
      backgroundBlendMode: 'normal',  
    },
    tag: {
      fontSize: '18px',
      rowGap: '15px',
      columnGap: '15px',
      background: '#e8dfca',
      color: '#000',
      borderRadius: '50px',
      border: '1px solid #000',
      padding: '20px 16px 20px 16px',
      boxShadow: '0 0 5px #924e70',
    },
  },
  about: {
    paddingTop: 80,
    paddingBottom: 80,
    background: 'linear-gradient(180deg,#fbeabc 0%,#fff 25%);',
    backgroundBlendMode: 'normal',
    backgroundSize: '',
    title: {
      fontSize: '42px',
      textAlign: 'center',
      color: '#000',
    },
    description: {
      fontSize: '24px',
      textAlign: 'center',
      color: '#000',
    },
  },
  portfolios: {
    paddingTop: 80,
    paddingBottom: 80,
    background: '#fff',
    backgroundSize: '',
    backgroundBlendMode: '',
    title: {
      color: '#000',
      fontsize: '42px',
    },
    item: {
      titleStyle: {
        color: "#000",
        fontSize: "20px"
      },
      descriptionStyle: {
        color: "#000",
        fontSize: "20px"
      },
      background: '#FFF',
      imgShadow: '0 0 4px #cdcdcd',
      boxShadow: 'none',
      border: '0 solid #0A2647',
      contentPadding: '10px',
      borderRadius: '5px',
      button: {
        fontSize: '14px',
        background: '#181D31',
        color: '#BFEAF5',
        border: 'none',
        margin: '0',
        borderRadius: 0,
        backgroundBlendMode: 'normal', // new
        boxShadow: '0 0 4px #cdcdcd', // new
      },
    },
  },
  store: {
    paddingTop: 80,
    paddingBottom: 100,
    background: 'linear-gradient(165deg,#fbeabc 0%,#ffe0ea 100%);',
    backgroundSize: '',
    backgroundBlendMode: '',
    global: {
      fontSize: '18px',
      color: '#000',
    },
    title: {
      fontSize: '42px',
      color: '#000',
      textAlign: 'center',
    },
    item: {
      border: '0 solid #0A2647',
      borderRadius: '10px',
      background: '#FFF',
      padding: '24px 24px 24px 24px',
      boxShadow: '0 0 4px #cdcdcd',
      title: {
        fontSize: '36px',
      },
      price: {
        fontSize: '18px',
      },
      duration: {
        fontSize: '18px',
      },
      description: {
        fontSize: '18px',
        color: '#000',
      },
      button: {
        fontSize: '18px',
        background: '#ffda79',
        color: '#000',
      },
      tag: {
        fontSize: '18px',
        rowGap: '15px',
        columnGap: '15px',
        background: '#fff7eb',
        color: '#000',
        borderRadius: '50px',
        border: '0px solid #fff',
        padding: '20px 16px 20px 16px',
        boxShadow: 'inset 0 0 3px #d0c2ad',
      },
    },
    addSPMBtn: {
      background: '#D0E8F2',
      color: '#0e1318',
      border: '0px solid #000',
      borderRadius: '50px',
      fontSize: '18px',
    },
  },
  calendar: {
    paddingTop: 60,
    paddingBottom: 100,
    background: "url(https://res.cloudinary.com/salesfronts/image/upload/v1675342516/ifsysu4tn0027iibnrky.png), linear-gradient(#e7fdff, #e6ffd7)",
    backgroundSize: "",    
    backgroundBlendMode: "",
    global: {
      fontSize: "18px",
      color: "#000"
    },
    title: {
      fontSize: "42px",
      color: "#000",
      textAlign: "center"
    },
    item: {
      border: "10px solid #fff",
      borderRadius: "2px",
      background: "#f6fff1",
      padding: "24px 24px 24px 24px",
      boxShadow: "#808080 -2px 2px 5px",
      title: {
        fontSize: "30px"
      },
      price: {
        fontSize: "20px"
      },
      duration: {
        fontSize: "20px"
      },
      description: {
        fontSize: "18px",
        color: "#000"
      },
      button: {
        fontSize: "20px",
        background: "#006867",
        color: "#fff"
      },
      tag: {
        fontSize: "16px",
        rowGap: "8px",
        columnGap: "8px",
        background: "#fff",
        color: "#000",
        borderRadius: "4px",
        border: "0px solid #fff",
        padding: "6px 6px 6px 6px",
        boxShadow: "inset -2px 2px 5px #939393"
      }
    }
  },
  footer: {
    color: '#000',    
    background: 'linear-gradient(180deg,#fbeabc 0%,#fff 25%);',
    backgroundSize: '',
    backgroundBlendMode: '',    
  },
};

export type ThemeType = typeof initialThemeState & {
  premium?: boolean;
};
