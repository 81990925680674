import { useAppSelector } from 'hooks/redux';
import useGetLoginToken from 'hooks/useGetLoginToken';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';

const useGetLoggedInUser = () => {
  const { loginToken } = useGetLoginToken();
  const userIdInReduxUserSlice = useAppSelector(selectLoggedInUserId);
  const shouldSkipFetchingEditor = !userIdInReduxUserSlice || !loginToken;
  const {
    data: cachedLoggedInUser,
    isError,
    isLoading,
    isFetching,
    isSuccess,
  } = useGetEditorByIdQuery(userIdInReduxUserSlice, {
    skip: shouldSkipFetchingEditor,
  });
  const languageDir = cachedLoggedInUser?.languageDir ?? 'ltr';
  return {
    isLoading,
    isFetching,
    isError,
    isSuccess,
    userIdInReduxUserSlice,
    cachedLoggedInUser,
    languageDir,
  };
};

export default useGetLoggedInUser;
