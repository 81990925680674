import React, { useState, useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { TagProps } from 'components/utilities/EditableTags/sub-components/Tag/Tag';

const useTag = ({ label, taglist, onChange }: TagProps) => {
  const [editModeState, seEditModeState] = useState({
    text: label,
    editMode: false,
  });

  const isEditMode = editModeState.editMode;

  const updateEditMode = (value: boolean) => {
    seEditModeState((prev) => ({
      ...prev,
      editMode: value,
    }));
  };

  const updateEditModeText = (value: string) => {
    seEditModeState((prev) => ({
      ...prev,
      text: value,
    }));
  };

  const closeEditMode = () => {
    updateEditMode(false);
  };

  // ✔️ closing edit more if clicked outside of tag
  const tagDOMRef = useOnclickOutside(() => closeEditMode());

  const handleEditTagBtnClick = () => {
    updateEditMode(true);
  };

  const handleCloseEditModeBtnClick = () => {
    closeEditMode();
  };

  const removeTag = (deletedTag: string) => {
    const tagsCopy = structuredClone(taglist);
    const tagsWithRemovedTag = [] as string[];
    for (const tag of tagsCopy) {
      if (tag !== deletedTag) {
        tagsWithRemovedTag.push(tag);
      }
    }
    return tagsWithRemovedTag;
  };

  const handleDeleteTagBtnClick = () => {
    const updatedTagList = removeTag(label);
    onChange(updatedTagList);
  };

  const handleEditConfirmBtnClick = () => {
    const editedValue = editModeState.text.trim();

    // ✅ if there's no change in text input
    if (editedValue.toLowerCase() === label.toLowerCase()) {
      closeEditMode();
      return;
    }

    // TODO: Add more validations on editedValue

    let tagListWithNewTag = structuredClone(taglist);
    const currentEditedLabelIndex = tagListWithNewTag.indexOf(label);
    tagListWithNewTag[currentEditedLabelIndex] = editedValue;

    onChange(tagListWithNewTag);
    closeEditMode();
  };

  const handleEditModeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditModeText(e.target.value);
  };

  // ✔️ syncing label props with edit mode state text
  useEffect(() => updateEditModeText(label), [label]);

  return {
    tagDOMRef,
    isEditMode,
    editModeState,
    handleEditModeInputChange,
    handleEditConfirmBtnClick,
    handleCloseEditModeBtnClick,
    handleDeleteTagBtnClick,
    handleEditTagBtnClick,
  };
};

export default useTag;
