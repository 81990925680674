import { alpha, Box, Typography, useTheme } from '@mui/material';
import AlertDialogSlide from 'components/utilities/Popups/AlertDialog/AlertDialogSlide';
import useMediaQuery from 'hooks/useMediaQuery';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGetStripeConnectedAccount from 'store/rtkqFeatures/api/hooks/stripeConnectedAccount/useGetStripeConnectedAccount';
import { subscriptionDashboardURL } from 'components/dashboard/FrontsAIPremium/FrontsAIPremium';
import StyledSwitch from 'components/ShareComponents/StyledSwitch';

// const TOAST_MESSAGE_SETTINGS = {
//   autoClose: 1000,
//   hideProgressBar: true,
// };

type FormPaidSwitchProps = {
  isPaid: boolean;
  entityName: string;
  isEntityStatusActive: boolean;
  getUpdatedIsPaidValue: (isPaid: boolean) => void;
};

const FormPaidSwitch = ({
  isPaid,
  entityName,
  getUpdatedIsPaidValue,
}: FormPaidSwitchProps) => {
  const history = useHistory();
  const theme = useTheme();
  const isScreenWidthLessThan768 = useMediaQuery('(max-width: 768px)');
  const [isAlertDialogBoxOpen, setIsAlertDialogBoxOpen] = useState(false);
  const { isStripeOnboardingProcessCompleted } = useGetStripeConnectedAccount();

  const handleIsPaidValueChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    // if (isStripeOnboardingProcessCompleted || !isEntityStatusActive) {
    if (isStripeOnboardingProcessCompleted) {
      getUpdatedIsPaidValue(checked);
      return;
    }
    setIsAlertDialogBoxOpen(true);
  };

  const handleDialogCancelBtnClick = () => {
    setIsAlertDialogBoxOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: '4px 0' }}>
        <StyledSwitch
          name='isPaid'
          checked={isPaid}
          onChange={handleIsPaidValueChange}
        />
        <Box
          sx={{
            padding: '4px 12px',
            borderRadius: '16px',
            backgroundColor: isPaid ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.grey[400], 0.1),
            color: isPaid ? theme.palette.primary.main : theme.palette.text.secondary,
            fontSize: '1.4rem',
            fontWeight: 600,
            transition: 'all 0.3s ease',
            }}
        >
            {entityName} is {isPaid ? 'paid' : 'free'}
        </Box>
      </Box>
      {/* ------------------------------------- Alert Dialog Box ------------------------------------------------------------ */}
      <AlertDialogSlide
        dialogTitleElement={
          <>
            <Typography variant='h4' textAlign='left' gutterBottom fontWeight={600}>
              Stripe Account Setup
            </Typography>
            <hr />
          </>
        }
        actionText={'Complete Setup'}
        isDialogBoxOpen={isAlertDialogBoxOpen}
        handleDialogCancelBtnClick={handleDialogCancelBtnClick}
        handleDialogActionBtnClick={() => history.push(subscriptionDashboardURL)}
      >
        <Typography variant='body2'>
          To activate, you need to complete your onboarding process with Stripe.
        </Typography>
      </AlertDialogSlide>
    </>
  );
};

export default FormPaidSwitch;
