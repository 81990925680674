import axios, { AxiosRequestConfig } from 'axios';
import loginTokenUtils from 'utilities/loginTokenUtils';

// NOTE: for backend api requesting

const createApiClientWithoutCredentials = (baseURL?: string) => {
  return axios.create({
    baseURL,
  });
}

const createApiClientWithCredentials = (baseURL?: string, withCredentials = false) => {
  const client = axios.create({
    baseURL,
    withCredentials,    
  });

  client.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = loginTokenUtils.getLocalStorageLoginToken();
      if (token && config && config.headers && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return client;
};

const isNodeEnvProduction = process.env.NODE_ENV === 'production';
const BASE_URL = isNodeEnvProduction
  ? process.env.REACT_APP_PRODUCTION_SERVER_API_URL
  : process.env.REACT_APP_DEVELOPMENT_SERVER_API_URL;

export const SERVER_API_BASE_URL = {
  V1: BASE_URL || '', // uses mongodb at backend
  V2: `${BASE_URL}/v2`, // uses mongoose at backend
  public: `${BASE_URL}/public`,
  cloudinary: `${BASE_URL}/cloudinary`,  
};

const publicAPIClient = createApiClientWithoutCredentials(SERVER_API_BASE_URL.public);
const basicAPIClientWithoutCredentials = createApiClientWithoutCredentials(SERVER_API_BASE_URL.V1);
const basicAPIClientWithCredentials = createApiClientWithCredentials(SERVER_API_BASE_URL.V1, true);
const cloudinaryAPIClient = createApiClientWithCredentials(SERVER_API_BASE_URL.cloudinary, true);
const domainAPIClient = createApiClientWithCredentials(`${SERVER_API_BASE_URL.V1}/domain`, true);
const onboardingRoutes = createApiClientWithCredentials(`${SERVER_API_BASE_URL.V1}/ai-web-builder`, true);

const serverApi = {
  v1: basicAPIClientWithCredentials,
  v2: axios.create({
    baseURL: SERVER_API_BASE_URL.V2,
    withCredentials: true,
  }),
  onboardingRoutes,
  basic: basicAPIClientWithoutCredentials,
  basicWithCredentials: basicAPIClientWithCredentials,
  public: publicAPIClient,
  cloudinary: cloudinaryAPIClient,
  domain: domainAPIClient,
};

export default serverApi;
