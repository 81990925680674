import { Redirect } from 'react-router-dom';
import routeConstants from 'constants/routeConstants';

const homeRoute = routeConstants.HOME_ROUTE;

const RedirectToSignIn = () => {
  return <Redirect to={homeRoute} />;
};

export default RedirectToSignIn;
