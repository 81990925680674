import { Box, Stack, Typography } from '@mui/material';
import IfElse from 'components/utilities/IfElse';
import FileReader from 'components/utilities/FileReader/FileReader';
import { useFormikContext } from 'formik';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import uploadImageService from 'lib/services/uploadImageService';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import Question from '../../reusable-components/Question';
import BoldSpan from 'components/utilities/BoldSpan';

const InputBgImage = () => {
  const { setFieldValue, values, setFieldTouched, isValid, errors } =
    useFormikContext<StepperState>();
  const isChangeMode = !!values.userBgImage?.trim();
  const bgImg = values.userBgImageFile && values.userBgImageFile instanceof File
    ? URL.createObjectURL(values.userBgImageFile)
    : values.userBgImage;

  function handleDeleteImg() {
    if (isChangeMode && values.userBgImage?.trim()) {
      if (values.userBgImage) {
        const publicId = cloudinaryUtilsFns.extractPublicIdFromCloudinaryURL(
          values.userBgImage
        );
        if (publicId) {
          uploadImageService.deleteCloudinaryImgWithPublicId(publicId);
        }
      }
      if (values.userBgImageFile) {
        URL.revokeObjectURL(values.userBgImage);
        setFieldValue('userBgImageFile', '');
      }
    }
  }

  return (
    <Box>
      <Question
        currentStep={2}
        helperText='Use your own background for the website '
      >
        <>
          {' '}
          Upload <BoldSpan>background image</BoldSpan>
        </>
      </Question>
      <Stack
        sx={{
          flexDirection: 'column',
          mt: 1,
          alignItems: { xs: 'center', md: 'flex-start' },
        }}
      >
        {/* img  */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Stack
            rowGap={2}
            sx={{
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            {!!bgImg && (
              <Box
                component='img'
                src={bgImg}
                alt='spm-img'
                sx={{
                  maxHeight: '139px',
                  objectFit: 'contain',
                }}
              />
            )}

            <FileReader
              label={values.userBgImageFile ? 'Change Image' : 'Upload Image'}
              handleImageChange={(e) => {
                setFieldTouched('userBgImageFile', true);
                if (e.target.files?.length) {
                  setFieldValue('userBgImageFile', e.target.files[0]);
                }
                isChangeMode && handleDeleteImg();
              }}
            />
          </Stack>
        </Box>
        <IfElse condition={!isValid}>
          <Typography component={'span'} textAlign={'center'} color='error.main'>
            {errors.userBgImage}
          </Typography>
        </IfElse>
      </Stack>
    </Box>
  );
};

export default InputBgImage;
