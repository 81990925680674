const supportedCountries = [
  { id: 'AE', default_currency: 'aed' },
  { id: 'AT', default_currency: 'eur' },
  { id: 'AU', default_currency: 'aud' },
  { id: 'BE', default_currency: 'eur' },
  { id: 'BG', default_currency: 'bgn' },
  { id: 'BR', default_currency: 'brl' },
  { id: 'CA', default_currency: 'cad' },
  { id: 'CH', default_currency: 'chf' },
  { id: 'CY', default_currency: 'eur' },
  { id: 'CZ', default_currency: 'czk' },
  { id: 'DE', default_currency: 'eur' },
  { id: 'DK', default_currency: 'dkk' },
  { id: 'EE', default_currency: 'eur' },
  { id: 'ES', default_currency: 'eur' },
  { id: 'FI', default_currency: 'eur' },
  { id: 'FR', default_currency: 'eur' },
  { id: 'GB', default_currency: 'gbp' },
  { id: 'GI', default_currency: 'gbp' },
  { id: 'GR', default_currency: 'eur' },
  { id: 'HK', default_currency: 'hkd' },
  { id: 'HR', default_currency: 'eur' },
  { id: 'HU', default_currency: 'huf' },
  { id: 'IE', default_currency: 'eur' },
  { id: 'IN', default_currency: 'inr' },
  { id: 'IT', default_currency: 'eur' },
  { id: 'JP', default_currency: 'jpy' },
  { id: 'LI', default_currency: 'chf' },
  { id: 'LT', default_currency: 'eur' },
  { id: 'LU', default_currency: 'eur' },
  { id: 'LV', default_currency: 'eur' },
  { id: 'MT', default_currency: 'eur' },
  { id: 'MX', default_currency: 'mxn' },
  { id: 'MY', default_currency: 'myr' },
  { id: 'NL', default_currency: 'eur' },
  { id: 'NO', default_currency: 'nok' },
  { id: 'NZ', default_currency: 'nzd' },
  { id: 'PL', default_currency: 'pln' },
  { id: 'PT', default_currency: 'eur' },
  { id: 'RO', default_currency: 'ron' },
  { id: 'SE', default_currency: 'sek' },
  { id: 'SG', default_currency: 'sgd' },
  { id: 'SI', default_currency: 'eur' },
  { id: 'SK', default_currency: 'eur' },
  { id: 'TH', default_currency: 'thb' },
  { id: 'US', default_currency: 'usd' },
];

export default supportedCountries;