import React, { forwardRef, Ref } from 'react';
import { Box, SxProps } from '@mui/material';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import StyledItemLabel from 'components/shared/StyledItemLabel';

type FormLabelWithTextInputProps = {
  labelText: string;
  sx?: SxProps;
  inputName?: string;
  inputId?: string;
  inputValue: string;
  placeholder?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  required?: boolean;
  [key: string]: any;
};

const FormLabelWithTextInput = forwardRef(
  (
    {
      inputName,
      labelText,
      inputId,
      inputValue,
      handleChange,
      placeholder,
      currentStep,
      sx,
      className,
      required,
      ...restProps
    }: FormLabelWithTextInputProps,
    ref: Ref<HTMLInputElement> | null
  ) => {
    return (
      <Box>
        <StyledItemLabel
          label={labelText} 
          required={required}
        />        
        <TextFieldSyncWithTheme
          name={inputName}
          id={inputId}
          variant='outlined'
          fullWidth
          value={inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          required={required}
          inputRef={ref}
          {...restProps}
        />
      </Box>
    );
  }
);

FormLabelWithTextInput.displayName = 'FormLabelWithTextInput';

export default FormLabelWithTextInput;