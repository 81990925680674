import { styleType } from 'components/logins/SelectLoginAccount/sub-components/EmailLinkedUserAccounts/EmailLinkedUserAccount.style';

export const style_ThemeSelector_formControl: styleType = () => {
  return {    
    m: 0,
    '& .MuiFormLabel-root': {
      backgroundColor: 'linear-gradient(#fff 50%, #fff900 50%)',
      color: '#000',
    },
  };
};

const style_ThemeSelector_shineStyle: styleType = () => {
  return {
    '&:after': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100px',
      height: '300px',
      background:
        'linear-gradient(30deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%)',
      transform: 'rotate(20deg) translateY(-100px) translateX(-30px)',
      transformOrigin: 'top left',
      animation: 'shine 2s ease-in infinite',
    },
    '@keyframes shine': {
      '0%': {
        transform: 'rotate(20deg) translateY(-100px) translateX(-200px)',
        width: '100px',
      },
      '100%': {
        transform: 'rotate(20deg) translateY(-100px) translateX(400px)',
        width: '100px',
      },
    },
  };
};

export const headerSelectorStyle: styleType = () => {
  return {    
    minHeight: '20px',
    backgroundColor: "transparent", /* Adjust as needed to match your navbar */
    fontSize: '1.5rem',    
    "&:before , &:after":{
      display: 'none',
    },
    "& .MuiSelect-filled":  {
      backgroundColor: "transparent", /* Adjust as needed to match your navbar */
      boxShadow: "none"
    },
    '& .MuiSelect-select' : {
      minHeight: '20px !important',
    },
    '&:first-of-type': {
      paddingY: '0',
    },
    '.MuiOutlinedInput-input': {
      padding: '8px 14px', // Adjust padding inside the input field
    },
    '.MuiSvgIcon-root': { // Adjust icon sizes if necessary
      fontSize: '2rem',
    },
    '& .MuiInputBase-input': {
      fontSize: { xs: 15, md: 16 },
      padding: '8px 26px 8px 12px',
    },
  };
};

export const style_ThemeSelector_select: styleType = () => {
  return {
    ...headerSelectorStyle(),
    minHeight: '20px',
    position: 'relative',
    overflow: 'hidden',
    ...(style_ThemeSelector_shineStyle() as any), // TODO: fix this any
  };
};

export const style_ThemeSelector_menuItems: styleType = () => {
  return {
    fontSize: '1.5rem',
    width: '100%',
    zIndex: 100001,
  };
};

export const style_ThemeSelector_Icon: styleType = (themeColor) => {
  return {
    borderRadius: '50px',
    width: 20, height: 20,
    bgcolor: `${themeColor}`,
    color: `${themeColor}`,
  };
};

export const style_ThemeSelector_name: styleType = () => {
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: 1.4,
  };
}