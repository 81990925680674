import React from 'react';
import { useGetMeetingsByUserIdQuery } from 'store/rtkqFeatures/api/meetingExtendedApi';
import MeetingsHighPriorityContext from 'components/MUI_SPM/Meeting/sub-components/MeetingsHighPriorityContext';
import MeetingsLowPriorityContext from 'components/MUI_SPM/Meeting/sub-components/MeetingsLowPriorityContext';
import SPMCard from 'components/MUI_SPM/SPMCard/SPMCard';
import { MeetingTypes } from 'types/meeting';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import {
  InitialDeleteEntityDialogState,
  SPMDeleteAction,
} from 'components/MUI_SPM/SPMCard/sub-components/SPMDeletionConfirmationDialog/hooks/useSPMDeletionConfirmationDialog';
import { useHistory } from 'react-router-dom';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

type MeetingProps = {
  meetingId: string;
  editorId: string;
  setDeleteEntityDialogState: React.Dispatch<
    React.SetStateAction<InitialDeleteEntityDialogState>
  >;
};

const Meeting: React.FunctionComponent<MeetingProps> = ({
  meetingId,
  editorId,
  setDeleteEntityDialogState,
}) => {
  const isCardRenderValid = editorId && meetingId;
  const history = useHistory();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { languageDir } = useGetLoggedInUser();
  // !! making store of type any until we finalize our structure
  const { storeStylings }: { storeStylings: any } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      storeStylings: themes
        ? themes[localCurrentThemeIndex].store
        : initialThemeState.store,
    }),
  });
  const { thisMeeting } = useGetMeetingsByUserIdQuery(editorId, {
    skip: !isCardRenderValid,
    selectFromResult: ({ data }) => ({
      thisMeeting: data?.find(({ _id }) => {
        return _id === meetingId;
      }),
    }),
  });

  if (!thisMeeting) return <></>;

  const isVirtualMeeting = thisMeeting.type === MeetingTypes.Virtual;
  const editEntityPathNameType = isVirtualMeeting ? 'v' : 'p';
  const editActionRedirectPath = `/edit-meeting-${editEntityPathNameType}`;
  const editActionSearchParams = `?meetingId=${meetingId}`;

  const handleDeleteActionBtnClick = async () => {
    setDeleteEntityDialogState({
      isDialogOpen: true,
      toBeDeletedEntityId: meetingId,
      deleteAction: SPMDeleteAction.DeleteMeeting,
    });
  };

  const handleMeetingActionBtnClick = () => {
    if (languageDir === 'rtl') {
      history.push(`/calendar/${meetingId}?dir=rtl`);
      return;
    }
    history.push(`/calendar/${meetingId}`);
  };

  return (
    <SPMCard
      key={thisMeeting._id}
      onCTABtnClick={handleMeetingActionBtnClick}
      styling={storeStylings}
      time={thisMeeting.time}
      price={thisMeeting.price}
      cardImg={thisMeeting.img}
      isPaid={thisMeeting.isPaid}
      cardTitle={thisMeeting.title}
      cardEntityId={thisMeeting._id}
      cardIncludedItems={thisMeeting.included}
      cardDescriptionContent={thisMeeting.desc}
      cardCTAButtonText={thisMeeting.ui.ctaBtnText}
      editActionRedirectPath={editActionRedirectPath}
      editActionSearchParams={editActionSearchParams}
      isEntityActive={thisMeeting.status === 'active'}
      handleCardDeleteAction={handleDeleteActionBtnClick}
      cardIncludedItemsHeading={thisMeeting.ui.includedItemsHeading}
      CTAHighPriorityContext={
        <MeetingsHighPriorityContext meetingId={meetingId} editorId={editorId} />
      }
      CTALowPriorityContext={
        <MeetingsLowPriorityContext meetingId={meetingId} editorId={editorId} />
      }
    />
  );
};
export default Meeting;
