import React from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { Box, Typography, styled, useTheme } from '@mui/material';
import Switch from '@mui/material/Switch';
import { alpha } from '@mui/material/styles';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import StyledSwitch from 'components/ShareComponents/StyledSwitch';

type ProfileSearchableSwitchProps = {
  isSearchable: boolean;
};

const ProfileSearchableSwitchFromEditProfile: React.FC<ProfileSearchableSwitchProps> = ({ isSearchable }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<EditUserDetailsForm>();
  const theme = useTheme();

  const handleSwitchChangeLocally = debounce((checked: boolean) => {
    setFieldTouched('searchable', true);
    setFieldValue('searchable', checked);
  }, 300);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <StyledSwitch
          checked={isSearchable}
          onChange={(_event, checked) => handleSwitchChangeLocally(checked)}
        />
        <Box
          sx={{
            padding: '4px 12px',
            borderRadius: '16px',
            backgroundColor: isSearchable ? alpha(theme.palette.primary.main, 0.1) : alpha(theme.palette.grey[400], 0.1),
            color: isSearchable ? theme.palette.primary.main : theme.palette.text.secondary,
            fontSize: '1.4rem',
            fontWeight: 600,
            transition: 'all 0.3s ease',
          }}
        >
          {isSearchable ? 'Searchable' : 'Hidden'}
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileSearchableSwitchFromEditProfile;