import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { LoggedInUser, UserModule } from 'types/user';
import { USER_MODULE_INITIAL_STATE } from './userModuleSliceInitialization';

const loggedinUserSlice = createSlice({
  name: 'loggedinUserModule',
  initialState: USER_MODULE_INITIAL_STATE,
  reducers: {
    setLoggedInUser: (
      state: UserModule,
      action: PayloadAction<LoggedInUser>
    ) => {
      state.loggedInUser = action.payload;
    },
    resetLoggedinUserSlice: () => {
      return USER_MODULE_INITIAL_STATE;
    },
  },
});

const loggedinUserSliceReducer = loggedinUserSlice.reducer;
export default loggedinUserSliceReducer;

export const {
  setLoggedInUser,
  resetLoggedinUserSlice,
} = loggedinUserSlice.actions;

// user slice selectors
export const selectLoggedinUserModule = (state: RootState) =>
  state.persistedReducer.loggedinUserModule;

export const selectLoggedinUser = (state: RootState) => {
  const loggedinUserModule = selectLoggedinUserModule(state);
  return loggedinUserModule.loggedInUser;
};

export const selectLoggedInUserId = (state: RootState) => {
  const loggedInUser = selectLoggedinUser(state);
  return loggedInUser?._id;
};
