import axios, { AxiosError } from 'axios';

import serverApi from 'lib/apiConfig';
import {
  DeleteMeetingActionParams,
  GetUserMeetingsActionParams,
} from 'store/features/meetings/meetingActions';
import {
  PhysicalOrVirtualMeeting,
  PhysicalOrVirtualMeetingList,
  PhysicalOrVirtualNewMeeting,
} from 'types/meeting';

type GetUserMeetingsReturnType =
  | {
      isSuccessful: boolean;
      meetings?: PhysicalOrVirtualMeetingList;
    }
  | undefined;

type GetUserMeetingReturnType<MeetingType> =
  | {
      isSuccessful: boolean;
      meeting?: MeetingType;
    }
  | undefined;

type CreateMeetingReturnType =
  | {
      isSuccessful: boolean;
      newMeetingId?: string;
    }
  | undefined;

// !! In use
export async function getMeetingById(meetingId: string): Promise<PhysicalOrVirtualMeeting | AxiosError> {
  try {
    const {data} = await serverApi.public.get<PhysicalOrVirtualMeeting>(`meetings/${meetingId}`)
    return data;
  } catch (error) {
    return error as AxiosError;
  }
}

export async function getUserMeetings(
  userId: GetUserMeetingsActionParams[0]
): Promise<GetUserMeetingsReturnType> {
  try {
    const res = await serverApi.v2.get(`/meetings/user/${userId}`);
    if (res.status === 200 && res.data.length) {
      return {
        isSuccessful: true,
        meetings: res.data,
      };
    }
    return {
      isSuccessful: false,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMsg = error.message;
      console.error(
        'error while fetching user meetings with id: ',
        userId,
        ' and error: ',
        errorMsg
      );
      throw error.message;
    }
  }
}

export async function createMeeting<
  MeetingType extends PhysicalOrVirtualNewMeeting
>(meeting: MeetingType): Promise<CreateMeetingReturnType> {
  try {
    const res = await serverApi.v2.post(`/meetings/`, meeting);
    if (res.status === 200 && res.data?.isSuccessful) {
      const { newMeetingId } = res.data;
      return {
        isSuccessful: true,
        newMeetingId,
      };
    }
    return {
      isSuccessful: false,
      newMeetingId: undefined,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMsg = error.message;
      console.error(
        'error while fetching user meetings with user id: ',
        meeting.userId,
        ' with error: ',
        errorMsg
      );
      throw errorMsg;
    }
  }
}

export async function getUserMeeting<
  MeetingType extends PhysicalOrVirtualMeeting
>(
  meetingId: PhysicalOrVirtualMeeting['_id']
): Promise<GetUserMeetingReturnType<MeetingType>> {
  try {
    const res = await serverApi.v2.get(`/meetings/${meetingId}`);
    if (res.status === 200 && res.data) {
      return {
        isSuccessful: true,
        meeting: res.data,
      };
    }
    return {
      isSuccessful: false,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMsg = error.message;
      console.error(
        'error while fetching a user meeting with meeting id: ',
        meetingId,
        ' and error: ',
        errorMsg
      );
      throw error.message;
    }
  }
}

export async function deleteMeeting(
  meetingId: DeleteMeetingActionParams[0]
): Promise<boolean | undefined> {
  try {
    const res = await serverApi.v2.delete(`/meetings/${meetingId}`);
    if (res.status === 200 && res.data.isSuccessful) return true;
    return false;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMsg = error.message;
      console.error(
        'error while deleting the meeting with id: ',
        meetingId,
        ' and error: ',
        errorMsg
      );
      throw error.message;
    }
  }
}

export async function updateMeeting(
  meetingId: PhysicalOrVirtualMeeting['_id'],
  toBeUpdatedMeeting: Partial<PhysicalOrVirtualMeeting>
): Promise<boolean | undefined> {
  const { _id, userId, isDeleted, type, ui, ..._toBeUpdatedMeeting } =
    toBeUpdatedMeeting;
  try {
    const res = await serverApi.v2.put(
      `/meetings/${meetingId}`,
      _toBeUpdatedMeeting
    );
    if (res.status === 200 && res.data.isSuccessful) return true;
    return false;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorMsg = error.message;
      console.error(
        'error while updating the meeting with id: ',
        meetingId,
        ' and error: ',
        errorMsg
      );
      throw error.message;
    }
  }
}

const meetingsServices = {
  getMeetingById,
  createMeeting,
  getUserMeetings,
  getUserMeeting,
  deleteMeeting,
  updateMeeting,
};

export default meetingsServices;
