import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';
import Compressor from 'compressorjs';
import {toast} from 'react-toastify';

import { EditUserDetailsForm } from '../types';

import StyledItemLabel from 'components/shared/StyledItemLabel';
import UploadImage from 'components/utilities/Cloudinary/UploadImage';

const UserBackgroundImgOrVideoUploader = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<EditUserDetailsForm>();
  const { userBgImage, files } = values;

  const handleImgUpload = async (e: React.ChangeEvent<HTMLInputElement>, textColor: string) => {
    const toastId = toast.loading('Uploading Cover Image...');

    const file = e.target.files?.[0];
    if (userBgImage) {
      setFieldValue('deletedImgs.userBgImage', userBgImage);
      setFieldTouched('deletedImgs.userBgImage', true);
    }
    if (file) {
      new Compressor(file, {
        quality: 1,
        maxWidth: 1920,
        maxHeight: 1080,
        width: 1920,
        height: 1080,
        strict: true,
        retainExif: true,        
        success: (compressedFile) => {
          setFieldValue('files.userBgImage', compressedFile);
          setFieldValue('profileStyle.color', textColor);
          setFieldTouched('files', true);
          toast.dismiss(toastId);
          toast.success('Image uploaded successfully');
        },
        error: (error) => {
          toast.dismiss(toastId);
          toast.error('Error uploading image');
        },
      });
    }
  };

  const removeFile = async (url: string | File) => {
    if (typeof url === 'string') {
      setFieldValue('deletedImgs.userBgImage', url);
      setFieldValue('userBgImage', '');
      setFieldTouched('userBgImage', true);
    } else {
      setFieldValue('files.userBgImage', null);
      setFieldTouched('files', true);
    }
  };

  return (
    <Stack gap={1} flexWrap={'wrap'} direction='row'>
    <StyledItemLabel label='Background image' secondaryLabel={<span>Upload background Image</span>} />
      <UploadImage
        imgUploadCallback={handleImgUpload}
        removeCallback={removeFile}
        img={files?.userBgImage ?? userBgImage}
      />
    </Stack>
  );
};

export default UserBackgroundImgOrVideoUploader;
