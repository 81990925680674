import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

const CountdownTimer = ({ minutes }: {minutes: number}) => {
  const [time, setTime] = useState(minutes * 60 * 1000); // Convert minutes to milliseconds

  useEffect(() => {
    if (time > 0) {
      const intervalId = setInterval(() => {
        setTime((prevTime) => prevTime - 1000);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [time]);

  const formatTime = (time: number) => {
    const totalSeconds = Math.floor(time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <Box>
      <Typography variant='body2' gutterBottom color={theme => theme.palette.text.primary}>Resend after {formatTime(time)}</Typography>
    </Box>
  );
};

export default CountdownTimer;