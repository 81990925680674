import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector } from 'hooks/redux';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import useEditorURL from 'store/rtkqFeatures/api/hooks/useEditorURL';
import useHandleStripeOnboardError from 'components/SPM_Forms/hooks/useHandleStripeOnboardError';
import useGetCurrency from 'hooks/useGetCurrency';
import { useGetStripeUtilsMinimumChargeQuery } from 'store/rtkqFeatures/api/stripeUtils';
import generalUtilities from 'utilities/generalUtilities';
import uploadImageService from 'lib/services/uploadImageService';
import {
  useCreateMeetingMutation,
  useUpdateMeetingMutation,
} from 'store/rtkqFeatures/api/meetingExtendedApi';
import {
  useCreateServiceMutation,
  useUpdateServiceMutation,
} from 'store/rtkqFeatures/api/serviceExtendedApi';
import { StoreItemTypes } from 'types/storeItem';
import { useGetEntitiesByUserIdQuery } from 'store/rtkqFeatures/api/entityExtendedApi';

export type BaseFormData = {
  isPaid: boolean;
  price: number;
  title: string;
  desc: string;
  img: string;
  included: string[];
  file?: File;
  deletedImg?: string;
  [key: string]: any;
};

type FormValues = {
  isPaid: boolean;
  price: number;
  [key: string]: any;
};

type FormErrors = {
  saveErrorMsg?: string;
  priceErrorMsg?: string;
};

export type CreateEditBaseProps<T extends BaseFormData> = {
  storeItemType: StoreItemTypes;
  getInitialValues: (
    isCreateMode: boolean,
    entityType: 'virtual' | 'physical' | 'service',
    editActionEntity: any,
    exchangeRates: any
  ) => T;
  createNew: (formData: T, editorId: string) => any;
  createEditedUpdatedContext: (formData: T, editActionEntity: any) => any;
  entityName: string;
  entityType: 'virtual' | 'physical' | 'service';
};

export const useCreateEditBase = <T extends BaseFormData>({
  storeItemType,
  getInitialValues,
  createNew,
  createEditedUpdatedContext,
  entityName,
  entityType,
}: CreateEditBaseProps<T>) => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { goToEditorPage } = useEditorURL();
  const { search } = useLocation();
  const entityId = new URLSearchParams(search).get(`${entityName}Id`);

  const isUserAgentWindows = generalUtilities.isUserAgentWindows();
  const isCtrlSSaveMode = useRef(false);
  const { handleStripeOnboardError } = useHandleStripeOnboardError();
  const { currency, symbol } = useGetCurrency();
  const { data: exchangeRates } = useGetStripeUtilsMinimumChargeQuery(
    currency || 'usd'
  );

  const isCreateMode = !entityId;
  const isEditMode = !isCreateMode;

  const { data: entities } = useGetEntitiesByUserIdQuery(editorId || '');

  const currentEntity = useMemo(
    () => entities?.find((entity) => entity._id === entityId),
    [entities, entityId]
  );

  const editActionEntity = currentEntity;
  const initialValues = useMemo(
    () =>
      getInitialValues(
        isCreateMode,
        entityType,
        editActionEntity,
        exchangeRates
      ),
    [isCreateMode, entityType, editActionEntity, exchangeRates]
  );

  const [createMeeting, { isLoading: isMeetingCreationInProgress }] =
    useCreateMeetingMutation();
  const [updateMeeting, { isLoading: isMeetingUpdationInProgress }] =
    useUpdateMeetingMutation();
  const [createService, { isLoading: isServiceCreationInProgress }] =
    useCreateServiceMutation();
  const [updateService, { isLoading: isServiceUpdationInProgress }] =
    useUpdateServiceMutation();

  const isFormPrimaryActionInProgress =
    isMeetingCreationInProgress ||
    isMeetingUpdationInProgress ||
    isServiceCreationInProgress ||
    isServiceUpdationInProgress;

  const validateForm = (values: FormValues): FormErrors => {
    const errors: FormErrors = {};
    if (isFormPrimaryActionInProgress) {
      errors.saveErrorMsg =
        'Cannot save while creating/updating action in progress';
    } else if (values.isPaid && values.price <= 0) {
      errors.priceErrorMsg =
        'With a paid entity, price should be greater than 0';
    }
    return errors;
  };

  const saveEntity = async (formData: T) => {
    if (entityType === 'service') {
      if (isCreateMode) {
        await createService(createNew(formData, editorId));
      } else {
        await updateService({ serviceId: entityId, updatedService: createEditedUpdatedContext(formData, editActionEntity) }).unwrap();
      }
    } else {
      if (isCreateMode) {
        await createMeeting(createNew(formData, editorId));
      } else {
        await updateMeeting({ meetingId: entityId || '', updatedMeeting: createEditedUpdatedContext(formData, editActionEntity) }).unwrap();
      }
    }
  };
  

  const handleFormSubmission = async (
    values: T,
    formikHelpers: FormikHelpers<T>
  ) => {
    const toastId = toast.loading(
      isEditMode ? `Saving ${entityName}` : `Creating ${entityName}`
    );

    try {
      let formData = { ...values };

      if (isEditMode && editActionEntity) {
        formData = { ...editActionEntity, ...formData };
      }

      if (formData.deletedImg) {
        try {
          uploadImageService.deleteImg(formData.deletedImg);
        } catch (error) {
          console.error('Error deleting image', error);
        }
        formData.img = '';
      }

      if (formData.file) {
        formData.img = await uploadImageService.uploadImageWithAuthentication(
          formData.file
        );
      }

      await handleStripeOnboardError(() => saveEntity(formData));

      toast.success(
        `${entityName} ${isEditMode ? 'updated' : 'created'} successfully`
      );
      goToEditorPage();
    } catch (error) {
      console.error('Form submission error', error);
      toast.error('An error occurred');
    } finally {
      isCtrlSSaveMode.current = false;
      toast.dismiss(toastId);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleFormSubmission,
    validate: validateForm,
    enableReinitialize: true,
  });

  const getUpdatedSkills = useCallback(
    (updatedUserSkills) => {
      formik.setValues((prev) => ({ ...prev, included: updatedUserSkills }));
    },
    [formik]
  );

  useEffect(() => {
    const handleSave = (event: KeyboardEvent) => {
      const isCtrlOrMetaKey = isUserAgentWindows
        ? event.ctrlKey
        : event.metaKey;
      if (event.key === 's' && isCtrlOrMetaKey && !isCreateMode) {
        event.preventDefault();
        isCtrlSSaveMode.current = true;
        formik.handleSubmit();
      }
    };
    window.addEventListener('keydown', handleSave);
    return () => window.removeEventListener('keydown', handleSave);
  }, [isUserAgentWindows, isCreateMode, formik]);

  return {
    symbol,
    currency,
    exchangeRates,
    isCreateMode,
    isEditMode,
    getUpdatedSkills,
    handleFormSubmission,
    validateForm,
    mutations: {
      isMeetingCreationInProgress,
      isMeetingUpdationInProgress,
      isServiceCreationInProgress,
      isServiceUpdationInProgress,
    },
    utilities: { goToEditorPage, isFormPrimaryActionInProgress },
    formik,
    initialValues,
  };
};