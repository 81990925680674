import { Typography } from '@mui/material';
import React from 'react';

const UserSettingsHeading = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <Typography
      variant='h5'
      textAlign='left'
      sx={{
        fontWeight: 600,
        marginBottom: '0.3em',        
        fontSize: { xs: '1.7rem', md: '2.4rem' },
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};

export default UserSettingsHeading;

export const UserSettingsSecondaryHeading = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <Typography
      variant='body1'
      textAlign='left'      
      sx={{
        marginBottom: '0.6em',        
        fontSize: { xs: '1.5rem', md: '1.6rem' },
        ...style,
      }}
    >
      {children}
    </Typography>
  );
};