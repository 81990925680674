import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Link, Toolbar, Typography, TypographyProps } from '@mui/material';
import { Redirect, Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';

import ServiceOrders from './sub-component/service-order-managment/ServiceOrdersManagment';
import MeetingManagment from './sub-component/meeting-managment/MeetingManagment';
import { DashboardMenu } from 'store/features/dashboard/dashboardSlice';
import SitesManagment from './sub-component/sites-managment/SitesManagment';
import routeConstants from 'constants/routeConstants';
import Subscription from './sub-component/Subscription';
import PaymentCollection from './sub-component/PaymentCollection';
import DomainManagment from './sub-component/domain-managment/DomainManagment';
import { themeColor } from 'themes/AppThemeProvider/light';

const DashboardNestedRoutes: RouteProps<DashboardMenu>[] = [
  {
    path: 'sites',
    children: <SitesManagment />,
    exact: true,
  },
  {
    path: 'meetings',
    children: <MeetingManagment />,
    exact: true,
  },
  {
    path: 'orders',
    children: <ServiceOrders />,
    exact: true,
  },
  // {
  //   path: 'subscription',
  //   children: <Subscription />,
  //   exact: true,
  // },
  {
    path: 'payment-collection',
    children: <PaymentCollection />,
    exact: true,
  },
  {
    path: 'editor',
    exact: true,
  },
  {
    path: 'domain',
    children: <DomainManagment />,
    exact: true,
  }
];

const DashboardMain = () => {
  const { path } = useRouteMatch();

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: themeColor,
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container
        maxWidth='lg'
        sx={{
          minHeight: 'calc(100vh - 150px)',
          my: { xs: 2, md: 4 },
          p: { xs: '8px', md: '16px' },
        }}
      >
        <Switch>
          {DashboardNestedRoutes.map((route, i) => {
            return (
              <Route key={i} path={`${path}/${route.path}`}>
                {route.children}
              </Route>
            );
          })}
          <Redirect exact to={`${path}/sites`}>
            <SitesManagment />
          </Redirect>
        </Switch>
      </Container>
      <Copyright sx={{ pt: 4, alignSelf: 'flex-end', mb: { xs: 1, md: 2 } }} />
    </Box>
  );
};

export default DashboardMain;

function Copyright(props: TypographyProps) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href={routeConstants.LIVE_ROUTE}>
        ProcessUs Solutions Inc.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
