import { Error } from 'components/ErrorBoundary';

import useSelectLoginAccount from 'components/logins/SelectLoginAccount/hooks/useSelectLoginAccount';
import EmailLinkedUserAccounts from 'components/logins/SelectLoginAccount/sub-components/EmailLinkedUserAccounts/EmailLinkedUserAccounts';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';

export const SelectLoginAccount = ({
  email,
  redirectRoute,
  callback,
}: {
  email?: string;
  redirectRoute?: string;
  callback?: () => void;
}) => {
  const {
    clearLoggedInUser,
    isFetching,
    isSuccess,
    isUninitialized,
    isFetchingEmailLinkedAccounts,
    data: { emailLinkedUserAccounts },
    errors: { isErrorFetchingEmailLinkedAccounts },
  } = useSelectLoginAccount(email);
  let content;
  
  if (isFetchingEmailLinkedAccounts || isUninitialized || isFetching) {
    return <FrontsLoader />;
  } else if(isSuccess && emailLinkedUserAccounts) {
    content = (
      <EmailLinkedUserAccounts
        callback={callback}
        redirectRoute={redirectRoute}
        emailLinkedUserAccounts={emailLinkedUserAccounts}
        clearLoggedInUser={clearLoggedInUser}
      />
    );
  } else if(isErrorFetchingEmailLinkedAccounts) {
    content = (
      <Error />
    )
  } else {
    content = <></>
  }

  return content;
};

export default SelectLoginAccount;
