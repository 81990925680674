import { Box, Container, Stack } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from 'hooks/redux';
import useFacebookLogin from 'components/logins/hooks/useFacebookLogin';
import useAsyncStatus from 'hooks/useAsyncStatus';

import UserSignupInterface from 'views/OnboardingFlow/sub-components/stepperComponents/UserSignupLoginInterface/UserSignupLoginInterface';
import routeConstants from 'constants/routeConstants';
import googleLoginServices from 'lib/services/googleLoginServices';
import { gradientBgColor } from 'themes/AppThemeProvider/light';
import { PrivacyPolicyLink } from 'views/Home/utility-components/HomeLayout';
import { googleOauthsource, fbOauthSource, fbWindowNames } from 'views/Oauth';
import userServices from 'lib/services/userServices';
import loginTokenUtils from 'utilities/loginTokenUtils';
import {
  loadEditorProfileData,
} from 'components/logins/onLoginSuccessUtilities';
import { saveRouteToSessionStorage } from 'views/Home/SignIn';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import { useEffect, useState } from 'react';
import ConfirmationDialog from 'Popups/ConfirmationDialog';
import { resetLoggedinUserSlice, setLoggedInUser } from 'store/features/user/userSlice';
import { LoggedInUser } from 'types/user';

export interface SignUpSignInBackendError {
  message: string;
  code?: string;
  userWebsitesCount?: number;
  success?: boolean;
  name?: string;
  status?: number;
  stack?: string;
}

type SignUpSignInResponse = {[key in string] : any}

// util function
const determineUserRoute = (signUpResponse: SignUpSignInResponse) => {
  const user = signUpResponse.user;
  const isExistingUser = !!signUpResponse.isExistingUser;
  const code = signUpResponse.code || null;
  const errorMsg = signUpResponse.errorMsg || null;

  if(code === 'WEBSITE_LIMIT_EXCEEDED' && errorMsg) {
    toast.error(errorMsg, {autoClose: 3000, pauseOnHover: true})
  }

  if (!isExistingUser && !code) {
    return routeConstants.ONBOARDING_ROUTE;
  } else {
    return {
      pathname: '/select-login-account',
      state: {
        isFromVerifiedMagicLoginLink: true,
        email: user.email,
      },
    };
  }
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const windowConfig = {
  width: 500,
  height: 600,
  left: window.innerWidth / 2 - 500 / 2,
  top: window.innerHeight / 2 - 600 / 2,
};

const SignUp = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [authURL, setAuthURL] = useState('');
  const customLoginStatus = useAsyncStatus();
  const googleLoginStatus = useAsyncStatus();
  const fbLoginStatus = useAsyncStatus();
  const fbCancelToken = axios.CancelToken.source();
  const googleCancelToken = axios.CancelToken.source();

  const { facebookLoginUrl } = useFacebookLogin();

  useEffect(() => {
    console.log("SignUp component mounted");
  
    return () => {
      console.log("SignUp component unmounted");
      fbCancelToken.cancel('Facebook Request canceled');
      googleCancelToken.cancel('Google Request canceled');
    };
  }, []);
  
  const handleAuthError = (error: AxiosError, updateStatusFn: Function) => {
    const errorData =
      errorHandlingUtilities.getErrorData<SignUpSignInBackendError>(error);
    let errMsg: string = '';
    if (!errorData) {
      errMsg = 'Internal Server Error, Try again';
    }
    errMsg = errorData?.message || '';

    if (errMsg) {
      toast.error(errMsg, { autoClose: 4000, pauseOnHover: true });
    }

    updateStatusFn({ type: customLoginStatus.Actions.ERROR, payload: errMsg });
  };

  const handleAuthSuccess = async (
    signUpResponse: any,    
    updateStatusFn: Function,
    successMessage: string,
  ) => {    
    dispatch(resetLoggedinUserSlice());
    loginTokenUtils.setLocalStorageLoginToken(signUpResponse.token);
    dispatch(setLoggedInUser(signUpResponse.user as LoggedInUser));
    await loadEditorProfileData(signUpResponse.user._id);
    
    updateStatusFn({ type: customLoginStatus.Actions.SUCCESS, payload: successMessage });
    const route = determineUserRoute(signUpResponse);
    if (route) {
      saveRouteToSessionStorage(history);
      history.push(route);
    }
  };

  const handlePopupClosed = () => {
    // Reset loading states
    customLoginStatus.updateStatus({ type: customLoginStatus.Actions.RESET });
    googleLoginStatus.updateStatus({ type: googleLoginStatus.Actions.RESET });
    fbLoginStatus.updateStatus({ type: fbLoginStatus.Actions.RESET });
  };

  const receiveMessage = async (event: MessageEvent) => {
    if (event.origin !== routeConstants.EDITOR_ROUTE) return;

    if (event.data.source === googleOauthsource) {
      googleLoginStatus.updateStatus({ type: customLoginStatus.Actions.LOADING });
      await handleOAuth(
        event.data,
        providerEnum.google,
        userServices.googleSignUp,
        googleLoginStatus.updateStatus
      );
    } else if (event.data.source === fbOauthSource) {
      fbLoginStatus.updateStatus({ type: customLoginStatus.Actions.LOADING });
      await handleOAuth(
        event.data,
        providerEnum.facebook,
        userServices.fbSignUp,        
        fbLoginStatus.updateStatus,
        'FB_LOGIN',
        fbLoginStatus.updateStatus
      );
    }
  };
  const providerEnum = {
    google: 'Google',
    facebook: 'Facebook',
  }
  const handleOAuth = async (
    data: MessageEvent['data'],
    provider: string,
    signUpService: Function,
    updateStatusFn: Function,
    expectedState?: string,
    fallbackStatusFn?: Function
  ) => {
    const cancelToken = provider === providerEnum.google ? googleCancelToken : fbCancelToken;
    const urlParams = new URLSearchParams(data.payload);
    const authorizationCode = urlParams.get('code');
    const paramState = urlParams.get('state');
    if (expectedState && paramState !== expectedState) {
      console.error(`Invalid ${provider} Authorization Code`);
      fallbackStatusFn?.({
        type: customLoginStatus.Actions.ERROR,
        payload: `${provider} Sign up failed`,
      });
      return;
    }

    if (!authorizationCode) {
      console.error(`${provider} Authorization Code not found`);
      updateStatusFn({
        type: customLoginStatus.Actions.ERROR,
        payload: `${provider} Sign up failed`,
      });
      return;
    }

    try {
      const signUpResponse = await signUpService(authorizationCode, cancelToken);      
      await handleAuthSuccess(
        signUpResponse,
        updateStatusFn,
        `${provider} Sign up successful`,        
      );
    } catch (error) {
      handleAuthError(error as AxiosError, updateStatusFn);
    } finally {
      updateStatusFn({ type: customLoginStatus.Actions.RESET });
    }
  };

  const generateGoogleAuthUrl = async () => {
    window.removeEventListener('message', receiveMessage);

    try {
      const authURL = await googleLoginServices.generateGoogleAuthUrl();

      if (isSafari) {
        setAuthURL(authURL);
        setOpen(true);
      } else {
        openAuthWindow(authURL, 'Google Sign Up');
        window.addEventListener('message', receiveMessage, false);
      }
    } catch (error) {
      console.error('Error generating Google Auth URL', error);
      toast.error(error);
      throw new Error('Error generating Google Auth URL');
    }
  };

  const handleFBSignUp = () => {
    fbLoginStatus.updateStatus({ type: customLoginStatus.Actions.LOADING });
    window.removeEventListener('message', receiveMessage);
    openAuthWindow(facebookLoginUrl, fbWindowNames.signup);
    window.addEventListener('message', receiveMessage, false);
  };

  const handleGoogleSignUp = async () => {
    googleLoginStatus.updateStatus({ type: customLoginStatus.Actions.LOADING });
    try {
      await generateGoogleAuthUrl();
    } catch (error) {
      handleAuthError(error as AxiosError, googleLoginStatus.updateStatus);
    }
  };

  const handleCustomEmailSignUp = async (email: string) => {
    customLoginStatus.updateStatus({ type: customLoginStatus.Actions.LOADING });
    dispatch(resetLoggedinUserSlice());

    try {
      await userServices.generateOtp(email);
      customLoginStatus.updateStatus({
        type: customLoginStatus.Actions.SUCCESS,
        payload: 'Code sent successfully',
      });
      history.push(`/sign-up/otp?email=${email}`);
    } catch (err) {
      handleAuthError(err as AxiosError, customLoginStatus.updateStatus);
    }
  };

  const openAuthWindow = (url: string, windowName: string) => {
    if (isSafari) {
      // For Safari, use a new tab instead of a popup
      const newTab = window.open(url, windowName);

      if (newTab) {
        newTab.focus();
      } else {
        console.error('Failed to open new tab. Please allow popups for this site.');
        toast.error('Please allow popups for this site to continue.');
      }
    } else {
      // For other browsers, use the popup window
      const authWindow = window.open(
        url,
        windowName,
        `menubar=no,toolbar=no,width=${windowConfig.width},height=${windowConfig.height},top=${windowConfig.top},left=${windowConfig.left}`
      );

      if (authWindow) {
        authWindow.focus();
      } else {
        console.error('Failed to open popup window. Please allow popups for this site.');
        toast.error('Please allow popups for this site to continue.');
        return;
      }

      const interval = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(interval);
          handlePopupClosed();
        }
      }, 500);
    }
  };

  useEffect(() => {
    toast.dismiss();
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  return (
    <Box sx={{ background: gradientBgColor }}>
      <Container maxWidth='xl' sx={{ padding: { xs: 1, md: 0 } }}>
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{ minHeight: '100vh' }}
          spacing={2}
        >
          <UserSignupInterface
            type='sign-up'
            handleCustomEmailClick={handleCustomEmailSignUp}
            handleFbBtnClick={handleFBSignUp}
            handleGoogleBtnClick={handleGoogleSignUp}
            isFbLoading={fbLoginStatus.isLoading}
            isGoogleLoading={googleLoginStatus.isLoading}
            customLoginStatus={
              customLoginStatus.isLoading ? 'Sending Code...' : 'Sign Up'
            }
            emailLogin={{
              isError: customLoginStatus.isError,
              updateStatus: customLoginStatus.updateStatus,
              error: customLoginStatus.error,
              isLoading: customLoginStatus.isLoading,
            }}
          />
          <PrivacyPolicyLink />
        </Stack>
      </Container>

      <ConfirmationDialog
        open={open}
        handleAgreeBtnClick={() => {
          window.addEventListener('message', receiveMessage);
          openAuthWindow(authURL, 'Google Sign In');
          setOpen(false);
        }}
        handleDialogClose={() => setOpen(false)}
        title='Sign Up'
        content='Click on confirm to continue with Google Sign Up'
        isLoading={false}
        ariaDescribedby='sign-up-dialog'
        cancelText='Cancel'
        confirmText='Confirm'
        agreeBtnProps={{
          color: 'primary',
        }}
      />
    </Box>
  );
};

export default SignUp;
