import { AutoCompleteGuestLocationDetails } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import { SPM_UI } from 'types/utility';

export enum MeetingTypes {
  Virtual = 'MEETING-V',
  Physical = 'MEETING-P',
}

export enum VirtualMeetingHostOptedOptions {
  GUEST_PHONE_SESSION_BOOKING = 'GUEST-PHONE-SESSION-BOOKING',
  HOST_LINK_PHONE_SESSION_SCHEDULED = 'HOST-LINK-PHONE-SESSION-SCHEDULED',
  HOST_LINK_PHONE_ALL_SESSIONS = 'HOST-LINK-PHONE-ALL-SESSIONS',
}

export enum PhysicalMeetingHostOptedOptions {
  GUEST_LOCATION_CHECKOUT = 'GUEST-LOCATION-CHECKOUT',
  HOST_LOCATION_PHONE_SESSION_SCHEDULED = 'HOST-LOCATION-PHONE-SESSION-SCHEDULED',
  HOST_LOCATION_ALL_SESSIONS = 'HOST-LOCATION-ALL-SESSIONS',
}

export enum MeetingTentatitveAvailabilityOptions {
  ALWAYS_TENTATITVELY_AVAILABLE = 'ALWAYS-TENTATITVELY-AVAILABLE',
  LIMIT_TENTATITVE_AVAILABILITY = 'LIMIT-TENTATITVE-AVAILABILITY',
}

type MeetingAllSessionsData<MeetingType> = {
  virtual?: MeetingType extends MeetingTypes.Virtual ? string : never;
  physical?: MeetingType extends MeetingTypes.Physical ? string : never;
};

type MeetingOptedOptions<MeetingType> = MeetingType extends MeetingTypes.Virtual
  ? VirtualMeetingHostOptedOptions
  : PhysicalMeetingHostOptedOptions;

export type MeetingHostDetails<MeetingType> = {
  optedOption: MeetingOptedOptions<MeetingType>;
  allSessionsData: MeetingAllSessionsData<MeetingType>; // TODO: Remove this key
};

export type VirtualMeeting = Meeting<MeetingTypes.Virtual>;
export type PhysicalMeeting = Meeting<MeetingTypes.Physical>;

export type PhysicalOrVirtualMeeting = VirtualMeeting | PhysicalMeeting;

export type NewMeeting<MeetingType extends MeetingTypes> = Omit<
  Meeting<MeetingType>,
  '_id'
>; // type while creating new meetings
export type NewVirtualMeeting = NewMeeting<MeetingTypes.Virtual>;
export type NewPhysicalMeeting = NewMeeting<MeetingTypes.Physical>;
export type PhysicalOrVirtualNewMeeting = NewVirtualMeeting | NewPhysicalMeeting;

export type PhysicalOrVirtualMeetingList = PhysicalOrVirtualMeeting[];

export type HostGuestMeetingDetails = Record<'link' | 'meetingExtraInstructions', string>;

export type Meeting<MeetingType extends MeetingTypes> = {
  readonly _id: string;
  readonly userId: string;
  isDeleted: boolean;
  type: MeetingType;
  status: 'active' | 'inactive';
  showLinkOrLocationDetailsToGuest: boolean,
  isGroup: boolean;
  title: string;
  desc: string;
  isPaid: boolean;
  price?: number;
  included: string[];
  img: string;
  time: number; // in ms
  ui: SPM_UI;
  hostDetails: MeetingHostDetails<MeetingType>; // host info opted option
  tentativeAvilabitity: MeetingTentatitveAvailabilityOptions;
  guestMeetingLocationInstructions?: string; // Only for MEETING-P && host opted option -> GUEST-LOCATION-CHECKOUT
  hostGuestMeetingDetails?: HostGuestMeetingDetails;
  inPersonMeetingGuestLocationDetails?: AutoCompleteGuestLocationDetails | undefined; // for MEETING-P only with google autocomplete  
} & MeetingAvailability;

export type MeetingAvailability = {
  bookingUpTo?: number;
  repeatebleTentativeHours?: RepeatebleTentativeHours[];
  nonRepeatebleTentativeHours?: TimeStampObject[];
  limitTentativeAvailability: boolean;
}

export type RepeatebleTentativeHours = {
  from: number;
  to: number;
};
export type TimeStampObject = RepeatebleTentativeHours;

export type TentativeTimes = RepeatebleTentativeHours;

// types for updating meetings
export type DetailsOfMeetingToBeUpdated = Pick<
  PhysicalOrVirtualMeeting,
  '_id' | 'userId' | 'isDeleted'
>;

export type CanBeUpdatedVirtualMeetingContext = Omit<
  VirtualMeeting,
  keyof DetailsOfMeetingToBeUpdated
>;

export type CanBeUpdatedPhysicalMeetingContext = Omit<
  PhysicalMeeting,
  keyof DetailsOfMeetingToBeUpdated
>;

export type CanBeUpdateMeetingContext =
  | CanBeUpdatedVirtualMeetingContext
  | CanBeUpdatedPhysicalMeetingContext;
