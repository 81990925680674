import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormikConfig, useFormik } from 'formik';
import { toast } from 'react-toastify';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import { initialEditUserDetails } from 'components/EditUserDetailsLatest/formInitialization';
import generalUtilities from 'utilities/generalUtilities';
import EUDFormValidationSchema from 'components/EditUserDetailsLatest/EUDFormValidationSchema';
import EUDUtilsFns from '../EUDUtilsFns';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { useUpdatedEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';

type EditUserDetailsFormSubmit = FormikConfig<EditUserDetailsForm>['onSubmit'];

const useEditUserDetails = () => {
  const history = useHistory();
  const { userIdInReduxUserSlice: editorId } = useGetLoggedInUser();
  const { cachedLoggedInUser: cacheUser } = useGetLoggedInUser();
  const [udpateEditor] = useUpdatedEditorByIdMutation();
  const isUserAgentWindows = generalUtilities.isUserAgentWindows();

  const saveUser = React.useCallback(
    async (
      values: Parameters<EditUserDetailsFormSubmit>[0],
      resetForm: () => void,
      isSaveKeyFunctionality: boolean = false
    ) => {
      const localValues = { ...values };
      const loadingToastId = toast.loading('Saving details');

      try {
        // delete mutliple images
        if (values.deletedImgs && Object.values(values.deletedImgs).length > 0) {
          try {
            const { userBgImage, userDashboardImg, userFavIcon } =
              await EUDUtilsFns.deleteProfileMutltipleImgs(values.deletedImgs);

            if (userBgImage === '') localValues.userBgImage = '';
            if (userDashboardImg === '') localValues.userDashboardImg = '';
            if (userFavIcon === '') localValues.userFavIcon = '';
          } catch (error) {
            console.log(error);
          }
        }

        // upload multiple images
        if (values.files && Object.values(values.files).length > 0) {
          try {
            const { userBgImage, userDashboardImg, userFavIcon } =
              await EUDUtilsFns.uploadProfileMultipleFile(values);

            localValues.userBgImage = userBgImage;
            localValues.userDashboardImg = userDashboardImg;
            localValues.userFavIcon = userFavIcon;
          } catch (error) {
            toast.error('Something went wrong, Please try again later', {
              autoClose: 2000
            });
            console.log(error);
          }
        }

        // update editor
        await udpateEditor({
          editorId,
          loggedInUser: {
            name: localValues.name,
            emailPublic: localValues.emailPublic,
            url: localValues.userUrl,
            title: localValues.title,
            searchable: localValues.searchable,
            isAuthTagVisible: localValues.isAuthTagVisible,
            tags: localValues.tags,
            socialLinks: localValues.socialLinks,

            // imgs
            img: localValues.userDashboardImg,
            faviconImg: localValues.userFavIcon,
            userBgImage: localValues.userBgImage,

            //style
            profileStyle: { ...cacheUser?.profileStyle, ...localValues.profileStyle },
            
            // contact details
            phone: localValues.phoneNumber,            
            whatsapp: {
              num: localValues.whatsAppNumber,
              countryCode: localValues.whatsAppCountryCode,
            },
            address: localValues.address,
            place_id: localValues.place_id,
            lat: localValues.lat,
            lng: localValues.lng,
            country: localValues.country,
            state: localValues.state,
            city: localValues.city,
            addressComponents: localValues.addressComponents,
          },
        }).unwrap();
        toast.success('Details saved', { autoClose: 500 });
        if (isSaveKeyFunctionality) {
          return;
        }
        resetForm();
      } catch (error) {
        toast.error('Error saving details');
      } finally {
        toast.dismiss(loadingToastId);
      }
    },
    [editorId, udpateEditor, cacheUser?.profileStyle]
  );

  // ------- form saving
  const handleFormSubmit: EditUserDetailsFormSubmit = async (values, formikHelpers) => {
    await saveUser(values, formikHelpers.resetForm);
    const userUrl = cacheUser?.url;

    if (userUrl) {
      history.push({
        pathname: `/${userUrl}`,
        state: {
          isFromEditUserPage: true,
        },
      });
    } else {
      history.goBack();
    }
  };

  const formik = useFormik<EditUserDetailsForm>({
    initialValues: initialEditUserDetails,
    onSubmit: handleFormSubmit,
    validationSchema: EUDFormValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
  });

  const { values, setValues, validateForm, resetForm, setTouched } = formik;

  // syncing form with cache loggedInUser
  React.useEffect(() => {
    if (!cacheUser) return;
    setValues({
      name: cacheUser.name,
      email: cacheUser.email,
      emailPublic: cacheUser.emailPublic ?? false,
      phoneNumber: cacheUser.phone,
      userUrl: cacheUser.url,
      searchable: cacheUser.searchable,
      title: cacheUser.title,
      isAuthTagVisible: cacheUser.isAuthTagVisible,
      whatsAppNumber: cacheUser.whatsapp?.num ?? '',
      whatsAppCountryCode: cacheUser.whatsapp?.countryCode ?? 1,
      tags: cacheUser.tags,
      userFavIcon: cacheUser.faviconImg,
      userDashboardImg: cacheUser.img,
      socialLinks: cacheUser.socialLinks ?? [],
      place_id: cacheUser.place_id ?? '',
      address: cacheUser?.address ?? '',
      lat: cacheUser.lat ?? 0,
      lng: cacheUser.lng ?? 0,
      country: cacheUser.country ?? '',
      state: cacheUser.state ?? '',
      city: cacheUser.city ?? '',
      userBgImage: cacheUser.userBgImage,
      profileStyle: cacheUser.profileStyle,
    });
  }, [cacheUser, setValues]);

  // ctrl + s functionality
  React.useEffect(() => {
    async function handleSave(event: KeyboardEvent) {
      if (event.key === 's' && (isUserAgentWindows ? event.ctrlKey : event.metaKey)) {
        event.preventDefault();
        const errors = await validateForm();
        const anyErrors = Boolean(Object.keys(errors).length);
        if (!anyErrors) {
          await saveUser(values, resetForm, true);
          setTouched({});
        }
      }
    }
    window.addEventListener('keydown', handleSave);
    return () => window.removeEventListener('keydown', handleSave);
  }, [isUserAgentWindows, values, saveUser, validateForm, resetForm, setTouched]);

  return {
    formik,
    cacheUser,
  };
};

export default useEditUserDetails;
