import { Box, Stack, Typography } from '@mui/material';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import {
  style_ProfileCard_bgWave,
} from 'components/ProfileCard/ProfileCard.style';
import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import LayoutContainer from 'components/Containers/LayoutContainer';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import LayoutWrapper from './reusable-components/LayoutWrapper';

const Layout2 = () => {
  const {
    currentTheme,

    tagsArray,
    tagsArrayLength,

    closeIconPopup,
    userAuthType,
    userName,
    userTitle,
    userProfileLink,
    isAuthTagVisible,
    showIconPopup,
    showIconPopupController,
    user,

    layout,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,

    buttonStyle,

    showImage,
    userImage,
    bgImageWithColorURL,
    bgImage,
    userBgImage,
    bg,

    isMeetingAndServiceAvailable,
    handleImageError,
    storeHeading,

    isNeedDiv,
    userNameStyling,
    isMobile,
    title,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,

    displayTags,
    displaySocialLinks,

    colorCode,

    userNameColor,
    titleColor,
  } = useProfileCard();

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box component='section' position='relative' sx={isNeedDiv ? {} : bg}>
      {isNeedDiv && !userBgImage && (
        <Box
          className='bg-wave'
          sx={{ ...style_ProfileCard_bgWave(bg), zIndex: -1 }}
        ></Box>
      )}
      <LayoutWrapper
        sx={{
          ...bgImage,
          minHeight: {
            xs: 'calc(100vh - 100px)',
            md: 'calc(100vh - 100px)',
          },
        }}
      >
        <LayoutContainer >
          <ProfileEditAndDesignBtn sx={{ my: 0 }} />
          <Stack
            sx={{
              maxWidth: '100ch',
              justifyContent: 'center',
              alignItems: 'center',
              m: 'auto',
              textAlign: 'center',
            }}
            useFlexGap
            spacing={2}
          >
            <Typography variant='h1' fontSize={{ xs: 40, md: 70 }} color={userNameColor}>
              {userName}
            </Typography>
            <Typography variant='h5' color={titleColor}>
              {userTitle}
            </Typography>

            {displaySocialLinks !== false && (
              <Box display='inline-block'>
                <SocialIconsList
                  emailPublic={emailPublic}
                  email={email}
                  socialLinks={socialLinks}
                  whatsapp={whatsapp}
                  address={address}
                  phone={phone}
                  styling={{ justifyContent: 'center', display: 'inline-flex' }}
                  showOnlyIcon
                />
              </Box>
            )}

            {displayTags !== false && (
              <Box>
                <Tags
                  tagsArray={tagsArray}
                  tag={tag}
                  global={global}
                  sx={{ justifyContent: 'center', display: 'inline-flex' }}
                />
              </Box>
            )}
            {isMeetingAndServiceAvailable && storeHeading && (
              <CallToActionBtn
                user={user}
                buttonStyle={buttonStyle}
                storeHeading={storeHeading}
              />
            )}
          </Stack>
        </LayoutContainer>
      </LayoutWrapper>

      {/* sub title */}
      <ProfileSubtitle
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        subTitle={user.subTitle}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout2;
