import { format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import moment from 'moment';

import ContentHeading from 'views/guestSession/GuestSession/utility-components/ContentHeading';
import { SessionDetailsProps } from 'views/guestSession/GuestSession/sub-components/SessionDetails/SessionDetailsTypes';
import TextWithCopyBtn from 'views/guestSession/GuestSession/sub-components/SessionDetails/reusable-components/TextWithCopyBtn';
import useValidationContextProvider from 'views/guestSession/ValidateGuestSession/hooks/useValidationContextProvider';
import { getStatusForGuest } from './reusable-components/getStatusForGuest';

const SessionDetails = ({
  status,
  startAt,
  endAt,
  timezone,
  participant,
  guestMeetingPreparationText,
  inPersonMeetingGuestLocationDetails,
  onlineMeetingLink = '',
  hostMeetingInstructions = '',
}: SessionDetailsProps) => {
  const meetingGoogleMapLink = inPersonMeetingGuestLocationDetails?.googleMapsLocationUrl || '';
  const meetingAddress = inPersonMeetingGuestLocationDetails?.address || ''; // for Inperson meetings
  const displayTime = `${getTime(startAt, timezone.value)} to ${getTime(endAt, timezone.value)}`;
  const isShowTime = status !== 'host-propose-new-times';
  const { isSessionCancelled, isSessionRejected } = useValidationContextProvider();
  const isSessionCancelledOrRejected = isSessionCancelled || isSessionRejected;
  const { backgroundColor, color, content } = getStatusForGuest(status);
  return (
    <Box>
      <Typography variant='h3' fontWeight={600} textAlign='left' mb='0.7em'>
        session details
      </Typography>
      {/* ----------------- status  */}
      <DataContainer heading='status' disablePaddingTop>
        <Typography color={color} bgcolor={backgroundColor} sx={{ transition: 'all .3s ease' }}>
          {content}
        </Typography>
      </DataContainer>
      {isShowTime && (
        <>
          {/* ----------------- date */}
          <DataContainer heading='when'>{getDate(startAt, timezone.value)}</DataContainer>
          {/* ----------------- time */}
          <DataContainer heading='time'>{displayTime}</DataContainer>
          {/* -------------- time zone  */}
          <DataContainer heading='time zone'>{timezone.label}</DataContainer>
        </>
      )}

      {/* ----------------- online meeting link */}
      {!isSessionCancelledOrRejected && (
        <DataContainer heading='where' isHidden={!Boolean(onlineMeetingLink)}>
          <TextWithCopyBtn
            copyText={onlineMeetingLink}
            copySuccessMsg='Link copied!'
            copyErrorMsg='Error while copying link'
            link
            sx={{
              fontSize: {
                xs: '1.5rem',
                sm: '1.8rem',
              },
            }}
          />
        </DataContainer>
      )}
      {/* -------------- Address */}
      <DataContainer
        dataDirection={meetingAddress.length > 15 ? 'column' : 'row'}
        isHidden={!Boolean(meetingGoogleMapLink && meetingAddress)}
        heading='Address'
      >
        <TextWithCopyBtn
          link
          copyText={meetingGoogleMapLink}
          linkValue={meetingAddress}
          copySuccessMsg='Link copied!'
          copyErrorMsg='Error while copying meeting location link'
          sx={{
            fontSize: {
              xs: '1.5rem',
              sm: '1.8rem',
            },
          }}
        />
      </DataContainer>
      {/* -------------- host meeting instructions */}
      <DataContainer heading='host instructions to guest' isHidden={!hostMeetingInstructions}>
        {hostMeetingInstructions}
      </DataContainer>
      {/* ----------------- participant details ---------------------------- */}
      <DataContainer heading='participant name'>{participant.name}</DataContainer>
      {participant.phoneNumber && (
        <DataContainer heading='participant phone'>{participant.phoneNumber}</DataContainer>
      )}
      {participant.email && (
        <DataContainer heading='participant email'>{participant.email}</DataContainer>
      )}
      {/* ----------------- Guest instructions */}
      {guestMeetingPreparationText && (
        <DataContainer heading='participant instructions to host' dataDirection='column'>
          {guestMeetingPreparationText}
        </DataContainer>
      )}
    </Box>
  );
};

export default SessionDetails;

// sub components
type DataContainerProps = {
  heading: string;
  children: number | string | JSX.Element;
  disablePaddingTop?: boolean;
  dataDirection?: 'row' | 'column';
  isHidden?: boolean;
};

function DataContainer({
  heading,
  children,
  isHidden = false,
  dataDirection = 'row',
  disablePaddingTop = false,
}: DataContainerProps) {
  if (isHidden) return <></>;
  const isChildrenTypeString = typeof children === 'string';

  let content;

  if (isChildrenTypeString) {
    content = <Typography variant='body1'>{children}</Typography>;
  } else {
    content = (
      <Box
        sx={{
          '& *': {
            fontSize: '1.5rem',
          },
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Stack
      direction={{
        xs: 'column',
        sm: dataDirection,
      }}
      alignItems={{
        xs: 'flex-start',
        sm: dataDirection === 'row' ? 'center' : 'flex-start',
      }}
      flexWrap='wrap'
      spacing={{
        xs: 0.9,
        sm: 1.5,
      }}
      sx={{
        pt: disablePaddingTop
          ? 0
          : {
              xs: '2rem',
              sm: '3rem',
            },
      }}
    >
      <ContentHeading text={heading} />
      {content}
    </Stack>
  );
}

function getTime(date: number, timezone: string) {
  const timezoneOffsetInMS = getTimezoneOffset(timezone);
  const bookingTime = moment(date);
  let bookingTimestamp = bookingTime
    .subtract({ minute: bookingTime.utcOffset() })
    .add({ millisecond: timezoneOffsetInMS })
    .valueOf();

  return format(bookingTimestamp, 'h:mm aa');
}

function getDate(date: number, timezone: string) {
  const timezoneOffsetInMS = getTimezoneOffset(timezone);
  const bookingTime = moment(date);
  let bookingTimestamp = bookingTime
    .subtract({ minute: bookingTime.utcOffset() })
    .add({ millisecond: timezoneOffsetInMS })
    .valueOf();

  return format(bookingTimestamp, 'd-MM-yyyy');
}

// session status maps
type GenericObjInterface = {
  [key: string]: string;
};

/* HOST  ------------------  DEV -------------------  GUEST 
  Pending --------------- pending -----------------  Pending
  Payment-waiting -------- payment-waiting --------- waiting for payment
  Confirm ---------

  
  HOST - 
  pending,
  accepted,
  rejected,
  proposed new time,
  payment-waiting -> confirm.
*/

export const statusMessage: GenericObjInterface = {
  'host-propose-new-times': "Waiting for client's confirmation on time",
  'pending-guest-select-new-time': "Waiting for client's confirmation on time",
  'pending-guest-payment': 'Waiting for client to make payment',
  cancelled: 'Client has cancelled the session',
};

export const guestStatusMessage: GenericObjInterface = {
  'host-propose-new-times': 'Waiting for your confirmation on time',
  'pending-guest-select-new-time': 'Waiting for your confirmation on time',
  'pending-guest-payment': 'Waiting for you to make payment',
  cancelled: 'you have cancelled the session',
};

export const cancelledStatuses = ['cancelled', 'denied', 'reject', 'rejected'];
