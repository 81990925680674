import { Box, FormHelperText } from '@mui/material';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';
import { useFormikContext } from 'formik';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import BoldSpan from 'components/utilities/BoldSpan';
import Question from './reusable-components/Question';
import { inputBgColor } from 'themes/AppThemeProvider/light';

const TimezoneOnboarding = () => {
  const { values, setValues, errors, touched, } =
    useFormikContext<StepperState>();
  const isTimezoneHasErrors = touched.timeZone && errors.timeZone;

  const handleTimeZoneChange = async (timezone: ITimezoneOption) => {
    setValues((prev) => {
      return { ...prev, timeZone: timezone.value, userGMTOffset: timezone.offset };
    })
  };

  return (
    <Box
      sx={{
        '& .timezon': {
          backgroundColor: inputBgColor,
          mt: 2,
          '& > div': {
            borderColor: (theme) =>
              isTimezoneHasErrors ? theme.palette.error.main : 'none',
            backgroundColor: inputBgColor,
          },
        },
      }}
    >
      <Question
        required
        currentStep={3}
        helperText='Choose a timezone to align calendar and content'
      >
        <>
          Select a{' '}
          <BoldSpan>
            <>timezone</>
          </BoldSpan>
        </>
      </Question>
      <TimezoneSelect
        className='timezon'
        value={values.timeZone || ''}
        onChange={handleTimeZoneChange}
      />
      {isTimezoneHasErrors && (
        <FormHelperText className='Mui-error'>{errors.timeZone}</FormHelperText>
      )}
    </Box>
  );
};

export default TimezoneOnboarding;
