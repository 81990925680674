import serverApi from 'lib/apiConfig';
import axios from 'axios';
import { AddPhysicalOrVirtualService, PhysicalOrVirtualService } from 'types/service';
import { UpdateServiceActionParams } from 'store/features/services/serviceActions';
import { ServiceCheckoutFormValues } from 'views/ServiceCheckout/ServiceCheckout';

export type RemoveServiceResponse = 'service removed' | 'error while removing service';

type AddServiceResponse = {
  isSuccessful: boolean;
  newServiceId?: string;
};

async function addService(
  service: AddPhysicalOrVirtualService
): Promise<AddServiceResponse> {
  try {
    console.log('request sent for creating service');
    const res = await serverApi.v2.post(`/services`, service);
    const isSuccessful = res.status === 200;
    const resData: AddServiceResponse = res.data;
    if (isSuccessful) {
      return resData;
    }
    return {
      isSuccessful,
    };
  } catch (error) {
    console.error('[ERROR]: error occurred while adding serice');
    throw Error('error while adding service');
  }
}

async function getUserServices(userId: string): Promise<PhysicalOrVirtualService[]> {
  try {
    const res = await serverApi.v2.get(`/services/user/${userId}`);
    const userServices = await res.data;
    return userServices;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error msg: ', error);
      throw error.message;
    }
    throw error;
  }
}

async function getServiceById(serviceId: string) {
  const res = await serverApi.v2.get(`services/${serviceId}`);
  return res.data;
}

async function updateService(
  serviceUpdatedContext: UpdateServiceActionParams[0],
  serviceId: PhysicalOrVirtualService['_id']
): Promise<boolean> {
  const res = await serverApi.v2.put(`/services/${serviceId}`, serviceUpdatedContext);
  if (res.status === 200 && res.data.isSuccessful) return true;
  return false;
}

// NOTE: using to sort services: Outdated code
async function updateServices(services: PhysicalOrVirtualService[]): Promise<boolean> {
  const res = await serverApi.v2.post(`services/sort-service`, services);
  console.log('response in updateServices in file serviceServies: ', res);
  return res.data;
}

async function removeServiceById(
  serviceId: PhysicalOrVirtualService['_id']
): Promise<boolean> {
  const res = await serverApi.v2.delete(`/services/${serviceId}`);
  const resData = res.data;
  console.log('resData in removeServiceById');
  console.log(resData ?? 'resData is undefined');
  if (res.status === 200) return true;
  return false;
}

async function serviceCheckout(
  serviceId: string,
  customerDetails: ServiceCheckoutFormValues,
  promiseActions?: {
    onSuccess?: (checkoutSessionUrl: string) => void;
    onError?: (errorMsg: string) => void;
  }
) {
  try {
    const res = await serverApi.public.post(`services/${serviceId}/checkout`, {
      customerDetails,
    });
    const checkoutSessionUrl = (await res.data) as string;
    promiseActions?.onSuccess?.(checkoutSessionUrl);
  } catch (error) {
    throw error;
  }
}

const serviceServices = {
  addService,
  getUserServices,
  getServiceById,
  updateService,
  updateServices,
  removeServiceById,
  serviceCheckout,
};

export default serviceServices;
