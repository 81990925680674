import { RootState } from 'store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebsiteExtractorModule } from 'store/features/websiteExtractor/websiteExtractorTypes';

const INITIAL_STATE: WebsiteExtractorModule = {
  showStickyElementsDrawer: false,
  stickyElements: [],
};

const websiteExtractorSlice = createSlice({
  name: 'websiteExtractorModule',
  initialState: INITIAL_STATE,
  reducers: {
    toggleShowStickyElementsDrawer: (
      state: WebsiteExtractorModule,
      action: PayloadAction<boolean>
    ) => {
      state.showStickyElementsDrawer = action.payload;
    },
    setStickyElements: (
      state: WebsiteExtractorModule,
      action: PayloadAction<WebsiteExtractorModule['stickyElements']>
    ) => {
      state.stickyElements = action.payload;
    },
    addStickyElement: (
      state: WebsiteExtractorModule,
      action: PayloadAction<WebsiteExtractorModule['stickyElements'][number]>
    ) => {
      state.stickyElements.push(action.payload);
    },
    deleteStickyElementWithUrl: (
      state: WebsiteExtractorModule,
      action: PayloadAction<Record<'url', string>>
    ) => {
      const { url: urlToFind } = action.payload;
      // ✔️ finding url index
      let toBeDeletedIndex = -1;
      for (const [idx, obj] of state.stickyElements.entries()) {
        if (obj.url === urlToFind) {
          toBeDeletedIndex = idx;
          break;
        }
      }
      // ✔️ deleting element
      if (toBeDeletedIndex > -1) {
        state.stickyElements.splice(toBeDeletedIndex, 1);
      }
    },
    resetWebsiteExtractorModule: (
      state: WebsiteExtractorModule,
      action: PayloadAction<void>
    ) => {
      return INITIAL_STATE;
    },
  },
});

const websiteExtractorSliceReducer = websiteExtractorSlice.reducer;
export default websiteExtractorSliceReducer;

export const {
  addStickyElement,
  deleteStickyElementWithUrl,
  setStickyElements,
  toggleShowStickyElementsDrawer,
  resetWebsiteExtractorModule,
} = websiteExtractorSlice.actions;

// slice selectors
export function selectWebsiteExtractorModule(state: RootState) {
  return state.persistedReducer.websiteExtractorModule;
}

export function selectWEStickyElems(state: RootState) {
  const websiteExtractorModule = selectWebsiteExtractorModule(state);
  return websiteExtractorModule.stickyElements;
}

export function selectShowStickyElementsDrawer(state: RootState) {
  const websiteExtractorModule = selectWebsiteExtractorModule(state);
  return websiteExtractorModule.showStickyElementsDrawer;
}
