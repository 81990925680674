import { Box, Stack, useMediaQuery } from '@mui/material';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import { useGetPortfoliosByUserIdQuery } from 'store/rtkqFeatures/api/portfolioExtendedApi';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import Portfolio from '../Portfolio/Portfolio';

const PortfolioGalleryMode = ({ userId }: { userId: string }) => {
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const isPhone = useMediaQuery('(max-width:600px)');
  // get data from cache
  const { portfolioSylings } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      portfolioSylings: themes
        ? themes[localCurrentThemeIndex].portfolios
        : initialThemeState.portfolios,
    }),
  });
  const { data } = useGetPortfoliosByUserIdQuery(userId); //fetching from cache.
  const userPortfolios = data?.gallery ?? [];
  const isPortfolioLength1Or2 =
    userPortfolios.length === 1 || userPortfolios.length === 2;

  return (
    <Box>
      {/* <ImageList */}
      <Stack
        direction={'row'}
        flexWrap={'wrap'}
        rowGap={{xs: 5, md: 6}}
        columnGap={{xs: 2, md: 4}}
        justifyContent={
          isPhone ? 'space-between' : isPortfolioLength1Or2 ? 'center' : 'space-between'
        }
        sx={{
          width: '100%',
          height: 'auto',
          maxWidth: '100%',
          textAlign: 'center',
        }}
        className='accordian-images'        
      >
        {userPortfolios.map((_, index) => {
          // return <></> on last array
          // if (index === array.length - 1) return <></>;
          // <ImageListItem key={index} >
          return <Box
            flexBasis={isPhone ? '100%' : '28%'}            
            sx={{
              '& .MuiPaper-root': {
                maxWidth: '100%',
              },
            }}
          >
            <Portfolio
              key={index}
              portfolioIndex={index}
              portfolioSylings={portfolioSylings}
            />
          </Box>
          // </ImageListItem>
          })}

        {/* <ImageListItem>
        <NavLink to='/edit-portfolio/-1'>
          <Box
            component='img'
            title='add new portfolio'
            src={'https://res.cloudinary.com/salesfronts/image/upload/v1672175205/add_nfl7ch.svg'}
            alt='add-portfolio-img'
            sx={{
              maxWidth: {
                xs: '100%',
                lg: '316px',
              },
              m: { xs: '0 auto', md: 'auto' },
              transition: 'filter 0.2s ease',
              '&:hover': {
                cursor: 'pointer',
                filter: 'brightness(0.8)',
              },
              objectFit: 'contain',
            }}
          />
        </NavLink>
      </ImageListItem> */}
      </Stack>
    </Box>
  );
};

export default PortfolioGalleryMode;
