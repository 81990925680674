import { useEffect } from 'react';
import Frame from '../styled-components/Frame';
import { Box, Stack } from '@mui/material';
import InputProfileImg from './sub-components/InputProfileImg';
import InputBgImage from './sub-components/InputBgImage';
import InputName from '../InputName';
import { onboardingImgForDifferentSteps } from '../../OnboardingFlowStepper/OnboardingFlowStepper';
import useGetTransformedImgOnboardingSrc from 'views/OnboardingFlow/hooks/useGetTransfromedOnboardingSrc';
import InputOccupation from '../InputOccupation';
import Question from '../reusable-components/Question';
import BoldSpan from 'components/utilities/BoldSpan';

const ProfileBackgroundMedia = () => {
  const imgSrc = useGetTransformedImgOnboardingSrc(onboardingImgForDifferentSteps[1]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'completion',
      app_name: 'fronts.ai',
      screen_name: 'onboard_p2_personal_details',
    });

    window.dataLayer.push({
      event: 'screen_view',
      app_name: 'fronts.ai',
      screen_name: 'onboard_p3_profile_background_media',
    });
  }, []);

  return (
    <Frame imgSrc={imgSrc}>
      <Stack gap={{ xs: 4, md: 7.5 }} direction='column'>
        <InputOccupation />
        <InputProfileImg />
        <InputBgImage />
      </Stack>
    </Frame>
  );
};

export default ProfileBackgroundMedia;
