import { Box, Divider, SxProps } from '@mui/material';
import React from 'react';

import UserSettingsHeading, { UserSettingsSecondaryHeading } from './UserSettingsHeading';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';

type ThisProps = {
  primaryHeading: React.ReactNode;
  children: React.ReactNode;
  secondaryHeading?: React.ReactNode;
  sx?: SxProps;
  isLoading?: boolean;
};

const UserSettingsSection = ({
  children,
  primaryHeading,
  secondaryHeading,
  sx,
  isLoading,
}: ThisProps) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        position: 'relative',
        userSelect: isLoading ? 'none' : 'auto',
        p: { xs: 2, md: 3 },
        pb: 0,
        ...sx,
      }}
    >
      {isLoading && <SectionLoader isLoading={isLoading} />}
      <UserSettingsHeading>{primaryHeading}</UserSettingsHeading>
      <Divider sx={{ mb: { xs: 1, md: 2 }, mt: { xs: 0.5, md: 1.5 } }} />
      <Box>
        {secondaryHeading && (
          <UserSettingsSecondaryHeading>{secondaryHeading}</UserSettingsSecondaryHeading>
        )}
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default UserSettingsSection;
