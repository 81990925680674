import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export enum ModalActionType {
  DELETE_PORTFOLIO = 'delete-portfolio',
  EDIT_PORTFOLIO = 'edit-portfolio',
  ADD_PORTFOLIO = 'add-portfolio',
  TENTATIVE_AVAILABILITY = 'tentative-availability',
}

export type ModalModule = {
  showModal: boolean;
  modalActionType?: ModalActionType;
  modalData?: any;
  index?: number;
};

const INITIAL_STATE: ModalModule = {
  showModal: false,
};

const modalSlice = createSlice({
  name: 'modalModule',
  initialState: INITIAL_STATE,
  reducers: {
    toggleModal: (state: ModalModule, action: PayloadAction<ModalModule>) => {
      const { showModal, modalData, modalActionType, index } = action.payload;
      state.showModal = showModal;
      state.modalActionType = modalActionType;
      state.modalData = modalData;
      state.index = index;
    },
    resetModalSlice: () => {
      return INITIAL_STATE;
    },
  },
});

const modalSliceReducer = modalSlice.reducer;
export default modalSliceReducer;

export const { toggleModal, resetModalSlice } = modalSlice.actions;

// slice selectors
export const selectModalModule = (state: RootState) => state.persistedReducer.modalModule;
