import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { updateLocalCurrentThemeIndex } from 'store/features/localThemeSelector/localThemeSelectorSlice';
import useCurrentThemeSelector from 'store/rtkqFeatures/api/hooks/useCurrentThemeSelector';
import {
  INITIAL_WEBSITE_FONT_FAMILY,
  udpateWebsiteFontFamily,
  WebsiteFontFamily,
} from 'store/features/websiteFontFamily/websiteFontFamilySlice';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { updateLocalCurrentLayoutIndex } from 'store/features/localLayoutState';

const useEditor = () => {
  const reduxDispatch = useAppDispatch();
  const { cachedLoggedInUser: loggedInUser } = useGetLoggedInUser();
  const { cacheCurrentThemeIndex } = useCurrentThemeSelector();
  const cacheWebsiteFontFamily = loggedInUser?.fontFamily ?? INITIAL_WEBSITE_FONT_FAMILY;
  const layout = loggedInUser?.profileStyle?.layout;

  // ✔️ syncing redux theme index with cache one
  useEffect(() => {
    // console.log('syncing redux theme index with cache one');
    reduxDispatch(updateLocalCurrentThemeIndex(cacheCurrentThemeIndex));
  }, [reduxDispatch, cacheCurrentThemeIndex]);

  // syncing redux layout index with cache one
  useEffect(() => {
    reduxDispatch(updateLocalCurrentLayoutIndex(layout || 'Layout6'));
  }, [reduxDispatch, layout]);

  // ✔️ syncing local redux website fontfamily with cache one
  useEffect(() => {
    // console.log('syncing local redux website fontfamily with cache one');
    reduxDispatch(udpateWebsiteFontFamily(cacheWebsiteFontFamily as WebsiteFontFamily));
  }, [reduxDispatch, cacheWebsiteFontFamily]);

  // -> if user param url not same with loggedin user URL
  // ✔️ Then redirecting to static page
  // ✔️ If coming from edit page, redirect to loggedin user url
  // FIXME - Need to do testing
  // useEffect(() => {
  //   if (!loggedInUserURL || !userRouteParamURL) return;
  //   const isUserURlParamURLSame = userRouteParamURL === loggedInUserURL;
  //   if (!isUserURlParamURLSame) {
  //     if (isFromEditUserPage && isValidRedirectFromEditPage) {
  //       console.log('redirecting to user url');
  //       history.replace(`/${loggedInUserURL}`);
  //     } else {
  //       console.log('redirecting to static');
  //       const REDIRECT_DOMAIN = isNodeEnvProduction
  //         ? 'https://fronts.ai'
  //         : 'http://localhost:3001';
  //       window.location.href = `${REDIRECT_DOMAIN}/${userRouteParamURL.trim()}`;
  //     }
  //   }
  // }, [
  //   history,
  //   loggedInUserURL,
  //   userRouteParamURL,
  //   isFromEditUserPage,
  //   isValidRedirectFromEditPage,
  // ]);

  return { loggedInUser };
};

export default useEditor;
