import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import StyledItemLabel from 'components/shared/StyledItemLabel';
import EditableTags from 'components/utilities/EditableTags/EditableTags';
import EUDTagsInput from 'components/EditUserDetailsLatest/sub-components/EUDTags/sub-components/EUDTagsInput';

const EUDTags = () => {
  const { values, setFieldValue, setFieldTouched, errors } = useFormikContext<EditUserDetailsForm>();
  
  const handleTagsChange = useCallback(
    (updatedTagList: string[]) => {
      setFieldValue('tags', updatedTagList);
      setFieldTouched('tags', true);
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <>
      <StyledItemLabel label='Skills' />
      <EditableTags
        error={errors.tags}
        taglist={values.tags}
        TagsInput={<EUDTagsInput />}
        onChange={handleTagsChange}
      />
    </>
  );
};

export default EUDTags;
