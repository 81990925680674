import ConfirmationDialog from 'Popups/ConfirmationDialog';

// TODO: put this in utilities

// NOTE: being used in currently built EUDActionBtns.tsx

type SimpleFunction = () => void;

type ThisProps = {
  open: boolean;
  contentText?: React.ReactNode;
  title?: React.ReactNode;
  onAgreeBtnClick: SimpleFunction;
  onDisagreeBtnClick: SimpleFunction;
};

const SPMFormConfirmationDialogBox = ({
  open,
  title,
  contentText,
  onAgreeBtnClick,
  onDisagreeBtnClick,  
}: ThisProps) => {
  return (
    <ConfirmationDialog    
    handleAgreeBtnClick={onAgreeBtnClick}
      open={open}
      handleDialogClose={onDisagreeBtnClick}
      isLoading={false}
      title={title}
      content={contentText}
      cancelText="Don't close"
      ariaDescribedby='confirmation-dialog'
      confirmText='Close anyway'
    ></ConfirmationDialog>
  );
};

export default SPMFormConfirmationDialogBox;