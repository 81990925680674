import { useMediaQuery, useTheme } from '@mui/material';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';

export default function useGetTransformedImgOnboardingSrc(imgSrc: string) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if(isMobile) {
    return cloudinaryUtilsFns.addTransformationToImageUrl({url: imgSrc, width: 100, height: 100});
  } else {
    return imgSrc;
  }
}