import React from 'react';
import Button from '@mui/material/Button/Button';
import { SPMCardProps } from 'components/MUI_SPM/SPMCard/SPMCard';

type SPMCardCTAButtonProps = Pick<
  SPMCardProps,
  'cardEntityId' | 'cardCTAButtonText' | 'onCTABtnClick'
> & {
  buttonStyling: any;
};

const SPMCardCTAButton: React.FunctionComponent<SPMCardCTAButtonProps> = ({
  cardCTAButtonText,
  buttonStyling,
  cardEntityId,
  onCTABtnClick,
}) => {
  const isActionBtnDisabled = !!!cardEntityId;

  if (isActionBtnDisabled) {
    console.error('Action btn disabled | Card entity id not found!!');
  }

  const { background, color, fontSize, ...restStyling } = buttonStyling;
  return (
    <Button
      disabled={isActionBtnDisabled}
      variant='contained'
      fullWidth
      onClick={onCTABtnClick}
      sx={{
        marginTop: {xs: '1em', md: '1.45em'},
        paddingY: '0.6em',
        background: background,
        color: color,
        fontSize: fontSize,
        ...restStyling,

        '&:hover': {
          opacity: 0.7,
          background: background,
          color: color,
        },
      }}
    >
      {cardCTAButtonText}
    </Button>
  );
};

export default SPMCardCTAButton;
