import { Box, Chip, Stack, SxProps } from '@mui/material';
import { getLocalOrGlobalStyling } from 'components/utilities/styleUtilities/styleUtilities';
import { ThemeType } from 'themes/AppThemeProvider/themeType';

type TagsProps = {
  tagsArray: string[];
  tag: any;
  global: ThemeType['profile']['global'];
  sx?: SxProps;
};

const Tags = (props: TagsProps) => {
  const { tagsArray, tag, global, sx } = props;

  return (
    <Stack
      component='ul'
      direction='row'
      justifyContent='center'
      alignItems='center'
      flexWrap='wrap'
      columnGap={{ xs: `calc(${tag.columnGap}/ 1.1)`, lg: tag.columnGap }}
      rowGap={{ xs: `calc(${tag.rowGap}/ 1.1)`, lg: tag.rowGap }}
      sx={{ listStyleType: 'none', paddingLeft: 0, ...sx }}
    >
      {tagsArray.map((eachTag, i) => (
        <Box component='li' key={i}>
          <Chip
            label={eachTag}
            sx={{
              ...tag,
              padding: tag.padding,
              background: tag.background + '88',
              color: getLocalOrGlobalStyling(tag.color, global.color),
              borderRadius: tag.borderRadius,
              border: 'none',
              fontSize: tag.fontSize ? tag.fontSize : { xs: '16px', md: '22px' },
              boxShadow: tag.boxShadow,
              maxWidth: '30ch',
            }}
            size='medium'
            variant='filled'
          />
        </Box>
      ))}
    </Stack>
  );
};

export default Tags;
