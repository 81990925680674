import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';

const useStoreStyles = () => {
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  // !! making store of type any until we finalize our structure | remove any
  const { storeStylings } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      storeStylings: themes
        ? themes[localCurrentThemeIndex].store
        : initialThemeState.store,
    }),
  });
  return { storeStylings };
};

export default useStoreStyles;
