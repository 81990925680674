import React, { useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Avatar, Box } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { useLocation } from 'react-router-dom';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CloseIcon from '@mui/icons-material/Close';

import Popup from 'components/utilities/Popups/Popup';
import { SelectLoginAccount } from 'components/logins/SelectLoginAccount/SelectLoginAccount';
import { admin_drawerWidth } from '../dashboard-sidebar/DashboardSidebar';
import { dashboard_icon_size } from 'views/Dashboard/Dashboard';
import {
  toggleSideBar,
  select_dashboard_user_is_sidebar_minified,
} from 'store/features/dashboard/dashboardSlice';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: admin_drawerWidth,
    width: `calc(100% - ${admin_drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DashboardNavbar = () => {
  const is_sidebar_minified = useAppSelector(select_dashboard_user_is_sidebar_minified);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const toggleDrawer = () => dispatch(toggleSideBar());

  const { cachedLoggedInUser } = useGetLoggedInUser();

  const loc = useLocation();
  const subpath = loc.pathname.split('/').pop();

  function handleSwitchAccountClick() {
    setOpen(true);
  }

  return (
    <AppBar
      position='absolute'
      elevation={0}
      open={!is_sidebar_minified}
      sx={{ bgcolor: 'transparent', color: 'text.primary' }}
    >
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
          backdropFilter: 'blur(2px)',
          boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            display: is_sidebar_minified ? 'inherit' : 'none',
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'translateX(3px)',
            },
          }}
        >
          <NavigateNextIcon sx={dashboard_icon_size} />
        </IconButton>
        <Typography
          component='h5'
          variant='h5'
          color='inherit'
          noWrap
          sx={{ flexGrow: 1, textTransform: 'capitalize', fontWeight: 600 }}
        >
          {subpath?.replace('-', ' ')}
        </Typography>
        <IconButton
          sx={{ boxShadow: (theme) => theme.shadows[3], p: 0 }}
          onClick={handleSwitchAccountClick}
        >
          <Avatar
            src={cachedLoggedInUser?.faviconImg}
            alt='user-img'
            sx={{ width: { xs: 25, md: 35 }, height: { xs: 25, md: 35 } }}
          />
        </IconButton>

        <SwitchAccountsPopup
          open={open}
          setOpen={setOpen}
          email={cachedLoggedInUser?.email}
        />

        {/* TODO -  Need to work on this in future */}
        {/* <IconButton color='inherit'>
          <Badge badgeContent={4} color='info'>
            <NotificationsIcon sx={dashboard_icon_size} />
          </Badge>
        </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;

function SwitchAccountsPopup({
  open,
  setOpen,
  email,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  email?: string;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Popup
      dialogProps={{
        fullScreen: false,
        open,
        sx: {
          '& .style_EmailLinkedUserAccount_bodyWrapper': {
            boxShadow: 'none !important',
            height: '100%',
            maxHeight: '100%',
            pr: 0,
            overflow: 'hidden',
            borderRadius: 0,
          },
          '& .style_EmailLinkedUserAccount_UserAccountsList': {
            height: '100%',
            maxHeight: '100%',
            overflow: 'hidden',
          },
          '& .style_EmailLinkedUserAccount_ImgCenter': {
            display: 'none',
          },
          '& .style_EmailLinkedUserAccount_ChooseAccount': {
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            mx: 2,
          },
        },
      }}
      setOpen={setOpen}
    >
      <Box sx={{ position: 'absolute', top: 0, right: 5, zIndex: 1 }}>
        <IconButton color='error' onClick={handleClose}>
          <CloseIcon sx={{ width: 25, height: 25 }} />
        </IconButton>
      </Box>
      <SelectLoginAccount
        email={email}
        redirectRoute={'/dashboard'}
        callback={() => setOpen(false)}
      />
    </Popup>
  );
}
