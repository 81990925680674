import { getTimezoneOffset,zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';
import { RepeatebleTentativeHours } from 'types/meeting';
import { getDayFromSeconds, getHourAndMinutesFromSecondsInLocalTimezone } from 'utilities/calendarUtilities';
import { TimeSlot } from './TentativeAvailabilityTypes';

export const initialDays = {
  sun: false,
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
};

export const daysOfWeek: readonly string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function getUtcTime(
  localTime: string,
  timeZone: string,
): number {
  const time = moment(localTime).format().split('+')[0];
  return (
    timeZone
      ? moment(zonedTimeToUtc(time, timeZone))
      : moment(localTime)
  ).utc().valueOf()
}

export function getLocalTimeNonRepeatable(
  utcStamp: number,
  timeZone: string,
  format: string
) {
  const timezoneOffset = getTimezoneOffset(timeZone);
  // conversion of UTC to loacal
  const convertedFromTime = utcStamp + timezoneOffset - moment(new Date()).utcOffset().valueOf() * 60 * 1000;
  return moment(convertedFromTime).format(format);
}

export function getLocalTime(
  utcStamp: number,
  timeZone: string,
): number {
  const timezoneOffset = getTimezoneOffset(timeZone) / 1000;
  // conversion of UTC to local
  const convertedFromTime = utcStamp + timezoneOffset;

  return convertedFromTime;
}

export function getLocalTimeForRepeatableSlots(
  from: number,
  to: number,
  timeZone: string,
) {
  // utc + (offset) = localTime;  
  const startTime = getHourAndMinutesFromSecondsInLocalTimezone(from, timeZone);
  const endTime = getHourAndMinutesFromSecondsInLocalTimezone(to, timeZone);

  if(from === 0 && to === 604800) {
    return `All days of the week`;
  } else if (startTime.day === endTime.day) {
    return `${getTimeInString(startTime, false)} - ${getTimeInString(endTime, false)} Every ${daysOfWeek[startTime.day]}`;
  }else {
    return `${getTimeInString(startTime, false)} Every ${daysOfWeek[startTime.day]} - ${getTimeInString(endTime, false)} Every ${daysOfWeek[endTime.day]}`;
  }
}

export function convertToNowUTCTime(
  hours: number,
  minutes = 0
): number {
  return (
    new Date().setHours(hours, minutes, 0) +
    new Date().getTimezoneOffset() * 60 * 1000
  );
}

type GetTimeString = {
  hour: number;
  min: string | number;
  day: number;
  meridiem: string
}

function getTimeInString(time: GetTimeString, withSeparateDays: boolean): string {
  const hour = time.hour !== 12 && time.hour >= 12 ? time.hour - 12 : time.hour;
  const minutes = time.min;
  if (withSeparateDays) {
    return `${hour}:${minutes} ${time.meridiem} ${daysOfWeek[time.day]}`
  } else {
    return `${hour}:${minutes} ${time.meridiem}`;
  }
}

export function prefixZero(numb: number | string, size = 2): string {
  let num = numb.toString();
  while (num.length < size) num = '0' + num;
  return num;
}

export function convertTimeslotToTimeString(time: TimeSlot, format: 12 | 24) {

  let updatedTime = { ...time };
  let timeInString = '';
  const hourInNumber = Number(updatedTime.hour) || 0;
  if (format === 12) {
    const meridiem = hourInNumber > 11 ? 'pm' : 'am';

    if (hourInNumber > 12) {
      updatedTime = {
        ...time,
        hour: Number(time.hour) - 12,
      }    
      timeInString = `${updatedTime.hour}:${updatedTime.min} ${meridiem}`;
    } else if (time.hour === 0) {
      updatedTime = {
        ...time,
        hour: 12,
      }

      timeInString = `${updatedTime.hour}:${updatedTime.min} am`;
    } else {
      timeInString = `${updatedTime.hour}:${updatedTime.min} ${meridiem}`;
    }
  } else {
    timeInString = `${updatedTime.hour}:${updatedTime.min}`;
  }

  return timeInString;
}


export function removeSameDayEvents(events: RepeatebleTentativeHours[]) {
  const uniqueDays = new Set();
  const filteredEvents = [];
  for (const event of events) {
    const day = getDayFromSeconds(event.from);
    if (!uniqueDays.has(day)) {
      filteredEvents.push(event);
      uniqueDays.add(day);
    }
  }
  return filteredEvents;
}

export function mergeSamedays(events: RepeatebleTentativeHours[]) {
  const mergedArr = events.reduce((acc: RepeatebleTentativeHours[], current: RepeatebleTentativeHours, i) => {
    let merged = false;

    const mergedArr = acc.map((prev) => {
      if ((current.from === prev.from || current.from === prev.to || current.to === prev.from || current.to === prev.to) && !merged) {
        merged = true;
        return {
          from: Math.min(current.from, prev.from),
          to: Math.max(current.to, prev.to),
        };
      } else {
        return prev;
      }
    });

    if (!merged) {
      mergedArr.push(current);
    }

    return mergedArr;
  }, []);
  return mergedArr;
}