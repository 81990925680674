import { useState } from 'react';
import { Box, SxProps } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import DeleteIcon from '@mui/icons-material/Delete';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useDeleteEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import { useGetLinkedAccountsQuery } from 'store/rtkqFeatures/api/linkedAccountsExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { useSiteContext } from 'views/Dashboard/sub-components/dashboard-main/sub-component/sites-managment/SiteContext';

import ResponsiveButton, {
  responsive_button_icon,
} from 'components/Buttons/ResponsiveButton';
import ConfirmationDialog from 'Popups/ConfirmationDialog';

import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { logout } from 'store/features/user/userActions';
import { attempt_login_with_existing_token } from 'lib/services/authServices';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';

const homeRoute = '/dashboard/sites';

const DeleteSite = ({ sx }: { sx: SxProps }) => {
  const context = useSiteContext();
  const [state, setState] = useState('');
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { isLoading, data } = useGetLinkedAccountsQuery(undefined, {
    skip: !cachedLoggedInUser?.email,
  });

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [isDeleteVerified, setIsDeleteVerified] = useState(false);

  const history = useHistory();
  const reduxDispatch = useAppDispatch();
  const editorId = useAppSelector(selectLoggedInUserId);
  const [deleteEditor, { isLoading: isEditorDeletionProcessInProgress }] =
    useDeleteEditorByIdMutation();

  const handleConfirmDeleteUserClick = async () => {
    const deletionMessage = 'Deleting site...';
    const loadingTimerId = toast.loading(deletionMessage);
    const randomAccountToLogin = data?.linkedAccounts.find(
      (linkedAccount) => linkedAccount._id !== editorId
    );

    try {
      if (isEditorDeletionProcessInProgress && isLoading) {
        return;
      }

      if (!editorId) {
        throw new Error('Unable to delete account: editorId not found');
      }

      if (data?.linkedAccounts.length === 1) {
        // If there is only one account, delete it and logout
        await deleteEditor(editorId).unwrap();        
        await reduxDispatch(logout());        
        history.push(homeRoute);
      } else {
        if (randomAccountToLogin) {
          const currentEditorId = editorId;
          const loginToken = await attempt_login_with_existing_token({
            email: randomAccountToLogin.email,
            userId: randomAccountToLogin._id,
          });

          if (currentEditorId && loginToken) {
            await deleteEditor(currentEditorId).unwrap();
            toast.dismiss(loadingTimerId);
            toast.success('Site deleted successfully');
            console.log('Redirecting to home...');
            history.push(homeRoute);
          }
        }
      }
    } catch (error) {
      toast.dismiss(loadingTimerId);
      toast.error('Unable to delete site');
      console.error('Deletion error:', error);
    } finally {
      toast.dismiss(loadingTimerId);
      context.setPopupOpen(false);
      // history.replace({ pathname: `/dashboard/sites/${randomAccountToLogin?.url}` });
      setDeleteConfirmationDialogOpen(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setState(e.target.value);
    if (typeof value === 'string' && value === cachedLoggedInUser?.url) {
      setIsDeleteVerified(true);
    } else {
      if (isDeleteVerified !== false) {
        setIsDeleteVerified(false);
      }
    }
  };

  return (
    <>
      <ConfirmationDialog
        ariaDescribedby='delete-user-confirmation-dialog'
        title='Delete Account'
        content={
          <Box>
            Once you delete the account, it cannot be recovered.
            <br />
            <br />
            To confirm, type "{cachedLoggedInUser?.url}" in the box below
            <TextFieldSyncWithTheme
              label=''
              fullWidth
              onChange={handleChange}
              value={state}
            />
          </Box>
        }
        isLoading={isEditorDeletionProcessInProgress || isLoading}
        confirmText='Delete'
        cancelText='Cancel'
        open={deleteConfirmationDialogOpen}
        handleAgreeBtnClick={handleConfirmDeleteUserClick}
        handleDialogClose={() => setDeleteConfirmationDialogOpen(false)}
        agreeBtnProps={{
          disabled: !isDeleteVerified,
        }}
      />

      <ResponsiveButton
        sx={sx}
        color='error'
        onClick={() => setDeleteConfirmationDialogOpen(true)}
        endIcon={<DeleteIcon sx={{ ...responsive_button_icon, color: 'error.main' }} />}
      >
        Delete site
      </ResponsiveButton>
    </>
  );
};

export default DeleteSite;
