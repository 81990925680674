type SuggestionProps = {
  suggestion: google.maps.places.AutocompletePrediction;
  children: (suggestion: google.maps.places.AutocompletePrediction) => void;
};

const GoogleAutoCompleteSuggestion = ({
  suggestion,
  children,
}: SuggestionProps) => {
  return (
    <li onClick={() => children(suggestion)} className='google-suggestion'>
      <span className='fw-semibold'>
        {suggestion.structured_formatting.main_text}
      </span>
      &nbsp;
      <span>{suggestion.structured_formatting.secondary_text}</span>
      <style>{`
        .google-suggestion {
          border: 1px solid hsl(0, 0%, 80%);
          padding: 0.8em 1.5em;
          font-size: 1.3rem;
          &:hover {
            cursor: pointer;
            background-color: hsla(0, 0%, 0%, 0.159);
          }
        }
      `}</style>
    </li>
  );
};

export default GoogleAutoCompleteSuggestion;
