import { Settings } from 'react-slick';

export type PortfolioItemType = {
  title: string;
  desc: string;
  img?: string;
  readMore: string;
  buttonLabel?: string;
  url?: string;
  updatedAt?: number;
  createdAt: number;
};

export type PortfolioProperties = {
  textAlign: React.CSSProperties['textAlign'];
  borderRadius: string;
  image: Pick<
    React.CSSProperties,
    | 'objectFit'
    | 'objectPosition'
    | 'height'
    | 'maxHeight'
    | 'clipPath'
    | 'borderRadius'
  >;
}

export type PortfoliosType = {
  _id: string;
  userId: string;
  gallery: PortfolioItemTypes;
  isGridMode?: boolean | null;
  portfolioSliderSettings?: Settings;
  properties: PortfolioProperties;
};

export type NewPortfolio = Omit<PortfolioItemType, 'createdAt'>;

export type PortfolioItemTypes = PortfolioItemType[];

export const initialPortfoliosData: PortfolioItemTypes = [];

export const initialPortfolioItem: PortfolioItemType = {
  img: '',
  title: '',
  desc: '',
  readMore: '',
  createdAt: Date.now(),
};

export const initialPortfolio: PortfoliosType = {
  _id: '',
  userId: '',
  gallery: initialPortfoliosData,
  isGridMode: false,
  properties: {
    textAlign: 'start',
    borderRadius: '0',
    image: {
      objectFit: 'contain',
      objectPosition: 'center',
      height: 'auto',
      maxHeight: '100%',
      clipPath: 'none',
      borderRadius: '0',
    },
  },
  portfolioSliderSettings: {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  },
};
