import serverApi from 'lib/apiConfig';

type PortfolioGallery = any;
type portfolioType = any;

// NOTE: No longer in use

const getUserPortfolio = async (userId: string) => {
  const res = await serverApi.basic.get(`portfolio/${userId}`);
  return res.data;
};

const savePortfolio = async (userId: string, portfolio: PortfolioGallery[]) => {
  await serverApi.basic.post(`portfolio/${userId}`, portfolio);
};

const removePortfolio = async (userId: string, index: number) => {
  const res = await serverApi.basic.delete(`portfolio/user/${userId}/index/${index}`);
  return res;
};

const updatePortfolio = async (portfolio: portfolioType): Promise<boolean> => {
  const res = await serverApi.basic.put(`portfolio/${portfolio._id}`, portfolio);
  const responseData = await res.data;
  if (responseData.msg) return true;
  return false;
};

const portfolioServices = {
  getUserPortfolio,
  savePortfolio,
  removePortfolio,
  updatePortfolio,
};

export default portfolioServices;
