import {
  ButtonProps,
  Dialog,
  DialogTitle,
  Divider,
  Stack,
  SxProps,
} from '@mui/material';
import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,

  // icons
  EmailIcon,
  FacebookIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WhatsappIcon,
  WorkplaceIcon,  
} from 'components/ShareComponents/index';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';


const ShareLink = ({
  link,
  children,
  sx,
  onClick,
  buttonProps,
}: {
  link: string;
  children: React.ReactNode;
  sx?: SxProps;
  onClick?: () => void;
  buttonProps?: ButtonProps;
}) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <>
      <ResponsiveButton onClick={handleClick} sx={sx || {}} {...buttonProps}>
        {children}
      </ResponsiveButton>
      <SimpleDialog
        onClose={() => {
          setOpen(false);
          onClick && onClick();
        }}
        open={open}
        selectedValue={link}
      />
    </>
  );
};

export default ShareLink;

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} id={'share-popup'} open={open} sx={{ px: 1, border: '1px solid red' }}>
      <DialogTitle sx={{ py: 1, px: 1, fontSize: { xs: '1.5rem', md: '1.6rem' } }}>
        Share link
      </DialogTitle>
      <Divider sx={{mb: 1}} />
      <Stack direction='row' flexWrap={'wrap'} spacing={1} sx={{ px: 1, pb: 2 }} useFlexGap>
        <EmailShareButton url={selectedValue}>
          <EmailIcon size={32} color='red' round />
        </EmailShareButton>
        <FacebookShareButton url={selectedValue}>
          <FacebookIcon size={32} color='red' round />
        </FacebookShareButton>
        <HatenaShareButton url={selectedValue}>
          <HatenaIcon size={32} color='red' round />
        </HatenaShareButton>
        <InstapaperShareButton url={selectedValue}>
          <InstapaperIcon size={32} color='red' round />
        </InstapaperShareButton>
        <LineShareButton url={selectedValue}>
          <LineIcon size={32} color='red' round />
        </LineShareButton>
        <LinkedinShareButton url={selectedValue}>
          <LinkedinIcon size={32} color='red' round />
        </LinkedinShareButton>
        <WhatsappShareButton url={selectedValue}>
          <WhatsappIcon size={32} color='red' round />
        </WhatsappShareButton>
        <WorkplaceShareButton url={selectedValue}>
          <WorkplaceIcon size={32} color='red' round />
        </WorkplaceShareButton>
        <RedditShareButton url={selectedValue}>
          <RedditIcon size={32} color='red' round />
        </RedditShareButton>
        <VKShareButton url={selectedValue}>
          <VKIcon size={32} color='red' round />
        </VKShareButton>
        <TwitterShareButton url={selectedValue}>
          <TwitterIcon size={32} color='red' round />
        </TwitterShareButton>
        <ViberShareButton url={selectedValue}>
          <ViberIcon size={32} color='red' round />
        </ViberShareButton>
        <TumblrShareButton url={selectedValue}>
          <TumblrIcon size={32} color='red' round />
        </TumblrShareButton>
        <TelegramShareButton url={selectedValue}>
          <TelegramIcon size={32} color='red' round />
        </TelegramShareButton>
      </Stack>
    </Dialog>
  );
}
