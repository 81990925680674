import React, { useEffect } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import usePlacesAutocomplete from 'use-places-autocomplete';
import { useState } from 'react';

import useInititalRender from 'hooks/useInitialRender';
import { getPlaceDetailsFromGoogleMapsApi } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteUtilities';
import { GoogleLocationApiReturn, GoogleLocationAutoCompleteInputProps } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';

const useGoogleLocationAutoCompleteInput = ({
  initialSuggestion,
  getLocationDetails,
  className,
  clearLocationValue,  
}: GoogleLocationAutoCompleteInputProps) => {
  const {
    ready,
    value: locationValue,
    setValue: setLocationValue,
    suggestions,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const [isInputInFocus, setIsInputInFocus] = useState<boolean>(false);
  const elementRef = useOnclickOutside(() => {
    clearSuggestions();
    setIsInputInFocus(false);
  });
  const isInititalRender = useInititalRender();

  clearLocationValue && setLocationValue('');

  // setting initial suggestion on mount
  useEffect(() => {
    if (isInititalRender && initialSuggestion) {
      setLocationValue(initialSuggestion);
      // clearSuggestions();
    }
  }, [isInititalRender, initialSuggestion, setLocationValue, clearSuggestions, suggestions.status]);

  async function getPlaceComponentsFromGoogleAPI(
    place_id: string
  ): Promise<GoogleLocationApiReturn> {
    const placeDetails = await getPlaceDetailsFromGoogleMapsApi(place_id);
    const {
      address_components,
      formatted_address: address,
      geometry: { location },
      url: googleMapsLocationUrl,
    } = placeDetails;
    
    // Extract the country, state, and city information from address components
    let country = '';
    let state = '';
    let city = '';
    let stateISOCode = '';
    let countryISOCode = '';
    let postalCode = '';

    address_components.forEach((component: any) => {
      if (component.types.includes('country')) {
        country = component.long_name;
        countryISOCode = component.short_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.long_name;
        stateISOCode = component.short_name;
      } else if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (component.types.includes('postal_code')) {
        postalCode = component.long_name;
      }
    });

    return {
      address,
      location,
      googleMapsLocationUrl,
      countryISOCode,
      stateISOCode,
      country,
      state,
      city,
      postalCode,
    };
  }

  function handleClearInputBtnClickFromHook() {
    setLocationValue('');    
  }

  // input element text change
  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setLocationValue(e.target.value);

  const handleSuggestionSelect = async ({
    description,
    place_id,
  }: google.maps.places.AutocompletePrediction) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setLocationValue(description, false);
    clearSuggestions();

    const {
      address,
      location,
      googleMapsLocationUrl,
      countryISOCode,
      country,
      state,
      city,
      postalCode, stateISOCode,
    } = await getPlaceComponentsFromGoogleAPI(place_id);

    getLocationDetails({
      suggestion: description,
      placeId: place_id,
      address,
      location: {
        lat: location.lat,
        lng: location.lng,
      },
      googleMapsLocationUrl,
      countryISOCode,
      country,
      state,
      city,
      postalCode, 
      stateISOCode,
    });
  };
  return {
    ready,
    locationValue,
    suggestions,

    elementRef,

    setLocationValue,
    handleLocationChange,
    handleClearInputBtnClickFromHook,
    handleSuggestionSelect,
    getPlaceComponentsFromGoogleAPI,

    input: {
      isInputInFocus,
      setIsInputInFocus,
    },
  };
};

export default useGoogleLocationAutoCompleteInput;
