import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import { CustomOmit, LoggedInUser } from 'types/user';

type FieldValuePair = Partial<LoggedInUser> & {editorId: string};
type MutationReturnType = {
  value: CustomOmit<FieldValuePair, 'editorId'>;
};

const editorExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEditorById: build.query<LoggedInUser, string>({
      query: (editorId) => `/user/${editorId}`,
      providesTags: (result, error, editorId) => [{ type: 'Editor', id: editorId }],
    }),
    updatedEditorById: build.mutation<
      void,
      {
        editorId: string;
        loggedInUser: Partial<LoggedInUser>;
      }
    >({
      query: ({
        editorId,
        loggedInUser: { authType, createdAt, ...toBeUpdatedEditor },
      }) => ({
        url: `/user/${editorId}`,
        method: 'PUT',
        body: toBeUpdatedEditor,
      }),
      invalidatesTags: (result, error, { editorId }) => [
        { type: 'Editor', id: editorId },
      ],
    }),
    patchUpdateEditorFieldById: build.mutation<MutationReturnType, FieldValuePair>({
      query: ({editorId, ...fieldValuePair}) => {
        return {
          url: `/user/update-field`,
          method: 'PATCH',
          body: fieldValuePair,
        };
      },
      invalidatesTags: (result, error, {editorId, ...fieldValuePair}) => [{ type: 'Editor', id: editorId }, {type: 'LinkedAccount'}],
    }),    
    deleteEditorById: build.mutation<void, string>({
      query: (editorId) => ({
        url: `/user/${editorId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, editorId) => [{ type: 'Editor', id: editorId }, {type: 'LinkedAccount'}],
    }),
  }),
});

export default editorExtendedApi;

export const {
  useGetEditorByIdQuery,
  useUpdatedEditorByIdMutation,
  usePatchUpdateEditorFieldByIdMutation,  
  useDeleteEditorByIdMutation,
} = editorExtendedApi;
