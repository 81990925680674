import { Box, Stack, Typography } from '@mui/material';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import { style_ProfileCard_bgWave } from 'components/ProfileCard/ProfileCard.style';

import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import LayoutContainer from 'components/Containers/LayoutContainer';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import LayoutWrapper from './reusable-components/LayoutWrapper';

const Layout11 = () => {
  const {
    currentTheme,

    tagsArray,
    tagsArrayLength,

    closeIconPopup,
    userAuthType,
    userName,
    userTitle,
    userProfileLink,
    isAuthTagVisible,
    showIconPopup,
    showIconPopupController,
    user,

    layout,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,

    buttonStyle,

    showImage,
    userImage,
    bgImageWithColorURL,
    bgImage,
    userBgImage,
    bg,

    isMeetingAndServiceAvailable,
    handleImageError,
    storeHeading,

    isNeedDiv,
    userNameStyling,
    isMobile,
    title,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,

    displayTags,
    displaySocialLinks,

    colorCode,

    userNameColor,
    titleColor,
  } = useProfileCard();
  const backgroundSize = user?.profileStyle?.image?.objectFit ?? 'cover';
  const objectFit = user?.profileStyle?.image?.objectFit || 'contain';
  
  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box component='section' position='relative' sx={isNeedDiv ? {} : bg}>
      {isNeedDiv && !userBgImage && (
        <Box className='bg-wave' sx={{ ...style_ProfileCard_bgWave(bg), zIndex: -1 }} />
      )}
      <LayoutWrapper
        sx={{
          backgroundImage: `url(${bgImageWithColorURL})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize,
          pb: 3,
        }}
      >
        <LayoutContainer maxWidth='lg'>
          <ProfileEditAndDesignBtn sx={{ my: 0, left: 0 }} />
          <Stack direction={{ xs: 'column', md: 'row' }} flexWrap='nowrap'>
            <Box
              className='img-container'
              sx={{ width: { xs: '100%', md: '50%' }, bgcolor: 'transparent'}}
            >
              <Box
                className='img'
                component='img'
                src={userImage}
                sx={{
                  objectFit,
                  objectPosition: 'center',
                  maxWidth: '100%',
                  height: '100%',
                  aspectRatio: '1/1',
                }}
                onError={handleImageError}
              ></Box>
            </Box>
            <Stack
              className='text-container'
              gap={2}
              sx={{
                justifyContent: 'center',
                width: { xs: '100%', md: '50%' },
                bgcolor: currentTheme.themeColor,
                p: {xs: 5, md: 10},
                pt: {xs: 2.5, md: 10},
                '&>*not(:first-child)': {
                  border: '1px solid red',
                },
              }}
            >
              <Typography
                variant='h1'
                fontSize={{ xs: 40, md: 70 }}
                fontWeight={500}
                textAlign={{ xs: 'center', md: 'start' }}
                color={userNameColor}
              >
                {userName}
              </Typography>
              <Typography variant='h6' color={titleColor} textAlign={{ xs: 'center', md: 'start' }}>
                {userTitle}
              </Typography>
              
              {displaySocialLinks !== false && (
                <Box display='inline-block'  textAlign={{xs: 'center', md: 'start'}}>
                  <SocialIconsList
                    emailPublic={emailPublic}
                    email={email}
                    socialLinks={socialLinks}
                    whatsapp={whatsapp}
                    address={address}
                    phone={phone}
                    styling={{ justifyContent: 'center', display: 'inline-flex' }}
                    showOnlyIcon
                  />
                </Box>
              )}
              {displayTags !== false && (
                <Box>
                  <Tags
                    tagsArray={tagsArray}
                    tag={tag}
                    global={global}
                    sx={{ justifyContent: {xs: 'center', md: 'flex-start'}, display: 'inline-flex' }}
                  />
                </Box>
              )}
              {isMeetingAndServiceAvailable && storeHeading && (
                <CallToActionBtn
                  user={user}
                  buttonStyle={buttonStyle}
                  storeHeading={storeHeading}
                />
              )}
            </Stack>
          </Stack>
        </LayoutContainer>
      </LayoutWrapper>

      {/* sub title */}
      <ProfileSubtitle
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        subTitle={user.subTitle}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout11;
