import { useEffect, useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import ProfileDetailsBannerLinks from 'components/ProfileDetailsBanner/sub-components/ProfileDetailsBannerLinks/ProfileDetailsBannerLinks';
import Fade from '@mui/material/Fade';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const ProfileDetailsBanner = () => {
  const { cachedLoggedInUser: editor } = useGetLoggedInUser();
  const [pageYOffset, updatePageYOffset] = useState(0);
  const showProfileDetailsBanner = pageYOffset === 0;
  const editorUrl = editor?.url || 'fronts';
  const staticPageLinkText = `fronts.ai/${editorUrl}`;
  const staticPageLinkURL = `https://${staticPageLinkText}`;

  useEffect(() => {
    const onScroll = () => {
      updatePageYOffset(window.pageYOffset);
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!showProfileDetailsBanner) return <></>;

  return (
    <Fade in timeout={650}>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1300,
          background: '#ffeb3b',
          boxShadow: '0px 0px 3px #777',
        }}
      >
        <Container maxWidth='xl'>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            justifyContent='center'
            alignItems='center'
            spacing={1}
            sx={{
              padding: '0.5rem 0.5rem',
            }}
          >
            {/* ------------------- static page url ----------------------------- */}
            <Typography
              variant='body2'
            >
              Share your site&nbsp;
              <Typography variant='body2' fontSize={'inherit'} component='a' href={staticPageLinkURL}>
                {staticPageLinkText}
              </Typography>
              &nbsp;
            </Typography>
            {/* -------------------- static page share ------------------------- */}
            <ProfileDetailsBannerLinks staticPageLinkURL={staticPageLinkURL} />
          </Stack>
        </Container>
      </Box>
    </Fade>
  );
};

export default ProfileDetailsBanner;
