import React, { useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import Frame from './styled-components/Frame';
import Question from './reusable-components/Question';
import BoldSpan from 'components/utilities/BoldSpan';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import serverApi from 'lib/apiConfig';
import SearchedDomainOnboarding from './SearchDomain/SearchedDomainOnboarding';
import { onboardingImgForDifferentSteps } from '../OnboardingFlowStepper/OnboardingFlowStepper';
import useGetTransformedImgOnboardingSrc from 'views/OnboardingFlow/hooks/useGetTransfromedOnboardingSrc';
import InputName from './InputName';

const BusinessInfoAndDomainSetup = () => {
  const regex = /^[a-z0-9\-_.]+$/;
  const [localState, setLocalState] = useState({
    error: '',
    value: '',
    status: 'idle' as 'idle' | 'loading' | 'success' | 'error',
  });
  const { setFieldValue, values, errors, touched } = useFormikContext<StepperState>();
  const imgSrc = useGetTransformedImgOnboardingSrc(onboardingImgForDifferentSteps[0]);
  const pendingUrlError = errors?.pendingUrl;
  const isPendingUrlTouched = touched?.pendingUrl;

  const debounceURLCheck = useCallback(
    debounce(async (urlToCheck: string) => {
      setLocalState((prev) => ({ ...prev, status: 'loading' }));
      try {
        const { data: doesURLExist } = await serverApi.onboardingRoutes.get(
          '/check-url-availability',
          {
            params: { url: urlToCheck },
          }
        );
        setLocalState((prev) => ({
          ...prev,
          error: doesURLExist ? 'URL is already taken' : '',
          status: doesURLExist ? 'error' : 'success',
        }));
      } catch (error) {
        console.error(error);
        setLocalState((prev) => ({
          ...prev,
          error: 'Failed to check URL availability',
          status: 'error',
        }));
      }
    }, 500),
    []
  );

  useEffect(() => {
    const pendingUrl = values.pendingUrl;
    if (pendingUrl && pendingUrl.trim().length > 0) {
      (async () => {
        await debounceURLCheck(pendingUrl);
      })();

      setLocalState((prev) => ({ ...prev, value: pendingUrl }));
    }
  }, [values.pendingUrl, debounceURLCheck]);

  useEffect(() => {
    if (pendingUrlError && isPendingUrlTouched) {
      setLocalState((prev) => ({ ...prev, status: 'error', error: pendingUrlError }));
    }
  }, [pendingUrlError, isPendingUrlTouched]);

  // Intialize the pendingUrl field with the value from the localState
  useEffect(() => {
    if (localState.status === 'success' && localState.value) {
      setFieldValue('pendingUrl', localState.value);
    }
  }, [localState.value, localState.status, setFieldValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value.match(/[A-Z]/)) {
      value = value.toLowerCase();
    }

    if (value.length < 1) {
      setLocalState({ error: 'Minimum 3 words required', value, status: 'error' });
      return;
    }

    if (value.length > 50) {
      setLocalState({ error: 'Maximum 50 words', value, status: 'error' });
      return;
    }

    if (!regex.test(value)) {
      setLocalState({
        error:
          'Only lowercase letters, numbers, hyphens, underscores, and periods are allowed',
        value,
        status: 'error',
      });
      return;
    }

    setLocalState({ error: '', value, status: 'idle' });

    // check if the URL is available
    debounceURLCheck(value);
  };

  const UrlStatus = () => (
    <>
      {localState.status === 'success' && (
        <CheckCircleIcon sx={{ mt: 1 }} color='success' />
      )}
      {localState.status === 'error' && <CancelIcon sx={{ mt: 1 }} color='error' />}
      {localState.status === 'loading' && (
        <Box sx={{ mt: 1 }}>
          {' '}
          <CircularProgress size={20} />{' '}
        </Box>
      )}
      {localState.status === 'idle' && <Typography sx={{ mt: 1 }}> &nbsp; </Typography>}
    </>
  );

  return (
    <Frame imgSrc={imgSrc} containerSx={{ mb: 10 }}>
      <Stack gap={{ xs: 4, md: 7.5 }} direction='column'>
        <InputName />
        {/* TODO - Replace this to InputURL.tsx */}
        <Box>
          <Question
            required
            currentStep={1}
            helperText='This will be the website’s publicly accessible URL'
          >
            <>
              Pick a free&nbsp;
              <BoldSpan>
                <>website address</>
              </BoldSpan>
            </>
          </Question>
          <Box
            sx={{
              mb: 0,
              '& .MuiInputBase-root': {
                alignItems: 'flex-start',
                pl: 0,
              },
              '& input::placeholder': {
                fontSize: { xs: '1.6rem', md: '1.8rem' },
              },
            }}
          >
            <TextFieldSyncWithTheme
              error={localState.status === 'error'}
              value={localState.value}
              onChange={handleChange}
              fullWidth
              autoCapitalize='none'
              autoComplete='off'
              name='url'
              placeholder='name'
              helperText={!!localState.error ? localState.error : ''}
              FormHelperTextProps={{
                sx: {
                  color: (theme) =>
                    !!localState.error
                      ? theme.palette.error.main
                      : theme.palette.text.secondary,
                },
              }}
              InputProps={{
                startAdornment: (
                  <Typography
                    sx={{
                      p: { xs: '0.8rem 1rem', md: '1rem 1.25rem' },
                      pr: 0,
                      borderRadius: '4px 0 0 4px',
                      bgcolor: (theme) => theme.palette.secondary.main,
                      color: (theme) => theme.palette.secondary.contrastText,
                      fontSize: { xs: '1.6rem', md: '1.8rem' },
                    }}
                  >
                    https://fronts.ai/
                  </Typography>
                ),
                endAdornment: <UrlStatus />,
              }}
            />
          </Box>
        </Box>
        <Box>
          <Question
            currentStep={1}
            helperText='Boost credibility with your own domain name '
          >
            <>
              Choose a&nbsp;
              <BoldSpan>
                <>custom domain</>
              </BoldSpan>
            </>
          </Question>
          <Box >
            <SearchedDomainOnboarding />
          </Box>
        </Box>
      </Stack>
    </Frame>
  );
};

export default BusinessInfoAndDomainSetup;
