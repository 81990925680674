
export function getTextContent(str: string) {
  const element = document.createElement('div');
  element.innerHTML = str;
  return element.textContent || '';
}

export function truncate(str: string, max_char: number) {  
  const text = getTextContent(str);
  if (text && text.length > max_char) {
    return text.slice(0, max_char) + '...';
  } else {
    return text || '';
  }
}

export function getLimitedPortfolioDescription({
  portfolioDescription,
  portfolioImg,
  doesPortfolioHaveImg,
  portfolioUrl,
}: {
  portfolioDescription: string;
  portfolioImg: string | undefined;
  doesPortfolioHaveImg: boolean;
  portfolioUrl: string;
}): string {
  const MIN_TEXT = 300;
  const MEDIUM_TEXT = 600;
  const MAX_TEXT = 600;
  
  if (doesPortfolioHaveImg) {
    if (portfolioImg) {
      if (portfolioUrl) {
        // Description length to show when IMG and URL is there.
        return truncate(portfolioDescription, MIN_TEXT);
      } else {
        // Description length to show when URL is not present but IMG is there
        return truncate(portfolioDescription, MIN_TEXT);
      }
    }
  } else if (portfolioUrl) {
    if (doesPortfolioHaveImg) {
      if (portfolioImg) {
        // Description length to show when IMG and URL is there.
        return truncate(portfolioDescription, MIN_TEXT);
      }
    } else {
      // Description length to show when IMG is not present but URL is present
      return truncate(portfolioDescription, MEDIUM_TEXT);
    }
  } else {
    return truncate(portfolioDescription, MEDIUM_TEXT);
  }
  return truncate(portfolioDescription, MAX_TEXT); // TODO: check this condition
}