import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { useFormikContext } from 'formik';
import { SelectChangeEvent } from '@mui/material';
import { State, Country, ICountry, IState } from 'country-state-city';

import {
  AutoCompleteGuestLocationDetails,
  GoogleLocationApiReturn,
} from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import { EditUserDetailsForm } from '../../types';
import useGoogleLocationAutoCompleteInput from 'components/utilities/GoogleLocationAutoCompleteInput/useGoogleLocationAutoCompleteInput';
import serverApi from 'lib/apiConfig';

// & ------------ Types -----------------
type HandleEmptyText = {
  name: string;
};

type HandleErrorResponse = {
  address: string;
  error: unknown;
  name: string;
};

export type Region = {
  country?: Partial<ICountry>;
  state?: Partial<IState>;
  city?: string;
};

interface UpdatePlaceDetails extends GoogleLocationApiReturn {
  name: string;
  placeId: string;
}

type GetPlaceDetails = {
  address: string;
  name: string;
  getPlaceComponentsFromGoogleAPI: (placeId: string) => Promise<GoogleLocationApiReturn>;
};

// & ------------ function -----------------
export function getCountryByName(name: string) {
  return Country.getAllCountries().find((country) => country.name === name);
}
export function getStateByName(name: string) {
  return State.getAllStates().find((state) => state.name === name);
}

// & ------------ hook -----------------
const useEUDAddressInput = (name: string) => {
  const [region, setRegion] = useState<Region>({});
  const [localInputState, setLocalInputState] = useState('');
  const { setFieldTouched, values, setValues, initialValues } =
    useFormikContext<EditUserDetailsForm>();
  const {address, city, country, state, } = values;

  const isValueLoaded = values !== initialValues;

  function handleErrorResponse({ error, name, address }: HandleErrorResponse) {
    setFieldTouched(name, true, false);
    setValues((prev) => ({
      ...prev,
      address: address,
      place_id: '',
      lat: 0,
      lng: 0,
      country: '',
      state: '',
      city: '',
    }));
  }

  function handleEmptyText({ name }: HandleEmptyText) {
    // Handle the case when text is empty
    setFieldTouched(name, true, false);
    setValues((prev) => ({
      ...prev,
      address: '',
      place_id: '',
      lat: 0,
      lng: 0,
      country: '',
      state: '',
      city: '',
    }));
  }

  async function getPlaceDetails({
    address,
    name,
    getPlaceComponentsFromGoogleAPI,
  }: GetPlaceDetails) {
    if (!address) {
      handleEmptyText({ name });
      return;
    }
    try {
      const response = await serverApi.v2.get('/google-maps-places-api/place_id', {
        params: {
          text: address,
        },
      });
      const {
        city,
        country,
        state,
        location,
        address: addressFromAPI,
        postalCode,
        googleMapsLocationUrl,
        countryISOCode,
        stateISOCode,
      } = await getPlaceComponentsFromGoogleAPI(response.data);

      updatePlaceDetails({
        address: addressFromAPI,
        placeId: response.data,
        location,
        city,
        country,
        state,
        name,
        postalCode,
        countryISOCode,
        stateISOCode,
        googleMapsLocationUrl,
      });
    } catch (error) {
      handleErrorResponse({ error, name, address });
    }
  }

  function updatePlaceDetails({
    address,
    placeId,
    location,
    city,
    country,
    state,
    countryISOCode,
    stateISOCode,
    name,
    postalCode,
    googleMapsLocationUrl,
  }: UpdatePlaceDetails) {
    const updated_values: Partial<EditUserDetailsForm> = {
      address,
      place_id: placeId,
      lat: location.lat,
      lng: location.lng,
      country: country || '',
      state: state || '',
      city: city || '',
    };

    setFieldTouched(name, true, false);
    setValues((prev) => {
      return {
        ...prev,
        ...updated_values,
        addressComponents: {
          ...updated_values,
          postalCode: postalCode || '',
          googleMapsLocationUrl: googleMapsLocationUrl || '',
          stateISOCode: stateISOCode || '',
          countryISOCode: countryISOCode || '',
        },
      };
    }, false);

    if (country || state || city) {
      setRegion((prev) => ({
        ...prev,
        country: {
          ...prev.country,
          name: country || '',
          isoCode: countryISOCode || '',
        },
        state: {
          ...prev.state,
          name: state || '',
          isoCode: stateISOCode || '',
        },
        city: city || '',
      }));
    }
  }

  const getLocationDetails = async (locationDetails: AutoCompleteGuestLocationDetails) => {
    const {
      address,
      location,
      placeId,
      city,
      country,
      state,
      countryISOCode,
      stateISOCode,
      googleMapsLocationUrl,
      postalCode,
    } = locationDetails;

    setLocalInputState(address);
    updatePlaceDetails({
      address,
      placeId,
      location,
      city,
      country,
      state,
      stateISOCode,
      countryISOCode,
      name,
      postalCode,
      googleMapsLocationUrl,
    });
  };

  const {
    locationValue,
    suggestions,
    input: { isInputInFocus, setIsInputInFocus },

    elementRef,

    handleLocationChange,
    handleSuggestionSelect,
    getPlaceComponentsFromGoogleAPI,
    setLocationValue,
  } = useGoogleLocationAutoCompleteInput({
    getLocationDetails,
    initialSuggestion: isValueLoaded ? values.address : '',
  });

  // setting initial suggestion on mount
  useEffect(() => {
    if (isValueLoaded && address) {
      setLocationValue(address);
      setRegion((prev) => ({
        ...prev,
        country: {
          ...prev.country,
          name: country,
          isoCode: getCountryByName(country)?.isoCode || '',
        },
        state: {
          ...prev.state,
          name: state,
          isoCode: getStateByName(state)?.isoCode || '',
        },
        city: city,
      }));
    }
  }, [isValueLoaded, address]);

  let cancellationToken = 0;
  const debounceSaveAddress = useCallback(
    debounce(async (address: string, latestToken: number) => {
      if (latestToken === cancellationToken) {
        await getPlaceDetails({
          address,
          name,
          getPlaceComponentsFromGoogleAPI,
        });
      }
    }, 500),
    []
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(e.target.value === '') {
      return handleClearInputBtnClick();
    }    
    handleLocationChange(e);
    setLocalInputState(e.target.value);
    cancellationToken += 1;
    debounceSaveAddress(e.target.value, cancellationToken);
  };

  const handleCountryChange = (event: SelectChangeEvent) => {
    setRegion((prev) => ({
      ...prev,
      country: Country.getCountryByCode(event.target.value) as ICountry,
    }));
    setFieldTouched('country', true, false);
    setValues((prev) => {
      return {
        ...prev,
        country: Country.getCountryByCode(event.target.value)?.name || '',
      };
    }, false);
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    setRegion((prev) => ({
      ...prev,
      state: State.getStateByCode(event.target.value) as IState,
    }));
    setFieldTouched('state', true, false);
    setValues((prev) => {
      return {
        ...prev,
        state: State.getStateByCode(event.target.value)?.name || '',
      };
    }, false);
  };

  const handleCityChange = (event: SelectChangeEvent) => {
    setRegion((prev) => ({ ...prev, city: event.target.value as string }));
    setFieldTouched('city', true, false);
    setValues((prev) => {
      return {
        ...prev,
        city: event.target.value || '',
      };
    }, false);
  };

  function handleClearInputBtnClick() {
    setLocationValue('');
    setLocalInputState('');
    setRegion({});
    setValues((prev) => ({
      ...prev,
      address: '',
      place_id: '',
      lat: 0,
      lng: 0,
      country: '',
      state: '',
      city: '',
    }));
    setFieldTouched('address', true, false);
  }

  return {
    locationValue,
    suggestions,
    input: { isInputInFocus, setIsInputInFocus },
    region,
    localInputState,

    elementRef,

    handleSuggestionSelect,
    handleInputChange,
    handleCountryChange,
    handleStateChange,
    handleCityChange,
    handleClearInputBtnClick,
  };
};

export default useEUDAddressInput;
