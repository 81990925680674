import { useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { toast } from 'react-toastify';

import { default as ServiceOrderType } from 'types/serviceOrder';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { useGetServiceOrdersByUserIdQuery } from 'store/rtkqFeatures/api/serviceOrdersExtendedApi';

import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import { CustomTabPanel, tabStyling } from '../meeting-managment/MeetingManagment';
import ServiceOrderTable from './ServiceOrderTable';
import { EmptyTabPanel } from 'components/dashboard/DashboardReusableComponent';

type FilterServiceOrders = {
  activeServiceOrders: ServiceOrderType[];
  archivedServiceOrders: ServiceOrderType[];
};

const ServiceOrdersManagment = () => {
  const [value, setValue] = useState(0);
  const { userIdInReduxUserSlice: editorId } = useGetLoggedInUser();
  const { isLoading, isError, data: serviceOrders } = useGetServiceOrdersByUserIdQuery(editorId);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const filteredServiceOrders: FilterServiceOrders = (serviceOrders || [])?.reduce(
    (acc, eachServiceOrder) => {
      if (eachServiceOrder.isArchived) {
        acc.archivedServiceOrders.push(eachServiceOrder);
      } else {
        acc.activeServiceOrders.push(eachServiceOrder);
      }
      return acc;
    },
    {
      activeServiceOrders: [] as ServiceOrderType[],
      archivedServiceOrders: [] as ServiceOrderType[],
    }
  );

  if (isError) {
    toast.error('Something went wrong! Please try again later.');
  }

  return (
    <Box position={'relative'}>
      <SectionLoader isLoading={isLoading} />
      <Box
        sx={{
          mr: 'auto',
          borderBottom: 1,
          borderColor: 'divider',
          justifyContent: { xs: 'space-between', md: 'flex-start' },
        }}
      >
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={value}
          onChange={handleChange}
          aria-label='meeting tabs'
        >
          <Tab
            sx={tabStyling}
            label={'Active'}
            id='meeting-tab-0'
            aria-controls='meeting-tabpanel-0'
          />
          <Tab
            sx={tabStyling}
            label={'Archived'}
            id='meeting-tab-1'
            aria-controls='meeting-tabpanel-1'
          />
          <Tab sx={tabStyling} label='All' id='meeting-tab-2' aria-controls='meeting-tabpanel-2' />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {filteredServiceOrders.activeServiceOrders.length > 0 ? (
          <ServiceOrderTable tableData={filteredServiceOrders.activeServiceOrders} />
        ) : (
          <EmptyTabPanel />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {filteredServiceOrders.archivedServiceOrders.length > 0 ? (
          <ServiceOrderTable tableData={filteredServiceOrders.archivedServiceOrders} />
        ) : (
          <EmptyTabPanel />
        )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {serviceOrders && serviceOrders.length > 0 ? (
          <ServiceOrderTable tableData={serviceOrders} />
        ) : (
          <EmptyTabPanel />
        )}
      </CustomTabPanel>
    </Box>
  );
};

export default ServiceOrdersManagment;
