import axios from 'axios';
export const twoMb = (1024 * 1024) * 2; 

export const imgUploadHandler: UploadHandler = (blobInfo, progress)  => new Promise((resolve, reject) => {
    const uploadImg = async () => {
      // Defining our variables
      const CLOUD_NAME = 'salesfronts';
      const UPLOAD_PRESET = 'sales_fronts';
      const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`;
    
      const FORM_DATA = new FormData();
      //Building the request body
      FORM_DATA.append('file', blobInfo.blob(), blobInfo.filename());
      FORM_DATA.append('upload_preset', UPLOAD_PRESET);
      try {
        if(blobInfo.blob().size > twoMb) {        
            return reject({message: 'Max Img Limit 1mb!', remove: true});
        }
        const res = await axios.post(UPLOAD_URL, FORM_DATA, {
          responseType: 'json'
        });
        return res.data;
      } catch (err) {        
        return reject({message: 'Max Img limit 1mb', remove: true})
      }
    };
    const data = uploadImg();
    const secure_url = data.then(data => data.secure_url)
    return resolve(secure_url)
  }
)

interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}
type ProgressFn = (percent: number) => void;

type UploadHandler = (blobInfo: BlobInfo, progress: ProgressFn) => Promise<string>;