import { useFormikContext } from 'formik';

import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import { Box } from '@mui/material';
import Question from './reusable-components/Question';
import BoldSpan from 'components/utilities/BoldSpan';

export default function InputOccupation() {
  const { getFieldProps, touched, errors } = useFormikContext<StepperState>();
  const isValidationError = Boolean(touched.occupation && errors.occupation);
  const textFieldHelperText = isValidationError ? errors.occupation : '';
  const mt = { xs: 0, md: 1 };
  return (
    <Box>
      <Question
        required
        currentStep={1}
        helperText='We’ll use AI to help build the website'
      >
        <>
          Describe your&nbsp;
          <BoldSpan>
            <>business</>
          </BoldSpan>
        </>
      </Question>
      <Box>
        <TextFieldSyncWithTheme
          error={isValidationError}
          multiline
          autoFocus
          maxRows={7}
          variant='outlined'
          fullWidth
          aria-label='description of services'
          placeholder='Enter your occupation and services'
          minRows={2}
          {...getFieldProps('occupation')}
          helperText={textFieldHelperText}
          FormHelperTextProps={{
            sx: {
              display: 'block',
              fontSize: '1.35rem',
            },
          }}
        />
      </Box>
    </Box>
  );
}
