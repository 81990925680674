import React, { useRef } from 'react';
import { FormikProvider } from 'formik';
import useCreateEditService from './hooks/useCreateEditService';
import CreateEditBase from 'components/SPM_Forms/base/CreateEditBase';

const CreateEditService: React.FC = () => {
  const {
    isServiceCreateMode,
    currency,
    symbol,
    exchangeRates,
    formik,
    utilities,
    getUpdatedSkills,
  } = useCreateEditService();

  return (
    <FormikProvider value={formik}>
      <CreateEditBase
        isCreateMode={isServiceCreateMode}
        entityName='service'
        symbol={symbol ?? ''}
        currency={currency ?? ''}
        exchangeRates={typeof exchangeRates === 'number' ? exchangeRates : 0}
        getUpdatedSkills={getUpdatedSkills}
        goToEditorPage={utilities.goToEditorPage}
        isFormPrimaryActionInProgress={utilities.isFormPrimaryActionInProgress}
      >
        <FormContent />
      </CreateEditBase>
    </FormikProvider>
  );
};

const FormContent: React.FC<any> = ({}) => {
  return <></>;
};

export default CreateEditService;
