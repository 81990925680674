import React from 'react';
import SPMCard from 'components/MUI_SPM/SPMCard/SPMCard';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import {
  InitialDeleteEntityDialogState,
  SPMDeleteAction,
} from 'components/MUI_SPM/SPMCard/sub-components/SPMDeletionConfirmationDialog/hooks/useSPMDeletionConfirmationDialog';
import { useGetServicesByUserIdQuery } from 'store/rtkqFeatures/api/serviceExtendedApi';
import { ServiceTypes } from 'types/service';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 1000,
  hideProgressBar: true,
};

type ServiceProps = {
  serviceId: string;
  editorId: string;
  setDeleteEntityDialogState: React.Dispatch<
    React.SetStateAction<InitialDeleteEntityDialogState>
  >;
};

const Service: React.FunctionComponent<ServiceProps> = ({
  serviceId,
  editorId,
  setDeleteEntityDialogState,
}) => {
  const isCardRenderValid = editorId && serviceId;
  const history = useHistory();
  const { languageDir } = useGetLoggedInUser();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  // !! making store of type any until we finalize our structure
  const { storeStylings }: { storeStylings: any } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      storeStylings: themes
        ? themes[localCurrentThemeIndex].store
        : initialThemeState.store,
    }),
  });
  const { thisService } = useGetServicesByUserIdQuery(editorId, {
    skip: !isCardRenderValid,
    selectFromResult: ({ data }) => ({
      thisService: data?.find(({ _id }) => {
        return _id === serviceId;
      }),
    }),
  });

  if (!thisService) return <></>;

  const isVirtualService = thisService.type === ServiceTypes.Virtual;
  const editEntityPathNameType = isVirtualService ? 'v' : 'p';
  const editActionRedirectPath = `/edit-service-${editEntityPathNameType}`;
  const editActionSearchParams = `?serviceId=${serviceId}`;

  const handleDeleteActionBtnClick = async () => {
    setDeleteEntityDialogState({
      isDialogOpen: true,
      toBeDeletedEntityId: serviceId,
      deleteAction: SPMDeleteAction.DeleteService,
    });
  };

  const handleServiceActionBtnClick = () => {
    if (!serviceId) {
      toast.error('Unable to do service checkout!!', TOAST_MESSAGE_SETTINGS);
      console.error(
        'Unable to do service checkout because serviceId not found, serviceId: ',
        serviceId
      );
      return;
    }
    if (!editorId) {
      toast.error('Unable to do service checkout!!', TOAST_MESSAGE_SETTINGS);
      console.error(
        'Unable to do service checkout because editorId not found, editorId: ',
        editorId
      );
      return;
    }
    if (languageDir === 'rtl') {
      history.push(`/service/${serviceId}/checkout?editor-id=${editorId}&dir=rtl`);
      return;
    }
    history.push(`/service/${serviceId}/checkout?editor-id=${editorId}`);
  };

  return (
    <SPMCard
      key={thisService._id}
      onCTABtnClick={handleServiceActionBtnClick}
      styling={storeStylings}
      price={thisService.price}
      cardImg={thisService.img}
      isPaid={thisService.isPaid}
      cardTitle={thisService.title}
      cardEntityId={thisService._id}
      cardIncludedItems={thisService.included}
      cardDescriptionContent={thisService.desc}
      cardCTAButtonText={thisService.ui.ctaBtnText}
      editActionRedirectPath={editActionRedirectPath}
      editActionSearchParams={editActionSearchParams}
      isEntityActive={thisService.status === 'active'}
      handleCardDeleteAction={handleDeleteActionBtnClick}
      cardIncludedItemsHeading={thisService.ui.includedItemsHeading}
    />
  );
};
export default Service;
