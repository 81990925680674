import { AutoCompleteGuestLocationDetails } from 'components/utilities/GoogleLocationAutoCompleteInput/GoogleLocationAutoCompleteInput';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import reduxStore from 'store/store';
import { Session } from 'types/sessions';
import {
  ProposeNewTime,
  Timezone,
} from 'views/DashboardActions/ProposeNewTime/hooks/useProposeNewTime';

export const sessionExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCurrentSessionsBySessionId: build.query<Session, string>({
      query: (sessionId) => `/sessions/${sessionId}`,
      providesTags: (result, error, arg) =>
        result
          ? [{ type: 'Session' as const, id: result._id }]
          : [{ type: 'Session' as const, id: 'CurrentSession' }],
    }),

    updateCurrentSessionsBySessionId: build.mutation<void, ProposeNewTimeQueryType>({
      query: (args) => ({
        url: `/sessions/propose-new-time/${args.sessionId}`,
        method: 'PATCH',
        body: {
          proposedTime: args.proposedTime,
          timezone: args.timezone,
          proposedTimeCreatedAt: args.proposedTimeCreatedAt,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Session', id: arg.sessionId }],
    }),

    getSessionsByUserId: build.query<Session[], string>({
      query: (userId) => `/sessions/user/${userId}`,
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: 'Session' as const, id: 'LIST' },
              ...result.map(({ _id }) => ({
                type: 'Session' as const,
                id: _id,
              })),
            ]
          : [{ type: 'Session' as const, id: 'LIST' }],
    }),
    updateSessionStatus: build.mutation<void, Record<'sessionId' | 'status', string>>({
      query: (arg) => ({
        url: `/sessions/${arg.sessionId}/status`,
        method: 'PATCH',
        body: {
          status: arg.status,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Session', id: arg.sessionId }],
    }),

    updateMeeingPInstructionsOrLocationDetails: build.mutation<
      void,
      {
        sessionId: string;
        meetingExtraInstructions: string;
        inPersonMeetingGuestLocationDetails: AutoCompleteGuestLocationDetails;
      }
    >({
      query: ({
        sessionId,
        meetingExtraInstructions,
        inPersonMeetingGuestLocationDetails,
      }) => ({
        url: `/sessions/${sessionId}/inperson-meeting-location-details-or-instructions`,
        method: 'PATCH',
        body: {
          meetingExtraInstructions,
          inPersonMeetingGuestLocationDetails,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Session', id: arg.sessionId }],
    }),

    updateSessionHostGuestMeetingDetails: build.mutation<
      void,
      Record<'sessionId', string> & Pick<Session, 'hostGuestMeetingDetails'>
    >({
      query: ({ sessionId, hostGuestMeetingDetails }) => ({
        url: `/sessions/${sessionId}/host-guest-meeting-details`,
        method: 'PATCH',
        body: hostGuestMeetingDetails,
      }),
      invalidatesTags: (result, error, { sessionId }) => [
        { type: 'Session', id: sessionId },
      ],
    }),
    updateSessionArchived: build.mutation<
      void,
      Record<'sessionId', string> & Pick<Session, 'isArchived'>
    >({
      query: ({ sessionId, isArchived }) => ({
        url: `/sessions/${sessionId}/is-archived`,
        method: 'PATCH',
        body: {
          isArchived,
        },
      }),
      invalidatesTags: (result, error, { sessionId }) => [
        { type: 'Session', id: sessionId },
      ],
    }),
    deleteSession: build.mutation<{ isDeleted: string; sessionId: string }, string>({
      query: (sessionId) => ({
        url: `/sessions/${sessionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Session', id: arg }],
    }),
  }),
});

export default sessionExtendedApi;

export const {
  useGetCurrentSessionsBySessionIdQuery,
  useGetSessionsByUserIdQuery,
  useUpdateSessionArchivedMutation,
  useUpdateSessionStatusMutation,
  useUpdateCurrentSessionsBySessionIdMutation,
  useUpdateMeeingPInstructionsOrLocationDetailsMutation,
  useUpdateSessionHostGuestMeetingDetailsMutation,
  useDeleteSessionMutation,
} = sessionExtendedApi;

const loggedInUserId = selectLoggedInUserId(reduxStore.getState());

export const selectUserSessionsQueryResult =
  sessionExtendedApi.endpoints.getSessionsByUserId.select(loggedInUserId); // returns query result object

// session selectors

type ProposeNewTimeQueryType = {
  sessionId: string;
  proposedTime: ProposeNewTime;
  timezone: Timezone;
  proposedTimeCreatedAt: number;
};
