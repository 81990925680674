import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import useGuestSessionServices from 'views/guestSession/GuestSession/sub-components/GuestSessionActions/hooks/useGuestSessionServices';
import { ValidationState } from 'views/guestSession/ValidateGuestSession/context/ValidationContextProvider';

type ThisProps = {
  sessionCancellationStatus: ValidationState['sessionCancellationStatus'];
};

const CancelledSessionPoppup = ({ sessionCancellationStatus }: ThisProps) => {
  const [open, setOpen] = useState(true);
  const { id: sessionId } = useParams<Record<'id', string>>();
  const { handleSessionLogout } = useGuestSessionServices(sessionId);

  if (sessionCancellationStatus === 'idle') return <></>;

  const popupUserMsg =
    sessionCancellationStatus === 'cancelled'
      ? 'Session has been cancelled.'
      : 'Session got declined by the host';

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {},
      }}
    >
      <DialogTitle
        sx={{
          fontSize: {
            xs: '',
            sm: '2.6rem',
          },
        }}
      >
        Alert
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {popupUserMsg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color='primary'
          variant='contained'
          sx={{
            fontSize: {
              xs: '1.5rem',
              sm: '1.62rem',
            },
          }}
          onClick={handleSessionLogout}
        >
          Logout
        </Button>
        <Button
          variant='contained'
          sx={{
            fontSize: {
              xs: '1.5rem',
              sm: '1.62rem',
            },
          }}
          onClick={() => setOpen(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelledSessionPoppup;
