import errorHandlingUtilities from 'utilities/errorHandlingUtilities';

type OnboardErrorDataType = {
  isOnboardProcessCompleted: boolean;
  stripeOnboardLink: string;
};

type PromiseFunction = () => Promise<any>;

// NOTE: Only to be used with RTK Query APIs

const useHandleStripeOnboardError = () => {
  const handleStripeOnboardError = async (promiseFunction: PromiseFunction) => {
    try {
      await promiseFunction();
    } catch (error) {
      console.error('in useHandleStripeOnboardError catch block: ', error);
      const isRTKQueryAPIError =
        errorHandlingUtilities.isRTKQueryAPIError<OnboardErrorDataType>(error, [
          'isOnboardProcessCompleted',
          'stripeOnboardLink',
        ]);
      // console.log('XXX01 | isRTKQueryAPIError: ', isRTKQueryAPIError);
      if (isRTKQueryAPIError) {
        // console.log('XXX02');
        const errorStatus = error.status;
        // console.log('XXX03 | errorStatus: ', errorStatus);
        const errorData = error.data;
        if (errorStatus === 403) {
          // console.log('XXX04 | Handling error status 403');
          const isOnboardingProcessNotCompleted = !errorData.isOnboardProcessCompleted;
          if (isOnboardingProcessNotCompleted) {
            // console.log(
            //   'XXX05 | isOnboardingProcessNotCompleted: ',
            //   isOnboardingProcessNotCompleted
            // );
            const onboardLink = errorData.stripeOnboardLink;
            onboardLink && window.location.replace(onboardLink);
          }
        }
      }
    }
  };

  return {
    handleStripeOnboardError,
  };
};

export default useHandleStripeOnboardError;
