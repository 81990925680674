import { Alert, AlertTitle, Stack, Typography } from '@mui/material';
import IfElse from 'components/utilities/IfElse';
import React from 'react';
import { useGetMeetingsByUserIdQuery } from 'store/rtkqFeatures/api/meetingExtendedApi';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { grey } from '@mui/material/colors';
import { PhysicalMeetingHostOptedOptions } from 'types/meeting';

type MeetingsHighPriorityContextProps = {
  editorId: string;
  meetingId: string;
};

const MeetingsHighPriorityContext: React.FunctionComponent<
  MeetingsHighPriorityContextProps
> = ({ editorId, meetingId }) => {
  const { thisMeeting } = useGetMeetingsByUserIdQuery(editorId, {
    selectFromResult: ({ data }) => ({
      thisMeeting: data?.find(({ _id }) => {
        return _id === meetingId;
      }),
    }),
  });

  if (!thisMeeting) return <></>;

  const isMeetingInPersonAndHostOptedOptionGuestCheckout =
    thisMeeting.type === 'MEETING-P' &&
    thisMeeting.hostDetails.optedOption ===
      PhysicalMeetingHostOptedOptions.GUEST_LOCATION_CHECKOUT;
  const inPersonMeetingBookingInstructions = thisMeeting.guestMeetingLocationInstructions;
  const isLocationMapLinkVisible =
    !!thisMeeting.showLinkOrLocationDetailsToGuest &&
    (thisMeeting.hostDetails.optedOption === PhysicalMeetingHostOptedOptions.HOST_LOCATION_ALL_SESSIONS) &&
    !!thisMeeting.inPersonMeetingGuestLocationDetails?.suggestion &&
    !!thisMeeting.inPersonMeetingGuestLocationDetails?.googleMapsLocationUrl;

  const areMeetingLocationInstructionsAvailable =
    isMeetingInPersonAndHostOptedOptionGuestCheckout &&
    !!inPersonMeetingBookingInstructions;

  if (!areMeetingLocationInstructionsAvailable && !isLocationMapLinkVisible) return <></>;

  return (
    <Stack marginTop='2rem' spacing={1}>
      {/* ---------- MEETING-P place with google maps link ---------------- */}
      <IfElse condition={isLocationMapLinkVisible}>
        <Alert
          severity='info'
          icon={
            <PinDropIcon
              fontSize='large'
              sx={{
                color: 'common.black',
              }}
            />
          }
        >
          <Typography variant='body2'>
            <a
              href={
                thisMeeting.inPersonMeetingGuestLocationDetails?.googleMapsLocationUrl ||
                ''
              }
              target='_blank'
              rel='noreferrer'
            >
              {thisMeeting.inPersonMeetingGuestLocationDetails?.suggestion}
            </a>
          </Typography>
        </Alert>
      </IfElse>
    </Stack>
  );
};

export default MeetingsHighPriorityContext;
