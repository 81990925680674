import { storeDispatch } from 'store/store';
import portfolioExtendedApi from 'store/rtkqFeatures/api/portfolioExtendedApi';
import editorExtendedApi from 'store/rtkqFeatures/api/editorExtendedApi';
import meetingExtendedApi from 'store/rtkqFeatures/api/meetingExtendedApi';
import spmSportedExtendedApi from 'store/rtkqFeatures/api/spmSportedExtendedApi';
import stripeConnectedAccountExtendedApi from 'store/rtkqFeatures/api/stripeConnectedAccountExtendedApi';
import stripePlatformCustomerExtendedApi from 'store/rtkqFeatures/api/stripePlatformCustomerExtendedApi';
import serviceOrdersExtendedApi from 'store/rtkqFeatures/api/serviceOrdersExtendedApi';
import sessionExtendedApi from 'store/rtkqFeatures/api/SessionExtendedApi';
import { setLoggedInUser } from 'store/features/user/userSlice';
import { LoggedInUser } from 'types/user';

// NOTE: main utility
export async function sucessfulLoginInit({ editorId }: { editorId: string }) {
  try {
    await fetchLoggedinUserModulesData(editorId);
  } catch (error) {
    // console.log('error occurred at sucessfulLoginInit() | error: ', error);
    throw error;
  }
}

export async function fetchLoggedinUserModulesData(editorId: string) {
  try {
    if (!editorId) throw new Error('editorId not found');
    // console.log('fetching all modules for editorId: ', editorId);
    // don't change the order of the dispatches
    const editorModulesData = await Promise.allSettled([
      storeDispatch(
        editorExtendedApi.endpoints.getEditorById.initiate(editorId)
      ).unwrap(),
      storeDispatch(
        portfolioExtendedApi.endpoints.getPortfoliosByUserId.initiate(editorId)
      ).unwrap(),
      storeDispatch(
        meetingExtendedApi.endpoints.getMeetingsByUserId.initiate(editorId)
      ).unwrap(),
      storeDispatch(
        stripePlatformCustomerExtendedApi.endpoints.getStripePlatformCustomer.initiate(
          null
        )
      ).unwrap(),
      storeDispatch(
        sessionExtendedApi.endpoints.getSessionsByUserId.initiate(editorId)
      ).unwrap(),
      storeDispatch(
        spmSportedExtendedApi.endpoints.getSortedSPMByEditorId.initiate(editorId)
      ).unwrap(),
      storeDispatch(
        stripeConnectedAccountExtendedApi.endpoints.getStripeConnectedAccountByEditorId.initiate(
          editorId
        )
      ).unwrap(),
      storeDispatch(
        serviceOrdersExtendedApi.endpoints.getServiceOrdersByUserId.initiate(editorId)
      ).unwrap(),
    ]);

    // Process the result
    const results = editorModulesData.map((each, i) => {
      if (each.status === 'fulfilled') {
        return each.value;
      } else {
        console.error('Request failed - ', each.reason);
        return false;
      }
    });

    const loggedInUser = results[0];
    // setting user in redux user slice data
    if (loggedInUser) {
      storeDispatch(setLoggedInUser(loggedInUser as LoggedInUser));
    }
  } catch (error) {
    throw error;
  }
}

export async function loadEditorProfileData(editorId: string) {
  try {
    if (!editorId) throw new Error('editorId not found');
    return await storeDispatch(
      editorExtendedApi.endpoints.getEditorById.initiate(editorId)
    ).unwrap();
  } catch (error) {
    throw error;
  }
}