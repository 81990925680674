import { useState } from 'react';

const useHandleImgOnError = () => {
  const [showImage, setShowImage] = useState(true);

  const handleImageError = () => {
    setShowImage(false);
  };

  return {
    showImage,
    handleImageError, 
  }
}

export default useHandleImgOnError;