type TransformationParamsType = {
  url: string;
  width: number;
  height?: number;
  color?: {
    colorCode: string;
    alpha: number;
  };
};

function addTransformationToImageUrl({
  color,
  url,
  width,
  height,
}: TransformationParamsType): string {
  const regex = /image\/upload\//;
  if (regex.test(url)) {
    if (color) {
      return url.replace(
        regex,
        `image/upload/co_rgb:${color.colorCode},e_colorize:${color.alpha}/w_${width}/`
      );
    }
    return url.replace(regex, `image/upload/w_${width}/h_${height || width}/`);
  }
  return url;
}

function extractPublicIdFromCloudinaryURL(url: string) {
  const match = url.match(/\/v\d+\/([^/]+)\.\w+$/);
  let publicId;

  if (match) {
    publicId = match[1];
    console.log(publicId);
  } else {
    console.error('Could not extract public_id from the Cloudinary URL');
  }

  return publicId;
}
// co_rgb:f3f3f3,e_colorize:50

function getImageSize(files: FileList) {
  let height = 0;
  let width = 0;
  const reader = new FileReader();

  //Read the contents of Image File.
  reader.readAsDataURL(files[0]);
  reader.onload = function (e: ProgressEvent<FileReader>) {
    //Initiate the JavaScript Image object.
    let image = new Image();

    if (image && typeof e.target?.result === 'string') {
      //Set the Base64 string return from FileReader as source.
      image.src = e.target?.result || '';
    }

    //Validate the File Height and Width.
    image.onload = function () {
      height = image.height;
      width = image.width;
    };
  };

  return { height, width };
}

const cloudinaryUtilsFns = {
  addTransformationToImageUrl,
  getImageSize,
  extractPublicIdFromCloudinaryURL,
};

export default cloudinaryUtilsFns;
