import serviceDefaults from 'defaults/serviceDefaults';
import {
  PhysicalService,
  PhysicalServiceOptedOptions,
  ServiceTypes,
  VirtualService,
} from 'types/service';

export const initialVirtualService: VirtualService = {
  _id: '',
  userId: '',
  isDeleted: false,
  status: 'inactive',
  type: ServiceTypes.Virtual,
  title: '',
  desc: '',
  included: [],
  img: serviceDefaults.digitalServiceImg,
  isPaid: true,
  price: 100,
  ui: {
    ctaBtnText: 'Checkout',
    includedItemsHeading: `What's Included`,
    tagTitle: '',
  },
};

export const initialPhysicalService: PhysicalService = {
  _id: '',
  userId: '',
  isDeleted: false,
  status: 'inactive',
  type: ServiceTypes.Physical,
  title: '',
  desc: '',
  included: [],
  img: serviceDefaults.inPersonServiceImg,
  isPaid: true,
  price: 100,
  location: {
    optedOption: PhysicalServiceOptedOptions.Host_Service_Location_All,
  },
  ui: {
    ctaBtnText: '',
    includedItemsHeading: '',
    tagTitle: '',
  },
};
