import { Box } from '@mui/material';
import { useFormikContext } from 'formik';

import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import BoldSpan from 'components/utilities/BoldSpan';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';
import Question from './reusable-components/Question';

export default function InputName() {
  const { getFieldProps, touched, errors } = useFormikContext<StepperState>();
  const isValidationError = Boolean(touched.name && errors.name);
  const textFieldHelperText = isValidationError ? errors.name : '';

  return (
    <Box>
      <Question
        required
        currentStep={2}
        helperText='This will appear first on the website and in search results'
      >
        <>
          {' '}
          Enter <BoldSpan>your name</BoldSpan> or <BoldSpan>business name</BoldSpan>
        </>
      </Question>
      <TextFieldSyncWithTheme
        error={isValidationError}
        variant='outlined'
        fullWidth
        autoFocus
        placeholder='Enter your name or company name'
        helperText={textFieldHelperText}
        {...getFieldProps('name')}
      />
    </Box>
  );
}
