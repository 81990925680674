import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { Stack } from '@mui/material';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import StyledItemLabel from 'components/shared/StyledItemLabel';
import UploadImage from 'components/utilities/Cloudinary/UploadImage';

const UserFavIconImgUploader = () => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<EditUserDetailsForm>();
  const { userFavIcon, files } = values;

  const handleImgUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toastId = toast.loading('Uploading favicon...');
    // save it to value.file.userFavIcon
    const file = e.target.files?.[0];
    if (userFavIcon) {
      setFieldValue('deletedImgs.userDashboardImg', userFavIcon);
      setFieldTouched('deletedImgs.userDashboardImg', true);
    }
    setFieldValue('files.userFavIcon', file);
    setFieldTouched('files', true);
    toast.dismiss(toastId);
    toast.success('Image uploaded successfully');
  };

  const removeFile = async (url: string | File) => {
    if (typeof url === 'string') {
      setFieldValue('deletedImgs.userFavIcon', url);
      setFieldValue('userFavIcon', '');
      setFieldTouched('userFavIcon', true);
    } else {
      setFieldValue('files.userFavIcon', null);
      setFieldTouched('files', true);
    }
  };

  return (
    <Stack gap={1} flexWrap={'wrap'} direction='row' justifyContent={'stretch'}>
      <StyledItemLabel
        label='Icon'
        secondaryLabel={
          <span>
            Upload site favicon
          </span>
        }
      />
      <UploadImage
        imgUploadCallback={handleImgUpload}
        removeCallback={removeFile}
        img={files?.userFavIcon ?? userFavIcon}
      />
    </Stack>
  );
};

export default UserFavIconImgUploader;
