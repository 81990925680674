import React from 'react';
import Switch from 'react-switch';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import StyledItemLabel from 'components/shared/StyledItemLabel';

const AuthTagVisbileSwitch = () => {
  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<EditUserDetailsForm>();
  const { cachedLoggedInUser: user } = useGetLoggedInUser();
  const isAuthTypeFb = Boolean(user?.authType === 'fb');

  if (!isAuthTypeFb) return <></>;

  const handleAuthTagSwitch = (checked: boolean) => {
    setFieldTouched('isAuthTagVisible', true);
    setFieldValue('isAuthTagVisible', checked);
  };

  return (
    <Grid item xs={12} sm={6}>
      <Box>
        <StyledItemLabel label='Facebook profile is visible' />
        <Box>
          <Switch
            checked={Boolean(values.isAuthTagVisible)}
            onChange={handleAuthTagSwitch}
            onColor='#3b3b3b'
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default AuthTagVisbileSwitch;
