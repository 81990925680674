import serverApi from 'lib/apiConfig';
import { ChangeEvent } from 'react';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import loginTokenUtils from 'utilities/loginTokenUtils';

export const regexForGifType = /[^\/\\]+(?:\.gif)/g;

let cloudinaryConfig = {
  CLOUD_NAME: 'salesfronts',
  UPLOAD_PRESET: 'sales_fronts',
};

async function uploadImg(ev: ChangeEvent<HTMLInputElement>) {
  const loginToken = loginTokenUtils.getLocalStorageLoginToken();
  if (!ev.target.files?.length) {
    throw new Error('No image found to be uploaded');
  }

  const toBeUploadedImg = ev.target.files[0];
  const FORM_DATA = new FormData();
  FORM_DATA.append('file', toBeUploadedImg);
  FORM_DATA.append('upload_preset', cloudinaryConfig.UPLOAD_PRESET);

  try {
    const res = await serverApi.cloudinary.post('/upload', FORM_DATA, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${loginToken}`,
      },
    });
    const secure_url = res.data;
    return secure_url;
  } catch (error) {
    if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
      console.error('ERROR: while uploading img below | Error: ', error.message);
      throw new Error(error.message);
    }
  }
}

async function uploadImageWithAuthentication(file: File | Blob) {
  const loginToken = loginTokenUtils.getLocalStorageLoginToken();

  if (!file) {
    throw new Error('No image found to be uploaded');
  }
  const toBeUploadedImg = file;
  const FORM_DATA = new FormData();
  FORM_DATA.append('file', toBeUploadedImg);
  FORM_DATA.append('upload_preset', cloudinaryConfig.UPLOAD_PRESET);

  try {
    const res = await serverApi.cloudinary.post('/upload', FORM_DATA, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${loginToken}`,
      },
    });
    const secure_url = res.data;
    return secure_url;
  } catch (error) {
    if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
      console.error('ERROR: while uploading img below | Error: ', error.message);
      throw new Error(error.message);
    }
  }
}

async function uploadPdf(file: File) {
  const toBeUploadedPdf = file;
  const FORM_DATA = new FormData();
  FORM_DATA.append('file', toBeUploadedPdf);
  FORM_DATA.append('upload_preset', cloudinaryConfig.UPLOAD_PRESET);

  try {
    const res = await serverApi.cloudinary.post('/upload', FORM_DATA);
    const uploadedPdfURL = res.data.secure_url;
    if (!uploadedPdfURL) throw new Error('Uploaded pdf URL not found in API response!!');
    return res.data.secure_url;
  } catch (error) {
    if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
      console.error('ERROR: while uploading pdf below to cloudinary: ', error.message);
      throw new Error(error.message);
    }
  }
}

class RequestEntityTooLargeError extends Error {
  statusCode: number;

  constructor(message: string) {
    super(message);
    this.name = 'RequestEntityTooLargeError';
    this.statusCode = 413;
  }
}

async function uploadVideo(file: File): Promise<string | undefined> {
  if (file.size > 10000000) {
    console.error('Video size is greater than 10MB');
    throw new RequestEntityTooLargeError('Video size is greater than 10MB');
  }
  const toBeUploadedVideo = file;
  const FORM_DATA = new FormData();
  FORM_DATA.append('file', toBeUploadedVideo);
  FORM_DATA.append('upload_preset', cloudinaryConfig.UPLOAD_PRESET);

  try {
    const res = await serverApi.cloudinary.post('/upload', FORM_DATA);
    const uploadedVideoURL = res.data.secure_url;
    if (!uploadedVideoURL)
      throw new Error('Uploaded video URL not found in API response!!');
    return res.data.secure_url;
  } catch (error) {
    if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
      console.error('ERROR: while uploading video below to cloudinary: ', error.message);
      throw new Error(error.message);
    }
  }
}

async function deleteCloudinaryImgWithPublicId(publicId: string) {
  try {
    const res = await serverApi.basic.delete(`/cloudinary/delete/${publicId}`);
    if (res.data.result === 'not found') {
      console.error('Image not found in cloudinary');
    }
    return true;
  } catch (error) {
    console.error('ERROR: while deleting image from db: ', error);
    throw new Error('Error while deleting image from db');
  }
}

async function deleteImg(secure_url: string) {
  try {
    const res = await serverApi.cloudinary.get(`/delete`, {
      params: {
        secure_url,
      },
    });
    if (res.data.result === 'not found') {
      console.error('Image not found in cloudinary');
    }
    return true;
  } catch (error) {
    console.error('ERROR: while deleting image from db: ', error);
    throw new Error('Error while deleting image from db');
  }
}

async function deleteMultipleImgs(secure_urls: string[]) {
  try {
    const res = await serverApi.cloudinary.post(`/delete-multiple-assets`, {
      secure_urls,
    });
    if (res.data.result === 'not found') {
      console.error('Image not found in cloudinary');
    }
    return true;
  } catch (error) {
    console.error('ERROR: while deleting image from db: ', error);
    throw new Error('Error while deleting image from db');
  }
}

// NOTE - This function is only for temporary use will be removed in future
/**
 * This function is only for temporary use will be removed in future
 * @param ev 
 * @returns 
 */
async function uploadImageWithoutAuthentication(file: File | Blob, public_id?: string) {  
  if (!file) {
    throw new Error('No image found to be uploaded');
  }
  const toBeUploadedImg = file;
  const FORM_DATA = new FormData();
  FORM_DATA.append('file', toBeUploadedImg);
  FORM_DATA.append('upload_preset', cloudinaryConfig.UPLOAD_PRESET);

  if (public_id) {
    FORM_DATA.append('public_id', public_id);
  }

  try {
    const res = await serverApi.public.post('/cloudinary/upload', FORM_DATA, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    const secure_url = res.data;
    return secure_url;
  } catch (error) {
    if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
      console.error('ERROR: while uploading img below | Error: ', error.message);
      throw new Error(error.message);
    }
  }
}

const uploadImageService = {
  uploadImg,
  uploadImageWithAuthentication,
  uploadPdf,
  uploadVideo,
  deleteImg,
  deleteMultipleImgs,
  uploadImageWithoutAuthentication,
  deleteCloudinaryImgWithPublicId,
};

export default uploadImageService;
