import React from 'react';

import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { EditUserDetailsForm } from 'components/EditUserDetailsLatest/types';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import useBooleanStateController from 'hooks/useBooleanStateController';

import SaveCancelButtons from 'components/Buttons/SaveCancelButtons';

const EUDActionBtns = () => {
  const history = useHistory();
  const [showLostChangesDlg, showLostChangesDlgController] = useBooleanStateController();
  const { cachedLoggedInUser: user } = useGetLoggedInUser();
  const {
    isValid: isFormErrorFree,
    isSubmitting,
    touched,
  } = useFormikContext<EditUserDetailsForm>();

  const isFormTouched: boolean = React.useMemo(
    () => Object.values(touched).some((key) => key === true),
    [touched]
  );
  const userURL = user?.url;

  const goBack = () => {
    const backURL = userURL ? `/${userURL}` : '/sign-in';
    history.replace(backURL);
  };

  const openConfirmDiscardChangesPopup = () => {
    showLostChangesDlgController.setToggle();
  };

  return (
    <>
      <SaveCancelButtons
        goBack={goBack}
        showLostChangesDlg={showLostChangesDlg}
        openConfirmDiscardChangesPopup={openConfirmDiscardChangesPopup}
        dialogTitle='You have unsaved changes!'
        dialogChildren={
          <>
            If you close this dialog, you will lose all the changes you made.
            <br />
            <br />
            Are you sure you want to close?
          </>
        }
        cancelBtnChildren={isFormTouched ? 'Cancel' : 'Close'}
        cancelBtnProps={{
          size: 'large',
          variant: 'text',
          color: 'secondary',
          onClick: isFormTouched ? openConfirmDiscardChangesPopup : goBack
        }}
        saveBtnChildren='Save'
        saveBtnProps={{
          size: 'large',
          variant: 'contained',
          color: 'primary',
          type: 'submit',
          disabled: !isFormTouched || !isFormErrorFree || isSubmitting
        }}        
      />
    </>
  );
};

export default EUDActionBtns;
