export type GenericAction<Type, Payload> = {
  type: Type,
  payload: Payload,
}

export type TentativeAvailabilityState = {
  slots: string[],
  format: 12 | 24,
  bookingUpTo: number,
  isWeekDayClick: boolean,
  addAvailability: boolean,
  repeatWeekly: boolean,
  count: number,
  error: string,
}

type UpdateSlots = GenericAction<'updateSlots', TentativeAvailabilityState['slots']>;
type UpdateBookingUpTo = GenericAction<'updateBookingUpTo', number>;
type UpdateIsWeekDayClick = GenericAction<'updateIsWeekDayClick', boolean>;
type UpdateAddAvailability = GenericAction<'updateAddAvailability', boolean>;
type UpdateCount = GenericAction<'updateCount', number>;
type UpdateRepeatWeekly = GenericAction<'updateRepeatWeekly', boolean>;
type UpdateFormat = GenericAction<'updateFormat', TentativeAvailabilityState['format']>;
type UpdateError = GenericAction<'updateError', TentativeAvailabilityState['error']>;
type UpdateAddAvailabilityAndBookingUpto = GenericAction<'updateAddAvailabilityAndBookingUpto', Pick<TentativeAvailabilityState, 'addAvailability' | 'bookingUpTo'>>;

export type TentativeAvailabilityActions = UpdateSlots | UpdateBookingUpTo | UpdateIsWeekDayClick | UpdateAddAvailability | UpdateCount | UpdateRepeatWeekly | UpdateFormat | UpdateError | UpdateAddAvailabilityAndBookingUpto;

export const intialTentativeAvailabilityStates: TentativeAvailabilityState = {
  slots: [''],
  bookingUpTo: 20,
  isWeekDayClick: false,
  addAvailability: false,
  repeatWeekly: false,
  count: 0,
  format: 12,
  error: '',
}

function TentativeAvailabilityReducer(state: TentativeAvailabilityState, action: TentativeAvailabilityActions): TentativeAvailabilityState {

  const { type, payload } = action;

  if (type === 'updateSlots') {
    return {
      ...state,
      slots: payload,
    }

  } else if (type === 'updateBookingUpTo') {
    return {
      ...state,
      bookingUpTo: payload,
    }
  } else if (type === 'updateIsWeekDayClick') {
    return {
      ...state,
      isWeekDayClick: state.error ? false : payload,
    }
  } else if (type === 'updateAddAvailability') {
    return {
      ...state,
      addAvailability: payload,
    }
  } else if (type === 'updateCount') {
    return {
      ...state,
      count: payload,
    }
  } else if (type === 'updateRepeatWeekly') {
    return {
      ...state,
      repeatWeekly: payload
    }
  } else if (type === 'updateFormat') {
    return {
      ...state,
      format: payload
    }
  } else if (type === 'updateError') {
    return {
      ...state,
      error: payload
    }
  } else if (type === 'updateAddAvailabilityAndBookingUpto') {
    return {
      ...state,
      addAvailability: payload.addAvailability,
      bookingUpTo: payload.bookingUpTo,
    }
  }
  else {
    return state;
  }
}

export default TentativeAvailabilityReducer;