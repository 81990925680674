import React from 'react';
import { useHistory } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { SxProps, Theme } from '@mui/material';

import ResponsiveButton from 'components/Buttons/ResponsiveButton';

type AddStoreEntitiesBtnProps = {
  styling?: SxProps<Theme>;
};

const AddStoreEntitiesBtn: React.FunctionComponent<AddStoreEntitiesBtnProps> = ({styling}) => {
  const history = useHistory();

  const handleAddStoreEntitiesBtnClick = () => {
    history.push('/add-item');
  };

  return (
    <ResponsiveButton
      variant='contained'
      onClick={handleAddStoreEntitiesBtnClick}
      endIcon={<AddCircleOutlineOutlinedIcon />}
      color='primary'
      sx={{
        ...styling,
      }}
    >
      Add
    </ResponsiveButton>
  );
};

export default AddStoreEntitiesBtn;
