import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetServicesByUserIdQuery } from 'store/rtkqFeatures/api/serviceExtendedApi';
import { initialVirtualService } from 'store/features/services/sliceInitializations';
import { EditCreateMode } from 'types/spm';
import { StoreItemTypes } from 'types/storeItem';
import { useCreateEditBase } from 'components/SPM_Forms/base/hooks/useCreateEditBase';
import {
  createEditedServiceUpdatedContext,
  createNewService,
  getFormInitialValues,
} from '../utils';
import { selectModalModule } from 'store/features/modal/modalSlice';

const useCreateEditService = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { showModal, modalData } = useAppSelector(selectModalModule);
  const locationData = useLocation();
  const searchParams = new URLSearchParams(locationData.search);
  const editActionServiceId = searchParams.get('serviceId');

  const isCreateService = !!!editActionServiceId;
  const serviceMode: EditCreateMode = isCreateService
    ? EditCreateMode.Create
    : EditCreateMode.Edit;
  const isServiceEditMode = serviceMode === EditCreateMode.Edit;
  const isServiceCreateMode = serviceMode === EditCreateMode.Create;

  const { data: services } = useGetServicesByUserIdQuery(editorId, {
    skip: !editorId,
  });

  const editActionService =
    services?.find(({ _id: serviceId }) => serviceId === editActionServiceId) ??
    initialVirtualService;

  const baseHook = useCreateEditBase({
    storeItemType: StoreItemTypes.ServiceVirtual,
    entityType: 'service',
    getInitialValues: (
      isCreateMode,
      entityType,
      editActionEntity,
      exchangeRates
    ) =>
      getFormInitialValues(
        isCreateMode,
        entityType,
        editActionEntity,
        exchangeRates
      ),
    createNew: createNewService,
    createEditedUpdatedContext: createEditedServiceUpdatedContext,
    entityName: 'service',
  });

  const setFieldValue = baseHook.formik.setFieldValue;
  const getUpdatedSkills = useCallback(
    (updatedUserSkills: string[]) => {
      setFieldValue('included', updatedUserSkills);
    },
    [setFieldValue]
  );

  return {
    editActionService,
    isServiceCreateMode,
    isServiceEditMode,
    currency: baseHook.currency,
    symbol: baseHook.symbol,
    exchangeRates: baseHook.exchangeRates,
    getUpdatedSkills,
    showModal,
    modalData,
    validateForm: baseHook.validateForm,
    mutations: {
      isServiceCreationInProgress:
        baseHook.mutations.isServiceCreationInProgress,
      isServiceUpdationInProgress:
        baseHook.mutations.isServiceUpdationInProgress,
    },
    formik: baseHook.formik,
    utilities: {
      goToEditorPage: baseHook.utilities.goToEditorPage,
      isFormPrimaryActionInProgress:
        baseHook.utilities.isFormPrimaryActionInProgress,
    },
    initialValues: baseHook.initialValues,
  };
};

export default useCreateEditService;
