import serverApi from 'lib/apiConfig';
import { ConnectedAccountModule } from 'store/features/connectedAccount/connectedAccountSlice';

type UserId = ConnectedAccountModule['userId'];

async function checkOnboardingProcessCompletion(userId: string): Promise<boolean> {
  const res = await serverApi.basic.get(
    `stripe/connected-account/check-onboarding-process-completion/${userId}`
  );
  return res.data;
}

async function getConnectedAccountByUserId(
  userId: UserId
): Promise<ConnectedAccountModule> {
  const res = await serverApi.basic.get(`stripe/connected-account/${userId}`);
  return res.data;
}

async function createConnectedAccountByUserId(userId: UserId) {
  const res = await serverApi.basic.post(`stripe/connected-account/${userId}`);
  return res.data;
}

/**
 * throws error in which there is stripe's limited time onboard link for
 *  stripe connected account. * Neccessary to enable payments for the connected account
 *  or fronts.ai user.
 */
async function generateAccountLinkByUserId(userId: UserId, returnPath: string) {
  try {    
    await serverApi.basic.post(
      `stripe/connected-account/generate-account-link/${userId}`,
      {
        returnPath,
      },      
    );
  } catch (error) {
    throw error;
  }
}

async function getCurrencyFromCountryCode(countryISOCode: string) {
  try {
    const res = await serverApi.onboardingRoutes.get(
      `/currency-from-country-iso/${countryISOCode.toLowerCase()}`,);
    return res.data;
  } catch (error) {
    throw error;
  }
}

export const connectedAccountServices = {
  getConnectedAccountByUserId,
  createConnectedAccountByUserId,
  checkOnboardingProcessCompletion,
  generateAccountLinkByUserId,
  getCurrencyFromCountryCode,
};

export default connectedAccountServices;
