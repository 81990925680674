import { Box, Stack, Typography } from '@mui/material';
import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import { style_ProfileCard_bgWave } from 'components/ProfileCard/ProfileCard.style';

import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import LayoutWrapper from './reusable-components/LayoutWrapper';

const Layout12 = () => {
  const {
    currentTheme,

    tagsArray,
    tagsArrayLength,

    closeIconPopup,
    userAuthType,
    userName,
    userTitle,
    userProfileLink,
    isAuthTagVisible,
    showIconPopup,
    showIconPopupController,
    user,

    layout,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,

    buttonStyle,

    showImage,
    userImage,
    bgImageWithColorURL,
    bgImage,
    userBgImage,
    bg,

    isMeetingAndServiceAvailable,
    handleImageError,
    storeHeading,

    isNeedDiv,
    userNameStyling,
    isMobile,
    title,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,

    displayTags,
    displaySocialLinks,

    colorCode,

    userNameColor,
    titleColor,
  } = useProfileCard();
  const backgroundSize = user?.profileStyle?.image?.objectFit ?? 'cover';

  const btnStyle = {
    ...buttonStyle,
    width: 'max-content',
    margin: { xs: 'auto', md: 'unset' },
  };

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box component='section' position='relative' sx={isNeedDiv ? {} : bg}>
      {isNeedDiv && !userBgImage && (
        <Box className='bg-wave' sx={{ ...style_ProfileCard_bgWave(bg), zIndex: -1 }} />
      )}

      <LayoutWrapper
        sx={{
          flexDirection: 'row',
          display: 'flex',
          flexWrap: 'wrap',          
          minHeight: { xs: 'auto', md: 'calc(100vh - 64px)' },
          mt: '64px',
          alignItems: 'center',
          color: currentTheme.themeColor,
          position: 'relative',
          paddingTop: { xs: 0, md: 0 },
          pb: { xs: 0, md: 0 },
        }}
      >
        <ProfileEditAndDesignBtn sx={{ my: 0, left: 0 }} />
        <Stack
          sx={{
            width: { xs: '100%', md: '30%' },
            bgcolor: currentTheme.themeColor,
            color: currentTheme.themeColor,
            p: 10,
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'stretch',
            backgroundImage: `url(${bgImageWithColorURL})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize,
          }}
        >
          <Box
            className='img'
            component='img'
            src={userImage}
            sx={{
              objectFit: 'cover',
              objectPosition: 'center',
              maxWidth: '100%',
              height: 'auto',
            }}
            onError={handleImageError}
          />
        </Stack>
        <Stack
          className='text-container'
          gap={{ xs: 1.8, md: 2 }}
          sx={{
            width: { xs: '100%', md: '70%' },
            p: { xs: 4, md: 10 },
            pl: { xs: 4, md: 5 },
            textAlign: { xs: 'center', md: 'start' },
          }}
        >
          <Typography
            variant='h1'
            fontSize={{ xs: 40, md: 70 }}
            textAlign={{ xs: 'center', md: 'start' }}
            fontWeight={600}
            color={userNameColor}
          >
            {userName}
          </Typography>
          <Typography
            variant='h6'
            color={titleColor}
            textAlign={{ xs: 'center', md: 'start' }}
          >
            {userTitle}
          </Typography>

          {displaySocialLinks !== false && (
            <Box display='inline-block'>
              <SocialIconsList
                emailPublic={emailPublic}
                email={email}
                socialLinks={socialLinks}
                whatsapp={whatsapp}
                address={address}
                phone={phone}
                styling={{ justifyContent: 'center', display: 'inline-flex' }}
                showOnlyIcon
              />
            </Box>
          )}
          {displayTags !== false && (
            <Box>
              <Tags
                tagsArray={tagsArray}
                tag={tag}
                global={global}
                sx={{
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  display: 'inline-flex',
                }}
              />
            </Box>
          )}
          {isMeetingAndServiceAvailable && storeHeading && (
            <CallToActionBtn
              user={user}
              buttonStyle={btnStyle}
              storeHeading={storeHeading}
            />
          )}
        </Stack>
      </LayoutWrapper>

      {/* sub title */}
      <ProfileSubtitle
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        subTitle={user.subTitle}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout12;