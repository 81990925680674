import React from 'react';
import { msToMinsHrs } from 'utilities/timeConvertions';
import { SPMCardProps } from 'components/MUI_SPM/SPMCard/SPMCard';
import { Box, Grid, Stack } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import IfElse from 'components/utilities/IfElse';
import { getLocalOrGlobalStyling } from 'components/utilities/styleUtilities/styleUtilities';
import useGetCurrency from 'hooks/useGetCurrency';

type SPMCardPriceTimeProps = Pick<SPMCardProps, 'time' | 'price' | 'isPaid'> & {
  styling: any;
};

const SPMCardPriceTime: React.FunctionComponent<SPMCardPriceTimeProps> = ({
  time,
  price,
  isPaid,
  styling,
}) => {
  const { mins, hrs } = msToMinsHrs(time ?? 0);
  const HOURS_TEXT = hrs > 1 ? 'hrs' : 'hr';
  const hours = !!hrs && `${hrs} ${HOURS_TEXT}`;
  const MINUTES_TEXT = mins > 1 ? 'mins' : 'min';
  const minutes = !!mins && `${mins} ${MINUTES_TEXT}`;
  const { symbol } = useGetCurrency();
  if (!price && !time) return <></>;

  return (
    <Box
      display='flex'
      justifyContent='flex-start'
      alignItems='center'
      flexWrap='nowrap'
      columnGap='2.2em'
    >
      {/* ---------- time ---------------- */}
      <IfElse condition={!!time}>
        <Stack
          justifyContent='flex-start'
          direction='row'
          alignItems='center'
          sx={{
            color: getLocalOrGlobalStyling(
              styling.item.duration.color,
              styling.global.color
            ),
            fontSize: {
              xs: `calc(${getLocalOrGlobalStyling(
                styling.item.duration.fontSize,
                styling.global.fontSize
              )} / 1.2 )`,
              lg: getLocalOrGlobalStyling(
                styling.item.duration.fontSize,
                styling.global.fontSize
              ),
            },
          }}
        >
          <AccessTimeIcon
            color='inherit'
            fontSize='large'
            className='vertical-center'
            sx={{
              marginRight: '0.3em',
            }}
          />
          <Box
            component='span'
            sx={{
              fontSize: '1.6rem',
              color: 'inherit',
            }}
          >
            {hours} {minutes}
          </Box>
        </Stack>
      </IfElse>

      {/* ---------- price ---------------- */}
      <IfElse condition={isPaid && !!price}>
        <Grid
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          sx={{
            color: getLocalOrGlobalStyling(
              styling.item.price.color,
              styling.global.color
            ),
            fontSize: {
              xs: `calc(${getLocalOrGlobalStyling(
                styling.item.price.fontSize,
                styling.global.fontSize
              )} / 1.2 )`,
              lg: getLocalOrGlobalStyling(
                styling.item.price.fontSize,
                styling.global.fontSize
              ),
            },
          }}
        >
          <Grid
            component='span'
            sx={{
              fontSize: 'inherit',
              color: 'inherit',
            }}
          >
            {symbol}{' '}
            {price}
          </Grid>
        </Grid>
      </IfElse>
    </Box>
  );
};

export default SPMCardPriceTime;
