import React, { useState, useEffect, useCallback } from 'react';
import {
  Avatar,
  Box,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  SxProps,
  IconButton,
  AvatarProps,
} from '@mui/material';
import Slider from '@mui/material/Slider';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { blue } from '@mui/material/colors';
import debounce from 'lodash/debounce';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import gallery from 'assets/icons/layouts/portfolio-layout/gallery.svg';
import carousel from 'assets/icons/layouts/portfolio-layout/carousel.svg';
import objectFitContain from 'assets/icons/portfolio-icons/image_fit_contain.svg';
import objectFitCover from 'assets/icons/portfolio-icons/image_fit_cover.svg';
import alignCenter from 'assets/icons/portfolio-icons/align_center.svg';
import alignLeft from 'assets/icons/portfolio-icons/align_left.svg';
import alignRight from 'assets/icons/portfolio-icons/align_right.svg';
import imageCircle from 'assets/icons/portfolio-icons/image_circle.svg';
import imageRounded from 'assets/icons/portfolio-icons/image_rounded.svg';
import imageSquare from 'assets/icons/portfolio-icons/image_square.svg';

import { selectLoggedInUserId } from 'store/features/user/userSlice';
import {
  useGetPortfoliosByUserIdQuery,
  useUpdatePortfolioItemModeMutation,
  useUpdatePortfoliosMutation,
} from 'store/rtkqFeatures/api/portfolioExtendedApi';
import { useAppSelector } from 'hooks/redux';

import EditDrawer from 'components/utilities/EditDrawer';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import { PopupHeading } from 'components/dashboard/DashboardReusableComponent';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { initialPortfolio } from 'types/portfolio';
import {
  DrawerButton,
  DrawerSecondaryHeading,
  DrawerSectionHeading,
} from 'components/Drawer/DrawerResuableComponent';
import generalUtilities from 'utilities/generalUtilities';

// Global constants and style variables
export const portfolioMaxHeight = 500;
export const portfolioHeightRatio = 5;
export const imageBorderRadius = {
  square: 0,
  rounded: '24px',
  circle: '50%',
};
const DRAGGABLE_ITEM_AVATAR_SIZE = 25;
export const listOfPortfolioLayouts: ListOfPortfolioLayouts[] = [
  {
    layout: 'carousel',
    isPremium: false,
    svg: carousel,
  },
  {
    layout: 'gallery',
    isPremium: false,
    svg: gallery,
  },
];
const switchStyle: SxProps = {
  '& .MuiSwitch-track': {
    backgroundColor: blue[200],
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: blue[800],
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    backgroundColor: blue[800],
  },
  '& .Mui-checked .MuiSwitch-track': {
    backgroundColor: blue[200] + ' !important',
  },
};
const portfolioDrawerContainer: SxProps = {
  px: 2,
  py: 2,
  position: 'relative',
  height: '100%',
};

// Types definitions
type PortfolioLayout = 'carousel' | 'gallery';
type ListOfPortfolioLayouts = {
  layout: PortfolioLayout;
  isPremium: boolean;
  svg: string;
};

// Error handling function
export function handleError(isError: boolean, errorMsg: string) {
  if (isError) {
    console.error(errorMsg);
    return toast.error(errorMsg, {
      autoClose: 2000,
    });
  }
}

// Main component
const PortfolioDrawer = () => {
  return (
    <>
      <EditDrawer
        drawerWidth={250}
        buttonProps={{
          color: 'primary',
          sx: { display: 'flex', marginLeft: 'auto' },
        }}
      >
        <Box sx={portfolioDrawerContainer}>
          <PopupHeading variant='h6'>Portfolio</PopupHeading>
          <Divider />
          <Stack direction={'column'} spacing={{ xs: 2, md: 4 }} mt={2} useFlexGap>
            <Layout />
            <Divider />
            <Alignment />
            <Divider />
            <ImageDisplayOptions />
            <Divider />
            <ImageFrame />
            <Divider />
            <HeightSlider />
            <Divider />
            <ReOrderItems />
          </Stack>
        </Box>
      </EditDrawer>
    </>
  );
};
export default PortfolioDrawer;

// Additional components
function Layout() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data } = useGetPortfoliosByUserIdQuery(cachedLoggedInUser?._id || '');
  const [setPortfolioMode, { isError, isLoading }] = useUpdatePortfolioItemModeMutation();

  const handleLayoutClick = (layout: PortfolioLayout, isPremium: boolean) => {
    if (layout === 'carousel') {
      setPortfolioMode({ isGridMode: false });
    } else if (layout === 'gallery') {
      setPortfolioMode({ isGridMode: true });
    } else {
      console.error('Error while updating portfolio mode');
      toast.error('Error while updating portfolio mode', {
        autoClose: 2000,
      });
    }
  };

  handleError(isError, 'Error while updating portfolio mode');

  return (
    <Stack direction='column' spacing={0.5} alignItems={'flex-start'}>
      <DrawerSectionHeading>Layout</DrawerSectionHeading>

      <Stack
        direction='row'
        flexWrap='nowrap'
        spacing={{ xs: 0.5, md: 2 }}
        alignItems='center'
        justifyContent='space-between'
        sx={{ ...switchStyle, pointerEvents: isLoading ? 'none' : 'inherit' }}
      >
        {listOfPortfolioLayouts.map(({ layout, isPremium, svg }, index) => (
          <IconButton
            key={index}
            title={layout}
            onClick={() => handleLayoutClick(layout, isPremium)}
            sx={{
              position: 'relative',
              borderRadius: 1,
              border: (theme) =>
                data?.isGridMode === (layout === 'gallery')
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid transparent',
            }}
          >
            <Typography component='img' sx={{ width: 80 }} src={svg} alt={layout} />
          </IconButton>
        ))}
      </Stack>
    </Stack>
  );
}

function Alignment() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data } = useGetPortfoliosByUserIdQuery(cachedLoggedInUser?._id || '');
  const [updatePortfolios, { isError, isLoading }] = useUpdatePortfoliosMutation();

  interface ButtonList {
    svg: string;
    align: React.CSSProperties['textAlign'];
  }
  const buttonList: ButtonList[] = [
    { svg: alignLeft, align: 'start' },
    { svg: alignCenter, align: 'center' },
    { svg: alignRight, align: 'end' },
  ];

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    textAlign: React.CSSProperties['textAlign']
  ) {
    e.preventDefault();
    if (data) {
      updatePortfolios({
        properties: {
          ...(data.properties ? data.properties : initialPortfolio.properties),
          textAlign,
        },
      });
    }
  }

  handleError(isError, 'Error while updating portfolio alignment');

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems='center'
      justifyContent={'space-between'}
    >
      <SectionLoader isLoading={isLoading} />
      <DrawerSectionHeading>Alignment</DrawerSectionHeading>
      <Stack direction={'row'} useFlexGap spacing={0}>
        {buttonList.map(({ svg, align }, index) => (
          <DrawerButton
            key={index}
            onClick={(e) => handleClick(e, align)}
            src={svg}
            selected={data?.properties?.textAlign === align}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function ImageDisplayOptions() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data } = useGetPortfoliosByUserIdQuery(cachedLoggedInUser?._id || '');
  const [updatePortfolios, { isError, isLoading }] = useUpdatePortfoliosMutation();

  interface ButtonList {
    svg: string;
    objectFit: React.CSSProperties['objectFit'];
  }
  const buttonList: ButtonList[] = [
    { svg: objectFitContain, objectFit: 'contain' },
    { svg: objectFitCover, objectFit: 'cover' },
  ];

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    objectFit: React.CSSProperties['objectFit']
  ) {
    e.preventDefault();
    if (data) {
      updatePortfolios({
        properties: {
          ...(data.properties ? data.properties : initialPortfolio.properties),
          image: {
            ...(data.properties?.image
              ? data.properties.image
              : initialPortfolio.properties.image),
            objectFit,
          },
        },
      });
    }
  }

  handleError(isError, 'Error while updating image display options');

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems='center'
      justifyContent={'space-between'}
    >
      <SectionLoader isLoading={isLoading} />
      <DrawerSectionHeading>Image fit</DrawerSectionHeading>
      <Stack direction={'row'} useFlexGap spacing={0} justifySelf={'flex-end'}>
        {buttonList.map(({ svg, objectFit }, index) => (
          <DrawerButton
            key={index}
            onClick={(e) => handleClick(e, objectFit)}
            src={svg}
            selected={data?.properties?.image?.objectFit === objectFit}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function ImageFrame() {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data } = useGetPortfoliosByUserIdQuery(cachedLoggedInUser?._id || '');
  const [updatePortfolios, { isError, isLoading }] = useUpdatePortfoliosMutation();

  interface ButtonList {
    svg: string;
    borderRadius: React.CSSProperties['borderRadius'];
    clipPath?: React.CSSProperties['clipPath'];
  }
  const buttonList: ButtonList[] = [
    { svg: imageRounded, borderRadius: imageBorderRadius.rounded },
    { svg: imageCircle, clipPath: 'circle()', borderRadius: imageBorderRadius.circle },
    { svg: imageSquare, borderRadius: imageBorderRadius.square },
  ];

  function handleClick(
    e: React.MouseEvent<HTMLButtonElement>,
    borderRadius: React.CSSProperties['borderRadius'],
    clipPath?: React.CSSProperties['clipPath']
  ) {
    e.preventDefault();
    if (data) {
      updatePortfolios({
        properties: {
          ...(data.properties ? data.properties : initialPortfolio.properties),
          image: {
            ...(data.properties?.image
              ? data.properties.image
              : initialPortfolio.properties.image),
            borderRadius,
            clipPath,
          },
        },
      });
    }
  }

  handleError(isError, 'Error while updating image frame');

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems='center'
      justifyContent={'space-between'}
      flexWrap='wrap'
    >
      <SectionLoader isLoading={isLoading} />
      <DrawerSectionHeading>Image Frame</DrawerSectionHeading>
      <Stack direction={'row'} useFlexGap spacing={0}>
        {buttonList.map(({ svg, borderRadius, clipPath }, index) => (
          <DrawerButton
            key={index}
            src={svg}
            onClick={(e) => handleClick(e, borderRadius, clipPath)}
            selected={data?.properties?.image?.borderRadius === borderRadius}
          />
        ))}
      </Stack>
    </Stack>
  );
}

function HeightSlider() {
  const [height, setHeight] = useState(100);
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data } = useGetPortfoliosByUserIdQuery(cachedLoggedInUser?._id || '');
  const [updatePortfolios, { isError, isLoading }] = useUpdatePortfoliosMutation();

  useEffect(() => {
    if (data?.properties?.image?.height) {
      const height = Number(data.properties.image.height) || portfolioMaxHeight;
      const newHeight = isNaN(height)
        ? portfolioMaxHeight
        : height / portfolioHeightRatio;
      setHeight(newHeight);
    }
  }, [data?.properties?.image?.height]);

  const debounceAPI = useCallback(
    debounce((heightInPercentage: number) => {
      if (data) {
        updatePortfolios({
          properties: {
            ...(data.properties ? data.properties : initialPortfolio.properties),
            image: {
              ...(data.properties?.image
                ? data.properties.image
                : initialPortfolio.properties.image),
              height: heightInPercentage * portfolioHeightRatio,
            },
          },
        });
      }
    }, 500),
    [data, updatePortfolios]
  );

  function handleChange(event: Event, newValue: number | number[]) {
    const height = newValue as number;
    setHeight(height);
    debounceAPI(newValue as number);
  }

  handleError(isError, 'Error while updating image height');

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems='center'
      justifyContent={'space-between'}
      flexWrap='wrap'
    >
      <SectionLoader isLoading={isLoading} />
      <DrawerSectionHeading>Image Height</DrawerSectionHeading>
      <Typography variant='body2' borderBottom={'1px solid'} color={'common.black'}>
        {height}%
      </Typography>
      <Slider
        sx={{ mt: 1, mr: height === 100 ? 7 + 'px !important' : 0 }}
        aria-label='Max-height'
        aria-valuetext={`Image height: ${height}%`}
        defaultValue={100}
        value={height}
        onChange={handleChange}
        valueLabelDisplay='auto'
        step={10}
        min={10}
        max={100}
        componentsProps={{
          thumb: {
            onTouchMove: (e) => {
              e.stopPropagation();
            },
          },
        }}
        marks
      />
    </Stack>
  );
}

function ReOrderItems() {
  return (
    <Stack direction='column' spacing={0.5} alignItems={'flex-start'}>
      <Box>
        <DrawerSectionHeading>Items order</DrawerSectionHeading>
        <DrawerSecondaryHeading>Drag to re-order</DrawerSecondaryHeading>
      </Box>
      <ItemsDraggable />
    </Stack>
  );
}

function ItemsDraggable() {
  const userId = useAppSelector(selectLoggedInUserId);
  const { data } = useGetPortfoliosByUserIdQuery(userId);
  const [sortedGallery, setSortedGallery] = useState(data?.gallery ?? []);
  const variant: AvatarProps['variant'] =
    data?.properties?.image?.borderRadius === 0
      ? 'square'
      : data?.properties?.image?.borderRadius === 1
      ? 'rounded'
      : 'circular';

  useEffect(() => {
    if (data?.gallery) {
      setSortedGallery(data.gallery);
    }
  }, [data?.gallery]);

  const [updatePortfolio, { isError, isLoading }] = useUpdatePortfoliosMutation();

  function handleOnDragEnd(result: DropResult, provided: ResponderProvided) {
    // TODO - don't do api calls if the order is not changed
    if (!result.destination) return;
    const previousSortedGallery = [...sortedGallery];
    const [removed] = previousSortedGallery.splice(result.source.index, 1);
    previousSortedGallery.splice(result.destination.index, 0, removed);
    setSortedGallery(previousSortedGallery);
    if (data && data?._id) {
      updatePortfolio({
        ...data,
        gallery: previousSortedGallery,
      });
    }
  }

  handleError(isError, 'Error while updating portfolio items order');

  return (
    <>
      <SectionLoader isLoading={isLoading} />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='draggableService'>
          {(provided) => (
            <Stack
              useFlexGap
              spacing={1}
              sx={{
                width: '100%',
                position: 'relative',
              }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sortedGallery.map((eachPortfolio, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided) => (
                    <ListItem
                      sx={{
                        borderRadius: '5px',
                        background: 'common.white',
                        py: '1rem',
                        boxShadow: (theme) => `0 0 3px ${theme.palette.grey[500]}`,
                      }}
                      disablePadding
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Stack
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        spacing={0.5}
                        useFlexGap
                      >
                        <ListItemIcon sx={{ minWidth: 20 }}>
                          <DragIndicatorIcon
                            sx={{
                              width: DRAGGABLE_ITEM_AVATAR_SIZE,
                              height: DRAGGABLE_ITEM_AVATAR_SIZE,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemAvatar sx={{ minWidth: 30 }}>
                          <Avatar
                            variant={variant}
                            src={eachPortfolio.img}
                            sx={{
                              width: DRAGGABLE_ITEM_AVATAR_SIZE,
                              height: DRAGGABLE_ITEM_AVATAR_SIZE,
                              '& img': {
                                objectFit: data?.properties?.image?.objectFit,
                              },
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            maxWidth: '40ch',
                            '& *': { fontSize: '1.2rem !important' },
                          }}
                          primary={generalUtilities.sliceText(eachPortfolio.title || '')}
                        />
                      </Stack>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
