import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export type LocalThemeSelector = {
  currentThemeIndex: number;
};

const INITIAL_THEME_SELECTOR_STATE: LocalThemeSelector = {
  currentThemeIndex: 0,
};

const localThemeSelectorSlice = createSlice({
  name: 'localThemeSelector',
  initialState: INITIAL_THEME_SELECTOR_STATE,
  reducers: {
    updateLocalCurrentThemeIndex: (
      state: LocalThemeSelector,
      action: PayloadAction<number>
    ) => {
      const updatedThemeIndex = action.payload;
      state.currentThemeIndex = updatedThemeIndex;
    },
    resetLocalThemeSelector: () => {
      return INITIAL_THEME_SELECTOR_STATE;
    },
  },
});

const localThemeSelectorReducer = localThemeSelectorSlice.reducer;
export default localThemeSelectorReducer;

export const { updateLocalCurrentThemeIndex, resetLocalThemeSelector } =
  localThemeSelectorSlice.actions;

// slice selectors
export const selectLocalThemeSelector = (state: RootState) => {
  return state.persistedReducer.localThemeSelector;
};

export const selectLocalCurrentThemeIndex = (state: RootState) => {
  const localThemeSelector = selectLocalThemeSelector(state);
  return localThemeSelector.currentThemeIndex;
};
