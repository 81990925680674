import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Required from 'components/InputField/Required';

import IfElse from 'components/utilities/IfElse';

type ThisProps = {
  label: React.ReactNode;
  required?: boolean;
  secondaryLabel?: React.ReactNode;
};

const StyledItemLabel = ({ label, required = false, secondaryLabel }: ThisProps) => {
  return (
    <Box
      component='label'
      sx={{
        fontSize: '1.5rem',
        mb: '0.6em',
        fontWeight: 600,
      }}
    >
      {label}
      {secondaryLabel && (
        <>
          <Typography component={'small'} sx={{ fontSize: { xs: '80%', md: '85%' }, display: 'block' }}>
            {secondaryLabel}
          </Typography>
        </>
      )}
      <IfElse condition={required}>
        <Required />
      </IfElse>
    </Box>
  );
};

export default StyledItemLabel;
