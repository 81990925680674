import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import {
  NewPortfolio,
  PortfoliosType,
  PortfolioItemType,
} from 'types/portfolio';

type PortfolioMutationQueryArg = {
  portfolioIndex: number;
  userId: string;
};

type PortfolioModeMutationQuery = Pick<PortfoliosType, 'isGridMode'>;

type UpdatePortfolioMutationArg = PortfolioMutationQueryArg & {
  portfolio: Partial<PortfolioItemType>;
};

type AddPortfolioMutationArg = Pick<PortfolioMutationQueryArg, 'userId'> & {
  newPortfolio: NewPortfolio;
};

const portfolioExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPortfoliosByUserId: build.query<PortfoliosType | null, string>({
      query: (userId) => `portfolios/${userId}`,
      // transformResponse: (response: PortfoliosType, meta, arg) => {
      //   if (!response) return null;
      //   const isGalleryInResponse = 'gallery' in response;
      //   if (!isGalleryInResponse) return null;
      //   return response;
      // },
      providesTags: (result) =>
        result
          ? [
              { type: 'Portfolio', id: 'LIST' },
              ...result.gallery.map((eachPortfolio, index) => ({
                type: 'Portfolio' as const,
                id: index,
              })),
            ]
          : [{ type: 'Portfolio', id: 'LIST' }],
    }),

    deletePortfolioItemByIndex: build.mutation<void, PortfolioMutationQueryArg>({
      query: ({ userId, portfolioIndex }) => ({
        url: `portfolios/index/${portfolioIndex}/user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'Portfolio', id: 'LIST' }],
    }),

    updatePortfolioItemMode: build.mutation<null, PortfolioModeMutationQuery>({
      query: ({ isGridMode }) => ({
        url: `portfolios/mode`,
        method: 'PATCH',
        body: { isGridMode },
      }),
      invalidatesTags: () => [{ type: 'Portfolio', id: 'LIST' }],
    }),
    updatePortfolioItem: build.mutation<void, UpdatePortfolioMutationArg>({
      query: ({ userId, portfolioIndex, portfolio }) => ({
        url: `portfolios/index/${portfolioIndex}/user/${userId}`,
        method: 'PATCH',
        body: portfolio,
      }),
      invalidatesTags: () => [{ type: 'Portfolio', id: 'LIST' }],
    }),
    addPortfolioItem: build.mutation<void, AddPortfolioMutationArg>({
      query: ({ userId, newPortfolio }) => ({
        url: `portfolios/user/${userId}`,
        method: 'POST',
        body: newPortfolio,
      }),
      invalidatesTags: () => [{ type: 'Portfolio', id: 'LIST' }],
    }),
    updatePortfolios: build.mutation<void, Partial<PortfoliosType>>({
      query: (portfolio) => ({
        url: `portfolios/update-portfolio`,
        method: 'PATCH',
        body: portfolio,
      }),
      invalidatesTags: (result) => [{ type: 'Portfolio', id: 'LIST' }],
    }),
  }),
});

export default portfolioExtendedApi;

export const {
  useGetPortfoliosByUserIdQuery,

  useAddPortfolioItemMutation,  
  useUpdatePortfolioItemModeMutation,
  
  useUpdatePortfolioItemMutation,  

  useUpdatePortfoliosMutation,

  useDeletePortfolioItemByIndexMutation,
} = portfolioExtendedApi;
