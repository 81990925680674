import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AppRouteComponent } from 'AppRouting/appRoutes';
import getNodeEnv from 'utilities/getNodeEnv';
import loginTokenUtils from 'utilities/loginTokenUtils';
import routeConstants from 'constants/routeConstants';

const homeRoute = routeConstants.HOME_ROUTE;

type PrivateRouteProps = RouteProps & {
  isProtected: boolean;
  Component: AppRouteComponent;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isProtected,
  Component,
  ...rest
}) => {
  const isNodeEnvProduction = getNodeEnv();
  const loginToken = loginTokenUtils.getLocalStorageLoginToken();
  const isUserAuthenticated = Boolean(loginToken);
  !isNodeEnvProduction &&
    !isUserAuthenticated &&
    console.warn('Editor loginToken not found | loginToken: ', loginToken);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isProtected && !isUserAuthenticated) {
          !isNodeEnvProduction &&
            console.log(
              'Redirected by <PrivateRoute /> because the route was editor private route, to disable this check set isProtected to false in appRoutes.ts'
            );
          return <Redirect to={homeRoute} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
