import serverApi from 'lib/apiConfig';
import { StepperState } from '../OnboardingFlowForm/OnboardingFlowForm';
import uploadImageService from 'lib/services/uploadImageService';
import React from 'react';
import { FormikErrors } from 'formik';
import { LoggedInUser } from 'types/user';

const stepWithFieldMapForSavingOnEveryStep: { [key: number]: Array<keyof StepperState> } =
  {
    1: ['pendingUrl', 'name', 'email', 'pendingDomain'],
    2: ['occupation', 'profileImgFile', 'userBgImageFile', 'userBgImage', 'img'],
    3: [
      'timeZone',
      'userGMTOffset',
      'language',
      'languageDir',
      'addressComponents',
      'address',
      'country',
      'state',
      'city',
      'phone',
      'whatsapp',
    ],
    4: ['socialLinks'],
  };

async function uploadMediToCloudinary(profileImgFile?: File, userBgImageFile?: File) {
  let img, userBgImage;
  try {
    // upload image to cloudinary
    const uploadPromises = [];

    if (profileImgFile) {
      const profileImgPromise =
        uploadImageService.uploadImageWithoutAuthentication(profileImgFile);
      uploadPromises.push(profileImgPromise);
    }
    if (userBgImageFile) {
      const userBgImgPromise =
        uploadImageService.uploadImageWithoutAuthentication(userBgImageFile);
      uploadPromises.push(userBgImgPromise);
    }

    const [profileImgResult, userBgImgResult] = await Promise.all(uploadPromises);

    if (profileImgFile) {
      img = profileImgResult;
    }
    if (userBgImageFile) {
      userBgImage = userBgImgResult;
    }
  } catch (error) {
    console.error('Error uploading profile image: ', error);
  }

  return {
    img,
    userBgImage,
  };
}

export async function saveFilledDetailsAndStepDetails({
  formDetails,
  stepDetails,
}: {
  formDetails: StepperState;
  stepDetails: { stepName: string; stepCount: number };
}) {
  try {
    await serverApi.onboardingRoutes.post('/save-details', {
      formDetails,
      stepDetails,
    });
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.log(err);
    }
  }
}

export async function getFieldsToSave(  
  formDetails: StepperState,
  setValues: (
    value: React.SetStateAction<StepperState>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<StepperState>>,
  cachedLoggedInUser: LoggedInUser, // will use for comapring the values and not making any request if doesn't change
) {

  // if content doesn't change then don't save it
  // if for a specific step, cachedLoggedInUser key and formDetails key are same, then check if the value is different and update the value in formDetails
  // Object.keys(cachedLoggedInUser).forEach((key) => {
  //   if (formDetails[key] !== undefined && formDetails[key] !== cachedLoggedInUser[key]) {
  //     formDetails[key] = cachedLoggedInUser[key]
  //   }}) 

  const step = formDetails.step;

  // copy of formdetails  
  let submittedValues = { ...formDetails };
  const fieldsToSave = stepWithFieldMapForSavingOnEveryStep[step];
  const fieldsToSaveObj: Partial<StepperState> | { [key: string]: any } = {};

  if (step === 2 && (formDetails.profileImgFile || formDetails.userBgImageFile)) {
    const { img, userBgImage } = await uploadMediToCloudinary(
      formDetails.profileImgFile,
      formDetails.userBgImageFile
    );

    submittedValues.img = img;
    submittedValues.userBgImage = userBgImage;
    delete submittedValues.profileImgFile;
    delete submittedValues.userBgImageFile;
    setValues((prev) => ({
      ...prev,
      img,
      userBgImage,
      profileImgFile: undefined,
      userBgImageFile: undefined,
    }));
  }

  fieldsToSave.forEach((field) => {
    if (submittedValues[field] !== undefined) {
      fieldsToSaveObj[field] = submittedValues[field];
    }
  });
  
  return fieldsToSaveObj;
}