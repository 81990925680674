import { BsFillPersonFill } from 'react-icons/bs';
import { HiDesktopComputer, HiUserGroup } from 'react-icons/hi';
import { MdHomeRepairService } from 'react-icons/md';
import { RiGroupFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { MeetingTypes } from 'types/meeting';
import { ServiceTypes } from 'types/service';
import EntityLayoutBox from './sub-components/EntityLayoutBox/EntityLayoutBox';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Container, Typography } from '@mui/material';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

// NOTE: do entity types styling in this file's CSS
const SelectNewStoreEntity = () => {
  const history = useHistory();

  return (
    <section id='entity-store'>
      <Container maxWidth='xl'>
        <Box marginBottom='1rem'>
          <KeyboardBackspaceIcon
            sx={{
              fontSize: '3rem',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => history.goBack()}
          />
        </Box>
        <Typography
          variant='h1'
          textAlign='center'
          sx={{
            fontSize: '4rem',
            marginBottom: '1em',
            fontWeight: 700,
          }}
        >
          What do you want to add to your website?
        </Typography>
        <section className='entity-store__container'>
          {/* -------------------------- meetings -------------------------- */}
          <EntityLayoutBox
            entityBoxStylingClassNames='entity-type--meeting'
            entityType={MeetingTypes.Virtual}
            entityBoxName='Online Meeting'
            pathname='create-meeting-v'
          >
            <RiGroupFill />
            <HiDesktopComputer />
          </EntityLayoutBox>
          <EntityLayoutBox
            entityBoxStylingClassNames='entity-type--meeting'
            entityType={MeetingTypes.Physical}
            entityBoxName='In-Person Meeting'
            pathname='create-meeting-p'
          >
            <RiGroupFill />
            <BsFillPersonFill />
          </EntityLayoutBox>

          {/* -------------------------- Service (COMING SOON) -------------------------- */}
          <EntityLayoutBox
            entityBoxStylingClassNames='entity-type--service'
            entityType={ServiceTypes.Virtual}
            entityBoxName='Service'
            pathname='create-service-v'
            // pathname=''
            // notAvailable={true}
          >
            <MdHomeRepairService />
          </EntityLayoutBox>
          {/* -------------------------- Subscriptions (COMING SOON) -------------------------- */}
          <EntityLayoutBox
            entityBoxStylingClassNames='entity-type--service'
            entityType={ServiceTypes.Physical}
            entityBoxName='Subscriptions'
            notAvailable={true}
            pathname=''
          >
            <CurrencyExchangeIcon fontSize='large' />
          </EntityLayoutBox>

          {/* --------------------- Events (COMING SOON) ------------------ */}
          <EntityLayoutBox
            entityBoxStylingClassNames='entity-type--product'
            entityType={MeetingTypes.Physical}
            entityBoxName='Virtual Event'
            pathname=''
            notAvailable={true}
          >
            <HiUserGroup />
            <HiDesktopComputer />
          </EntityLayoutBox>
          <EntityLayoutBox
            entityBoxStylingClassNames='entity-type--product'
            entityType={MeetingTypes.Physical}
            entityBoxName='In-Person Event'
            pathname=''
            notAvailable={true}
          >
            <HiUserGroup />
            <BsFillPersonFill />
          </EntityLayoutBox>
        </section>
      </Container>
    </section>
  );
};

export default SelectNewStoreEntity;
