import { DeleteUserResponse } from 'lib/services/userServices';
import { resetModalSlice } from 'store/features/modal/modalSlice';
import { resetLoggedinUserSlice, setLoggedInUser } from 'store/features/user/userSlice';
import { TypedThunkAction } from 'types/reduxStore';
import { LoggedInUser } from 'types/user';
import { sucessfulLoginInit } from 'components/logins/onLoginSuccessUtilities';
import { resetSalesfrontsAPIQueryCache } from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import { resetWebsiteFontFamilySlice } from 'store/features/websiteFontFamily/websiteFontFamilySlice';
import { resetLocalThemeSelector } from 'store/features/localThemeSelector/localThemeSelectorSlice';

export function deleteUser(
  userId: LoggedInUser['_id']
): TypedThunkAction<Promise<DeleteUserResponse>> {
  return async (dispatch, getState, apiServices) => {
    const { deleteUser } = apiServices.userServices;
    try {
      const responseStatus = await deleteUser(userId);
      await dispatch(logout());
      return responseStatus;
    } catch (error) {
      console.log('some error occurred in userActions/deleteUser catched: ', error);
    }
    return 'failed to delete user account';
  };
}

export function linkedinSignUp(
  linkedinAccessCode: string
): TypedThunkAction<Promise<string>> {
  return async (dispatch, getState, apiServices) => {
    const { userServices } = apiServices;
    try {
      const loggedInUser = await userServices.linkedinSignUp(linkedinAccessCode);
      dispatch(setLoggedInUser(loggedInUser));
      await sucessfulLoginInit({
        editorId: loggedInUser._id,
      });
    } catch (error) {
      console.error('error in userAction.js/linkedinSignUp(): ', error);
      return 'error';
    }
    return 'user created';
  };
}

export function getUserById(
  id: string
): TypedThunkAction<Promise<'user fetched' | 'error while fetching user'>> {
  return async (dispatch, getState, apiServices) => {
    const { userServices } = apiServices;
    try {
      const loggedInUser = await userServices.getUserById(id);
      dispatch(setLoggedInUser(loggedInUser));
      return 'user fetched';
    } catch (error) {
      console.error(error);
      return 'error while fetching user';
    }
  };
}

export function logout(): TypedThunkAction<Promise<'logout done'>> {
  return async (dispatch) => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(resetLoggedinUserSlice());
      dispatch(resetModalSlice());
      dispatch(resetWebsiteFontFamilySlice());
      dispatch(resetLocalThemeSelector());
      dispatch(resetSalesfrontsAPIQueryCache());
      return 'logout done';
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

export function updateUserDetails(user: LoggedInUser): TypedThunkAction<Promise<string>> {
  return async (dispatch, getState, apiServices) => {
    const { userServices } = apiServices;
    try {
      const loggedInUser = await userServices.updateUserDetails(user);
      dispatch(setLoggedInUser(loggedInUser));
      return 'user updated';
    } catch (error) {
      console.error(error);
      throw Error('error while updating user');
    }
  };
}

const userActions = {
  deleteUser,  
  linkedinSignUp,
  getUserById,
  logout,
  updateUserDetails,
};

export default userActions;
