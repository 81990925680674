import { useMemo } from 'react';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const useCurrentThemeSelector = () => {
  const { cachedLoggedInUser: _editor } = useGetLoggedInUser();
  const cacheCurrentThemeId = _editor?.themeId ?? 0;

  const { data: rtkThemes } = useGetThemesQuery(undefined);
  
  const cacheCurrentThemeIndex = useMemo(() => {
    const editorCurrentThemeId = _editor?.themeId ?? 0;
    const editorRTKThemes = rtkThemes ?? [];
    let _cacheCurrentThemeIndex = editorRTKThemes.findIndex(
      (eachTheme) => eachTheme.id === editorCurrentThemeId
    );
    if (_cacheCurrentThemeIndex === -1) _cacheCurrentThemeIndex = 0;

    return _cacheCurrentThemeIndex;
  }, [rtkThemes, _editor]);

  const cacheCurrentThemeName = rtkThemes ? rtkThemes[cacheCurrentThemeIndex].name : '';

  return {
    cacheCurrentThemeIndex,
    cacheCurrentThemeName,
    cacheCurrentThemeId,
    currentTheme: rtkThemes?.[cacheCurrentThemeIndex],
  };
};

export default useCurrentThemeSelector;
