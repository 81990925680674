import uploadImageService from 'lib/services/uploadImageService';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useDeleteMeetingByIdMutation,
  useLazyGetMeetingByMeetingIdQuery,
} from 'store/rtkqFeatures/api/meetingExtendedApi';
import {
  useDeleteServiceByIdMutation,
  useLazyGetServiceByServiceIdQuery,
} from 'store/rtkqFeatures/api/serviceExtendedApi';

// NOTE: Entity means SPM

export enum SPMDeleteAction {
  DeleteMeeting = 'deleteMeeting',
  DeleteService = 'deleteService',
  DeleteProduct = 'deleteProduct',
}

export type InitialDeleteEntityDialogState = {
  isDialogOpen: boolean;
  toBeDeletedEntityId: string;
  deleteAction?: SPMDeleteAction;
};

const initialDeleteEntityDialogState: InitialDeleteEntityDialogState = {
  isDialogOpen: false,
  toBeDeletedEntityId: '',
};

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 1000,
  hideProgressBar: true,
};

async function deleteImgFromCloudinary(imgUrl: string) {
  try {
    await uploadImageService.deleteImg(imgUrl);
  } catch (err) {
    console.error('Error while deleting meeting image: ', err);
  }
}

const useSPMDeletionConfirmationDialog = () => {
  // deleting meeting mutation
  const [deleteMeeting, { isLoading: isMeetingDeletionProcessInProgress }] =
    useDeleteMeetingByIdMutation();

  // deleting service mutation
  const [deleteService, { isLoading: isServiceDeletionProcessInProgress }] =
    useDeleteServiceByIdMutation();

  const isSPMDeletionInProgress =
    isMeetingDeletionProcessInProgress || isServiceDeletionProcessInProgress;

  // ----------------- delete entity confirmation dialog state --------------------
  const [deleteEntityDialogState, setDeleteEntityDialogState] = useState(
    initialDeleteEntityDialogState
  );

  const [getMeetingByMeetingId] = useLazyGetMeetingByMeetingIdQuery();
  const [getServiceByServiceId] = useLazyGetServiceByServiceIdQuery();

  const resetDeletionProcess = () => {
    setDeleteEntityDialogState({
      isDialogOpen: false,
      toBeDeletedEntityId: '',
    });
  };

  const handleDeleteEntityConfirmationDialogBtnClick = async () => {
    const isValidEnitityId = !!deleteEntityDialogState.toBeDeletedEntityId;
    const deleteAction = deleteEntityDialogState.deleteAction;
    const isValidDeleteAction = !!deleteAction;
    if (!isValidEnitityId) {
      console.error(
        'Id of the Entity to be deleted not found, Id: ',
        deleteEntityDialogState.toBeDeletedEntityId
      );
      toast.error('Error while deleting store item', TOAST_MESSAGE_SETTINGS);
      return;
    }
    if (!isValidDeleteAction) {
      console.error(
        'SPM delete action not found while deleting store entity with Id: ',
        deleteEntityDialogState.toBeDeletedEntityId
      );
      toast.error('Error while deleting store item', TOAST_MESSAGE_SETTINGS);
      return;
    }
    if (deleteAction === SPMDeleteAction.DeleteMeeting) {
      const meeting = await getMeetingByMeetingId(
        deleteEntityDialogState.toBeDeletedEntityId
      ).unwrap();
      await deleteImgFromCloudinary(meeting.img);
      await deleteMeeting(deleteEntityDialogState.toBeDeletedEntityId).unwrap();
    } else if (deleteAction === SPMDeleteAction.DeleteService) {
      const service = await getServiceByServiceId(
        deleteEntityDialogState.toBeDeletedEntityId
      ).unwrap();
      deleteImgFromCloudinary(service.img);
      await deleteService(deleteEntityDialogState.toBeDeletedEntityId).unwrap();
    } else if (deleteAction === SPMDeleteAction.DeleteProduct) {
      // await deleteProduct(deleteEntityDialogState.toBeDeletedEntityId).unwrap();
    }
    resetDeletionProcess();
  };

  return {
    deleteEntityDialogState,
    setDeleteEntityDialogState,
    isSPMDeletionInProgress,
    handleDeleteEntityConfirmationDialogBtnClick,
  };
};

export default useSPMDeletionConfirmationDialog;
