import React from 'react';
import { Box } from '@mui/material';

import { Layout } from 'types/user';

import useProfileCard from './hooks/useProfileCard';

import Layout1 from './subcomponents/ProfileLayout/Layout1';
import Layout2 from './subcomponents/ProfileLayout/Layout2';
import Layout3 from './subcomponents/ProfileLayout/Layout3';
import Layout4 from './subcomponents/ProfileLayout/Layout4';
import Layout5 from './subcomponents/ProfileLayout/Layout5';
import Layout6 from './subcomponents/ProfileLayout/Layout6';
import Layout7 from './subcomponents/ProfileLayout/Layout7';
import Layout8 from './subcomponents/ProfileLayout/Layout8';
import Layout9 from './subcomponents/ProfileLayout/Layout9';
import Layout10 from './subcomponents/ProfileLayout/Layout10';
import Layout11 from './subcomponents/ProfileLayout/Layout11';
import Layout12 from './subcomponents/ProfileLayout/Layout12';

import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
type LayoutComponent = React.ReactElement<any, string | React.JSXElementConstructor<any>>;

const ProfileCard = () => {
  const { currentTheme, layout } = useProfileCard();
  // const isPremiumLayout = listOfLayouts.find((l) => l.layout === layout)?.isPremium;
  
  if (!currentTheme) return <FrontsLoader />;

  const layoutComponents: Partial<Record<Layout, LayoutComponent>> = {
    Layout1: <Layout1 />,
    Layout2: <Layout2 />,
    Layout3: <Layout3 />,
    Layout4: <Layout4 />,
    Layout5: <Layout5 />,
    Layout6: <Layout6 />,
    Layout7: <Layout7 />,
    Layout8: <Layout8 />,
    Layout9: <Layout9 />,
    Layout10: <Layout10 />,
    Layout11: <Layout11 />,
    Layout12: <Layout12 />,
  };

  const selectedComponent: LayoutComponent = layoutComponents[layout as Layout] || (
    <Layout2 />
  );

  // const handleCancelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   dispatchLocalLayout(resetLocalLayoutState());
  // };

  return (
    <Box position='relative'>
      {/* <Box bgcolor='black' mt={`${HEADER_HEIGHT}px`}>
         <PremiumThemeSubsNewSubscribeAction
          typographySx={{
            width: '100%',
            py: 2,
          }}
          onCancelClick={handleCancelClick}
          name='Selected Layout'
          text='is a premium layout.'
        /> 
      </Box> */}
      {selectedComponent}
    </Box>
  );
};

export default ProfileCard;
