import { Dispatch, SetStateAction, FC } from 'react';
import { List, ListItem } from '@mui/material';
import { useHistory } from 'react-router-dom';

import Popup from 'components/utilities/Popups/Popup';
import getNodeEnv from 'utilities/getNodeEnv';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';

type PremiumSubscriptionPopupProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: React.ReactNode;
  fullScreen?: boolean;
  description?: React.ReactNode;
};

const intialDescription = (
  <List dense component='ul'>
    <ListItem component='li'>&#x2022; 5 Extra accounts Per Subscription.</ListItem>
    <ListItem component='li'>&#x2022; Priority Directory Listing to maximize SEO</ListItem>
    <ListItem component='li'>&#x2022; See page visits.</ListItem>
    <ListItem component='li'>&#x2022; Access all the Themes.</ListItem>
  </List>
);

const PremiumSubscriptionPopup: FC<PremiumSubscriptionPopupProps> = ({
  fullScreen = false,
  open,
  setOpen,
  title,
  description = intialDescription,
}) => {
  const history = useHistory();
  const isNodeEnvProduction = getNodeEnv();
  const react_app_url = isNodeEnvProduction
    ? process.env.REACT_APP_PRODUCTION_APP_URL
    : process.env.REACT_APP_DEVELOPMENT_APP_URL;

  const handleUpdatePremium = () => {
    history.push(`/pricing`);
  };

  return (
    <Popup
      dialogProps={{ fullScreen, open, keepMounted: false }}
      title={title}
      setOpen={setOpen}
      content={description}
      dialogActions={
        <>
          <ResponsiveButton onClick={() => setOpen(!open)} color='secondary'>
            Cancel
          </ResponsiveButton>
          <ResponsiveButton
            onClick={handleUpdatePremium}
            href={`${react_app_url}/pricing`}
            variant='contained'
            color='primary'
          >
            Upgrade to premium
          </ResponsiveButton>
        </>
      }
    />
  );
};

export default PremiumSubscriptionPopup;
