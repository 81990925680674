import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IfElse from 'components/utilities/IfElse';
import { InitialDeleteEntityDialogState } from 'components/MUI_SPM/SPMCard/sub-components/SPMDeletionConfirmationDialog/hooks/useSPMDeletionConfirmationDialog';

// NOTE: Entity means SPM

type SPMDeletionConfirmationDialogBtnsProps = {
  isEntityDeletionProcessInProgress: boolean;
  setDeleteEntityDialogState: React.Dispatch<
    React.SetStateAction<InitialDeleteEntityDialogState>
  >;
  handleDeleteEntityConfirmationDialogBtnClick: () => Promise<void>;
};

const SPMDeletionConfirmationDialogBtns: React.FunctionComponent<
  SPMDeletionConfirmationDialogBtnsProps
> = ({
  handleDeleteEntityConfirmationDialogBtnClick,
  isEntityDeletionProcessInProgress,
  setDeleteEntityDialogState,
}) => {
  return (
    <>
      {/* ------------------- Cancel SPM deletion Action --------------------- */}
      <Button
        sx={{
          fontSize: '1.45rem',
        }}
        onClick={() =>
          setDeleteEntityDialogState({
            isDialogOpen: false,
            toBeDeletedEntityId: '',
          })
        }
      >
        Cancel
      </Button>
      {/* ------------------- delete SPM deletion Action --------------------- */}
      <Button
        disabled={isEntityDeletionProcessInProgress}
        variant='contained'
        onClick={handleDeleteEntityConfirmationDialogBtnClick}
        sx={{
          fontSize: '1.45rem',

          bgcolor: 'error.main',
          '&:hover': {
            bgcolor: 'error.light',
          },
        }}
      >
        Delete
        <IfElse condition={isEntityDeletionProcessInProgress}>
          <CircularProgress
            size={14}
            sx={{
              marginLeft: '0.6rem',
              color: 'common.white',
            }}
          />
        </IfElse>
      </Button>
    </>
  );
};

export default SPMDeletionConfirmationDialogBtns;
