import React from 'react';
import { toast } from 'react-toastify';
import { useCopyToClipboard } from 'usehooks-ts';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton, SxProps, Theme } from '@mui/material';

type ThisProps = {
  copyContent: string;
  sx?: SxProps<Theme>;
  sxButton?: SxProps<Theme>;
  children?: React.ReactNode;
};

const CopyBtn = ({ copyContent, sx, sxButton, children }: ThisProps) => {
  const [, copy] = useCopyToClipboard();

  const onCopyClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      await copy(copyContent);
      toast.success('Copied!');
    } catch (error) {
      toast.error('Failed while copying');
    }
  };

  return (
    <IconButton
      onClick={onCopyClick}
      sx={{        
        ...sxButton,
      }}
    >
      {children}
      <ContentCopyIcon sx={{ ...sx }} />
    </IconButton>
  );
};

export default CopyBtn;
