const countryISOCode = [
  {
      "name": "Afghanistan",
      "countryCodeISO2": "AF"
  },
  {
      "name": "Åland Islands",
      "countryCodeISO2": "AX"
  },
  {
      "name": "Albania",
      "countryCodeISO2": "AL"
  },
  {
      "name": "Algeria",
      "countryCodeISO2": "DZ"
  },
  {
      "name": "American Samoa",
      "countryCodeISO2": "AS"
  },
  {
      "name": "Andorra",
      "countryCodeISO2": "AD"
  },
  {
      "name": "Angola",
      "countryCodeISO2": "AO"
  },
  {
      "name": "Anguilla",
      "countryCodeISO2": "AI"
  },
  {
      "name": "Antarctica",
      "countryCodeISO2": "AQ"
  },
  {
      "name": "Antigua and Barbuda",
      "countryCodeISO2": "AG"
  },
  {
      "name": "Argentina",
      "countryCodeISO2": "AR"
  },
  {
      "name": "Armenia",
      "countryCodeISO2": "AM"
  },
  {
      "name": "Aruba",
      "countryCodeISO2": "AW"
  },
  {
      "name": "Australia",
      "countryCodeISO2": "AU"
  },
  {
      "name": "Austria",
      "countryCodeISO2": "AT"
  },
  {
      "name": "Azerbaijan",
      "countryCodeISO2": "AZ"
  },
  {
      "name": "Bahamas",
      "countryCodeISO2": "BS"
  },
  {
      "name": "Bahrain",
      "countryCodeISO2": "BH"
  },
  {
      "name": "Bangladesh",
      "countryCodeISO2": "BD"
  },
  {
      "name": "Barbados",
      "countryCodeISO2": "BB"
  },
  {
      "name": "Belarus",
      "countryCodeISO2": "BY"
  },
  {
      "name": "Belgium",
      "countryCodeISO2": "BE"
  },
  {
      "name": "Belize",
      "countryCodeISO2": "BZ"
  },
  {
      "name": "Benin",
      "countryCodeISO2": "BJ"
  },
  {
      "name": "Bermuda",
      "countryCodeISO2": "BM"
  },
  {
      "name": "Bhutan",
      "countryCodeISO2": "BT"
  },
  {
      "name": "Bolivia (Plurinational State of)",
      "countryCodeISO2": "BO"
  },
  {
      "name": "Bonaire, Sint Eustatius and Saba",
      "countryCodeISO2": "BQ"
  },
  {
      "name": "Bosnia and Herzegovina",
      "countryCodeISO2": "BA"
  },
  {
      "name": "Botswana",
      "countryCodeISO2": "BW"
  },
  {
      "name": "Bouvet Island",
      "countryCodeISO2": "BV"
  },
  {
      "name": "Brazil",
      "countryCodeISO2": "BR"
  },
  {
      "name": "British Indian Ocean Territory",
      "countryCodeISO2": "IO"
  },
  {
      "name": "Brunei Darussalam",
      "countryCodeISO2": "BN"
  },
  {
      "name": "Bulgaria",
      "countryCodeISO2": "BG"
  },
  {
      "name": "Burkina Faso",
      "countryCodeISO2": "BF"
  },
  {
      "name": "Burundi",
      "countryCodeISO2": "BI"
  },
  {
      "name": "Cabo Verde",
      "countryCodeISO2": "CV"
  },
  {
      "name": "Cambodia",
      "countryCodeISO2": "KH"
  },
  {
      "name": "Cameroon",
      "countryCodeISO2": "CM"
  },
  {
      "name": "Canada",
      "countryCodeISO2": "CA"
  },
  {
      "name": "Cayman Islands",
      "countryCodeISO2": "KY"
  },
  {
      "name": "Central African Republic",
      "countryCodeISO2": "CF"
  },
  {
      "name": "Chad",
      "countryCodeISO2": "TD"
  },
  {
      "name": "Chile",
      "countryCodeISO2": "CL"
  },
  {
      "name": "China",
      "countryCodeISO2": "CN"
  },
  {
      "name": "Christmas Island",
      "countryCodeISO2": "CX"
  },
  {
      "name": "Cocos (Keeling) Islands",
      "countryCodeISO2": "CC"
  },
  {
      "name": "Colombia",
      "countryCodeISO2": "CO"
  },
  {
      "name": "Comoros",
      "countryCodeISO2": "KM"
  },
  {
      "name": "Congo",
      "countryCodeISO2": "CG"
  },
  {
      "name": "Congo, Democratic Republic of the",
      "countryCodeISO2": "CD"
  },
  {
      "name": "Cook Islands",
      "countryCodeISO2": "CK"
  },
  {
      "name": "Costa Rica",
      "countryCodeISO2": "CR"
  },
  {
      "name": "Côte d'Ivoire",
      "countryCodeISO2": "CI"
  },
  {
      "name": "Croatia",
      "countryCodeISO2": "HR"
  },
  {
      "name": "Cuba",
      "countryCodeISO2": "CU"
  },
  {
      "name": "Curaçao",
      "countryCodeISO2": "CW"
  },
  {
      "name": "Cyprus",
      "countryCodeISO2": "CY"
  },
  {
      "name": "Czechia",
      "countryCodeISO2": "CZ"
  },
  {
      "name": "Denmark",
      "countryCodeISO2": "DK"
  },
  {
      "name": "Djibouti",
      "countryCodeISO2": "DJ"
  },
  {
      "name": "Dominica",
      "countryCodeISO2": "DM"
  },
  {
      "name": "Dominican Republic",
      "countryCodeISO2": "DO"
  },
  {
      "name": "Ecuador",
      "countryCodeISO2": "EC"
  },
  {
      "name": "Egypt",
      "countryCodeISO2": "EG"
  },
  {
      "name": "El Salvador",
      "countryCodeISO2": "SV"
  },
  {
      "name": "Equatorial Guinea",
      "countryCodeISO2": "GQ"
  },
  {
      "name": "Eritrea",
      "countryCodeISO2": "ER"
  },
  {
      "name": "Estonia",
      "countryCodeISO2": "EE"
  },
  {
      "name": "Eswatini",
      "countryCodeISO2": "SZ"
  },
  {
      "name": "Ethiopia",
      "countryCodeISO2": "ET"
  },
  {
      "name": "Falkland Islands (Malvinas)",
      "countryCodeISO2": "FK"
  },
  {
      "name": "Faroe Islands",
      "countryCodeISO2": "FO"
  },
  {
      "name": "Fiji",
      "countryCodeISO2": "FJ"
  },
  {
      "name": "Finland",
      "countryCodeISO2": "FI"
  },
  {
      "name": "France",
      "countryCodeISO2": "FR"
  },
  {
      "name": "French Guiana",
      "countryCodeISO2": "GF"
  },
  {
      "name": "French Polynesia",
      "countryCodeISO2": "PF"
  },
  {
      "name": "French Southern Territories",
      "countryCodeISO2": "TF"
  },
  {
      "name": "Gabon",
      "countryCodeISO2": "GA"
  },
  {
      "name": "Gambia",
      "countryCodeISO2": "GM"
  },
  {
      "name": "Georgia",
      "countryCodeISO2": "GE"
  },
  {
      "name": "Germany",
      "countryCodeISO2": "DE"
  },
  {
      "name": "Ghana",
      "countryCodeISO2": "GH"
  },
  {
      "name": "Gibraltar",
      "countryCodeISO2": "GI"
  },
  {
      "name": "Greece",
      "countryCodeISO2": "GR"
  },
  {
      "name": "Greenland",
      "countryCodeISO2": "GL"
  },
  {
      "name": "Grenada",
      "countryCodeISO2": "GD"
  },
  {
      "name": "Guadeloupe",
      "countryCodeISO2": "GP"
  },
  {
      "name": "Guam",
      "countryCodeISO2": "GU"
  },
  {
      "name": "Guatemala",
      "countryCodeISO2": "GT"
  },
  {
      "name": "Guernsey",
      "countryCodeISO2": "GG"
  },
  {
      "name": "Guinea",
      "countryCodeISO2": "GN"
  },
  {
      "name": "Guinea-Bissau",
      "countryCodeISO2": "GW"
  },
  {
      "name": "Guyana",
      "countryCodeISO2": "GY"
  },
  {
      "name": "Haiti",
      "countryCodeISO2": "HT"
  },
  {
      "name": "Heard Island and McDonald Islands",
      "countryCodeISO2": "HM"
  },
  {
      "name": "Holy See",
      "countryCodeISO2": "VA"
  },
  {
      "name": "Honduras",
      "countryCodeISO2": "HN"
  },
  {
      "name": "Hong Kong",
      "countryCodeISO2": "HK"
  },
  {
      "name": "Hungary",
      "countryCodeISO2": "HU"
  },
  {
      "name": "Iceland",
      "countryCodeISO2": "IS"
  },
  {
      "name": "India",
      "countryCodeISO2": "IN"
  },
  {
      "name": "Indonesia",
      "countryCodeISO2": "ID"
  },
  {
      "name": "Iran (Islamic Republic of)",
      "countryCodeISO2": "IR"
  },
  {
      "name": "Iraq",
      "countryCodeISO2": "IQ"
  },
  {
      "name": "Ireland",
      "countryCodeISO2": "IE"
  },
  {
      "name": "Isle of Man",
      "countryCodeISO2": "IM"
  },
  {
      "name": "Israel",
      "countryCodeISO2": "IL"
  },
  {
      "name": "Italy",
      "countryCodeISO2": "IT"
  },
  {
      "name": "Jamaica",
      "countryCodeISO2": "JM"
  },
  {
      "name": "Japan",
      "countryCodeISO2": "JP"
  },
  {
      "name": "Jersey",
      "countryCodeISO2": "JE"
  },
  {
      "name": "Jordan",
      "countryCodeISO2": "JO"
  },
  {
      "name": "Kazakhstan",
      "countryCodeISO2": "KZ"
  },
  {
      "name": "Kenya",
      "countryCodeISO2": "KE"
  },
  {
      "name": "Kiribati",
      "countryCodeISO2": "KI"
  },
  {
      "name": "Korea (Democratic People's Republic of)",
      "countryCodeISO2": "KP"
  },
  {
      "name": "Korea, Republic of",
      "countryCodeISO2": "KR"
  },
  {
      "name": "Kuwait",
      "countryCodeISO2": "KW"
  },
  {
      "name": "Kyrgyzstan",
      "countryCodeISO2": "KG"
  },
  {
      "name": "Lao People's Democratic Republic",
      "countryCodeISO2": "LA"
  },
  {
      "name": "Latvia",
      "countryCodeISO2": "LV"
  },
  {
      "name": "Lebanon",
      "countryCodeISO2": "LB"
  },
  {
      "name": "Lesotho",
      "countryCodeISO2": "LS"
  },
  {
      "name": "Liberia",
      "countryCodeISO2": "LR"
  },
  {
      "name": "Libya",
      "countryCodeISO2": "LY"
  },
  {
      "name": "Liechtenstein",
      "countryCodeISO2": "LI"
  },
  {
      "name": "Lithuania",
      "countryCodeISO2": "LT"
  },
  {
      "name": "Luxembourg",
      "countryCodeISO2": "LU"
  },
  {
      "name": "Macao",
      "countryCodeISO2": "MO"
  },
  {
      "name": "Madagascar",
      "countryCodeISO2": "MG"
  },
  {
      "name": "Malawi",
      "countryCodeISO2": "MW"
  },
  {
      "name": "Malaysia",
      "countryCodeISO2": "MY"
  },
  {
      "name": "Maldives",
      "countryCodeISO2": "MV"
  },
  {
      "name": "Mali",
      "countryCodeISO2": "ML"
  },
  {
      "name": "Malta",
      "countryCodeISO2": "MT"
  },
  {
      "name": "Marshall Islands",
      "countryCodeISO2": "MH"
  },
  {
      "name": "Martinique",
      "countryCodeISO2": "MQ"
  },
  {
      "name": "Mauritania",
      "countryCodeISO2": "MR"
  },
  {
      "name": "Mauritius",
      "countryCodeISO2": "MU"
  },
  {
      "name": "Mayotte",
      "countryCodeISO2": "YT"
  },
  {
      "name": "Mexico",
      "countryCodeISO2": "MX"
  },
  {
      "name": "Micronesia (Federated States of)",
      "countryCodeISO2": "FM"
  },
  {
      "name": "Moldova, Republic of",
      "countryCodeISO2": "MD"
  },
  {
      "name": "Monaco",
      "countryCodeISO2": "MC"
  },
  {
      "name": "Mongolia",
      "countryCodeISO2": "MN"
  },
  {
      "name": "Montenegro",
      "countryCodeISO2": "ME"
  },
  {
      "name": "Montserrat",
      "countryCodeISO2": "MS"
  },
  {
      "name": "Morocco",
      "countryCodeISO2": "MA"
  },
  {
      "name": "Mozambique",
      "countryCodeISO2": "MZ"
  },
  {
      "name": "Myanmar",
      "countryCodeISO2": "MM"
  },
  {
      "name": "Namibia",
      "countryCodeISO2": "NA"
  },
  {
      "name": "Nauru",
      "countryCodeISO2": "NR"
  },
  {
      "name": "Nepal",
      "countryCodeISO2": "NP"
  },
  {
      "name": "Netherlands",
      "countryCodeISO2": "NL"
  },
  {
      "name": "New Caledonia",
      "countryCodeISO2": "NC"
  },
  {
      "name": "New Zealand",
      "countryCodeISO2": "NZ"
  },
  {
      "name": "Nicaragua",
      "countryCodeISO2": "NI"
  },
  {
      "name": "Niger",
      "countryCodeISO2": "NE"
  },
  {
      "name": "Nigeria",
      "countryCodeISO2": "NG"
  },
  {
      "name": "Niue",
      "countryCodeISO2": "NU"
  },
  {
      "name": "Norfolk Island",
      "countryCodeISO2": "NF"
  },
  {
      "name": "North Macedonia",
      "countryCodeISO2": "MK"
  },
  {
      "name": "Northern Mariana Islands",
      "countryCodeISO2": "MP"
  },
  {
      "name": "Norway",
      "countryCodeISO2": "NO"
  },
  {
      "name": "Oman",
      "countryCodeISO2": "OM"
  },
  {
      "name": "Pakistan",
      "countryCodeISO2": "PK"
  },
  {
      "name": "Palau",
      "countryCodeISO2": "PW"
  },
  {
      "name": "Palestine, State of",
      "countryCodeISO2": "PS"
  },
  {
      "name": "Panama",
      "countryCodeISO2": "PA"
  },
  {
      "name": "Papua New Guinea",
      "countryCodeISO2": "PG"
  },
  {
      "name": "Paraguay",
      "countryCodeISO2": "PY"
  },
  {
      "name": "Peru",
      "countryCodeISO2": "PE"
  },
  {
      "name": "Philippines",
      "countryCodeISO2": "PH"
  },
  {
      "name": "Pitcairn",
      "countryCodeISO2": "PN"
  },
  {
      "name": "Poland",
      "countryCodeISO2": "PL"
  },
  {
      "name": "Portugal",
      "countryCodeISO2": "PT"
  },
  {
      "name": "Puerto Rico",
      "countryCodeISO2": "PR"
  },
  {
      "name": "Qatar",
      "countryCodeISO2": "QA"
  },
  {
      "name": "Réunion",
      "countryCodeISO2": "RE"
  },
  {
      "name": "Romania",
      "countryCodeISO2": "RO"
  },
  {
      "name": "Russian Federation",
      "countryCodeISO2": "RU"
  },
  {
      "name": "Rwanda",
      "countryCodeISO2": "RW"
  },
  {
      "name": "Saint Barthélemy",
      "countryCodeISO2": "BL"
  },
  {
      "name": "Saint Helena, Ascension and Tristan da Cunha",
      "countryCodeISO2": "SH"
  },
  {
      "name": "Saint Kitts and Nevis",
      "countryCodeISO2": "KN"
  },
  {
      "name": "Saint Lucia",
      "countryCodeISO2": "LC"
  },
  {
      "name": "Saint Martin (French part)",
      "countryCodeISO2": "MF"
  },
  {
      "name": "Saint Pierre and Miquelon",
      "countryCodeISO2": "PM"
  },
  {
      "name": "Saint Vincent and the Grenadines",
      "countryCodeISO2": "VC"
  },
  {
      "name": "Samoa",
      "countryCodeISO2": "WS"
  },
  {
      "name": "San Marino",
      "countryCodeISO2": "SM"
  },
  {
      "name": "Sao Tome and Principe",
      "countryCodeISO2": "ST"
  },
  {
      "name": "Saudi Arabia",
      "countryCodeISO2": "SA"
  },
  {
      "name": "Senegal",
      "countryCodeISO2": "SN"
  },
  {
      "name": "Serbia",
      "countryCodeISO2": "RS"
  },
  {
      "name": "Seychelles",
      "countryCodeISO2": "SC"
  },
  {
      "name": "Sierra Leone",
      "countryCodeISO2": "SL"
  },
  {
      "name": "Singapore",
      "countryCodeISO2": "SG"
  },
  {
      "name": "Sint Maarten (Dutch part)",
      "countryCodeISO2": "SX"
  },
  {
      "name": "Slovakia",
      "countryCodeISO2": "SK"
  },
  {
      "name": "Slovenia",
      "countryCodeISO2": "SI"
  },
  {
      "name": "Solomon Islands",
      "countryCodeISO2": "SB"
  },
  {
      "name": "Somalia",
      "countryCodeISO2": "SO"
  },
  {
      "name": "South Africa",
      "countryCodeISO2": "ZA"
  },
  {
      "name": "South Georgia and the South Sandwich Islands",
      "countryCodeISO2": "GS"
  },
  {
      "name": "South Sudan",
      "countryCodeISO2": "SS"
  },
  {
      "name": "Spain",
      "countryCodeISO2": "ES"
  },
  {
      "name": "Sri Lanka",
      "countryCodeISO2": "LK"
  },
  {
      "name": "Sudan",
      "countryCodeISO2": "SD"
  },
  {
      "name": "Suriname",
      "countryCodeISO2": "SR"
  },
  {
      "name": "Svalbard and Jan Mayen",
      "countryCodeISO2": "SJ"
  },
  {
      "name": "Sweden",
      "countryCodeISO2": "SE"
  },
  {
      "name": "Switzerland",
      "countryCodeISO2": "CH"
  },
  {
      "name": "Syrian Arab Republic",
      "countryCodeISO2": "SY"
  },
  {
      "name": "Taiwan, Province of China",
      "countryCodeISO2": "TW"
  },
  {
      "name": "Tajikistan",
      "countryCodeISO2": "TJ"
  },
  {
      "name": "Tanzania, United Republic of",
      "countryCodeISO2": "TZ"
  },
  {
      "name": "Thailand",
      "countryCodeISO2": "TH"
  },
  {
      "name": "Timor-Leste",
      "countryCodeISO2": "TL"
  },
  {
      "name": "Togo",
      "countryCodeISO2": "TG"
  },
  {
      "name": "Tokelau",
      "countryCodeISO2": "TK"
  },
  {
      "name": "Tonga",
      "countryCodeISO2": "TO"
  },
  {
      "name": "Trinidad and Tobago",
      "countryCodeISO2": "TT"
  },
  {
      "name": "Tunisia",
      "countryCodeISO2": "TN"
  },
  {
      "name": "Turkey",
      "countryCodeISO2": "TR"
  },
  {
      "name": "Turkmenistan",
      "countryCodeISO2": "TM"
  },
  {
      "name": "Turks and Caicos Islands",
      "countryCodeISO2": "TC"
  },
  {
      "name": "Tuvalu",
      "countryCodeISO2": "TV"
  },
  {
      "name": "Uganda",
      "countryCodeISO2": "UG"
  },
  {
      "name": "Ukraine",
      "countryCodeISO2": "UA"
  },
  {
      "name": "United Arab Emirates",
      "countryCodeISO2": "AE"
  },
  {
      "name": "United Kingdom",
      "countryCodeISO2": "GB"
  },
  {
      "name": "United States of America",
      "countryCodeISO2": "US"
  },
  {
      "name": "United States Minor Outlying Islands",
      "countryCodeISO2": "UM"
  },
  {
      "name": "Uruguay",
      "countryCodeISO2": "UY"
  },
  {
      "name": "Uzbekistan",
      "countryCodeISO2": "UZ"
  },
  {
      "name": "Vanuatu",
      "countryCodeISO2": "VU"
  },
  {
      "name": "Venezuela (Bolivarian Republic of)",
      "countryCodeISO2": "VE"
  },
  {
      "name": "Viet Nam",
      "countryCodeISO2": "VN"
  },
  {
      "name": "Virgin Islands (British)",
      "countryCodeISO2": "VG"
  },
  {
      "name": "Virgin Islands (U.S.)",
      "countryCodeISO2": "VI"
  },
  {
      "name": "Wallis and Futuna",
      "countryCodeISO2": "WF"
  },
  {
      "name": "Western Sahara",
      "countryCodeISO2": "EH"
  },
  {
      "name": "Yemen",
      "countryCodeISO2": "YE"
  },
  {
      "name": "Zambia",
      "countryCodeISO2": "ZM"
  },
  {
      "name": "Zimbabwe",
      "countryCodeISO2": "ZW"
  }
]

export default countryISOCode;