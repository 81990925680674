const ContactUsHere = () => {
  const link = '#';
  return (
    <a href={link} target='_blank' rel='noreferrer'>
      here
    </a>
  );
};

export default ContactUsHere;
