import { useTheme } from '@mui/material/styles';

export const useStyledTheme = () => {
  const theme = useTheme();

  return {
    primaryMain: theme.palette.primary.main,
    primaryLight: theme.palette.primary.light,
    outlineSelected: `2px solid ${theme.palette.primary.main}`,
    outlineHover: `1px solid ${theme.palette.primary.main}`,
    boxShadowSelected: `0 0 5px 2px ${theme.palette.primary.light}, 0 0 0 1px ${theme.palette.primary.light}`,
  };
};