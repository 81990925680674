import serverApi from 'lib/apiConfig';

const googleLoginServices = {
  generateGoogleAuthUrl,
};

export default googleLoginServices;

async function generateGoogleAuthUrl(): Promise<string> {
  const response = await serverApi.basic.post('/auth/google-auth-url');
  const authURL = (await response.data) as string;
  return authURL;
}