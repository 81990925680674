import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

import CustomTableHead, { CustomTableHeadProps } from './CustomTableHead';

interface CustomTableProps extends CustomTableHeadProps {
  children: React.ReactNode;
}

const CustomTable = ({ headCells, children }: CustomTableProps) => {
  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <TableContainer
      >
        <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size={'small'}>
          <CustomTableHead headCells={headCells} />
          {children}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomTable;