import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { selectAllServices } from 'store/features/services/serviceSlice';
import { selectAllMeetings } from 'store/features/meetings/meetingsSlice';

export type SortedSPMIds = string[];

const INITIAL_STATE: SortedSPMIds = [];

// payload Action types | AP: ActionPayload
type IdAddedAP = {
  index: number;
  id: string;
};

type IdDeletedAP = Record<'id', string>;

type IdPositionUpdatedAP = {
  currentIndex: number;
  updatedIndex: number;
};

// NOTE: specially made for sorting services meetings.
// NOTE: adding new item at an idex, updating position and deleting element at an index is possible
// NOTE: needs to be in sync with ServicesModule.services
const sortedSPMIdsSlice = createSlice({
  name: 'sortedSPMIds',
  initialState: INITIAL_STATE,
  reducers: {
    idAdded: (state: SortedSPMIds, action: PayloadAction<IdAddedAP>) => {
      const { index, id } = action.payload;
      state.splice(index, 0, id);
    },
    idDeleted: (state: SortedSPMIds, action: PayloadAction<IdDeletedAP>) => {
      const { id } = action.payload;
      const indexToBeDeleted = state.indexOf(id);
      state.splice(indexToBeDeleted, 1);
    },
    idPositionUpdated: (
      state: SortedSPMIds,
      action: PayloadAction<IdPositionUpdatedAP>
    ) => {
      const { currentIndex, updatedIndex } = action.payload;
      const toBeUpdatedId = state[currentIndex];
      state.splice(currentIndex, 1).splice(updatedIndex, 0, toBeUpdatedId);
    },
    setAllIds: (state: SortedSPMIds, action: PayloadAction<SortedSPMIds>) => {
      return action.payload;
    },
    allIdsDeleted: () => {
      return INITIAL_STATE;
    },
  },
});

const sortedSPMIdsSliceReducer = sortedSPMIdsSlice.reducer;
export default sortedSPMIdsSliceReducer;

export const {
  idAdded,
  idDeleted,
  idPositionUpdated,
  allIdsDeleted,
  setAllIds,
} = sortedSPMIdsSlice.actions;

// slice selectors
export function selectSortedSPMIds(state: RootState) {
  return state.persistedReducer.sortedSPMIds;
}

export const selectTotalSPMIds = (state: RootState) => {
  const SPM_sortedIds = selectSortedSPMIds(state);
  return SPM_sortedIds.length;
};

export const selectSortedSPM = createSelector(
  [selectSortedSPMIds, selectAllServices, selectAllMeetings],
  (rootStateSPMIds, rootStateAllServices, rootStateAllMeetings) => {
    const unsortedSPM = [...rootStateAllServices, ...rootStateAllMeetings];
    const calculatedSortedSPMOutput = rootStateSPMIds.map((eachId) => {
      return unsortedSPM.filter((eachUnsortedSPM_id) => {
        return eachUnsortedSPM_id._id === eachId;
      })[0];
    });
    const validCalculatedSortedSPMOutput = calculatedSortedSPMOutput.filter(Boolean); // removing undefined(s)
    return validCalculatedSortedSPMOutput;
  }
);
