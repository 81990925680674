export type ClipboardOnSuccess = () => void;
export type ClipboardOnError = (err: string) => void;

const useClipbord = () => {
  const setClipboard = (
    text: string,
    onSuccess?: ClipboardOnSuccess,
    onError?: ClipboardOnError
  ) => {
    const type = 'text/plain';
    const blob = new Blob([text], { type });
    const data = [new ClipboardItem({ [type]: blob })];
    navigator.clipboard.write(data).then(
      () => {
        onSuccess?.();
      },
      (err) => {
        onError?.(err as string);
      }
    );
  };
  return { setClipboard };
};

export default useClipbord;
