import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import SPMCardActions from 'components/MUI_SPM/SPMCard/sub-components/SPMCardActions/SPMCardActions';
import SPMCardCTAButton from 'components/MUI_SPM/SPMCard/sub-components/SPMCardCTAButton/SPMCardCTAButton';
import SPMCardIncludedItems from 'components/MUI_SPM/SPMCard/sub-components/SPMCardIncludedItems/SPMCardIncludedItems';
import SPMCardPriceTime from 'components/MUI_SPM/SPMCard/sub-components/SPMCardPriceTime/SPMCardPriceTime';
import IfElse from 'components/utilities/IfElse';
import { getLocalOrGlobalStyling } from 'components/utilities/styleUtilities/styleUtilities';
import CircleIcon from '@mui/icons-material/Circle';
import ReadMore from 'components/utilities/ReadMore';
import useHandleImgOnError from 'hooks/useHandleImgOnError';
import { TinymceFormatPreserver } from 'components/utilities/TinyMceEditor/TinymceRichText';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import cloudinaryUtilsFns from 'utilities/CloudinaryUtilsFns';
import { useGetSortedSPMByEditorIdQuery } from 'store/rtkqFeatures/api/spmSportedExtendedApi';

type Function = () => void;

export type SPMCardProps = {
  styling: any;
  cardEntityId: string;
  cardTitle: string;
  isPaid: boolean;
  cardImg: string;
  isEntityActive: boolean;
  cardDescriptionContent: string;
  cardIncludedItemsHeading: string;
  cardCTAButtonText: string;
  editActionRedirectPath: string;
  editActionSearchParams: string;
  handleCardDeleteAction: Function;
  onCTABtnClick: Function;
  time?: number;
  price?: number;
  CTAHighPriorityContext?: React.ReactElement;
  CTALowPriorityContext?: React.ReactElement;
  cardIncludedItems?: string[];
  // COMMENT styling: Store;
};

const SPMCard: React.FunctionComponent<SPMCardProps> = ({
  time,
  price,
  isPaid,
  styling,
  cardImg,
  cardTitle,
  cardEntityId,
  isEntityActive,
  cardCTAButtonText,
  cardDescriptionContent,
  handleCardDeleteAction,
  editActionRedirectPath,
  editActionSearchParams,
  cardIncludedItemsHeading,
  CTAHighPriorityContext,
  CTALowPriorityContext,
  onCTABtnClick,
  cardIncludedItems = [],
}) => {
  const { cachedLoggedInUser: loggedInUser } = useGetLoggedInUser();
  const { handleImageError, showImage } = useHandleImgOnError();
  const {data: sortedSPM} = useGetSortedSPMByEditorIdQuery(loggedInUser?._id ?? '');

  const imageStyle = sortedSPM?.style?.image;
  const isScreenSizeUp901Px = useMediaQuery('(min-width:901px)');
  const serviceStatusColor = isEntityActive ? 'green' : 'red';
  const {
    button,
    description,
    duration,
    price: priceStyling,
    tag,
    title,
    ...restItem
  } = styling.item;

  const haveTags = !!cardIncludedItems.length;
  const haveTimeOrPrice = !!time || !!price;
  const doesCardDescLengthExceeded = cardDescriptionContent.length > 300;
  const editEntityPath = `${editActionRedirectPath}${editActionSearchParams}`;
  const descriptionStyle = {
    color: getLocalOrGlobalStyling(description.color, styling.global.color),
    fontSize: getLocalOrGlobalStyling(description.fontSize, styling.global.fontSize),
  };
  const isDirectionRtl = loggedInUser?.languageDir === 'rtl';
  
  return (
    <Grid
      item
      component='li'
      xs={12}
      sx={{
        '& *': { direction: loggedInUser?.languageDir ?? 'ltr' },
      }}
    >
      <Grid
        container
        justifyContent='space-between'
        gap={2}
        sx={{
          position: 'relative', // for card actions
          borderStyle: 'solid',          
          ...restItem,
        }}
      >
        <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
          {/* --------------- card actions (Position: absolute) (Edit | Delete) ---------------- */}
          <SPMCardActions
            isDirectionRtl={isDirectionRtl}
            borderRadius={styling.item.borderRadius}
            background={styling.item.background}
            color={styling.title.color}
            handleCardDeleteAction={handleCardDeleteAction}
            editActionRedirectPath={editActionRedirectPath}
            editActionSearchParams={editActionSearchParams}
          />
          <Stack
            sx={{
              '& > *:not(:first-of-type)': {
                marginTop: haveTags ? '2.25rem' : haveTimeOrPrice ? '1.5rem' : 0,
              },
            }}
            className='this-stack'
          >
            {/* --------------- card title + price / time ---------------- */}
            <Box>
              <Link to={editEntityPath}>
                <Typography
                  textAlign={isDirectionRtl ? 'right' : 'left'}
                  component='h3'
                  variant='h3'
                  sx={{
                    marginBottom: '0.3em',
                    fontSize: {
                      xs: `calc(${title.fontSize}/ 1.2 )`,
                      md: title.fontSize,
                    },
                    fontWeight: 500,
                    color: getLocalOrGlobalStyling(title.color, styling.global.color),
                  }}
                >
                  {cardTitle}&nbsp;
                  <Box
                    component='span'
                    sx={{
                      verticalAlign: 'text-top',
                    }}
                  >
                    <CircleIcon
                      alignmentBaseline='middle'
                      sx={{
                        color: serviceStatusColor,
                        width: '12px',
                        height: '10px',
                      }}
                    />
                  </Box>
                </Typography>
              </Link>
              {/* card price time   */}
              <Link to={editEntityPath}>
                <SPMCardPriceTime
                  styling={styling}
                  time={time}
                  price={price}
                  isPaid={isPaid}
                />
              </Link>
            </Box>

            {/* card what's included */}
            <IfElse condition={haveTags}>
              <Link to={editEntityPath}>
                <SPMCardIncludedItems
                  globalStyling={styling.global}
                  tagStyling={tag}
                  cardIncludedItems={cardIncludedItems}
                  cardIncludedItemsHeading={cardIncludedItemsHeading}
                />
              </Link>
            </IfElse>

            {/* ----------- card description | mode = read more */}
            <CardDescriptionContainer
              hidden={!doesCardDescLengthExceeded}
              redirectEditPath={editEntityPath}
            >
              <ReadMore
                sx={{
                  ...descriptionStyle,
                  '& > *': { direction: loggedInUser?.languageDir ?? 'ltr' },
                }}
                html={cardDescriptionContent}
                length={300}
                gradient={styling.item.background}
              />
            </CardDescriptionContainer>

            {/* ----------- card description | mode = simple text */}
            <Box
              sx={{
                '& > *': {
                  direction: loggedInUser?.languageDir ?? 'ltr',
                  textAlign: isDirectionRtl ? 'right !important' : 'revert',
                },
              }}
            >
              <CardDescriptionContainer
                hidden={doesCardDescLengthExceeded}
                redirectEditPath={editEntityPath}
              >
                <Typography
                  dir={loggedInUser?.languageDir ?? 'ltr'}
                  variant='body1'
                  component='div'
                  dangerouslySetInnerHTML={{ __html: cardDescriptionContent }}
                  sx={{
                    ...descriptionStyle,
                  }}
                />
              </CardDescriptionContainer>
            </Box>

            {!!!isScreenSizeUp901Px && (
              <Box marginTop={'auto'}>
                {/* --------- cta Highest priority context (Mobile) --------------------- */}
                <IfElse condition={!!CTAHighPriorityContext}>
                  {CTAHighPriorityContext}
                </IfElse>
                {/* ---------------- card cta btn (Mobile) --------------------------- */}
                <SPMCardCTAButton
                  onCTABtnClick={onCTABtnClick}
                  cardEntityId={cardEntityId}
                  cardCTAButtonText={cardCTAButtonText}
                  buttonStyling={button}
                />
              </Box>
            )}
          </Stack>
        </Grid>
        <Grid
          className='card-image-container'
          item
          xs={12}
          md={3}
          order={{ xs: 1, md: 2 }}
          sx={{
            marginBottom: {
              xs: 2.8,
              md: 0,
            },
            flexGrow: 1,
            maxWidth: {
              xs: '100%',
              md: '100%',
            },
            mt: 'auto',
          }}
        >
          {/* ---------------- card image --------------------------- */}
          <IfElse condition={showImage && !!cardImg}>
            <Link
              to={editEntityPath}
              style={{ display: 'inline-block', flexGrow: 1, marginTop: 'auto', width: '100%', height: '100%' }}
            >
              <Paper
                elevation={0}
                sx={{
                  background: 'transparent',
                  text: 'center',
                  display: 'grid',
                  placeContent: 'center',
                }}
              >
                <Box
                  component='img'
                  onError={handleImageError}
                  src={cloudinaryUtilsFns.addTransformationToImageUrl({
                    width: 500,
                    url: cardImg,
                  })}
                  sx={{
                    width: {
                      xs: '100%',
                      md: '100%',
                    },
                    ...imageStyle,
                  }}
                />
              </Paper>
            </Link>
          </IfElse>
          {/* --------- cta Highest priority context (Desktop) --------------------- */}
          <IfElse condition={isScreenSizeUp901Px}>
            <IfElse condition={!!CTAHighPriorityContext}>{CTAHighPriorityContext}</IfElse>
          </IfElse>
          {/* ---------------- card cta btn --------------------------- */}
          <IfElse condition={!!isScreenSizeUp901Px}>
            <SPMCardCTAButton
              onCTABtnClick={onCTABtnClick}
              cardEntityId={cardEntityId}
              cardCTAButtonText={cardCTAButtonText}
              buttonStyling={button}
            />
          </IfElse>
          <IfElse condition={!!CTALowPriorityContext}>
            <Stack>{CTALowPriorityContext}</Stack>
          </IfElse>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SPMCard;

type CardDescriptionContainerProps = {
  hidden: boolean;
  redirectEditPath: string;
  children: React.ReactNode;
};

const CardDescriptionContainer = ({
  hidden,
  children,
  redirectEditPath,
}: CardDescriptionContainerProps) => {
  if (hidden) return <></>;

  return (
    <Link to={redirectEditPath}>
      <TinymceFormatPreserver>{children}</TinymceFormatPreserver>
    </Link>
  );
};
