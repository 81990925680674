import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { SocialLinkType } from 'types/user';
import { socialIconsURL } from './SocialLinkInput';

type CustomLinksType = Pick<SocialLinkType, 'link' | 'iconUrl'>;

export type SocialLinksGlobalState = {
  links: {
    instagram: string;
    tiktok: string;
    facebook: string;
    twitter: string;
    x: string;
    youtube: string;
    linkedin: string;
  };
  customLinks: CustomLinksType[];
  errors: Partial<{
    instagram: string;
    tiktok: string;
    facebook: string;
    twitter: string;
    x: string,
    youtube: string;
    linkedin: string;
  }> | null;
};

const socialLinksGlobalinitialState: SocialLinksGlobalState = {
  links: {
    instagram: '',
    tiktok: '',
    facebook: '',
    twitter: '',
    x: '',
    youtube: '',
    linkedin: '',
  },
  customLinks: [],
  errors: null,
};

type SocialContextType = {
  setSocialLinksGlobalState: React.Dispatch<React.SetStateAction<SocialLinksGlobalState>>;
  socialLinksGlobalState: SocialLinksGlobalState;
};

// constants
const socialSitesHandleURL = {
  instagram: 'https://instagram.com/handle',
  twitter: 'https://twitter.com/handle',
  x: 'https://x.com/handle',
  facebook: 'https://facebook.com/handle',
  linkedin: 'https://linkedin.com/in/handle',
  youtube: 'https://youtube.com/handle',
  youtube_user: 'https://youtube.com/user/handle',
  youtube_channel: 'https://youtube.com/channel/handle',
  tiktok: 'https://tiktok.com/@handle',
  // snapchat: 'https://snapchat.com/add/handle',
  // reddit: 'https://reddit.com/user/handle',
  // pinterest: 'https://pinterest.com/handle',
  // tumblr: 'https://handle.tumblr.com',
};

export const SocialContext = createContext<SocialContextType>({
  socialLinksGlobalState: socialLinksGlobalinitialState,
  setSocialLinksGlobalState: () => {},
});

type SocialContextProviderProps = {
  children: React.ReactNode;
  socialLinks?: SocialLinkType[];
};

export type SocialContextProviderRef = {
  onFinalSubmission: () => void;
};

const SocialContextProvider = forwardRef<
  SocialContextProviderRef,
  SocialContextProviderProps
>(({ children, socialLinks }, ref) => {
  const [socialLinksGlobalState, setSocialLinksGlobalState] =
    useState<SocialLinksGlobalState>(socialLinksGlobalinitialState);

  useEffect(() => {
    if (socialLinks?.length) {
      const replicaSocialLinksGlobalState = { ...socialLinksGlobalState };
      const localSocialLinksList = [...socialLinks];

      for (let i = 0; i < socialLinks.length; i++) {
        const element = socialLinks[i];
        for (const property in socialSitesHandleURL) {
          const removedSiteHandle = socialSitesHandleURL[
            property as keyof typeof socialSitesHandleURL
          ].replace('handle', '');
          if (element.link?.includes(removedSiteHandle)) {
            replicaSocialLinksGlobalState.links[
              property as keyof SocialLinksGlobalState['links']
            ] = element.link?.replace(removedSiteHandle, '') || '';

            const socialLinkToRemove = localSocialLinksList.findIndex(
              (eachSocialLink) => eachSocialLink?.link === socialLinks[i].link
            );
            localSocialLinksList.splice(socialLinkToRemove, 1);
          }
        }
      }
      
      setSocialLinksGlobalState((prev) => ({
        ...prev,
        customLinks: localSocialLinksList,
        links: replicaSocialLinksGlobalState.links,
      }));
    }
  }, [socialLinks]);

  function onFinalSubmission() {
    const obj: CustomLinksType[] = [];
  
    for (const property in socialLinksGlobalState.links) {
      const urlPrefix = property.split('_');
      if (
        (urlPrefix.length === 1 &&
        socialLinksGlobalState.links[property as keyof SocialLinksGlobalState['links']]) ||
        (urlPrefix.length === 2  &&
        socialLinksGlobalState.links[urlPrefix[0] as keyof SocialLinksGlobalState['links']] &&
        socialLinksGlobalState.links[urlPrefix[1] as keyof SocialLinksGlobalState['links']])
      ) {
        const modifiedLink = getFullSocialLink(socialLinksGlobalState, property)[0];
        if (modifiedLink) {
          obj.push({
            link: modifiedLink,
            iconUrl: socialIconsURL[property as keyof typeof socialIconsURL],
          });
        }
      }
    }
  
    obj.push(...socialLinksGlobalState.customLinks);
  
    return obj;
  }

  // Expose onFinalSubmission function via ref
  useImperativeHandle(ref, () => ({
    onFinalSubmission,
  }));

  return (
    <SocialContext.Provider
      value={{
        socialLinksGlobalState,
        setSocialLinksGlobalState,
      }}
    >
      {children}
    </SocialContext.Provider>
  );
});

export default SocialContextProvider;

export const useSocialContext = () => {
  const context = useContext(SocialContext);
  if (!context) {
    throw new Error('useSocialContext must be used within a SocialContextProvider');
  }
  return context;
};

export const getFullSocialLink = (socialLinks: SocialLinksGlobalState, property: string) => {
  let handle = socialLinks.links[property as keyof SocialLinksGlobalState['links']].replace('www.', '').split('?')[0];
  if (handle.charAt(handle.length - 1) === '/') {
    handle = handle.slice(0, -1);
  }
  handle = handle.replace('@', '');
  const domain = socialSitesHandleURL[property as keyof typeof socialSitesHandleURL].replace('handle', '');
  const fullUrl = handle.includes(domain) ? handle : domain + handle;
  const handleOnly = handle.replace(domain, '');
  return [fullUrl, handleOnly];
}

