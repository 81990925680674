import serverApi from 'lib/apiConfig';
import loginTokenUtils from 'utilities/loginTokenUtils';
import { sucessfulLoginInit } from 'components/logins/onLoginSuccessUtilities';

export async function addAnotherAccount() {
  try {
    const res = await serverApi.basicWithCredentials.post('/auth/add-another-account');
    const { user, token } = res.data;

    // Save the token and initialize successful login
    loginTokenUtils.setLocalStorageLoginToken(token);
    await sucessfulLoginInit({ editorId: user._id });

    return { success: true, user, token };
  } catch (error) {
    console.error('Error adding another account:', error);
    return { success: false, error };
  }
}
