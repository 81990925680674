// !! Not in use anymore
import Box from '@mui/material/Box';

type ThisProps = {
  children: JSX.Element;
  bigCurves?: boolean;
};

const CurvesDesign = ({ children, bigCurves = true }: ThisProps) => {
  const curvesStyle = bigCurves ? 'big-curves' : 'small-curves';

  return <Box className={`bg-curves ${curvesStyle}`}>{children}</Box>;
};

export default CurvesDesign;
