import React from 'react';
import IfElse from 'components/utilities/IfElse';

// !! DEPRECATED: don't use this component for future developements

type InputValueOptions = number | string;

type LabelOnTheBorderType<InputValueType extends InputValueOptions> = {
  value: InputValueType;
  type: InputValueType extends number ? 'number' : 'text';
  labelname: React.ReactNode;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  floatingLabelStyling?: string;
  floatingLabelDisappeared?: boolean;
  [x: string]: any;
};

// NOTE: This is being used in GoogleLocationAutoCompleteInput Component. Play carefully
const LabelOnTheBorder = <InputValueType extends InputValueOptions>({
  value,
  type,
  labelname,
  handleChange,
  floatingLabelStyling = '',
  floatingLabelDisappeared = false,
  ...restProps
}: LabelOnTheBorderType<InputValueType>) => {
  return (
    <div className={`${floatingLabelStyling} floating-label`}>
      <IfElse condition={!floatingLabelDisappeared}>
        <span className='floating-label-content'>{labelname}</span>
      </IfElse>
      <input style={{ fontSize: '2.2rem', textAlign: 'center', padding: '16px' }} type={type} value={value} onChange={handleChange} {...restProps} />
    </div>
  );
};

export default LabelOnTheBorder;
