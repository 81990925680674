import { useEffect, useCallback, useRef, useState, ReactNode, ReactElement } from 'react';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Slide,
  Stack,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import MenuIcon from '@mui/icons-material/Menu';

import HeaderMenuItems from 'components/Header/sub-components/HeaderMenuItems';
import IfElse from 'components/utilities/IfElse';
import ThemeSelector, {
  PREMIUM_THEME_ICON_URL,
} from 'components/ThemeSelector/ThemeSelector';
import FontSelector from 'components/FontSelector/FontSelector';
import ThemeBanner from 'components/Header/sub-components/ThemeBanner/ThemeBanner';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import LayoutContainer from 'components/Containers/LayoutContainer';

import useBooleanStateController, {
  StateController,
} from 'hooks/useBooleanStateController';
import useCurrentThemeSelector from 'store/rtkqFeatures/api/hooks/useCurrentThemeSelector';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import useGetStripePlatformCustomer from 'store/rtkqFeatures/api/hooks/useGetStripePlatformCustomer';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';

import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import { Link } from 'react-router-dom';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

export const HEADER_HEIGHT = 48;

const Header = () => {
  const { isPremiumUser } = useGetStripePlatformCustomer();
  const [themeBannerVisible, updateThemeBannerVisible] = useBooleanStateController(false);
  const isScreenSizeLessThan601Px = useMediaQuery('(max-width: 601px)');
  const [isMenuItemsDrawerOpen, menuItemsDrawerController] =
    useBooleanStateController(false);
  const { cacheCurrentThemeIndex } = useCurrentThemeSelector();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();

  const { navbarStyles } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      navbarStyles: themes
        ? themes[localCurrentThemeIndex].navbar
        : initialThemeState.navbar,
    }),
  });

  const closeThemeBanner = useCallback(
    () => updateThemeBannerVisible.setFalse(),
    [updateThemeBannerVisible]
  );

  const onFontChange = useCallback(
    () => menuItemsDrawerController.setFalse(),
    [menuItemsDrawerController]
  );

  useEffect(() => {
    const localCacheThemeIndexSame = localCurrentThemeIndex === cacheCurrentThemeIndex;
    updateThemeBannerVisible.set(!localCacheThemeIndexSame);
  }, [localCurrentThemeIndex, cacheCurrentThemeIndex, updateThemeBannerVisible]);

  if (!navbarStyles) {
    return <FrontsLoader />;
  }

  return (
    <>
      <ThemeBannerWrapper visible={themeBannerVisible} />
      <AppHeader
        closeThemeBanner={closeThemeBanner}
        isPremiumUser={isPremiumUser}
        themeBannerVisible={themeBannerVisible}
      >
        <HeaderContent
          isScreenSizeLessThan601Px={isScreenSizeLessThan601Px}
          isMenuItemsDrawerOpen={isMenuItemsDrawerOpen}
          menuItemsDrawerController={menuItemsDrawerController}
          onFontChange={onFontChange}
        />
      </AppHeader>
    </>
  );
};

const ThemeBannerWrapper = ({ visible }: { visible: boolean }) => (
  <IfElse condition={visible}>
    <Box sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 10002 }} />
  </IfElse>
);

const HeaderContent = ({
  isScreenSizeLessThan601Px,
  isMenuItemsDrawerOpen,
  menuItemsDrawerController,
  onFontChange,
}: {
  isScreenSizeLessThan601Px: boolean;
  isMenuItemsDrawerOpen: boolean;
  menuItemsDrawerController: StateController;
  onFontChange: () => void;
}) => (
  <Stack
    direction='row'
    gap={{ xs: 0, sm: 0, md: 0 }}
    sx={{
      '& > div': {
        maxWidth: { md: 'fit-content' },
      },
    }}
    alignItems='center'
  >
    <ThemeSelector />
    <IfElse condition={!isScreenSizeLessThan601Px}>
      <FontSelector />
    </IfElse>
    <IfElse
      condition={isScreenSizeLessThan601Px}
      alternative={
        <HeaderMenuItems isScreenSizeLessThan601Px={isScreenSizeLessThan601Px} />
      }
    >
      <MobileMenu
        isMenuItemsDrawerOpen={isMenuItemsDrawerOpen}
        menuItemsDrawerController={menuItemsDrawerController}
        isScreenSizeLessThan601Px={isScreenSizeLessThan601Px}
        onFontChange={onFontChange}
      />
    </IfElse>
  </Stack>
);

const MobileMenu = ({
  isMenuItemsDrawerOpen,
  menuItemsDrawerController,
  isScreenSizeLessThan601Px,
  onFontChange,
}: {
  isMenuItemsDrawerOpen: boolean;
  menuItemsDrawerController: StateController;
  isScreenSizeLessThan601Px: boolean;
  onFontChange: () => void;
}) => (
  <>
    <IconButton sx={{ marginLeft: 'auto' }} onClick={menuItemsDrawerController.setTrue}>
      <MenuIcon fontSize='large' />
    </IconButton>
    <Drawer
      sx={{ zIndex: 1301 }}
      anchor='right'
      open={isMenuItemsDrawerOpen}
      onClose={menuItemsDrawerController.setFalse}
      PaperProps={{ style: { width: '50%' } }}
    >
      <HeaderMenuItems
        isScreenSizeLessThan601Px={isScreenSizeLessThan601Px}
        onFontChange={onFontChange}
      />
    </Drawer>
  </>
);

const AppHeader = ({
  themeBannerVisible,
  closeThemeBanner,
  isPremiumUser,
  children = null,
}: {
  themeBannerVisible: boolean;
  closeThemeBanner: () => void;
  isPremiumUser: boolean;
  children: ReactNode;
}) => {
  const [top, setTop] = useState(HEADER_HEIGHT);
  const appBarRef = useRef<HTMLElement>(null);
  const trigger = useScrollTrigger();

  useEffect(() => {
    setTop(trigger ? 0 : appBarRef.current?.clientHeight || HEADER_HEIGHT);
  }, [trigger]);

  return (
    <>
      <HideOnScroll>
        <AppBar
          ref={appBarRef}
          position='fixed'
          elevation={1}
          sx={{
            backgroundColor: 'white',
            width: '100%',
            pt: 0,
            zIndex: 1300,
          }}
        >
          <LayoutContainer>
            <Toolbar
              disableGutters
              sx={{
                justifyContent: 'space-between',
                gap: 2.5,
                maxHeight: { xs: `${HEADER_HEIGHT}px`, md: `${HEADER_HEIGHT}px` },
                minHeight: { xs: `${HEADER_HEIGHT}px`, md: `${HEADER_HEIGHT}px` },
              }}
            >
              <Logo isPremiumUser={isPremiumUser} />
              {children}
            </Toolbar>
          </LayoutContainer>
        </AppBar>
      </HideOnScroll>
      <IfElse condition={themeBannerVisible}>
        <ThemeBanner
          closeThemeBanner={closeThemeBanner || (() => {})}
          sx={{
            position: 'fixed',
            top: top,
            left: 0,
            right: 0,
            zIndex: 1300,
            width: '100%',
          }}
        />
      </IfElse>
    </>
  );
};

const Logo = ({ isPremiumUser }: { isPremiumUser: boolean }) => {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  return (
    <Box
      sx={{
        maxWidth: { xs: '60px', sm: '80px', md: '100px', lg: '100px' },
        minWidth: 60,
      }}
    >
      <Link to={`/${cachedLoggedInUser?.url || ''}`} style={{ display: 'flex' }}>
        <Box
          component='img'
          src={`${process.env.PUBLIC_URL}/fronts-ai.png`}
          alt='logo'
          sx={{ objectFit: 'contain', maxWidth: '100%' }}
        />
        {isPremiumUser && (
          <Box
            component='img'
            width={{ xs: 15, md: 30 }}
            height={{ xs: 15, md: 30 }}
            ml={0.5}
            src={PREMIUM_THEME_ICON_URL}
            alt='logo'
            sx={{ objectFit: 'contain', maxWidth: '100%' }}
          />
        )}
      </Link>
    </Box>
  );
};

const HideOnScroll = ({ children }: { children: ReactElement<any, any> }) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};

export default Header;
