import { createContext, useContext } from 'react';

type SiteContextType = {
  popupOpen: boolean;
  setPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SiteContext = createContext<SiteContextType>({
  setPopupOpen: () => {},
  setIsLoading: () => {},
  popupOpen: false,
  isLoading: false,
});

const SiteContextProvider = ({
  children,
  popupOpen,
  isLoading,
  setPopupOpen,
  setIsLoading,
}: {
  children: React.ReactNode;  
} & SiteContextType) => {
  return (
    <SiteContext.Provider
      value={{
        setIsLoading,
        isLoading,
        setPopupOpen,
        popupOpen,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export default SiteContextProvider;

export const useSiteContext = () => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error('useSiteContext must be used within a SiteContextProvider');
  }
  return context;
};
