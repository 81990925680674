import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import ServiceOrder, { ServiceOrderStatusValues } from 'types/serviceOrder';

const serviceOrdersExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getServiceOrdersByUserId: build.query<ServiceOrder[], string>({
      query: (userId) => `/service-orders/user/${userId}`,
      providesTags: (result, error, userId) =>
        result
          ? [...result.map(({ _id }) => ({ type: 'ServiceOrder' as const, id: _id }))]
          : [{ type: 'ServiceOrder' as const, id: userId }],
      transformResponse: (response: ServiceOrder[]) =>
        response.sort((a, b) => {
          return Number(b.createdAt) - Number(a.createdAt);
        }),
    }),
    getServiceOrderByServiceOrderId: build.query<ServiceOrder, string>({
      query: (serviceOrderId) => `/service-orders/${serviceOrderId}`,
      providesTags: (result, error, serviceOrderId) => [
        { type: 'ServiceOrder' as const, id: serviceOrderId },
      ],
    }),
    updateServiceOwnerInstructionsToCustomer: build.mutation<
      void,
      Record<'serviceOrderId' | 'updatedInstructions', string>
    >({
      query: ({ serviceOrderId, updatedInstructions }) => ({
        url: `/service-orders/${serviceOrderId}/instructions-to-customer`,
        method: 'PATCH',
        body: {
          updatedInstructions,
        },
      }),
      invalidatesTags: (result, error, { serviceOrderId }) => [
        { type: 'ServiceOrder' as const, id: serviceOrderId },
      ],
    }),
    updateServiceOrderStatus: build.mutation<
      void,
      {
        serviceOrderId: string;
        updatedStatus: ServiceOrderStatusValues;
      }
    >({
      query: ({ serviceOrderId, updatedStatus }) => ({
        url: `/service-orders/${serviceOrderId}/status`,
        method: 'PATCH',
        body: {
          updatedStatus,
        },
      }),
      invalidatesTags: (result, error, { serviceOrderId }) => [
        { type: 'ServiceOrder' as const, id: serviceOrderId },
      ],
    }),
    updateServiceOrderArchived: build.mutation<
      void,
      {
        serviceOrderId: string;
        isArchived: boolean;
      }
    >({
      query: ({ serviceOrderId, isArchived }) => ({
        url: `/service-orders/${serviceOrderId}/is-archived`,
        method: 'PATCH',
        body: {
          isArchived,
        },
      }),
      invalidatesTags: (result, error, { serviceOrderId }) => [
        { type: 'ServiceOrder' as const, id: serviceOrderId },
      ],
    }),
    deleteServiceOrder: build.mutation<
      { isDeleted: boolean; serviceOrderId: string },
      string
    >({
      query: (serviceOrderId) => ({
        url: `/service-orders/${serviceOrderId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, serviceOrderId) => [
        { type: 'ServiceOrder' as const, id: serviceOrderId },
      ],
    }),
  }),
});

export const {
  useGetServiceOrdersByUserIdQuery,
  useGetServiceOrderByServiceOrderIdQuery,
  useUpdateServiceOwnerInstructionsToCustomerMutation,
  useUpdateServiceOrderStatusMutation,
  useUpdateServiceOrderArchivedMutation,
  useDeleteServiceOrderMutation,
} = serviceOrdersExtendedApi;

export default serviceOrdersExtendedApi;
