import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'hooks/redux';
import { toast } from 'react-toastify';
import TimezoneSelect, { ITimezoneOption } from 'react-timezone-select';

import { selectLoggedInUserId } from 'store/features/user/userSlice';
import {
  useGetEditorByIdQuery,
  usePatchUpdateEditorFieldByIdMutation,
} from 'store/rtkqFeatures/api/editorExtendedApi';

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 1000,
  hideProgressBar: true,
};

const TimeZoneSelectSettings = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: loggedInUser } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  }); //  user from cache
  const userCurrentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [updateEditorTimezoneField, { isLoading, isError, data }] =
    usePatchUpdateEditorFieldByIdMutation();
  const timezoneSelectorValue =
    data?.value?.timeZone || loggedInUser?.timeZone || userCurrentTimeZone;

  const handleTimeZoneChange = async (timezone: ITimezoneOption) => {
    if (!loggedInUser) {
      toast.error('Unable to update timezone', TOAST_MESSAGE_SETTINGS);
      console.error(
        'Unable to update timezone because loggedInUser not found!!, loggedInUser: ',
        loggedInUser
      );
      return;
    }
    await updateEditorTimezoneField({
      editorId,
      timeZone: timezone.value,
    });
  };

  if (isError) {
    toast.error('Unable to update timezone', TOAST_MESSAGE_SETTINGS);
  }

  return (
    <>
      <Box
        sx={{
          pointerEvents: isLoading ? 'none' : 'auto',
          '& *': {
            fontSize: { xs: '1.2rem', md: '1.4rem' },
          },
        }}
      >
        <TimezoneSelect          
          className='timezon font-verdana'
          value={timezoneSelectorValue}
          onChange={handleTimeZoneChange}
        />
      </Box>
      {isError && (
        <Typography variant='body2' color='error.main'>
          Some error occured while updating timezone, Please try again later
        </Typography>
      )}
    </>
  );
};

export default TimeZoneSelectSettings;
