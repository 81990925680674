import { useFormikContext } from 'formik';
import { useLocation } from 'react-router-dom';

import Frame from './styled-components/Frame';
import Question from './reusable-components/Question';
import BoldSpan from 'components/utilities/BoldSpan';
import ChooseLanguages, { LanguageType } from 'components/utilities/ChooseLanguages/ChooseLanguages';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import { useEffect } from 'react';
import { Box } from '@mui/material';

declare global {
  interface Window {
    dataLayer: {
      push: (event: any) => void;
    };
  }
}

const SelectLanguage = () => {
  const { values, setValues } = useFormikContext<StepperState>();

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'screen_view',
      app_name: 'fronts.ai',
      screen_name: 'onboard_p1_lang',
    });
  }, []);

  const setChoosedLanguage = (value: LanguageType) => {
    setValues({
      ...values,
      language: value.language,
      languageDir: value.languageDir,
    });
  };

  return (
    <Box>
      <Question required currentStep={3} helperText='Choose the language the website will be displayed in'>
        <>
          Select a {' '}
          <BoldSpan>
            <>language</>
          </BoldSpan>
        </>
      </Question>
      <ChooseLanguages
        choosedLanguage={{
          language: values.language,
          languageDir: values.languageDir,
        }}
        setChoosedLanguage={setChoosedLanguage}
        name='language'
      />
    </Box>
  );
};

export default SelectLanguage;
