import portfolioServices from './portfolioServices';
import serviceServices from './serviceServices';
import sessionServices from './sessionServices';
import uploadImageService from './uploadImageService';
import userServices from './userServices';
import connectedAccountServices from './connectedAccountServices';
import guestSessionServices from 'lib/services/guestSessionServices';
import meetingsServices from 'lib/services/meetingsServices';
import sortedSPMIdsServices from './sortedSPMIdsServices';

type ApiServices = {
  portfolioServices: typeof portfolioServices;
  serviceServices: typeof serviceServices;
  meetingsServices: typeof meetingsServices;
  sessionServices: typeof sessionServices;
  guestSessionServices: typeof guestSessionServices;
  uploadImageService: typeof uploadImageService;
  userServices: typeof userServices;
  connectedAccountServices: typeof connectedAccountServices;
  sortedSPMIdsServices: typeof sortedSPMIdsServices;
};

const apiServices: ApiServices = {
  guestSessionServices,
  portfolioServices,
  serviceServices,
  meetingsServices,
  sessionServices,
  uploadImageService,
  userServices,
  connectedAccountServices,
  sortedSPMIdsServices,
};

export default apiServices;
