import { TimeSlot } from '../TentativeAvailabilityTypes';

export type GenericAction<Type, Payload> = {
  type: Type,
  payload: Payload,
}

export type TimeWeekdayState = {
  from: TimeSlot,
  to: TimeSlot,
  clickedWeekDays: (string | number)[],
  currentDay: string | number,
  isReleased: string,
}

type UpdateFrom = GenericAction<'updateFrom', TimeSlot>;
type UpdateTo = GenericAction<'updateTo', TimeSlot>;
type UpdateClickedWeekDays = GenericAction<'updateClickedWeekDays', string | number>;
type RemoveWeekDay = GenericAction<'removeWeekDay', string | number>;
type RemoveAllWeekDay = GenericAction<'removeAllWeekDay', []>;
type UpdateCurrentDay = GenericAction<'updateCurrentDay', string | number>;
type UpdateIsReleased = GenericAction<'updateIsReleased', string>;

export type TimeWeekdayActions = UpdateFrom | UpdateTo | UpdateClickedWeekDays | RemoveWeekDay | UpdateCurrentDay | UpdateIsReleased | RemoveAllWeekDay;

export const intialTimeWeekdayState: TimeWeekdayState = {
  from: { hour: 9, min: '00' },
  to: { hour: 10, min: '00' },
  clickedWeekDays: [],
  currentDay: '',
  isReleased: 'true',
}

function timeWeekdayReducer(state: TimeWeekdayState, action: TimeWeekdayActions): TimeWeekdayState {

  const { type, payload } = action;

  if (type === 'updateFrom') {
    return {
      ...state,
      from: payload,
    }
  } else if (type === 'updateTo') {
    return {
      ...state,
      to: payload,
    }
  } else if (type === 'updateClickedWeekDays') {
    if (!state.clickedWeekDays.includes(payload)) {
      state.clickedWeekDays.push(payload);
    }
    return {
      ...state,
    }
  } else if (type === 'removeWeekDay') {
    const arrayIndexToBeRemoved = state.clickedWeekDays.findIndex(each => each === payload);
    if (arrayIndexToBeRemoved > -1) {
      state.clickedWeekDays.splice(arrayIndexToBeRemoved, 1);
    } 
    return {
      ...state,
    }
  } else if (type === 'removeAllWeekDay') {
    return {
      ...state,
      clickedWeekDays: []
    }
  } else if (type === 'updateCurrentDay') {
    return {
      ...state,
      currentDay: payload,
    }
  } else if (type === 'updateIsReleased') {
    return {
      ...state,
      isReleased: payload,
    }
  } else {
    return state;
  }
}

export default timeWeekdayReducer;