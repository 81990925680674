import { ComponentType, lazy, LazyExoticComponent } from 'react';

import SignIn from 'views/Home/SignIn';
import Editor from 'views/editor/Editor';
import SelectNewStoreEntity from 'components/SelectNewStoreEntity/SelectNewStoreEntity';
import MagicEmailLoginLinkVerify from 'components/logins/MagicEmailLoginLinkVerify/MagicEmailLoginLinkVerify';
import SelectLoginAccount from 'components/logins/SelectLoginAccount/SelectLoginAccount';
import RedirectToSignIn from 'AppRouting/RedirectToSignIn';
import GuestSessionValidationContextProvider from 'views/guestSession/ValidateGuestSession/context/ValidationContextProvider';
import EditUserDetails from 'components/EditUserDetailsLatest/EditUserDetails';
import EditProfileSubtitle from 'components/ProfileCard/subcomponents/ProfileSubtitle/EditProfileSubtitle/EditProfileSubtitle';
import OnboardingFlow from 'views/OnboardingFlow/OnboardingFlow';
import PremiumThemeSubscriptionSuccessPage from 'views/SuccessCancelPages/premiumThemeSubscription/PremiumThemeSubscriptionSuccessPage';
import PrivacyPolicy from 'views/PrivacyPolicy';
import ServiceCheckout from 'views/ServiceCheckout/ServiceCheckout';
import BasicDetails from 'views/BasicDetails';
import CreateEditService from 'components/SPM_Forms/services/CreateEditService/CreateEditService';
import PageNotFound from 'components/Error/PageNotFound';
import Dashboard from 'views/Dashboard/Dashboard';
import ServiceCheckoutSuccessPage from 'views/ServiceCheckout/checkoutRedirectPages/ServiceCheckoutSuccessPage';
import EditAboutUs from 'views/editor/AboutUs/sub-components/EditAboutUs/EditAboutUs';
import SignUp from 'views/SignUp/SignUp';
import Oauth from 'views/Oauth';
import OtpConfirmation from 'views/SignUp/OtpConfirmation';

const DomainsSearchSuggestions = lazy(
  () => import('views/Domains/DomainsSearchSuggestions')
);
const DomainPurchase = lazy(() => import('views/Domains/DomainPurchase'));
const Calendar = lazy(() => import('views/Calendar/Calendar'));

const GoogleConsentSuccess = lazy(
  () => import('views/DashboardActions/GoogleConsentSuccess')
);

const ProposeNewTime = lazy(
  () => import('views/DashboardActions/ProposeNewTime/ProposeNewTime')
);
const SelectNewTime = lazy(
  () => import('views/DashboardActions/SelectNewTime/SelectNewTime')
);
const GuestSessionLogin = lazy(
  () => import('views/guestSession/GuestSessionLogin/GuestSessionLogin')
);
const SessionBookSuccess = lazy(
  () => import('views/session/SessionBookSuccess')
);
const SetSessionTime = lazy(() => import('views/SetSessionTime'));
const SearchBar = lazy(() => import('views/Search/Search'));
const Unsubscribe = lazy(() => import('views/Unsubscribe/Unsubscribe'));
const AdminPanel = lazy(() => import('views/AdminPanel/AdminPanel'));
const Domains = lazy(() => import('views/Domains/Domains'));
const DomainDetailsLazy = lazy(
  () => import('views/Domains/DomainDetails/DomainDetails')
);

export type AppRouteComponent =
  | ComponentType<any>
  | LazyExoticComponent<ComponentType<any>>;

export type AppRoute = {
  Component: AppRouteComponent;
  path: string;
  exact?: boolean;
  showHeader?: boolean;
  isProtected?: boolean;
};

const appRoutes: AppRoute[] = [
  {
    path: '/',
    Component: RedirectToSignIn,
  },
  {
    path: '/edit/profile-subtitle',
    Component: EditProfileSubtitle,
  },
  {
    path: '/select-login-account',
    Component: SelectLoginAccount,
    isProtected: true,
  },
  {
    path: '/sign-in',
    Component: SignIn,
    isProtected: false,
  },
  {
    path: '/sign-up',
    Component: SignUp,
    isProtected: false,
  },
  {
    path: '/sign-up/otp',
    Component: OtpConfirmation,
    isProtected: false,
  },
  {
    path: '/onboarding',
    Component: OnboardingFlow,
    isProtected: true,
  },
  {
    path: '/oauth',
    Component: Oauth,
    isProtected: false,
  },
  {
    path: '/oauth/facebook/callback',
    Component: Oauth,
    isProtected: false,
  },
  {
    path: '/login/email/verify',
    Component: MagicEmailLoginLinkVerify,
    isProtected: false,
  },
  {
    path: '/premium-theme-subscription/success',
    Component: PremiumThemeSubscriptionSuccessPage,
    isProtected: false,
  },
  {
    path: '/service/:serviceId/checkout/success',
    Component: ServiceCheckoutSuccessPage,
    isProtected: false,
  },
  {
    path: '/privacy',
    Component: PrivacyPolicy,
    isProtected: false,
  },
  {
    path: '/search',
    Component: SearchBar,
    isProtected: false,
    showHeader: false,
  },
  {
    path: '/service/:serviceId/checkout',
    Component: ServiceCheckout,
    isProtected: false,
  },
  {
    path: '/set-session-time/:id',
    Component: SetSessionTime,
    showHeader: true,
  },
  {
    path: '/guest-session/:id/login',
    Component: GuestSessionLogin,
    isProtected: false,
  },
  {
    path: '/guest-session/:id',
    Component: GuestSessionValidationContextProvider,
    isProtected: false,
  },
  {
    path: '/calendar/:id',
    Component: Calendar,
    isProtected: false,
  },
  {
    path: '/unsubscribe/:id',
    Component: Unsubscribe,
    isProtected: false,
  },
  {
    path: '/edit-user',
    Component: EditUserDetails,
  },
  {
    path: '/edit-aboutUs',
    Component: EditAboutUs,
  },
  // !! deprecated
  {
    path: '/basic-details',
    Component: BasicDetails,
    showHeader: true,
    isProtected: false,
  },
  {
    path: '/dashboard',
    Component: Dashboard,
    exact: false,
  },
  {
    path: '/domain-details/:domainName',
    Component: DomainDetailsLazy,
  },
  {
    path: '/propose-new-time/:id',
    Component: ProposeNewTime,
  },
  {
    path: '/guest-session/select-new-time/:sessionId',
    Component: SelectNewTime,
    isProtected: false,
  },
  {
    path: '/add-item',
    Component: SelectNewStoreEntity,
  },
  {
    path: '/create-service-v',
    Component: CreateEditService,
  },
  {
    path: '/edit-service-v',
    Component: CreateEditService,
  },
  {
    path: '/create-meeting-v',
    Component: lazy(() => import('components/SPM_Forms/meetings/CreateEditMeeting/CreateEditMeeting').then(module => ({
      default: (props: any) => module.default({ ...props, meetingType: 'virtual' })
    }))),
  },
  {
    path: '/create-meeting-p',
    Component: lazy(() => import('components/SPM_Forms/meetings/CreateEditMeeting/CreateEditMeeting').then(module => ({
      default: (props: any) => module.default({ ...props, meetingType: 'physical' })
    }))),
  },
  {
    path: '/edit-meeting-v',
    Component: lazy(() => import('components/SPM_Forms/meetings/CreateEditMeeting/CreateEditMeeting').then(module => ({
      default: (props: any) => module.default({ ...props, meetingType: 'virtual' })
    }))),
  },
  {
    path: '/edit-meeting-p',
    Component: lazy(() => import('components/SPM_Forms/meetings/CreateEditMeeting/CreateEditMeeting').then(module => ({
      default: (props: any) => module.default({ ...props, meetingType: 'physical' })
    }))),
  },
  {
    path: '/GoogleConsentSuccess',
    Component: GoogleConsentSuccess,
    isProtected: false,
  },
  {
    path: '/session-booking-success/:id',
    Component: SessionBookSuccess,
    isProtected: false,
  },
  {
    path: '/admin-panel',
    Component: AdminPanel,
    isProtected: true,
  },
  {
    path: '/domains',
    Component: Domains,
    isProtected: true,
    showHeader: true,
  },
  {
    path: '/domains/search',
    Component: DomainsSearchSuggestions,
    isProtected: false,
    // showHeader: true,
  },
  {
    path: '/domains/purchase',
    Component: DomainPurchase,
    isProtected: true,
    showHeader: true,
  },
  // {
  //   path: '/domains/purchase',
  //   Component: DomainPurchased,
  //   isProtected: true,
  // },
  // NOTE - Priority is important here. This should be the last route
  {
    path: '/:url?',
    Component: Editor,
    showHeader: true,
  },
  {
    path: '/error',
    Component: PageNotFound,
    isProtected: false,
  },
  {
    path: '*',
    Component: PageNotFound,
    isProtected: false,
  },
];

export default appRoutes;

// Don't use these until discussed
// const workPausedRoutes = [
//   {
//     path: '/ai/:editorUrl/reset-password',
//     Component: ResetEmailLoginPassword,
//   },
// ];
