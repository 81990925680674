import { Box, Stack, Typography } from '@mui/material';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import LayoutContainer from 'components/Containers/LayoutContainer';
import CallToActionBtn from './reusable-components/CallToActionBtn';

import { style_ProfileCard_bgWave } from 'components/ProfileCard/ProfileCard.style';
import LayoutWrapper from './reusable-components/LayoutWrapper';

const Layout3 = () => {
  const {
    userImage,

    currentTheme,
    tagsArray,

    userName,
    userTitle,
    user,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,
    userBgImage,

    buttonStyle,
    bg,
    bgImage,

    isMeetingAndServiceAvailable,
    storeHeading,

    isNeedDiv,
    isMobile,
    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,
    colorCode,

    displayTags,
    displaySocialLinks,

    userNameColor,
    titleColor,
  } = useProfileCard();

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box position='relative' component='section' sx={isNeedDiv ? {} : bg}>
      {isNeedDiv && !userBgImage && (
        <Box
          className='bg-wave'
          sx={{ ...style_ProfileCard_bgWave(bg), zIndex: -1 }}
        ></Box>
      )}
      <LayoutWrapper
        sx={{
          ...bgImage,          
        }}
      >
        <LayoutContainer>
          <ProfileEditAndDesignBtn />
          <Stack
            sx={{
              maxWidth: '90ch',
              p: { xs: 2, md: 4 },
              justifyContent: 'center',
              alignItems: 'center',
              m: 'auto',
              textAlign: 'center',
              backdropFilter: 'blur(10px)',
              borderRadius: currentTheme?.store.item.borderRadius,
              boxShadow: currentTheme?.store.item.boxShadow,
              border: currentTheme?.store.item.border,
            }}
            useFlexGap
            spacing={2}
          >
            <Typography variant='h1' fontSize={{ xs: 30, md: 50 }} color={currentTheme?.profile.title.color}>
              {userName}
            </Typography>
            <Typography variant='h5' color={currentTheme?.profile.subtitle.color}>
              {userTitle}
            </Typography>

            {displaySocialLinks !== false && (
              <Box display='inline-block'>
                <SocialIconsList
                  emailPublic={emailPublic}
                  email={email}
                  socialLinks={socialLinks}
                  whatsapp={whatsapp}
                  address={address}
                  phone={phone}
                  styling={{ justifyContent: 'center', display: 'inline-flex' }}
                  showOnlyIcon
                />
              </Box>
            )}

            {displayTags !== false && (
              <Box>
                <Tags
                  tagsArray={tagsArray}
                  tag={tag}
                  global={global}
                  sx={{ justifyContent: 'center', display: 'inline-flex' }}
                />
              </Box>
            )}
            {isMeetingAndServiceAvailable && storeHeading && (
              <CallToActionBtn
                user={user}
                buttonStyle={buttonStyle}
                storeHeading={storeHeading}
              />
            )}
          </Stack>
        </LayoutContainer>
      </LayoutWrapper>

      {/* sub title */}
      <ProfileSubtitle
        subTitle={user.subTitle}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout3;
