import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const Or = () => {
  const lineStyling: SxProps<Theme> = {
    flex: '1 1 auto',
    border: `2px solid ${grey[400]}`,
    borderRadius: '5px',
  };

  return (
    <Stack
      spacing={2.6}
      flexWrap='nowrap'
      direction='row'
      justifyContent='center'
      alignItems='center'
      flex={1}
    >
      <Box sx={lineStyling} />
      <Typography variant='body1'>Or continue with</Typography>
      <Box sx={lineStyling} />
    </Stack>
  );
};

export default Or;
