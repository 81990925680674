import { SPM_UI } from 'types/utility';

export enum ServiceTypes {
  Virtual = 'SERVICE-V',
  Physical = 'SERVICE-P',
}

export enum PhysicalServiceOptedOptions {
  Guest_Service_Location = 'GUEST-SERVICE-LOCATION',
  Host_Service_Location = 'HOST-SERVICE-LOCATION',
  Host_Service_Location_All = 'HOST-SERVICE-LOCATION-ALL',
}

type ServicePhysicalLocation = {
  optedOption: PhysicalServiceOptedOptions;
  permanentLocation?: string;
};

type ServiceLocation<ServiceType> = ServiceType extends ServiceTypes.Physical
  ? ServicePhysicalLocation
  : never;

export type PhysicalOrVirtualServiceList = PhysicalOrVirtualService[];
export type VirtualService = Service<ServiceTypes.Virtual>;
export type PhysicalService = Service<ServiceTypes.Physical>;

export type PhysicalOrVirtualService = VirtualService | PhysicalService;

export type NewService<ServiceType extends ServiceTypes> = Omit<
  Service<ServiceType>,
  '_id'
>; // type while creating new meetings
export type NewVirtualService = NewService<ServiceTypes.Virtual>;
export type NewPhysicalService = NewService<ServiceTypes.Physical>;
export type PhysicalOrVirtualNewService = NewVirtualService | NewPhysicalService;

export type Service<ServiceType extends ServiceTypes> = {
  readonly _id: string;
  readonly userId: string;
  isDeleted: boolean;
  type: ServiceType;
  status: 'active' | 'inactive';
  title: string;
  desc: string;
  isPaid: boolean;
  price?: number;
  included: string[];
  img: string;
  ui: SPM_UI;
  location?: ServiceLocation<ServiceType>; // NOTE: "location is same as hostDetails as in meeting"
};

// !! don't use below types (being )
// # Service Module Service Mutation Types - Add
export type AddPhysicalOrVirtualService = Omit<PhysicalOrVirtualService, '_id'>;

// # Service Module Service Mutation Types - Update
export type ToBeUpdatedServiceDetails = Pick<
  PhysicalOrVirtualService,
  '_id' | 'userId' | 'isDeleted'
>;

export type CanBeUpdatedServiceContext = Omit<
  PhysicalOrVirtualService,
  '_id' | 'userId' | 'isDeleted'
>;
