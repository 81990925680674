import React from 'react';
import { useUpdatedEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import EditPageWithRichText from 'components/utilities/EditPageWithRichText/EditPageWithRichText';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const EditAboutUs = () => {
  const { cachedLoggedInUser: editor, userIdInReduxUserSlice: editorId } =
    useGetLoggedInUser();
  const [updateEditor, { isLoading: isAboutContentUpdateInProgress }] =
    useUpdatedEditorByIdMutation();
  const editPageHeading = `Edit ${editor?.ui.aboutHeading ?? 'About'}`;
  
  const handleSavingForm = React.useCallback(
    async (updatedAboutContent: string) => {
      try {
        updateEditor({
          editorId: editorId,
          loggedInUser: {
            about: updatedAboutContent,
          },
        });
      } catch (error) {
        throw error;
      }
    },
    [updateEditor, editorId]
  );

  return (
    <EditPageWithRichText
      allowSavingForm={!isAboutContentUpdateInProgress}
      disableCancelBtn={false}
      disableUpdateBtn={isAboutContentUpdateInProgress}
      editorCurrentContent={editor?.about ?? ''}
      handleSavingForm={handleSavingForm}
      pageHeading={editPageHeading}
    />
  );
};

export default EditAboutUs;
