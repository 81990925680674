import { useEffect, useState } from 'react';
import useBooleanStateController from 'hooks/useBooleanStateController';
import useViewerURL from 'hooks/useViewerURL';
import useClipbord from 'hooks/useClipbord';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import { initialThemeState,  } from 'themes/AppThemeProvider/themeType';
import { useAppSelector } from 'hooks/redux';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';
import { initialUserData } from 'store/features/user/userModuleSliceInitialization';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';

const useProfileStyle = () => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: editor } = useGetEditorByIdQuery(editorId, {
    skip: !editorId,
  }); //  user from cache
  const user = editor ?? initialUserData;
  const { setClipboard } = useClipbord();
  const [showClipboardPopup, setShowClipboardPopup] = useState(false);
  const [showIconPopup, showIconPopupController] = useBooleanStateController();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const {currentTheme} = useGetThemesQuery(
    undefined,
    {
      selectFromResult: ({ data: themes }) => ({
        currentTheme: themes?.[localCurrentThemeIndex] || initialThemeState,
      }),
    }
  );
  const userAuthType = user.authType;
  const VIEWER_URL = useViewerURL(user.url);
  const userName = user.name || '';
  const userImage: string = user.img;
  const userTitle = user.title || '';
  const userEmail = user.emailPublic ? user.email: null;

  const tagsArray = user.tags;
  const tagsArrayLength = tagsArray.length;

  const userProfileLink = user?.profileLink ?? '';
  const isAuthTagVisible = user.isAuthTagVisible && user.authType === 'fb';

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (showClipboardPopup) {
      timerId = setTimeout(() => {
        setShowClipboardPopup(false);
      }, 800);
    }
    
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [showClipboardPopup]);

  const closeIconPopup = () => {
    showIconPopupController.setFalse();
  };

  const handleClipboardIconClick = () => {
    setClipboard(
      VIEWER_URL,
      () => setShowClipboardPopup(true),
      (err) => console.error(`error while copying clipboard text: ${err}`)
    );
  };

  const handleCopyClick = () => {
    if(!userEmail) return;
    setClipboard(userEmail);
  };

  const userMetaData = {
    url: `fronts.ai/${user.url}`,
    email: userEmail,
    phone: user.phone,
    address: user.address,
  };

  return {
    currentTheme,
    userImage,
    userEmail,

    tagsArray,
    tagsArrayLength,

    userMetaData,
    handleClipboardIconClick,
    handleCopyClick,
    closeIconPopup,
    showIconPopup,
    userAuthType,
    userName,
    userTitle,    
    userProfileLink,
    isAuthTagVisible,
    showIconPopupController,
    user,
    VIEWER_URL,
  };
};

export default useProfileStyle;
