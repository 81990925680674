import { Box } from '@mui/material';
import { Formik } from 'formik';

import { LoadingStatus } from 'hooks/useAsyncStatus';
import useOnboardingFormSubmission from 'views/OnboardingFlow/hooks/useOnboardingFormSubmission';
import { LoggedInUser, SocialLinkType } from 'types/user';
import aiWebBuilderFormValidationSchema from '../utils/validationSchema';
import { useEffect, useState } from 'react';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import OnboardingFlowStepper from '../sub-components/OnboardingFlowStepper/OnboardingFlowStepper';
import { WebsiteFontFamily } from 'store/features/websiteFontFamily/websiteFontFamilySlice';
import {toast} from 'react-toastify';
import routeConstants from 'constants/routeConstants';

export const INITIAL_PROFILEE_IMG_URL =
  'https://res.cloudinary.com/salesfronts/image/upload/v1678930882/with-bg_fieg5p.jpg';

export enum ProfileTypeValues {
  MALE = 'male',
  FEMALE = 'female',
  COMPANY = 'company',
}

export type StepperState = {
  id: string | undefined;
  websiteUrl: string;
  ownWebsite: boolean | undefined;
  url: string;
  name: string;
  profileType?: ProfileTypeValues | '';
  occupation: string;
  provideInPersonService: boolean | undefined;
  address: string;
  img: string;
  email: string;
  emailPublic: boolean;
  isExitingUserAfterSubmitError: boolean;
  step: number;
  submitStatus: LoadingStatus;
  errorMsg: string;
  googleRecaptchaToken: string;
  place_id: string;
  generateWebsiteThroughResume: boolean;
  generateWebsiteThroughDescription: boolean;
  themeId: number;
  fontFamily: string;
  language: string;
  languageDir: string;
  userGMTOffset: number; // in hours
  country?: string;
  state?: string;
  city?: string;
  userBgImage?: string;
  socialLinks?: SocialLinkType[];
  profileImgFile?: File;
  userBgImageFile?: File;  
  slideDirection?: 'up' | 'down' | 'left' | 'right';
  isNextLoading?: boolean;
  pendingUrl?: string;
} & Pick<
  LoggedInUser,
  'profileStyle' | 'isPremium' | 'addressComponents' | 'timeZone' | 'authType' | 'id' | 'pendingDomain' | 'whatsapp' | 'phone'
>;

export const initialStepper: StepperState = {
  id: undefined,
  authType: 'ee',
  ownWebsite: undefined,
  websiteUrl: '',
  provideInPersonService: undefined,
  address: '',
  place_id: '',
  phone: '',
  emailPublic: false, // default to false
  step: 1,
  slideDirection: 'left',
  isExitingUserAfterSubmitError: false,
  submitStatus: LoadingStatus.IDLE,
  errorMsg: '',
  googleRecaptchaToken: '',
  generateWebsiteThroughResume: false,
  generateWebsiteThroughDescription: false,
  themeId: 0,
  fontFamily: WebsiteFontFamily.POPPINS,
  language: 'English',
  languageDir: 'ltr',
  userGMTOffset: 0,
  country: '',
  state: '',
  city: '',
  isPremium: false,
  profileStyle: {
    layout: 'Layout6',
  },
  timeZone: '',
  name: '',
  profileType: ProfileTypeValues.COMPANY,
  occupation: '',
  email: '',
  url: '',
  img: '',
};

const OnboardingFlowForm = () => {
  const { handleFormSubmit } = useOnboardingFormSubmission();
  const { isFetching, isLoading, cachedLoggedInUser } = useGetLoggedInUser();
  const [initialStepperState, setInitialStepperState] = useState(initialStepper);  
  useEffect(() => {    
    if(isFetching || isLoading) return;
    if(!cachedLoggedInUser) {
      toast.error('You are not logged in, redirecting to home');
      return;
    };
    // if user has already url then send him to editory
    if(cachedLoggedInUser.url) {
      toast.error('You already have a website, redirecting to editor');
      window.location.href = `${routeConstants.EDITOR_ROUTE}/${cachedLoggedInUser.url}`;
    }
    let {_id, ...rest} = cachedLoggedInUser;
    setInitialStepperState((prev) => ({
      ...prev,
      ...rest,
    }));
  }, [cachedLoggedInUser, isFetching, isLoading]);

  return (    
    <Formik
      enableReinitialize // necessary for initial values
      initialValues={initialStepperState}
      onSubmit={handleFormSubmit}
      validationSchema={aiWebBuilderFormValidationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      validateOnMount={true}
    >
      <Box component='form' onSubmit={(e) => e.preventDefault()}>
        <OnboardingFlowStepper />
      </Box>
    </Formik>
  );
};

export default OnboardingFlowForm;
