import { ButtonGroup, SxProps } from '@mui/material';

import EditBtn from 'components/utilities/edit-btn/EditBtn';
import ProfileDrawer from './ProfileDrawer/ProfileDrawer';
import LayoutContainer from 'components/Containers/LayoutContainer';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const ProfileEditAndDesignBtn = ({ sx }: { sx?: SxProps }) => {
  const {cachedLoggedInUser} = useGetLoggedInUser();
  const isRtl = cachedLoggedInUser?.languageDir === 'rtl';
  return (
    <LayoutContainer
      sx={{
        width: '100%',
        ...sx,
        position: 'absolute',
        top: 80,
        right: isRtl ? 0 : '10px',
        left: isRtl ? '10px' : 0,
        direction:  'ltr',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: 2,
        zIndex: 3,
      }}
    >
      <ButtonGroup>
        <ProfileDrawer />
        <EditBtn stylings={{}} to='/edit-user' />
      </ButtonGroup>
    </LayoutContainer>
  );
};

export default ProfileEditAndDesignBtn;
