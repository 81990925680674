import { NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { SxProps, Theme, Typography, useMediaQuery } from '@mui/material';

import ResponsiveButton from 'components/Buttons/ResponsiveButton';

type EditBtnProps = {
  to: any;
  stylings: SxProps<Theme>;
  classname?: string;
};

const EditBtn = ({ to, stylings }: EditBtnProps) => {
  const isScreenSizeLessThan601Px = useMediaQuery('(max-width: 601px)');

  return (
    <Typography component='span' zIndex={1000} sx={stylings}>
      <NavLink to={to} style={{ textDecoration: 'none', color: 'inherit' }}>
        <ResponsiveButton
          sx={{boxShadow: 0, '&:hover': { boxShadow: 0 }}}
          variant='contained'
          color='primary'          
          endIcon={
            <EditIcon
              width={isScreenSizeLessThan601Px ? 30 : 40}
              height={isScreenSizeLessThan601Px ? 30 : 40}
            />
          }
        >
          Edit
        </ResponsiveButton >
      </NavLink>
    </Typography>
  );
};

export default EditBtn;
