import { Box, ButtonGroup, Stack, SxProps } from '@mui/material';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import PortfolioDrawer from './PortfolioDrawer';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';
import Popup from 'components/utilities/Popups/Popup';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import EditPortfolio from './edit-portfolio/EditPortfolio';
import { usePortfolioContext } from '../PortfolioContext';

const PortfolioAddAndDesignBtns = ({ sx }: { sx?: SxProps }) => {
  return (
    <Stack
      justifyContent={'flex-end'}
      direction='row'
      sx={{ width: '100%', mb: 2, mt: 2, ...sx }}
      gap={2}
    >
      <ButtonGroup sx={{ zIndex: 1 }}>
        <PortfolioDrawer />
        <AddPortfoliobtn />
      </ButtonGroup>
    </Stack>
  );
};

export default PortfolioAddAndDesignBtns;

function AddPortfoliobtn({
  sx,  
}: {
  sx?: SxProps;  
}) {
  const context = usePortfolioContext();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { portfolioSylings } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      portfolioSylings: themes
        ? themes[localCurrentThemeIndex].portfolios
        : initialThemeState.portfolios,
    }),
  });  

  function handleAddPortfolio() {
    context.setOpenEditDialog(true);
    context.setPortfolioIndex(-2);
  }

  const handleClose = () => {
    context.setPortfolioIndex(-1);
    context.setOpenEditDialog(false);
  }
  
  return (
    <Box sx={sx}>
      <ResponsiveButton
        variant='contained'
        color='primary'
        onClick={(e) => {
          e.preventDefault();
          handleAddPortfolio();
        }}
        endIcon={<AddCircleOutlineOutlinedIcon />}
      >
        Add
      </ResponsiveButton>

      <Popup
        onClose={handleClose}
        dialogProps={{
          fullWidth: false,
          open: context.portfolioIndex === -2,
          PaperProps: {
            className: 'edit-portfolio-dialog',
            sx: {
              background: portfolioSylings.background,
              backgroundSize: portfolioSylings.backgroundSize,
              backgroundBlendMode: portfolioSylings.backgroundBlendMode ?? 'normal',
              overflow: 'auto',
              margin: 2,
              mx: 'auto',
              maxHeight: '100%',
            },
          },
        }}
        setOpen={() => {}}
      >        
        <EditPortfolio    
          mode='add'
          widthOfParent={340} // TODO - get the width of the parent
          portfolioIndex={-2}
          portfolioSylings={portfolioSylings}
        />
      </Popup>
    </Box>
  );
}
