export function removeAnySpaceFromString(sstring: string): string {
  return sstring.replace(/\s+/g, '').trim();
}

export function isStringEmpty(string: string) : boolean {
  if(string && string.length > 0 && string.trim().length > 0) {
    return true;
  };
  return false;
}

export function isStringNonEnglish(string: string) : boolean {
  const regex = /^[a-zA-Z0-9_ ]*$/;
  
  return regex.test(string);
}