import React, { useEffect, useCallback, useState } from 'react';
import { Box, FormHelperText, IconButton, InputLabel, SxProps, Theme } from '@mui/material';
import debounce from 'lodash/debounce';
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import { alpha, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { inputBgColor } from 'themes/AppThemeProvider/light';

import 'react-phone-number-input/style.css';
import StyledItemLabel from 'components/shared/StyledItemLabel';

type Tagged<A, T> = A & { __tag?: T };
type E164Number = Tagged<string, 'E164Number'>;

export type Country = {
  code: string;
  phone: string;
};

type CountrySelectCustomProps = {
  value: string;
  onChange: (num: number | null, countryCode: number | null, numWithCountry?: string) => void;
  hideLabel?: boolean;
  inputProps?: any;
  customCss?: SxProps<Theme>;
  closeBtnSx?: SxProps<Theme>;
  borderColor?: string;
  textColor?: string;
};

const customTextFieldPadding = {
  xs: '0.8rem 1rem',
  sm: '0.9rem 1rem',
  md: '1rem 1.25rem',
};

const CountrySelectCustom: React.FC<CountrySelectCustomProps> = ({
  value,
  onChange,
  hideLabel = false,
  inputProps,
  customCss = {},
  closeBtnSx = {},
  borderColor,
  textColor,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<E164Number>();
  const [isValid, setIsValid] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();
  const transparentPrimaryLight = alpha(theme.palette.primary.light, 0.5);

  const parsedNumber = useCallback((num: E164Number) => {
    if (num && isValidPhoneNumber(num)) {
      const parsedNumber = parsePhoneNumber(num);
      if (parsedNumber) {
        const numOnly = parsedNumber.nationalNumber as unknown as number;
        const countryCode = +parsedNumber.countryCallingCode;
        onChange(numOnly, countryCode, num.toString());
        setIsValid(true);
      }
    } else {
      setIsValid(false);
    }
  }, [onChange]);

  useEffect(() => {
    setPhoneNumber(value);
  }, [value]);

  const debounceChange = useCallback(debounce(parsedNumber, 1000), [parsedNumber]);

  const handlePhoneInputChange = (num: string) => {
    setPhoneNumber(num);
    debounceChange(num);
  };

  const handleClearInputBtnClick = () => {
    setPhoneNumber('');
    onChange(null, null);
    setIsValid(true);
  };

  return (
    <Box
      sx={{
        '& .PhoneInput': {
          width: '100%',
          background: `${inputBgColor} !important`,
          borderRadius: '4px',
          border: `1px solid ${borderColor || '#cbd6e2'}`,
          transition: 'all 0.3s',
          display: 'flex',
          alignItems: 'center',
          '& .PhoneInputInput': {
            color: textColor ? `${textColor} !important` : 'text.primary',
            fontWeight: 600,
            fontSize: '1.6rem',
            padding: { xs: customTextFieldPadding.xs, md: customTextFieldPadding.md },
            paddingLeft: '10px', // Add left padding to the input
            border: 'none',
            outline: 'none',
            fontFamily: 'inherit',
            backgroundColor: 'transparent',
            flexGrow: 1,
          },
          '& .PhoneInputCountry': {
            marginRight: '10px',
            marginLeft: '10px', // Add left margin to the country selector
          },
        },
        '& .PhoneInput:hover': {
          borderColor: 'text.primary',
        },
        '& .PhoneInput:focus-within': {
          borderColor: theme.palette.primary.main,
          borderWidth: '2px',
          boxShadow: `0 0 4px 2px ${transparentPrimaryLight}, 0 0 0 1px ${transparentPrimaryLight}`,
        },
        '& input::placeholder': {
          fontSize: '1.6rem !important',
          color: '#7d98b6',
          opacity: 1,
        },
        ...customCss,
      }}
    >
      {!hideLabel && (
        <StyledItemLabel label="WhatsApp Number" />
      )}
      <Box position="relative">
        <PhoneInput
          smartCaret={true}
          {...inputProps}
          invalid={isValid ? undefined : 'invalid'}
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handlePhoneInputChange}
          international
          defaultCountry="CA"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <IconButton
          onClick={handleClearInputBtnClick}
          edge="end"
          sx={{
            position: 'absolute',
            right: '10px', // Adjust right position
            top: '50%',
            transform: 'translateY(-50%)',
            ...closeBtnSx,
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Box>
      {!isValid && (
        <FormHelperText error sx={{ fontSize: '1.6rem', mt: 1 }}>
          Invalid phone number
        </FormHelperText>
      )}
    </Box>
  );
};

export default CountrySelectCustom;