import { Box, Stack, Typography } from '@mui/material';

import useProfileCard from 'components/ProfileCard/hooks/useProfileCard';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';
import ProfileEditAndDesignBtn from '../ProfileEditAndDesignBtn';
import ProfileSubtitle from '../ProfileSubtitle/ProfileSubtitle';
import SocialIconsList from 'components/utilities/SocialIconsList';
import Tags from '../Tags';
import LayoutContainer from 'components/Containers/LayoutContainer';
import CallToActionBtn from './reusable-components/CallToActionBtn';
import LayoutWrapper from './reusable-components/LayoutWrapper';
import LayoutProfileImg from './reusable-components/LayoutProfileImg';

const Layout4 = () => {
  const {
    userImage,
    userBgImage,

    currentTheme,
    tagsArray,

    userName,
    userTitle,
    user,

    email,
    emailPublic,
    whatsapp,
    socialLinks,
    address,
    phone,

    buttonStyle,
    bg,

    isMeetingAndServiceAvailable,
    storeHeading,

    paddingBottom,
    paddingTop,
    subtitle,
    tag,
    global,
    bgImage,
    displayTags,
    displaySocialLinks,

    userNameColor,
    titleColor,
  } = useProfileCard();

  if (!currentTheme) return <FrontsLoader />;

  return (
    <Box component='section'>
      <Box sx={bg}>
        <LayoutWrapper sx={bgImage}>
          <LayoutContainer>
            <ProfileEditAndDesignBtn />
            <Stack
              sx={{
                maxWidth: '90ch',
                justifyContent: 'center',
                alignItems: 'center',
                m: 'auto',
                textAlign: 'center',
              }}
              useFlexGap
              spacing={2}
            >
              <Typography
                variant='h1'
                fontSize={{ xs: 30, md: 50 }}
                color={userNameColor}
              >
                {userName}
              </Typography>
              <Typography variant='h5' color={titleColor}>
                {userTitle}
              </Typography>

              <LayoutProfileImg />

              {displaySocialLinks !== false && (
                <Box display='inline-block'>
                  <SocialIconsList
                    emailPublic={emailPublic}
                    email={email}
                    socialLinks={socialLinks}
                    whatsapp={whatsapp}
                    address={address}
                    phone={phone}
                    styling={{ justifyContent: 'center', display: 'inline-flex' }}
                    showOnlyIcon
                  />
                </Box>
              )}

              {displayTags !== false && (
                <Box>
                  <Tags
                    tagsArray={tagsArray}
                    tag={tag}
                    global={global}
                    sx={{ justifyContent: 'center', display: 'inline-flex' }}
                  />
                </Box>
              )}
              {isMeetingAndServiceAvailable && storeHeading && (
                <Stack justifyContent={'center'} direction='row'>
                  <CallToActionBtn
                    user={user}
                    buttonStyle={{...buttonStyle, mb: 3}}
                    storeHeading={storeHeading}
                  />
                </Stack>
              )}
            </Stack>
          </LayoutContainer>
        </LayoutWrapper>
      </Box>

      {/* sub title */}
      <ProfileSubtitle
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
        subTitle={user.subTitle}
        subTitleStyle={{ ...subtitle, pt: userBgImage ? 2 : 0 }}
      />
    </Box>
  );
};

export default Layout4;
