import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, ListItemIcon, SxProps, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import WebIcon from '@mui/icons-material/Web';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PaidIcon from '@mui/icons-material/Paid';
import HttpIcon from '@mui/icons-material/Http';

import frontsPng from 'assets/icons/fronts-ai-icon.png'

import {
  DashboardMenu,
  select_dashboard_user_is_sidebar_minified,
  set_sidebar_minified,
} from 'store/features/dashboard/dashboardSlice';
import { dashboard_icon_size } from '../../Dashboard';

import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

interface IMenuItems {
  icon: React.ReactNode;
  text: string;
  id: DashboardMenu;
}

const PrimarySidebarListItems = () => {
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const dispatch = useAppDispatch();

  const menuItems: IMenuItems[] = [
    { icon: <ArrowBackIcon sx={dashboard_icon_size} />, text: 'Back to Editor', id: 'editor' },
    { icon: <WebIcon sx={dashboard_icon_size} />, text: 'Sites', id: 'sites' },
    { icon: <CalendarMonthIcon sx={dashboard_icon_size} />, text: 'Meetings', id: 'meetings' },
    { icon: <ShoppingCartIcon sx={dashboard_icon_size} />, text: 'Orders', id: 'orders' },
    // {
    //   icon: <Avatar src={frontsPng} color='primary' sx={{ ...dashboard_icon_size, borderRadius: 'none', overflow: 'visible' }} imgProps={{sx: {objectFit: 'contain'}}} />,
    //   text: 'Subscription',
    //   id: 'subscription',
    // },
    {
      icon: <PaidIcon sx={dashboard_icon_size} />,
      text: 'Payment Collection',
      id: 'payment-collection',
    },
    {      
      icon: <HttpIcon sx={dashboard_icon_size} />,
      text: 'Domain',
      id: 'domain',
    },
  ];

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: DashboardMenu) => {        
    dispatch(set_sidebar_minified(true));
  };

  return (
    <>
      {menuItems.map(({ icon, text, id }, index) => {
        if (id === 'editor') {
          return (
            <DashboardListItem
              key={index}
              icon={icon}
              link={`/${cachedLoggedInUser?.url}`}
              text={text}
              id={id}
              handleClick={handleClick}
            />
          );
        }
        return (
          <DashboardListItem
            key={index}
            icon={icon}
            link={`/dashboard/${id}`}
            text={text}
            id={id}
            handleClick={handleClick}
          />
        );
      })}
    </>
  );
};
export default PrimarySidebarListItems;

interface IDashboardListItem {
  icon: React.ReactNode;
  text: string;
  link: string;
  id?: DashboardMenu;
  sx?: SxProps;
  handleClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, id: DashboardMenu) => void;
}

export const DashboardListItem = ({
  icon,
  text,
  id,
  handleClick,
  sx,
  link,
}: IDashboardListItem) => {
  const loc = useLocation();
  const subpath = loc.pathname.split('/').pop();

  return (
    <Link
      to={link}
      onClick={(e) => handleClick && handleClick(e, id || 'sites')}
      style={{ textDecoration: 'none', width: '100%' }}
    >
      <ListItemButton sx={sx} selected={id ? id.toLowerCase() === subpath?.toLowerCase() : false}>
        <DashboardListIcon sx={{ color: 'text.primary' }}>{icon}</DashboardListIcon>
        <ListItemText
          primaryTypographyProps={{
            sx: {
              color: 'text.primary',
              fontSize: { xs: '1.4rem', md: '1.4rem' },
            },
          }}
          primary={text}
        />
      </ListItemButton>
    </Link>
  );
};

export const DashboardListIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: useAppSelector(select_dashboard_user_is_sidebar_minified)
    ? theme.spacing(7)
    : theme.spacing(4),
}));
