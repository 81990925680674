import React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { useFormikContext } from 'formik';
import { Box, Container, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
  StepperState,  
} from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import { stepToFieldMap } from '../../OnboardingFlowStepper/StepperIncDecrementBtns';

export const FINAL_STEP_AI = 4;

type ThisProps = {
  children: React.ReactNode;
  autoFormTouch?: boolean;
  containerSx?: SxProps;
  title?: React.ReactNode;
  imgSrc?: string;
};

export const stepperPadding: SxProps = { px: { xs: 0, md: 12.5 } };

const Frame = ({
  children,
  autoFormTouch = true,
  title,
  containerSx,
  imgSrc,
}: ThisProps) => {
  const { setFieldTouched, values, touched } = useFormikContext<StepperState>();
  const fields = stepToFieldMap[values.step];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffectOnce(() => {
    fields?.forEach((currentFieldName) => {      
      if (!touched[currentFieldName] && autoFormTouch) {
        setFieldTouched(currentFieldName, true, true);
      }
    });
  });

  return (
    <>
      <Container sx={{ maxWidth: 'none !important', ...containerSx }}>
        <Box
          sx={{
            height: {xs: 'fit-content', md: '100%'},
            width: '50%',
            position: {xs: 'absolute', md: 'fixed' },
            top: 0,
            right: {xs: '50%', md: 0},
            zIndex: 1,
            transform: {xs: 'translateX(50%)', md: 'none'},
            ...stepperPadding,
            pt: { xs: 4, md: 12.5 },
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              component={'img'}
              sx={{
                filter: 'drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5))',
                maxWidth: {xs: 70, md: 400},
                maxHeight: {xs: 70, md: 400},
                objectFit: 'contain',
              }}
              src={
                imgSrc ||
                'https://plus.unsplash.com/premium_photo-1661962352925-9c7c81c9c637?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
            gap: '1rem',
            pt: {xs: 12.5, md: 0}
          }}
        >
          <Box
            className='onboarding-content'
            sx={{ ...stepperPadding, pt: { xs: 3.75, md: 12.5 }, pb: 12.5 }}
          >
            {title && <Box>{title}</Box>}
            <Box className='main-content-container'>{children}</Box>
          </Box>
          {!isMobile && (
            <Box
              sx={{
                maxHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box sx={{ maxWidth: {xs: 100, md: 400} }}>
                <Typography
                  component={'img'}
                  sx={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                  src={''}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

export default Frame;
