import ErrorBoundary from 'components/ErrorBoundary';
import AppRouting from 'AppRouting/AppRouting';
import AppThemeProvider from 'themes/AppThemeProvider/AppThemeProvider';

function App() {
  return (
    <ErrorBoundary>
      <AppThemeProvider mode='light'>
        <AppRouting />
      </AppThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
