import { EmailSignInFormState } from 'components/logins/EmailSignIn/EmailSignIn';
import { sucessfulLoginInit } from 'components/logins/onLoginSuccessUtilities';
import routeConstants from 'constants/routeConstants';
import { useAppDispatch } from 'hooks/redux';
import useAsyncStatus from 'hooks/useAsyncStatus';
import serverApi from 'lib/apiConfig';
import {toast} from 'react-toastify';
import { setLoggedInUser } from 'store/features/user/userSlice';
import { LoggedInUser } from 'types/user';
import errorHandlingUtilities from 'utilities/errorHandlingUtilities';
import loginTokenUtils from 'utilities/loginTokenUtils';

const useHandleEmailSignIn = () => {
  const dispatch = useAppDispatch();
  const {
    // status: sendEmailLoadingStatus,
    isLoading: isEmailSendStatusLoading,
    isSuccess: isEmailSendStatusSuccess,
    isError: isEmailSendStatusError,
    error: emailSendStatusErrorMsg,
    updateStatus: updateEmailSendStatus,
    Actions: EmailSendStatusActions,
  } = useAsyncStatus();

  const resetEmailSendStatus = () => {
    updateEmailSendStatus({
      type: EmailSendStatusActions.RESET,
    });
  };

  const handleFormSubmit = async (values: EmailSignInFormState) => {
    // console.log('Form submitted | Values: ', values);
    const email = values.email;
    try {
      updateEmailSendStatus({
        type: EmailSendStatusActions.LOADING,
      });
      const response = await sendMagicLoginLinkToEmail({
        email,
      });
      if (!response.isEmailSent) {
        if (response.onboarding && response.loginToken && response.user) {
          loginTokenUtils.setLocalStorageLoginToken(response.loginToken);
          dispatch(setLoggedInUser(response.user));
          await sucessfulLoginInit({ editorId: response.user._id.toString() });
          toast.success("Redirecting to onboarding");
          window.location.replace(`${routeConstants.ONBOARDING_ROUTE}?fromSignIn=true`);
        }
      }
      if (response.isEmailSent) {
        updateEmailSendStatus({
          type: EmailSendStatusActions.SUCCESS,
          payload: 'Email sent successfully',
        });
        return;
      }
      throw new Error('Some error occurred!!');
    } catch (error) {
      updateEmailSendStatus({
        type: EmailSendStatusActions.ERROR,
        payload: 'Error while sending email',
      });
      if (errorHandlingUtilities.isErrorObjectOrAxiosError(error)) {
        if ('response' in error) {
          const status = error.response?.status;
          // handling not a valid user with input email
          if (status === 404) {
            updateEmailSendStatus({
              type: EmailSendStatusActions.ERROR,
              payload: 'user not found',
            });
          }
        }
      }
    }
  };

  return {
    emailSendStatus: {
      isEmailSendStatusLoading,
      isEmailSendStatusSuccess,
      isEmailSendStatusError,
      emailSendStatusErrorMsg,
      resetEmailSendStatus,
    },
    handleFormSubmit,
  };
};

export default useHandleEmailSignIn;

// utilities

type SendMagicLoginLinkToEmail = {
  email: string;
};

export async function sendMagicLoginLinkToEmail({
  email,
}: SendMagicLoginLinkToEmail): Promise<{
  isEmailSent: boolean;
  onboarding?: boolean;
  loginToken?: string;
  user?: LoggedInUser;
}> {
  try {
    const res = await serverApi.basic.post<Record<'isEmailSent', boolean>>(
      '/auth/login/email',
      {
        email,
      }
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
