import salesfrontsApiSlice from 'store/rtkqFeatures/api/salesfrontsbackendApiSlice';
import {
  PhysicalOrVirtualService,
  PhysicalOrVirtualServiceList,
  ServiceTypes,
} from 'types/service';
import {
  PhysicalOrVirtualMeeting,
  PhysicalOrVirtualMeetingList,
  MeetingTypes,
} from 'types/meeting';

export type Entity = PhysicalOrVirtualService | PhysicalOrVirtualMeeting;
export type EntityList = (PhysicalOrVirtualService | PhysicalOrVirtualMeeting)[];
export type EntityType = ServiceTypes | MeetingTypes;

function isServiceType(type: EntityType): type is ServiceTypes {
  return Object.values(ServiceTypes).includes(type as ServiceTypes);
}

const entityExtendedApi = salesfrontsApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEntitiesByUserId: build.query<EntityList, string>({
      async queryFn(userId, _queryApi, _extraOptions, fetchWithBQ) {
        // Fetch both services and meetings
        const servicesResult = await fetchWithBQ(`/services/user/${userId}`);
        const meetingsResult = await fetchWithBQ(`/meetings/user/${userId}`);

        if (servicesResult.error) return { error: servicesResult.error };
        if (meetingsResult.error) return { error: meetingsResult.error };

        const services = servicesResult.data as PhysicalOrVirtualServiceList;
        const meetings = meetingsResult.data as PhysicalOrVirtualMeetingList;

        // Combine and return both services and meetings
        return { data: [...services, ...meetings] };
      },
      providesTags: (result) =>
        result
          ? [
              { type: 'Service' as const, id: 'LIST' },
              { type: 'Meeting' as const, id: 'LIST' },
              ...result.map(({ _id, type }) => ({ 
                type: isServiceType(type) ? ('Service' as const) : ('Meeting' as const), 
                id: _id 
              })),
            ]
          : [
              { type: 'Service' as const, id: 'LIST' },
              { type: 'Meeting' as const, id: 'LIST' }
            ],
    }),
    getEntityById: build.query<Entity, { entityId: string; entityType: 'service' | 'meeting' }>({
      query: ({ entityId, entityType }) => ({
        url: `/${entityType}s/${entityId}`,
      }),
      providesTags: (result, error, arg) =>
        result 
          ? [{ type: arg.entityType === 'service' ? 'Service' : 'Meeting', id: result._id }]
          : [{ type: arg.entityType === 'service' ? 'Service' : 'Meeting', id: 'ENTITY' }],
    }),
  }),
});

export const {
  useGetEntitiesByUserIdQuery,
  useGetEntityByIdQuery,
} = entityExtendedApi;

export default entityExtendedApi;