import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

// NOTE: for testing purpose only

type TestCounterState = number;

const INITIAL_COUNTER_STATE: TestCounterState = 0;

const testCounterSlice = createSlice({
  name: 'testCounter',
  initialState: INITIAL_COUNTER_STATE,
  reducers: {
    incrementTestCounter: (state: TestCounterState) => {
      if (state < 10) {
        state = state + 1;
      } else {
        state = 0;
      }
      return state;
    },
    decrementTestCounter: (state: TestCounterState) => {
      if (state > 0) {
        state = state - 1;
      } else {
        state = 0;
      }
      return state;
    },
    resetTestCounter: () => {
      return INITIAL_COUNTER_STATE;
    },
  },
});

const testCounterReducer = testCounterSlice.reducer;
export default testCounterReducer;

export const { incrementTestCounter, decrementTestCounter, resetTestCounter } =
  testCounterSlice.actions;

// slice selectors
export const selectTestCounter = (state: RootState) => {
  return state.persistedReducer.testCounter;
};
