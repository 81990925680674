import { CircularProgress, Stack, useMediaQuery, useTheme } from '@mui/material';
import InputWithButton from 'components/InputField/InputWithButton';
import { customTextFieldPadding } from 'components/InputField/TextFieldSyncWithTheme';
import { Actions, AsyncReducerActions } from 'hooks/useAsyncStatus';
import { validateDomainName } from 'views/Domains/components/DomainSearchHeroSection';

type ThisProps = {
  inputValue: string;
  isError: boolean;
  isLoading?: boolean;
  error: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleClick?: () => void;
  handleSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
  updateStatus: React.Dispatch<AsyncReducerActions>;
  isStandaloneForm: boolean; // New prop to control whether it's a form or not
};
type WrapperProps =
  | {
      component: 'form';
      onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    }
  | {
      component: typeof Stack;
      onSubmit?: never;
    };

const DomainSearchInput = ({
  error,
  onChange,
  updateStatus,
  handleClick,
  handleSubmit,
  isError,
  isLoading,
  inputValue,
  isStandaloneForm, // New prop to control whether it's a form or not
}: ThisProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {    
    let input = e.target.value;
    input = input.toLowerCase();

    // Remove any special characters except letters, numbers, dots, hyphens, and spaces
    input = input.replace(/[^a-z0-9.\-\s]/g, '');
    onChange({ ...e, target: { ...e.target, value: input } });
    validateDomainName(input, updateStatus, Actions);
  };

  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleClick && handleClick();
    }
  }

  const wrapperProps: WrapperProps = isStandaloneForm
    ? { component: 'form', onSubmit: handleSubmit! }
    : { component: Stack };

  return (
    <InputWithButton
      containerWrapperProps={{
        ...wrapperProps,
        mb: 2,
      }}
      textFieldsProps={{
        onChange: handleChange,
        value: inputValue,
        placeholder: 'yourdomain.com',
        InputProps: {
          onKeyUp: !isStandaloneForm ? handleKeyUp : undefined,
        },
        sx: {
          pr: 0,
          width: '100%',
          height: 'auto',
          padding: 0,
        },
        helperText: isError ? error : '',
        FormHelperTextProps: {
          error: isError,
          sx: {
            width: '100%',
          },
        },
      }}
      buttonLabel='Search'
      buttonProps={{
        color: 'primary',
        type: isStandaloneForm ? 'submit' : 'button',
        onClick: !isStandaloneForm && handleClick ? handleClick : undefined,
        variant: 'contained',
        disabled: isError || isLoading,
        startIcon: isLoading ? (
          <CircularProgress color='inherit' size={isMobile ? 10 : 20} />
        ) : (
          <></>
        ),
        size: isMobile ? 'small' : 'large',
        sx: {
          pointerEvents: isLoading ? 'none' : 'auto',
          opacity: isError ? 0.5 : 1,
          boxShadow: 'none',
          padding: {
            xs: customTextFieldPadding.xs,
            md: customTextFieldPadding.md,
          },
        },
      }}
    />
  );
};

export default DomainSearchInput;
