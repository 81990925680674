export enum ServiceOrderStatusValues {
  RECEIVED = 'received',
  IN_PROGRESS = 'in-progress',
  DELIVERED = 'delivered',
}

type ServiceOrderCustomerDetails = {
  name: string;
  email: string;
  messageToProductOwner: string;
  created: string;
  phone?: string;
  paymentMethodStripeId?: string;
  stripeId?: string;
  receiptUrl?: string;
};

type ServiceDetails = Record<'title' | 'img', string>;

type ServiceOrder = {
  _id: string;
  serviceId: string;
  editorId: string;
  status: ServiceOrderStatusValues;
  currency: string;
  amountPaid: number;
  productOwnerInstructionsToCustomer: string;
  customerDetails: ServiceOrderCustomerDetails;
  hostConnectedAccDetails?: Record<'stripeId' | 'dbId', string>;
  stripePaymentIntentId?: string;
  serviceDetails: ServiceDetails;
  createdAt: string;
  isArchived?: boolean;
};

export default ServiceOrder;
