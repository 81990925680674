import * as yup from 'yup';
import { StepperState } from 'views/OnboardingFlow/OnboardingFlowForm/OnboardingFlowForm';
import { LoadingStatus } from 'hooks/useAsyncStatus';
import { userUrlValidation } from 'components/EditUserDetailsLatest/EUDFormValidationSchema';

const aiWebBuilderFormValidationSchema = yup.object().shape({
  pendingUrl: userUrlValidation(),
  name: yup
    .string()
    .min(2, 'Too short!!')
    .max(50, 'Too long!!')
    .test('ownWebsite-condition-name', 'Name is required', function (value) {
      const { ownWebsite } = this.parent as StepperState;
      return !ownWebsite ? !!value : true;
    }),
  profileType: yup
    .string()
    .oneOf(['male', 'female', 'company'], 'Invalid profile type')
    .test(
      'required-profile-type-condition',
      'Profile type is required',
      function (value) {
        const { ownWebsite } = this.parent as StepperState;
        return ownWebsite ? true : value !== undefined;
      }
    ),
  occupation: yup
    .string()
    .min(4, 'Too short!!')
    .max(10000, 'Too long!!')
    .test('ownWebsite-condition-occupation', 'Field is required', function (value) {
      const { ownWebsite } = this.parent as StepperState;
      return !ownWebsite ? !!value : true;
    }),
  address: yup
    .string()
    .test('provideInPersonService-condition', 'Address is required', function (value) {
      const { provideInPersonService } = this.parent as StepperState;
      return provideInPersonService ? !!value : true;
    }),
  // email: yup
  //   .string()
  //   .email('Invalid email')
  //   .required('Contact email is required')
  //   .test(
  //     'email-availability',
  //     'Email is already registered',
  //     function (value, { createError }) {
  //       return aiWebsiteBuilderUtilsFn
  //         .checkEmailValidity(value)
  //         .then(({ isValid }) => {
  //           return isValid;
  //         })
  //         .catch((err) => {
  //           toast.error((err as AxiosError).response?.data.message, {
  //             autoClose: 2000,
  //             pauseOnHover: true,
  //           });
  //           return createError({ message: (err as AxiosError).response?.data.message });
  //         });
  //     }
  //   ),
  phone: yup.string(),
  profileImgFile: yup
    .mixed<File>()
    .required('Profile image is required')
    .test('fileType', 'The file must be an image (jpg, jpeg, png, gif)', (value) => {
      if (!value) return false; // Return false if there is no file
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      return validImageTypes.includes(value.type);
    })
    .test('fileSize', 'The file size must be less than 10MB', (value) => {
      if (!value) return false; // Return false if there is no file
      const maxSize = 10 * 1024 * 1024; // 10MB in bytes
      return value.size <= maxSize;
    }),
  step: yup.number(),
  forgotPasswordLink: yup.string(),
  submitStatus: yup.string().oneOf(Object.values(LoadingStatus)),
  timeZone: yup.string().required('Timezone is required'),
  userGMTOffset: yup.number().required('GMT offset is required'),
});

export default aiWebBuilderFormValidationSchema;
