import loginTokenUtils from 'utilities/loginTokenUtils';

const useGetLoginToken = () => {
  const loginToken = loginTokenUtils.getLocalStorageLoginToken();
  return {
    loginToken,
  };
};

export default useGetLoginToken;
