import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { NavLink, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CircularProgress, SxProps } from '@mui/material';

import IfElse from 'components/utilities/IfElse';
import ReadMore from 'components/utilities/ReadMore';
import { getLocalOrGlobalStyling } from 'components/utilities/styleUtilities/styleUtilities';
import serviceValidationSchema from './serviceValidationSchema';
import EUDFormValidationErrorMsg from 'components/EditUserDetailsLatest/utility-components/EUDFormValidationErrorMsg';
import FrontsLoader from 'components/utilities/Loaders/FrontsLoader';

import useServiceCheckout from 'views/ServiceCheckout/hooks/useServiceCheckout';
import useGetCurrency from 'hooks/useGetCurrency';
import TextFieldSyncWithTheme from '../../components/InputField/TextFieldSyncWithTheme';
import { subscriptionDashboardURL } from 'components/dashboard/FrontsAIPremium/FrontsAIPremium';

export type ServiceCheckoutFormValues = {
  name: string;
  email: string;
  description: string;
  phone: string;
};

const initialServiceCheckoutFormValues: ServiceCheckoutFormValues = {
  name: '',
  email: '',
  description: '',
  phone: '',
};

const itemsCenter: SxProps = { display: 'inline-flex', alignItems: 'center' };

const ServiceCheckout = () => {
  const [loadingStatus, updateLoadingStatus] = useState<'idle' | 'loading' | 'error'>('idle');

  const isLoadingStatusLoading = loadingStatus === 'loading';
  const isLoadingStatusError = loadingStatus === 'error';
  const {
    errors: { fetchCurrentServiceError, fetchConnectedAccError, fetchEditorError },
    loading: { isConnectAccLoading, isEditorLoading, isServiceLoading },
    editor,
    currentServiceDetails,
    editorConnectedAcc,
    history,
    serviceId,
    handleStripeServiceCheckout,
  } = useServiceCheckout();
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const languageDir = query.get('dir') ?? 'ltr';
  const editorUrl = editor?.url ?? 'fronts';
  const servicePrice =
    currentServiceDetails && currentServiceDetails.price ? currentServiceDetails.price : 0;
  const isOnboardingProcessCompleted =
    !!editorConnectedAcc && editorConnectedAcc.isOnboardingProcessCompleted;
  const isServicePaid = !!currentServiceDetails && currentServiceDetails.isPaid;
  const showConnectedAccountOnboardingNotCompletedError =
    isServicePaid && !isOnboardingProcessCompleted;
  const disableCheckout = showConnectedAccountOnboardingNotCompletedError || isLoadingStatusLoading;
  const { symbol } = useGetCurrency(editor?.currency || 'usd');

  const handleServiceCheckoutFormSubmit = async (
    submittedValues: ServiceCheckoutFormValues,
    formikHelpers: FormikHelpers<ServiceCheckoutFormValues>
  ) => {
    // console.log({ submittedValues });
    if (disableCheckout) return;
    try {
      updateLoadingStatus('loading');
      await handleStripeServiceCheckout(submittedValues, servicePrice, isServicePaid); // paid service stripe checkout session
      if (!isServicePaid) {
        history.push({
          pathname: `/service/${serviceId}/checkout/success`,
          search: `?user-url=${editorUrl}`,
        });
      }
      formikHelpers.resetForm();
    } catch (error) {
      updateLoadingStatus('error');
    }
  };

  const { handleSubmit, handleChange, errors } = useFormik<ServiceCheckoutFormValues>({
    validationSchema: serviceValidationSchema,
    initialValues: initialServiceCheckoutFormValues,
    onSubmit: handleServiceCheckoutFormSubmit,
  });

  if (isConnectAccLoading || isEditorLoading || isServiceLoading) {
    return <FrontsLoader />;
  }

  if (fetchCurrentServiceError || fetchConnectedAccError || fetchEditorError) {
    console.error(
      `Error while fetching data,\nfetchCurrentServiceError: ${fetchCurrentServiceError}\nfetchConnectedAccError: ${fetchConnectedAccError}\nfetchEditorError: ${fetchEditorError}`
    );
    <Stack direction='row' justifyContent='center' alignItems='center' height='100vh'>
      <Alert>Some error occurred.</Alert>
    </Stack>;
  }

  // Just for a case
  if (!currentServiceDetails || !editor) {
    return <FrontsLoader />;
  }
  const { calendar } = currentServiceDetails.theme;
  const color = calendar.item.description.color ?? calendar.global.color;
  const descriptionStyle = {
    color: getLocalOrGlobalStyling(calendar.item.description.color, calendar.global.color),
    fontSize: getLocalOrGlobalStyling(calendar.item.description.fontSize, calendar.global.fontSize),
  };

  return (
    <Box
      sx={{
        background: calendar.background,
        backgroundBlendMode: calendar.backgroundBlendMode,
        backgroundSize: calendar.backgroundSize,
        minHeight: '100vh',
        paddingTop: '4rem',
        paddingBottom: '6rem',
      }}
    >
      <Container
        maxWidth='xl'
        sx={{
          marginBottom: '2rem',
        }}
      >
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon
            sx={{
              width: 30,
              height: 30,
              color,
            }}
          />
        </IconButton>
      </Container>
      <Container maxWidth='xl'>
        <Grid container spacing={4} justifyContent='space-between'>
          {/* ----------- Service details ------------- */}
          <Grid item xs={12} md={7} dir={languageDir}>
            <Stack direction='column' spacing={2}>
              <Typography variant='h2' dir={languageDir} color={color}>
                {currentServiceDetails.title}
              </Typography>
              {/* ------------ service price --------------- */}
              <IfElse condition={!!currentServiceDetails.price}>
                <Box>
                  <Typography component='span' bgcolor='#1565C0' color={'white'} sx={itemsCenter}>
                    &nbsp;
                    {symbol} &nbsp;
                    {currentServiceDetails.price}&nbsp;
                  </Typography>
                </Box>
              </IfElse>
              {/* ------------ service desc --------------- */}
              <ReadMore
                sx={descriptionStyle}
                html={currentServiceDetails.desc}
                length={300}
                gradient={'transparent'}
              />
            </Stack>
          </Grid>
          {/* ----------- Service checkout Form ------------- */}
          <Grid item xs={12} md={5}>
            <Stack
              direction='column'
              justifyContent='flex-end'
              spacing={{ xs: 1.5, md: 2 }}
              component='form'
              id='checkout-form'
              onSubmit={handleSubmit}
            >
              {/* ----------- Error Alerts ---------------- */}
              {/* ------------------ stripe onboarding process error ------------------------ */}
              <IfElse condition={showConnectedAccountOnboardingNotCompletedError}>
                <Alert severity='error' className='checkout-form__error-alert'>
                  <Typography variant='body2'>
                    Just a quick step left! Click&nbsp;
                    <NavLink to={subscriptionDashboardURL}>here</NavLink>&nbsp;to complete your Stripe
                    Onboarding Process and start accepting paid checkouts.
                  </Typography>
                </Alert>
              </IfElse>
              {/* ------------------ stripe checkout error ------------------------ */}
              <IfElse condition={isLoadingStatusError}>
                <Alert severity='error' className='checkout-form__error-alert'>
                  <Typography variant='body2'>
                    Some error occurred. Please try again after some time.
                  </Typography>
                </Alert>
              </IfElse>
              <Box>
                {/* ------------------ name ------------------------ */}
                <TextFieldSyncWithTheme
                  borderColor={calendar.global.color}
                  textColor={calendar.global.color}
                  type='text'
                  required
                  id='name'
                  label='Name'
                  fullWidth
                  name='name'
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Box>
              <Box>
                {/* ------------------ email ------------------------ */}
                <TextFieldSyncWithTheme
                  borderColor={calendar.global.color}
                  textColor={calendar.global.color}
                  type='email'
                  required
                  id='email'
                  label='Email'
                  fullWidth
                  name='email'
                  inputProps={{
                    sx: {
                      textTransform: 'lowercase',
                    },
                  }}      
                  onChange={handleChange}
                />
                <EUDFormValidationErrorMsg
                  errorMsg={errors.email || ''}
                  isVisible={!!errors.email}
                />
              </Box>

              {/* ------------------ contact number ------------------------ */}
              <TextFieldSyncWithTheme
                borderColor={calendar.global.color}
                textColor={calendar.global.color}
                type='text'
                id='phone'
                name='phone'
                label='Phone number'
                fullWidth
                inputProps={{
                  pattern: '[0-9\\-\\+\\(\\)\\s]*',
                  title: 'Please enter only numbers and symbols',
                }}
                onChange={handleChange}
              />
              <Box>
                {/* ------------------ Description ------------------------ */}
                <TextFieldSyncWithTheme
                  borderColor={calendar.global.color}
                  textColor={calendar.global.color}
                  type='text'
                  id='text'
                  label='Description'
                  fullWidth
                  required
                  name='description'
                  multiline
                  minRows={4}
                  onChange={handleChange}
                />
                <EUDFormValidationErrorMsg
                  errorMsg={errors.description || ''}
                  isVisible={!!errors.description}
                />
              </Box>
              {/* ------------------ checkout btn ------------------------ */}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                sx={{
                  ...calendar.item.button,
                  '&:hover': {
                    background: calendar.item.button.background,
                    color: calendar.item.button.color,
                  },
                }}
              >
                <Stack direction='row' justifyContent='center' alignItems='center'>
                  <IfElse
                    condition={isLoadingStatusLoading}
                    alternative={
                      <Box
                        sx={{
                          color: calendar.item.button.color,
                        }}
                      >
                        {currentServiceDetails.ui.ctaBtnText ?? 'Checkout'}
                      </Box>
                    }
                  >
                    <CircularProgress
                      size={18}
                      sx={{
                        color: calendar.item.button.color,
                      }}
                    />
                  </IfElse>
                </Stack>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ServiceCheckout;
