import { Typography } from '@mui/material';
import useEditableHeadingsTextLengthValidation from 'components/utilities/EditableHeadingsTextLengthValidationError/hooks/useEditableHeadingsTextLengthValidation';

type ThisProps = {
  isError: boolean;
};

const EditableHeadingsTextLengthValidationError: React.FunctionComponent<ThisProps> = ({
  isError,
}) => {
  const { MAX_HEADING_LENGTH } = useEditableHeadingsTextLengthValidation();
  if (!isError) return <></>;

  return (
    <Typography
      variant='overline'
      component='span'
      color='error'
      fontSize={{ xs: 12, md: 14 }}
      fontWeight={600}
      sx={{
        position: 'absolute',
        bottom: '0px',
        left: 0,
        transform: 'translateY(100%)',
      }}
    >
      Only {MAX_HEADING_LENGTH} characters are supported
    </Typography>
  );
};

export default EditableHeadingsTextLengthValidationError;
