type PolicySubSectionProps = {
  children: JSX.Element | JSX.Element[];
  lastSection?: boolean;
  classname?: string;
};

const PolicySubSection = ({
  children,
  lastSection,
  classname,
}: PolicySubSectionProps) => {
  return (
    <section
      className={`policy__sub-section ${classname} ${lastSection && 'mb-0'}`}
    >
      {children}
    </section>
  );
};

export default PolicySubSection;
