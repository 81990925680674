import serverApi from 'lib/apiConfig';
import { SortedSPMIds } from 'store/features/sortedSPMIds/sortedSPMIdsSlice';
import { LoggedInUser } from 'types/user';

async function getSortedSPMIds(userId: LoggedInUser['_id']) {
  const res = await (
    await serverApi.v2.get(`/sorted-spm-ids/user/${userId}`)
  ).data;
  return res;
}

async function updateSortedSPMIds(
  userId: LoggedInUser['_id'],
  sortedSPMIds: SortedSPMIds
) {
  const res = await serverApi.v2.put(
    `/sorted-spm-ids/user/${userId}`,
    sortedSPMIds
  );
  return res.data;
}

const sortedSPMIdsServices = {
  getSortedSPMIds,
  updateSortedSPMIds,
};

export default sortedSPMIdsServices;
