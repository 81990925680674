import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Stack,
  Typography,
  lighten,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DOMAIN_PARAMS } from '../useDomains';
import { useHistory } from 'react-router-dom';
import LayoutContainer from 'components/Containers/LayoutContainer';

import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import useAsyncStatus, { Actions, AsyncReducerActions } from 'hooks/useAsyncStatus';

// Utils functions
export const validateDomainName = (
  value: string,
  updateStatus: React.Dispatch<AsyncReducerActions>,
  actions: typeof Actions
) => {
  // Check if the domain name is empty
  if (value.length === 0) {
    updateStatus({ type: actions.SUCCESS, payload: '' });
    return;
  }

  // Check the length of the domain name
  if (value.length < 4) {
    updateStatus({
      type: actions.ERROR,
      payload: 'Domain name should be at least 4 characters',
    });
    return;
  }

  if (value.length > 63) {
    updateStatus({
      type: actions.ERROR,
      payload: 'Domain name should be at most 63 characters',
    });
    return;
  }

  // Check for invalid characters (allowing spaces)
  if (/[^a-zA-Z0-9.-\s]/.test(value)) {
    updateStatus({
      type: actions.ERROR,
      payload: 'Domain name should not contain special characters',
    });
    return;
  }

  // // Check if the domain starts and ends with a letter or number
  // if (!/^[a-zA-Z0-9].*[a-zA-Z0-9]$/.test(value)) {
  //   updateStatus({
  //     type: actions.ERROR,
  //     payload: 'Domain name should start and end with a letter or number',
  //   });
  //   return;
  // }

  // Check for consecutive hyphens
  if (/--/.test(value)) {
    updateStatus({
      type: actions.ERROR,
      payload: 'Domain name should not contain consecutive hyphens',
    });
    return;
  }

  // If all validations pass, the domain name is valid
  updateStatus({ type: actions.SUCCESS, payload: '' });
};


const DomainSearchHeroSection = () => {
  const history = useHistory();
  const [url, setUrl] = useState<string>('');
  const { Actions, updateStatus, isError, error } = useAsyncStatus();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value;
    
    input = input.toLowerCase();
  
    // Remove any special characters except letters, numbers, dots, hyphens, and spaces
    input = input.replace(/[^a-z0-9.\-\s]/g, '');
  
    setUrl(input);    
    validateDomainName(input, updateStatus, Actions);
  };
  

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the domain name before submitting
    const sanitizedUrl = url.replace(/\s+/g, '').toLowerCase(); // Remove spaces from the domain name

    if (!sanitizedUrl || sanitizedUrl.trim().length === 0 || isError) {
      // If the input is empty or there is a validation error, return early
      updateStatus({
        type: Actions.ERROR,
        payload: 'Please enter a valid domain name',
      });
      return;
    }

    const urlParams = new URLSearchParams();
    urlParams.append(DOMAIN_PARAMS.searchedDomain, sanitizedUrl);

    history.push({
      pathname: '/domains/search',
      search: urlParams.toString(),
    });
  };

  return (
    <Box
      component='section'
      sx={{
        backgroundImage: 'linear-gradient(45deg, #e613ff, #a0c1fb)',
        py: { xs: 5, md: 0 },
      }}
    >
      <LayoutContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: 'calc(100vh - 64px)',
        }}
      >
        <Stack
          sx={{
            color: (theme) => theme.palette.common.white,
            width: { xs: '100%', md: '50%' },
            order: { xs: 1, md: 0 },
          }}
        >
          <Typography
            variant='h1'
            fontSize={{ xs: '3rem', md: '6rem' }}
            color={(theme) => theme.palette.common.white}
          >
            Find your perfect domain and Get Online instantly
          </Typography>
          <Typography
            mt={1}
            variant='body1'
            color={(theme) => lighten(theme.palette.common.white, 0.5)}
          >
            Find, buy, and auto-connect your perfect domain to your website effortlessly.
            Our seamless process ensures you get the domain you want and have it linked to
            your site instantly, all in a few simple steps.
          </Typography>

          <FormControl
            component={'form'}
            onSubmit={handleSubmit}
            sx={{
              maxWidth: '400px',
              mt: 2,
              '& .MuiOutlinedInput-root': {
                borderColor: 'white',
                color: 'white',
                height: 'auto',
                pr: 0,
                textTransform: 'lowercase',

                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white !important',
                },

                '& .MuiOutlinedInput-input': {
                  padding: '10px 14px',
                  color: 'white',
                  textTransform: 'none',
                  textDecoration: 'none',
                },

                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                },
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white !important',
                },
              },
            }}
          >
            <OutlinedInput
              fullWidth
              notched={false}
              size={isPhone ? 'small' : 'medium'}
              onChange={handleChange}
              label='Domain Name'
              placeholder='example.com'
              endAdornment={
                <Button
                  size={isPhone ? 'small' : 'medium'}
                  sx={{
                    opacity: isError ? 0.5 : 1,
                    minWidth: 'auto',
                    boxShadow: 'none',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    bgcolor: 'common.white',
                    color: 'common.black',
                    alignSelf: 'stretch',
                  }}
                  type='submit'
                  variant='contained'
                >
                  Search
                  <SearchIcon />
                </Button>
              }
            />
            <FormHelperText
              error={isError}
              sx={{
                display: isError ? 'block' : 'none',
                backgroundColor: 'red',
                color: 'white !important',
                padding: '8px',
                borderRadius: '4px',
                marginTop: '8px',
                mx: 0,
              }}
            >
              {isError ? error : ''}
            </FormHelperText>
          </FormControl>
        </Stack>

        <Box
          component={'figure'}
          width={{ xs: '100%', md: '40%' }}
          order={{ xs: 0, md: 1 }}
        >
          <Box
            sx={{
              width: '120%',
              maxWidth: { xs: '100%', md: '120%' },
              height: 'auto',
            }}
            component={'img'}
            src='https://res.cloudinary.com/salesfronts/image/upload/v1718025057/samples/ykvt9qfuhef3blqt8npq.png'
            alt='Domain Search'
          />
        </Box>
      </LayoutContainer>
    </Box>
  );
};

export default DomainSearchHeroSection;
