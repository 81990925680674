import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button, useMediaQuery } from '@mui/material';
import { LoggedInUser } from 'types/user';

const CallToActionBtn = ({
  user,
  buttonStyle,
  storeHeading,
}: {
  user: LoggedInUser;
  buttonStyle: any;
  storeHeading: string;
}) => {
  const isPhone = useMediaQuery('(max-width:600px)');
  const isRtl = user.languageDir === 'rtl';
  return (
    <Button
      variant='contained'
      size={isPhone ? 'small' : 'large'}
      LinkComponent={'a'}
      endIcon={
        isRtl ? (
          <NavigateBeforeIcon
            sx={{ width: { xs: 25, md: 35 }, height: { xs: 25, md: 35 } }}
          />
        ) : (
          <NavigateNextIcon
            sx={{ width: { xs: 25, md: 35 }, height: { xs: 25, md: 35 } }}
          />
        )
      }
      component='a'
      href={`#${storeHeading}`}
      sx={{        
        ...buttonStyle,
        '&:hover': {
          opacity: 0.8,
          color: buttonStyle?.color,
          bgcolor: buttonStyle?.background,
        },
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: { xs: '18px', md: '20px' },
      }}
    >
      {storeHeading}{' '}
    </Button>
  );
};

export default CallToActionBtn;
