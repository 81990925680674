// NOTE : This file is for general utilities that can be used in any project.
const generalUtilities = { isUserAgentWindows, findChanges, sliceText };

export default generalUtilities;

function isUserAgentWindows() {
  return navigator.userAgent.indexOf('Windows') > -1;
}

export function readEnv(env: string) {
  // Not needed to check process undefined as it is not always not defined in browser. it's added by webpack.  
  return process.env?.[env]?.trim() ?? undefined;
}

function findChanges<T extends {}>(oldObject: T, newObject: T) {
  const changes: any = {};

  for (const key in newObject) {
    if (oldObject.hasOwnProperty(key) && newObject.hasOwnProperty(key)) {
      if (oldObject[key] !== newObject[key]) {
        changes[key] = newObject[key];
      }
    } else if (!oldObject.hasOwnProperty(key) && newObject.hasOwnProperty(key)) {
      changes[key] = newObject[key];
    }
  }

  return changes;
}

function sliceText(text: string, length?: number) {
  const maxLength = length || 23;

  if (text.trim().length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

// TODO - use this function to format price
// export const priceFormatter = (price: number, curreny: string) => {
//   const formatterEnCA = new Intl.NumberFormat('en-', {
//     style: 'currency',
//     currency: 'CAD',
//   });
//   return formatterEnCA.format(price);
// };
