import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useOnclickOutside from 'react-cool-onclickoutside';
import {
  ButtonGroup,
  Typography,
  Button,
  ButtonProps,
  Stack,
  IconButton,
  Box,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { logout } from 'store/features/user/userActions';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import IfElse from 'components/utilities/IfElse';
import FontSelector from 'components/FontSelector/FontSelector';
import userServices from 'lib/services/userServices';
import routeConstants from 'constants/routeConstants';
import useViewerURL from 'hooks/useViewerURL';
import { useGetEditorByIdQuery } from 'store/rtkqFeatures/api/editorExtendedApi';
import { useGetDomainDetailsByIdQuery } from 'store/rtkqFeatures/api/domainExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';
import loginTokenUtils from 'utilities/loginTokenUtils';
import {
  Menu,
  Logout,
  Search,
  Dashboard,
  LiveTv,
  Http,
  AdminPanelSettings,
} from '@mui/icons-material';
import {
  style_HeaderMenuItems_button,
  style_HeaderMenuItems_container,
  style_HeaderMenuItems_liveSiteBtn,
  style_HeaderMenuItems_subMenu,
} from './HeaderMenuItems.style';

const HOME_ROUTE = routeConstants.HOME_ROUTE;
const FONT_SIZE_BUTTON = { xs: '1.5rem', md: '1.6rem', lg: '1.8rem' };

export type HeaderMenuItemsProps = {
  isScreenSizeLessThan601Px: boolean;
  onFontChange?: () => void;
};

const HeaderMenuItems: React.FC<HeaderMenuItemsProps> = ({
  isScreenSizeLessThan601Px,
  onFontChange,
}) => {
  return isScreenSizeLessThan601Px ? (
    <MobileHeaderMenuItems onFontChange={onFontChange} />
  ) : (
    <DesktopHeaderMenuItems />
  );
};

const MobileHeaderMenuItems: React.FC<{ onFontChange?: () => void }> = ({
  onFontChange,
}) => {
  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: user } = useGetEditorByIdQuery(editorId, { skip: !editorId });
  const editorURL = user?.url ?? '';
  const STATIC_PAGE_URL = useViewerURL(editorURL);
  const staticPageUrlWithRef = `${STATIC_PAGE_URL}?ref=${user?.url}`;

  const history = useHistory();
  const reduxDispatch = useAppDispatch();

  const logoutUserAndResetRedux = async () => {
    const timerId = toast.loading('Logging out...');
    try {
      await userServices.userLogout();
      loginTokenUtils.removeLocalStorageLoginToken();
      reduxDispatch(logout());
      toast.success('Logged out successfully');
      window.location.href = HOME_ROUTE;
    } catch (err) {
      console.error(err);
    } finally {
      history.replace(HOME_ROUTE);
      toast.dismiss(timerId);
    }
  };

  const handleDashboardLinkClick = () => history.push('/dashboard');

  return (
    <Stack
      alignItems='center'
      gap={1}
      direction='column'
      sx={style_HeaderMenuItems_container(FONT_SIZE_BUTTON)}
    >
      <FontSelector onFontChange={onFontChange} />
      <MenuItems
        handleDashboardLinkClick={handleDashboardLinkClick}
        signOut={logoutUserAndResetRedux}
        isScreenSizeLessThan601Px={true}
        staticPageUrlWithRef={staticPageUrlWithRef}
      />
    </Stack>
  );
};

const DesktopHeaderMenuItems: React.FC = () => {
  const [showMore, setShowMore] = useState(false);
  const moreBtnRef = useOnclickOutside(() => setShowMore(false));

  const { cachedLoggedInUser } = useGetLoggedInUser();
  const { data: domainDetails } = useGetDomainDetailsByIdQuery(
    cachedLoggedInUser?._id || '',
    {
      skip: !cachedLoggedInUser?._id,
    }
  );
  const doesHaveCustomDomain = (domainDetails?.length || 0) > 0;

  const editorId = useAppSelector(selectLoggedInUserId);
  const { data: user } = useGetEditorByIdQuery(editorId, { skip: !editorId });
  const editorURL = user?.url ?? '';
  const STATIC_PAGE_URL = useViewerURL(editorURL);
  const staticPageUrlWithRef = `${STATIC_PAGE_URL}?ref=${user?.url}`;

  const history = useHistory();
  const reduxDispatch = useAppDispatch();

  const logoutUserAndResetRedux = async () => {
    const timerId = toast.loading('Logging out...');
    try {
      await userServices.userLogout();
      loginTokenUtils.removeLocalStorageLoginToken();
      reduxDispatch(logout());
      toast.success('Logged out successfully');
      window.location.href = HOME_ROUTE;
    } catch (err) {
      console.error(err);
    } finally {
      history.replace(HOME_ROUTE);
      toast.dismiss(timerId);
    }
  };

  const handleMoreBtnClick = () => setShowMore(!showMore);
  const handleDashboardLinkClick = () => history.push('/dashboard');

  return (
    <>
      <Box>
        <MenuButton
          sx={{ ...style_HeaderMenuItems_liveSiteBtn(), textTransform: 'unset' }}
          href={staticPageUrlWithRef}
          onMouseEnter={setCookie}
        >
          Live Site
        </MenuButton>
      </Box>
      <Box>
        <Link
          style={{ padding: 0 }}
          to={doesHaveCustomDomain ? '/dashboard/domain' : '/domains'}
        >
          <MenuButton
            sx={{ ...style_HeaderMenuItems_liveSiteBtn(), textTransform: 'unset' }}
          >
            Domain
          </MenuButton>
        </Link>
      </Box>
      <Box>
        <IconButton
          className='Menu_icon'
          sx={{
            fontSize: 'inherit',
            position: 'relative',
            py: 1,
            pl: 1,
            paddingRight: { xs: 3, md: 0, lg: 0 },
            borderRadius: 0,
          }}
          size='large'
          onClick={handleMoreBtnClick}
          aria-label='More'
          ref={moreBtnRef}
        >
          <Menu fontSize='medium' />
          <IfElse condition={showMore}>
            <MenuItems
              handleDashboardLinkClick={handleDashboardLinkClick}
              signOut={logoutUserAndResetRedux}
            />
          </IfElse>
        </IconButton>
      </Box>
    </>
  );
};

const MenuItems = ({
  handleDashboardLinkClick,
  signOut,
  isScreenSizeLessThan601Px,
  staticPageUrlWithRef,
}: {
  handleDashboardLinkClick: () => void;
  signOut: () => Promise<void>;
  isScreenSizeLessThan601Px?: boolean;
  staticPageUrlWithRef?: string;
}) => {
  const history = useHistory();
  const { cachedLoggedInUser } = useGetLoggedInUser();
  const adminUsers = ['mohammad@fronts.ai', 'gil@fronts.ai', 'eugene@fronts.ai'];
  const isAdmin = adminUsers.some(
    (adminUser) => adminUser.toLowerCase() === cachedLoggedInUser?.email.toLowerCase()
  );

  return (
    <ButtonGroup
      variant='text'
      color='secondary'
      orientation='vertical'
      sx={style_HeaderMenuItems_subMenu()}
    >
      {isAdmin && (
        <MenuButton
          startIcon={<AdminPanelSettings width={30} height={30} />}
          onClick={() => history.push('/admin-panel')}
        >
          Admin panel
        </MenuButton>
      )}
      <MenuButton
        aria-label='Dashboard'
        onClick={handleDashboardLinkClick}
        startIcon={<Dashboard width={30} height={30} />}
      >
        dashboard
      </MenuButton>
      {isScreenSizeLessThan601Px && (
        <>
          <MenuButton
            aria-label='Live site'
            href={staticPageUrlWithRef}
            onMouseEnter={setCookie}
            LinkComponent='a'
            startIcon={<LiveTv sx={{ width: 25, height: 25 }} />}
          >
            Live site
          </MenuButton>
          <MenuButton
            href='/domains'
            aria-label='Your domain'
            startIcon={<Http sx={{ width: 25, height: 25 }} />}
          >
            Your domain
          </MenuButton>
        </>
      )}
      <MenuButton
        LinkComponent='a'
        href={routeConstants.LIVE_ROUTE}
        aria-label='search'
        startIcon={<Search width={30} height={30} />}
      >
        Search
      </MenuButton>
      <MenuButton
        onClick={signOut}
        aria-label='Logout'
        startIcon={<Logout width={30} height={30} />}
      >
        Logout
      </MenuButton>
    </ButtonGroup>
  );
};

const MenuButton = ({ children, ...buttonProps }: ButtonProps) => (
  <Button color='inherit' sx={{ ...style_HeaderMenuItems_button() }} {...buttonProps}>
    <Typography variant='body2' className='menu_label'>
      {children}
    </Typography>
  </Button>
);

const setCookie = () => {
  document.cookie = `previousUrl=${window.location.href}; domain=fronts.ai`;
};

export default HeaderMenuItems;
