import { useEffect } from 'react';
import {
  Box,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

import { useAppDispatch } from 'hooks/redux';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';

import { updateLocalCurrentThemeIndex } from 'store/features/localThemeSelector/localThemeSelectorSlice';
import {
  style_ThemeSelector_Icon,
  style_ThemeSelector_formControl,
  style_ThemeSelector_menuItems,
  style_ThemeSelector_name,
  style_ThemeSelector_select,
} from './ThemeSelector.style';

export const PREMIUM_THEME_ICON_URL =
  'https://res.cloudinary.com/salesfronts/image/upload/v1675231638/premium-theme-icon_xzfc4q.png';

const ThemeSelector = () => {
  const reduxDispatch = useAppDispatch();
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const { cachedThemes } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      cachedThemes: themes?.map((eachTheme) => ({
        id: eachTheme.id,
        name: eachTheme.name,
        themeColor: eachTheme.themeColor,
        themeBGColor: eachTheme.navbar.background,
        themeButtonColor: eachTheme.navbar.ctaButton.color,
        themeButtonBGColor: eachTheme.navbar.ctaButton.background,
        isPremium: eachTheme.premium ?? false,
      })),
    }),
  });

  // ✔️ Adding cached theme to html css variable.
  useEffect(() => {
    if (cachedThemes) {
      const computedStyleOfBody = document.body.style;
      computedStyleOfBody.setProperty(
        '--theme-color',
        cachedThemes[localCurrentThemeIndex].themeColor
      );
      computedStyleOfBody.setProperty(
        '--theme-color-transparent',
        cachedThemes[localCurrentThemeIndex].themeColor.substring(0, 7) + 'aa'
      );
      computedStyleOfBody.setProperty(
        '--theme-bgcolor',
        cachedThemes[localCurrentThemeIndex].themeBGColor
      );
      computedStyleOfBody.setProperty(
        '--theme-bgcolor-transparent',
        cachedThemes[localCurrentThemeIndex].themeBGColor.substring(0, 7) + '11'
      );
      computedStyleOfBody.setProperty(
        '--theme-button-color',
        cachedThemes[localCurrentThemeIndex].themeButtonColor
      );
      computedStyleOfBody.setProperty(
        '--theme-button-bg-color',
        cachedThemes[localCurrentThemeIndex].themeButtonBGColor
      );
    }
  }, [cachedThemes, localCurrentThemeIndex]);

  // currentThemeNumber & updatedThemeIndex are the cachedThemes array index numbers not the current themeId
  const handleThemeChange = async (e: SelectChangeEvent<number>) => {
    const updatedThemeIndex = Number(e.target.value);
    if (updatedThemeIndex === localCurrentThemeIndex) return;
    // console.log('updating local theme index of redux');
    reduxDispatch(updateLocalCurrentThemeIndex(updatedThemeIndex));
  };

  if (!cachedThemes) {
    return <></>;
  }

  return (
    <>
      <FormControl fullWidth sx={style_ThemeSelector_formControl()}>
        <Select
          labelId='theme-selector'
          id='theme-selector'
          aria-label='change-themes'
          variant='filled'
          defaultValue={0}
          sx={style_ThemeSelector_select()}
          value={localCurrentThemeIndex}
          onChange={handleThemeChange}
          inputProps={{
            classes: {
              icon: 'MuiSvgIcon-root', // Adjust this class name as needed
            }
          }}      
        >
          {cachedThemes?.map(({ name, themeColor, isPremium }, index) => {
            return (
              <MenuItem key={index} value={index} sx={style_ThemeSelector_menuItems()}>
                <Stack
                  direction='row'
                  spacing={{ xs: 1, md: 1 }}
                  justifyContent='flex-start'
                  alignItems='center'
                >
                  {/*  theme color  */}
                  <SquareRoundedIcon sx={style_ThemeSelector_Icon(themeColor)} />
                  {/* theme name  */}
                  <Typography variant='body2' sx={style_ThemeSelector_name()}>
                    {name}
                  </Typography>
                  {/* premium theme icon  */}
                  {isPremium && (
                    <Box
                      component='img'
                      src={PREMIUM_THEME_ICON_URL}
                      alt='premium-theme-icon'
                      width={20}
                      sx={{ marginLeft: 'auto' }}
                    />
                  )}
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

export default ThemeSelector;
