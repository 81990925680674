import { useState, useEffect } from 'react';
import {
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

import { languages } from './languages';
import TextFieldSyncWithTheme from 'components/InputField/TextFieldSyncWithTheme';

export type LanguageType = typeof languages extends Array<infer U> ? U : never;

type ChooseLanguagesProps = {
  setChoosedLanguage: (
    value: {
      language: string;
      languageDir: string;
    }
  ) => void;
  choosedLanguage: {
    language: string;
    languageDir: string;
  }
  name: string;
};

const ChooseLanguages = ({setChoosedLanguage, name, choosedLanguage}: ChooseLanguagesProps) => {
  const [language, setLanguage] = useState<LanguageType>(languages[0]);

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const parsedValue = JSON.parse(e.target.value as string);
    setLanguage(parsedValue);
    setChoosedLanguage(parsedValue);
  };

  useEffect(() => {
    setLanguage(choosedLanguage);
  }, [choosedLanguage])


    return (
      <TextFieldSyncWithTheme
        select
        color='primary'
        variant='outlined'
        name={name}
        SelectProps={{
          onChange: handleChange,
          value: JSON.stringify(language),
        }}
        sx={{ mt: 2, minWidth: 120, width: '100%' }}
      >
        {languages.map((langObj) => (
          <MenuItem key={langObj.language} value={JSON.stringify(langObj)}>
            {langObj.language}
          </MenuItem>
        ))}
      </TextFieldSyncWithTheme>    
  );
};

export default ChooseLanguages;
