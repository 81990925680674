import React, { forwardRef } from 'react';
import { Box, Button, ButtonProps } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';

const FileReader = forwardRef(({
  label = 'Upload Image',
  handleImageChange,
  color = 'primary',
}: {
  label: string;
  handleImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  color?: ButtonProps['color'];
}, ref: React.Ref<HTMLInputElement>) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        color={color}
        fullWidth
        variant='outlined'
        sx={{ fontSize: { xs: 14, md: 16 } }}
      >
        <input
          ref={ref}
          type='file'
          accept='image/*'
          style={{
            position: 'absolute',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
          }}
          onChange={handleImageChange}
        />
        &nbsp;
        <ImageIcon sx={{ width: { xs: 20, md: 25 }, height: { xs: 20, md: 25 } }} />
        <Box color={`${color}.main`}>
          {label}
        </Box>
      </Button>
    </Box>
  );
});

FileReader.displayName = 'FileReader';

export default FileReader;