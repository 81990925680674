import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';

type ThisProps = {
  errorMsg: string;
  isVisible?: boolean;
};

const EUDFormValidationErrorMsg = ({ errorMsg, isVisible = true }: ThisProps) => {
  if (!isVisible && !errorMsg) return <></>;

  return (
    <Typography
      variant='body2'
      sx={{
        color: red[600],
      }}
    >
      {errorMsg}
    </Typography>
  );
};

export default EUDFormValidationErrorMsg;
