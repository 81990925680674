import axios, { AxiosError } from 'axios';

const errorHandlingUtilities = {
  isTypeObject,
  isTypeObjectWithProps,
  isErrorObjectOrAxiosError,
  isRTKQueryAPIError,
  getErrorData,
};

export type TypedError = Error | AxiosError;

export default errorHandlingUtilities;

// utility 1
interface IsTypeObjectWithPropsReturnType<T extends object> {
  (value: unknown): value is T;
}

function isTypeObjectWithProps<T extends object>(
  properties: (keyof T)[]
): IsTypeObjectWithPropsReturnType<T> {
  return (value: unknown): value is T => {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    for (const prop of properties) {
      if (!(prop in value)) {
        return false;
      }
    }

    return true;
  };
}

// utility 2
function isTypeObject(entity: unknown): entity is object {
  return typeof entity === 'object' && entity !== null;
}

// utility 3
function isErrorObjectOrAxiosError(error: any): error is TypedError {
  return error instanceof Error || axios.isAxiosError(error);
}

/**
 * utility 4
 * useful for error handling with RTK mutations in the catch block
 * to check or handle the type of unknown error object.
 */
type RTKQueryAPIError<ErrorData> = {
  status: number;
  data: ErrorData;
};

export function isRTKQueryAPIError<ErrorData extends object>(
  error: unknown,
  requiredProperties: (keyof ErrorData)[]
): error is RTKQueryAPIError<ErrorData> {
  if (typeof error !== 'object' || error === null) {
    return false;
  }

  if (
    'status' in error &&
    typeof (error as any).status === 'number' &&
    'data' in error &&
    typeof (error as any).data === 'object'
  ) {
    const errorData = (error as RTKQueryAPIError<ErrorData>).data;
    return requiredProperties.every((property) => property in errorData);
  }

  return false;
}

export function getErrorData<T>(error: AxiosError): T | undefined {
  if (axios.isAxiosError(error)) {
    // Check if response data has a message
    if (error.response?.data?.message) {
      return error.response.data;
    }
  }
  
  return undefined;
};
