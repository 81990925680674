import { forwardRef, Ref, useCallback } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ReCAPTCHA from 'react-google-recaptcha';
import { SxProps } from '@mui/material';

const isNodeEnvProduction = process.env.NODE_ENV === 'production';

const GOOGLE_RECAPTCHA_KEY = isNodeEnvProduction
  ? (process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string)
  : (process.env.REACT_APP_DEVELOPMENT_GOOGLE_RECAPTCHA_SITE_KEY as string);

type FunctionType = () => void;

type ThisProps = {
  onChangeWithValidCaptchaValue: (token: string) => void;
  onClick?: FunctionType;
  onError?: FunctionType;
  onExpired?: FunctionType;
  sx?: SxProps;
};

const GoogleCaptcha = forwardRef(
  (
    { onChangeWithValidCaptchaValue, onError, onClick, sx }: ThisProps,
    recaptchaRef: Ref<ReCAPTCHA>
  ) => {
    const handlRecpatchaChange = useCallback(
      (token: string | null) => {
        if (token) onChangeWithValidCaptchaValue(token);
      },
      [onChangeWithValidCaptchaValue]
    );

    // console.log('GOOGLE_RECAPTCHA_KEY: ', GOOGLE_RECAPTCHA_KEY);

    return (
      <Fade in timeout={800}>
        <Box
          sx={{
            transformOrigin: '0 0',
            transform: {
              xs: 'scale(1)',
              md: 'scale(1)',
            },
            ...sx,
          }}
        >
          <ReCAPTCHA
            onClick={onClick}
            sitekey={GOOGLE_RECAPTCHA_KEY}
            ref={recaptchaRef}
            onChange={handlRecpatchaChange}
            onError={onError}
            onExpired={onError}
          />
        </Box>
      </Fade>
    );
  }
);

export default GoogleCaptcha;
