import { LoggedInUser, UserModule } from 'types/user';
import { WebsiteFontFamily } from '../websiteFontFamily/websiteFontFamilySlice';

const themeId = 0;
const lastChosenFreeThemeId = themeId;

export const initialUserData: LoggedInUser = {
  _id: '',
  authType: 'ee',
  searchable: false,
  isAuthTagVisible: true,
  timeZone: '',
  name: '',
  url: '',
  title: '',
  subTitle: '',
  email: '',
  emailPublic: false,
  themeId: 0,
  lastChosenFreeThemeId,
  forcedSearchable: false,
  img: '',
  fontFamily: WebsiteFontFamily.POPPINS,
  faviconImg: '',
  phone: '',
  whatsapp: {
    num: '',
    countryCode: 1,
  },
  address: '',
  about: '',
  skills: '',
  tags: [],
  ui: {
    aboutHeading: 'About',
    portfolioHeading: 'Portfolio',
    storeHeading: 'Services',
    skillsHeading: 'demo skills',
    displaySocialLinks: true,
    displayTags: true,
  },
  socialLinks: [],
  createdAt: 0,
};

export const USER_MODULE_INITIAL_STATE: UserModule = {
  loggedInUser: initialUserData,
};
