import { Session } from 'types/sessions';

export function getStatusForGuest(status: Session['status']): {
  backgroundColor: React.CSSProperties['backgroundColor'];
  color: React.CSSProperties['color'];
  content: React.ReactNode;
} {
  let backgroundColor: React.CSSProperties['backgroundColor'],
    color: React.CSSProperties['color'];

  if (status === 'guest-request-meeting' || status === 'host-propose-new-times') {
    backgroundColor = '#FFD700';
    color = '#000';
  } else if (
    status === 'confirmed' ||
    status === 'guest-selected-proposed-time' ||
    status === 'paid'
  ) {
    backgroundColor = '#66bb6a';
    color = '#FFF';
  } else if (status === 'rejected' || status === 'cancelled') {
    backgroundColor = '#ef5350';
    color = '#FFF';
  } else if (status === 'payment-waiting') {
    backgroundColor = '#FFD700';
    color = '#000';
  } else {
    backgroundColor = 'transparent';
    color = 'grey.700';
  }

  return {
    backgroundColor,
    color,
    content: sessionStatusMap[status as keyof typeof sessionStatusMap],
  };
}

const sessionStatusMap = {
  paid: 'paid',
  'payment-waiting': 'Payment waiting',
  'guest-request-meeting': 'Pending',
  pending: 'Pending',
  confirmed: 'Confirmed',  
  rejected: 'Declined by host',
  'host-propose-new-times': 'Host has proposed new time',
  'pending-guest-payment': 'Pending',
  'pending-guest-select-new-time': 'Pending',
  cancelled: 'Cancelled',
  'guest-selected-proposed-time': 'Confirmed',
};

