import React from 'react';
import EditPageWithRichText from 'components/utilities/EditPageWithRichText/EditPageWithRichText';
import { useUpdatedEditorByIdMutation } from 'store/rtkqFeatures/api/editorExtendedApi';
import useGetLoggedInUser from 'store/rtkqFeatures/api/hooks/useGetLoggedInUser';

const EditProfileSubtitle = () => {
  const { cachedLoggedInUser: editor, userIdInReduxUserSlice: editorId } =
    useGetLoggedInUser();
  const [updateEditor, { isLoading: isSubtitleUpdateInProgress }] =
    useUpdatedEditorByIdMutation();

  const handleSavingForm = React.useCallback(
    async (updatedSubtitle: string) => {
      try {
        updateEditor({
          editorId: editorId,
          loggedInUser: {
            subTitle: updatedSubtitle,
          },
        });
      } catch (error) {
        throw error;
      }
    },
    [updateEditor, editorId]
  );

  return (
    <EditPageWithRichText
      allowSavingForm={!isSubtitleUpdateInProgress}
      disableCancelBtn={false}
      disableUpdateBtn={isSubtitleUpdateInProgress}
      editorCurrentContent={editor?.subTitle ?? ''}
      handleSavingForm={handleSavingForm}
      pageHeading='Edit Profile Subtitle'
    />
  );
};

export default EditProfileSubtitle;
