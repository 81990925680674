import { useState } from 'react';
import Slider, { Settings } from 'react-slick';
import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid';
import { IconButton, SxProps } from '@mui/material';

import { useAppSelector } from 'hooks/redux';
import { selectLoggedInUserId } from 'store/features/user/userSlice';
import { useGetPortfoliosByUserIdQuery } from 'store/rtkqFeatures/api/portfolioExtendedApi';
import { useGetThemesQuery } from 'store/rtkqFeatures/api/themesExtendedApi';
import useLocalCurrentThemeIndex from 'store/features/localThemeSelector/hooks/useLocalCurrentThemeIndex';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { getInPx } from 'components/utilities/styleUtilities/styleUtilities';
import { initialThemeState } from 'themes/AppThemeProvider/themeType';
import PortfolioHeading from 'components/Portfolios/sub-components/PortfolioHeading/PortfolioHeading';
import BootstrapLoader from 'components/utilities/Loaders/BootstrapLoader';
import PortfolioGalleryMode from './sub-components/PortfolioGalleryMode/PortfolioGalleryMode';
import PortfolioSlider from './sub-components/PortfolioSlider';
import LayoutContainer from 'components/Containers/LayoutContainer';
import PortfolioAddAndDesignBtn from './sub-components/PortfolioAddAndDesignBtns';
import PortfolioContextProvider from './PortfolioContext';
import { initialPortfolioItem } from 'types/portfolio';

const CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER = 2.5;

const Portfolios = () => {
  const [openEditDialog, setEditDialogController] = useState(false);
  const [portfolioIndex, setPortfolioIndex] = useState(-1);
  const [portfolioLocalState, setPortfolioLocalState] = useState(initialPortfolioItem);
  const { localCurrentThemeIndex } = useLocalCurrentThemeIndex();
  const [slider, setSlider] = useState<Slider | null>();
  const [sliderSettings, setSliderSettings] = useState<
    Settings['slidesToScroll'] | null
  >();
  const { portfolioSylings } = useGetThemesQuery(undefined, {
    selectFromResult: ({ data: themes }) => ({
      portfolioSylings: themes
        ? themes[localCurrentThemeIndex].portfolios
        : initialThemeState.portfolios,
    }),
  });
  // portfolios data
  const userId = useAppSelector(selectLoggedInUserId);
  const {
    data,
    error: porftolioLoadingErrorMsg,
    isError: isPortfolioLoadingError,
    isSuccess: isPortfolioLoadingSuccessful,
    isLoading: arePortfoliosLoading,
    isFetching,
  } = useGetPortfoliosByUserIdQuery(userId, {
    skip: !!!userId,
  });
  let content;
  if (isPortfolioLoadingError) {
    console.error('porftolioLoadingError: ', porftolioLoadingErrorMsg);
    content = (
      <p className='m-0 text-danger'>Some error occurred while loading portfolios</p>
    );
  }

  if (!portfolioSylings) return <></>;

  if (arePortfoliosLoading) {
    content = <BootstrapLoader />;
  }

  if (isPortfolioLoadingSuccessful) {
    if (data?.isGridMode) {
      content = <PortfolioGalleryMode userId={userId} />;
    } else {
      content = (
        <PortfolioSlider
          userId={userId}
          setSlider={setSlider}
          sliderSettings={sliderSettings}
        />
      );
    }
  }

  return (
    <PortfolioContextProvider
      portfolioLocalState={portfolioLocalState}
      setPortfolioLocalState={setPortfolioLocalState}
      openEditDialog={openEditDialog}
      setOpenEditDialog={setEditDialogController}
      portfolioIndex={portfolioIndex}
      setPortfolioIndex={setPortfolioIndex}
    >
      <Grid
        item
        sx={{
          background: portfolioSylings.background,
          backgroundSize: portfolioSylings.backgroundSize,
          backgroundBlendMode: portfolioSylings.backgroundBlendMode ?? 'normal',
        }}
      >
        <Box
          component='section'
          id='portfolios'
          sx={{
            opacity: isFetching ? 0.5 : 1,
            pointerEvents: isFetching ? 'none' : 'unset',
          }}
        >
          <LayoutContainer
            sx={{
              position: 'relative',
              paddingTop: {
                xs: getInPx(
                  portfolioSylings.paddingTop / CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER
                ),
                sm: getInPx(portfolioSylings.paddingTop),
              },
              paddingBottom: {
                xs: getInPx(
                  portfolioSylings.paddingBottom / CONTAINER_MOBILE_PADDING_Y_AXIS_REDUCER
                ),
                sm: getInPx(portfolioSylings.paddingBottom),
              },
            }}
          >
            <PortfolioHeading portfolioHeadingStyles={portfolioSylings.title} />
            <PortfolioAddAndDesignBtn />
            <Box className='wrapper' component='article' marginTop='3rem'>
              {content}
              {/* ARROWS button */}
              {!data?.isGridMode && (
                <>
                  <IconButton
                    aria-label='portfolio previous button'
                    onClick={() => {
                      slider?.slickPrev();
                      setSliderSettings(3);
                    }}
                    onMouseLeave={() => {
                      setSliderSettings(null);
                    }}
                    sx={{ ...arrowButtonStyling, left: { xs: 5, md: 0 } }}
                  >
                    <ArrowBackIcon
                      sx={{
                        width: { xs: 30, md: 40 },
                        height: { xs: 30, md: 40 },
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        fill: 'white',
                        padding: '0.7rem',
                        stroke: 'white',
                      }}
                    />
                  </IconButton>
                  <IconButton
                    aria-label='portfolio next button'
                    onClick={() => {
                      slider?.slickNext();
                      setSliderSettings(3);
                    }}
                    onMouseLeave={() => {
                      setSliderSettings(null);
                    }}
                    sx={{ ...arrowButtonStyling, right: { xs: 5, md: 0 } }}
                  >
                    <ArrowForwardIcon
                      sx={{
                        width: { xs: 30, md: 40 },
                        height: { xs: 30, md: 40 },
                        backgroundColor: 'black',
                        borderRadius: '50%',
                        fill: 'white',
                        padding: '0.7rem',
                        stroke: 'white',
                      }}
                    />
                  </IconButton>
                </>
              )}
            </Box>
          </LayoutContainer>
        </Box>
      </Grid>
    </PortfolioContextProvider>
  );
};

export default Portfolios;

const arrowButtonStyling: SxProps = {
  mt: '3rem',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  padding: 0,
};
