import { useState, useRef, useEffect } from 'react';
import { Stack, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

import IfElse from 'components/utilities/IfElse';
import GoogleCaptcha from 'components/utilities/GoogleCaptcha';
import SectionLoader from 'components/utilities/Loaders/SectionLoader';
import { PopupSectionHeading } from 'components/dashboard/DashboardReusableComponent';
import ResponsiveButton from 'components/Buttons/ResponsiveButton';

import { useUpdateServiceOwnerInstructionsToCustomerMutation } from 'store/rtkqFeatures/api/serviceOrdersExtendedApi';
import { dashboard_icon_size } from 'views/Dashboard/Dashboard';

export type ServiceOrderProps = {
  serviceOrderId: string;
};

const TOAST_MESSAGE_SETTINGS = {
  autoClose: 1000,
  hideProgressBar: true,
};

type ServiceOrderInstructionToCustomerProps = ServiceOrderProps & {
  cacheProductOwnerInstructionsToCustomer: string;
};

const ServiceOrderInstructionToCustomer: React.FunctionComponent<ServiceOrderInstructionToCustomerProps> = ({
  serviceOrderId,
  cacheProductOwnerInstructionsToCustomer,
}) => {
  const INITIAL_FORM_STATE = {
    isCaptchaVisible: false,
    captchaValue: '',
    currentProductOwnerInstructionsToCustomer: cacheProductOwnerInstructionsToCustomer,
  };
  const [formState, updateFormState] = useState(INITIAL_FORM_STATE);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  
  const [updateInstructionsToCustomer, { isLoading: areInstructionsUpdating }] = useUpdateServiceOwnerInstructionsToCustomerMutation();

  const handleFormSubmit = async () => {
    if (areInstructionsUpdating) return;
    if (!formState.captchaValue) {
      toast.error('Please complete the reCAPTCHA before submitting the form.', TOAST_MESSAGE_SETTINGS);
      return;
    }
    try {
      await updateInstructionsToCustomer({
        serviceOrderId,
        updatedInstructions: formState.currentProductOwnerInstructionsToCustomer || '',
      }).unwrap();
      toast.success('Message sent successfully 🎉');
    } catch (error) {
      toast.error('Error while sending message.');
      console.error('Error while sending message | service order msg Error: ', error);
    } finally {
      recaptchaRef && recaptchaRef?.current?.reset();
      updateFormState(INITIAL_FORM_STATE);
    }
  };

  const onGoogleRecaptchWithToken = (token: string | null) => {
    if (token) {
      updateFormState((prev) => ({
        ...prev,
        captchaValue: token,
      }));
    }
  };

  const onGoogleRecaptchaExpiredOrError = () => {
    updateFormState((prev) => ({
      ...prev,
      captchaValue: '',
    }));
  };

  useEffect(() => {
    updateFormState((prev) => ({
      ...prev,
      currentProductOwnerInstructionsToCustomer: cacheProductOwnerInstructionsToCustomer,
    }));
  }, [cacheProductOwnerInstructionsToCustomer, updateFormState]);

  return (
    <Stack spacing={1}>
      <PopupSectionHeading>Instructions To Customer</PopupSectionHeading>
      <Stack
        sx={{
          width: {
            xs: 'auto',
            md: '100%',
          },
        }}
      >
        {/*  currentProductOwnerInstructionsToCustomer textarea */}
        <TextField
          variant='outlined'
          multiline
          size='small'
          fullWidth
          minRows={1}
          disabled={areInstructionsUpdating}
          placeholder='Type new message to customer…'
          value={formState.currentProductOwnerInstructionsToCustomer}
          onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) =>
            updateFormState((prev) => ({
              ...prev,
              isCaptchaVisible: true,
              currentProductOwnerInstructionsToCustomer: ev.target.value,
            }))
          }
        />
        {/* actions */}
        <Stack spacing={2} justifyContent='flex-end' alignItems='flex-end' mt={2}>
          {/* ReCAPTCHA */}
          <IfElse condition={formState.isCaptchaVisible}>
            <GoogleCaptcha
              ref={recaptchaRef}
              onChangeWithValidCaptchaValue={onGoogleRecaptchWithToken}
              onExpired={onGoogleRecaptchaExpiredOrError}
              onError={onGoogleRecaptchaExpiredOrError}
            />
          </IfElse>
          <IfElse condition={Boolean(formState.captchaValue)}>
            <ResponsiveButton
              disabled={areInstructionsUpdating}
              onClick={handleFormSubmit}
              variant='outlined'
              color='primary'              
              startIcon={<ForwardToInboxIcon sx={dashboard_icon_size} />}
            >
              {areInstructionsUpdating && (
                <SectionLoader isLoading={true} />
              )}
              send email update
            </ResponsiveButton>            
          </IfElse>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ServiceOrderInstructionToCustomer;
