import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { Layout } from 'types/user';

export type LocalLayoutSelector = {
  layout: Layout;
}

const INITIAL_THEME_SELECTOR_STATE: LocalLayoutSelector = {
  layout: 'Layout6',
};

const localLayoutStateSlice = createSlice({
  name: 'localLayoutState',
  initialState: INITIAL_THEME_SELECTOR_STATE,
  reducers: {
    updateLocalCurrentLayoutIndex: (
      state,
      action: PayloadAction<Layout>
    ) => {
      const updatedLayoutIndex = action.payload;
      state.layout = updatedLayoutIndex;
    },
    resetLocalLayoutState: () => {
      return INITIAL_THEME_SELECTOR_STATE;
    },
  },
});

const localLayoutStateReducer = localLayoutStateSlice.reducer;
export default localLayoutStateReducer;

export const { updateLocalCurrentLayoutIndex, resetLocalLayoutState } = localLayoutStateSlice.actions;

export const selectLocalLayoutState = (state: RootState) => {
  return state.persistedReducer.localLayoutSelector.layout;
}

